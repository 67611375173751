import { Button } from "@material-ui/core";
import { BrandAlert } from "../CoreComponents/BrandAlert";
import { TempSecondaryBrandButton } from "../CoreComponents/BrandButton";
import BackupIcon from '@material-ui/icons/Backup';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const UploadLogoAccountHolder = ({styles,previewLogo,handleUpload,handleDeleteLogo,errorUploadLogo}) =>{

    return (
        <div className={styles.uploadLogoWrapper}>
        <span className={styles.uploadLogoTitle}>Logo (Optional)</span>
        <div className={styles.upload}>
            {
            previewLogo ? 
                <>
                    <Button
                        component="label"
                        className={styles.uploadLogoButton}
                        >
                            <img src={previewLogo.URL} alt='currentLogo' className={styles.previewLogo}/>
                            <span className={styles.logoName}>{previewLogo.name}</span>
                            <input type='file' onChange={handleUpload} className={styles.hiddenInputFile}/>
                    </Button>
                    <DeleteOutlineIcon
                        className={styles.deleteLogoButton}
                        onClick={handleDeleteLogo}
                        />
                    

                </>
                : 
                <>
                    <Button
                        component="label"
                        className={styles.uploadLogoButton}
                        startIcon={<BackupIcon />}
                        >
                            Upload Logo
                        <input type='file' onChange={handleUpload} className={styles.hiddenInputFile}/>
                    </Button>
                    {errorUploadLogo && <BrandAlert className={styles.errorValidateFile}>{errorUploadLogo}</BrandAlert>}
                </>
            }
        </div>
        
        
    </div>
    )
}

export default UploadLogoAccountHolder;