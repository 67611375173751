import { AppBar, Box, Tab, Tabs, makeStyles } from '@material-ui/core';
import React, { useState } from 'react'
import RoyaltyStatements from './RoyaltyStatements/RoyaltyStatements';
import CsvStatements from './CsvStatements/CsvStatements';
import { useLocation } from 'react-router-dom'
import { userAccessByType } from '../utils/AccessUtils';
import { useStoreContext } from '../../store/Store';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minWidth: '1275px',
    },
    bar: {
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiPaper-elevation4': {
            boxShadow: 'none !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main
        },
        "& .MuiButtonBase-root": {
            color: 'white',
            opacity: 1,
            font: 'normal normal bold 28px/21px Roboto',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            minWidth: '300px',
            textTransform: 'none',
        },
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
        marginBottom: theme.spacing(5),
    },
}));

const ReportingPage = () => {
    const location = useLocation();
    const styles = useStyles();
    const [state] = useStoreContext();
    const [tabValue, setTabValue] = useState(location.state?.tabValue || 0);

    const [NavOptions] = useState([
        { label: "CSV Statements", tabProps: {}, component: <CsvStatements />, roles: [userAccessByType.USER_TYPE_ADMIN_ACCESS] },
        { label: "Royalty Statements", tabProps: {}, component: <RoyaltyStatements />, roles: [userAccessByType.USER_TYPE_ADMIN_ACCESS, userAccessByType.USER_TYPE_USER_ACCESS] },
    ].filter(x => x.roles.includes(state.user.userType)));

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div className={styles.container}>
            <div className={styles.bar}>
                <AppBar position="static">
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        {NavOptions.map((tab, index) => (
                            <Tab key={index} label={tab.label} {...tab.tabProps} />
                        ))}
                    </Tabs>
                </AppBar>
            </div>

            <Box>
                {NavOptions[tabValue]?.component}
            </Box>
        </div>
    )
}

export default ReportingPage