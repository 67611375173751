import { IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import {  TempBrandButton, TempSecondaryBrandButton } from "../../../CoreComponents/BrandButton";
import { useEffect, useState } from "react";
import * as Papa from 'papaparse';
import { postData } from "../../../utils/FetchUtils";
import { BrandLoader } from "../../../CoreComponents/BrandLoader";
import { ButtonSize } from "../../../../constants/buttonConstants";

const useStyles = makeStyles((theme) => ({
    uploadedFileListContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '30px',
        width: '300px',
        height: '175px'
    },
    rootLIContent: {
        display: 'flex'
    },
    buttonContainer: {
        display: 'flex',
        gap: '50px'
    },
    rootLIContent: {
        display: 'flex',
        alignItems: 'center'
    },
    rootLI: {
        padding: 0
    },
    uploadedFilesTitle: {
        font: 'normal normal bold 14px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.primary.lightGray,
    },
    list: {
        padding: '0px'
    },
    icon: {
        color: theme.palette.text.hint
    },
    alert: {
        color: theme.palette.primary.alert
    },
    successMsg: {
        color: theme.palette.primary.main
    },
    loader: {
        display: 'flex',
        justifyContent: "center"
    },

}));

export const UploadRegistrationFileList = ({
    uploadedFile,
    setUploadedFile,
    details,
    refresh,
    setRefresh,
    onClose
}) => {
    const styles = useStyles();
    const [parsedDataCsv, setParsedDataCsv] = useState([]);
    const [disableAdd, setDisableAdd] = useState(true);
    const [alert, setAlert] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const loaderOptions = {
        loaderHeight: 40,
        loaderWidth: 40
    }

    const { name } = uploadedFile?.[0] ?? '';
    const addData = () => {
        setLoading(true);

        postData(process.env.REACT_APP_SERVER_HOST + '/api/music-shares/add-music-shares-data', { songId: details.id, parsedDataCsv })
            .then(res => {
                setUploadedFile('');
                setParsedDataCsv([]);
                setSuccessMsg('Successfully uploaded.');
                setLoading(false);
                setAlert('');
                setRefresh(true);
                onClose();
            })
            .catch(err => {
                setAlert(err.message);
                setLoading(false);
            });
    };

    const parseCsvFile = (file) => {
        Papa.parse(file, {
            header: true,
            worker: true,
            skipEmptyLines: true,
            complete: function (results) {
                const musicSharesData = results.data.map(x => {

                    const reportingCompany = x['Affiliation'] !== "-" ? (x['Affiliation']).trim() : null;
                    const participantName = x['Participant Name'] !== "-" ? (x['Participant Name']).trim() : null;
                    const shareType = x['Usage'] !== "-" ? (x['Usage']).trim() : null;
                    const participantType = x['Participant Type'] !== "-" ? (x['Participant Type']).trim() : null;
                    const sharePercentage = x['Shares'] !== "-" ? Number(x['Shares'].replace('%', '')) : null;
                    const csvFileName = name || null;

                    return {
                        reportingCompany,
                        participantName,
                        shareType,
                        participantType,
                        sharePercentage,
                        csvFileName
                    }
                })
                setParsedDataCsv(musicSharesData);
            }
        });
    };

    useEffect(() => {
        if (uploadedFile !== '') {
            parseCsvFile(uploadedFile[0]);
            setDisableAdd(false);
        } else {
            setDisableAdd(true);
        }
    }, [uploadedFile]);

    useEffect(() => {
        setTimeout(() => {
            setSuccessMsg('');
        }, 5000);
    }, [successMsg]);

    const addHandler = () => {
        addData();
    };

    const deleteHandler = () => {
        setUploadedFile('')
        setParsedDataCsv([])
    };

    const cancelHandler = () => {
        setUploadedFile('');
        setParsedDataCsv([]);
    };


    return (
        <div className={styles.uploadedFileListContainer}>
            <div className={styles.uploadedFilesTitle}>Uploaded File</div>
            {
                loading ?
                    <div className={styles.loader}>
                        <BrandLoader
                            height={loaderOptions.loaderHeight}
                            width={loaderOptions.loaderWidth}
                        />
                    </div>
                    :
                    name ?
                        <List className={styles.list}>
                            < div key={Math.random()}>
                                <ListItem classes={{ root: styles.rootLI }}>
                                    <div className={styles.rootLIContent}>
                                        <ListItemIcon>
                                            <DescriptionOutlinedIcon className={styles.icon} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={name}
                                        />
                                        <IconButton
                                            onClick={deleteHandler}
                                        >
                                            <DeleteOutlineOutlinedIcon className={styles.icon} />
                                        </IconButton>
                                    </div>
                                </ListItem>
                            </div>
                        </List>
                        :
                        ''
            }
            {
                alert ?
                    <div className={styles.alert}>{alert}</div>
                    :
                    ''
            }
            {
                successMsg ?
                    <div className={styles.successMsg}>{successMsg}</div>
                    :
                    ''
            }
            <div className={styles.buttonContainer}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    onClick={cancelHandler}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton
                    size={ButtonSize.SMALL}
                    onClick={addHandler}
                    disabled={disableAdd}
                >
                    Add
                </TempBrandButton>
            </div>
        </div>
    );
}