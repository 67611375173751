import { Slide, makeStyles } from "@material-ui/core"
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'justify',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        fontSize: '21px',
        width: '777px',
        lineHeight: 1.6,
        [theme.breakpoints.down('sm')]: {
            width: '340px',
            fontSize: '14px',
        }
    },
}))
export default function LearnMore() {
    const styles = useStyles();

    return (
        <Slide
            timeout={500}
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
        >
            <div className={styles.container}>
                <p>Record is a financial technology platform for alternative assets ranging from Music, NFTs, Cryptocurrency, Film, TV, Literature & Content royalties.</p>
                <p>Record is currently in private beta but opening shortly for everyone. If interested in utilizing Record’s services please fill out the following <Link to="/contact"><u>contact form.</u></Link></p>
            </div>
        </Slide>
    )
}