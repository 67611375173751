import { AppBar, Box, Button, makeStyles, Tab, Tabs, TextareaAutosize, Typography } from "@material-ui/core";
import clsx from 'clsx';
import { useEffect, useState } from "react";
import { useStoreContext } from "../../store/Store";
import { BrandAlert } from "../CoreComponents/BrandAlert";
import { postData } from "../utils/FetchUtils";
import ManageOwnershipRegistrationFrom from "./ManageOwnershipRegistrationForm";
import SongDetails from "./SongDetails";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '10px 30px',
        width: '1250px',
        height: '630px',
    },
    titles: {
        fontWeight: 'bold',
        letterSpacing: 2,
        marginBottom: '20px'
    },
    bar: {
        width: '100%',
        '& .MuiTabs-root': {
            marginBottom: '24px',
        },
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiPaper-elevation4': {
            boxShadow: 'none !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main
        },
        '& .MuiBox-root': {
            padding: 0
        },
        "& .MuiButtonBase-root": {
            padding: 0,
            color: 'white',
            opacity: 1,
            font: 'normal normal normal 20px/25px Roboto',
            letterSpacing: '1.6px',
            textTransform: 'none'
        },
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: 'start',
            gap: '50px'
        },
    },
    cancelButton: {
        width: '200px',
        marginBottom: '10px',
        font: 'normal normal  14px/32px Roboto !important',
        textTransform: 'uppercase !important',
        backgroundColor: theme.palette.background.default,
        '&:hover': {
            backgroundColor: theme.palette.background.default
        },
        '& .MuiButton-label': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiButton-label:hover': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
        },
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px 30px 0 30px',
    },
    submitButton: {
        width: '200px',
        marginBottom: '10px',
        font: 'normal normal  14px/32px Roboto !important',
        textTransform: 'uppercase !important',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiButton-label': {
            borderRadius: '20px',
            color: theme.palette.background.default,
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiButton-label:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
        },
    },
    textarea: {
        marginTop: 20,
        padding: 30,
        width: '550px',
        minHeight: '100px',
        maxHeight: '100px',
        backgroundColor: 'inherit',
        borderRadius: '10px',
        outline: 'none',
        color: theme.palette.text.primary,
        "&::placeholder": {
            fontSize: "16px"
        },
        overflow: 'auto !important'
    },
    tabPanel: {
        width: '100%',
        height: '320px'
    },
    saveAsDraftBtnContainer: {
        display: 'flex',
        gap: '40px'
    },
    draftBtn: {
        width: '180px',
        height: '45px',
        borderColor: theme.palette.text.primary,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '40px',
        color: theme.palette.text.primary,
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} >
                    <Typography component='div'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function setTabProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function RegistrationForm({ setShowModal, rowData, fetchData, setIssueReportedState, selectedRowData, setRowData, originalData }) {
    const [state, setState] = useStoreContext();
    const styles = useStyles();
    const [value, setValue] = useState(0);
    const [systemComment, setSystemComment] = useState(rowData.resolutionComment ?? '');
    const [alert, setAlert] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const resolveHandler = () => {
        setAlert('');

        postData(process.env.REACT_APP_SERVER_HOST + '/api/missing-data/resolve-issue', {
            missingDataCaseId: rowData.id,
            comment: systemComment
        })
            .then(res => {
                setShowModal(false)
                setIssueReportedState(rowData.id)
                const filteredData = originalData.filter(row => row.id !== selectedRowData.id);
                setRowData(filteredData);
            })
            .catch(err => setAlert(err.message))

    };

    const reportHandler = () => {
        postData(process.env.REACT_APP_SERVER_HOST + '/api/missing-data/report-issue', {
            missingDataCaseId: rowData.id,
            comment: systemComment
        })
            .then(res => {
                setShowModal(false)
                setIssueReportedState(rowData.id)
            })
            .catch(err => setAlert(err.message))
    };

    const saveAsDraftHandler = () => {
        postData(process.env.REACT_APP_SERVER_HOST + '/api/missing-data/save-as-draft', {
            missingDataCaseId: rowData.id,
            comment: systemComment
        })
        .then(() => { 
            setShowModal(false);
            fetchData();
        })
        .catch((err) => console.log(err));
    };

    const textAreaHandler = (e) => {
        setSystemComment(e.target.value);
    };

    return (
        <div>
            <div className={styles.container}>
                <Typography
                    className={styles.titles}
                    component='div'
                    variant='h5'
                >
                    Registration Form
                </Typography>
                <div className={styles.bar}>
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label='Song Details' {...setTabProps(0)} />
                            {
                                state.user.userType === 1 ?
                                    <Tab label='Manage Ownership' {...setTabProps(1)} />
                                    :
                                    null
                            }
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0} className={clsx(styles.tabPanel, styles.tabHeight)}>
                        <SongDetails songId={rowData.songId} />
                    </TabPanel>
                    <TabPanel value={value} index={1} className={clsx(styles.tabPanel, styles.tabHeight)}>
                        <ManageOwnershipRegistrationFrom />
                    </TabPanel>
                </div>
                <div>
                    <BrandAlert>{alert}</BrandAlert>
                    <Typography
                        className={styles.titles}
                        component='div'
                        variant='h5'
                    >
                        {
                            state.user.userType === 1 ?
                                'Leave resolution comment (optional)'
                                :
                                'Leave us a comment (optional)'

                        }
                    </Typography>
                    <TextareaAutosize onChange={textAreaHandler} className={styles.textarea} placeholder="Click here to leave us a comment with more information on the issue" />
                </div>
            </div>
            <div className={styles.btnContainer}>
                <Button onClick={() => setShowModal(false)} className={styles.cancelButton}>Cancel</Button>
                {
                    state.user.userType === 1 ?
                        <div className={styles.saveAsDraftBtnContainer}>
                            <Button onClick={saveAsDraftHandler} className={styles.draftBtn}>Save as Draft</Button>
                            <Button onClick={resolveHandler} className={styles.submitButton}>Resolve</Button>
                        </div>
                        :
                        <Button onClick={reportHandler} className={styles.submitButton}>Submit</Button>
                }
            </div>
        </div>
    );
};

export default RegistrationForm;