import {  useRef } from 'react';
import { useRenderPDF } from './useRenderPDF';
import { BrandLoader } from '../../CoreComponents/BrandLoader';

export const RenderedPDFViewer = ({ pdfData,styles,reportStyles ,style, className, text: outerText, innerRef, showToolbar = true, ...props }) => {
    const paramsRef = useRef({ pdfData, styles });
    
    if (paramsRef.current.pdfData !== pdfData || paramsRef.current.styles !== styles) {
        paramsRef.current = { pdfData, styles };
    }

    const { url, loading, error } = useRenderPDF(paramsRef.current);

  const src = url ? `${url}#toolbar=${showToolbar ? 1 : 0}` : null;
  if (loading)
    return (      
      <div className={reportStyles.collectionsLoader}><BrandLoader color='white' width={50} height={50} /></div> 
    );

  if (error) {
    console.log({ error });
    return (
      <div className={className} style={style}>
        {JSON.stringify(error)}
      </div>
    );
  }

  return (
    <iframe
      src={src}
      ref={innerRef}
      style={{width:window.innerWidth,height:window.innerHeight}}
      className={className}
      {...props}
    />
  );
};
