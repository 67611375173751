
import React from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { useStoreContext } from '../../store/Store';
import SearchSongAutocomplete from './SearchSongAutocomplete';
import SongSuggestionsAutocomplete from './SongSuggestionsAutocomplete';
import FoundSong from './FoundSong';
import AccountHoldersPopover from '../CoreComponents/AccountHoldersPopover';
import { MatchingLevels } from '../../constants/song-matching-levels-constants';

const useStyles = makeStyles((theme) => ({
    noDataTitle: {
        fontSize: '20px',
        textAlign: 'center',
    },
    subTitles: {
        display: 'flex',
        gap: '35%'
    },
    subTitle: {
        fontSize: '20px',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    songListItem: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display: 'flex',
        gap: theme.spacing(2),
        height: '65px',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    songListItems: {
        display: 'flex',
        flexDirection: 'column',
        '& tr:nth-child(odd)': {
            backgroundColor: theme.palette.background.grayNuance
        }
    },
    songListItemItem: {
        width: '150px',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    songListItemTitle: {
        fontSize: '11px',
        color: theme.palette.text.primary
    },
    searchField: {
        width: '350px',
        display: 'flex',
        justifyContent: 'end',
        position: 'relative'
    },
    songListItemData: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '150px',
        textOverflow: 'ellipsis'
    },
    accountHolderWarning: {
        color: theme.palette.primary.alert,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '-10%'
    }
}));

const MISSMATCHING_ACCOUNT_HOLDER_WARNING = 'This song has been uploaded by a different account holder.';

const WarningTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
))(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.alert
}));

export default function SongsList({
    songsMapKeys,
    onSelectedSong,
    setExternalUPCs,
    setExternalISRCs,
    accountHolder,
    songsFilter
}) {
    const [state] = useStoreContext();
    const styles = useStyles();

    const applyFilter = (currentSong) => {
        if (!songsFilter) return true;

        if (songsFilter.unmatchedOption[0] && !currentSong.foundSongId)
            return true;
        if (songsFilter.matchedWithSuggestionsOption[0] && currentSong.matchingLevel === MatchingLevels.PARTIAL_MATCH_SUGGESTIONS)
            return true;
        if (songsFilter.partialMatchOption[0] && currentSong.matchingLevel === MatchingLevels.PARTIAL_MATCH)
            return true;
        if (songsFilter.manuallyMatchedOption[0] && currentSong.manuallyMatched === true)
            return true;

        return false;
    }

    return (
        songsMapKeys.length > 0 ?
            <>
                <div className={styles.subTitles}>
                    <div className={styles.subTitle}>Songs from Report</div>
                    <div className={styles.subTitle}>Songs from System</div>
                    <div />
                </div>
                <div className={styles.songListItems}>
                    {songsMapKeys.map(songMapKey => {
                        const songMap = state.songsMap[songMapKey] || state.partialSongsMap[songMapKey];
                        if (songMap && applyFilter(songMap)) {
                            return (
                                <div key={songMapKey} className={styles.songListItem}>
                                    <div className={styles.songListItemItem}>
                                        <div className={styles.songListItemTitle}>Title</div>
                                        <Tooltip title={songMap.title} placement="bottom">
                                            <div className={styles.songListItemData}>{songMap.title?.trim()}</div>
                                        </Tooltip>
                                    </div>
                                    <div className={styles.songListItemItem}>
                                        <div className={styles.songListItemTitle}>ISRC</div>
                                        <Tooltip title={songMap.isrc} placement="bottom">
                                            <div className={styles.songListItemData}>{songMap.isrc?.trim() || 'No Data'}</div>
                                        </Tooltip>
                                    </div>
                                    <div className={styles.songListItemItem}>
                                        <div className={styles.songListItemTitle}>Artist</div>
                                        <Tooltip title={songMap.artist} placement="bottom">
                                            <div className={styles.songListItemData}>{songMap.artist?.trim() || 'No Data'}</div>
                                        </Tooltip>
                                    </div>
                                    <div className={styles.songListItemItem}>
                                        <div className={styles.songListItemTitle}>Album</div>
                                        <Tooltip title={songMap.album} placement="bottom">
                                            <div className={styles.songListItemData}>{songMap.album?.trim() || 'No Data'}</div>
                                        </Tooltip>
                                    </div>
                                    <div className={styles.searchField}>
                                        {(songMap.foundAccountHolders?.length > 0 && !songMap.foundAccountHolders.includes(accountHolder.name)) &&
                                            <WarningTooltip
                                                title={MISSMATCHING_ACCOUNT_HOLDER_WARNING}
                                                placement="bottom"
                                            >
                                                <ReportProblemOutlinedIcon className={styles.accountHolderWarning} />
                                            </WarningTooltip>
                                        }
                                        {songMap.foundSongId ?
                                            <FoundSong
                                                songMap={songMap}
                                                songMapKey={songMapKey}
                                                setExternalISRCs={setExternalISRCs}
                                                setExternalUPCs={setExternalUPCs}
                                            />
                                            : songMap.matchingLevel === MatchingLevels.PARTIAL_MATCH_SUGGESTIONS ?
                                                <SongSuggestionsAutocomplete
                                                    songMap={songMap}
                                                    songMapKey={songMapKey}
                                                    onSelectedSong={(song) => onSelectedSong(song, songMapKey)}
                                                />
                                                :
                                                <SearchSongAutocomplete
                                                    onSelectedSong={(song) => onSelectedSong(song, songMapKey)}
                                                />
                                        }

                                    </div>
                                    <div className={styles.songListItemItem}>
                                        <div className={styles.songListItemTitle}>Account Holder(s)</div>
                                            <div className={styles.songListItemData}><AccountHoldersPopover accountHolders={songMap.foundAccountHolders} /></div>
                                        </div>
                                    <div className={styles.songListItemItem}>
                                        <div className={styles.songListItemTitle}>ISRC</div>
                                        <Tooltip title={songMap.foundIsrc} placement="bottom">
                                            <div className={styles.songListItemData}>{songMap.foundIsrc || 'N/A'}</div>
                                        </Tooltip>
                                    </div>
                                    <div className={styles.songListItemItem}>
                                        <div className={styles.songListItemTitle}>Artist</div>
                                        <Tooltip title={songMap.foundArtist} placement="bottom">
                                            <div className={styles.songListItemData}>{songMap.foundArtistId ? songMap.foundArtist : 'N/A'}</div>
                                        </Tooltip>
                                    </div>
                                    <div className={styles.songListItemItem}>
                                        <div className={styles.songListItemTitle}>Album</div>
                                        <Tooltip title={songMap.foundAlbum} placement="bottom">
                                            <div className={styles.songListItemData}>{songMap.foundAlbum || 'N/A'}</div>
                                        </Tooltip>
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </>
            :
            <div className={styles.noDataTitle}>No matched songs</div>
    );
}