import makeStyles from '@material-ui/core/styles/makeStyles';
import { useState } from 'react';
import { getData, postData } from '../../utils/FetchUtils';
import { useEffect } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as Papa from 'papaparse';
import NftDetailsTabs from './NftDetailsTabs';
import { BrandAlert } from '../../CoreComponents/BrandAlert';
import { TempSecondaryBrandButton } from '../../CoreComponents/BrandButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { BrandLoaderDots } from '../../CoreComponents/BrandLoader';

const useNftStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minWidth: '1275px',
    },
    close: {
        position: "absolute",
        right: 18,
        top: 10,
        '&:hover': {
            cursor: "pointer",
        }
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.25),
        background: theme.palette.background,
        borderTop: '1px solid black',
        paddingRight: theme.spacing(3),
    },
    footerContent: {
        display: 'flex',
        alignItems: 'center'
    },
    downloadButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 10
    },
    shareButton: {
        color: 'black',
        textTransform: 'capitalize',
        font: 'normal normal bolder 14px/18px Roboto',
        letterSpacing: '1.4px',
        marginLeft: theme.spacing(3),
        height: 50,
        width: 200,
        '& .MuiSvgIcon-root': {
            fontSize: '35px'
        }
    },
    tabsContainer: {
        display: 'flex',
        alignItems: 'end'
    },
    detailTabsContainer: {
        width: '100%'
    },

}));

const SelectedNftData = ({ details, onClose, accountHolder }) => {
    const styles = useNftStyles();
    const [fetchedDiligence, setFetchedDiligence] = useState(false);
    const [alert, setAlert] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [isFetchingDiligence, setIsFetchingDiligence] = useState(false);
    const [showCurrencySelect, setShowCurrencySelect] = useState(false);
    const [cryptocurrencies, setCryptocurrencies] = useState([]);
    const [selectedCryptocurrency, setSelectedCryptocurrency] = useState('');
    const [additionalDetails, setAdditionalDetails] = useState(null);


    useEffect(() => {
        if (details.blockchain === 'ETHEREUM') {
            setShowCurrencySelect(true);
            getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-cryptocurrencies?nftId=${details.id}`)
                .then(data => {
                    if (data && data.length) {
                        setCryptocurrencies(data);
                        setSelectedCryptocurrency(data[0]);
                    } else {
                        setShowCurrencySelect(false);
                    }
                }, err => {
                    setShowCurrencySelect(false);
                    console.error(err);
                })

        } else {
            setShowCurrencySelect(false);
        }
    }, [details])

    useEffect(() => {
        if (selectedCryptocurrency && details.blockchain === 'ETHEREUM') {
            getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-ethereum-nft-revenue?nftId=${details.id}&cryptoCurrency=${selectedCryptocurrency}`)
                .then(data => {
                    data.currency = selectedCryptocurrency;
                    setAdditionalDetails(data);
                }, err => {
                    setShowCurrencySelect(false);
                    console.error(err);
                })
        }
    }, [selectedCryptocurrency])

    function formatAccountHolders(accountHolders){
        const formattedAccountHolders = JSON.parse(accountHolders).map(acc =>{
            return acc.name
        })
        return formattedAccountHolders
    }

    function downloadFiles() {
        setIsDownloading(true);
        const formattedAccountHolders = formatAccountHolders(details.accountHolders);
        const reportInfo = [{...details,accountHolders: formattedAccountHolders}];
        const asJsonData = JSON.stringify(reportInfo);
        const asCsvData = Papa.unparse(asJsonData);
        const csvFile = new File([asCsvData], `${details.title}.csv`, {
            type: "text/plain",
        });
        const dateStamp = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
        const url = window.URL.createObjectURL(csvFile);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${details.title}_${dateStamp}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setIsDownloading(false);
    }

    function shareDiligence() {
        setIsFetchingDiligence(true);
        postData(process.env.REACT_APP_SERVER_HOST + '/api/report/share-nft-diligence', { nfdId: details.id, accountHolderId: accountHolder })
            .then(data => {
                navigator.clipboard.writeText(process.env.REACT_APP_SERVER_HOST + `/share-diligence?token=${data.token}`);
                setFetchedDiligence(true);
                setIsFetchingDiligence(false);
                setAlert('Copied link to clipboard');
            }, err => {
                setFetchedDiligence(false);
                setIsFetchingDiligence(false);
                setAlert(err.message);
            })
    }

    return (
        <div className={styles.container}>
            <HighlightOffIcon color='primary' className={styles.close} fontSize='large' onClick={() => { onClose(false) }} />
            <div className={styles.tabsContainer} >
                <NftDetailsTabs
                    className={styles.detailTabsContainer}
                    styles={{ width: '100%' }}
                    details={details}
                    additionalDetails={additionalDetails}
                    accountHolder={accountHolder}
                    selectedCryptocurrency={selectedCryptocurrency}
                    setSelectedCryptocurrency={setSelectedCryptocurrency}
                    cryptocurrencies={cryptocurrencies}
                />
            </div>
            <div className={styles.footer}>
                <div className={styles.footerContent}>
                </div>
                <div className={styles.footerContent}>
                    <div className={styles.downloadButtonContainer}>
                        {alert ? <BrandAlert success={fetchedDiligence}>{alert}</BrandAlert> : null}
                        <TempSecondaryBrandButton
                            variant="outlined"
                            onClick={downloadFiles}
                            disabled={isDownloading}
                            startIcon={isDownloading ? null : <GetAppIcon />}
                        >
                            {isDownloading ? <BrandLoaderDots /> : 'Download Reports'}
                        </TempSecondaryBrandButton>
                    </div>
                    {/* <BrandButton
                        className={styles.shareButton}
                        onClick={shareDiligence}
                        disabled={isFetchingDiligence}
                        startIcon={isFetchingDiligence ? null : <ShareIcon />}
                    >
                        {isFetchingDiligence ? <BrandLoaderDots /> : 'Share Diligence'}
                    </BrandButton>   */}
                </div>
            </div>
        </div>
    );
}

export default SelectedNftData;