import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
    makeStyles
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { styled } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CustomAccordionContent from "./CustomAccordionContent";
import { TempBrandButton } from "../CoreComponents/BrandButton";
import { useState } from "react";
import { transformPeriodToQuarterYear } from "../utils/DateUtils";
import { ButtonSize } from "../../constants/buttonConstants";

const useStyles = makeStyles(() => ({
    accordion: {
        borderRadius: '0px !important',
        backgroundColor: '#151515',
        width: '100%'
    },
    accordionSummary: {
        height: '92px',
        width: '85%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: '10px'
    },
    accordionFileName: {
        fontSize: '18px'
    },
    percentageLabel: {
        fontSize: '18px'
    },
    revenueSplitLabel: {
        position: 'absolute',
        top: '2%',
        fontWeight: 'bold'
    },
    accordionPercentage: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#EDE107'
    },
    expandMoreIcon: {
        color: 'white',
        width: '32px',
        height: '32px'
    },
    accordionSummaryContent: {
        margin: '0px'
    },
    accordionExpanded: {
        marginTop: '0px !important',
        marginBottom: '0px !important'
    },
    accordionDetailsWrapper: {
        height: '80px',
        paddingBottom: '35px'
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'row',
        width: '60%',
        justifyContent: 'space-between'
    },
    progressBarWrapper: {
        display: 'flex',
        gap: '10px',
        marginLeft: 'auto'
    },
    editButton: {
        width: '120px',
        display: 'flex',
        gap: '10px',
        marginLeft: 15
    }
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 26,
    width: 250,
    borderRadius: 21,
    backgroundColor: theme.palette.background.grayNuance,
    [`& .MuiLinearProgress-bar`]: {
        borderRadius: 5,
        background: 'transparent linear-gradient(90deg, #4CBD24 0%, #FFF23E 100%) 0% 0% no-repeat padding-box'
    },
}));

const CustomAccordion = ({
    data,
    handleEdit,
    accountHolderName
}) => {
    const [expanded, setExpanded] = useState(false);

    const styles = useStyles();

    return (
        <Accordion
            className={styles.accordion}
            classes={{ expanded: styles.accordionExpanded }}
            expanded={expanded}
            onChange={(_, expanded) => setExpanded(expanded)}
        >
            <AccordionSummary
                expandIcon={<ExpandMore className={styles.expandMoreIcon} />}
                classes={{
                    content: styles.accordionSummaryContent,
                    expanded: styles.accordionExpanded
                }}
            >
                <Box className={styles.accordionSummary}>
                    <Typography className={styles.accordionFileName}>
                        {data.fileName}
                    </Typography>
                    <div className={styles.progressBarWrapper}>
                        <Typography className={styles.revenueSplitLabel}>
                            Revenue Split
                        </Typography>
                        <Typography className={styles.percentageLabel}>
                            {data.splitPercentage}%
                        </Typography>
                        <BorderLinearProgress variant="determinate" value={data.splitPercentage} />
                    </div>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        className={styles.editButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit();
                        }}
                    >
                        Edit
                    </TempBrandButton>
                </Box>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetailsWrapper}>
                <Box className={styles.accordionDetails}>
                    <CustomAccordionContent label='Reporting Company'>
                        {data.reportingCompanyName}
                    </CustomAccordionContent>
                    <CustomAccordionContent label='Date'>
                        {transformPeriodToQuarterYear(data.reportingPeriod)}
                    </CustomAccordionContent>
                    <CustomAccordionContent label='Main Account Holder'>
                        {accountHolderName} - {data.mainSplitPercentage}%
                    </CustomAccordionContent>
                    <CustomAccordionContent label='Notes'>
                        {data.notes ?? 'N/A'}
                    </CustomAccordionContent>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default CustomAccordion;