import { React, useRef, useState } from "react";
import PopoverAutocomplete from "../../Earnings/Autocompletes/PopoverAutocomplete";
import useAbortableFetch from "../../../hooks/useAbortableFetch";

const ReportingCompaniesAutocomplete = ({
    options,
    setOptions,
    selectedOptions,
    setSelectedOptions,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [textContent] = useState('All Reporting Companies');
    const [placeholder] = useState('Search reporting companies');
    const [allSelectedText] = useState('All reporting companies selected');
    const [isLoading, setIsLoading] = useState(true);
    const abortableFetch = useAbortableFetch();
    const offset = useRef(0);

    const callback = async () => {
        const fetchedData = await abortableFetch('POST', `api/elastic/csv-statements/reporting-companies`,
            {
                body: {
                    filter: searchQuery
                }
            }
        )
        setOptions(fetchedData);
        setIsLoading(false);
    };

    return (
        <PopoverAutocomplete
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            options={options}
            setOptions={setOptions}
            offset={offset}
            callback={callback}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            textContent={textContent}
            placeholder={placeholder}
            allSelectedText={allSelectedText}
            isLoading={isLoading}
        />
    )
};

export default ReportingCompaniesAutocomplete;