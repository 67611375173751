import { useState } from "react"

export const useMessageDisplay = (milliseconds = 5000) => {
    const [message, setMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const showMessage = (text) => {
        setMessage(text);
        setIsVisible(true);

        setTimeout(() => {
            setIsVisible(false);
        }, milliseconds);
    };

    return { showMessage, message, isVisible };
}