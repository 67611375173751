import { 
    Checkbox, 
    FormControlLabel, 
    Typography, 
    makeStyles 
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    formControlLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '35px',
        justifyContent: 'flex-start'
    },
    disabled: {
        '&.Mui-disabled': {
            color: '#A5A5A5'
        }
    },
    label: {
        marginTop: '4px',
        color: 'white'
    }
}));

const CustomCheckbox = ({
    checked,
    onChange,
    children,
    disabled
}) => {
    const styles = useStyles();

    return (
        <FormControlLabel 
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            className={styles.formControlLabel}
            classes={{ disabled: styles.disabled, label: styles.label }}
            control={<Checkbox color="primary" />}
            label={<Typography variant="body2">{ children }</Typography>}
        />
    )
}

export default CustomCheckbox;