import { Checkbox, Input, ListItemText, MenuItem, Select, TextField, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { reportNames, REPORT_TYPE_ID_BY_NAME } from "../utils/ReportNamesUtils";
import { BrandPopover } from "../CoreComponents/BrandPopover";
import { getData } from "../utils/FetchUtils";

const useStyles = makeStyles((theme) => ({
    checkboxContainer: {
        width: '20%',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: `1px  ${theme.palette.primary.border}`,
            }
        },
        '& .MuiInputBase-root': {
            height: theme.spacing(5),
            border: '1px solid grey',
            borderRadius: theme.spacing(3),
            height: '40px'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.border,
            border: 'none'
        },
        '& .MuiFormLabel-root': {
            top: '-6px'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'white'
        },
        '& .MuiInputBase-input': {
            position: 'relative',
            bottom: theme.spacing(1),
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: 'white'
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: 'white',
            "& span": {
                "& svg": {
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')" // your svg icon path here
                    }
                }
            }
        },
    },
    dropdownMenu: {
        width: '20%',
        height: '40px',
        border: "1px solid grey",
        borderRadius: 24,
        '& .MuiSelect-root': {
            display: 'flex',
            marginLeft: '10px',
            width: '100%',
            textAlign: 'center',
        },
        '& .MuiInputBase-root': {
            width: '98%',
            height: '40px',
            borderRadius: '28px',
        },
        '& .MuiInput-underline:after': {
            position: 'relative',
            zIndex: -1
        },
        '& .MuiInput-underline:before': {
            position: 'relative',
            zIndex: -1
        },
        '& .MuiSelect-icon': {
            color: 'white',
            "& path": {
                d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')" // your svg icon path here
            }
        }
    },
    selectMenuesContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
        gap: '50px'
    },
    checkbox: {
        marginRight: 8
    }
}))

const SelectReportingCompanyAndType = ({
    setFilterByType,
    setFilterByCompany,
    isActionClicked
}) => {
    const styles = useStyles();
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

    const dropDownValues = useState(reportNames);
    const defaultValueReportingType = 'Select Reporting Type';
    const [defaultName, setDefaultName] = useState([defaultValueReportingType]);
    const [selectAll, setSelectAll] = useState(false);

    const icon = <CheckBoxOutlineBlankIcon />;
    const checkedIcon = <CheckBoxIcon />;
    const [value, setValue] = useState([]);

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/admin/reporting-companies`)
            .then((res) =>{
                setAutoCompleteOptions(res.companies.map(el => {return {name: el.name, id: el.id}}))
            })
    }, [isActionClicked])

    const handleChange = (e) => {
        const selectedTypeValues = e.target.value;
        const allValues = dropDownValues[0].map((el) => el.name);

        if (selectedTypeValues.includes('Select All') && !selectAll) {
            // If "Select All" is selected, set all values
            setSelectAll(true);
            setDefaultName(allValues);
        } else if (selectedTypeValues.includes('Select All') && selectAll) {
            // If "Select All" is unselected, set default value
            setSelectAll(false);
            setDefaultName([defaultValueReportingType]);
        } else {
            // If  any other option is selected
            setSelectAll(false);
            if (!!selectedTypeValues.length) {
                setDefaultName(selectedTypeValues);
            } else {
                setDefaultName([defaultValueReportingType]);
            }
        }

        if (allValues.length === (selectedTypeValues.slice(1, selectedTypeValues.length)).length) {
            setSelectAll(!selectAll);
        }

    };

    const renderHandler = (selected, defaultName) => {
        if (defaultName.length > 1) {
            return selected.filter(el => el !== defaultValueReportingType).join(", ");
        } else {
            return selected.join(", ");
        }
    };

    const handleChangeAutoComplete = (event, newValue) => {
        const isSelectAllClicked = newValue.find((option) => option.all);
        const changedValue = value.length === autoCompleteOptions.length ? [] : autoCompleteOptions;

        if (isSelectAllClicked) {
            setFilterByCompany(changedValue);
            setValue(changedValue);
            return;
        }

        setFilterByCompany(newValue);
        setValue(newValue);
    };

    const filteredOptions = (options, params) => {
        // inject the Select All option
        const filter = createFilterOptions();
        const filtered = filter(options, params);
        return [{ name: 'Select All', all: true }, ...filtered];
    };

    const getFilteredReportingCompaies = (selectedReportingTypes) => {
        let reportingTypeIds = selectedReportingTypes.map(reportingTypeName => REPORT_TYPE_ID_BY_NAME[reportingTypeName]);
        getData(process.env.REACT_APP_SERVER_HOST + `/api/admin/reporting-companies?filterByType=${reportingTypeIds}`)
            .then(res => {
                setAutoCompleteOptions(res.companies.map(el => { return { name: el.name, id: el.id } }));
                setValue([]);
            })
            .catch(err => {
                console.error(err)
            });
    };

    useEffect(() => {
        let selectedReportingTypes = defaultName.filter(el => el !== defaultValueReportingType);
        setFilterByType(selectedReportingTypes);
        setFilterByCompany([]);
        getFilteredReportingCompaies(selectedReportingTypes);
    }, [defaultName]);


    return (
        <div className={styles.selectMenuesContainer}>
            <div className={styles.dropdownMenu}>
                <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={defaultName}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => {
                        return (
                            <BrandPopover
                                popoverData={
                                    selected.length > 1 ?
                                        defaultName.filter(el => el !== defaultValueReportingType)
                                        :
                                        defaultName[0]
                                }
                                popoverDataFormated={renderHandler(selected, defaultName)}
                            />
                        )

                    }}
                    displayEmpty={true}
                >
                    <MenuItem onClick={() => setSelectAll(!selectAll)} value={'Select All'} >
                        <Checkbox color={'primary'} checked={selectAll} />
                        <ListItemText primary={'Select All'} />
                    </MenuItem>
                    {dropDownValues[0].map((company) => (
                        <MenuItem key={company.name} value={company.name}>
                            <Checkbox color={'primary'} checked={defaultName.indexOf(company.name) > -1} />
                            <ListItemText primary={company.name} />
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <Autocomplete
                multiple
                className={styles.checkboxContainer}
                value={value}
                filterOptions={(options, params) => filteredOptions(options, params)}
                onChange={(event, newValue) => handleChangeAutoComplete(event, newValue)}
                renderTags={() => null}
                options={autoCompleteOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                    <>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            color="primary"
                            checked={
                                option.all ?
                                    !!(value.length === autoCompleteOptions.length)
                                    :
                                    selected
                            }
                        />
                        {option.name}
                    </>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Search for Reporting Company"
                    />
                )}
            />
        </div>
    )

}

export default SelectReportingCompanyAndType;