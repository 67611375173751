import { Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import RecordLogin from '../../assets/RecordLogin.png';
import recordLogo from '../../assets/recordLogo.svg'
import React, { useState } from 'react';
import { useStoreContext } from '../../store/Store.js';
import { useEffect } from 'react';
import clsx from 'clsx'
import Header from '../HomePage/Header';
import Footer from '../HomePage/Footer';
import { Link } from "react-router-dom";
import HomePageMobileMenu from '../HomePage/HomePageMobileMenu';
import CookiePreferencesTab from '../HomePage/CookiePreferencesTab';

const useStyles = makeStyles((theme) => ({
    container: {
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        background: theme.palette.background.dark,
        overflow: 'overlay',
        height: '100%',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a:hover': {
            color: theme.palette.primary.main
        }
    },
    authLayoutPaper: {
        display: 'flex',
        justifyContent: 'end',
        background: 'inherit',
        boxShadow: 'none',
        padding: theme.spacing(4),
        paddingRight: '10%',
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            justifyContent: 'center',
        }
    },
    layout: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.dark,
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            backgroundSize: 'auto 100%',
        }
    },
    login: {

    },
    authPageLayout: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        textAlign: 'center',
        margin: 'auto',
        width: '100%',
        alignItems: 'center',
        zIndex: 0,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    logo: {
        maxWidth: '201px',
        height: '56px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '180px',
            height: '45px',
        }
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '40%',
    },
    moto: {
        textAlign: 'center',
        fontSize: '20px',
        width: '239px',
        height: '24px',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px'
        }
    },
    hiddenPageProps: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
    },
    socialMedias: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '50px',
        fontSize: '16px',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
    },
}));


export default function AuthPage({ hidePageProps, children }) {
    const styles = useStyles();
    const [state] = useStoreContext();
    const [page, setPage] = useState(undefined);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 960);

    const updateScreenWidth = () => {
        setIsMobileView(window.innerWidth < 960);
    };

    useEffect(() => {
        window.addEventListener('resize', updateScreenWidth);

        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    useEffect(() => {
        setPage(state.page);
    }, [state]);

    return (
        hidePageProps ?
            <div className={styles.hiddenPageProps}>{children}</div>
            :
            <>
                <div className={styles.container}>
                    <div className={styles.layout}>
                        {
                            isMobileView ?
                                <HomePageMobileMenu />
                                :
                                <Header />
                        }
                        <div className={styles.authPageLayout}>
                            <div className={styles.logoContainer}>
                                <Link to='/home-page'>
                                    <img src={recordLogo} alt='take-record-logo' className={styles.logo}></img>
                                </Link>
                                <p className={styles.moto}>Finance in service of art.</p>
                            </div>
                            <Paper classes={{ root: clsx(styles.authLayoutPaper, styles.page) }}>
                                {children}
                            </Paper>
                        </div>
                        <Footer />
                    </div>
                </div>
                {/*<CookiePreferencesTab />*/}
            </>
    );
}