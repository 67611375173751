import React, { useState, useEffect, useMemo, useRef } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce, getData } from '../utils/FetchUtils'
import { InputAdornment, ListItem, ListItemText } from '@material-ui/core';
import { BrandInput } from '../CoreComponents/BrandInput';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { BrandButton, SecondaryBrandButton } from '../CoreComponents/BrandButton';

const useStyles = makeStyles(theme => ({
    autocomplete: {
        "& .MuiAutocomplete-clearIndicatorDirty": {
            display: 'none'
        },
        "& .MuiAutocomplete-popupIndicator": {
            display: 'none'
        },
    },
    secondaryButton: {
        color: 'white'
    }
}));

export function ManageNftAutoComplete({ nftId, label, onRemoveSelectedAccountHolder, onAddSelectedAccountHolder }) {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [focus, setFocus] = useState(false);
    const autocomplete = useRef();
    const [offset,setOffset] = useState(0);

    const [LIMIT] = useState(100);

    useEffect(() => {
        if (!inputValue.length && focus) {
            setOpen(true);
            (async () => {
                await getAccountHolders();
            })();
            return;
        }

        setOpen(inputValue.length > 0);
        (async () => {
            if (!inputValue || !isLoading) return;
                await getAccountHolders();
        })();

    }, [inputValue, focus,offset]);

    async function getAccountHolders(){
        if(!isLoading){
            setIsLoading(true);
        }
        let countAllAccountHolders = 0;
        const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-catalog-search-nft-account-holders?offset=${offset}&limit=${LIMIT}&filter=${inputValue}&nftId=${nftId}`);
        setOptions(prev => {
            countAllAccountHolders = [...prev,...data.items].length;
            return [...prev,...data.items]
        });
        
        if(data.total > countAllAccountHolders){
            setOffset(prev => prev + 100);
            return
        }
        setIsLoading(false);
    }

    const debounceMemo = useMemo(
        () => debounce(
            (event, newInputValue) => {
                setOptions([]);
            }, (event, newInputValue) => {
                setIsLoading(newInputValue.length > 0);
                setOffset(0);
                setInputValue(newInputValue);
            }, 500),
        [],
    );

    return (
        <Autocomplete
            ref={autocomplete}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            className={styles.autocomplete}
            open={open}
            onClose={() => {
                setOpen(false);
                setOptions([]);
                setOffset(0);
            }}
            onInputChange={debounceMemo}
            blurOnSelect={true}
            clearOnBlur={true}
            clearOnEscape={true}
            getOptionLabel={(option) => option?.name || ''}
            getOptionSelected={() => false}
            renderOption={(accountHolder, option) => {
                return (
                    <ListItem
                        key={accountHolder.id}
                        button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            autocomplete.current.children[0].children[1].blur()
                            setIsLoading(false);
                            setOpen(false);
                            setOptions([]);
                            setOffset(0);
                            accountHolder.nftId = nftId
                            if (accountHolder.linked) {
                                onRemoveSelectedAccountHolder(accountHolder);
                            } else {
                                onAddSelectedAccountHolder(accountHolder);
                            }
                        }}>
                        <ListItemText primary={accountHolder.name} />
                        {accountHolder.linked ?
                            <SecondaryBrandButton variant='text' className={styles.secondaryButton}>Remove</SecondaryBrandButton>
                            :
                            <BrandButton variant='text'>Add</BrandButton>
                        }
                    </ListItem>
                )
            }}
            options={options}
            loading={isLoading}
            renderInput={(params) => (
                <BrandInput
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                        startAdornment: (
                            <InputAdornment position="start"><SearchIcon style={{ color: '#707070' }} /></InputAdornment>
                        )
                    }}
                />
            )}
        />
    );
}

