import React, { useState, useRef, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, IconButton, Popover, List, ListItem, Tooltip, Button } from '@material-ui/core';
import { BrandInput } from './BrandInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStoreContext } from '../../store/Store';
import { DisabledQuarterMessage } from '../../constants/messageConstants';
import { generateYears, isCurrentQuarter } from '../utils/DateUtils';

const listItemHeight = 52.5;

const useQuarterPickerStyles = makeStyles((theme) => ({
    inputField: {
        width: theme.spacing(20),
    },
    year: {
        minWidth: theme.spacing(5),
        marginRight: theme.spacing(1),
    },

    container: {
        marginTop: '12px',
        marginBottom: '12px',
    },

    picker: {
        maxHeight: theme.spacing(4 * (listItemHeight / 8)),
        width: theme.spacing(47),
        overflowY: 'auto',
        border: `1px solid ${theme.palette.primary.border}`,
        borderRadius: '17px',
        '& .MuiList-root': {
            borderRadius: '10px',
        },

        '&::-webkit-scrollbar': {
            width: '13px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: `${theme.palette.primary.border} 0% 0% no-repeat padding-box`,
            borderRadius: '1px',

        },
        '&::-webkit-scrollbar-thumb': {
            background: `${theme.palette.primary.border} 0% 0% no-repeat padding-box`,
            borderRadius: '10px',

        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.primary.lightGray,
        },
    },

    listItem: {
        height: theme.spacing(listItemHeight / 8),
    },
    QButtons: {
        width: '60px',
        height: '33px',
        backgroundColor: theme.palette.primary.black,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.text.primary}`,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.black,
        },
        borderRadius: '17px',
        marginRight: theme.spacing(1),
        '&:disabled': {
            width: '60px',
            height: '33px',
            backgroundColor: theme.palette.primary.black,
            border: `1px solid ${theme.palette.primary.darkGray}`,
            borderRadius: '17px',
            marginRight: theme.spacing(1),
            "& .MuiButton-label": {
                color: theme.palette.primary.darkGray,
            },
        },
    },
    activeQuarter: {
        width: '60px',
        height: '33px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.black,
        '&:hover': {
            color: theme.palette.text.black,
            backgroundColor: theme.palette.primary.hover,
        },
        borderRadius: '17px',
        marginRight: theme.spacing(1),
    },
    expandMoreIcon: {
        color: theme.palette.primary.contrastText
    },
    disabledQuarterTooltipText: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.alert,
        border: `1px solid ${theme.palette.primary.lightYellow}`,
        borderRadius: '16px',
        textAlign: 'left',
        font: 'normal normal normal 16px/26px Roboto',
        padding: '12px',
    },

}));

function quarterToText(val) {
    const quarter = val % 10;
    const year = Math.round(val / 10);
    return `Q${quarter} ${year}`;
}

export const QuarterPicker = ({ $value, className, minVal, maxVal, value, onChange, onClose, iconSize = 'large', reportQuarterPicker, ...props }) => {
    const classes = useQuarterPickerStyles();
    const [state] = useStoreContext();
    const [years, setYears] = useState(generateYears(minVal || state.quarterPickerStartDate));
    const [quarters] = useState([1, 2, 3, 4]);
    const [showSelect, setShowSelect] = useState(false);
    const inputRef = useRef(null);
    const [listRef, setListRef] = useState(null);

    useEffect(() => {
        setYears(generateYears(minVal || state.quarterPickerStartDate, maxVal))
    }, [minVal, maxVal])

    const handleRef = useCallback((node) => {
        setListRef(node);
    }, []);

    return <div className={className} ref={inputRef}>
        <BrandInput
            value={quarterToText(value || $value[0])}
            // helperText={errors?.join('\n')}
            onFocusCapture={e => {
                setTimeout(() => {
                    setShowSelect(true);
                }, 0);
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle quarter visibility"
                            onClick={() => setShowSelect(true)}
                            onMouseDown={() => setShowSelect(true)}
                            edge="end"
                            className={reportQuarterPicker ? classes.expandMoreIcon : ''}
                        >
                            <ExpandMoreIcon fontSize={iconSize === 'medium' ? 'medium' : 'large'} />
                        </IconButton>
                    </InputAdornment>
                ),
                labelwidth: 70
            }}
            classes={{
                input: classes.inputField
            }}
            {...props}
        />
        <Popover
            anchorEl={inputRef.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={() => {
                // $firstFocus.set(false);
                setShowSelect(false);
                if (onClose) {
                    onClose();
                }
            }}
            open={showSelect}
            disableRestoreFocus
        >
            <List className={classes.picker} ref={handleRef}>
                <div className={classes.container}>
                    {years.map(year => {
                        return <ListItem key={year} className={classes.listItem}>
                            <span className={classes.year}>
                                {year}
                            </span>
                            {quarters.map(quarter => {
                                const qVal = year * 10 + quarter;
                                const disabled = !isCurrentQuarter(year, quarter);

                                return (
                                    <React.Fragment key={year + quarter}>
                                        {
                                            disabled ?
                                                <Tooltip title={DisabledQuarterMessage}
                                                    classes={{ tooltip: classes.disabledQuarterTooltipText }}>
                                                    <span key={quarter + year}>
                                                        <Button
                                                            onClick={() => {
                                                                if ($value) {
                                                                    $value[1](qVal);
                                                                }
                                                                setShowSelect(false);
                                                                onChange(qVal);
                                                            }}
                                                            className={(qVal === (value || $value[0])) ? classes.activeQuarter : classes.QButtons}
                                                            disabled={disabled}
                                                        >
                                                            Q{quarter}
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                :
                                                <Button
                                                    key={quarter + year}
                                                    onClick={() => {
                                                        if ($value) {
                                                            $value[1](qVal);
                                                        }
                                                        setShowSelect(false);
                                                        onChange(qVal);
                                                    }}
                                                    className={(qVal === (value || $value[0])) ? classes.activeQuarter : classes.QButtons}
                                                    disabled={disabled}
                                                >
                                                    Q{quarter}
                                                </Button>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </ListItem>
                    })}
                </div>
            </List>
        </Popover>
    </div>
}
