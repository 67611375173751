import { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    additionalIsrcsUpcsPopover: {
        maxHeight: '250px', 
        overflowY: 'auto', 
        position: 'absolute',
        zIndex: '1', 
        backgroundColor: theme.palette.background.default,
        marginTop: '15px',
        marginLeft: '0px',
        padding: '15px',
        color: theme.palette.primary.main,
        font: 'normal normal normal 16px Roboto',
    },
    additionalIsrcsUpcsNumber: {
        color: theme.palette.primary.main,
        marginLeft: '4px'
    },
    isrcsUpcsContainer: {
        display: 'flex',
        cursor: 'default'
    },
    listBulletsFontBoldProperties: {
        fontWeight: 'bold',
        marginRight: '4px'
    },
}))

export function AdditionalIsrcsUpcs({ data, text = '' }) {
    const styles = useStyles();
    const [isShown, setIsShown] = useState(false);
    const ref = useRef(null);

    const dataToShow = data.slice(1).map((item, index) => <div key={item + index}>{item}</div>);

    return (
        <div 
            className={styles.isrcsUpcsContainer}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            ref={ref}
        >
            {(isShown && data.length > 1) &&
                <div className={styles.additionalIsrcsUpcsPopover} >
                    {data.length > 1 && dataToShow}
                </div>
            }
            {text !== '' &&
                <span className={styles.listBulletsFontBoldProperties}>
                    {text}:
                </span> 
            }
            {data.length > 0 ? data[0] : 'N/A' }{' '}
            {data.length > 1 ? 
                <div className={styles.additionalIsrcsUpcsNumber}>
                    {'+' + (data.length - 1)}
                </div> 
            : ''}
        </div>
    )
}