import React, { useState, useEffect } from 'react';
import { useStoreContext } from '../../store/Store.js';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import DropZone from './DropZone';
import Summary from './Summary.js';
import XLSX from 'xlsx';
import Mapping from './Mapping.js';
import { Validation } from './Validation.js';
import { useHistory } from 'react-router-dom';
import { getData, postFormData } from '../utils/FetchUtils'
import { BrandButton, BrandNeutralButton, SecondaryBrandButton, TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton'
import { BrandLoader, BrandLoaderSpinner } from '../CoreComponents/BrandLoader'
import { ArrowBack } from '@material-ui/icons';
import recordLogo from '../../assets/recordLogo.svg';
import { BrandLink } from '../CoreComponents/BrandLink'
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { BrandModal } from '../CoreComponents/BrandModal'
import { textIsDate, textIsUpc, textIsIsrc, textIsNumber, textIsYearQuarter, isCSVFile } from '../utils/ValidationUtils'
import { QuarterPicker } from '../CoreComponents/QuarterPicker.js';
import { UploadAutoComplete } from './UploadAutoComplete.js';
import * as Papa from 'papaparse';
import Songs from './Songs.js';
import { postData } from '../utils/FetchUtils';

import { TextField } from '@material-ui/core';
import { useMessageDisplay } from '../../hooks/useMessageDisplay.js';
import { reportNames } from '../utils/ReportNamesUtils.js';
import useAbortableFetch from '../../hooks/useAbortableFetch.js';
import SongsPartialMatching from './SongsPartialMatching.js';


const useStyles = makeStyles((theme) => ({
    logo: {
        width: '116px',
        height: '32px',
        marginLeft: '1.5em',
        margin: 'auto',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    quarterPick: {
        '& .MuiButtonBase-root': {
            color: 'white',
        },
    },
    title: {
        fontSize: '30px',
    },
    goBackIcon: {
        margin: 'auto',
        display: 'flex',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    goBack: {
        height: '68px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    stepper: {
        height: '68px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 'auto',
        background: '#161616 0% 0% no-repeat padding-box',
        borderBottom: '1px solid black',
        '& .MuiStepper-horizontal': {
            width: '80%',
            marginLeft: '10em',
        },
        '& .MuiStepper-root': {
            background: '#161616 0% 0% no-repeat padding-box',
        },

        '& .MuiStepIcon-root': {
            border: '1px solid white',
            borderRadius: '50%',
            '&.MuiStepIcon-completed': {
                color: theme.palette.primary.main,
                border: 'none',
            },
            '&.MuiStepIcon-active': {
                color: theme.palette.primary.main,
                border: 'none',
            }
        }
    },
    cancel: {
        marginLeft: '80px',
        width: '148px',
        backgroundColor: theme.palette.background.grayNuance,
        '&:hover': {
            backgroundColor: theme.palette.background.grayNuance,
        }
    },
    previous: {
        backgroundColor: theme.palette.background.grayNuance,
        '&:hover': {
            backgroundColor: theme.palette.background.grayNuance,
        }
    },
    continue: {
        display: 'flex',
        marginRight: '50px',
        '& button': {
            marginRight: '10px',
            marginLeft: '10px',
            width: '148px',
        }
    },
    actions: {
        width: '100%',
        paddingTop: '1em',
        paddingBottom: '1em',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 'auto',
        background: `${theme.palette.background.grayNuance} 0% 0% no-repeat padding-box`,
        
        alignItems: 'center',
    },
    uploadedFilesTitle: {
        marginLeft: '15px',
        textOverflow: 'ellipsis',
        font: 'normal normal bold 10px / 12px Proxima Nova',
        letterSpacing: '1.5px',
        color: '#FFFFFF61',
        textTransform: 'uppercase',
        opacity: 1,
    },
    uploadedFiles: {
        marginLeft: '-15px',
    },
    select: {
        marginBottom: '20px'
    },
    uploadSelects: {
        width: '328px'
    },
    upload: {
        width: 'auto',
        paddingRight: '60px'
    },
    uploadContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: 30
    },
    progressBarContainer: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    contentContainer: {
        overflow: 'auto',
        height: '100%',
        padding: '2rem',
    },
    stepOneContainer: {
        height: '100%',
    },
    label: {
        marginBottom: 8,
        marginTop: 8
    },
    list: {
        maxHeight: '600px',
        overflow: 'auto',
        width: '410px',
    },
    loader: {
        display: 'flex',
        justifyContent: "center",
        marginTop: theme.spacing(2)
    },
    modalActions: {
        marginTop: theme.spacing(5),
        display: 'flex',
        justifyContent: "flex-end"
    },
    rootLI: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "start",
    },
    rootLIContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        alignItems: 'center',
    },
    hr: {
        border: 'none',
        borderBottom: '1px solid #393939',
        marginTop: 10,
        marginBottom: 10,
    },
    createButton: {
        color: theme.palette.text.black,
        width: '150px',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',

    },
    notesField: {
        marginTop: '10px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#FFFFFF61',
            },
            '&:hover fieldset': {
                borderColor: '#FFFFFF61',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FFFFFF61',
            },
        },
    },
    headerSectionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    errorMessage: {
        color: 'red'
    }
}));

const csvValidators = {
    'date': textIsDate,
    'upc': textIsUpc,
    'isrc': textIsIsrc,
    'number': textIsNumber,
    'yearQuarter': textIsYearQuarter,
}

function getMappingRow(workbook) {
    const re = /^[a-zA-Z]+1$/;
    const mappingRowFill = {};
    for (const [key, values] of Object.entries(workbook.Sheets.Sheet1)) {
        if (re.test(key)) {
            if (!mappingRowFill[values.v]) {
                mappingRowFill[values.v] = 1;
            }
        }
    }
    return mappingRowFill;
}

const UploadFilesList = ({ uploadedFiles, setUploadedFiles }) => {
    const styles = useStyles();

    function onSelectQuarter(index, quarter) {
        uploadedFiles[index].reportingPeriod = quarter;
        setUploadedFiles([...uploadedFiles]);
    }

    return (
        <>
            <div className={styles.uploadedFilesTitle}>Uploaded File(s)</div>
            <List className={styles.list}>
                {uploadedFiles.map((file, index) => {
                    let label = file.name;
                    if (label.length >= 25) {
                        label = label.slice(0, 25) + '...'
                    }

                    return (
                        < div key={Math.random()}>
                            <ListItem classes={{ root: styles.rootLI }}>
                                <QuarterPicker
                                    className={styles.quarterPick}
                                    label='Reporting Period'
                                    value={file.reportingPeriod}
                                    onChange={(quarter) => onSelectQuarter(index, quarter)}
                                />
                                <div className={styles.rootLIContent}>
                                    <ListItemIcon>
                                        <DescriptionOutlinedIcon style={{ color: '#FFFFFF8A' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={label}
                                    />
                                    <IconButton
                                        onClick={() => { setUploadedFiles(uploadedFiles.filter(x => x !== file)) }}
                                    >
                                        <DeleteOutlineOutlinedIcon style={{ color: '#FFFFFF8A' }}
                                        />
                                    </IconButton>
                                </div>
                            </ListItem>
                            {index !== uploadedFiles.length - 1 ?
                                < hr className={styles.hr} />
                                :
                                null
                            }
                        </div>
                    )
                })}
            </List>
        </>
    );
}

export default function ProgressBar() {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [activeStep, setActiveStep] = useState(0);
    const [csvContentAndMappings, setCsvContentAndMappings] = useState({ manualMapping: {}, csvContent: [] });
    const history = useHistory();
    const [reportType, setReportType] = useState(0);
    const [reportTypes] = useState(reportNames);
    const [reportingCompanies, setReportingCompanies] = useState('');
    const [reportingCompany, setReportingCompany] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const [loadingText, setLoadingText] = useState({ txt: '', success: true });
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [mapDone, setMapDone] = useState(false);
    const [data, setData] = useState([]);
    const [submitSpinner, setSubmitSpinner] = useState(false);
    const [submitLoadingText, setSubmitLoadingText] = useState({ txt: '', success: true });
    const [submitModal, setSubmitModal] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [validationRows, setValidationRows] = useState([]);
    const [mandatoryCellsAreEmptyOrWrongDataType, setMandatoryCellsAreEmptyOrWrongDataType] = useState(0);
    const [csvToServer, setCsvToServer] = useState({});
    const [dataMappings, setDataMappings] = useState({});
    const [steps] = useState(['Upload CSV', 'Mapping', 'Validation', 'Songs', 'Partial Matching', 'Summary']);
    const [csvDataMappings, setCsvDataMappings] = useState([]);
    const [accountHolder, setAccountHolder] = useState({});
    const [equivalentSongs, setEquivalentSongs] = useState({});
    const [tableTemplate, setTableTemplate] = useState({});
    const [mappingColumns, setMappingColumns] = useState([]);
    const [didWentBack, setDidWentBack] = useState(true);
    const [isProcessingFile, setIsProcessingFile] = useState(false);
    const [loadingSongsStep, setLoadingSongsStep] = useState(false);
    const [alternativeNamesData, setAlternativeNamesData] = useState([]);
    const [songsMapKeys, setSongsMapKeys] = useState([]);
    const [partialSongsMapKeys, setPartialSongsMapKeys] = useState([]);
    const [alternativeNamesWithSongData, setAlternativeNamesWithSongData] = useState([]);
    const [addedAlternativeNames, setAddedAlternativeNames] = useState([]);
    const [createReportModal, setCreateReportModal] = useState(false);
    const [isMapClicked, setIsMapClicked] = useState(false);
    const [showMatchingWarning, setShowMatchingWarning] = useState(false);
    const [isNewMappingsSaved, setIsNewMappingsSaved] = useState(false);
    const [retryUpload, setRetryUpload] = useState(false);
    const [invalidFileProcessed, setInvalidFileProcessed] = useState(false);
    const [notes, setNotes] = useState('');

    const [externalISRCs, setExternalISRCs] = useState({});
    const [externalUPCs, setExternalUPCs] = useState({});

    const abortableFetch = useAbortableFetch();

    const { showMessage, message, isVisible } = useMessageDisplay();

    const setStateReportingCompany = (id) => {
        setState(state => (state.selectedReportingCompany = id, { ...state }));
        setReportingCompany(id);
    }

    useEffect(() => {
        async function fetchReportingCompanies() {
            setSpinner(true);
            setShowLoadingModal(true);
            setLoadingText({ success: true, txt: 'Loading' });
            try {
                const data = await getData(process.env.REACT_APP_SERVER_HOST + "/api/admin/reporting-companies");
                const reportingCompanyItems = { 1: [], 2: [], 3: [], 4: [], 5: [] };
                for (let i = 0; i < data.companies.length; i++) {
                    const rc = data.companies[i];
                    if (reportingCompanyItems[rc.reportingType]) {
                        reportingCompanyItems[rc.reportingType].push({ name: rc.name, id: rc.id })
                    } else {
                        reportingCompanyItems[rc.reportingType] = [{ name: rc.name, id: rc.id }]
                    }
                };

                for (let i = 1; i < Object.keys(reportingCompanyItems).length; i++) {
                    reportingCompanyItems[i] = reportingCompanyItems[i].sort((a, b) => a.name > b.name ? 1 : -1);
                }

                setReportingCompanies(reportingCompanyItems);
                setSpinner(false);
                setShowLoadingModal(false);
            } catch (err) {
                setSpinner(false);
                setLoadingText({ success: false, txt: 'There was an error, please contact an admin' });
                return;
            }
        }
        fetchReportingCompanies();

        getData(process.env.REACT_APP_SERVER_HOST + "/api/admin/mappings")
            .then(data => {
                const tempTableTemplate = {};

                data.forEach(x => {
                    if (tempTableTemplate[x.reportType]) {
                        if (!tempTableTemplate[x.reportType].includes(x.name)) {
                            tempTableTemplate[x.reportType].push(x.name);
                        }
                    } else {
                        tempTableTemplate[x.reportType] = [x.name];
                    }
                    x.validatorName = x.validations.split('|')
                    x.required = x.validations.includes('required');
                    x.validations = x.validations
                        .split('|')
                        .filter(v => v && v !== 'required')
                        .map(v => csvValidators[v])
                });
                setTableTemplate(tempTableTemplate);
                setCsvDataMappings(data);
            });

        setState(state => (state.hideNavigation = true, state.disableRootPadding = true, { ...state }));
        return () => { setState(state => (state.hideNavigation = false, state.disableRootPadding = false, { ...state })) };
    }, [uploadedFiles])

    useEffect(() => {
        if (activeStep === 1 && uploadedFiles.length && !didWentBack) {
            setState(state => (state.uploadReportFileName = `${uploadedFiles[0].name} / ${uploadedFiles[0].reportingPeriod}`, { ...state }));
            parseCsvFile([uploadedFiles[0]]);
        }
    }, [activeStep, didWentBack]);

    useEffect(() => {
        if (reportingCompany && reportType && Object.keys(tableTemplate)) {
            const mappings = {};
            for (let columnName of tableTemplate[reportType]) {
                mappings[columnName] = csvDataMappings.find(x => x.name === columnName && x.reportingCompanyId === reportingCompany);
            }
            setDataMappings(mappings);
        }
    }, [reportingCompany, uploadedFiles]);

    useEffect(() => {
        if (uploadedFiles.length && reportType && reportingCompany && accountHolder.id) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

        if (activeStep === 0) {
            setCsvContentAndMappings({
                manualMapping: {},
                csvContent: []
            });
        }
    }, [uploadedFiles, reportType, reportingCompany, accountHolder]);

    const handleNext = () => {
        const nextStep = activeStep + 1;

        if (nextStep >= steps.length || nextStep === 1) {
            if (!didWentBack) {
                setUploadedFiles(uploadedFiles.slice(1));
            }

            setActiveStep(1);
            setIsMapClicked(false);
        } else {
            // if (nextStep === 5) {
            //     addAlternativeSongNames();
            // }

            setActiveStep(nextStep);
        }

        setDidWentBack(false);
    }

    const addAlternativeSongNames = async () => {
        if (alternativeNamesWithSongData.length > 0) {
            let alternativeNamesWithSongDataToUpdate = [];

            alternativeNamesWithSongData.forEach(altName => {
                const findSong = addedAlternativeNames.filter(song => song.songId === altName.songId);
                if (findSong[0] && findSong[0].songId) {
                    alternativeNamesWithSongDataToUpdate.push(1);
                } else {
                    alternativeNamesWithSongDataToUpdate.push(0);
                }
            });

            if (alternativeNamesWithSongDataToUpdate.includes(0) || alternativeNamesWithSongData.length != addedAlternativeNames.length) {

                // postData(process.env.REACT_APP_SERVER_HOST + `/api/song/add-alternative-song-names`, { alternativeNames: alternativeNamesWithSongData })
                //     .then(data => {
                //         setState(state => (state.initialLoad = true, { ...state }));
                //         setState(state => (state.alternativeNamesAdded = true, { ...state }));
                //         setAddedAlternativeNames(alternativeNamesWithSongData);

                //     })
                //     .catch(err => {
                //         console.log(err);
                //     });
            }
        }

    };

    const refreshStatesHandler = () => {
        setState(state => (state.songsMap = {}, { ...state }));
        setState(state => (state.initialLoad = false, { ...state }));
        setState(state => (state.findSongsInDb = true, { ...state }));
    };

    const SubmitLoadingDialog = ({ loadingSongsStep, textContent = 'Loading' }) => {
        return (
            <div>
                {loadingSongsStep ?
                    <Typography variant='h5' component='div'>
                        {textContent}
                    </Typography>
                    :
                    <>
                        <Typography variant='h5' component='div' color={submitLoadingText.success ? 'primary' : 'secondary'}> {submitLoadingText.success ? 'Success' : 'Error'}</Typography>
                        <Typography variant='h6' component='div'> {submitLoadingText.txt}</Typography>
                    </>
                }
                {loadingSongsStep ?
                    <div className={styles.loader}>
                        <BrandLoader />
                    </div>
                    :
                    <div className={styles.modalActions}>
                        {submitLoadingText.success ?
                            <TempBrandButton onClick={() => {
                                setSubmitModal(false);

                                if (uploadedFiles.length > 1) {
                                    handleNext();
                                } else {
                                    history.push('/portfolio');
                                }
                            }}>
                                OK
                            </TempBrandButton>
                            :
                            <TempSecondaryBrandButton
                                onClick={() => {
                                    setSubmitModal(false);
                                    setActiveStep(1);
                                    setDidWentBack(true)
                                }}>
                                Retry
                            </TempSecondaryBrandButton>
                        }
                    </div>
                }
            </div>
        );
    }

    const prepareCsvData = () => {
        return new Promise((resolve, reject) => {
            const payloadData = { ...csvToServer }; // JSON.parse(JSON.stringify(csvToServer));

            const csvDataToServer = [];
            for (let key in equivalentSongs) {
                const song = equivalentSongs[key];
                let songRevenue = 0;
                const csvData = { ...csvToServer.data[song.rows[0]] };
                const columns = Object.entries(csvData);
                let keyTitle = '';
                let keyArtist = '';
                let upc = '';
                let isrc = '';
                let externalId = '';

                for (let col = 0; col < columns.length; col++) {
                    const [cellColumnName, cellData] = columns[col];
                    if (cellColumnName === 'title') {
                        keyTitle = cellData;
                    } else if (cellColumnName === 'artist') {
                        keyArtist = cellData === "No Data" ? '' : cellData;
                    } else if (cellColumnName === 'isrc') {
                        isrc = cellData === "No Data" ? '' : cellData;
                    } else if (cellColumnName === 'upc') {
                        upc = cellData === "No Data" ? '' : cellData;
                    } else if (cellColumnName === 'externalId') {
                        externalId = cellData === "No Data" ? '' : cellData;
                    }

                    if ((cellData !== 0 && !cellData) || cellData === "No Data" || (cellData && typeof cellData === typeof '' && cellData.search('NaN') > 0)) {
                        csvData[cellColumnName] = null;
                    }
                };

                for (const rowIndex of song.rows) {
                    const rev = parseFloat(csvToServer.data[rowIndex].revenue)
                    songRevenue += isNaN(rev) ? 0 : rev;
                };

                const songsMapKey = `${keyTitle}-${keyArtist}-${upc}-${isrc}-${externalId}`;
                if (state.songsMap[songsMapKey] && state.songsMap[songsMapKey].foundSongId) {
                    csvData.songId = state.songsMap[songsMapKey].foundSongId;
                } else if (state.partialSongsMap[songsMapKey] && state.partialSongsMap[songsMapKey].foundSongId) {
                    csvData.songId = state.partialSongsMap[songsMapKey].foundSongId;
                }

                csvData.revenue = songRevenue;
                csvDataToServer.push(csvData);
            }
            payloadData.data = csvDataToServer;
            payloadData.mappedColumns = csvContentAndMappings.manualMapping;

            resolve(payloadData);
        })
    }

    const handleSubmit = async (e) => {
        setCreateReportModal(false);
        setSubmitSpinner(true);
        setSubmitModal(true);
        setSubmitLoadingText({ success: true, txt: '' });

        prepareCsvData()
            .then(async data => {
                try {
                    const formData = new FormData();
                    formData.append('csvData', JSON.stringify(data));
                    const file = uploadedFiles[0];
                    formData.append('csvFile', file, file.name);

                    postFormData(process.env.REACT_APP_SERVER_HOST + "/api/report/", formData)
                        .then(data => {
                            setSubmitLoadingText({ success: true, txt: 'Data was successfully ingested' });
                            setSubmitSpinner(false);
                            setState(state => (state.songsMap = {}, { ...state }));
                            setState(state => (state.initialLoad = false, { ...state }));
                            setState(state => (state.findSongsInDb = true, { ...state }));
                            setState(state => (state.alternativeNamesAdded = false, { ...state }));
                            setRetryUpload(false);
                            setCsvContentAndMappings({
                                manualMapping: {},
                                csvContent: []
                            });
                        }, error => {
                            if (error.message === 'File size too large.') {
                                setSubmitLoadingText({ success: false, txt: 'File size too large.' });
                            } else {
                                setSubmitLoadingText({ success: false, txt: `Data was not ingested. Please try uploading again. ${error.message != 'Server error!' ? `</br> Error: ${error.message}` : ''}` });
                            }
                            setSubmitSpinner(false);
                            setRetryUpload(true);
                        })

                    await abortableFetch('POST', '/api/song/external-data', {
                        body: {
                            upcs: externalUPCs,
                            isrcs: externalISRCs
                        }
                    });
                } catch (err) {
                    setRetryUpload(true);
                    setSubmitLoadingText({ success: false, txt: 'Data was not ingested. Please try uploading again' });
                    setSubmitSpinner(false);
                }
            })

    }

    const LoadingDialog = () => {
        return (
            <div>
                <Typography variant='h5' component='div' color='primary'> {loadingText.txt}</Typography>
                {spinner ?
                    <div className={styles.loader}>
                        <BrandLoaderSpinner />
                    </div>
                    :
                    loadingText.success ?
                        null
                        :
                        <div className={styles.loader}>
                            <TempSecondaryBrandButton onClick={() => {
                                setShowLoadingModal(false);
                                history.push("/portfolio")
                            }}
                            >
                                Close
                            </TempSecondaryBrandButton>
                        </div>
                }
            </div>
        );
    }

    const handleBack = () => {
        setIsMapClicked(false);
        setState(state => (state.changedIndex = [], { ...state }));
        setDidWentBack(activeStep > 0);
        if (activeStep === 0 || activeStep - 1 < 0) {
            setActiveStep(0);
            return;
        } else setActiveStep(activeStep - 1);
    };

    const handleDrop = (files) => {
        const quarterPick = Number(new Date().getFullYear() + '1');

        const foundNotCSV = files.some((file) => !isCSVFile(file));

        if (foundNotCSV) {
            showMessage('Uploaded files are filtered and only CSV files are shown.');
        }

        const csvFiles = files
            .filter(isCSVFile)
            .map((file) => {
                file.reportingPeriod = quarterPick;
                return file;
            });

        setUploadedFiles([...uploadedFiles, ...csvFiles]);
    };

    const parseCsvFile = (file) => {
        setIsProcessingFile(true);
        Papa.parse(file[0], {
            header: true,
            worker: true,
            complete: function (results) {
                const mappingRowFill = {};
                let tempMappingColumns = {};
                results.meta.fields.forEach(x => mappingRowFill[x] = 1);
                let resultsData = [];
                const resultsDataKeys = Object.keys(results.data[results.data.length - 1]);

                if (resultsDataKeys.length < results.meta.fields.length || (resultsDataKeys.length === 1 && results.data[0][resultsDataKeys[0]] === '')) {
                    resultsData = results.data.slice(0, -1);
                    //for some reason last row of csv is always {key:""}, so i'm deleting it
                } else {
                    resultsData = results.data;
                }

                setCsvContentAndMappings((prevState) => ({
                    ...prevState,
                    csvContent: resultsData,
                    mappingRow: mappingRowFill,
                    reportingPeriod: file.reportingPeriod,
                }));

                const partialData = resultsData.slice(0, 5);
                for (const fileIterator of partialData) {
                    for (const key in fileIterator) {
                        if (!tempMappingColumns[key]) {
                            tempMappingColumns[key] = [];
                        }
                        tempMappingColumns[key].push(fileIterator[key]);
                    }
                }
                setMappingColumns(tempMappingColumns);
            }
        });
    };

    //TODO: test when we start working with excel files
    const parseExcelFile = (files) => {
        let csvArrs = [];
        let tempMappingColumns = {};
        let loadedFiles = 0;

        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onload = function (e) {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const mappingRowFill = getMappingRow(workbook);
                workbook.SheetNames.forEach(sheetName => {
                    const fileAsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    const partialData = fileAsArray.slice(0, 5);
                    for (const fileIterator of partialData) {
                        for (const key in fileIterator) {
                            if (!tempMappingColumns[key]) {
                                tempMappingColumns[key] = [];
                            }
                            tempMappingColumns[key].push(fileIterator[key]);
                        }
                    }
                    csvArrs = csvArrs.concat(fileAsArray);
                    loadedFiles++;
                    if (loadedFiles === files.length) {
                        setCsvContentAndMappings(csvContentAndMappings => (csvContentAndMappings.csvContent = csvArrs, csvContentAndMappings.mappingRow = mappingRowFill, csvContentAndMappings.reportingPeriod = file.reportingPeriod, { ...csvContentAndMappings }));
                        for (const key in tempMappingColumns) {
                            tempMappingColumns[key] = tempMappingColumns[key].slice(0, 5);
                        }
                        setMappingColumns(tempMappingColumns);
                    }
                });
            };

            const readArr = (obj) => {
                reader.readAsArrayBuffer(obj);
            }
            readArr(file);
        }
    };

    const onReportTypeChange = (newReportType) => { 
        setReportType(newReportType); 
        setReportingCompany(0);
    }

    const stepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div key={step} className={styles.stepOneContainer}>
                        <BrandModal
                            open={showLoadingModal}
                            onClose={() => setShowLoadingModal(false)}
                            notClosable={true}
                        >
                            <LoadingDialog />
                        </BrandModal>
                        <div className={styles.headerSectionWrapper}>
                            <div className={styles.title}>1. Upload CSV</div>
                            { isVisible && (
                                <div className={styles.errorMessage}>{message}</div>
                            ) } 
                        </div>
                        <div className={styles.uploadContainer}>
                            <div className={styles.uploadSelects}>
                                <div className={styles.accountHolder}>
                                    <UploadAutoComplete
                                        heading='Account holder'
                                        placeholder='Select Account Holder'
                                        onSelectedAutoCompleteOption={setAccountHolder}
                                        selectedAutoCompleteOption={accountHolder}
                                    />
                                </div>
                                <UploadAutoComplete
                                    heading='Report Type'
                                    placeholder='Select Report Type'
                                    onSelectedAutoCompleteOption={onReportTypeChange}
                                    selectedAutoCompleteOption={reportType}
                                    autoCompleteOptions={reportTypes}
                                    autoCompleteOptionsString={JSON.stringify(reportTypes)}
                                />
                                {reportType ?
                                    <UploadAutoComplete
                                        key={reportType}
                                        heading='Reporting Company'
                                        placeholder='Select Reporting Company'
                                        onSelectedAutoCompleteOption={setStateReportingCompany}
                                        selectedAutoCompleteOption={reportingCompany}
                                        autoCompleteOptions={reportingCompanies[reportType]}
                                        autoCompleteOptionsString={JSON.stringify(reportingCompanies[reportType])}
                                    />
                                    : null}
                                <div className={styles.notesField}>
                                    <div>Notes</div>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        minRows={4}
                                        variant="outlined"
                                        fullWidth={true}
                                        onChange={(e) => setNotes(e.target.value)}
                                        maxRows={15}
                                        value={notes}
                                    />
                                </div>
                            </div>

                            <div className={styles.upload}>
                                <DropZone
                                    handleDrop={handleDrop}
                                    acceptedFormats={{'text/csv': ['.csv']}}>
                                </DropZone>
                            </div>

                            <div className={styles.uploadedFiles}>
                                <UploadFilesList
                                    uploadedFiles={uploadedFiles}
                                    setUploadedFiles={setUploadedFiles}
                                />
                            </div>
                        </div>

                    </div>
                );
            case 1:
                return (
                    <Mapping
                        reportingCompany={reportingCompany}
                        reportType={reportType}
                        csvContentAndMappings={csvContentAndMappings}
                        setCsvContentAndMappings={setCsvContentAndMappings}
                        setMapDone={setMapDone}
                        setValidationRows={setValidationRows}
                        dataMappings={dataMappings}
                        mappingTemplate={tableTemplate[reportType]}
                        mappingColumns={mappingColumns}
                        isProcessingFile={isProcessingFile}
                        setIsProcessingFile={setIsProcessingFile}
                        isMapClicked={isMapClicked}
                        setIsMapClicked={setIsMapClicked}
                        handleNext={handleNext}
                        isNewMappingsSaved={isNewMappingsSaved}
                        setIsNewMappingsSaved={setIsNewMappingsSaved}
                        didWentBack={didWentBack}
                        validationRows={validationRows}
                        retryUpload={retryUpload}
                        setDataMappings={setDataMappings}
                    />
                );
            case 2:
                return (
                    <Validation
                        validationRows={validationRows}
                        setValidationRows={setValidationRows}
                        mandatoryCellsAreEmptyOrWrongDataType={mandatoryCellsAreEmptyOrWrongDataType}
                        setMandatoryCellsAreEmptyOrWrongDataType={setMandatoryCellsAreEmptyOrWrongDataType}
                        dataMappings={dataMappings}
                        mappingTemplate={tableTemplate[reportType]}
                        retryUpload={retryUpload}
                    />
                );
            case 3:
                return (
                    <div key={step}>
                        <BrandModal
                            open={loadingSongsStep}
                            onClose={() => setLoadingSongsStep(false)}
                            notClosable={true}
                        >
                            <SubmitLoadingDialog
                                loadingSongsStep={loadingSongsStep}
                                textContent='Might take longer than expected'
                            />
                        </BrandModal>
                        <Songs
                            validationRows={validationRows}
                            setLoadingSongsStep={setLoadingSongsStep}
                            mappingTemplate={tableTemplate[reportType]}
                            songsMapKeys={songsMapKeys}
                            setSongsMapKeys={setSongsMapKeys}
                            setPartialSongsMapKeys={setPartialSongsMapKeys}
                            setAlternativeNamesWithSongData={setAlternativeNamesWithSongData}
                            reportingCompany={reportingCompany}
                            accountHolder={accountHolder}
                            setExternalISRCs={setExternalISRCs}
                            setExternalUPCs={setExternalUPCs}
                        />
                    </div>
                );
            case 4:
                return (
                    <div key={step}>
                        <BrandModal
                            open={loadingSongsStep}
                            onClose={() => setLoadingSongsStep(false)}
                            notClosable={true}
                        >
                            <SubmitLoadingDialog
                                loadingSongsStep={loadingSongsStep}
                                textContent='Might take longer than expected'
                            />
                        </BrandModal>
                        <SongsPartialMatching
                            partialSongsMapKeys={partialSongsMapKeys}
                            setExternalISRCs={setExternalISRCs}
                            setExternalUPCs={setExternalUPCs}
                            accountHolder={accountHolder}
                            showMatchingWarning={showMatchingWarning}
                            setShowMatchingWarning={setShowMatchingWarning}
                            handleNext={handleNext}
                        />
                    </div>
                );
            case 5:
                return (
                    <div key={step}>
                        <BrandModal
                            open={submitModal}
                            onClose={() => setSubmitModal(false)}
                            notClosable={true}
                        >
                            <SubmitLoadingDialog loadingSongsStep={submitSpinner} />
                        </BrandModal>
                        <Summary
                            handleBack={handleBack}
                            reportType={reportType}
                            reportingCompany={reportingCompany}
                            reportingCompanies={reportingCompanies}
                            data={data}
                            setData={setData}
                            validationRows={validationRows}
                            setCsvToServer={setCsvToServer}
                            csvToServer={csvToServer}
                            dataMappings={dataMappings}
                            mappingTemplate={tableTemplate[reportType]}
                            accountHolderId={accountHolder.id}
                            reportingPeriod={uploadedFiles[0].reportingPeriod}
                            equivalentSongs={equivalentSongs}
                            setEquivalentSongs={setEquivalentSongs}
                            alternativeNamesData={alternativeNamesData}
                            setAlternativeNamesData={setAlternativeNamesData}
                            createReportModal={createReportModal}
                            setCreateReportModal={setCreateReportModal}
                            setActiveStep={setActiveStep}
                            handleSubmit={handleSubmit}
                            notes={notes}
                        />
                    </div>
                );
            default:
                return (<h1>Unknown step</h1>);
        }
    };

    return (
        <div className={styles.progressBarContainer}>
            <div className={styles.stepper}>
                <div className={styles.goBack}>
                    <BrandLink to='/portfolio' onClick={refreshStatesHandler} className={styles.goBackIcon}>
                        <ArrowBack fontSize='large' style={{ color: '#FFFFFF' }} />
                        <img src={recordLogo} alt='' className={styles.logo}></img>
                    </BrandLink>
                </div>
                <Stepper activeStep={activeStep}>
                    {steps.map((step) =>
                        <Step key={step}>
                            <StepLabel>{step}</StepLabel>
                        </Step>
                    )}
                </Stepper>
            </div>
            <div className={styles.contentContainer}>
                {stepContent(activeStep)}
            </div>
            <div className={styles.actions}>
                <TempSecondaryBrandButton
                    className={styles.cancel}
                    onClick={() => {
                        refreshStatesHandler();
                        history.push('/portfolio');
                    }}
                >
                    Cancel
                </TempSecondaryBrandButton>
                {activeStep === 0 ?
                    <div className={styles.continue}>
                        <TempBrandButton
                            disabled={disabled}
                            onClick={handleNext}
                            >
                            Continue
                        </TempBrandButton>
                    </div>
                    :
                    activeStep === 1 ?
                        <div className={styles.continue}>
                            <TempSecondaryBrandButton 
                                className={styles.previous}
                                onClick={() => {
                                setCsvContentAndMappings(csvContentAndMappings => (csvContentAndMappings.csvContent = [], { ...csvContentAndMappings }));
                                handleBack();
                                refreshStatesHandler();
                            }} startIcon={<ArrowBackIosIcon />}>
                                Previous
                            </TempSecondaryBrandButton>
                            <TempBrandButton
                                disabled={!mapDone}
                                onClick={() => setIsMapClicked(true)}
                            >
                                Continue
                            </TempBrandButton>
                        </div>
                        :
                        activeStep === 2 ?
                            <div className={styles.continue}>
                                <TempSecondaryBrandButton 
                                    className={styles.previous}
                                    onClick={() => handleBack()} 
                                    startIcon={<ArrowBackIosIcon />}>
                                    Previous
                                </TempSecondaryBrandButton>
                                <TempBrandButton disabled={mandatoryCellsAreEmptyOrWrongDataType > 0} onClick={handleNext}>
                                    Continue
                                </TempBrandButton>
                            </div>
                            :
                            activeStep === 3 ?
                                <div className={styles.continue}>
                                    <TempSecondaryBrandButton
                                        className={styles.previous}
                                        onClick={() => {
                                            handleBack();
                                            refreshStatesHandler();
                                        }}
                                        startIcon={<ArrowBackIosIcon />}
                                    >
                                        Previous
                                    </TempSecondaryBrandButton>
                                    <TempBrandButton disabled={loadingSongsStep} onClick={handleNext}>
                                        Continue
                                    </TempBrandButton>
                                </div>
                                :
                                activeStep === 4 ?
                                    <div className={styles.continue}>
                                        <TempSecondaryBrandButton
                                            className={styles.previous}
                                            onClick={() => {
                                                handleBack();
                                            }}
                                            startIcon={<ArrowBackIosIcon />}
                                        >
                                            Previous
                                        </TempSecondaryBrandButton>
                                        <TempBrandButton disabled={loadingSongsStep} onClick={() => {
                                            if (partialSongsMapKeys?.length > 0)
                                                setShowMatchingWarning(true);
                                            else
                                                handleNext();
                                        }}>
                                            Continue
                                        </TempBrandButton>
                                    </div>
                                    :
                                    activeStep === 5 ?
                                        <div className={styles.continue}>
                                            <TempSecondaryBrandButton 
                                                className={styles.previous}
                                                onClick={() => handleBack()} 
                                                startIcon={<ArrowBackIosIcon />}>
                                                Previous
                                            </TempSecondaryBrandButton>
                                            <TempBrandButton
                                                onClick={() => setCreateReportModal(true)}
                                            >
                                                Create Report
                                            </TempBrandButton>
                                        </div>
                                        :
                                        'Something went wrong!'}
            </div>
        </div>
    );
}