import React, { useState, useEffect } from 'react';
import { postData } from '../utils/FetchUtils'
import { BrandButton, TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton'
import { BrandInput } from '../CoreComponents/BrandInput'
import { BrandLoaderDots } from '../CoreComponents/BrandLoader'
import { Typography } from '@material-ui/core';
import { BrandLink } from '../CoreComponents/BrandLink'
import { BrandAlert } from '../CoreComponents/BrandAlert'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { textIsEmail, textIsEmpty } from '../utils/ValidationUtils';
import { useHistory } from 'react-router-dom';
import { useStoreContext } from '../../store/Store.js';
require("dotenv").config();


const dataValidators = {
    validateEmail: (value) => {
        const errors = []
        if (!textIsEmail(value)) {
            errors.push('Invalid mail.')
        }
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
}


const useStyles = makeStyles((theme) => ({
    submitBtn: {
        width: '100%',
        height: '48px',
        color: 'black'
    },
    input: {
        marginTop: 25,
        marginBottom: 30
    },
    title: {
        padding: 15
    }
}));

export default function ForgotPasswordPage() {
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const email = useState('');
    const emailPassed = useState(false);
    const [alert, setAlert] = useState('');
    const history = useHistory();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [state, setState] = useStoreContext();

    async function handleForgottenPasswordSubmission(e) {
        e.preventDefault();
        setLoading(true)
        await postData(process.env.REACT_APP_SERVER_HOST + '/api/user/forgotten-password', { email: email[0] })
        setSuccess(true)
        setLoading(false)
    }

    useEffect(() => {
        setAlert('');
    }, [email[0]]);

    useEffect(() => {
        setState(state => (state.page = undefined, { ...state }));
    }, []);

    useEffect(() => {
        if (!emailPassed[0]) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [email[0]])

    return (
        <div>
            {success ?
                <div>
                    <Typography className={styles.title} variant='subtitle2' component='div' gutterBottom>
                        Reset password instructions were sent to {email[0]}, please check your email.
                    </Typography>
                    <TempSecondaryBrandButton className={styles.submitBtn} onClick={() => history.push('/login')}>
                        Go to login page
                    </TempSecondaryBrandButton>
                </div>
                :
                <div>
                    <Typography className={styles.title} variant='h5' component='div' gutterBottom>
                        Reset password
                    </Typography>
                    <form onSubmit={handleForgottenPasswordSubmission}>
                        <div className={styles.input}>
                            <BrandInput $value={email} required validator={dataValidators.validateEmail} validatorPassed={emailPassed} placeholder='Enter email here' label='Email address' />
                            <BrandLink to='/login'>Return to login page</BrandLink>
                        </div>
                        {alert ? <BrandAlert>{alert}</BrandAlert> : null}
                        <TempBrandButton capitalize={true} disabled={submitDisabled} className={styles.submitBtn} type='submit'>
                            {loading ?
                                <BrandLoaderDots /> :
                                'Send reset instructions'}
                        </TempBrandButton>
                    </form>
                </div>
            }
        </div>
    );
}
