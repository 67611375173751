import {
    Box,
    Dialog,
    Slide,
    makeStyles
} from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { forwardRef } from "react";

const Transition = forwardRef((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

const useStyles = makeStyles(() => ({
    close: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        margin: '5px',
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));

const CustomModal = ({
    open,
    onClose,
    children,
    hideCloseIcon
}) => {
    const styles = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            maxWidth={false}
        >
            {!hideCloseIcon && (
                <HighlightOff
                    color='primary'
                    fontSize='large'
                    className={styles.close}
                    onClick={onClose}
                />
            )}
            {children}
        </Dialog>
    )
}

export default CustomModal;