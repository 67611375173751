import React, { useState, useEffect } from 'react';
import { getData, postData } from '../../utils/FetchUtils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import { BrandVerticalBarsChart } from '../../CoreComponents/BrandChart';
import clsx from 'clsx';
import AlternativeNames from './AlternativeNames';
import SelectedSongData from './SelectedSongData';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SongsRegistration from './SongsRegistration/SongsRegistration';
import { useStoreContext } from '../../../store/Store';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function setTabProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const useSongStyles = makeStyles((theme) => ({
    bar: {
        height: '720px',
        width: '1300px',
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiPaper-elevation4': {
            boxShadow: 'none !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main
        },
        '& .MuiBox-root': {
            padding: 0
        },
        "& .MuiButtonBase-root": {
            color: 'white',
            opacity: 1,
            font: 'normal normal bold 16px/20px Roboto',
            letterSpacing: '1.6px'
        },
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: 'center',
            gap: '50px'
        },
        marginTop: '5px'
    },
    chart: {
        marginTop: '30px',
        width: '100%',
        overflow: 'auto'
    },
    tabContainer: {
        margin: theme.spacing(2),
        marginTop: 30,
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiChip-root': {
            marginTop: theme.spacing(1),
        }
    },
    tabPanel: {
        background: theme.palette.background.default,
        padding: theme.spacing(2),
        '& .MuiTableContainer-root': {
            padding: '0px 28px 0px 28px'
        },
        '& .MuiTableCell-root': {
            padding: '9px 16px'
        },
        '& .MuiTableCell-head': {
            padding: '16px'
        },
        '& .MuiTableRow-head': {
            position: 'sticky',
            top: 0
        },
        marginTop: '20px'
    },
    tabHeight: {
        padding: 0
    },
    title: {
        marginLeft: '28px'
    },
    close: {
        position: 'absolute',
        top: '10px',
        right: '20px',
        margin: '5px'
    },
    noChartDataMessage: {
        color: theme.palette.primary.alert,
        marginLeft: '28px'
    }
}));

function SongDetailsTabs({
    details,
    setDetails,
    onClose,
    setDisplayConnectSong,
    setSelectedSongToConnect,
    selectedSongModalRefresh,
    startQuarter,
    endQuarter,
    accountHolder,
    onChange
}) {
    const styles = useSongStyles();
    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const [state, setState] = useStoreContext();

    const CHART_BAR_PERCENTAGE_WITH_ONE_VALUE = '0.7';
    const CHART_BAR_PERCENTAGE_WITH_MORE_VALUES = '0.9';
    const USER_TYPE_USER_ACCESS = 2;
    const isAdmin = state.user.userType !== USER_TYPE_USER_ACCESS;

    let indexCounter = 0;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (value === 1) {
            getData(process.env.REACT_APP_SERVER_HOST + `/api/elastic/portfolio-song-performance?songId=${details.id}&accountHolder=${accountHolder}`)
                .then(d => {
                    setData(d);
                });
        };
    }, [value]);

    const arr = [
        "Overview",
        "Performance",
        isAdmin ? "Alternative Names" : null,
        "Song Shares"
    ].reduce((acc, label) => {
        if (label) {
            acc.push({
                label,
                tabProps: setTabProps(indexCounter),
                index: indexCounter
            });
            indexCounter++;
        }
        return acc;
    }, []);

    return (
        <div className={styles.bar}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange}>
                    {arr.map((tab) => (
                        <Tab key={tab.label} label={tab.label} {...tab.tabProps} />
                    ))}
                </Tabs>
                <HighlightOffIcon color='primary' className={styles.close} fontSize='large' onClick={() => onClose()} />
            </AppBar>
            {arr.map((tab) => (
                <TabPanel key={tab.label} value={value} index={tab.index} className={clsx(styles.tabPanel, styles.tabHeight)}>
                    {tab.label === "Overview" ? (
                        <SelectedSongData
                            details={details}
                            setDetails={setDetails}
                            onClose={onClose}
                            setDisplayConnectSong={setDisplayConnectSong}
                            setSelectedSongToConnect={setSelectedSongToConnect}
                            selectedSongModalRefresh={selectedSongModalRefresh}
                            startQuarter={startQuarter}
                            endQuarter={endQuarter}
                            accountHolder={accountHolder}
                        />
                    ) : tab.label === "Performance" ? (
                        <div className={styles.tabContainer}>
                            <Typography className={styles.title} variant='h6' component='div'>Revenue By Year</Typography>
                            <div className={styles.chart}>
                                {data && data.length > 0 ?
                                    <BrandVerticalBarsChart
                                        data={data}
                                        tooltipLabel='Earnings'
                                        color='#16AE90'
                                        width={`${data.length > 1 ? data.length * 100 : data.length * 300}px`}
                                        height='350px'
                                        barPercentage={data.length > 1 ? CHART_BAR_PERCENTAGE_WITH_MORE_VALUES : CHART_BAR_PERCENTAGE_WITH_ONE_VALUE}
                                    />
                                    :
                                    <Typography className={styles.noChartDataMessage}>No chart data to display.</Typography>
                                }
                            </div>
                        </div>
                    ) : tab.label === "Alternative Names" ? (
                        <AlternativeNames details={details} onChange={onChange} />
                    ) : tab.label === "Song Shares" ? (
                        <SongsRegistration details={details} isAdmin={isAdmin} />
                    ) : null}
                </TabPanel>
            ))}
        </div>
    );
}
export default SongDetailsTabs;