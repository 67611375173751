export function calculateNewPage(newRowsPerPageValue, currentOffsetValue) {
  let newPage = 0 + Math.floor(currentOffsetValue / newRowsPerPageValue);
  let newOffset = newPage * newRowsPerPageValue;

  return { newPage, newOffset };
};

export function handleRowsPerPageChange(event, offset, setRowsPerPage, setPage, setOffset) {
  let rowsPerPage = parseInt(event.target.value, 10);
  let { newPage, newOffset } = calculateNewPage(rowsPerPage, offset);

  setRowsPerPage(rowsPerPage);
  setPage(newPage);
  setOffset(newOffset);
};

export const EARNINGS_DEFAULT_ROWS_PER_PAGE = 5;
export const EARNINGS_ROWS_PER_PAGE_OPTIONS = [5, 10];

export const MANAGE_ACCOUNT_HOLDERS_DEFAULT_ROWS_PER_PAGE = 6;
export const MANAGE_ACCOUNT_HOLDERS_ROWS_PER_PAGE_OPTIONS = [6, 12, 18];

export const MANAGE_ARTISTS_DEFAULT_ROWS_PER_PAGE = 6;
export const MANAGE_ARTISTS_ROWS_PER_PAGE_OPTIONS = [6, 12, 18];

export const MANAGE_USERS_DEFAULT_ROWS_PER_PAGE = 6;
export const MANAGE_USERS_ROWS_PER_PAGE_OPTIONS = [6, 12, 18];

export const SONG_PORTFOLIO_DEFAULT_ROWS_PER_PAGE = 25;
export const SONG_PORTFOLIO_ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

export const SONGS_DEFAULT_ROWS_PER_PAGE = 25;
export const SONGS_ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

export const REPORTING_COMPANIES_DEFAULT_ROWS_PER_PAGE = 6;
export const REPORTING_COMPANIES_ROWS_PER_PAGE_OPTIONS = [6, 12, 18];