import { Slide, makeStyles } from "@material-ui/core"
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'justify',
        overflow: 'scroll',
        height: '550px',
        width: '777px',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        fontSize: '14px',
        lineHeight: 1.6,
        '& p,section': {
            marginLeft: 20
        },
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a:hover': {
            color: theme.palette.primary.main
        },
        '& header': {
            textAlign: 'justify',
            fontSize: '32px',
            lineHeight: '141%',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0px 20px 0px 20px'
        },
    },
}))
export default function ThermsOfUse() {
    const styles = useStyles();

    return (
        <Slide
            timeout={500}
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
        >
            <div className={styles.container}>
                <div>
                    <header><b>Terms of Use</b></header>


                    <br />

                    <i>Last Updated</i>: 03/23/2023
                    <br />
                    <br />
                    Record Financial Inc. (“Company”, “we”, “us”, or “our”) is a record management company offering a financial technology platform for alternative assets ranging from Music, NFTs, Cryptocurrency, Film, TV, Literature & Content royalties.
                    <br />
                    <br />

                    <section className={styles.firstSection}>
                        1. This Term of Use agreement ("the "Agreement") specifies the terms and conditions for access to and use of the Company website and all other websites, applications, platforms, tools, mobile sites, and mobile applications where this Agreement appears or is linked, and through the use of our services, (collectively the “Site”) and describes the terms and conditions applicable to your access of and use of the Site.
                        <br />
                        <br />

                        By creating an account, providing information to us (by any means, whether in correspondence, via our Site, or otherwise), or continuing to use our services, you acknowledge that you have read, understood, and consent to be bound by this Agreement.
                        <br />
                        <br />

                        IF YOU DO NOT AGREE WITH THIS AGREEMENT OR OUR PRACTICES, YOU MAY NOT USE OUR SITE. THIS AGREEMENT MAY CHANGE FROM TIME TO TIME AND YOUR CONTINUED USE OF OUR SITE CONSTITUTES YOUR ACCEPTANCE OF THOSE CHANGES. WE ENCOURAGE YOU TO REVIEW THIS AGREEMENT PERIODICALLY AND WE WILL USE COMMERCIALLY REASONABLE EFFORTS TO UPDATE YOU THROUGH THE SITE OR VIA EMAIL OF ANY UPDATES OR MODIFICATIONS.

                        <br />
                        <br />

                        2. <b>Agreement</b>. This Agreement was published on the date “Last Updated” above. We may change this Agreement from time to time. Changes to this Agreement will be made by updating this page. Any such modifications shall be effective immediately. Please visit this Agreement regularly to read the current version. Each use by you shall constitute and be deemed your unconditional acceptance of this Agreement.
                        <br />
                        <br />

                        3.<b>Discontinuation or Suspension of the Site</b>. We may, at any time and without liability, modify, suspend, or discontinue, temporarily or permanently, the Site (including access to the Site via any third-party links), with or without notice to you; charge, modify or waive any fees required to use our services; or offer opportunities to some or all users. This includes the right to modify, discontinue or remove any content, postings, links, pages, services, or other materials at any time and for any reason. We may refuse or restrict anyone from access to the Site at any time.
                        <br />
                        <br />

                        4.<b>Intended Audience</b>. This Site is intended for adults only. This Site is not intended for any children under the age of 18.
                        <br />
                        <br />

                        5.<b>Privacy</b>. Your visit to our site is also governed by our Privacy Policy. Please review our Privacy Policy at  <Link to={'/privacy-policy'}>[Privacy policy]</Link>
                        <br />
                        <br />

                        6.<b>Use of Information</b>. Your submission of information through the Site is governed by our Privacy Policy. You represent and warrant that any information you provide in connection with the Site is and will remain accurate and complete, and that you will maintain and update such information as needed. You must always get written permission from all persons named, identified, referred or alluded to, either explicitly or implicitly, in any such information you provide in connection with the Site and, if required, provide us with such written permission upon our request. This includes getting permission from parents or guardians if the person is under the age of 18.
                        <br />

                        Company owns and shall continue to own all right, title and interest in and to all aggregate or anonymized data and any statistical information, benchmarking, comparison, and other analytics or analyses created or developed by Company from performance and usage data generated through your use of the Site and our services (collectively, “Aggregate Data”). Aggregate Data will be reasonably de-identified by Company. As used in this Agreement, “aggregate” means individual identities have been removed, such that the information is not linked or reasonably linkable to any person or household, including via a device. As used in this Agreement, “deidentified” means the information cannot reasonably be used to reasonably identify, relate to, describe, be capable of being associated with, infer information about, or otherwise be linked, directly or indirectly, to a particular person (individual or entity), device, or computer. We will: (1) take reasonable measures to ensure that the information cannot be associated with a person (individual or entity), device, computer, or household, including by implementing technical safeguards that prohibit reidentification and implementing business processes to prevent inadvertent release of deidentified data or information; (2) committing to maintain and use the information in the deidentified form and not to attempt to reidentify the information; and (3) contractually obligating any recipient of the information to comply with all provisions of this Section.
                        <br />
                        <br />

                        7.<b>Alerts and Notifications</b>. You may receive notifications, alerts, emails, text messages or other types of messages regarding the Site in accordance with the terms of our Privacy Policy. We may also periodically send you emails or text messages that directly promote certain services and products. When you receive such promotional emails from us you will have the opportunity to “opt-out” by following the unsubscribe instructions provided in the email or text message you receive.
                        <br />
                        <br />

                        8.<b>Site Use</b>. Subject to all limitations and restrictions contained in this Agreement, Company grants you a limited, revocable, nonexclusive license to use this Site solely for your own personal use and not for republication, distribution, assignment, sublicense, sale, preparation of derivative works, or other use. You agree not to copy materials on the Site, reverse engineer or break into the Site, or use materials, products or services in violation of any law. The use of the Site is at the discretion of Company and Company may terminate your use of this Site at any time.
                        <br />
                        <br />

                        You shall not, and shall not permit anyone else to, transfer, license, assign, distribute, translate, reverse engineer, decompile, disassemble, or modify the Site, duplicate the Site or portions thereof. Further, you shall not, and shall not permit anyone else to, (i) directly or indirectly: sell, rent, lease, transfer, assign, or exploit the Site other than as expressly provided herein; (ii) copy any features, functions, look and feel or graphics of the Site unless permitted herein; or (iii) interfere with the integrity or performance of the Site. You may not access or use the Site if you are a competitor of Company or for the principal purpose of monitoring availability, performance or functionality.
                        <br />
                        <br />

                        9.<b>Unacceptable Use</b>. You agree not to:
                        <br />
                        <p>
                            a. use or access the Site for any purpose that is unlawful or prohibited by this Agreement;
                            <br />

                            b. use or access the Site in a manner that could damage, disable, overburden, or impair the server hosting the Site or the networks connected to any server hosting the Site;
                            <br />

                            c. interfere with any third-party's use and enjoyment of the Site;
                            <br />

                            d. attempt to gain unauthorized access to information, accounts, computer systems or networks retained on or connected to any server hosting the Site through hacking, password mining or any other means;
                            <br />

                            e. post, transmit or otherwise make available through or in connection with the Site any virus, worm, Trojan horse, Easter egg, time bomb, spyware or other computer code, cancelbots, file or program that is or is potentially harmful or invasive or intended to damage, destroy, disrupt, impair or hijack the operation of a computer’s functionality or the operation of our (or anyone else’s) Site, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or information, or to monitor the use of, any hardware, software or equipment (each, a “Virus”);
                            <br />

                            f. post content that is obscene, indecent, pornographic, violent, derogatory, defamatory, threatening, harassing, abusive, slanderous, hateful, objectionable or embarrassing to any other person or entity (as determined by us, in our sole discretion);
                            <br />

                            g. engage in behavior through the Site that is harass, degrade, or intimidate an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, disability, or any other basis or characteristic recognized by applicable law;
                            <br />

                            h. post content that contains SPAM links to other websites or individuals;
                            <br />

                            i. impersonate another business, person or entity, including Company, its related entities, employees, and agents;
                            <br />

                            j. violate any policy posted on the Site or on any third-party platform (e.g., social media platforms); or
                            <br />

                            k. use any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, “scrape,” “data mine” or otherwise gather Site content, or reproduce or circumvent the navigational structure or presentation of the Site, without our express prior written consent.
                            <br />
                            <br />

                            We reserve the right to investigate occurrences which may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who have participated in such violations. You understand that data and communications, including e-mail and other electronic communications, may be accessed by unauthorized third parties when communicated over the Internet. You agree that it is your responsibility, and not ours, to obtain and use third-party software products that support encryption and other security protocols compatible with such protocols (if any) that may be used by us from time to time in connection with the Site.
                            <br />
                            <br />
                        </p>

                        10.<b>Ownership</b>. The Site, and all content included on this Site, including all legal notices, credits, copyright, trademarks, trade names, logos, color schemes, service marks, slogans, other proprietary notices, and similar means of identifying products or services displayed on the Site (collectively, the “Marks”), is and shall continue to be the property of Company or its content suppliers and is protected under applicable copyright, patent, trademark, and other proprietary rights. Any copying, redistribution, use or publication by you of any such content or any part of the Site is prohibited, except as expressly permitted in this Agreement. You may not use our Marks or copyrighted materials in any search engine descriptions, content, meta-tags, “white lettering”, key words, or other means of directing or influencing web traffic to any website, web page, portal or e-mail operated, controlled or authorized by you without our express prior written permission and your doing so constitutes a violation of our rights under United States federal and state law, and other international laws and is a breach of this Agreement. You will not adopt or use any names, trademarks, slogans, trade names, trademarks, service marks, e-mail addresses, URLs, meta-tags, key words, search descriptions or the like that are the same or are confusingly similar to the Marks. Without our prior written consent, you will not submit or maintain any information submitted to search engines which incorporates any content from the Site, the Marks, our copyrighted materials or any marks that are confusingly similar to the Marks. Under no circumstances will you acquire any ownership rights or other interest in any content by or through your use of this Site.
                        <br />
                        <br />

                        All rights not expressly granted under this Agreement are expressly reserved to us. Nothing contained on the Site should be construed as granting any right to use any trade names, trademarks, service marks or logos without the express prior written consent of the owner.
                        <br />
                        <br />

                        11.<b>Submission</b>. You and other Site visitors may have the ability to transmit or otherwise make available through or in connection with the Site certain materials, including without limitation, materials which may contain information that personally identifies you or someone else, so long as you comply with the terms of this Agreement, and the content is not illegal or injurious to third parties (each, a “Submission” and collectively “Submissions”). You must always get written permission from all persons named, identified, referred or alluded to, either explicitly or implicitly, in such content and, if required, provide us with such written permission upon our request. This includes getting permission from parents or guardians if the person is under the age of 18. We have no control over and are not responsible for any use or misuse (including any distribution) by any third-party of Submissions. IF YOU CHOOSE TO MAKE ANY OF YOUR PERSONALLY IDENTIFIABLE OR OTHER INFORMATION PUBLICLY AVAILABLE THROUGH THE SITE, YOU DO SO AT YOUR OWN RISK.
                        <br />
                        <br />

                        12.<b>Feedback</b>. If you provide us any suggestions, ideas, proposals, comments, feedback or other information (“Feedback”), whether related to the Site or otherwise, you hereby grant to us a worldwide, royalty-free, fully paid-up, non-exclusive, perpetual, irrevocable, transferable and fully sublicensable (through multiple tiers) license, without additional consideration to you or any third-party, to reproduce, distribute, perform and display (publicly or otherwise), create derivative works of, adapt, modify and otherwise use, analyze and exploit such Feedback, in any format or media now known or hereafter developed, and for any purpose (including promotional purposes, such as testimonials). You further irrevocably waive any “moral rights” or other rights with respect to attribution of authorship or integrity of materials regarding any Feedback that you may have under any applicable law under any legal theory. You acknowledge and agree that such Feedback is not confidential, and that your provision of such Feedback is gratuitous, unsolicited and without restriction, and does not place us under any fiduciary or other obligation.
                        <br />
                        <br />

                        You represent and warrant that you have all rights necessary to grant the licenses granted in this Section and that your Feedback, and your provision of Feedback through and in connection with the Site, do not violate any terms or conditions of this Agreement, are complete and accurate, and are not fraudulent, tortious or otherwise in violation of any applicable law or any right of any third-party.
                        <br />
                        <br />

                        13.<b>Compliance with Laws</b>. You may use the Site only for lawful purposes. The Site and use of our services is subject to, and you agree that you will at all times comply with all applicable local, state, national, and international laws, statutes, rules, regulations, ordinances and the like applicable to the use of the Site and our services. This obligation includes your agreement to comply with all applicable laws, regulations, and rules relating to the export of technical and other data from the United States (and from your country if you are not located in the United States) and your agreement not to export or re-export any such data or any other content or materials in violation of such laws, rules or regulations without first obtaining all necessary licenses, consents and approvals therefor, as well as authorization from us. You further agree that information provided by you is truthful and accurate to the best of your knowledge.
                        <br />
                        <br />

                        14.<b>Non-United States Use & Restrictions</b>. We control and operate the Site from the United States, and all information is processed within the United States. We do not represent that the Site is appropriate or available for use in other locations. By visiting the Site and using any services provided through the Site, you acknowledge that you are not a national of, or resident within any of the countries that are subject to trade embargo, restriction or other limitation under any laws and regulations or listed on any of the United States government's lists of prohibited and restricted parties.
                        <br />
                        <br />

                        15.<b>Third-Party Websites</b>. If, in your interactions with the Site, you are linked or directed to, or click on, a third-party website, we cannot control what information you may provide to that party or on that website, and we are not responsible for how that party may use or disclose any information you may provide to them. This is not as an endorsement by us of any third-party website, content that may be offered on such third-party website, or of any products or services provided by such third-party. We do not control, nor are we responsible for, such third-party website, product or service offerings. As such, we urge that you exercise caution before providing them with your information and to review the third-party’s policies and procedures.
                        <br />
                        <br />

                        You should contact the site administrator for such third-party website if you have any complaints, claims, concerns or questions regarding such third-party website or its privacy practices.
                        <br />
                        <br />

                        16.<b>Indemnification</b>. To the fullest extent permitted under applicable law, you agree to indemnify, defend and hold Company and our partners, employees, and affiliates, and our respective successors and assigns, harmless from and against any and all claims, demands, causes of actions, debt, liabilities, damages, judgments, awards, losses, costs, expenses and fees (including attorneys’ fees) to the extent that such action is based on, arises out of, or relates to (a) your violation of this Agreement; (b) your use of (or inability to use) the Site; and (c) any other activities of yours accomplished using our services or the Site.
                        <br />
                        <br />

                        17.<b>Disclaimer</b>. THE INFORMATION ON THIS SITE IS PROVIDED ON AN "AS IS," "AS AVAILABLE" BASIS. YOU AGREE THAT USE OF THIS SITE IS AT YOUR SOLE RISK. COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO ANY EXPRESS WARRANTIES, STATUTORY WARRANTIES, AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. TO THE EXTENT YOUR JURISDICTION DOES NOT ALLOW LIMITATIONS ON WARRANTIES, THIS LIMITATION MAY NOT APPLY TO YOU. YOUR SOLE AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF THE SITE SHALL BE TO DISCONTINUE USING THE SITE.
                        <br />
                        <br />

                        18.<b>Limitation of Liability</b>. UNDER NO CIRCUMSTANCES WILL COMPANY BE LIABLE OR RESPONSIBLE UNDER ANY THEORY OF LIABILITY, WHETHER IN AN EQUITABLE, LEGAL, OR COMMON LAW ACTION ARISING HEREUNDER FOR CONTRACT, STRICT LIABILITY, INDEMNITY, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES FROM LOSS OF BUSINESS, LOST PROFITS, LITIGATION, OR THE LIKE), SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN ANY WAY RELATING TO THE SITE, YOUR SITE USE, OR THE CONTENT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE AND/OR CONTENT IS TO CEASE ALL OF YOUR SITE USE.
                    </section>
                    <br />
                    <br />

                    These limitations of liability shall apply even if a limited remedy fails of its essential purpose. The limitation of liability herein applies to all liabilities in the aggregate, including, without limitation, those resulting from your use or your inability to use the Site, or from any third party websites, including from any Virus that may be transmitted in connection therewith, or any other matter arising from or relating to the Site. The limitations in this section do not purport to limit liability or alter your rights as a consumer that cannot be excluded under applicable law. Some states do not allow the exclusion or limitation of certain categories of damages. In the event the foregoing exclusion of liability is determined, in whole or in part, to be invalid or unenforceable, then Company’s liability arising in connection with the Site, or under this Agreement whether in contract, tort (including without limitation negligence, strict liability and other actions in contract or tort) or otherwise, shall not exceed, under any circumstances, fifty dollars ($50). THIS PROVISION SHALL HAVE NO EFFECT ON THE CHOICE OF LAW PROVISION SET FORTH BELOW.
                    <br />
                    <br />
                    <p>
                        1.<b>Applicable Law</b>. You agree that the laws of the State of Delaware, without regard to conflicts of laws provisions will govern this Agreement and any dispute that may arise between you and Company or its affiliates.
                        <br />
                        <br />

                        The parties agree and hereby submit to the exclusive jurisdiction and venue of the appropriate state and federal courts located in Delaware with respect to such matters.
                        <br />
                        <br />

                        Regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the Site must be filed or otherwise commenced within one (1) year after such claim or cause of action arose or be forever barred.
                        <br />
                        <br />

                        2.<b>Notices</b>. Company may give notice by means of a notification on the Site or through electronic mail to your e-mail address on file with Company, or by written communication sent by first class mail or pre-paid post to your address on file with Company. You may give notice to Company at any time by letter sent by first class mail to Company. Our relevant contact information is provided in Section 29 below. All notices shall be deemed to have been given five days after mailing if sent by first class mail. Any notice of material breach shall clearly define the breach including the specific contractual obligation that has been breached.
                        <br />
                        <br />

                        3.<b>Severability</b>. If any provision of this Agreement shall be adjudged by any court of competent jurisdiction to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect.
                        <br />
                        <br />

                        4.<b>Waiver</b>. The failure of Company to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. Any waiver of this Agreement by Company must be in writing and signed by an authorized representative of Company.
                        <br />
                        <br />

                        5. <b>Termination</b>. Company may terminate this Agreement or the Site at any time, or any portion thereof, with or without notice, for any reason. Upon any such termination or suspension, your right to use the Site and our services will immediately cease, and we may, without liability to you or any third-party, immediately deactivate or delete all associated materials, without any obligation to provide any further access to such materials. Termination of this Agreement shall not limit Company from pursuing other remedies available to it, including injunctive relief, nor shall such termination relieve your obligation to pay all fees that have accrued or are otherwise owed by you under this Agreement. Any provision of this Agreement which expressly or by its nature survives termination or expiration, or which contemplates performance or observance subsequent to termination or expiration of this Agreement, will survive the termination or expiration of this Agreement and continue in full force and effect thereafter.
                        <br />
                        <br />

                        6.<b>Relationship of the Parties</b>. Nothing contained in this Agreement or your use of the Site shall be construed to constitute either party as a partner, joint venturer, employee or agent of the other party, nor shall either party hold itself out as such. Neither party has any right or authority to incur, assume or create, in writing or otherwise, any warranty, liability or other obligation of any kind, express or implied, in the name of or on behalf of the other party, it being intended by both parties that each shall remain independent contractors responsible for its own actions.
                        <br />
                        <br />

                        7.<b>Assignment</b>. You may not assign, transfer or sublicense any or all of your rights or obligations under this Agreement without our express prior written consent. We may assign, transfer or sublicense any or all of our rights or obligations under this Agreement without restriction.
                        <br />
                        <br />

                        8.<b>Force Majeure</b>. Company shall not be liable to you for any delay or failure of Company to perform its obligations hereunder if such delay or failure arises from any cause or causes beyond the reasonable control of Company. Such causes shall include, but are not limited to, acts of God, acts of governmental authority, floods, fires, loss of electricity or other utilities, or delays by you in providing required resources or support or performing any other requirements hereunder.
                        <br />
                        <br />

                        9.<b>Entire Agreement</b>. This Agreement constitutes the entire agreement between you and Company and governs the terms and conditions of your use of the Site, and supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and Company with respect to this Site. Neither the course of conduct between the parties nor trade practices shall act to modify this Agreement. Notwithstanding the foregoing, you may also be subject to additional terms and conditions, posted policies (including but not limited to the Privacy Policy), guidelines, or rules that may apply when you use the Site.
                        <br />
                        <br />

                        10.<b>Contact Information</b>.
                        <br />
                        <br />
                    </p>

                    Record Financial Inc.
                    <br />
                    <br />
                    <Link to={'/contact'}>[Contact Form]</Link>
                </div>
            </div>
        </Slide>
    )
}