import React, { forwardRef, useState } from 'react';
import { OutlinedInput } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import { CancelTwoTone } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BrandInput } from './BrandInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const BrandMenuItem = forwardRef((props, ref) =>
    <MenuItem innerRef={ref} {...props} />)

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
            width: 500,
        },
    },
};

const useMultiSelectStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    chipFromUploadMapping: {
        backgroundColor: theme.palette.primary.hover,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        }
    }
}));

export function BrandMultiSelect({ label, value, onChange, isMapping, autodetected, templateCol, autoDetectedTrigger, mappingColumns, isFromCsvUpload }) {
    const [chips, setChips] = React.useState([]);
    const styles = useMultiSelectStyles();

    const handleChange = (event) => {
        if (isMapping) {
            const { target: { value } } = event;
            setChips(typeof value === 'string' ? value.split(',') : value);
            onChange(typeof value === 'string' ? value.split(',') : value);
        } else {
            const value = event.target.value;
            setChips(value);
            onChange(value)
        }
    };

    const handleDelete = (e, value) => {
        setChips(chips.filter(chip => chip !== value));
        if (isMapping) {
            onChange(chips.filter(chip => chip !== value));
        }
    };

    React.useEffect(() => {
        if (isMapping) {
            if (Object.keys(autodetected).length > 0 && autodetected[templateCol]) {
                if (typeof autodetected[templateCol] === 'string') {
                    setChips(autodetected[templateCol]);
                } else {
                    setChips(autodetected[templateCol]);
                }
            }
            else if (Object.keys(autodetected).length === 0) {
                setChips([]);
            }
        }
    }, [autoDetectedTrigger, autodetected]);

    return (
        <FormControl classes={{ root: styles.root }}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={chips.length === 0 ? '' : chips}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip 
                                className={`${isFromCsvUpload ? styles.chipFromUploadMapping : ""}`}
                                color='primary'
                                key={isMapping ? value : value.id}
                                label={isMapping ? value : value.name}
                                clickable
                                deleteIcon={
                                    <CancelTwoTone
                                        onMouseDown={(event) => event.stopPropagation()}
                                    />
                                }
                                onDelete={(e) => handleDelete(e, value)}
                            />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {value.map((x) => {
                    let columns = (mappingColumns[x.id] || []).join(', ');
                    if (columns.length > 20) {
                        columns = columns.slice(0, 19) + '...';
                    }
                    return (
                        <BrandMenuItem key={isMapping ? x.name : x.id} value={isMapping ? x.name : x}>
                            <Checkbox color='primary' checked={isMapping ? chips.includes(x.name) : chips.includes(x)} />
                            <ListItemText primary={`${x.name}${columns ? ` (e.g. ${columns})` : ''}`} />
                        </BrandMenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

const useSelectStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },

    arrow: {
        right: theme.spacing(0.5),
        position: 'absolute',
        cursor: 'pointer',
    }
}));

export function BrandSelect({ label, value, onChange, onChangeWithNotify, children, ...props }) {
    const styles = useSelectStyles();
    const [open, setOpen] = useState(false);

    const handleChange = (e) => {
        onChange(e.target.value)
    };

    return (props.$value ?
        <Select
            value={props.$value[0]}
            onChange={e => props.$value[1](e.target.value)}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            IconComponent={(props) => <ExpandMoreIcon fontSize='large' {...props} className={styles.arrow}  onClick={() => setOpen(!open)} />}
            input={<BrandInput label={label} />}
        >
            {children}
        </Select>
        :
        <Select
            value={value}
            onChange={onChangeWithNotify ? onChangeWithNotify : handleChange}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            IconComponent={(props) => <ExpandMoreIcon fontSize='large' {...props} className={styles.arrow} onClick={() => setOpen(!open)} />}
            input={<BrandInput label={label} />}
        >
            {children}
        </Select>
    );
}
