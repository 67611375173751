import { makeStyles, TablePagination } from '@material-ui/core';
import { useState } from 'react';
import BrandTable from '../../CoreComponents/BrandTable';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { getData } from '../../utils/FetchUtils';
import { useEffect } from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { EthIcon, roundToSecondDecimal } from '../NftPortfolio';
import NFTActivityList from './NftActivityList';

const useNftStyles = makeStyles((theme) => ({
    tableRowIcon: {
        fontSize: '18px !important'
    },
    tableRow: {
        cursor: "pointer",
        display: 'flex',
        justifyContent: 'start',
        gap: 3,
    },
    tableRowToggler: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: "pointer",
        }
    },
    hash: {
        width: '143px'
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            paddingRight: theme.spacing(2)
        }
    },
    nftActivityTable: {

    },
}));

const NftActivity = ({ nftId, nftData, selectedCryptocurrency, page, setPage }) => {
    const styles = useNftStyles();
    const [headCells] = useState([
        {
            id: 'timestamp',
            label: 'Timestamp',
            CellRender: ({ rowData }) => {
                const releaseDate = new Date(rowData.timestamp)
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {releaseDate.getTime() ?
                            `${releaseDate.getFullYear()}-${releaseDate.getMonth() < 9 ? '0' : ''}${releaseDate.getMonth() + 1}-${releaseDate.getDate() < 10 ? '0' : ''}${releaseDate.getDate()} ${releaseDate.getHours() < 10 ? '0' : ''}${releaseDate.getHours()}:${releaseDate.getMinutes() < 10 ? '0' : ''}${releaseDate.getMinutes()}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            },
        },
        {
            id: 'hash',
            label: 'Hash',
            CellRender: ({ cellData }) => {
                return (
                    <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(cellData)}>
                        <div>{cellData.slice(0, 11) + '...' + cellData.slice(-5)}</div>
                        <FileCopyIcon className={styles.tableRowIcon} />
                    </div>)
            }
        },
        {
            id: 'status',
            label: 'Status',
        },
        {
            id: 'quantity',
            label: 'Quantity',
        },
        {
            id: 'transactionFee',
            label: 'Fee',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData === null ?
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                            :
                            <> {roundToSecondDecimal(cellData)} < EthIcon header={true} /> </>
                        }
                    </div >
                );
            }
        },
        {
            id: 'toggle',
            label: '',
            notSortable: true,
            CellRender: ({ rowData, tableRows }) => {
                return <div
                    className={styles.tableRowToggler}
                    onClick={() => setToggleProps({ rowData: rowData, tableRows: tableRows })}
                >
                    {rowData.toggleRow ?
                        <>
                            Hide Transfer Details
                            <ExpandLess />
                        </>
                        :
                        <>
                            Show Transfer Details
                            <ExpandMore />
                        </>
                    }
                </div>;
            }
        }
    ]);
    const [rowsPerPageOptions] = useState([5, 10, 15]);
    const [rows, setRows] = useState([]);

    const [offset, setOffset] = useState(0);
    const [rowCounts, setRowCounts] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [sortBy, setSortBy] = useState('timestamp');
    const [sortType, setSortType] = useState('ASC');
    const [rowForToggle, setRowForToggle] = useState(undefined);
    const [toggleProps, setToggleProps] = useState(undefined);

    useEffect(() => {
        setOffset(page * 5)
    }, [page])

    useEffect(() => {
        setPage(0);
    }, [rowsPerPage])

    useEffect(() => {
        fetchData();
    }, [offset, rowsPerPage, sortType, sortBy, selectedCryptocurrency])

    useEffect(() => {
        if (toggleProps) {
            const rowData = toggleProps.rowData;
            const tableRows = toggleProps.tableRows;
            const transfers = JSON.parse(rowData.transactionTransfers);;
            const actions = JSON.parse(rowData.transactionActions);

            if (rowForToggle && rowForToggle.id === rowData.id) {
                const previousRowToggleIndex = tableRows.indexOf(rowForToggle);
                delete tableRows[previousRowToggleIndex].toggleRow;
                tableRows.splice(previousRowToggleIndex + 1, 1);
                setRowForToggle(undefined);
            } else if (rowForToggle === undefined) {
                const newRowToggleIndex = tableRows.indexOf(rowData);
                tableRows[newRowToggleIndex].toggleRow = { actions: actions, transfers: transfers };
                tableRows.splice(newRowToggleIndex + 1, 0, { toggledRow: true, actions: actions, transfers: transfers });
                setRowForToggle(rowData);
            } else {
                const previousRowToggleIndex = tableRows.indexOf(rowForToggle);
                delete tableRows[previousRowToggleIndex].toggleRow;
                tableRows.splice(previousRowToggleIndex + 1, 1);

                const newRowToggleIndex = tableRows.indexOf(rowData);
                tableRows[newRowToggleIndex].toggleRow = { actions: actions, transfers: transfers };
                tableRows.splice(newRowToggleIndex + 1, 0, { toggledRow: true, actions: actions, transfers: transfers });
                setRowForToggle(rowData);
            }
            setRows([...tableRows]);
        }
    }, [toggleProps])

    async function fetchData() {
        if (nftId) {
            const fetchedData = await getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-activity?nftId=${nftId}&offset=${offset}&limit=${rowsPerPage}&sort=${sortBy}&type=${sortType}${selectedCryptocurrency ? `&cryptocurrency=${selectedCryptocurrency}` : ''}`)
            setRowCounts(fetchedData.total || 0);
            setRows(fetchedData.items || []);
            setRowForToggle(undefined);
            setToggleProps(undefined);
        }
    }

    return (
        <>
            <BrandTable
                headCells={headCells}
                rows={rows}
                page={page}
                setSortBy={setSortBy}
                setSortType={setSortType}
                hidePagination={true}
                checkboxless={true}
                ToggleRowData={NFTActivityList}
                additionalData={nftData}
                className={styles.nftActivityTable}
            />
            <TablePagination
                className={styles.tablePagination}
                rowsPerPageOptions={rowsPerPageOptions}
                count={rowCounts}
                rowsPerPage={rowsPerPage}
                page={page}
                component="div"
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
            />
        </>
    );
}

export default NftActivity;