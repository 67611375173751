import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  tooltipText: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.lightYellow}`,
    borderRadius: '16px',

    textAlign: 'left',
    font: 'normal normal normal 16px/26px Roboto',
    padding: '12px',
  },
  tooltipArrow: {
    // Add stuff if needed
  }
}));

export function BrandTooltip({ title, iconSize = 'small', ...props }) {
    const styles = useStyles();

    return (
        <Tooltip title={title} arrow classes={{
            tooltip: styles.tooltipText,
            arrow: styles.tooltipArrow,
          }} {...props}>
            <IconButton>
                <InfoOutlinedIcon fontSize={iconSize} />
            </IconButton>
        </Tooltip>
    );
};