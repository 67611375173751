import React from 'react';
import Dropzone from 'react-dropzone';
import { Link } from '@material-ui/core';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  browse: {
    textDecoration: 'none'
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 57,
    width: 349,
    height: 239,
    textAlign: 'center',
    border: '2px dashed #FFFFFFDD',
    '&:hover': {
      cursor: "pointer",
    }
  },
  icon: {
    fontSize: 100,
    marginTop: 20
  }
}));


export default function DropZone({ handleDrop, acceptedFormats }) {
  const styles = useStyles();
  const CSVFormat = acceptedFormats['text/csv' ? 'text/csv' : 'text/plain'][0].slice(1).toUpperCase();
  return (
    <Dropzone
      onDrop={handleDrop}
      accept={acceptedFormats}>
      {({ getRootProps, getInputProps }) => (
        <section >
          <div className={styles.dropzone} {...getRootProps()}>
            <input {...getInputProps()} />
            <BackupOutlinedIcon className={styles.icon} />
            <p className="dropZoneText">
              Drag and drop to upload {CSVFormat}<br></br>or <Link className={styles.browse}>browse</Link> to choose one
            </p>
          </div>
        </section>
      )}
    </Dropzone>
  );
}




