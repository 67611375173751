import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles
} from "@material-ui/core";
import CustomAutocomplete from "./CustomAutocomplete";
import { useEffect, useState } from "react";
import useAbortableFetch from '../../hooks/useAbortableFetch';
import { useDebounce } from "../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
    listItem: {
        '&:hover': { cursor: 'pointer' }
    },
    optionsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        background: '#1A1A1A',
        rowGap: '5px',
        height: '250px',
        overflowY: 'scroll',
        position: 'absolute',
        marginTop: '10px',
        top: '100%',
        left: 0,
        zIndex: 2
    },
    suggestedWrapper: {
        paddingTop: '18px',
        paddingLeft: '22px',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.suggestedSongsDropdown,
        fontSize: '14px',
        height: '250px',
        borderRadius: '5%',
        overflowY: 'scroll',
        marginTop: '10px',
        position: 'absolute',
        top: '100%',
        left: 0,
        zIndex: 1,
        '& .MuiListItem-gutters': {
            paddingLeft: '0px'
        }
    },
    suggestedSongLabel: {
        marginBottom: '15px'
    },
    suggestedSongAvatar: {
        height: '36px',
        width: '36px',
        marginRight: '10px'
    },
    suggestedSongText: {
        '& .MuiTypography-body1': {
            fontSize: '14px'
        }
    }
}));

const DEBOUNCE_DELAY_MILLISECONDS = 500;

const SearchSongAutocomplete = ({ onSelectedSong, suggestedSongs }) => {
    const [suggestedSongsData, setSuggestedSongsData] = useState(suggestedSongs || []);
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const debouncedSearch = useDebounce(searchQuery, DEBOUNCE_DELAY_MILLISECONDS);

    const styles = useStyles();
    const abortableFetch = useAbortableFetch();

    const handleLoadData = async () => {
        try {
            const { data } = await abortableFetch('GET', '/api/song/search-song', {
                query: { song: debouncedSearch }
            });

            setOptions(data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSelectSearchSong = (option) => {
        option.manuallyMatched = true;
        onSelectedSong(option);
    }

    useEffect(() => {
        if (!searchQuery) {
            setOptions([]);
            setShowOptions(false);
        } else {
            handleLoadData();
            setShowOptions(true);
        }
    }, [debouncedSearch]);

    return (
        <CustomAutocomplete
            showOptions={showOptions || suggestedSongsData?.length > 0}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setShowOptions={setShowOptions}
        >
            {options.length > 0 ?
                <List className={styles.optionsWrapper}>
                    { options.map((option) => (
                        <ListItem
                            component='div'
                            key={option.foundSongId}
                            className={styles.listItem}
                            onClick={() => handleSelectSearchSong(option)}
                        >
                            <Avatar
                                alt={`Avatar of ${option.foundTitle}`}
                                src={option && option.foundCoverArt ? option.foundCoverArt : null}
                            />
                            <ListItemAvatar />
                            <ListItemText
                                primary={`${option && option.foundArtistId ? `${option.foundArtist} - ` : ''}${option.foundTitle}`}
                            />
                        </ListItem>
                    )) }
                </List>
                : (suggestedSongsData.length > 0 &&
                    <List className={styles.suggestedWrapper}>
                        <span className={styles.suggestedSongLabel}>Suggested songs:</span>
                        {suggestedSongsData.map((option) => (
                            <ListItem
                                component='div'
                                key={option.foundSongId}
                                className={styles.listItem}
                                onClick={() => onSelectedSong(option)}
                            >
                                <Avatar
                                    className={styles.suggestedSongAvatar}
                                    alt={`Avatar of ${option.foundTitle}`}
                                    src={option && option.foundCoverArt ? option.foundCoverArt : null}
                                />
                                <ListItemText
                                    className={styles.suggestedSongText}
                                    primary={`${option && option.foundArtistId ? `${option.foundArtist} - ` : ''}${option.foundTitle}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                )
            }
        </CustomAutocomplete>
    )
}

export default SearchSongAutocomplete;