import { Box, Button, InputAdornment, TextField, makeStyles } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { useDebounce } from "../../hooks/useDebounce";
import { useEffect, useRef, useState } from "react";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import { useInfiniteScrolling } from "../../hooks/useInfiniteScrolling";

const useStyles = makeStyles(() => ({
    autocompleteWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '290px',
        rowGap: '10px',
        padding: '10px',
        marginTop: '5px'
    },
    optionsWrapper: {
        height: '125px',
        maxHeight: '125px',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column'
    },
    optionButton: {
        textTransform: 'capitalize',
        fontSize: '14px'
    }
}));

const HiddenSongsAutocomplete = ({ 
    songId, 
    handleButtonClick 
}) => {
    const LIMIT = 5;
    const DEBOUNCE_TIMEOUT = 500;

    const [options, setOptions] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const searchQuery = useDebounce(searchInput, DEBOUNCE_TIMEOUT);

    const abortableFetch = useAbortableFetch();

    const offsetRef = useRef(0);
    const elementRef = useRef(null);

    const styles = useStyles();

    const [setIsFetching, setIsDisabled] = useInfiniteScrolling(elementRef, async () => await fetchSongs());

    const fetchSongs = async () => {
        try {
            const { songs } = await abortableFetch('GET', '/api/merge-history/songs', {
                query: { songId, searchQuery, offset: offsetRef.current, limit: LIMIT }
            });

            if (songs.length === 0) {
                setIsDisabled(true);
                return;
            }

            setOptions((prevOptions) => prevOptions.concat(songs));

            offsetRef.current += LIMIT;
            setIsFetching(false);
            setIsDisabled(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        offsetRef.current = 0;
        setOptions([]);
        setIsDisabled(false);
        fetchSongs();
    }, [searchQuery]);

    return (
        <Box className={styles.autocompleteWrapper}>
            <TextField
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder='Search for Song'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchOutlined />
                        </InputAdornment>
                    ),
                    classes: {
                        input: styles.textInputField
                    }
                }}
            />
            <Box ref={elementRef} className={styles.optionsWrapper}>
                { options && options.map(({ id, displayName, artist }) => (
                    <Button 
                        key={id} 
                        value={id} 
                        className={styles.optionButton}
                        onClick={() => handleButtonClick({ id, displayName, artist })}
                    >
                        { artist + ' - ' + displayName }
                    </Button>
                )) }
            </Box>
        </Box>
    )
}

export default HiddenSongsAutocomplete;