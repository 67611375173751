import {
    Box,
    Typography,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles
} from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import CustomModal from "./CustomModal";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import { useState } from "react";
import { DeleteRevenueSplitOptions } from '../../constants/deleteSplitOptions';
import { BrandInformationalButton, BrandDeleteButton, TempBrandButton } from "../CoreComponents/BrandButton";

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        padding: '30px 25px 20px 25px'
    },
    actionsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '20px',
        justifyContent: 'flex-end',
        marginTop: '30px'
    },
    header: {
        fontSize: '22px',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    informationWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '35px',
        width: '90%'
    },
    successMessage: {
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    successMessageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '75%'
    },
    successButtonWrapper: {
        marginTop: '80px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    checkboxesWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    formControlLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '35px'
    },
    disabled: {
        '&.Mui-disabled': {
            color: theme.palette.primary.lightGray
        }
    },
    warningMessage: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        color: theme.palette.primary.alert
    }
}));

const deleteOptions = [
    { label: 'Keep the Main Account holder split % the same.', value: DeleteRevenueSplitOptions.KEEP_SPLIT_PERCENTAGE },
    { label: 'Set Main Account holder split to 100%.', value: DeleteRevenueSplitOptions.SET_SPLIT_100_PERCENTAGE },
    { label: 'Set Main Account holder split to 0%.', value: DeleteRevenueSplitOptions.SET_SPLIT_0_PERCENTAGE },
];

const DeleteRevenueSplitModal = ({
    open,
    song,
    onClose,
    selectedItems,
    setSelectedItems,
    fetchRevenueSplits,
    accountHolder
}) => {
    const [showSuccessfulDeletion, setShowSuccessfulDeletion] = useState(false);
    const [optionChecked, setOptionChecked] = useState(0);

    const styles = useStyles();
    const abortableFetch = useAbortableFetch();

    const handleDeleteStatements = async () => {
        try {
            if (!optionChecked) return;

            const requestBody = {
                songId: song.id,
                reportAndSongIdPairs: selectedItems.flatMap(({ id, songIds }) => (songIds.map(songId => ({ reportId: id, songId: songId })))),
                mainAccountHolderId: accountHolder?.id
            };

            if (optionChecked === DeleteRevenueSplitOptions.SET_SPLIT_0_PERCENTAGE)
                requestBody.mainAccountHolderNewPercentage = 0;
            else if (optionChecked === DeleteRevenueSplitOptions.SET_SPLIT_100_PERCENTAGE)
                requestBody.mainAccountHolderNewPercentage = 100;

            await abortableFetch('DELETE', '/api/revenue-split', {
                body: requestBody
            });

            setShowSuccessfulDeletion(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleCloseSuccessModal = async () => {
        await fetchRevenueSplits();
        onClose();
        setSelectedItems([]);
    }

    return (
        <CustomModal
            open={open}
            hideCloseIcon
            onClose={showSuccessfulDeletion ? handleCloseSuccessModal : onClose}
        >
            {showSuccessfulDeletion ? (
                <Box className={styles.contentWrapper}>
                    <Typography className={styles.header}>
                        Success!
                    </Typography>
                    <Box className={styles.informationWrapper}>
                        <Typography variant="body2">
                            You have successfully deleted the custom revenue splits.

                        </Typography>
                    </Box>
                    <Box className={styles.actionsWrapper}>
                        <TempBrandButton
                            onClick={handleCloseSuccessModal}
                        >
                            Continue
                        </TempBrandButton>
                    </Box>

                </Box>
            ) : (
                <Box className={styles.contentWrapper}>
                    <Typography className={styles.header}>
                        Warning
                    </Typography>
                    <Box className={styles.informationWrapper}>
                        <Typography variant="body2">
                            This action will remove the additional custom split added to the main account holder - {accountHolder?.name}.
                        </Typography>
                        <FormControl className={styles.checkboxesWrapper}>
                            <FormLabel focused={false}>
                                <Typography variant="body2">
                                    Please select the correct behavior for the main account holder:
                                </Typography>
                            </FormLabel>
                            <RadioGroup
                                value={optionChecked}
                                onChange={(e) => setOptionChecked(Number(e.target.value))}
                            >
                                {deleteOptions.map((option) => (
                                    <FormControlLabel
                                        key={option.value}
                                        className={styles.formControlLabel}
                                        control={<Radio color='primary' />}
                                        label={<Typography variant='body2'>{option.label}</Typography>}
                                        classes={{ label: styles.disabled }}
                                        value={option.value}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {optionChecked === DeleteRevenueSplitOptions.SET_SPLIT_0_PERCENTAGE && 
                        <Typography variant="body2" className={styles.warningMessage}>
                            <ReportProblemOutlinedIcon /> 
                            If the total revenue split % is equal to 0% <br/>
                            total revenue will be equal to $0.
                        </Typography>
                    }
                    <Box className={styles.actionsWrapper}>
                        <BrandInformationalButton
                            variant="outlined"
                            onClick={onClose}
                        >
                            Cancel
                        </BrandInformationalButton>
                        <BrandDeleteButton
                            onClick={handleDeleteStatements}
                        >
                            Delete
                        </BrandDeleteButton>
                    </Box>
                </Box>
            )}

        </CustomModal>
    )
}

export default DeleteRevenueSplitModal;