import { roundToSecondDecimal } from "../Portfolio/NftPortfolio";

export const validateSingleInput = (
    props,
    indexName,
    index,
    inputValue,
    isPrimary
) => {

    const {
        nftAccountHolders,
        setInvalidInputs,
        primaryValues,
        setPrimaryValues,
        secondaryValues,
        setSecondaryValues,
        setDisabledSave,
        isValuesReduced
    } = props;

    let tempNfts = nftAccountHolders;

    let tempPrimaryValues = 0;
    let tempSecondaryValues = 0;

    const parsedInputValue = Number(inputValue)
    const minPossibleNumber = 0;
    const isInteger = Number.isInteger(Number(inputValue));


    if (isNaN(parsedInputValue) || parsedInputValue < minPossibleNumber) {
        setInvalidInputs(invalidInputs => (invalidInputs[`${indexName}${index}`] = 1, { ...invalidInputs }));
        setDisabledSave(true);
    } else {
        setInvalidInputs(invalidInputs => (delete invalidInputs[`${indexName}${index}`], { ...invalidInputs }));
        setDisabledSave(false);

        !isInteger ?
            isPrimary ?
                tempNfts[index].primaryOwnership = roundToSecondDecimal(inputValue)
                :
                tempNfts[index].secondaryOwnership = roundToSecondDecimal(inputValue)
            :
            isPrimary ?
                tempNfts[index].primaryOwnership = inputValue
                :
                tempNfts[index].secondaryOwnership = inputValue
    }

    if(!!isValuesReduced) {
        if (tempNfts.length > 0) {
            tempPrimaryValues = (tempNfts.reduce((prev, curr) => prev + (curr.primaryOwnership != null ? Number(curr.primaryOwnership) : 0), 0))
            tempSecondaryValues = (tempNfts.reduce((prev, curr) => prev + (curr.secondaryOwnership != null ? Number(curr.secondaryOwnership) : 0), 0))
            setPrimaryValues(tempPrimaryValues);
            setSecondaryValues(tempSecondaryValues);
        }
        primaryValues > 100 || secondaryValues > 100 ? setDisabledSave(true) : setDisabledSave(false);
    }

    return tempNfts

}