import { useEffect, useRef, useState } from "react";
import { postData } from "../../utils/FetchUtils";
import PopoverAutocomplete from "./PopoverAutocomplete";

const PlaySourcesAutocomplete = ({
    selectedOptions,
    setSelectedOptions,
    selectedSongs,
    selectedCountries,
    selectedAccountHolders,
    selectedReportingCompanies
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [textContent,setTextContent] = useState('All Play Sources');
    const [placeholder,setPlaceholder] = useState('Search play sources');
    const [allSelectedText,setAllSelectedText] = useState('All play sources selected');
    const [isLoading,setIsLoading] = useState(true);

    const offset = useRef(0);

    const callback = async () => {
        const searchSongsIDs = selectedSongs.map((x) => x.id).join(', ');
        const searchCountriesNames = selectedCountries.map((x) => x.name).join(' | ');
        const searchAccountHoldersIDs = selectedAccountHolders.map((x) => x.id).join(', ');
        const searchReportingCompanies = selectedReportingCompanies.map((x) => x.id).join(', ');

        const body = {
            accountHolderIDs: searchAccountHoldersIDs,
            songsIDs: searchSongsIDs,
            countriesNames: searchCountriesNames,
            reportingCompanies: searchReportingCompanies
        };
        
        const query = `/api/earnings/play-sources?offset=${offset.current}&limit=10&filter=${searchQuery}`;
        const { items } = await postData(process.env.REACT_APP_SERVER_HOST + query, body);
        
        setIsLoading(false);
        const updatedIndexes = items.map((x) => ({ id: x.name , name: x.name}) )
        setOptions((options) => options.concat(...updatedIndexes));
        offset.current += 10;
    };

    const enabled = selectedAccountHolders.length > 0 
        && selectedSongs.length > 0 
        && selectedCountries.length > 0
        && selectedReportingCompanies.length > 0;

    useEffect(() => {
        setSelectedOptions([])
    }, [enabled])

    return (
        <PopoverAutocomplete
            disabled={!enabled}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            options={options}
            setOptions={setOptions}
            offset={offset}
            callback={callback}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            textContent={textContent}
            placeholder={placeholder}
            allSelectedText={allSelectedText}
            isLoading={isLoading}
        />
    )
};

export default PlaySourcesAutocomplete;