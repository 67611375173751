import { TableBody, TableCell, TableRow, makeStyles } from "@material-ui/core";
import { formatNumber } from "./Earnings";

const useStyles = makeStyles(() => ({
    blackRow: {
        backgroundColor: 'black'
    },
    cell: {
        border: 'none',
        textAlign: 'center'
    }
}));

const TableBodyPart = ({ rows, selectedRange }) => {
    const styles = useStyles();

    return (
        <TableBody>
            { rows.map(({ song, country, royaltyType, playSource, reportingCompany, period, revenue }, index) => (
                <TableRow key={index} className={`${index % 2 !== 0 ? styles.blackRow : ''}`} >
                    <TableCell className={styles.cell}>{song}</TableCell>
                    <TableCell className={styles.cell}>{country || 'Uncategorized'}</TableCell>
                    {/* <TableCell className={styles.cell}>2</TableCell> */}
                    <TableCell className={styles.cell}>{reportingCompany}</TableCell>
                    <TableCell className={styles.cell}>{playSource || 'Uncategorized'}</TableCell>
                    <TableCell className={styles.cell}>{selectedRange}</TableCell>
                    <TableCell className={styles.cell}>${revenue ? formatNumber(revenue) : '0.00'}</TableCell>
                </TableRow>
            )) }
        </TableBody>
    )
};

export default TableBodyPart;