import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Overview from './Overview';
import Details from './Details';
import Footer from './FooterOverview';
import { getData } from '../../utils/FetchUtils';

const useSongStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minWidth: '1275px'
    },
    close: {
        position: "absolute",
        right: 10,
        top: 10,
        '&:hover': {
            cursor: "pointer",
        }
    },
    icons: {
        marginRight: '6px',
    },
}));

const SelectedSongData = ({
    details,
    setDetails,
    setDisplayConnectSong,
    setSelectedSongToConnect,
    selectedSongModalRefresh,
    startQuarter,
    endQuarter,
    accountHolder
}) => {
    const styles = useSongStyles();
    const [reportingCompanies,setReportingCompanies] = useState();

    useEffect(()=>{
        getSongReportingCompanies();
    },[]);

    const getSongReportingCompanies = async () =>{
        const filterQuery = accountHolder ? `&accountHolderId=${accountHolder}` : '';
        const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/report/song-reporting-companies?songId=${details.id}${filterQuery}`).catch(error => console.error(error));
        setReportingCompanies(data.reportingCompanies);
    }

    return (
        <div className={styles.container}>
            <Overview
                details={details}
                setDisplayConnectSong={setDisplayConnectSong}
                setSelectedSongToConnect={setSelectedSongToConnect}
                reportingCompanies={reportingCompanies}
            />
            <Details details={details}/>
            <Footer
                details={details}
                setDetails={setDetails}
                selectedSongModalRefresh={selectedSongModalRefresh}
                startQuarter={startQuarter}
                endQuarter={endQuarter}
                accountHolder={accountHolder}
            />
        </div>
    );
}

export default SelectedSongData;