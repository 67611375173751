import { makeStyles } from "@material-ui/core";
import { participantTypeEnum } from "../../../utils/MusicShareEnums";
import { deleteData, getData, putData } from "../../../utils/FetchUtils";
import { useEffect, useState } from "react";
import clsx from 'clsx';
import { BrandInput } from "../../../CoreComponents/BrandInput";
import { BrandMenuItem, BrandSelect } from "../../../CoreComponents/BrandSelect";
import { textIsEmpty, validateRangeNumBetween0to100 } from "../../../utils/ValidationUtils";
import { reportNames } from "../../../utils/ReportNamesUtils";
import { formatNumberByMinMaxFractionDigits } from "../../../utils/NumberUtils";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { BrandPopover } from "../../../CoreComponents/BrandPopover";
import ActionButtons from "../../../CoreComponents/ActionButtons";

const dataValidators = {
    validateParticipantName: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
    validateSharePercentage: (value) => {
        const errors = []
        if (validateRangeNumBetween0to100(value)) {
            errors.push('Value must be between 0 and 100')
        }
        return errors;
    },

}

const useStyles = makeStyles((theme) => ({
    tableHeadersContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '52px',
        backgroundColor: theme.palette.primary.border,
        marginTop: '10px',
        paddingRight: '24px',
        font: 'normal normal bold 14px/19px Roboto',
        paddingLeft: '8px',
    },
    tableHeadersContainerUser: {
        display: 'flex',
        alignItems: 'center',
        font: 'normal normal bold 14px/19px Roboto',
        backgroundColor: theme.palette.primary.border,
        width: '100%',
        height: '52px',
        marginTop: '10px',
        margin: '15px 0px',
        paddingRight: '24px',
        paddingLeft: '8px',
    },
    header: {
        width: '200px',
        '&:last-child': {
            width: '100px',
            paddingLeft: '7em'
        },
        '&:first-child': {
            width: '300px',
        }
    },
    headerUser: {
        width: '200px',
        '&:last-child': {
            width: '100px',
            paddingLeft: '28.6em',
            '& .MuiInputBase-input': {
                textAlign: 'center',
                width: '80px'
            }
        },
        '&:first-child': {
            width: '300px',
        }
    },
    headerUserComposition: {
        width: '200px',
        '&:last-child': {
            width: '100px',
            '& .MuiInputBase-input': {
                textAlign: 'center',
                width: '80px'
            }
        },
        '&:first-child': {
            width: '300px',
        }
    },
    dataContianer: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '40px',
        justifyContent: 'space-between',
        // marginLeft: '8px'
    },
    dataRow: {
        display: 'flex',
        width: '100%',
        font: 'normal normal normal 14px/19px Roboto',
        alignItems: 'center',
        paddingLeft: '8px',
        '& .MuiInputBase-root': {
            width: '200px',
            '&:last-child': {
                width: 'max-content',
            },
            '& .MuiInputBase-input': {
                textAlign: 'center',
            }
        },
        '& div:last-child': {
            textAlign: 'end',
            paddingRight: '48px'
        },
    },
    sharesDataContainer: {
        overflow: 'auto',
        maxHeight: '170px',
        paddingRight: '24px'
    },
    sharesDataContainerAdmin: {
        maxHeight: '140px'
    },
    gradient: {
        background: 'transparent linear-gradient(90deg, #8124BD 0%, #3EA1D9 100%) 0px 0px no-repeat padding-box',
        color: 'transparent',
    },
    gradientUser: {
        width: '200px !important',
        paddingLeft: '0em !important'
    },
    infoIcon: {
        fontSize: '12px'
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    }

}));

const MAX_CHARS = 255;
const MIN_DIGITS = 5;
const MAX_DIGITS = 5;

export default function SharesTable({
    tableHeaders,
    sharesData,
    originalData,
    setOriginalData,
    fetchData,
    setEditAlert,
    setSuccessMsg,
    editAlert,
    setIsLoading,
    isAdmin,
    isInManageShares
}) {
    const styles = useStyles();
    const [isClicked, setIsClicked] = useState(false);
    const [btnClicked, setBtnClicked] = useState({});
    const [reportingTypes, setReportingTypes] = useState([]);
    const [selectedReportingType, setSelectedReportingType] = useState('');
    const [selectedParticipantType, setSelectedParticipantType] = useState('');
    const [selectedParticipantTypeId, setSelectedParticipantTypeId] = useState('');
    const participantNameValue = useState('');
    const participantNamePassed = useState(true);
    const sharePercentageValue = useState('');
    const sharePercentagePassed = useState(true);

    const isCompositionTable = tableHeaders.length > 3;

    useEffect(() => {
        if (!participantNamePassed[0] || participantNameValue[0].length === MAX_CHARS) {
            setEditAlert(`Value must not be empty or longer than ${MAX_CHARS} characters`)
        } else if (!sharePercentagePassed[0]) {
            setEditAlert('Value must be between 0 and 100')
        } else {
            setEditAlert('')
        }

    }, [participantNamePassed, sharePercentagePassed])

    const deleteRow = (id) => {
        setIsClicked(!isClicked);
        setIsLoading(true);
        deleteData(process.env.REACT_APP_SERVER_HOST + '/api/music-shares/delete-music-shares-data', { id })
            .then(res => {
                const filteredData = originalData.filter(obj => obj.id !== id);
                setOriginalData(filteredData);
                setSuccessMsg('Successfully deleted!');
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err.message);
                setEditAlert(err.message);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + "/api/admin/reporting-companies")
            .then(reportingCompaniesData => {
                setReportingTypes(reportingCompaniesData.companies);
            })
            .catch(err => {
                setEditAlert(err.message);
            })
    }, [])

    const deleteHandler = (id) => {
        deleteRow(id);
    };

    const editHandler = () => {
        setIsClicked(!isClicked);

        if (editAlert.length) return;
        setIsLoading(true);

        const updatedColumns = {
            id: btnClicked.id,
            reportingCompanyId: selectedReportingType,
            participantName: participantNameValue[0],
            participantType: selectedParticipantTypeId,
            sharePercentage: sharePercentageValue[0]
        }
        putData(process.env.REACT_APP_SERVER_HOST + `/api/music-shares/update-music-shares-data`, updatedColumns)
            .then(res => {
                setSuccessMsg('Changes applied successfully!');
                fetchData();
                setIsLoading(false);
            })
            .catch(err => {
                setEditAlert(err.message);
                setIsLoading(false);
            })
    }

    const clickHandler = (btnValue, id) => {
        setIsClicked(!isClicked);
        setBtnClicked({ btnValue, id });
        sharePercentagePassed[1](true)
        participantNamePassed[1](true)

        if (!id) return;

        const { reportingCompanyId, participantType, participantName, sharePercentage } = sharesData.filter(x => x.id === id)[0]

        setSelectedReportingType(reportingCompanyId === 0 ? 1 : reportingCompanyId ?? 1);
        setSelectedParticipantType(participantTypeEnum[participantType]);
        setSelectedParticipantTypeId(participantType);
        participantNameValue[1](participantName);
        sharePercentageValue[1](sharePercentage);
    }

    return (
        <div>
            <div className={isInManageShares ? styles.tableHeadersContainer : styles.tableHeadersContainerUser}>
                {
                    tableHeaders.map(header => {
                        return (
                            <div
                                key={`${Math.random()}${header}`}
                                className={isCompositionTable ? styles.headerUserComposition : styles.headerUser}>{header}</div>
                        )
                    })
                }
            </div>
            <div className={styles.sharesDataContainer}>
                {
                    sharesData.map(obj => {
                        return (
                            <div
                                key={obj.id}
                                className={styles.dataContianer}
                            >
                                <div className={styles.dataRow}>
                                    {
                                        isClicked && btnClicked.btnValue === 'edit' && btnClicked.id === obj.id ?
                                            <>
                                                <BrandInput
                                                    $value={participantNameValue}
                                                    onChange={(e) => participantNameValue[1](e.target.value)}
                                                    required
                                                    validator={dataValidators.validateParticipantName}
                                                    validatorPassed={participantNamePassed}
                                                    maxLength={MAX_CHARS}

                                                />
                                                <BrandSelect
                                                    label=""
                                                    value={selectedParticipantType}
                                                    onChange={(e) => {
                                                        setSelectedParticipantType(e)
                                                        setSelectedParticipantTypeId(Object.values(participantTypeEnum).findIndex(x => x === e) + 1)
                                                    }}
                                                    className={styles.participantTypeSelect}
                                                >
                                                    {
                                                        isCompositionTable ?
                                                            Object.values(participantTypeEnum).map((x, id) => {
                                                                return (
                                                                    id < 2 ?
                                                                        <BrandMenuItem key={x} value={x}>
                                                                            {x}
                                                                        </BrandMenuItem>
                                                                        :
                                                                        ''
                                                                )
                                                            })
                                                            :
                                                            Object.values(participantTypeEnum).map((x, id) => {
                                                                return (
                                                                    id > 1 ?
                                                                        <BrandMenuItem key={x} value={x}>
                                                                            {x}
                                                                        </BrandMenuItem>
                                                                        :
                                                                        ''
                                                                )
                                                            })
                                                    }
                                                </BrandSelect>
                                            </>
                                            :
                                            <>
                                                <div className={clsx(styles.header, styles.infoContainer)}>
                                                    <div>
                                                        {obj.participantName}

                                                    </div>
                                                    {
                                                        isCompositionTable ?
                                                            <BrandPopover
                                                                popoverData={obj.ipiNumber ? `IPI Number : ${obj.ipiNumber}` : `IPI Number: Unknown `}
                                                                popoverDataFormated={<InfoOutlinedIcon className={styles.infoIcon} />}
                                                            />
                                                            :
                                                            ''
                                                    }
                                                </div>
                                                <div className={styles.header}>{participantTypeEnum[obj.participantType]}</div>
                                            </>
                                    }
                                    {
                                        isCompositionTable ?
                                            <>
                                                {
                                                    isClicked && btnClicked.btnValue === 'edit' && btnClicked.id === obj.id ?
                                                        <BrandSelect
                                                            label=""
                                                            value={selectedReportingType}
                                                            onChange={(e) => setSelectedReportingType(e)}
                                                            className={styles.reportingCompaniesSelect}
                                                        >
                                                            {reportingTypes.map((x) => {
                                                                return (<BrandMenuItem key={x.id} value={x.id}>
                                                                    {x.name}
                                                                </BrandMenuItem>)
                                                            })}
                                                        </BrandSelect>

                                                        :
                                                        <div className={styles.header}>{obj.reportingCompanyName && obj?.reportingCompanyName !== 'EMPTY_COMPANY' ? obj.reportingCompanyName : 'Unknown'}</div>
                                                }
                                                {
                                                    // isClicked && btnClicked.btnValue === 'edit' && btnClicked.id === obj.id ?
                                                    //     <BrandSelect
                                                    //         label=""
                                                    //         value={selectedReportingType}
                                                    //         onChange={(e) => setSelectedReportingType(e)}
                                                    //         className={styles.reportingCompaniesSelect}
                                                    //     >
                                                    //         {reportNames.map((x) => {
                                                    //             return (<BrandMenuItem key={x.id} value={x.id}>
                                                    //                 {x.name}
                                                    //             </BrandMenuItem>)
                                                    //         })}
                                                    //     </BrandSelect>

                                                    //     :
                                                    <div className={styles.header}>{reportNames.filter(company => company.id === obj.reportingType)[0]?.name || 'Unknown'}</div>
                                                }
                                            </>
                                            :
                                            ''
                                    }
                                    {
                                        isClicked && btnClicked.btnValue === 'edit' && btnClicked.id === obj.id ?
                                            <div className={isCompositionTable ? styles.headerUserComposition : styles.headerUser}>
                                                <BrandInput
                                                    $value={sharePercentageValue}
                                                    onChange={(e) => sharePercentageValue[1](e.target.value)}
                                                    required
                                                    validator={dataValidators.validateSharePercentage}
                                                    validatorPassed={sharePercentagePassed}
                                                />
                                            </div>
                                            :
                                            <div className={isCompositionTable ? styles.headerUserComposition : styles.headerUser}>
                                                {formatNumberByMinMaxFractionDigits(obj.sharePercentage, MIN_DIGITS, MAX_DIGITS)}%
                                            </div>
                                    }
                                </div>
                                {
                                    isAdmin && isInManageShares ?
                                        <ActionButtons 
                                        isActionClicked={isClicked}
                                        actionButtonTypeClicked={btnClicked}
                                        tableData={obj}
                                        clickHandler={clickHandler}
                                        onDelete={deleteHandler}
                                        onEdit={editHandler}
                                        editAlert={editAlert}
                                        />
                                        :
                                        ""
                                }
                                {
                                    !isInManageShares ?
                                        <div className={clsx(styles.header, isInManageShares ? "" : styles.gradientUser)}>
                                            <div className={styles.gradient} style={{ width: `${obj.sharePercentage}%` }}>
                                                text
                                            </div>
                                        </div>
                                        :
                                        ''
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}