import { 
    Button, 
    Typography,
    makeStyles 
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '2.5px 20px',
        borderRadius: theme.spacing(2.5),
        border: '1px solid gray',
        width: '270px',
        maxWidth: '270px',
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&.Mui-disabled': {
            color: 'grey'
        }
    },
    icon: {
        marginLeft: 'auto',
        marginRight: '-10px'
    }
}));

const ExpandMoreButton = ({ 
    onClick,
    textContent
}) => {
    const styles = useStyles();

    return (
        <Button
            className={styles.button}
            onClick={onClick}
        >
            <Typography>
                { textContent ?? 'Select' }
            </Typography>
            <ExpandMore  
                fontSize="large" 
                className={styles.icon} 
            />
        </Button>
    )
}

export default ExpandMoreButton;