import { TextareaAutosize, Typography, makeStyles } from "@material-ui/core";
import { BrandButton } from "../../../CoreComponents/BrandButton";
import { useState } from "react";
import DropZone from "../../../upload/DropZone";
import { UploadRegistrationFileList } from "./UploadRegistrationFileList";
import { isCSVFile } from "../../../utils/ValidationUtils";
import WarningIcon from '@material-ui/icons/Warning';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDownloadCSV } from "../../../../hooks/useDownloadCSV";

const useStyles = makeStyles((theme) => ({
    mainUploadContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    uploadSharesContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '1200px',
        justifyContent: 'space-around'
    },
    uploadBtn: {
        width: '158px',
        height: '42px',
        '& .MuiButton-label': {
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 'normal',
            letterSpacing: '0.49px',
            color: theme.palette.background.default
        }
    },
    dropZoneContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '50px',
        '& section > div': {
            margin: '20px 20px 20px 0px',
            height: '173px',
            width: '256px'
        },
        '& section > div > svg': {
            fontSize: '60px'
        },
        '& section > div > p': {
            fontSize: '14px'
        }
    },
    text: {
        display: 'flex',
        color: theme.palette.primary.attention,
        margin: '32px 0px',
        alignItems: 'center',
        gap: '10px'
    },
    downloadTemplate: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer'
    },
    titles: {
        font: 'normal normal normal 20px/19px Roboto',
        letterSpacing: '2px'
    },
    textarea: {
        marginTop: 20,
        padding: 30,
        width: '500px',
        minHeight: '115px',
        maxHeight: '115px',
        backgroundColor: 'inherit',
        borderRadius: '10px',
        outline: 'none',
        color: theme.palette.text.primary,
        "&::placeholder": {
            fontSize: "16px"
        },
        overflow: 'auto !important'
    },
    notes: {
        marginTop: '30px',
        borderTop: `2px solid ${theme.palette.primary.border}`,
        padding: '50px 82px'
    },
    buttonContainer: {
        display: 'flex',
        gap: '50px',
        marginTop: '20px'
    },
    cancelBtn: {
        color: `${theme.palette.text.primary} !important`,
        border: `1px solid ${theme.palette.text.primary} !important`,
        backgroundColor: `${theme.palette.background.default} !important`,
        width: '135px',
        height: '42px',
        font: 'normal normal normal 14px/19px Roboto !important',
        textTransform: 'none'

    },
    addBtn: {
        width: '135px',
        height: '42px',
        color: `${theme.palette.background.default} !important`,
        font: 'normal normal normal 14px/19px Roboto !important',
        textTransform: 'none',
        '&:disabled': {
            backgroundColor: `${theme.palette.primary.lightGray} !important`,
            '& .MuiButton-label': {
                color: `${theme.palette.background.default} !important`,
            }
        }
    },
    selectedSong: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    close: {
        position: 'absolute',
        top: '10px',
        right: '20px',
        margin: '5px',
        cursor: 'pointer'
    }

}));

export default function UploadShares({
    details,
    onClose,
    refresh,
    setRefresh
}) {
    const styles = useStyles();
    const [uploadedFile, setUploadedFile] = useState('');
    const [note, setNote] = useState('');
    const downloadCSV = useDownloadCSV();

    const textAreaHandler = (e) => {
        setNote(e.target.value);
    };

    const handleDrop = (files) => {

        const csvFile = files
            .filter(isCSVFile)

        setUploadedFile(csvFile);
    };

    async function downloadTemplate() {

        const dataToDownload = [{
            "Participant Name": 'BRIAN DOUGLAS WILSON',
            "Usage": 'Master, Compostion',
            "Participant Type": 'Songwriter/Composer, Publisher, Artist, Rightsholder',
            "Affiliation": 'ASCAP, BMI',
            "Shares": '15%'
        }]


        downloadCSV(dataToDownload, 'Shares_CSV_Template');
    }

    return (
        <div className={styles.mainUploadContainer}>
            <div className={styles.uploadSharesContainer}>
                <div>
                    <div>
                        <Typography
                            variant="h5"
                            style={{ paddingBottom: 20 }}
                        >
                            Upload CSV
                        </Typography>
                        <HighlightOffIcon color='primary' className={styles.close} fontSize='large' onClick={() => onClose()} />
                    </div>
                    <Typography
                        variant="h6"
                        style={{ paddingBottom: 20 }}
                    >
                        Select CSV to Upload
                    </Typography>
                    <div className={styles.text}>
                        <WarningIcon />
                        <div>
                            Uploaded CSV data will replace current shares data excluding.
                        </div>
                    </div>
                    <div className={styles.dropZoneContainer}>
                        <DropZone
                            className={styles.songsRegistrationDropZone}
                            handleDrop={handleDrop}
                            acceptedFormats={{ 'text/csv': ['.csv'] }}
                        />
                        <UploadRegistrationFileList
                            uploadedFile={uploadedFile}
                            setUploadedFile={setUploadedFile}
                            details={details}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            onClose={onClose}
                        />
                    </div>
                    <div className={styles.downloadTemplate} onClick={downloadTemplate}>
                        <VerticalAlignBottomIcon />
                        <p>Download CSV template</p>
                    </div>
                </div>
                <div className={styles.selectedSong}>
                    <div>
                        <b>Selected song that will be affected:</b>
                    </div>
                    <div>
                        {details.title}
                    </div>
                </div>
            </div>
            {/* <div className={styles.notes}>
                <Typography
                    className={styles.titles}
                    component='div'
                    variant='h5'
                >
                    Leave Note (optional)
                </Typography>
                <TextareaAutosize
                    value={note}
                    onChange={textAreaHandler}
                    className={styles.textarea}
                    placeholder="Click here to leave a note with more information."
                />
                <div className={styles.buttonContainer}>
                    <BrandButton
                        className={styles.cancelBtn}
                        onClick={cancelHandler}
                    >
                        Cancel
                    </BrandButton>
                    <BrandButton
                        className={styles.addBtn}
                        onClick={addHandler}
                        disabled={disableAdd}
                    >
                        Add
                    </BrandButton>
                </div>
            </div> */}
        </div>
    );
}