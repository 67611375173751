import { useEffect, useRef, useState } from "react"

export const useInfiniteScrolling = (ref, callback) => {
    const [isFetching, setIsFetching] = useState(false);
    const disabledRef = useRef(false);

    useEffect(() => {
        if (!isFetching) return;
        callback();
    }, [isFetching]);

    const handleScroll = () => {
        const element = ref.current;
        const disabled = disabledRef.current;

        const { 
            scrollTop, 
            clientHeight, 
            scrollHeight 
        } = element;

        if (disabled || scrollTop + clientHeight < scrollHeight) return;

        setIsFetching(true);
    }

    useEffect(() => {
        const element = ref.current;

        if (!element) return;

        element.addEventListener('scroll', handleScroll);

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        }
    }, []);

    const setIsDisabled = (value) => disabledRef.current = value;

    return [setIsFetching, setIsDisabled];
}