import * as Papa from 'papaparse';

export const useDownloadCSV = () =>{
    function downloadCSV(dataToDownload,fileName){
        if(!dataToDownload || !fileName){
            throw new Error("dataToDownload and fileName is required")
        }
        const asJsonData = JSON.stringify(dataToDownload);
        const asCsvData = Papa.unparse(asJsonData);
        const csvFile = new File([asCsvData], `${fileName}.csv`, {
            type: "text/plain",
        });
        const url = window.URL.createObjectURL(csvFile);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
    return downloadCSV;
}
