import React, { useEffect, useState, useMemo, useRef } from 'react';
import { debounce, getData, postData, putData } from '../utils/FetchUtils'
import { BrandButton, SecondaryBrandButton, TempBrandButton, TempBrandDeleteButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, List, ListItemAvatar, Typography } from '@material-ui/core';
import { BrandSearchInput } from '../CoreComponents/BrandInput';
import { IconButton } from '@material-ui/core';
import { ListItem, ListItemText } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { BrandCheckbox } from '../CoreComponents/BrandCheckbox';
import AlbumIcon from '@material-ui/icons/Album';
import { BrandModal } from '../CoreComponents/BrandModal';
import { BrandLoader } from '../CoreComponents/BrandLoader';
import { BrandAlert } from '../CoreComponents/BrandAlert';
import { useDebounce } from '../../hooks/useDebounce';
import { useAsyncAction } from '../../hooks/useAsyncAction';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';
import { useInfiniteScrolling } from '../../hooks/useInfiniteScrolling';
import { BrandTooltip } from '../CoreComponents/BrandTooltip';
import { ExactWordTooltipMessage } from '../../constants/messageConstants';
import { ButtonSize } from '../../constants/buttonConstants';

const useStyles = makeStyles(theme => ({
    tabLayout: {
        display: 'flex',
        width: '1265px',
        justifyContent: 'space-between',
        gap: theme.spacing(3),
    },
    modalLayout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    search: {
        display: 'flex',
        flexDirection: 'row',
        "& .MuiInputBase-root": {
            width: '100%',
        }
    },
    list: {
        width: '100%',
        height: theme.spacing(50),
        overflow: 'auto'
    },
    accHolderActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        '& .MuiButton-outlinedPrimary:hover': {
            border: `1px solid ${theme.palette.text.lightYellow}`
        },
        '& button': {
            width: '183px',
        }
    },
    root: {
        paddingLeft: theme.spacing(4)
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        '& .Mui-disabled':{
            color: theme.palette.primary.lightGray
        }
    },
    deleteText: {
        marginBottom: theme.spacing(5)
    },
    disabledOutlined: {
        '& .MuiButtonBase-root': {
            border: `1px solid ${theme.palette.primary.darkGreen}`,
            backgroundColor: 'transparent',
            '& .MuiButton-label': {
                color: theme.palette.primary.darkGreen
            }
        }
    },
    uploading: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
    attachSongButtonAndAlertContainer: {
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'column',
        alignItems: 'end',
        '& button': {
            width: '163px',
        }

    },
    doneAndDiscardButtons: {
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        color: theme.palette.text.dark,
        font: 'normal normal normal 14px Roboto',
        textTransform: 'none'
    },
    cancelButton: {
        color: theme.palette.text.lightYellow,
        borderColor: theme.palette.text.lightYellow,
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        font: 'normal normal normal 14px Roboto',
        textTransform: 'none'
    },
}));

const ManageSongs = ({ accountHolder, onClose }) => {
    const styles = useStyles();

    // TODO: rename variable :)
    const [initiallyAttachedSongs, setInitiallyAttachedSongs] = useState([]);
    const [discardDisabled, setDiscardDisabled] = useState(true);
    const [openDiscardModal, setOpenDiscardModal] = useState(false);

    const [enableRefreshAttachedSongs, setEnableRefreshAttachedSongs] = useState(false);
    const [enableRefreshDetachedSongs, setEnableRefreshDetachedSongs] = useState(false);

    const discardChanges = async () => {
        const body = {
            accountHolderId: accountHolder.id,
            songIds: initiallyAttachedSongs
        };

        try {
            await putData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/discard-attached-and-detached-songs`, body);
        } catch (err) {
            console.log(err.message);
        } finally {
            setEnableRefreshAttachedSongs(true);
            setEnableRefreshDetachedSongs(true);
            setDiscardDisabled(true);
            setOpenDiscardModal(false);
        }
    }

    return (
        <div>
            <BrandModal open={openDiscardModal} onClose={() => setOpenDiscardModal(false)}>
                <div>
                    <Typography variant="h5" style={{ paddingBottom: 20 }} >
                        Discard Changes
                    </Typography>
                    <div className={styles.deleteText}>
                        Are you sure you want to discard all attached and detached songs?
                    </div>
                    <div className={styles.accHolderActions}>
                        <TempBrandButton
                            // className={styles.cancelButton}
                            size={ButtonSize.SMALL}
                            variant='outlined'
                            onClick={() => setOpenDiscardModal(false)}
                        >
                            Cancel
                        </TempBrandButton>
                        <TempBrandButton
                            // className={styles.doneAndDiscardButtons}
                            size={ButtonSize.SMALL}
                            disabled={discardDisabled}
                            onClick={async () => await discardChanges()}
                        >
                            Discard
                        </TempBrandButton>
                    </div>
                </div>
            </BrandModal>
            <div className={styles.tabLayout}>
                <DetachedSongs
                    refreshDetached={enableRefreshDetachedSongs}
                    setRefreshDetached={setEnableRefreshDetachedSongs}
                    setRefreshAttached={setEnableRefreshAttachedSongs}
                    accountHolder={accountHolder}
                    setDiscardDisabled={setDiscardDisabled}
                    setInitiallyAttachedSongs={setInitiallyAttachedSongs}
                />
                <AttachedSongs
                    refreshAttached={enableRefreshAttachedSongs}
                    setRefreshAttached={setEnableRefreshAttachedSongs}
                    setRefreshDetached={setEnableRefreshDetachedSongs}
                    accountHolder={accountHolder}
                    setInitiallyAttachedSongs={setInitiallyAttachedSongs}
                    initiallyAttachedSongs={initiallyAttachedSongs}
                    setDiscardDisabled={setDiscardDisabled}
                />
            </div>
            <div className={styles.accHolderActions}>
                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        variant='outlined'
                        disabled={discardDisabled}
                        onClick={() => setOpenDiscardModal(true)}
                    >
                        Discard Changes
                    </TempSecondaryBrandButton>
                <TempBrandButton
                    size={ButtonSize.SMALL}
                    onClick={() => onClose()}
                >
                    Done
                </TempBrandButton>
            </div>

        </div>
    );
}

const DetachedSongs = ({ 
    accountHolder, 
    refreshDetached,
    setRefreshAttached,
    setRefreshDetached,
    setInitiallyAttachedSongs
}) => {
    const LIMIT = 7;
    const styles = useStyles();

    const isFirstRender = useIsFirstRender();

    const [artistItems, setArtistItems] = useState([]);
    const [selectedSongs, setSelectedSongs] = useState({});
    const [refresh, setRefresh] = useState({});

    const [search, setSearch] = useState('');
    const searchQuery = useDebounce(search, 500);

    const [successMessage, setSuccessMessage] = useState('');

    const [selectAll, setSelectAll] = useState(false);
    const [songsCount, setSongsCount] = useState(0);
    const [disabled, setDisabled] = useState(true);

    const elementRef = useRef(null);
    const offsetRef = useRef(0);

    const [setIsFetching, setIsDisabled] = useInfiniteScrolling(elementRef, async () => await handleFetchData());

    async function handleFetchData() {
        try {
            const { items, total, offset } = await getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/songs-search?offset=${offsetRef.current}&limit=${LIMIT}&filter=${searchQuery}&accountHolder=${accountHolder.id}`);

            if (items.length === 0) {
                setIsDisabled(true);
                return;
            }

            setArtistItems((prev) => prev.concat(items));
            setSongsCount((prev) => prev + total);

            offsetRef.current = offset;
            setIsFetching(false);
            setIsDisabled(false);
        } catch (err) {
            console.log(err.message);
        } 
    }

    useEffect(() => {
        offsetRef.current = 0;
        setArtistItems([]);
        handleFetchData();
        setSongsCount(0);
    }, [searchQuery]);

    useEffect(() => {
        if (!refreshDetached) return;

        setIsDisabled(true);
        offsetRef.current = 0;
        setSearch('');
        setArtistItems([]);
        setSelectAll(false);
        setSongsCount(0);
        handleFetchData();
        setRefreshDetached(false);
    }, [refreshDetached]);

    useEffect(() => {
        if (!successMessage) return;

        setTimeout(() => setSuccessMessage(''), 4000);
    }, [successMessage]);

    const { trigger, loading } = useAsyncAction(async () => {
        const body = {
            accountHolderId: accountHolder.id,
            songIds: selectAll ? [] : Object.keys(selectedSongs),
            selectAll: selectAll,
            filter: searchQuery
        };

        const data = await postData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/songs`, body);

        if (!data.success) return;

        setInitiallyAttachedSongs(Object.keys(selectedSongs));
        setSuccessMessage('Attached and saved successfully');
        setSearch('');
        setSelectedSongs({});
        setDisabled(true);
        setRefresh({});
        setArtistItems([]);
        setSelectAll(false);
        setSongsCount(0);
        offsetRef.current = 0;
        setRefreshAttached(true);
        setIsDisabled(true);
        handleFetchData();
    });

    const handleSelectAllOnChange = (event) => {
        const selected = event.target.checked;

        setSelectAll(selected);

        if (!selected) {
            setSelectedSongs({});
        } else {
            const temp = { };

            for (const { albums } of artistItems) {
                for (const { tracks } of albums) {
                    for (const { id } of tracks) {
                        temp[id] = true;
                    }
                }
            }

            setSelectedSongs(temp);
        }

        setRefresh({});
    }

    useEffect(() => {
        if (isFirstRender) return;

        const selectedCount = Object.keys(selectedSongs).length;
        
        setDisabled(selectedCount === 0);
        if (songsCount > 0)
            setSelectAll(selectedCount === songsCount);
    }, [selectedSongs, refresh]);

    return (
        <div className={styles.modalLayout}>
            <div className={styles.search}>
                <BrandSearchInput 
                    value={search} 
                    onChange={(e) => setSearch(e.target.value)} 
                />
                <BrandTooltip title={ExactWordTooltipMessage} />
            </div>
            <div className={styles.toolbar}>
                <BrandCheckbox
                    checked={selectAll} 
                    onChange={handleSelectAllOnChange} 
                    label='Select All' 
                    disabled={loading}
                />
                <div className={styles.attachSongButtonAndAlertContainer}>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        disabled={disabled}
                        onClick={trigger}
                    >
                        { loading ? (
                            <div className={styles.uploading}>
                                <BrandLoader color='white' width={20} height={20} />
                                <div>Attaching</div>
                            </div>
                        ) : 'Attach Songs' }
                    </TempBrandButton>
                    { successMessage && (
                        <BrandAlert success={true}>{successMessage}</BrandAlert>
                    ) }
                </div>
            </div>
            <List className={styles.list} ref={elementRef}>
                { artistItems.map((artist, i) => {
                    return (
                        <NestedListItem
                            key={artist.name + i}
                            item={artist}
                            itemType='artist'
                            setSelectedItems={setSelectedSongs}
                            selectedItems={selectedSongs}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            selectAll={selectAll}
                            setSelectAll={setSelectAll}
                        />
                    );
                })}
            </List>
        </div>
    );
}

const AttachedSongs = ({ 
    accountHolder, 
    setRefreshDetached,
    refreshAttached,
    setRefreshAttached
}) => {
    const LIMIT = 7;
    const styles = useStyles();

    const isFirstRender = useIsFirstRender();

    const [artistItems, setArtistItems] = useState([]);
    const [selectedSongs, setSelectedSongs] = useState({});
    const [refresh, setRefresh] = useState({});

    const [search, setSearch] = useState('');
    const searchQuery = useDebounce(search, 500);

    const [successMessage, setSuccessMessage] = useState('');

    const [selectAll, setSelectAll] = useState(false);
    const [songsCount, setSongsCount] = useState(0);
    const [disabled, setDisabled] = useState(true);

    const elementRef = useRef(null);
    const offsetRef = useRef(0);

    const [setIsFetching, setIsDisabled] = useInfiniteScrolling(elementRef, async () => await handleFetchData());

    async function handleFetchData() {
        try {
            const { items, total } = await getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/songs?accountHolderId=${accountHolder.id}&offset=${offsetRef.current}&limit=${LIMIT}&filter=${searchQuery}`);

            if (items.length === 0) {
                setIsDisabled(true);
                return;
            }

            setArtistItems((prev) => prev.concat(items));
            setSongsCount((prev) => prev + total);

            offsetRef.current += LIMIT;
            setIsFetching(false);
            setIsDisabled(false);
        } catch (err) {
            console.log(err.message);
        }
    }
    
    useEffect(() => {
        offsetRef.current = 0;
        setArtistItems([]);
        handleFetchData();
        setSongsCount(0);
    }, [searchQuery]);

    useEffect(() => {
        if (!refreshAttached) return;

        setIsDisabled(true);
        setSelectAll(false);
        setSongsCount(0);
        setSearch('');
        offsetRef.current = 0;
        setArtistItems([]);
        handleFetchData();
        setRefreshAttached(false);
    }, [refreshAttached]);

    useEffect(() => {
        if (!successMessage) return;

        setTimeout(() => setSuccessMessage(''), 4000);
    }, [successMessage]);

    const { trigger, loading } = useAsyncAction(async () => {
        const body = {
            accountHolderId: accountHolder.id,
            songIds: selectAll ? [] : Object.keys(selectedSongs),
            selectAll: selectAll,
            filter: searchQuery
        };

        const data = await putData(process.env.REACT_APP_SERVER_HOST + '/api/account-holder', body);

        if (!data.success) return;

        setSuccessMessage('Detached and saved successfully');
        setSearch('');
        setSelectedSongs({});
        setDisabled(true);
        setRefresh({});
        setArtistItems([]);
        setSelectAll(false);
        setSongsCount(0);
        offsetRef.current = 0;
        setRefreshDetached(true);
        setIsDisabled(true);
        handleFetchData();
    });

    const handleSelectAllOnChange = (event) => {
        const selected = event.target.checked;

        setSelectAll(selected);

        if (!selected) {
            setSelectedSongs({});
        } else {
            const temp = { };

            for (const { albums } of artistItems) {
                for (const { tracks } of albums) {
                    for (const { id } of tracks) {
                        temp[id] = true;
                    }
                }
            }

            setSelectedSongs(temp);
        }

        setRefresh({});
    }

    useEffect(() => {
        if (isFirstRender) return;

        const selectedCount = Object.keys(selectedSongs).length;
        
        setDisabled(selectedCount === 0);
        if (songsCount > 0)
            setSelectAll(selectedCount === songsCount);
    }, [selectedSongs, refresh])

    return (
        <div className={styles.modalLayout}>
            <div className={styles.search}>
                <BrandSearchInput
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <BrandTooltip title={ExactWordTooltipMessage}/> 
            </div>
            <div className={styles.toolbar}>
                <BrandCheckbox 
                    checked={selectAll} 
                    onChange={handleSelectAllOnChange} 
                    label='Select All' 
                    disabled={loading}
                />
                <div className={styles.attachSongButtonAndAlertContainer}>
                    <TempBrandDeleteButton
                        size={ButtonSize.SMALL}
                        disabled={disabled}
                        onClick={trigger}
                    >
                        { loading ? (
                            <div className={styles.uploading}>
                                <BrandLoader color='white' width={20} height={20} />
                                <div>Detaching</div>
                            </div>
                        ) : 'Detach Songs' }
                    </TempBrandDeleteButton>
                    {successMessage ? <BrandAlert success={true}>{successMessage}</BrandAlert> : null}
                </div>
            </div>
            <List className={styles.list} ref={elementRef}>
                {artistItems.map((artist, i) => {
                    return (
                        <NestedListItem
                            key={artist.name + i}
                            item={artist}
                            itemType='artist'
                            setSelectedItems={setSelectedSongs}
                            selectedItems={selectedSongs}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            selectAll={selectAll}
                            setSelectAll={setSelectAll}
                        />
                    );
                })}
            </List>
        </div>
    );
}

const NestedListItem = ({ item, itemType, setSelectedItems, selectedItems, refresh, setRefresh }) => {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);

    const onClickExpand = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    const onCheck = (e) => {
        e.stopPropagation();
        if (!checked) {
            if (itemType === 'artist') {
                const trackObj = {}
                for (const album of item.albums) {
                    for (const track of album.tracks) {
                        trackObj[track.id] = true;
                    }
                }
                setSelectedItems(Object.assign(trackObj, selectedItems));
            } else if (itemType === 'album') {
                const trackObj = {}
                for (const track of item.tracks) {
                    trackObj[track.id] = true;
                }
                setSelectedItems(Object.assign(trackObj, selectedItems));
            } else if (itemType === 'track') {
                setSelectedItems(song => (song[item.id] = true, { ...selectedItems }));
            } else if (itemType === 'period') {
                const reportObj = {}
                for (const report of item) {
                    reportObj[report.id] = true;
                }
                setSelectedItems(Object.assign(reportObj, selectedItems));
            } else if (itemType === 'report') {
                setSelectedItems(report => (report[item.id] = true, { ...selectedItems }));
            }
        } else {
            if (itemType === 'artist') {
                const trackObj = selectedItems
                for (const album of item.albums) {
                    for (const track of album.tracks) {
                        delete trackObj[track.id];
                    }
                }
                setSelectedItems(trackObj);
            } else if (itemType === 'album') {
                const trackObj = selectedItems;
                for (const track of item.tracks) {
                    delete trackObj[track.id];
                }
                setSelectedItems(trackObj);
            } else if (itemType === 'track') {
                const trackObj = selectedItems;
                delete trackObj[item.id];
                setSelectedItems(trackObj);
            } else if (itemType === 'period') {
                const reportObj = selectedItems;
                for (const report of item) {
                    delete reportObj[report.id];
                }
                setSelectedItems(reportObj);
            } else if (itemType === 'report') {
                const reportObj = selectedItems;
                delete reportObj[item.id];
                setSelectedItems(reportObj);
            }
        }
        setChecked(!checked);
        setRefresh({});
    }

    useEffect(() => {
        if (itemType === 'artist') {
            let tmpChecked = true
            outer:
            for (const album of item.albums) {
                for (const track of album.tracks) {
                    if (!selectedItems[track.id]) {
                        tmpChecked = false;
                        break outer;
                    }
                }
            }
            setChecked(tmpChecked);
        } else if (itemType === 'album') {
            let tmpChecked = true;
            for (const track of item.tracks) {
                if (!selectedItems[track.id]) {
                    tmpChecked = false;
                    break;
                }
            }
            setChecked(tmpChecked);
        } else if (itemType === 'track') {
            setChecked(selectedItems[item.id] ? true : false)
        } else if (itemType === 'period') {
            let tmpChecked = true;
            for (const report of item) {
                if (!selectedItems[report.id]) {
                    tmpChecked = false;
                    break;
                }
            }
            setChecked(tmpChecked);
        } else if (itemType === 'report') {
            setChecked(selectedItems[item.id] ? true : false)
        }

    }, [refresh])

    return (
        <>
            <ListItem button onClick={itemType !== 'track' && itemType !== 'report' ? onClickExpand : onCheck}>
                <BrandCheckbox checked={checked} onClick={(e) => e.stopPropagation()} onChange={onCheck} />

                {itemType === 'album' ?
                    <ListItemAvatar>
                        {item.coverArt ?
                            <Avatar
                                variant='square'
                                alt={`Avatar of ${item.name}`}
                                src={item.coverArt}
                            />
                            :
                            <AlbumIcon fontSize='large' />
                        }
                    </ListItemAvatar>
                    :
                    null
                }
                {itemType === 'artist' ?
                    <ListItemAvatar>
                        <Avatar
                            variant='square'
                            alt={`Avatar of ${item.name}`}
                            src={item.coverArt || null}
                        />
                    </ListItemAvatar>
                    :
                    null
                }
                <ListItemText primary={
                    itemType === 'period' ?
                        `Reporting Period: ${item[0].reportingPeriod}`
                        :
                        itemType === 'report' ?
                            item.fileName
                            :
                            item.name
                } />
                {itemType !== 'track' && itemType !== 'report' ?
                    open ?
                        <IconButton style={{ color: 'white' }} onClick={onClickExpand}>
                            <ExpandLess />
                        </IconButton>
                        :
                        <IconButton style={{ color: 'white' }} onClick={onClickExpand}>
                            <ExpandMore />
                        </IconButton>
                    : null}
            </ListItem>
            {itemType !== 'track' && itemType !== 'report' ?
                <Collapse classes={{ root: styles.root }} in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {itemType === 'period' ?
                            item.map(report => {
                                return (
                                    <NestedListItem
                                        key={report.id}
                                        item={report}
                                        itemType='report'
                                        setSelectedItems={setSelectedItems}
                                        selectedItems={selectedItems}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                    />
                                );
                            }) : null}
                        {itemType === 'artist' ?
                            item.albums.map((album, i) => {
                                return (
                                    <NestedListItem
                                        key={album.name + i}
                                        item={album}
                                        itemType='album'
                                        setSelectedItems={setSelectedItems}
                                        selectedItems={selectedItems}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                    />
                                );
                            }) : null}
                        {itemType === 'album' ?
                            item.tracks.map(track => {
                                return (
                                    <NestedListItem
                                        key={track.id}
                                        item={track}
                                        itemType='track'
                                        setSelectedItems={setSelectedItems}
                                        selectedItems={selectedItems}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                    />
                                );
                            }) : null}

                    </List>
                </Collapse>
                :
                null
            }
        </>
    );
}

export default ManageSongs;