import { Box, Typography, makeStyles } from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { formatNumber } from './Earnings';
import { BrandLoaderDots } from '../CoreComponents/BrandLoader';

const isNumeric = (input) => {
    if (typeof input === 'number') return true;
    if (typeof input === 'string') return false;
    return !isNaN(input) && !isNaN(parseFloat(input));
}

const transformInputIntoMap = (input) => {
    const resultMap = {};

    for (const songDetails of input) {
        const {
            id,
            revenue,
            reportingCompany
        } = songDetails;

        const country = songDetails.country ?? 'Uncategorized';
        const playSource = songDetails.playSource ?? 'Uncategorized';

        if (!resultMap[id]) {
            const currentColor = COLORS[Object.keys(resultMap).length % COLORS.length];
            resultMap[id] = { revenue: 0, id, color: currentColor };
        }

        if (!isNumeric(country)) {
            if (!resultMap[id][country]) {
                resultMap[id][country] = { revenue: 0, id: (id + ' ' + country) };
            }

            resultMap[id][country].revenue += revenue;
        }

        if (!isNumeric(reportingCompany) && resultMap[id] && resultMap[id][country]) {
            if (!resultMap[id][country][reportingCompany]) {
                resultMap[id][country][reportingCompany] = { revenue: 0, id: (id + ' ' + country + ' ' + reportingCompany) };
            }

            resultMap[id][country][reportingCompany].revenue += revenue;
        }

        if (!isNumeric(playSource) && resultMap[id] && resultMap[id][country] && resultMap[id][country][reportingCompany]) {
            if (!resultMap[id][country][reportingCompany][playSource]) {
                resultMap[id][country][reportingCompany][playSource] = { revenue: 0, id: (id + ' ' + country + ' ' + reportingCompany + ' ' + playSource) };
            }

            resultMap[id][country][reportingCompany][playSource].revenue += revenue;
        }

        resultMap[id].revenue += revenue;
    }

    return resultMap;
}

const extractLegendInformation = (input, titleMap) => {
    const unsortedResult = [];

    for (const [key, { revenue, color }] of Object.entries(input)) {
        const label = titleMap[key].toUpperCase() ?? key.toUpperCase();

        const currentObj = {
            label,
            value: revenue,
            color
        };

        unsortedResult.push(currentObj);
    }

    return unsortedResult.sort((a, b) => b.value - a.value);
}

const createMappingBetweenSongIdAndTitle = (input) => {
    const idToTitleMap = {};

    for (const songDetails of input) {
        const { id, song } = songDetails;

        idToTitleMap[id] = song.toUpperCase();
    }

    return idToTitleMap;
}

const mapToChartDataInner = (input, parent, elements, children, revenues, ids, map) => {
    for (const pair of Object.entries(input)) {
        const [key, values] = pair;

        if (key === 'revenue') continue;
        if (key === 'id') continue;
        if (key === 'color') continue;

        const { id, revenue } = values;

        ids.push(id);
        elements.push(map[key] ? map[key] : key);
        children.push(parent);
        revenues.push(revenue);

        mapToChartDataInner(values, id, elements, children, revenues, ids, map);
    }
}

const mapToChartData = (input, idToTitleMap) => {
    const ids = [];
    const elements = [];
    const children = [];
    const revenues = [];

    mapToChartDataInner(input, '', elements, children, revenues, ids, idToTitleMap);

    return [
        ids,
        elements,
        children,
        revenues
    ];
}

const constructCustomData = (labels, values) => {
    return labels.map((label, index) => {
        const labelValueString = `<b>${label.toUpperCase()}<br></b>` +
            `$ ${formatNumber(values[index])}`;

        return labelValueString;
    })
}

const useStyles = makeStyles((theme) => ({
    bullet: {
        width: '20px',
        borderRadius: theme.spacing(0.625)
    },
    legend: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '20px'
    },
    legendWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
    },
    mainWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '100px',
        '& .slice:first-child > .surface': {
            fill: '#F42A82 !important',
            fillOpacity: '1 !important'
        },
        '& .slice > .slicetext > text': {
            fill: `${theme.palette.background.default}  !important`
        }
    },
    loadingWrapper: {
        width: '100%',
        position: 'relative',
        height: '400px'
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}));

const COLORS = ['#F42A82', '#EDE107', '#62E162', '#3EA1D9', '#F96C00'];

const SunburstChart = ({ data, loading }) => {
    const [ids, setIds] = useState([]);
    const [labels, setLabels] = useState([]);
    const [parents, setParents] = useState([]);
    const [values, setValues] = useState([]);
    const [legend, setLegend] = useState([]);

    const styles = useStyles();

    useEffect(() => {
        const titleMapper = createMappingBetweenSongIdAndTitle(data);
        const treeData = transformInputIntoMap(data);

        const arrays = mapToChartData(treeData, titleMapper);

        setLegend(extractLegendInformation(treeData, titleMapper));

        const [zr, fst, snd, trd] = arrays;

        setIds(zr);
        setLabels(fst);
        setParents(snd);
        setValues(trd);
    }, [data]);

    const getSectorValues = (dataValues) => {
        // minimum sector width is 1% of the total sum
        let minSectorWidth = dataValues.reduce((sum, value) => sum += value, 0) * 0.01;

        return dataValues.map(value => Math.max(value, minSectorWidth));
    };

    return (
        <Fragment>
            {loading ? (
                <Box className={styles.loadingWrapper}>
                    <BrandLoaderDots className={styles.loading} />
                </Box>
            ) : (
                <Box className={styles.mainWrapper}>
                    <Plot data={[
                        {
                            type: "sunburst",
                            ids,
                            labels,
                            parents,
                            values: getSectorValues(values),
                            leaf: { opacity: 0.9 },
                            marker: { line: { width: 2 } },
                            branchvalues: 'total',
                            customdata: constructCustomData(labels, values),
                            hovertemplate: "%{customdata}" +
                                "<extra></extra>",
                            hoverlabel: {
                                align: 'left'
                            },
                            name: labels,
                            textinfo: 'label'
                        },
                    ]} layout={{
                        width: 400,
                        height: 400,
                        paper_bgcolor: 'transparent',
                        sunburstcolorway: COLORS,
                        margin: { l: 0, r: 0, t: 0, b: 0 },
                        hovermode: 'closest',
                        hoverlabel: {
                            bgcolor: '#363535'
                        },
                        showlegend: true,
                        legend: {
                            x: 1,
                            y: 0.5,
                            orientation: 'v'
                        },
                        clickmode: 'none', // Disable click interactions
                    }} config={{
                        displayModeBar: false, // Hide the interactive mode bar
                    }}
                    >
                    </Plot>
                    <Box className={styles.legendWrapper}>
                        {legend.map(({ label, value, color }) => (
                            <Box className={styles.legend}>
                                <Box className={styles.bullet} style={{ backgroundColor: color }} />
                                <Box>
                                    <Typography>{label}</Typography>
                                    <Typography>$ {formatNumber(value)}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </Fragment>
    )
};

export default SunburstChart;