import { useCallback, useRef, useState } from "react"

export const useAsyncAction = function (action) {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const actionRef = useRef(action);
    actionRef.current = action;

    const perform = useCallback(async (...args) => {
        setError(undefined);
        setLoading(true);

        try {
            const result = await actionRef.current(...args);
            setData(result);

            return result;
        } catch (error) {
            setError(error);

            throw error;
        } finally {
            setLoading(false);
        }
    }, []);

    const trigger = useCallback((...args) => {
        perform(...args).catch(() => { });
    }, []);

    return { trigger, perform, data, error, loading };
}