import React, { useState, useEffect } from 'react';
import { getData, postData } from '../../utils/FetchUtils'
import BrandTable from '../../CoreComponents/BrandTable'
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReplayIcon from '@material-ui/icons/Replay';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ConfirmationDialog from '../../CoreComponents/ConfirmationDialog';
import { BrandAlert } from '../../CoreComponents/BrandAlert';
import { AlternativeNamesActions } from '../../../constants/alternativeNamesConstants';

const useSongStyles = makeStyles((theme) => ({

    actionBtnsContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start'
    },
    verifiedIconApproved: {
        color: theme.palette.primary.blue,
        marginRight: '6px'
    },
    verifiedIconPendingApproval: {
        color: theme.palette.primary.attention,
        marginRight: '6px'
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '10px',
        cursor: 'pointer'
    },
    statusContainer: {
        display: 'flex',
        textAlign: 'center'
    },
    icons: {
        marginRight: '6px',
    },
    unmergeAndSeparateActions: {
        display: 'flex',
        gap: '30px'
    },
    alertContainer: {
        marginLeft: '25px'
    }
}));

function AlternativeNames({ details, onChange }) {
    const styles = useSongStyles();
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [alert, setAlert] = useState('');
    const [headCells] = useState([
        {
            id: 'title',
            label: 'Song',
            notSortable: true,
            minWidth: '250px'
        },
        {
            id: 'status',
            label: 'Status',
            notSortable: true,
            CellRender: ({ cellData }) => {
                return (
                    cellData !== 'merged' ?
                        <div className={styles.statusContainer}>
                            <VerifiedUserIcon
                                className={styles.verifiedIconPendingApproval}
                                fontSize='small'
                            />
                            Pending Approval
                        </div>
                        :
                        <div className={styles.statusContainer}>
                            <VerifiedUserIcon
                                className={styles.verifiedIconApproved}
                                fontSize='small'
                            />
                            Approved
                        </div>
                )
            }
        },
        {
            id: 'source',
            label: 'Source',
            notSortable: true
        },
        {
            id: 'actions',
            label: 'Actions',
            notSortable: true,
            minWidth: '150px',
            CellRender: ({ rowData }) => {
                const [showUnmergeModal, setShowUnmergeModal] = useState(false);
                const [showMergeModal, setShowMergeModal] = useState(false);
                const [showKeepSeparateModal, setShowKeepSeparateModal] = useState(false);

                return (
                    <div className={styles.actionBtnsContainer}>
                        {
                            rowData.status === 'merged' ?
                                <>
                                    <div
                                        className={styles.actions}
                                        onClick={() => setShowUnmergeModal(true)}
                                    >
                                        <ReplayIcon
                                            className={styles.icons}
                                        />
                                        Un-merge
                                    </div>
                                    <ConfirmationDialog
                                        type={AlternativeNamesActions.UnmergeAlternativeName}
                                        onClose={() => setShowUnmergeModal(false)}
                                        onAction={() => unmergeSongHandler(rowData)}
                                        showState={showUnmergeModal}
                                        setShowState={setShowUnmergeModal}
                                    />
                                </>
                                :
                                <div className={styles.unmergeAndSeparateActions}>
                                    <div
                                        className={styles.actions}
                                        onClick={() => setShowMergeModal(true)}
                                    >
                                        <CallMergeIcon
                                            className={styles.icons}

                                        />
                                        Merge
                                    </div>
                                    <ConfirmationDialog
                                        type={AlternativeNamesActions.MergeAlternativeName}
                                        onClose={() => setShowMergeModal(false)}
                                        onAction={() => mergeSongHandler(rowData)}
                                        showState={showMergeModal}
                                        setShowState={setShowMergeModal}
                                    />
                                    <div
                                        className={styles.actions}
                                        onClick={() => setShowKeepSeparateModal(true)}
                                    >
                                        <CallSplitIcon className={styles.icons} />
                                        Keep Separate
                                    </div>
                                    <ConfirmationDialog
                                        type={AlternativeNamesActions.KeepSeparateAlternativeName}
                                        onClose={() => setShowKeepSeparateModal(false)}
                                        onAction={() => keepSeparateSongHandler(rowData)}
                                        showState={showKeepSeparateModal}
                                        setShowState={setShowKeepSeparateModal}
                                    />
                                </div>
                        }
                    </div>
                );
            }
        },
    ]);

    const fetchAltNames = () => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/song/get-alternative-names?songId=${details.id}`)
            .then(d => {
                setRows(d);
            })
    }

    const mergeSongHandler = (rowSongData) => {
        postData(process.env.REACT_APP_SERVER_HOST + `/api/song/merge-alternative-names`, {
            songId: details.id,
            alternativeNames: [rowSongData]
        })
            .then(res => {
                fetchAltNames();
                onChange();

            })
            .catch(err => {
                console.log(err);
                setAlert(err.message);
            })


    }

    const unmergeSongHandler = (rowSongData) => {
        postData(process.env.REACT_APP_SERVER_HOST + `/api/song/unmerge-alternative-names`, {
            songId: details.id,
            alternativeNames: [rowSongData]
        })
            .then(res => {
                fetchAltNames();
                onChange();
            })
            .catch(err => console.log(err))

    }
    const keepSeparateSongHandler = (rowSongData) => {
        postData(process.env.REACT_APP_SERVER_HOST + `/api/song/remove-alternative-song-names`, {
            songId: details.id,
            alternativeNameId: rowSongData.id,
            reportingCompanyId: rowSongData.reportingCompanyId
        })
            .then(res => {
                fetchAltNames();
                onChange();
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        fetchAltNames();
    }, []);

    useEffect(() => {
        if (alert !== '') {
            setTimeout(() => {
                setAlert('');
            }, "4000")
        }
    }, [alert])

    return (
        <div>
            <div className={styles.alertContainer}>
                {alert !== '' && <BrandAlert>{alert}</BrandAlert>}
            </div>
            <BrandTable
                rows={rows}
                headCells={headCells}
                checkboxless={true}
                page={page}
                hidePagination={true}
            />
        </div>
    );
}

export default AlternativeNames;