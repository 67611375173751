import { Button, makeStyles, TextareaAutosize, Typography } from "@material-ui/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useStoreContext } from "../../store/Store";
import { BrandAlert } from "../CoreComponents/BrandAlert";
import { BrandButton } from "../CoreComponents/BrandButton";
import { postData } from "../utils/FetchUtils";
import { reportNames } from "../utils/ReportNamesUtils";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '10px 30px',
        width: '1250px',
        height: '620px',
    },
    missingRevenueText: {
        font: 'normal normal lighter 16px/24px Roboto',
        width: '60%',
        marginBottom: '70px',
        letterSpacing: 1.25
    },
    textarea: {
        marginTop: 20,
        padding: 30,
        width: '550px',
        minHeight: '200px',
        maxHeight: '200px',
        backgroundColor: 'inherit',
        borderRadius: '10px',
        outline: 'none',
        color: theme.palette.text.primary,
        "&::placeholder": {
            fontSize: "16px"
        },
        overflow: 'auto !important'
    },
    titles: {
        fontWeight: 'bold',
        letterSpacing: 2
    },
    missingRevenueInfo: {
        letterSpacing: 1.25,
        margin: '20px 0px 70px 0',
    },
    revenueInfo: {
        letterSpacing: 1.25,
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px 30px 0 30px',
    },
    cancelButton: {
        width: '200px',
        marginBottom: '10px',
        font: 'normal normal  14px/32px Roboto !important',
        textTransform: 'uppercase !important',
        backgroundColor: theme.palette.background.default,
        '&:hover': {
            backgroundColor: theme.palette.background.default
        },
        '& .MuiButton-label': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiButton-label:hover': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
        },
    },
    submitButton: {
        width: '200px',
        marginBottom: '10px',
        font: 'normal normal  14px/32px Roboto !important',
        textTransform: 'uppercase !important',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiButton-label': {
            borderRadius: '20px',
            color: theme.palette.background.default,
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiButton-label:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
        },
    },
    link: {
        textDecoration: 'none'
    },
    uploadCSVButton: {
        color: theme.palette.background.default,
        height: '35px',
        textDecoration: 'none'
    },
    revenueContainer: {
        display: 'flex',
        gap: '72px',
        margin: '20px 0px 120px 0',
    },
    saveAsDraftBtnContainer: {
        display: 'flex',
        gap: '40px'
    },
    draftBtn: {
        width: '180px',
        height: '45px',
        borderColor: theme.palette.text.primary,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '40px',
        color: theme.palette.text.primary,
    },
    parsedMissingPeriods: {
        display: 'flex',
        width: '400px',
        marginLeft: '20px'
    },
    parsedMissingPeriodsContainer: {
        display: 'flex',
        marginBottom: 10
    }
}));

function MissingRevenueIssueModal({ setShowModal, rowData, parsedPeriods, fetchData, setIssueReportedState, selectedRowData, setRowData, originalData }) {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [systemComment, setSystemComment] = useState(rowData.resolutionComment ?? '');
    const [alert, setAlert] = useState('');

    const reportHandler = () => {
        postData(process.env.REACT_APP_SERVER_HOST + '/api/missing-data/report-issue', {
            missingDataCaseId: rowData.id,
            comment: systemComment
        })
            .then(res => {
                setShowModal(false)
                setIssueReportedState(rowData.id)
            })
            .catch(err => setAlert(err.message))
    };

    const resolveHandler = () => {
        setAlert('');

        postData(process.env.REACT_APP_SERVER_HOST + '/api/missing-data/resolve-issue', {
            missingDataCaseId: rowData.id,
            comment: systemComment
        })
            .then(res => {
                setShowModal(false)
                setIssueReportedState(rowData.id)
                const filteredData = originalData.filter(row => row.id !== selectedRowData.id);
                setRowData(filteredData);
            })
            .catch(err => setAlert(err.message))
            
    };

    const saveAsDraftHandler = () => {
        postData(process.env.REACT_APP_SERVER_HOST + '/api/missing-data/save-as-draft', {
            missingDataCaseId: rowData.id,
            comment: systemComment
        })
        .then(() => { 
            setShowModal(false);
            fetchData();
        })
        .catch((err) => console.log(err));
    };

    const textAreaHandler = (e) => {
        setSystemComment(e.target.value);
    };

    const reportName = reportNames.filter(repName => repName.id === Number(rowData.reportType))[0].name

    return (
        <div>
            <div className={styles.container}>
                <Typography
                    className={styles.titles}
                    component='div'
                    variant='h5'
                >
                    {
                        state.user.userType === 1 ?
                            'Missing Song Data'
                            :
                            'Submit Missing Revenue Issue'
                    }
                </Typography>
                {
                    state.user.userType === 1 ?
                        <>
                            <p className={styles.missingRevenueText}></p>
                            <Typography
                                className={styles.titles}
                                component='div'
                                variant='h5'
                            >
                                Revenue
                            </Typography>
                            <div className={styles.revenueContainer}>
                                <div className={styles.revenueInfo}>
                                    <div><b>{reportName}</b></div>
                                    <div className={styles.parsedMissingPeriodsContainer}>
                                        Missing Period -
                                        <span className={styles.parsedMissingPeriods}>
                                            {parsedPeriods.join(',')}
                                        </span>
                                    </div>
                                    <div>
                                        Account Holder -
                                        <b> {rowData.accountholderName}</b>
                                    </div>
                                    <BrandAlert>{alert}</BrandAlert>
                                </div>
                                <Link to='/upload' className={styles.link}><BrandButton className={styles.uploadCSVButton}>Upload CSV</BrandButton></Link>
                            </div>
                        </>
                        :
                        <>
                            <p className={styles.missingRevenueText}>We have found Missing Revenue for your song. By clicking on Submit you grant permission to Record to rectify the error and locate any potential revenues</p>
                            <Typography
                                className={styles.titles}
                                component='div'
                                variant='h5'
                            >
                                Missing Revenue
                            </Typography>
                            <div className={styles.missingRevenueInfo}>
                                <div><b>{reportName}</b></div>
                                <div className={styles.parsedMissingPeriodsContainer}>
                                    Missing Period -
                                    <span className={styles.parsedMissingPeriods}>
                                        {parsedPeriods.join(',')}
                                    </span>
                                </div>
                                <div>
                                    Account Holder -
                                    <b> {rowData.accountholderName}</b>
                                </div>
                            </div>
                        </>
                }
                <Typography
                    className={styles.titles}
                    component='div'
                    variant='h5'
                >
                    {
                        state.user.userType === 1 ?
                            'Leave resolution comment (optional)'
                            :
                            'Leave us a comment (optional)'

                    }
                </Typography>
                <TextareaAutosize
                    value={systemComment}
                    onChange={textAreaHandler} 
                    className={styles.textarea} 
                    placeholder="Click here to leave us a comment with more information on the issue" 
                />
            </div>
            <div className={styles.btnContainer}>
                <Button onClick={() => setShowModal(false)} className={styles.cancelButton}>Cancel</Button>
                {
                    state.user.userType === 1 ?
                        <div className={styles.saveAsDraftBtnContainer}>
                            <Button onClick={saveAsDraftHandler} className={styles.draftBtn}>Save as Draft</Button>
                            <Button onClick={resolveHandler} className={styles.submitButton}>Resolve</Button>
                        </div>
                        :
                        <Button onClick={reportHandler} className={styles.submitButton}>Submit</Button>
                }
            </div>
        </div>
    );
};

export default MissingRevenueIssueModal;