import React from 'react'
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    greenText: {
        color: theme.palette.primary.main,
        font: 'normal normal normal 14px / 21px Roboto !important',
    },
    tooltipItems: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.lightYellow}`,
        borderRadius: '16px',
    
        textAlign: 'left',
        font: 'normal normal normal 16px/26px Roboto',
        padding: '12px',
    },
    accountHoldersText: {
        '& .MuiTypography-root': {
            font: 'normal normal bold 14px/21px Roboto',
            textAlign: 'left',
            letterSpacing: '0px',
        },
    },
}));

const AdditionalAccountHolders = ({ accountHolders, showAccountHoldersText = true, textStyle = undefined }) => {
    const styles = useStyles();
  
    return (
      <Box className={textStyle ? textStyle : styles.accountHoldersText}>
        <Typography>
          {showAccountHoldersText && 'Account Holder(s): '}
          {accountHolders &&
          accountHolders[0] !== null &&
          accountHolders.length !== 0
            ? accountHolders[0]
            : 'N/A'}
          {accountHolders?.length > 1 ? (
            <Tooltip
              title={
                <Box>
                  {accountHolders.slice(1).map((item, index) => (
                    <Typography key={index}>{item}</Typography>
                  ))}
                </Box>
              }
              classes={{
                tooltip: styles.tooltipItems,
              }}
            >
              <span className={styles.greenText}>
                {' '}
                {`  +${accountHolders.length - 1}`}
              </span>
            </Tooltip>
          ) : (
            ''
          )}
        </Typography>
      </Box>
    )
  }

  export default AdditionalAccountHolders;