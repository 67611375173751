import { Button, makeStyles, Popover, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { endOfMonth, endOfYear, previousMonday, startOfMonth, startOfYear, isMonday } from 'date-fns';
import {parseDate} from '../utils/DateUtils'

const useNftStyles = makeStyles((theme) => ({
    rangeTitle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        cursor: 'pointer',
        font: 'normal normal normal 18px/24px Roboto',
        width: '250px'
    },
    datePickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        marginLeft: theme.spacing(12),
        '& .MuiPickersStaticWrapper-staticWrapperRoot': {
            backgroundColor: theme.palette.background.default,
            borderRight: `2px solid ${theme.palette.primary.border}`
        },
        '& .MuiPickersCalendarHeader-iconButton': {
            backgroundColor: theme.palette.background.default,
            color: 'white'
        },
    },
    datePicker: {
        display: 'flex',
    },
    rangesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: theme.spacing(43),
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.default,
        width: theme.spacing(24)
    },
    ranges: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: theme.spacing(43),
        justifyContent: 'center'
    },
    buttonName: {
        textTransform: 'none',
        font: 'normal normal normal 16px/21px Roboto',
        marginBottom: theme.spacing(1)
    },
}));

const calendarButtonsText = [
    'Today',
    'This Week',
    'Last Month',
    'Last Quarter',
    'Last Year',
    'All Time'
];

function Calendar({ showTypography, test, setTest }) {
    const styles = useNftStyles();
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [arrow, setArrow] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dateRangeTitle, setDateRangeTitle] = useState(calendarButtonsText[0]);
    const openGeneralPopover = Boolean(anchorEl);
    const popoverId = openGeneralPopover ? 'general-popover' : undefined;

    useEffect(() => {
        setAnchorEl(test)
    }, [test])

    const arrowHandler = (e) => {
        setArrow(arrow ? false : true)
        setAnchorEl(e.currentTarget);
    }

    const handleCloseGeneralPopover = () => {
        setAnchorEl(null);
        setTest?.(null)
        setArrow(false);
    };

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    const calendarHandler = (e, section) => {

        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const todayResult = date;

        const thisWeekResult = isMonday(date) ? date : previousMonday(new Date(year, month, day));

        const firstDayOfPreviousMonth = startOfMonth(new Date(year, month - 1, day));
        const lastDayOfPreviousMonth = endOfMonth(new Date(year, month - 1, day));

        const firstDayOfLastQuarter = startOfMonth(new Date(year, month - 3, day));
        const lastDayOfLastQuarter = endOfMonth(new Date(year, month - 1, day));

        const firstDayOfLastYear = startOfYear(new Date(year - 1, month, day));
        const lastDayOfLastYear = endOfYear(new Date(year - 1, month, day));

        setDateRangeTitle(e.target.innerText);
        switch (e.target.innerText) {
            case 'Today':
                setSelectedStartDate(todayResult);
                setSelectedEndDate(todayResult);
                break;
            case 'This Week':
                setSelectedStartDate(thisWeekResult);
                setSelectedEndDate(todayResult);
                break;
            case 'Last Month':
                setSelectedStartDate(firstDayOfPreviousMonth);
                setSelectedEndDate(lastDayOfPreviousMonth);
                break;
            case 'Last Quarter':
                setSelectedStartDate(firstDayOfLastQuarter);
                setSelectedEndDate(lastDayOfLastQuarter);
                break;
            case 'Last Year':
                setSelectedStartDate(firstDayOfLastYear);
                setSelectedEndDate(lastDayOfLastYear);
                break;
            default:
                break;
        }
    };

    return (
        <div className={styles.datePickerContainer}>
            {showTypography ?
                <Typography
                    component='div'
                    variant='subtitle1'
                    className={styles.rangeTitle}
                    onClick={arrowHandler}
                >
                    Date as of: {dateRangeTitle} {arrow ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </Typography>
                :
                null
            }
            <Popover
                id={popoverId}
                open={openGeneralPopover}
                anchorEl={anchorEl}
                onClose={handleCloseGeneralPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                >
                    <div className={styles.datePickerContainer}>
                        <div className={styles.datePicker}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="static"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Date picker inline"
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                maxDate={parseDate(maxDate)}
                            />

                            <KeyboardDatePicker
                                disableToolbar
                                margin="normal"
                                variant="static"
                                id="date-picker-dialog"
                                label="Date picker dialog"
                                format="MM/dd/yyyy"
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                maxDate={parseDate(maxDate)}
                            />

                            <div className={styles.rangesContainer}>
                                <div className={styles.ranges}>
                                    {calendarButtonsText.map(buttonName =>
                                        <Button
                                            key={`${buttonName}earningSection`}
                                            className={styles.buttonName}
                                            onClick={calendarHandler}
                                        >
                                            {buttonName}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </MuiPickersUtilsProvider>
            </Popover>
        </div>
    );
}

export default Calendar;


