import { StyleSheet } from "@react-pdf/renderer";
export const stylesPDF = StyleSheet.create({
    page: {
        width:'100%',
        height:'100%',
        letterSpacing: 0.8
    },
    container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: '2px',
        borderBottomColor: '#000',
        width: '90%',
        marginTop: 10,
    },
    viewer: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    nftImage: {
        width: '100px',
        paddingLeft: '10px'
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    headerText: {
        display: 'flex',
        alignItems: "flex-end",
        paddingRight: '10px'
    },
    dataRange: {
        fontFamily: "Helvetica-Bold",
        fontSize: 12,
    },
    dataRangeSub: {
        fontSize: 8,
    },
    headerAccHolderText: {
        fontSize: 8,
        fontFamily: "Helvetica-Bold",
        paddingBottom: '2px'
    },
    dateRangeText: {
        padding: '20px 100px 0 12px'
    },
    headerSubText: {
        display: 'flex',
        alignItems: "flex-end",
        justifyContent: "center",
        paddingRight: '10px',
        paddingTop: '7px',
        paddingBottom: '10px'
    },
    infoSection: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "30px",
        marginBottom: "30px",
        backgroundColor: '#308132',
        color: '#fff',
        width: '90%',
        borderRadius: 5
    },
    accountInfo: {
        display: 'flex',
        paddingLeft: '15px',
        paddingTop: '10px',
    },
    accountName: {
        fontFamily: "Helvetica-Bold",
        fontSize: 20,
        letterSpacing: '2px'
    },
    walletAddress: {
        display: 'flex',
        fontSize: 10,
        paddingTop: '5px',
        height: 30,
        justifyContent: 'space-between'
    },
    totalEarnings: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: "center",
        width: "100%",
        marginTop: '12px',
        padding: "0 15px 0 15px",
        letterSpacing: '0.5px'
    },
    totalEarningsInfoContainer: {
    },
    totalEarningsPaymentContainer: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    totalEarningsInfo: {
        display: 'flex',
        justifyContent: "space-between",
        fontSize: 9,
        paddingBottom: '3px',
        width: '100%'
    },
    currencyContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 10
    },
    totalEarningsInfoResults: {
        paddingBottom: 2
    },
    totalPayment: {
        fontSize: 8,
        color: '#73CE81',
    },
    totalPaymentResult: {
        fontSize: 11,
        letterSpacing: 1,
        paddingTop: 10
    },
    salesTypeSection: {
        width: '90%',
    },
    salesTypeContainer: {
        width: '100%',
        display: 'flex',
        paddingTop: '10px',
        border: '1px solid #F1F2F5',
        borderRadius: '5px',
    },
    salesTypeHeader: {
        fontFamily: "Helvetica-Bold",
        fontSize: 13,
        paddingLeft: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginTop: 10,
    },
    transactionsContainer: {
        marginTop: 40,
    },
    transactionsContainerTitle: {
        fontFamily: "Helvetica-Bold",
        fontSize: 14,
        paddingLeft: '20px',
        paddingBottom: '10px',
        marginTop: 6,
    },
    transactionSectionsTitle: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "flex-start",
        fontFamily: "Helvetica-Bold",
        fontSize: 9,
        paddingTop: 10,
        height: 35,
    },
    transactionSections: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: 'center',
        fontSize: 9,
        height: 35
    },
    transactionSectionsGray: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: 'center',
        fontSize: 9,
        height: 35,
        backgroundColor: '#E3E7EB',
    },


    transactionsMainRowTitle: {
        width: '28%',
        paddingLeft: 10
    },
    transactionsMainRowDate: {
        width: '12%',
        paddingLeft: 10
    },
    transactionsMainRowSaleType: {
        width: '17%',
        paddingLeft: 10
    },
    transactionsMainRowCurrencyType: {
        width: '20%',
    },
    transactionsAmount: {
        width: '23%',
    },
    transactionsTitle: {
        width: '28%',
        fontFamily: 'Helvetica-Bold',
        paddingLeft: 10
    },
    transactionsDate: {
        width: '12%',
        paddingLeft: 10
    },
    transactionsSaleType: {
        width: '17%',
        paddingLeft: 10
    },
    transactionsCurrencyType: {
        width: '20%',
    },
    ownerWallet:{
        width:'50%'
    },
    ownerId:{
        width:'10%',
        paddingLeft: 10
    },
    ownedNftCount:{
        width:'20%',
        textAlign:'center'
    },
    currencyHeader: {
        color: '#73CE81',
        fontSize: 8,
    },
    currency: {
        fontSize: 11,
        letterSpacing: 1,
        borderLeft: `1px solid #73CE81`,
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    firstCurrency: {
        fontSize: 11,
        letterSpacing: 1,
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    currencyTotalEthereum: {
        fontSize: 11,
        letterSpacing: 1,
        borderRight: `1px solid #73CE81`,
        paddingRight: '20px'
    },
    currencyTotalMatic: {
        fontSize: 11,
        letterSpacing: 1,
        paddingLeft: '20px'
    },
    currencyType: {
        fontSize: 8,
        paddingBottom: 10,
        fontFamily: 'Helvetica-Bold',
    },
    salesHeaders: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 9,
        width: '30%'
    },
    totalSumHeader: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 9,
        width: '40%',
        paddingLeft: '20px'
    },
    typeTotalSum: {
        fontSize: 9,
        width: '40%',
        paddingLeft: '20px'
    },
    emptyHeader: {
        fontSize: 9,
        color: 'transparent',
        width: '30%'
    },
    salesContainerType: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
    },
    salesContainerPrimary: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingBottom: 2,
        paddingTop: 10,
        marginBottom: 5
    },
    salesContainerSecondary: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingBottom: 10,
        paddingTop: 2,
    },
    salesContainerTotal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 10,
        backgroundColor: '#E3E7EB',
        height: '25px',
        fontFamily: 'Helvetica-Bold',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px'
    },
    tranasctionSection: {
        width: '90%',
        border: '1px solid #F1F2F5',
        borderRadius: '5px'
    }

});