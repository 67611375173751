import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useQuery } from '../utils/RouteUtils'
import { putData } from '../utils/FetchUtils'
import { TempBrandButton } from '../CoreComponents/BrandButton'
import { BrandPasswordInput } from '../CoreComponents/BrandInput'
import { BrandLoaderDots } from '../CoreComponents/BrandLoader'
import { Typography } from '@material-ui/core';
import { BrandLink } from '../CoreComponents/BrandLink'
import { BrandAlert } from '../CoreComponents/BrandAlert'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { textIsEmpty, textIsPassword } from '../utils/ValidationUtils';
require('dotenv').config();


const dataValidators = {
    isRequired: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
    validatePassword: (value) => {
        const errors = []
        const res = textIsPassword(value);
        if (res !== true) {
            errors.push(res)
        }
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
}

const useStyles = makeStyles((theme) => ({
    submitBtn: {
        width: '100%',
    },
    input: {
        marginTop: 25,
    },
    title: {
        padding: 15
    },
    link: {
        marginBottom: 30
    }
}));

export default function LoginPage(props) {
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const { token } = useQuery()
    const [alert, setAlert] = useState('');
    const password = useState('');
    const repeatPassword = useState('');

    const passwordPassed = useState(true);
    const repeatPasswordPassed = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (!token) {
            history.push('/login')
        }
    }, [])


    async function handleChangePassword(e) {
        e.preventDefault();
        try {
            setLoading(true)
            if (password[0] === repeatPassword[0]) {
                await putData(process.env.REACT_APP_SERVER_HOST + '/api/user/change-forgotten-password', { token: token, password: password[0] })
            } else {
                throw new Error("Passwords don't match")
            }
            setLoading(false)
            history.push('/login')
        } catch (err) {
            setAlert(err.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!passwordPassed[0] || !repeatPasswordPassed[0]) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [passwordPassed[0], repeatPasswordPassed[0]])

    useEffect(() => {
        setAlert('');
    }, [password[0], repeatPassword[0]]);

    return (
        <>
            <Typography className={styles.title} variant='h5' component='div' gutterBottom>
                Reset password
            </Typography>
            <form onSubmit={handleChangePassword}>
                <div className={styles.input}>
                    <BrandPasswordInput $value={password} required validator={dataValidators.validatePassword} validatorPassed={passwordPassed} label='New Password' />
                </div>
                <div className={styles.input}>
                    <BrandPasswordInput $value={repeatPassword} validator={dataValidators.isRequired} validatorPassed={repeatPasswordPassed} required label='Confirm Password' />
                </div>
                <div className={styles.link}>
                    <BrandLink to='/login'>Return to login page</BrandLink>
                </div>
                {alert ? <BrandAlert>{alert}</BrandAlert> : null}
                <TempBrandButton className={styles.submitBtn} disabled={submitDisabled} type='submit'>
                    {loading ? <BrandLoaderDots /> : 'Submit'}
                </TempBrandButton>
            </form>
        </>
    );
}