import { useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { IconButton, ListItem, ListItemText, List, Collapse } from '@material-ui/core';
import { AdditionalIsrcsUpcs } from "./AdditionalIsrcsUpcs";
import AdditionalAccountHolders from "./AdditionalAccountHolders";
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelCircleIcon from '@mui/icons-material/CancelOutlined';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.darkNuanceVersion4,
        marginTop: '10px',
        marginBottom: '10px',
        borderRadius: '6px',
        border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
    },
    listBulletsHeading: {
        color: theme.palette.primary.main,
        lineBreak: 'strict'
    },
    listBulletSpotifyIdContainer: {
        marginBottom: theme.spacing(3),
        lineBreak: 'anywhere'
    },
    listBulletHeadingContainer: {
        display: 'flex',
        lineBreak: 'anywhere'
    },
    listBulletAccountholderContainer: {
        display: 'flex'
    },
    listBulletsFontBoldProperties: {
        fontWeight: 'bold'
    },
    nested: {
        paddingLeft: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    songItem: {
        '& .MuiListItemText-primary': {
            font: 'normal normal bold 17px/21px Roboto',
            textAlign: 'left',
            letterSpacing: '0px',
        },  
        '& .MuiListItemText-secondary': {
            font: 'normal normal bold 14px/21px Roboto',
            textAlign: 'left',
            letterSpacing: '0px',
        },  
    },
    verifiedIcon: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
    },
    unverifiedIcon: {
        color: theme.palette.primary.delete,
        marginLeft: theme.spacing(1),
    },
    itemHeader: {
        display: 'flex',
        flexDirection: 'row',
    }
}));

export function SongListItem({
    song, 
    onClick
}) {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const verified = song.spotifySongId ? true : false;

    const onClickExpand = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    const modifiedAccountHolders = !song.accountHolders || song.accountHolders.length === 0 ?
    "N/A"
    :
    song.accountHolders.length === 1 ?
    song.accountHolders[0]
        :
        `${song.accountHolders[0]}; +${song.accountHolders.length - 1}`

    return (
        <>
            <ListItem className={styles.container} button onClick={() => onClick(song)}>
                <ListItemText
                    secondary={<AdditionalAccountHolders accountHolders={song.accountHolders}/>}
                    className={styles.songItem}
                >
                    <Box className={styles.itemHeader}>
                        {`${song.artistName ? song.artistName + ' - ' : ''}${song.name ? song.name + ' - ' : ''}${song.displayName}`}
                        { verified ? 
                            <Box className={styles.verifiedIcon}>
                                <CheckCircleIcon/>
                            </Box>
                            : 
                            <Box className={styles.unverifiedIcon}>
                                <CancelCircleIcon/>
                            </Box>
                        }    
                    </Box>
                </ListItemText>
                {open ?
                    <IconButton onClick={onClickExpand}>
                        <ExpandLess />
                    </IconButton>
                    :
                    <IconButton onClick={onClickExpand}>
                        <ExpandMore />
                    </IconButton>
                }
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense={true}>
                    <ListItem className={styles.nested}>
                        <ListItemText>
                            <div className={styles.listBulletSpotifyIdContainer}>
                                <span className={styles.listBulletsHeading}>
                                    Spotify Id:&nbsp;
                                </span>
                                {`${song.spotifySongId || 'N/A'}`}
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <span className={styles.listBulletsFontBoldProperties}>
                                    Genre:&nbsp;
                                </span>
                                {`${song.genre || 'N/A'}`}
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <span className={styles.listBulletsFontBoldProperties}>
                                    Producers:&nbsp;
                                </span>
                                {` ${song.producers || 'N/A'}`}
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <span className={styles.listBulletsFontBoldProperties}>
                                    Writers:&nbsp;
                                </span>
                                {`${song.writers || 'N/A'}`}
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <AdditionalIsrcsUpcs data={song.isrcs} text='ISRC' />
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <AdditionalIsrcsUpcs data={song.upcs} text='UPC' />
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <span className={styles.listBulletsFontBoldProperties}>
                                    Album:&nbsp;
                                </span>
                                {`${song.album || 'N/A'}`}    
                            </div>
                        </ListItemText>
                        <ListItemText>
                            <div>
                                <span className={styles.listBulletsFontBoldProperties}>
                                    Release Date:&nbsp;
                                </span>
                                {`${song.releaseDate ? song.releaseDate.split('T')[0] : 'N/A'}`} 
                            </div>
                        </ListItemText>
                    </ListItem>
                </List>
            </Collapse>
        </>
    );
}