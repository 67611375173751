import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getData } from "../utils/FetchUtils";

const useStyles = makeStyles((theme) => ({
    songDataContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '24px 0px',
    },
    songDataCol: {
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(38),
        justifyContent: 'space-between',
    },
    songDataRow: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },
    songDataRowData: {
        marginBottom: theme.spacing(1),
        font: 'normal normal bold 16px/20px Roboto',
        letterSpacing: '1.6px',
    },
    songDataRowLabel: {
        color: theme.palette.primary.lightGray,
        font: 'normal normal normal 12px/15px Roboto',
        letterSpacing: '1.2px',
    },
    songDataCols: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    displayNameContainer: {
        display: 'flex',
        alignItems: 'end',
        wordBreak: 'break-all'
    },
}));

function SongDetails({ songId }) {
    const styles = useStyles();
    const [songDetails, setSongDetails] = useState([]);

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/song/get-song-details?songId=${songId}`)
            .then(data => {
                data.songDetails[0].publisherAdministrator = JSON.parse(data.songDetails[0].publisherAdministrator).filter(el => el !== null);
                data.songDetails[0].labelDistributor = JSON.parse(data.songDetails[0].labelDistributor).filter(el => el !== null);
                data.songDetails[0].masterPro = JSON.parse(data.songDetails[0].masterPro).filter(el => el !== null);
                data.songDetails[0].compositionPro = JSON.parse(data.songDetails[0].compositionPro).filter(el => el !== null);
                setSongDetails(data.songDetails[0]);
            });
    }, [])

    return (
        <>
            <div className={styles.songDataContainer}>
                <div className={styles.songDataCols}>
                    <div className={styles.songDataCol}>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.artist || 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Artist</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.album || 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Album</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.title || 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Title</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>
                                <div className={styles.displayNameContainer}>{songDetails.displayName || 'N/A'}</div>
                            </div>
                            <div className={styles.songDataRowLabel}>Record display name</div>
                        </div>
                    </div>
                    <div className={styles.songDataCol}>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.accountHolder || 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Account Holder(s)</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.releaseDate?.split('T')[0] || 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Released</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.writers ? `${songDetails.writers?.substring(0, 50)} ...` : 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Songwriters</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.producers ? `${songDetails.producers?.substring(0, 50)} ...` : 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Producers</div>
                        </div>
                    </div>
                    <div className={styles.songDataCol}>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.labelDistributor?.length > 0 ? songDetails.labelDistributor : 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Label/Distributor</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.publisherAdministrator?.length > 0 ? songDetails.publisherAdministrator : 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Publisher/Administrator</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.compositionPro?.length > 0 ? songDetails.compositionPro : 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Composition PRO</div>
                        </div>
                        <div className={styles.songDataRow}>
                            <div className={styles.songDataRowData}>{songDetails.masterPro?.length > 0 ? songDetails.masterPro : 'N/A'}</div>
                            <div className={styles.songDataRowLabel}>Master Pro</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SongDetails;