import {
    Box,
    Button,
    Typography,
    makeStyles
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import PercentageInput from "./PercentageInput";
import {
    Edit as EditIcon,
    DeleteOutlined as DeleteOutlinedIcon
} from '@material-ui/icons';
import { useEffect, useState } from "react";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import clsx from 'clsx'
import ActionButtons from "../CoreComponents/ActionButtons";
import { BrandPopover } from "../CoreComponents/BrandPopover";

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
    },
    revenueSplitWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    bulkEditButton: {
        color: theme.palette.primary.lightGray,
        fontSize: '12px',
        textTransform: 'capitalize',
        padding: '0px',
        marginLeft: '60%',
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        },

    },
    bulkEditInput: {
        marginLeft: '60%',
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        width: '20px',
        height: '20px',
        marginRight: '5px'
    },
    starIcon: {
        verticalAlign: 'middle'
    },
    bulkEditWrapper: {
        display: 'flex',
        width: '100%',
    },
    accountHolderName: {
        display: 'flex',
        alignItems: 'center',
        width: '60%'
    },
    actionButtonsWrapper: {
        display: 'flex',
        '& .MuiInputBase-input': {
            textAlign: 'center'
        }
    },
    percentageText: {
        width: '80px',
        textAlign: 'center'
    },
    removeButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '5px',
        fontSize: '14px',
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.alert
        },
        position: 'absolute',
        right: '-240px',
        top: '50%',
        transform: 'translate(0%, -50%)'
    },
    saveButton: {
        right: '-150px',
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.main
        },
    },
    disabled: {
        color: '#A5A5A5 !important'
    },
    popover: {
        color: theme.palette.text.lime,
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

const EditListRevenueSplits = ({
    revenueSplits,
    songId,
    mainAccountHolderId,
    setRevenueSplits,
    refreshRevenueSplits,
    setDisabledApplyEdit,
    selectedItems,
    editSplits,
    handleGetRevenueSplits
}) => {
    const [bulkEditValue, setBulkEditValue] = useState('');
    const [showBulkEditField, setShowBulkEditField] = useState(false);
    const [bulkFieldValidator, setBulkFieldValidator] = useState(false);
    const [percentageValidators, setPercentageValidators] = useState([]);

    const [isClicked, setIsClicked] = useState(false);
    const [btnClicked, setBtnClicked] = useState({});

    useEffect(() => {
        const validators = revenueSplits.map((x) => ({ id: x.id, validated: true }));
        setPercentageValidators(validators);
    }, []);

    useEffect(() => {
        const validationDisableEdit = percentageValidators.some((v) => !v.validated);
        setDisabledApplyEdit(validationDisableEdit);
    }, [percentageValidators]);

    const styles = useStyles();
    const abortableFetch = useAbortableFetch();

    const handleShowBulkEditField = () => setShowBulkEditField(true);
    const handleHideBulkEditField = () => {
        setBulkEditValue('');
        setShowBulkEditField(false);
    }

    const handlePercentageOnChange = (value, revenueSplitId) => {
        const updatedRevenueSplits = revenueSplits.map((s) =>
            s.id === revenueSplitId ? { ...s, splitPercentage: value } : s
        );

        setRevenueSplits(updatedRevenueSplits);
        setDisabledApplyEdit(false);
    }

    const handleBulkEditValueOnChange = (value) => {
        const updatedRevenueSplits = revenueSplits.map((s) => (
            { ...s, splitPercentage: value })
        );

        setBulkEditValue(value);
        setRevenueSplits(updatedRevenueSplits);
        setDisabledApplyEdit(false);
    }

    const generateValidatorSetter = (id) => {
        return (value) => setPercentageValidators(
            percentageValidators.map((v) =>
                v.id === id ? { ...v, validated: value } : v
            )
        );
    }

    const generateBulkValidatorSetter = (value) => {
        setBulkFieldValidator(value);
        setPercentageValidators(
            percentageValidators.map((v) => ({ ...v, validated: value }))
        );
    }

    const handleRemoveRevenueSplit = async (accountHolderId) => {
        const reportAndSongIdPairs = selectedItems.flatMap(({ id, songIds }) => (songIds.map(songId => ({ reportId: id, songId: songId }))));

        try {
            await abortableFetch('DELETE', '/api/revenue-split/account-holder-split', {
                body: { songId, accountHolderId, reportAndSongIdPairs }
            });

            setIsClicked(!isClicked);
            await refreshRevenueSplits();
        } catch (error) {
            console.log(error);
        }
    }

    const clickHandler = (btnValue, id) => {
        setIsClicked(!isClicked);
        setBtnClicked({ btnValue, id });
    }

    const cancelEdit = async () => {
        setIsClicked(false);
        setBtnClicked({});
        handleHideBulkEditField();
        await handleGetRevenueSplits();
    }

    return (
        <Box className={styles.contentWrapper}>
            <Box className={styles.bulkEditWrapper}>
                {showBulkEditField ? (
                    <Box className={styles.bulkEditInput}>
                        <PercentageInput
                            inputData={bulkEditValue}
                            setInputData={handleBulkEditValueOnChange}
                            validatorPassed={[
                                bulkFieldValidator,
                                generateBulkValidatorSetter
                            ]}
                            autoFocus
                        />
                        <ActionButtons
                            showBulkEditButtons={showBulkEditField}
                            handleShowBulkEditButtons={handleHideBulkEditField}
                            isActionClicked={isClicked}
                            setIsActionClicked={setIsClicked}
                            actionButtonTypeClicked={btnClicked}
                            setActionButtonTypeClicked={setBtnClicked}
                            clickHandler={clickHandler}
                            onEdit={editSplits}
                            showDelete={false}
                            cancelEdit={cancelEdit}
                        />
                    </Box>
                ) : (
                    <Button
                        className={styles.bulkEditButton}
                        onClick={handleShowBulkEditField}
                    >
                        <EditIcon className={styles.icon} />
                        Bulk Edit
                    </Button>
                )}
            </Box>
            {revenueSplits.map(({
                id,
                name,
                splitPercentage,
                accountHolderId
            }) => (
                <Box key={id} className={styles.revenueSplitWrapper}>
                    <Typography className={styles.accountHolderName}>
                        {name}
                        {mainAccountHolderId == accountHolderId &&
                            <BrandPopover
                                popoverDataFormated={
                                    <StarIcon
                                        className={styles.starIcon}
                                    />
                                }
                                popoverData={<>Main Account Holder.<br />Cannot be deleted.</>}
                            >
                            </BrandPopover>
                        }
                    </Typography>
                    <Box className={styles.actionButtonsWrapper}>
                        {
                            isClicked && btnClicked.btnValue === 'edit' && btnClicked.id === id ?
                                <PercentageInput
                                    inputData={splitPercentage}
                                    setInputData={(e) => handlePercentageOnChange(e, id)}
                                    validatorPassed={[
                                        percentageValidators.find((x) => x.id === id),
                                        generateValidatorSetter(id)
                                    ]}
                                    autoFocus
                                />
                                :
                                <div className={styles.percentageText}>{splitPercentage}%</div>
                        }
                        <ActionButtons
                            isActionClicked={isClicked}
                            setIsActionClicked={setIsClicked}
                            actionButtonTypeClicked={btnClicked}
                            setActionButtonTypeClicked={setBtnClicked}
                            tableData={{ id }}
                            clickHandler={clickHandler}
                            onDelete={() => handleRemoveRevenueSplit(accountHolderId)}
                            onEdit={editSplits}
                            showDelete={accountHolderId != mainAccountHolderId}
                            cancelEdit={cancelEdit}
                        />
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

export default EditListRevenueSplits;