import { Switch, makeStyles } from "@material-ui/core"
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import BarChartIcon from '@material-ui/icons/BarChart';

const useStyles = makeStyles(theme => ({
    cookieContainer: {
        position: 'absolute',
        left: '22%',
        bottom: '15%',
        background: theme.palette.background.darkNuanceVersion3,
        width: '900px',
        height: '400px',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        borderRadius: '20px'
    },
    cookieHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px'
    },
    headerTitle: {
        fontSize: '32px'
    },
    headerBody: {
        fontSize: '18px'
    },
    cookieAnalytics: {
        display: 'flex',
        alignItems: 'center',
        margin: '20px',
        borderBottom: '1px solid white',
        padding: '20px'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '20px'
    },
    textHeader: {
        fontSize: '20px',
        margin: '4px 0px 4px 0px'
    },
    textBody: {
        fontSize: '14px',
    }

}))
export default function CookiePreferencesTab() {
    const styles = useStyles();

    return (
        <div className={styles.cookieContainer}>
            <div className={styles.cookieHeader}>
                <div className={styles.headerTitle}> Cookie Preferences</div>
                <div className={styles.headerBody}>This website uses the following types of services. Learn more from our Privacy Policy</div>
            </div>
            <div className={styles.cookieAnalytics}>
                <div className={styles.icon}>
                    <PowerSettingsNewIcon />
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.textHeader}>Strictly Necessary</div>
                    <div className={styles.textBody}>Strictly Necessary services are absolutely necessary for core functions such as navigating the page or accessing secure areas. The website cannot function properly without these cookies.</div>
                </div>
                <div className={styles.cookieBtn}>
                    <Switch color='primary' />
                </div>
            </div>
            <div className={styles.cookieAnalytics}>
                <div className={styles.icon}>
                    <BarChartIcon />
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.textHeader}>Analytics</div>
                    <div className={styles.textBody}>Analytics services serve to improve the performance and functionality of this website by collecting and reporting information anonymously.</div>
                </div>
                <div className={styles.cookieBtn}>
                    <Switch color='primary' />
                </div>
            </div>
        </div>
    )
}