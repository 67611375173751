import React from "react";
import { Checkbox } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    reportCheckBox: {
        color: theme.palette.primary.main
    }
}));

export function BrandCheckbox({ $value, disabled, reportBrandCheckBox,checked ,...props }) {
    const styles = useStyles();
    return ($value ?
        <FormControlLabel
            checked={$value[0]}
            onChange={e => $value[1](e.target.checked)}
            control={<Checkbox disabled={disabled} color="primary" />}
            {...props}
        />
        :
        <FormControlLabel checked={checked} control={<Checkbox disabled={disabled} color="primary" className={reportBrandCheckBox ? styles.reportCheckBox : ''}/>} {...props} />
    );
}
