import React, { useEffect, useState } from 'react';
import { getData, deleteData } from '../utils/FetchUtils'
import {Typography,} from '@material-ui/core';
import { BrandSearchInput } from '../CoreComponents/BrandInput';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { Checkbox } from '@material-ui/core';
import { BrandAlert } from '../CoreComponents/BrandAlert';
import { BrandDeleteModal, BrandMessageModal } from '../CoreComponents/BrandModal';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const useStyles = makeStyles(theme => ({
    text: {
        marginBottom: theme.spacing(2.5)
    },
    modalSubheadText: {
        marginBottom: theme.spacing(8.5)
    },
    deleteModalTitle: {
        paddingBottom: theme.spacing(3.5)
    },
    warningMessage: {
        color: theme.palette.primary.attention,
        marginBottom: theme.spacing(1)
    },
    deleteModalCheckboxMainContainer: {
        marginBottom: '10px',
    },
    deleteModalCheckboxInnerContainer: {
        display: 'flex', 
        alignItems: 'center'
    },
    deleteModalCheckbox: {
        marginLeft: '-13px'
    },
    deleteModalCheckboxText: {
        marginLeft: '-5px'
    },
    moveArtistSearchInput: {
        width: theme.spacing(25.625),
        marginBottom: '10px'
    },
    listElement: {
        marginTop: '15px', 
        marginLeft: '15px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    collapseDeleteArtistDataContainer: {
        display: 'flex', 
        alignItems: 'center'
    },
    collapseDeleteArtistDataWarningIcon: {
        marginLeft: '-5px', 
        color: theme.palette.primary.attention
    },
    collapseDeleteArtistDataText: {
        marginLeft: '15px', 
        color: theme.palette.primary.attention, 
        lineHeight: '28px' 
    },
    moveArtistSearchField: {
        marginTop: '10px', 
        marginBottom: '10px' 
    },
    moveArtistDataOptions: {
        backgroundColor: theme.palette.background.default, 
        position: 'fixed', 
        padding: '15px'
    }
}));

export function DeleteArtist({
    selectedRowDataForDelete,
    open,
    setDisplayDeleteArtistModal,
    trigger
}) {
    const styles = useStyles();
    const [deleteArtistData, setDeleteArtistData] = useState(false);
    const [moveArtistData, setMoveArtistData] = useState(false);
    const [accoutnHolders, setAccountHolders] = useState([]);
    const artistToMoveData = useState('');
    const [artistIdToMoveData, setArtistIdToMoveData] = useState([])
    const [artistsToMoveData, setArtistsToMoveData] = useState([]);
    const searchArtistToMoveData = useState('');
    const [checked, setChecked] = useState(false);
    const [sortBy, setSortBy] = useState('name');
    const [sortType, setSortType] = useState('ASC');
    const [deleteArtistAlert, setDeleteArtistAlert] = useState('');
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [reportsAttachedToArtist, setReportsAttachedToArtist] = useState([]);
    const [songsMergedInSongsByArtist, setSongsMergedInSongsByArtist] = useState([]);
    const [songsByArtistWithoutSongsData, setSongsByArtistWithoutSongsData] = useState([]);
    const [artistDeleted, setArtistDeleted] = useState(false);

    const SEARCH_LIMIT = 5;
    const SEARCH_OFFSET = 0;

    const onDeleteArtist = async () => {
        setIsLoadingDelete(true);
        try {
            await deleteData(process.env.REACT_APP_SERVER_HOST + '/api/artist/', { ...selectedRowDataForDelete, confirmation: checked, moveArtistData: moveArtistData, deleteArtistData: deleteArtistData, artistToMoveData: artistIdToMoveData })
                .then((data) => {
                    setAccountHolders(data.uniqueAccountHoldersRelatedToArtist ? data.uniqueAccountHoldersRelatedToArtist : []);
                    setReportsAttachedToArtist(data.reportForSongsByArtist ? data.reportForSongsByArtist : []);
                    setSongsMergedInSongsByArtist(data.songsMergedInSongsForCurrentArtist ? data.songsMergedInSongsForCurrentArtist : []);
                    setSongsByArtistWithoutSongsData(data.songsByArtistWithoutSongsData ? data.songsByArtistWithoutSongsData : []);
                    if (!data.reportForSongsByArtist && !data.songsMergedInSongsForCurrentArtist && !data.songsByArtistWithoutSongsData) {
                        setDisplayDeleteArtistModal(false);
                        setArtistDeleted(true);
                        setChecked(false);
                        trigger();
                    }
                })
                .finally(() => {
                    setIsLoadingDelete(false);
                })
        } catch (error) {
            setDeleteArtistAlert(error.message);
            setIsLoadingDelete(false);
        }
    };

    useEffect(() => {
        if(moveArtistData && searchArtistToMoveData[0]){
            const getArtistsToTransferData = async() => {
                const fetchedData = await getData(process.env.REACT_APP_SERVER_HOST + `/api/artist/?&offset=${SEARCH_OFFSET}&limit=${SEARCH_LIMIT}&sort=${sortBy}&type=${sortType}&filter=${searchArtistToMoveData[0]}`);
                const filteredArtists = fetchedData.items.filter((artist) => artist.name !== selectedRowDataForDelete.name);
                setArtistsToMoveData(filteredArtists);
            }
            getArtistsToTransferData();
        }
        
        if(searchArtistToMoveData[0] === ''){
            setArtistsToMoveData([]);
        }
    }, [searchArtistToMoveData[0]])

    const handleSetArtistToTransferData = (e) => {
        e.preventDefault();
        const element = e.target;
        const artistIdToMoveData = artistsToMoveData.find((artist) => artist.name === element.innerText);
        setArtistIdToMoveData(artistIdToMoveData);
        artistToMoveData[1](element.innerText);
        searchArtistToMoveData[1]('');
        setArtistsToMoveData([]);
    }

    useEffect(() => {
        artistToMoveData[1]('');
        searchArtistToMoveData[1]('');
    }, [moveArtistData])

    return(
        <>
            <BrandMessageModal 
                open={artistDeleted}
                onClose={() => {
                    setArtistDeleted(false);
                    setArtistIdToMoveData([]);
                    setDeleteArtistData(false);
                    setMoveArtistData(false);
                }}
            >
                <Typography className={styles.inviteMessage}>
                    {selectedRowDataForDelete.name} was successfully deleted{
                    moveArtistData ? ' and all related CSV data was transferred to ' + artistIdToMoveData.name 
                    : 
                    deleteArtistData ? 'and all related CSV data' : ''
                }!
                </Typography>
            </BrandMessageModal>
            <BrandDeleteModal
                open={open}
                onClose={() => {
                    setDisplayDeleteArtistModal(false);
                    setDeleteArtistAlert('');
                    setReportsAttachedToArtist([]);
                    setSongsMergedInSongsByArtist([]);
                    setSongsByArtistWithoutSongsData([]);
                    setMoveArtistData(false);
                    setDeleteArtistData(false);
                    setArtistsToMoveData([]);
                    artistToMoveData[1]('');
                    searchArtistToMoveData[1]('');
                }}
                onDelete={() => onDeleteArtist()}
                loading={isLoadingDelete}
                disabled={deleteArtistData ? !deleteArtistData 
                    : (songsMergedInSongsByArtist.length > 0 && !(artistToMoveData[0] && moveArtistData)) ? !artistToMoveData[0] 
                    : (songsByArtistWithoutSongsData.length > 0 && !(artistToMoveData[0] && moveArtistData))}
                maxWidth={'sm'}
            >
                <Typography
                    variant="h5"
                    className={styles.deleteModalTitle}
                >
                    Delete Artist
                </Typography>
                {songsMergedInSongsByArtist.length > 0 && 
                    <Typography className={styles.warningMessage}>
                        This artist has songs merged in his songs, unmerge them first!
                    </Typography>
                }
                {deleteArtistAlert && <BrandAlert>{deleteArtistAlert}</BrandAlert>}
                <div className={(songsMergedInSongsByArtist.length > 0 || reportsAttachedToArtist.length > 0 || songsByArtistWithoutSongsData.length > 0) ? styles.text : styles.modalSubheadText}>
                    Are you sure you want to delete "{selectedRowDataForDelete.name}"?
                </div>
                {songsByArtistWithoutSongsData.length > 0 && 
                    <Typography className={styles.warningMessage}>
                        This artist has song(s) without CSV related data.
                    </Typography>
                }
                {(songsMergedInSongsByArtist.length || reportsAttachedToArtist.length || songsByArtistWithoutSongsData.length) > 0 && (
                    <div className={styles.deleteModalCheckboxMainContainer}>
                        <Typography className={styles.warningMessage}>
                            Before you continue you must select the option for the artist:
                        </Typography>
                        <div className={styles.deleteModalCheckboxInnerContainer}>
                            <div className={styles.deleteModalCheckbox}>
                                <Checkbox 
                                    color='primary'
                                    onChange={() => setMoveArtistData(!moveArtistData)}
                                    disabled={deleteArtistData}
                                />
                            </div>
                            <div className={styles.deleteModalCheckboxText}>
                                Delete {selectedRowDataForDelete.name} and move all {songsByArtistWithoutSongsData.length ? 'song(s)' : 'CSV'} data to another artist.
                            </div>
                        </div>
                        <Collapse in={deleteArtistData}>
                            <div className={styles.collapseDeleteArtistDataContainer}>
                                <div className={styles.collapseDeleteArtistDataWarningIcon}>
                                    <ReportProblemOutlinedIcon/>
                                </div>
                                <div className={styles.collapseDeleteArtistDataText}>
                                    Deleting {selectedRowDataForDelete.name} and all {songsByArtistWithoutSongsData.length ? 'song(s)' : 'CSV connected data'} will affect to other account holders reports. Affected account holders:
                                    <div>{accoutnHolders.length > 0 && accoutnHolders.map((accountHolder) => accountHolder + '; ')}</div>
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={moveArtistData}>
                            <div className={styles.moveArtistSearchField}>Search Artist</div>
                            <BrandSearchInput
                                placeholder='Search for artist'
                                $value={artistToMoveData[0] !== '' ? artistToMoveData : searchArtistToMoveData}
                                classes={{ input: styles.moveArtistSearchInput }}
                            />
                            <Collapse in={artistsToMoveData.length > 0}>
                                <div className={styles.moveArtistDataOptions}>{artistsToMoveData.map((artist, i) => <div key={i} className={styles.listElement} onClick={(e) => handleSetArtistToTransferData(e)} value={artist.name}>{artist.name}</div>)}</div>
                            </Collapse>
                        </Collapse>
                    </div>
                )}
            </BrandDeleteModal>
        </>
    )
}