import React from 'react';
import ProgressBar from './ProgressBar.js';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary.js';

export default function Upload() {
  return (
    <ErrorBoundary>
      <ProgressBar></ProgressBar>
    </ErrorBoundary>
  );
}
