import { makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { BrandButton } from "../CoreComponents/BrandButton";
import { BrandInput } from "../CoreComponents/BrandInput";
import { BrandMenuItem, BrandSelect } from "../CoreComponents/BrandSelect";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '80%'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    titles: {
        letterSpacing: 2
    },
    ownershipTitle: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    ownershipDataContainer: {
        width: '100%'
    },
    ownershipColTitles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '20px',
        '& .MuiInputBase-root': {
            width: '200px'
        },
    },
    dataElement: {
        width: '200px',
        font: 'normal normal normal 14px/35px Roboto',
    },
    addButton: {
        color: `${theme.palette.background.default} !important`,
        font: 'normal normal normal 16px/40px Roboto !important',
        width: 120
    },
    removeButton: {
        font: 'normal normal normal 16px/35px Roboto !important',
        width: 180
    }
}));
function ManageOwnershipRegistrationFrom() {
    const styles = useStyles();
    const [newOwnership, setNewOwnership] = useState([]);
    const [showInputFields, setShowInputFields] = useState(false);
    const ownershipName = useState('');
    const ownershipEmail = useState('');
    const ownershipType = useState('');
    const ownershipPercent = useState('');

    const ownershipTypes = ['Master', 'Publishing'];

    const addNewOwnershipHandler = () => {
        setShowInputFields(true)
    }

    const addButtonHandler = () => {
        setNewOwnership([...newOwnership, {
            name: ownershipName[0],
            email: ownershipEmail[0],
            ownershipType: ownershipType[0],
            ownership: ownershipPercent[0]
        }]);

        setShowInputFields(false);
        
        ownershipName[1]('');
        ownershipEmail[1]('');
        ownershipType[1]('');
        ownershipPercent[1]('');
    };

    const removeButtonHandler = (index) => {
        newOwnership.splice(index, 1);
        setNewOwnership([...newOwnership] || []);
    };


    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <Typography
                    className={styles.titles}
                    component='div'
                    variant='h6'
                >
                    Manage Ownership
                </Typography>
                <div className={styles.ownershipTitle} onClick={addNewOwnershipHandler}>+ Add New Ownership</div>
            </div>
            <div className={styles.ownershipDataContainer}>
                {
                    showInputFields ?
                        <div className={styles.ownershipColTitles}>
                            <BrandInput
                                placeholder='Name'
                                $value={ownershipName}
                            />
                            <BrandInput
                                placeholder='Email'
                                $value={ownershipEmail}
                            />
                            <BrandSelect
                                className={styles.selectOwnership}
                                $value={ownershipType}
                            >
                                {ownershipTypes.map((x, index) => (
                                    <BrandMenuItem key={index} value={x}>
                                        {x}
                                    </BrandMenuItem>
                                ))}
                            </BrandSelect>
                            <BrandInput
                                placeholder='Ownership'
                                $value={ownershipPercent}
                            />
                            <BrandButton className={styles.addButton} onClick={addButtonHandler}>ADD</BrandButton>
                        </div>
                        :
                        null
                }
                <div className={styles.ownershipColTitles}>
                    <div className={styles.dataElement}>Name</div>
                    <div className={styles.dataElement}>Email</div>
                    <div className={styles.dataElement}>Ownership Type</div>
                    <div className={styles.dataElement}>Ownership (%)</div>
                    <BrandButton className={styles.removeButton} ></BrandButton>
                </div>
                {
                    newOwnership.map((x, index) => (
                        <div key={index} className={styles.ownershipColTitles}>
                            <div className={styles.dataElement}>{x.name}</div>
                            <div className={styles.dataElement}>{x.email}</div>
                            <div className={styles.dataElement}>{x.ownershipType}</div>
                            <div className={styles.dataElement}>{x.ownership} (%)</div>
                            <BrandButton color={'secondary'} className={styles.removeButton} onClick={() => removeButtonHandler(index)}>Remove</BrandButton>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default ManageOwnershipRegistrationFrom;