import { Box, Button, Chip, CircularProgress, InputAdornment, TextField, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useInfiniteScrolling } from "../../hooks/useInfiniteScrolling";
import { SearchOutlined } from "@material-ui/icons";
import { useDebounce } from "../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        display: 'flex',
        flexDirection: 'column',
        width: '290px',
        rowGap: '10px',
        padding: '15px'
    },
    optionsWrapper: {
        height: '200px',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column'
    },
    chipsWrapper: {
        '& > :not(:last-child)': { mr: 1 },
        '& > *': { mr: 1 },
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '10px',
        marginTop: '15px'
    },
    chip: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.hover,
        },
        padding: '2px',
        '& .MuiChip-deleteIcon': {
            color: 'black'
        },
        maxWidth: '95%'
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'transale(-50%, -50%)'
    }
}));

const CustomAutocomplete = ({
    searchQuery,
    setSearchQuery,
    options,
    setOptions,
    offsetRef,
    callback,
    selectedOptions,
    setSelectedOptions,
    placeholder,
    allSelectedText,
    isLoading,
    handleResetAutoCompletes,
    disableAccountHolderAutoComplete
}) => {
    const styles = useStyles();
    const elementRef = useRef();

    const [allSelectedClicked, setAllSelectedClicked] = useState(false);
    const [setIsFetching] = useInfiniteScrolling(elementRef, async () => await handleGetOptions());
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 1000);

    const handleOnDelete = (targetId, name) => {
        if (name === allSelectedText) {
            offsetRef.current = 0;
            setOptions([]);
            handleGetOptions();
            setAllSelectedClicked(false);
        }
        setSelectedOptions((state) => state.filter(({ id }) => id !== targetId));
        handleResetAutoCompletes?.()
    }

    const handleGetOptions = async () => {
        await callback();
        setIsFetching(false);
    }

    const handleItemOnClick = (targetId, name) => {
        if (name === allSelectedText) {
            setSelectedOptions([]);
            setOptions([]);
            setAllSelectedClicked(true);
        }

        const found = selectedOptions.find(({ id }) => id === targetId) !== undefined;

        if (found) {
            if (name === allSelectedText) {
                offsetRef.current = 0;
                setOptions([]);
                handleGetOptions();
            }
            setSelectedOptions((state) => state.filter(({ id }) => id !== targetId));
            return;
        }


        if (selectedOptions.length >= 5) {
            return;
        }

        setSelectedOptions((prev) => prev.concat({ id: targetId, name }));
        handleResetAutoCompletes?.();
    }

    useEffect(() => {
        offsetRef.current = 0;
        setOptions([]);
        handleGetOptions();
    }, [searchQuery]);

    useEffect(() => {
        setAllSelectedClicked(selectedOptions.flatMap(obj => obj.name).includes(allSelectedText));
    }, [selectedOptions]);

    useEffect(() => {
        setSearchQuery(debouncedSearch)
    }, [debouncedSearch]);

    return (
        <Box className={styles.autocomplete}>
            <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={placeholder}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchOutlined />
                        </InputAdornment>
                    )
                }}
                autoFocus
            />
            <Box
                className={styles.optionsWrapper}
                ref={elementRef}
            >
                {placeholder === 'Search account holders' ?
                    ''
                    :
                    <Button value={'Select All'} onClick={() => handleItemOnClick('', allSelectedText)}>
                        {allSelectedText}
                    </Button>
                }
                {isLoading ?
                    <CircularProgress size={20} className={styles.spinner} />
                    :
                    !allSelectedClicked &&
                    options && options.map(({ id, name,artistName }, index) => (
                        <Button key={id} value={id} onClick={() => handleItemOnClick(id, name)}>
                            {artistName ? `${artistName} - ${name}` : name}
                        </Button>
                    ))}
            </Box>
            <Box
                mt={3}
                className={styles.chipsWrapper}
            >
                {selectedOptions.map(({ id, name }, index) => (
                    <Chip
                        disabled={disableAccountHolderAutoComplete}
                        key={index + name}
                        label={name}
                        onClick={() => handleOnDelete(id, name)}
                        onDelete={() => handleOnDelete(id, name)}
                        className={styles.chip}
                    />
                ))}
            </Box>
        </Box>
    )
};

export default CustomAutocomplete;