import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrandButton } from '../CoreComponents/BrandButton.js';
import { BrandLoaderDots } from '../CoreComponents/BrandLoader.js';
import { BrandInput } from '../CoreComponents/BrandInput.js';
import { getData, postData } from '../utils/FetchUtils';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/BrandSelect.js';
import { textIsEmpty, textIsNumber } from '../utils/ValidationUtils.js';
import { BrandAlert } from '../CoreComponents/BrandAlert.js';

require('dotenv').config();

const useStyles = makeStyles((theme) => ({
    agentContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    dataCol: {
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(38),
        justifyContent: 'space-between',
    },
    dataRow: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },
    dataRowData: {
        marginBottom: theme.spacing(1),
        font: 'normal normal bold 16px/20px Roboto',
        letterSpacing: '1.6px',
    },
    dataRowLabel: {
        color: '#A5A5A5',
        font: 'normal normal normal 12px/15px Roboto',
        letterSpacing: '1.2px',
    },
    contentContainer: {
        overflow: 'auto',
        height: '100%',
        width: '100%',
        padding: '2rem',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start'
    },
    agentsInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    chainSection: {
        borderTop: `1px solid ${theme.palette.primary.light}`,
        margin: theme.spacing(1),
    },

    select: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '& .MuiInputBase-root': {
            minWidth: '215px',
            background: '#000000',
            border: '#000000'
        },
        "& .MuiSelect-root": {
            letterSpacing: '0px',
            font: 'normal normal medium 18px/26px Roboto',
            fontSize: '18px'
        }
    },
    artistSelectLabel: {
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '1px',
        color: '#FFFFFFDE'
    },
    resetSyncContainer: {
        display: 'flex',
        gap: theme.spacing(3),
        flexDirection: 'column',
    },
    successfulReset:{
        color: theme.palette.primary.main
    }
}));


export const NftAgentStatistics = () => {
    const styles = useStyles();
    const [statsData, setStatsData] = useState(null);
    const [fetchedData, setFetchedData] = useState(null);
    const targetBlockNumber = useState(29011769);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [blockchains] = useState([{ id: 'ETHEREUM', name: 'Ethereum' }, { id: 'MATIC', name: 'Matic' }]);
    const selectedBlockchain = useState('ETHEREUM');
    const blockNumberToResetSync = useState('');
    const [isResettingSync, setIsResettingSync] = useState(false);
    const [alert, setAlert] = useState('');
    const [successfulReset, setSuccessfulReset] = useState('');

    useEffect(() => {
        setAlert('');
    }, [selectedBlockchain[0], blockNumberToResetSync[0]]);

    useEffect(() => {
        setTimeout(() => setSuccessfulReset(''), 5000);
    }, [successfulReset]);

    function resetSync() {
        setIsResettingSync(true);
        const reqObj = {
            "resetCurrentBlockNumber": Number(blockNumberToResetSync[0]),
            "chainName": selectedBlockchain[0]
        };

        postData(process.env.REACT_APP_SERVER_HOST + '/api/nft/reset-token-sync', reqObj)
            .then(data => {
                setIsResettingSync(false);
                setSuccessfulReset('Successfully reset!');

            }, error => {
                setAlert('Server Error. Please check your Target block number to reset sync or the name of the selected blockchain');
                setIsResettingSync(false);
            })
    };

    const refreshData = () => {
        setIsFetchingData(true);

        return getData(process.env.REACT_APP_SERVER_HOST + '/api/nft/agent-status')
            .then(data => {
                setIsFetchingData(false);
                // data.lastProcessedBlockTimeStamp *= 1000;
                setFetchedData(data);
                return data;
            }, error => {
                setIsFetchingData(false);
            });
    };

    useEffect(() => {
        refreshData();

        const intervalHandler = setInterval(() => {
            refreshData();
        }, 5000);

        return () => {
            console.log(`clear interval ${intervalHandler}`);
            clearInterval(intervalHandler);
        }
    }, []);

    useEffect(() => {
        if (!fetchedData) {
            return;
        }

        setStatsData({
            ...fetchedData,
            agents: fetchedData.agents.map(x => ({
                ...x,
                lastProcessedBlockTimeStamp: x.lastProcessedBlockTimeStamp * 1000,
                timeLeftUntilTargetBlockMinutes: (((targetBlockNumber[0] || 0) - x.lastProcessedBlockNumber) * x.processingSpeed) / 60,
                timeUntilLastKnownBlockSyncLeftMinutes: ((x.latestKnownBlockNumber - x.lastProcessedBlockNumber) * x.processingSpeed) / 60,
            }))
        });
    }, [fetchedData, targetBlockNumber[0]]);

    return (
        <div className={styles.agentContainer}>
            <div className={styles.progressBarContainer}>
                <BrandInput label="Target block number" $value={targetBlockNumber} />
                <BrandButton
                    onClick={() => refreshData()}
                    className={styles.continue}
                >
                    {isFetchingData ?
                        <BrandLoaderDots />
                        :
                        'Refresh'
                    }
                </BrandButton>
                {statsData ?
                    <div>
                        <div className={styles.dataRow}>
                            <div className={styles.dataRowData}>{statsData.executeMetaTransactions}</div>
                            <div className={styles.dataRowLabel}>Execute meta transaction count</div>
                        </div>
                        <div className={styles.dataRow}>
                            <div className={styles.dataRowData}>{statsData.normalTransactions}</div>
                            <div className={styles.dataRowLabel}>Sells transaction count</div>
                        </div>
                        <div className={styles.agentsInfoContainer}>
                            {statsData.agents.map(x =>
                                <div key={x.chainName} className={styles.chainSection}>
                                    <div className={styles.dataRow}>
                                        <div className={styles.dataRowData}>{x.chainName}</div>
                                        <div className={styles.dataRowLabel}>Chain name</div>
                                    </div>
                                    <div className={styles.dataRow}>
                                        <div className={styles.dataRowData}>{Math.trunc(x.timeLeftUntilTargetBlockMinutes / 60)}:{Math.trunc(x.timeLeftUntilTargetBlockMinutes % 60).toString().padStart(2, '0')}</div>
                                        <div className={styles.dataRowLabel}>ETA until target block</div>
                                    </div>
                                    <div className={styles.dataRow}>
                                        <div className={styles.dataRowData}>{(targetBlockNumber[0] || 0) - x.lastProcessedBlockNumber}</div>
                                        <div className={styles.dataRowLabel}>Blocks left until reaching target</div>
                                    </div>
                                    <div className={styles.dataRow}>
                                        <div className={styles.dataRowData}>{x.latestKnownBlockNumber}</div>
                                        <div className={styles.dataRowLabel}>Last known block number</div>
                                    </div>
                                    <div className={styles.dataRow}>
                                        <div className={styles.dataRowData}>{Math.trunc(x.timeUntilLastKnownBlockSyncLeftMinutes / 60)}:{Math.trunc(x.timeUntilLastKnownBlockSyncLeftMinutes % 60).toString().padStart(2, '0')}</div>
                                        <div className={styles.dataRowLabel}>ETA until last known block</div>
                                    </div>
                                    <div className={styles.dataRow}>
                                        <div className={styles.dataRowData}>{x.latestKnownBlockNumber - x.lastProcessedBlockNumber}</div>
                                        <div className={styles.dataRowLabel}>Blocks left until reaching last known block</div>
                                    </div>
                                    <div className={styles.dataRow}>
                                        <div className={styles.dataRowData}>{x.processingSpeed} seconds</div>
                                        <div className={styles.dataRowLabel}>Average processing speed</div>
                                    </div>
                                    <div className={styles.dataRow}>
                                        <div className={styles.dataRowData}>{x.lastProcessedBlockNumber}</div>
                                        <div className={styles.dataRowLabel}>Last processed block number</div>
                                    </div>
                                    <div className={styles.dataRow}>
                                        <div className={styles.dataRowData}>{new Date(x.lastProcessedBlockTimeStamp).toISOString()}</div>
                                        <div className={styles.dataRowLabel}>Last processed block timestamp</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    : null}
            </div>
            <div className={styles.resetSyncContainer}>
                <div className={styles.select}>
                    <div className={styles.artistSelectLabel}>Blockchain to reset sync:</div>
                    <div>
                        <BrandSelect $value={selectedBlockchain}>
                            {blockchains.map(x => (
                                <BrandMenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </BrandMenuItem>
                            ))}
                        </BrandSelect>
                    </div>
                </div>

                <BrandInput $value={blockNumberToResetSync} label='Target block number to reset sync' />
                <BrandButton
                    onClick={resetSync}
                    className={styles.continue}
                    disabled={isResettingSync}
                >
                    {isResettingSync ?
                        <BrandLoaderDots />
                        :
                        'Reset Sync'
                    }
                </BrandButton>
                {alert ? <BrandAlert>{alert}</BrandAlert> : null}
                {successfulReset ? <div className={styles.successfulReset}>{successfulReset}</div> : null}
            </div>
            <div />
        </div>
    );
}