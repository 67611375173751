import { useEffect, useRef, useState } from "react";
import { postData } from "../../utils/FetchUtils";
import PopoverAutocomplete from "./PopoverAutocomplete";

const ReportingCompaniesAutocomplete = ({
    selectedOptions,
    setSelectedOptions,
    selectedSongs,
    selectedCountries,
    selectedAccountHolders,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [textContent,setTextContent] = useState('All Reporting Companies');
    const [placeholder,setPlaceholder] = useState('Search reporting companies');
    const [allSelectedText,setAllSelectedText] = useState('All reporting companies selected');
    const [isLoading,setIsLoading] = useState(true);
    const offset = useRef(0);

    const callback = async () => {
        const searchSongsIDs = selectedSongs.map((x) => x.id).join(', ');
        const searchCountriesNames = selectedCountries.map((x) => x.name).join(' | ');
        const searchAccountHoldersIDs = selectedAccountHolders.map((x) => x.id).join(', ');

        const body = {
            accountHolderIDs: searchAccountHoldersIDs,
            songsIDs: searchSongsIDs,
            countriesNames: searchCountriesNames,
        };

        const query = `/api/earnings/reporting-companies?offset=${offset.current}&limit=10&filter=${searchQuery}`;
        const { items } = await postData(process.env.REACT_APP_SERVER_HOST + query, body);

        setIsLoading(false);
        setOptions((options) => options.concat(...items));
        offset.current += 10;
    };

    const enabled = selectedAccountHolders.length > 0 
        && selectedSongs.length > 0 
        && selectedCountries.length > 0;

        useEffect(()=>{
            setSelectedOptions([])
        },[enabled])

    return (
        <PopoverAutocomplete
            disabled={!enabled}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            options={options}
            setOptions={setOptions}
            offset={offset}
            callback={callback}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            textContent={textContent}
            placeholder={placeholder}
            allSelectedText={allSelectedText}
            isLoading={isLoading}
        />
    )
};

export default ReportingCompaniesAutocomplete;