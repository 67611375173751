import React, { useState } from 'react';
import {
    Typography,
    Popover
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
        maxWidth: ({popoverMaxWidth}) => popoverMaxWidth ? theme.spacing(popoverMaxWidth) : ''
    }
}));

export function BrandPopover({
    popoverData,
    popoverDataFormated,
    popoverMaxWidth,
    ...props
}) {
    const styles = useStyles({popoverMaxWidth});

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <>
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {popoverDataFormated}
            </div>
            <Popover
                id="mouse-over-popover"
                className={styles.popover}
                classes={{
                    paper: styles.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                {...props}
            >
                {Array.isArray(popoverData) ?
                    popoverData.map(x => {
                        return (
                            <Typography key={`${x}${Math.random()}`} color='primary'>{x}</Typography>
                        )
                    })
                    :
                    <Typography color='primary'>{popoverData}</Typography>
                }
            </Popover>
        </>
    )
}