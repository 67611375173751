import React, { useState, useEffect, } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, Popover, Switch, Typography } from '@material-ui/core';
import ethSvg from '../../assets/ethSvg.svg'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { BrandVerticalBarsChart, BrandDoughnutChart } from '../CoreComponents/BrandChart'
import { endOfMonth, endOfYear, previousMonday, set, startOfMonth, startOfYear } from 'date-fns'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { NftHorizontalChart } from './NftHorizontalChart';
import { isMonday } from 'date-fns/esm';
import { getData } from '../utils/FetchUtils';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/BrandSelect';
import { BrandAlert } from '../CoreComponents/BrandAlert';
import { BrandLoader } from '../CoreComponents/BrandLoader';
import { formatMoney } from '../utils/NumberUtils';
import {parseDate} from '../utils/DateUtils'


const checkAccountHolders = (err) => {
    return err.message == 'User does not have account holders' ? false : true
}


const calendarButtonsText = [
    'Today',
    'This Week',
    'Last Month',
    'Last Quarter',
    'Last Year',
    'All Time'
]

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(4),
        alignItems: 'center',
        justifyContent: 'center'
    },
    generalSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    generalTitle: {
        marginTop: theme.spacing(3),
        font: 'normal normal normal 24px/34px Roboto'
    },
    salesContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: theme.spacing(55)
    },
    sales: {
        marginTop: theme.spacing(4),
        borderRight: `2px solid ${theme.palette.primary.border}`,
        height: '-webkit-fill-available',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        gap: theme.spacing(6)
    },
    salesCalculations: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

    },
    salesData: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        marginTop: theme.spacing(0.5),
        font: 'normal normal normal 25px/37px Roboto',
        wordBreak: 'break-all',
        width: '85%',
        fontSize: '22px',
        lineHeight: '26px'
    },
    salesTitles: {
        font: 'normal normal normal 18px/37px Roboto',
    },
    datePickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        marginLeft: theme.spacing(12),
        '& .MuiPickersStaticWrapper-staticWrapperRoot': {
            backgroundColor: theme.palette.background.darkNuance,
            borderRight: `2px solid ${theme.palette.primary.border}`
        },
        '& .MuiPickersCalendarHeader-iconButton': {
            backgroundColor: theme.palette.background.darkNuance,
            color: 'white'
        },
    },
    datePicker: {
        display: 'flex',
    },
    secondDatePicker: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end'
    },
    dateRange: {
        marginBottom: theme.spacing(2)
    },
    rangesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: theme.spacing(43),
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.darkNuance,
        width: theme.spacing(24)
    },
    ranges: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: theme.spacing(43),
        justifyContent: 'center'
    },
    rangeTitle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        cursor: 'pointer',
        font: 'normal normal normal 18px/24px Roboto'
    },
    earningSectionHeader: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(6)

    },
    earningSectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: `2px solid ${theme.palette.primary.border}`,
        marginTop: theme.spacing(6),
        paddingTop: theme.spacing(6),
    },
    totalEarningsSection: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(6),
        width: theme.spacing(169)
    },
    totalEarningsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(6),
        width: '100%'
    },
    switchContainer: {
        display: 'flex',
        justifyContent: ' center',
        alignItems: 'center',
        font: 'normal normal normal 19px/26px Roboto'
    },
    chartContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `2px solid ${theme.palette.primary.border}`,
        paddingBottom: theme.spacing(6),
        marginBottom: theme.spacing(6)
    },
    titlesContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    calculations: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    pieChartContainer: {
        display: 'flex',
        // justifyContent: 'space-evenly',
        alignItems: 'center',
        // gap: theme.spacing(5),
        borderBottom: `2px solid ${theme.palette.primary.border}`,
        width: '100%',
        gap: '20px'
    },
    vertical: {
        height: '350px',
        display: 'inline-block',
        border: `1px solid ${theme.palette.primary.border}`
    },
    chart: {
        display: 'flex',
        alignItems: 'end'
    },
    earningDateRange: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        font: 'normal normal normal 18px/24px Roboto'
    },
    datePickerContainerEarnngSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiPickersStaticWrapper-staticWrapperRoot': {
            backgroundColor: theme.palette.background.darkNuance,
            borderRight: `2px solid ${theme.palette.primary.border}`
        },
        '& .MuiPickersCalendarHeader-iconButton': {
            backgroundColor: theme.palette.background.darkNuance,
            color: 'white'
        },
    },
    buttonName: {
        textTransform: 'none',
        font: 'normal normal normal 16px/21px Roboto',
        marginBottom: theme.spacing(1)
    },
    calculationsTitles: {
        font: 'normal normal normal 19px/37px Roboto'
    },
    calculationsData: {
        display: 'flex',
        alignItems: 'center',
        font: 'normal normal normal 25px/37px Roboto',
        wordBreak: 'break-all',
        lineHeight: '26px',
        fontSize: '24px'
    },
    totalEarningsTitles: {
        font: 'normal normal normal 19px/37px Roboto'
    },
    noDataAvailableField: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '580px',
        height: '500px'
    },
    select: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '& .MuiInputBase-root': {
            minWidth: '215px',
            borderColor: theme.palette.primary.border
        },
        "& .MuiSelect-root": {
            letterSpacing: '0px',
            font: 'normal normal medium 18px/26px Roboto',
            fontSize: '18px'
        }
    },
    artistSelectLabel: {
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '1px',
        color: theme.palette.text.lightYellow
    },
    isHaveAttachedAcountholderMessage: {
        display: 'flex',
        justifyContent: 'center'
    },
    alertMessage: {
        marginTop: '-28px'
    },
    selectContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '1352px'
    },
    doughnutChartContainer: {
        width: '675px'
    },
    calculationsWrapper: {
        width: '50%'
    }
}));

export const EthIcon = () => {
    return (
        <img src={ethSvg} alt='' style={{
            width: '16px',
            height: '25px',
            marginTop: '2px',
            marginBottom: '2px',
            marginRight: '8px'
        }} />
    );
}

export default function NftDashboard() {
    const styles = useStyles();
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [selectedStartDateEarningSection, setSelectedStartDateEarningSection] = useState(new Date());
    const [selectedEndDateEarningSection, setSelectedEndDateEarningSection] = useState(new Date());
    const [switchState, setSwitchState] = useState(false);
    const [generalSectionArrow, setGeneralSectionArrow] = useState(false);
    const [earningSectionArrow, setEarningSectionArrow] = useState(false);
    const [earningAnchorEl, setEarningAnchorEl] = useState(null);
    const [generalSectionData, setGeneralSectionData] = useState({});
    const [earningSectionDataNfts, setEarningSectionDataNfts] = useState({});
    const [earningSectionDataCollections, setEarningSectionDataCollections] = useState({});
    const [monthChartData, setMonthChartData] = useState([]);
    const [yearChartData, setYearChartData] = useState([]);
    const [averageAndHighestEarning, setAverageAndHighestEarning] = useState(null);
    const [generalAnchorEl, setGeneralAnchorEl] = useState(null);
    const [dateRangeTitleGeneralSection, setDateRangeTitleGeneralSection] = useState(calendarButtonsText[0]);
    const [dateRangeTitleEarningSection, setDateRangeTitleEarningSection] = useState(calendarButtonsText[0]);
    const openEarningPopover = Boolean(earningAnchorEl);
    const idEarningSection = openEarningPopover ? 'earning-popover' : undefined;
    const openGeneralPopover = Boolean(generalAnchorEl);
    const idGeneralSection = openGeneralPopover ? 'general-popover' : undefined;
    const [pieChartColors] = useState(['#9A2A9D', '#F96C00', '#EDE107', '#03B56D', '#57C7FD']);
    const [isHaveAccountHolders, setIsHaveAccountHolders] = useState(true);
    const [accountHolders, setAccountHolders] = useState([]);
    const selectedAccountHolder = useState(0);
    const [error, setError] = useState('');
    const [disableSelect, setDisableSelect] = useState(false);
    const [successfulRequests, setSuccessfulReqeusts] = useState({ f: false, s: false, t: false, fo: false, fi: false, si: false });
    const [switchCurrency, setSwitchCurrency] = useState(false);
    const dateOfFirstTransactionEthereum  =  new Date('2015-07-30');

    const handleStartDateChange = (date, section) => {

        if (section === 'generalSection') {
            setSelectedStartDate(date);
        } else {
            setSelectedStartDateEarningSection(date);
        }
    };

    const handleEndDateChange = (date, section) => {

        if (section === 'generalSection') {
            setSelectedEndDate(date);
        } else {
            setSelectedEndDateEarningSection(date);
        }
    };

    const switchHandleChange = (e) => {
        setSwitchState(e.target.checked);
    };
    const switchHandleChangeCurrency = (e) => {
        setSwitchCurrency(e.target.checked);
    };

    const arrowHandler = (e) => {
        setGeneralSectionArrow(generalSectionArrow ? false : true)
        setGeneralAnchorEl(e.currentTarget);
    }

    const pieChartsDateHandler = (e) => {
        setEarningSectionArrow(earningSectionArrow ? false : true)
        setEarningAnchorEl(e.currentTarget);
    }

    const handleCloseEarningPopover = () => {
        setEarningAnchorEl(null);
        setEarningSectionArrow(false);
    };

    const handleCloseGeneralPopover = () => {
        setGeneralAnchorEl(null);
        setGeneralSectionArrow(false);
    };

    const getDateOfFirstTransaction = async () =>{
        const result = await getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-date-of-first-transaction?accountHolderId=${selectedAccountHolder[0]}`);
        if(result.date){
            return new Date(result.date);
        }else{
            return dateOfFirstTransactionEthereum
        }
          
    }

    const calendarHandler = async (e, section) => {

        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const todayResult = date;

        const thisWeekResult = isMonday(date) ? date : previousMonday(new Date(year, month, day));

        const firstDayOfPreviousMonth = startOfMonth(new Date(year, month - 1, day));
        const lastDayOfPreviousMonth = endOfMonth(new Date(year, month - 1, day));

        const firstDayOfLastQuarter = startOfMonth(new Date(year, month - 3, day));
        const lastDayOfLastQuarter = endOfMonth(new Date(year, month - 1, day));

        const firstDayOfLastYear = startOfYear(new Date(year - 1, month, day));
        const lastDayOfLastYear = endOfYear(new Date(year - 1, month, day));
        let startDateForAllTime = '';
        if(!selectedAccountHolder[0]){
            startDateForAllTime = dateOfFirstTransactionEthereum;
        }else{
            startDateForAllTime = await getDateOfFirstTransaction();
        }
        

        if (section === 'generalSection') {
            setDateRangeTitleGeneralSection(e.target.innerText);
            switch (e.target.innerText) {
                case 'Today':
                    setSelectedStartDate(todayResult);
                    setSelectedEndDate(todayResult);
                    break;
                case 'This Week':
                    setSelectedStartDate(thisWeekResult);
                    setSelectedEndDate(todayResult);
                    break;
                case 'Last Month':
                    setSelectedStartDate(firstDayOfPreviousMonth);
                    setSelectedEndDate(lastDayOfPreviousMonth);
                    break;
                case 'Last Quarter':
                    setSelectedStartDate(firstDayOfLastQuarter);
                    setSelectedEndDate(lastDayOfLastQuarter);
                    break;
                case 'Last Year':
                    setSelectedStartDate(firstDayOfLastYear);
                    setSelectedEndDate(lastDayOfLastYear);
                    break;
                case 'All Time':
                    setSelectedStartDate(startDateForAllTime);
                    setSelectedEndDate(todayResult);
                    break;
                default:
                    break;
            }
        } else {
            setDateRangeTitleEarningSection(e.target.innerText)
            switch (e.target.innerText) {
                case 'Today':
                    setSelectedStartDateEarningSection(todayResult);
                    setSelectedEndDateEarningSection(todayResult);
                    break;
                case 'This Week':
                    setSelectedStartDateEarningSection(thisWeekResult);
                    setSelectedEndDateEarningSection(todayResult);
                    break;
                case 'Last Month':
                    setSelectedStartDateEarningSection(firstDayOfPreviousMonth);
                    setSelectedEndDateEarningSection(lastDayOfPreviousMonth);
                    break;
                case 'Last Quarter':
                    setSelectedStartDateEarningSection(firstDayOfLastQuarter);
                    setSelectedEndDateEarningSection(lastDayOfLastQuarter);
                    break;
                case 'Last Year':
                    setSelectedStartDateEarningSection(firstDayOfLastYear);
                    setSelectedEndDateEarningSection(lastDayOfLastYear);
                    break;
                case 'All Time':
                    setSelectedStartDateEarningSection(startDateForAllTime);
                    setSelectedEndDateEarningSection(todayResult);
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/my`)
            .then(data => {
                setAccountHolders(data);
                setSuccessfulReqeusts({ f: false, s: false, t: false, fo: false, fi: false, si: false });
                setDisableSelect(true);
            },
                err => {
                    console.log(err);
                });
    }, [selectedAccountHolder[0]]);


    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-top-earning-exchanges?startDate=${selectedStartDate}&endDate=${selectedEndDate}&accountHolderId=${selectedAccountHolder[0]}`)
            .then(data => {
                setError('');
                setGeneralSectionData(data);
                setSuccessfulReqeusts(prev => {
                    prev.f = true;
                    return { ...prev};
                })
            })
            .catch(error => {
                console.log(error.message);
                setIsHaveAccountHolders(checkAccountHolders(error));
                setError(error.message);
            })
    }, [selectedStartDate, selectedEndDate, selectedAccountHolder[0], switchCurrency]);

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-top-earning-nfts?startDate=${selectedStartDateEarningSection}&endDate=${selectedEndDateEarningSection}&accountHolderId=${selectedAccountHolder[0]}`)
            .then(data => {
                data.data.forEach((x, i) => {
                    x.color = pieChartColors[i];
                });
                setEarningSectionDataNfts(data);
                setError('');
                setSuccessfulReqeusts(prev => {
                    prev.s = true;
                    return { ...prev};
                })
            })
            .catch(error => {
                console.log(error.message);
                setIsHaveAccountHolders(checkAccountHolders(error));
                setError(error.message);
            });

        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-top-earning-collections?startDate=${selectedStartDateEarningSection}&endDate=${selectedEndDateEarningSection}&accountHolderId=${selectedAccountHolder[0]}`)
            .then(data => {
                data.data.forEach((x, i) => {
                    x.color = pieChartColors[i]
                });
                setError('');
                setEarningSectionDataCollections(data);
                setSuccessfulReqeusts(prev => {
                    prev.t = true;
                    return { ...prev};
                })
            })
            .catch(error => {
                console.log(error.message);
                setIsHaveAccountHolders(checkAccountHolders(error));
                setError(error.message);
            });
    }, [selectedStartDateEarningSection, selectedEndDateEarningSection, selectedAccountHolder[0]]);

    useEffect(() => {
        changeSelectedStartDateEarningSection();

        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-total-earning-per-month-chart?&accountHolderId=${selectedAccountHolder[0]}`)
            .then(data => {
                setError('');
                data.map(x => {
                    x.month = x.month.substr(0, 3);
                })
                setMonthChartData(data);
                setSuccessfulReqeusts(prev => {
                    prev.fo = true;
                    return { ...prev};
                })
                    
            })
            .catch(error => {
                console.log(error.message);
                setIsHaveAccountHolders(checkAccountHolders(error));
                setError(error.message);
            });

        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-total-earning-per-year-chart?&accountHolderId=${selectedAccountHolder[0]}`)
            .then(data => {
                setError('');
                setYearChartData(data);
                setSuccessfulReqeusts(prev => {
                    prev.fi = true;
                    return { ...prev};
                })
            })
            .catch(error => {
                console.log(error.message);
                setIsHaveAccountHolders(checkAccountHolders(error));
                setError(error.message);
            });

        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-average-and-highest-earning-nft?&accountHolderId=${selectedAccountHolder[0]}`)
            .then(data => {
                setAverageAndHighestEarning(data);
                setSuccessfulReqeusts(prev => {
                    prev.si = true;
                    return { ...prev};
                })
            })
            .catch(error => {
                console.log(error.message);
            });



    }, [selectedAccountHolder[0]]);

    useEffect(() => {
        if (successfulRequests.f === true && successfulRequests.s === true && successfulRequests.t === true  && successfulRequests.fi === true && successfulRequests.si === true && successfulRequests.fo === true) {
            setDisableSelect(false);
        } else {
            setDisableSelect(true);
        }

        if (error === "User does not have NFT's") {
            setDisableSelect(false);
        }
    }, [successfulRequests, error]);

    useEffect(() => {

        if (dateRangeTitleEarningSection !== calendarButtonsText[0] || dateRangeTitleGeneralSection !== calendarButtonsText[0]) {
            setSwitchCurrency(false);
        }

    }, [dateRangeTitleEarningSection, dateRangeTitleGeneralSection]);

    const changeSelectedStartDateEarningSection = async ()=>{
        if(!selectedAccountHolder[0] && dateRangeTitleGeneralSection === calendarButtonsText[5]){
            setSelectedStartDate(dateOfFirstTransactionEthereum);
        }else if(dateRangeTitleGeneralSection === calendarButtonsText[5]){
            const startDateForAllTime = await getDateOfFirstTransaction();
            if(startDateForAllTime){
                setSelectedStartDate(startDateForAllTime);
            }else{
                setSelectedStartDate(dateOfFirstTransactionEthereum);
            }
        }
    }

    return (
        <div>

            {
                isHaveAccountHolders ?

                    <div className={styles.container}>
                        <div className={styles.selectContainer}>
                            <div className={styles.select}>
                                <div className={styles.artistSelectLabel}>Account Holder</div>
                                <div>
                                    <BrandSelect $value={selectedAccountHolder}>
                                        <BrandMenuItem disabled={disableSelect} value={0}>All</BrandMenuItem>
                                        {accountHolders.map(x => (
                                            <BrandMenuItem disabled={disableSelect} key={x.id} value={x.id}>
                                                {x.name}
                                            </BrandMenuItem>
                                        ))}
                                    </BrandSelect>
                                
                                </div>
                                {disableSelect ? <BrandLoader color='white' width={25} height={25} /> : ''}
                            </div>
                            {!error ?
                                (dateRangeTitleEarningSection === calendarButtonsText[0] && dateRangeTitleGeneralSection === calendarButtonsText[0] ?
                                    <div className={styles.switchContainer}>
                                        <div>ETH</div>
                                        <Switch
                                            checked={switchCurrency}
                                            onChange={switchHandleChangeCurrency}
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        <div>USD</div>
                                    </div>
                                    :
                                    null) : ''

                            }
                        </div>
                        {
                            error === "User does not have NFT's" ?
                                <BrandAlert className={styles.alertMessage}>{error}</BrandAlert>
                                :
                                <>
                                    <div className={styles.generalSection}>
                                        <div className={styles.salesContainer}>
                                            <Typography
                                                color='primary'
                                                component='div'
                                                variant='h5'
                                                className={styles.generalTitle}
                                            >
                                                General
                                            </Typography>
                                            <div className={styles.sales}>
                                                <div className={styles.salesCalculations}>
                                                    <div className={styles.salesTitles}>Primary Sales</div>
                                                    <div className={styles.salesData}>
                                                        < EthIcon />
                                                        {formatMoney(generalSectionData.primarySales ? generalSectionData.primarySales : 0)}
                                                    </div>
                                                </div>
                                                <div className={styles.salesCalculations}>
                                                    <div className={styles.salesTitles}>Secondary Sales</div>
                                                    <div className={styles.salesData}>
                                                        < EthIcon />
                                                        {formatMoney(generalSectionData.secondarySales ? generalSectionData.secondarySales : 0)}
                                                    </div>
                                                </div>
                                                <div className={styles.salesCalculations}>
                                                    <div className={styles.salesTitles}>Total Sales</div>
                                                    <div className={styles.salesData}>
                                                        {
                                                            switchCurrency ?
                                                                `$${formatMoney(generalSectionData.totalSales ? generalSectionData.totalSales.usdValue : 0)}`
                                                                :
                                                                <>
                                                                    < EthIcon />
                                                                    {formatMoney(generalSectionData.totalSales ? generalSectionData.totalSales.ethValue : 0)}
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.datePickerContainer}>
                                            <Typography
                                                component='div'
                                                variant='subtitle1'
                                                className={styles.rangeTitle}
                                                onClick={arrowHandler}
                                            >
                                                Date as of: {dateRangeTitleGeneralSection} {generalSectionArrow ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                            </Typography>
                                            <Popover
                                                id={idGeneralSection}
                                                open={openGeneralPopover}
                                                anchorEl={generalAnchorEl}
                                                onClose={handleCloseGeneralPopover}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                >
                                                    <div className={styles.datePickerContainer}>
                                                        <div className={styles.datePicker}>
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                variant="static"
                                                                format="MM/dd/yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Date picker inline"
                                                                value={selectedStartDate}
                                                                onChange={(e) => handleStartDateChange(e, 'generalSection')}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                                maxDate={parseDate(maxDate)}
                                                            />

                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                margin="normal"
                                                                variant="static"
                                                                id="date-picker-dialog"
                                                                label="Date picker dialog"
                                                                format="MM/dd/yyyy"
                                                                value={selectedEndDate}
                                                                onChange={(e) => handleEndDateChange(e, 'generalSection')}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                                maxDate={parseDate(maxDate)}
                                                            />

                                                            <div className={styles.rangesContainer}>
                                                                <div className={styles.ranges}>
                                                                    {calendarButtonsText.map(buttonName =>
                                                                        <Button
                                                                            key={`${buttonName}earningSection`}
                                                                            className={styles.buttonName}
                                                                            onClick={(e) => calendarHandler(e, 'generalSection')}
                                                                        >
                                                                            {buttonName}
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </MuiPickersUtilsProvider>
                                            </Popover>
                                            <NftHorizontalChart currencyState={switchCurrency} data={generalSectionData.data ? generalSectionData.data : ''} tooltipLabel='Earnings' width='816px' color='#1DAC9D' height='344px' />
                                        </div>
                                    </div >
                                    <div className={styles.earningSectionContainer}>
                                        <div className={styles.earningSectionHeader}>
                                            <Typography
                                                color='primary'
                                                component='div'
                                                variant='h5'
                                            >
                                                Top 5 Earning NFTs & Collections
                                            </Typography>
                                            <Typography
                                                component='div'
                                                variant='subtitle1'
                                                className={styles.earningDateRange}
                                                onClick={pieChartsDateHandler}
                                            >
                                                Date as of: {dateRangeTitleEarningSection} {earningSectionArrow ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                            </Typography>
                                            <Popover
                                                id={idEarningSection}
                                                open={openEarningPopover}
                                                anchorEl={earningAnchorEl}
                                                onClose={handleCloseEarningPopover}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <div className={styles.datePickerContainerEarnngSection}>
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}
                                                    >
                                                        <div className={styles.datePicker}>
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                variant="static"
                                                                format="MM/dd/yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Date picker inline"
                                                                value={selectedStartDateEarningSection}
                                                                onChange={(e) => handleStartDateChange(e, 'earningSection')}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                                maxDate={parseDate(maxDate)}
                                                            />

                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                margin="normal"
                                                                variant="static"
                                                                id="date-picker-dialog"
                                                                label="Date picker dialog"
                                                                format="MM/dd/yyyy"
                                                                value={selectedEndDateEarningSection}
                                                                onChange={(e) => handleEndDateChange(e, 'earningSection')}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                                maxDate={parseDate(maxDate)}
                                                            />

                                                            <div className={styles.rangesContainer}>
                                                                <div className={styles.ranges}>
                                                                    {calendarButtonsText.map(buttonName =>
                                                                        <Button
                                                                            key={`${buttonName}earningSection`}
                                                                            className={styles.buttonName}
                                                                            onClick={(e) => calendarHandler(e, 'earningSection')}
                                                                        >
                                                                            {buttonName}
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </Popover>
                                        </div>
                                        <div className={styles.pieChartContainer}>
                                            <div className={styles.doughnutChartContainer}>
                                                <div className={styles.calculations}>
                                                    <div className={styles.calculationsWrapper}>
                                                        <div className={styles.calculationsTitles}>Top 5 Earning NFTs</div>
                                                        <div className={styles.calculationsData}>
                                                            {switchCurrency ?
                                                                earningSectionDataNfts.topFourTotalEarningsSum ? `$${formatMoney(earningSectionDataNfts.topFourTotalEarningsSum.usdValue)}` : 0
                                                                :
                                                                <>
                                                                    < EthIcon />
                                                                    {earningSectionDataNfts.topFourTotalEarningsSum ? formatMoney(earningSectionDataNfts.topFourTotalEarningsSum.ethValue) : 0}
                                                                </>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className={styles.calculationsWrapper}>
                                                        <div className={styles.calculationsTitles}>All NFTs</div>
                                                        <div className={styles.calculationsData}>
                                                            < EthIcon />
                                                            {earningSectionDataNfts.allNfts ? formatMoney(earningSectionDataNfts.allNfts) : 0}
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    earningSectionDataNfts.data === undefined || earningSectionDataNfts.data.length === 0 ?
                                                        <div className={styles.noDataAvailableField}> NO DATA AVAILABLE FOR THE SELECTED RANGE</div>
                                                        :
                                                        <BrandDoughnutChart data={earningSectionDataNfts.data} hasLegend={true} width='500px' height='500px' />
                                                }
                                            </div>
                                            <hr className={styles.vertical} />
                                            <div className={styles.doughnutChartContainer}>
                                                <div className={styles.calculations}>
                                                    <div>
                                                        <div className={styles.calculationsTitles}>Top 5 Earning Collections</div>
                                                        <div className={styles.calculationsData}>
                                                            {switchCurrency ?
                                                                earningSectionDataCollections.topFourTotalEarningsSum ? `$${formatMoney(earningSectionDataCollections.topFourTotalEarningsSum.usdValue)}` : 0
                                                                :
                                                                <>
                                                                    < EthIcon />
                                                                    {
                                                                        earningSectionDataCollections.topFourTotalEarningsSum &&
                                                                            earningSectionDataCollections.topFourTotalEarningsSum.ethValue ?
                                                                            formatMoney(earningSectionDataCollections.topFourTotalEarningsSum.ethValue)
                                                                            :
                                                                            0
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={styles.calculationsTitles}>All Collections</div>
                                                        <div className={styles.calculationsData}>
                                                            < EthIcon />
                                                            {earningSectionDataCollections.allCollections ? formatMoney(earningSectionDataCollections.allCollections) : 0}
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    earningSectionDataCollections.data === undefined || earningSectionDataCollections.data.length === 0 ?
                                                        <div className={styles.noDataAvailableField}> NO DATA AVAILABLE FOR THE SELECTED RANGE</div>
                                                        :
                                                        <BrandDoughnutChart data={earningSectionDataCollections.data} hasLegend={true} width='500px' height='500px' />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.totalEarningsSection}>
                                        <div className={styles.totalEarningsHeader} >
                                            <Typography
                                                color='primary'
                                                component='div'
                                                variant='h5'
                                            >
                                                Total Earnings
                                            </Typography>
                                            <div className={styles.switchContainer}>
                                                <div>Per Month</div>
                                                <Switch
                                                    checked={switchState}
                                                    onChange={switchHandleChange}
                                                    color="primary"
                                                    name="checkedB"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                <div>Per Year</div>
                                            </div>
                                        </div>
                                        <div className={styles.chartContainer}>
                                            <div className={styles.titlesContainer}>
                                                {
                                                    switchState ?
                                                        <>
                                                            <div>
                                                                <div className={styles.totalEarningsTitles}>
                                                                    Average Earning per Year
                                                                </div>
                                                                <div className={styles.salesData}>
                                                                    < EthIcon />
                                                                    {averageAndHighestEarning?.averageEarningPerYear?.ethValue ? formatMoney(averageAndHighestEarning.averageEarningPerYear.ethValue) : 0}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className={styles.totalEarningsTitles}>
                                                                    Highest Earning Year
                                                                </div>
                                                                <div className={styles.salesData}>
                                                                    < EthIcon />
                                                                    {averageAndHighestEarning?.yearHighestIncome?.ethValue ? formatMoney(averageAndHighestEarning.yearHighestIncome.ethValue) : 0}
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div>
                                                                <div className={styles.totalEarningsTitles}>
                                                                    Average Earning per Month
                                                                </div>
                                                                <div className={styles.salesData}>
                                                                    < EthIcon />
                                                                    {averageAndHighestEarning ? formatMoney(averageAndHighestEarning.averageEarningPerMonth.ethValue) : 0}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className={styles.totalEarningsTitles}>
                                                                    Highest Earning Month
                                                                </div>
                                                                <div className={styles.salesData}>
                                                                    < EthIcon />
                                                                    {averageAndHighestEarning ? formatMoney(averageAndHighestEarning.highestEarningMonth[0].ethValue) : 0}
                                                                </div>
                                                            </div>
                                                        </>

                                                }
                                                <div>
                                                    <div>
                                                        <div className={styles.totalEarningsTitles}>Highest Earning NFT </div>
                                                        <div className={styles.totalEarningsTitles}>
                                                            {
                                                                averageAndHighestEarning !== null ?
                                                                    averageAndHighestEarning.highestEarningNft.nft.title
                                                                    :
                                                                    ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={styles.salesData}>
                                                        < EthIcon />
                                                        {
                                                            averageAndHighestEarning !== null ?
                                                                formatMoney(averageAndHighestEarning.highestEarningNft.highestEarning.ethValue)
                                                                :
                                                                0
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <hr className={styles.vertical} />
                                            <div className={styles.chart}>
                                                {switchState
                                                    ?
                                                    <BrandVerticalBarsChart
                                                        // customFormat={(value) => `${selectedCryptocurrency ? selectedCryptocurrency === 'ETHEREUM' ? 'ETH' : selectedCryptocurrency : 'ETH'} ${roundToSecondDecimal(value)}`}
                                                        by='year'
                                                        data={yearChartData}
                                                        tooltipLabel='Earnings'
                                                        color='#16AE90'
                                                        width='840px'
                                                        height='300px'
                                                        currency={'eth'}
                                                    />
                                                    :
                                                    <BrandVerticalBarsChart
                                                        // customFormat={(value) => `${selectedCryptocurrency ? selectedCryptocurrency === 'ETHEREUM' ? 'ETH' : selectedCryptocurrency : 'ETH'} ${roundToSecondDecimal(value)}`}
                                                        by='month'
                                                        data={monthChartData}
                                                        tooltipLabel='Earnings'
                                                        color='#16AE90'
                                                        width='840px'
                                                        height='300px'
                                                        currency={'eth'}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                    </div >
                    :
                    <div className={styles.isHaveAttachedAcountholderMessage}>
                        Тhe user does not have an account holder attached
                    </div>
            }
        </div>
    );
}