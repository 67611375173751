import { Box, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        borderRadius: '5px',
        backgroundColor: theme.palette.primary.main,
        width: '120px',
        height: '25px',
        position: 'relative'
    },
    movingBox: {
        width: '50%',
        height: '25px',
        borderRadius: '5px',
        transition: 'all 0.5s ease',
        backgroundColor: '#1c1c1c',
        position: 'absolute',
        left: '0px',
        top: '0px',
        outline: '1px solid gray'
    },
    button: {
        borderRadius: '5px',
        fontSize: '12px',
        transition: 'all 0.2s 0.1s ease',
        textTransform: 'none',
        '&.MuiButton-root': {
            minWidth: '50%',
            minHeight: '100%',
            color: 'black'
        }
    },
    grayButton: {
        '&.MuiButton-root': {
            color: '#707070'
        }
    }
}));

const Switch = ({ show, setShow }) => {
    const styles = useStyles();

    const handleClick = () => setShow(!show);

    return (
        <Box onClick={handleClick} className={styles.wrapper}>
            <Box 
                className={styles.movingBox} 
                style={{ transform: `translateX(${show ? 0 : '100%'})` }} 
            />
            <Button 
                disableRipple 
                variant='text' 
                className={`${styles.button} ${show ? styles.grayButton : ''}`} 
            >
                Show
            </Button>
            <Button 
                disableRipple 
                variant='text' 
                className={`${styles.button} ${!show ? styles.grayButton : ''}`} 
            >
                Hide
            </Button>
        </Box>
    )
};

export default Switch;