import React, { useState, useEffect } from 'react';
import { useStoreContext } from '../../store/Store.js';
import { BrandButton, TempBrandButton } from '../CoreComponents/BrandButton'
import { BrandInput, BrandPasswordInput } from '../CoreComponents/BrandInput';
import { BrandCheckbox } from '../CoreComponents/BrandCheckbox';
import { Slide, Typography } from '@material-ui/core';
import { BrandLoaderDots } from '../CoreComponents/BrandLoader';
import { BrandLink } from '../CoreComponents/BrandLink';
import { postData } from '../utils/FetchUtils';
import { useHistory } from 'react-router-dom';
import { BrandAlert } from '../CoreComponents/BrandAlert'
import makeStyles from '@material-ui/core/styles/makeStyles';
require('dotenv').config();


const useStyles = makeStyles((theme) => ({
    submitBtn: {
        width: '100%',
    },
    input: {
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        marginTop: 25,
        "& .MuiInputBase-root": {
            fontFamily: 'ITCAvantGardeGothicStd-Normal',
            width: '350px',
            height: '48px',
            height: theme.spacing(6),
            borderRadius: 28,
            '& input::placeholder': {
                fontSize: '16px',
                color: theme.palette.text.darkNuance,
                letterSpacing: '0.15px',
                fontFamily: 'ITCAvantGardeGothicStd-Normal',
            }

        },
        "& label": {
            fontFamily: 'ITCAvantGardeGothicStd-Normal',
            fontSize: '18px',
        }
    },
    title: {
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        padding: 15,
        fontSize: '24px',
        letterSpacing: '0px',
        fontWeight: 'bold',
    },
    link: {
        "& .MuiTypography-root": {
            fontSize: '15px',
            letterSpacing: '0px',
            color: theme.palette.text.greenNuance
        }
    },
    keepSignedIn: {
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        "& .MuiTypography-root": {
            fontFamily: 'ITCAvantGardeGothicStd-Normal',
            fontSize: '15px',
            letterSpacing: '0px',
            paddingTop: '2px',
        },
        "& .MuiIconButton-label": {
            marginBottom: '5px'
        }
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
    },
    loginContainer: {
        width: '410px',
        height: '484px',
        padding: '40px 0px 40px 0px',
        borderRadius: 13,
        background: theme.palette.background.darkNuanceVersion3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            background: theme.palette.background.dark,
        }
    },
    alert: {
        minHeight: theme.spacing(5),
        marginTop: theme.spacing(1),
        maxWidth: '350px',
    }
}));


export default function LoginPage() {
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useStoreContext();
    const history = useHistory();
    const email = useState('');
    const password = useState('');
    const keepSignedIn = useState(false);
    const [alert, setAlert] = useState('');
    const [checked, setChecked] = useState(false);

    const proxyText = 'Proxy';
    const proxyAlertMessage = 'There is a server error on login.';

    useEffect(() => {
        setChecked(true);
    }, []);

    useEffect(() => {
        setState(state => (state.page = 'login', { ...state }));
        // document.getElementsByClassName('MuiButtonBase-root')[2].click();
    }, []);

    useEffect(() => {
        setAlert('');
    }, [email[0], password[0]]);

    async function handleLoginFormSubmission(e) {
        e.preventDefault();
        try {
            setLoading(true);
            const data = await postData(process.env.REACT_APP_SERVER_HOST + '/api/user/login', { email: email[0], password: password[0] });
            if (!data) {
                throw Error('An error occurred on login. Check if the email and password provided are valid.');
            }
            setState(state => (state.user = data, { ...state }));
            localStorage.setItem("keepSignedIn", keepSignedIn[0]);
            history.push('/');
            setState(state => (state.page = undefined, { ...state }));
            setLoading(false);
        } catch (err) {
            const proxyErrorChecking = err.message.split(' ')[0] === proxyText;
            proxyErrorChecking ? setAlert(proxyAlertMessage) : setAlert(err.message);
            setLoading(false);
        }
    }

    return (
        <Slide
            timeout={500}
            direction="left"
            in={checked}
            mountOnEnter
            unmountOnExit
        >
            <div className={styles.loginContainer}>
                <Typography className={styles.title} variant='h5' component='div' gutterBottom>
                    Log Into My Account
                </Typography>
                <form onSubmit={handleLoginFormSubmission}>
                    <div className={styles.input}>
                        <BrandInput $value={email} placeholder='Enter email here' label='Email' />
                    </div>
                    <div className={styles.input}>
                        <BrandPasswordInput $value={password} placeholder='Enter password here' label='Password' />
                    </div>
                    <div className={styles.actions}>
                        <BrandCheckbox className={styles.keepSignedIn} $value={keepSignedIn} label='Keep me signed in' />
                        <div className={styles.link}>
                            <BrandLink to='/forgotten-password'>Forgot your password?</BrandLink>
                        </div>
                    </div>
                    <BrandAlert className={styles.alert}>{alert}</BrandAlert>
                    <TempBrandButton className={styles.submitBtn} type='submit' >
                        {loading ?
                            <BrandLoaderDots />
                            :
                            'Log In'}
                    </TempBrandButton>
                </form>
            </div>
        </Slide>
    );
}