import React, { useState, useEffect, } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useStoreContext } from '../../store/Store';
import SongsDashboard from './SongsDashboard';
import NftDashboard from './NftDashboard';

const useStyles = makeStyles((theme) => ({

}));

export default function Dashboard() {
    const styles = useStyles();
    const [state] = useStoreContext();

    return (
        <div>
            {
                state.navType === 'Music'
                    ?
                    <SongsDashboard />
                    :
                    <NftDashboard />
            }
        </div>
    );
}