import React, { useState } from 'react';
import {
    Typography,
    Popover
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1)
    }
}));

export default function AccountHoldersPopover({
    accountHolders
}) {
    const styles = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    if (!accountHolders || accountHolders.length === 0)
        return 'N/A';

    if (accountHolders.length === 1)
        return accountHolders[0];

    return (
        <div>
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {`${accountHolders[0]}; +${accountHolders.length - 1}`}
            </div>
            <Popover
                id="mouse-over-popover"
                className={styles.popover}
                classes={{
                    paper: styles.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {
                    accountHolders.map(x => {
                        return (
                            <Typography key={`${x}${Math.random()}`} color='primary'>{x}</Typography>
                        )
                    })
                }
            </Popover>
        </div>
    )
}