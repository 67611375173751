import { Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AlbumIcon from '@material-ui/icons/Album';
import { useEffect, useState } from 'react';
import clsx from 'clsx'


const useNftStyles = makeStyles((theme) => ({
    nftDataContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: theme.spacing(1.25),
        cursor: 'pointer'
    },
    verifyAndCoverArt: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    img: {
        width: theme.spacing(23),
        height: theme.spacing(23),
        borderRadius: theme.spacing(1)
    },
    dataCols: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '25px'
    },
    dataCol: {
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(38),
        justifyContent: 'space-between',
    },
    dataRow: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },
    dataRowData: {
        marginBottom: theme.spacing(1),
        font: 'normal normal bold 16px/20px Roboto',
        letterSpacing: '1.6px',
    },
    dataRowLabel: {
        color: '#A5A5A5',
        font: 'normal normal normal 12px/15px Roboto',
        letterSpacing: '1.2px',
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    salesRows: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
        flexWrap: 'wrap'
    },
    rightRow: {
        fontSize: '25px',
        fontWeight: 'bold',
    },
    walletAddress: {
        fontSize: '11px'
    },
    leftCol: {
        width: '324px'
    },
    rightCol: {
        width: '250px'
    },
}));

function CollectionItem({ details, accountHolderName }) {
    const styles = useNftStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <div className={styles.nftDataContainer}>
            <div className={styles.verifyAndCoverArt}>
                {details.coverArt ?
                    <img src={details.coverArt} alt={''} className={styles.img} />
                    :
                    <AlbumIcon className={styles.img} />
                }
            </div>

            <div className={styles.dataCols}>
                <div className={clsx(styles.dataCol, styles.leftCol)}>
                    <div className={styles.dataRow}>
                        <div className={styles.dataRowData}>{details.collectionName ? details.collectionName : 'N/A' }</div>
                        <div className={styles.dataRowLabel}>Collection</div>
                    </div>
                    <div className={styles.dataRow}>
                        <div className={clsx(styles.dataRowData, styles.walletAddress)}>{details.contractAddresses[0]}</div>
                        <div className={styles.dataRowLabel}>Contract addresses</div>
                    </div>
                    <div className={styles.dataRow}>
                    <div>
         
                        <div 
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        className={styles.dataRowData}>{accountHolderName ? accountHolderName : details.accountHolders[0]?.name}{details.accountHolders.length > 1 ? `, +${details.accountHolders.length -1}` : ''}</div>
                        {details.accountHolders.length > 1 ? 
                        <Popover
                            id="mouse-over-popover"
                            className={styles.popover}
                            classes={{
                                paper: styles.paper,
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            {
                                details.accountHolders?.map(x => {
                                    return (
                                        <Typography key={x.id} color='primary'>{x.name}</Typography>
                                    )
                                })
                            }
                        </Popover>
                        : ''
                        }
                    </div>
                        <div className={styles.dataRowLabel}>Account holder</div>
                    </div>

                </div>
                <div className={clsx(styles.dataCol, styles.rightCol)}>
                    <div className={styles.salesRows}>
                        <div>
                            <div className={styles.rightRow}>{details.mintSalesEth ? details.mintSalesEth : 0}</div>
                            <div className={styles.dataRowLabel}>Mint Sales ETH</div>
                        </div>
                        <div>
                            <div className={styles.rightRow}>{details.secondarySalesEth ? details.secondarySalesEth : 0}</div>
                            <div className={styles.dataRowLabel}>Secondary Sales ETH</div>
                        </div>
                    </div>
                    <div className={styles.dataRow}>
                        <div className={styles.rightRow}>{details.totalTransactions ? details.totalTransactions : 0}</div>
                        <div className={styles.dataRowLabel}>Total transactions</div>
                    </div>
                    <div className={styles.dataRow}>
                        <div className={styles.rightRow}>{details.totalNFTsInCollection ? details.totalNFTsInCollection : 0}</div>
                        <div className={styles.dataRowLabel}>Total NFTs in Collection</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CollectionItem;