import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getData } from "../../utils/FetchUtils";

const useNftStyles = makeStyles((theme) => ({
    container: {
        width: '450px'
    },
    periodOverview: {
        fontSize: '16px',
        '& div': {
            display: 'flex',
            justifyContent: 'space-between'
        },
        '& span': {
            fontWeight: 'bold',
        }
    },
    periodTransactions: {
        display: 'flex',
        flexDirection: 'column',
        height: '160px',
        overflow: 'scroll',
        '& h3': {
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.background.default,
            lineHeight: '3'
        }
    },
    transactionsContainer: {
        display: 'flex',
        gap: '32px',
        fontSize: '16px',
        justifyContent: 'space-between'
    },
    percentContainer: {
        margin: '32px 0px 0px 0px'
    },
    percent: {
        color: theme.palette.primary.main,
    }
}));

function gainsAndLossesPercent(currentPrice, originalPurchasePrice) {
    const percent = (currentPrice - originalPurchasePrice) / originalPurchasePrice

    return percent
}

function GainsAndLossesTransactions({ nftId, selectedCryptocurrency }) {
    const styles = useNftStyles();
    const [data, setData] = useState(null);

    const ethCurrentPrice = 2000
    const totalHoldingEth = data?.reduce((a, b) => a + b.ethValue, 0).toFixed(2)

    // const averagePercent = (data
    //     .map(x => {
    //         const avgP = gainsAndLossesPercent(x.currentUsdValue, x.originalUsdValue)
    //         x.avgP = avgP
    //         return x
    //     })
    //     .reduce(((a, b) => a + b.avgP, 0) / test.length)) / 100

    useEffect(() => {

        if (nftId) {
            getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-all-transactions-by-currency?nftId=${nftId}${selectedCryptocurrency ? `&cryptocurrency=${selectedCryptocurrency}` : ''}`)
                .then(res => {
                    const dataWithcalculatedEthValue = res.items.map(transaction => {
                        const ethValue = JSON.parse(transaction.transactionTransfers).map((x, index) => {
                            if (selectedCryptocurrency === 'WETH') {
                                if (x.transactionChain === selectedCryptocurrency) {
                                    return Number(x.ethValue)
                                }
                                return 0
                            } else if (selectedCryptocurrency === 'ETHEREUM') {
                                if (x.transactionChain === selectedCryptocurrency && x.ethValue !== undefined) {
                                    return Number(x.ethValue)
                                }
                                return 0
                            } else if (selectedCryptocurrency === 'USDC') {
                                if (index === 0) {
                                    return Number(x.ethValue)
                                }
                                return 0
                            } else if (selectedCryptocurrency === '') {
                                if (x.ethValue !== undefined) {
                                    return Number(x.ethValue)
                                }
                                return 0
                            }
                        }).reduce((a, b) => a + b, 0)
                        transaction.ethValue = ethValue
                        return transaction
                    })
                    setData(dataWithcalculatedEthValue)
                })
        }
    }, [selectedCryptocurrency])

    return (
        <div className={styles.container}>
            <h3>Overview For The Period</h3>
            <div className={styles.periodOverview}>
                <div>Total Holding:<span>ETH {totalHoldingEth}</span> </div>
                <div>Current ETH Price: <span>USD {ethCurrentPrice}</span> </div>
                <div className={styles.percentContainer}>Gains And Losses: <span className={styles.percent}>+12%</span></div>
            </div>
            <div className={styles.periodTransactions}>
                <h3>Transactions For The Period</h3>
                {data?.map(x => (
                    <div className={styles.transactionsContainer}>
                        <div style={{ width: 170 }}>
                            {x.hash.slice(0, 11) + '...' + x.hash.slice(-5)}
                        </div>
                        <div style={{ width: 125 }}>
                            {console.log()}
                            ETH {x.ethValue.toFixed(4)}
                        </div>
                        <div style={{ width: 90 }}>
                            $ {x.usdValue === null ? 0 : x.usdValue}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default GainsAndLossesTransactions;