/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, TablePagination, Typography } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { BrandButton } from "../CoreComponents/BrandButton";
import { BrandModal } from "../CoreComponents/BrandModal";
import { debounce, getData, postData } from "../utils/FetchUtils";
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import CollectionItem from './CollectionItem'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { BrandLoader } from "../CoreComponents/BrandLoader";
import TablePaginationActions from '../CoreComponents/TablePaginationActions';

import { NavLink, useHistory } from 'react-router-dom';

const useNftCatalogStyles = makeStyles(theme => ({
    container: {
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: 'white'
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: '7px 16px'
        },
        '& .MuiTableCell-head': {
            padding: '16px'
        }
    },
    sectionHeader: {
        fontWeight: 'bold',
        fontSize: 25
    },
    input: {
        width: theme.spacing(55.625),
    },
    uploadNftButton: {
        color: '#222222',
        width: 200,
    },
    searchBarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 30px 20px 0px'
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    nftTitle: {
        marginLeft: '50px',
        fontSize: 25,
        fontWeight: 'bold'
    },
    tabPanel: {
        paddingTop: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    bar: {
        width: '1350px',
        height: '779px',
        '& .MuiTabs-flexContainer': {
            width: '45%',
            justifyContent: 'space-evenly'
        },
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: '#1B1B1B',
        },
        '& .MuiPaper-elevation4': {
            boxShadow: 'none !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#62E162'
        },
        '& .MuiBox-root': {
            padding: 0
        },
        "& .MuiButtonBase-root": {
            opacity: 1,
            font: 'normal normal bold 16px/20px Roboto',
            letterSpacing: '1.6px'
        },
        "& .Mui-selected": {
            color: '#62E162',
        },
    },
    nftContainer: {
        marginLeft: 20,
    },
    singleNftContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    singleNftInputSection: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    singleNftInputContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    singleNftInputHeader: {
        marginLeft: 15,
        marginBottom: 5
    },
    showNftButtonContainer: {

    },
    showButton: {
        height: '42px',
        color: '#222222',
        marginLeft: 15,
        textAlign: 'center'
    },
    nftUrlInput: {
        height: '42px',
        width: theme.spacing(70),
    },
    headerDetailsSection: {
        marginLeft: 20,
        font: 'normal normal bold 16px/20px Roboto',
        letterSpacing: '1.6px',
    },
    nftDataContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        padding: theme.spacing(2),
    },
    verifyAndCoverArt: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    img: {
        width: theme.spacing(23),
        height: theme.spacing(23)
    },
    dataCols: {
        width: '100%',
        height: '184px',
        display: 'flex',
        justifyContent: 'space-around',
    },
    dataCol: {
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(38),
        justifyContent: 'space-between',
    },
    dataRow: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },
    dataRowData: {
        marginBottom: theme.spacing(1),
        font: 'normal normal bold 16px/20px Roboto',
        letterSpacing: '1.6px',
    },
    dataRowLabel: {
        color: '#A5A5A5',
        font: 'normal normal normal 12px/15px Roboto',
        letterSpacing: '1.2px',
    },
    accHolderActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
    },
    uploadModalButton: {
        color: '#222222'
    },
    tableSection: {
        marginTop: theme.spacing(2),
        '& .MuiTableContainer-root': {
            height: theme.spacing(66)
        }
    },
    tableHeaders: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    uploading: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center'
    },
    uploadAlert: {
        textAlign: 'right',
        marginTop: theme.spacing(1)
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControlLabel-root': {
            margin: 0,
            padding: 0
        },
    },
    checkboxContainer: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: '1px  #3C3C3C',
            }
        },
        '& .MuiInputBase-root': {
            height: theme.spacing(5),
            border: '1px solid #3C3C3C',
            borderRadius: theme.spacing(3),
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3C3C3C',
            border: 'none'
        },
        '& .MuiFormLabel-root': {
            top: '-6px'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'white'
        },
        '& .MuiInputBase-input': {
            position: 'relative',
            bottom: theme.spacing(1),
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: 'white'
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: 'white',
            "& span": {
                "& svg": {
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')" // your svg icon path here
                    }
                }
            }
        },
    },
    searchAndCheckboxContainer: {
        display: 'flex',
        justifyContent: 'start',
        gap: theme.spacing(2)
    },
    circleIconContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            fontSize: '4rem',
            color: '#62E162',
            marginBottom: theme.spacing(2)
        },
        '& p': {
            margin: 0,
            fontSize: 11
        },
        '& h4': {
            margin: 0,
        },
        marginTop: theme.spacing(5)
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    collections: {
        width: '60%'
    },
    collectionsContainer: {
        display: 'flex',
        minHeight: '60vh'
    },
    accHolderName: {
        fontSize: '18px',
        fontWeight: 'bold',
        paddingLeft: '12px',
        height: '30px'
    },
    deleteButton: {
        color: '#CCC !important',
        fontSize: '14px',
        fontWeight: 'bold',
        backgroundColor: '#1B1B1B',
        boxShadow: 'none',
        textTransform: 'none'
    },
    collectionsSearch: {
        marginBottom: '45px',

    },
    tableHeadersCollections: {
        display: 'flex',
        width: '400px',
        fontSize: '13px',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '10px',
        borderBottom: ' 1px solid #707070',
        fontWeight: 'bold'
    },
    tableBottomCollections: {
        display: 'flex',
        width: '400px',
        fontSize: '13px',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '10px',
        borderTop: ' 1px solid #707070',
        paddingTop: '10px'
    },
    collectionTotalOwnersTitle: {
        width: '65%'
    },
    collectionTotalOwners: {
        width: '37%',
        paddingLeft: '2px'

    },
    collectionsInfo: {
        display: 'flex',
        width: '400px',
        fontSize: '13px',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '5px',
        paddingTop: '5px'
    },
    collectionCol: {
        width: '60%',
        display: 'flex',
        padding: '0px 11px 0px 0px',
        alignItems: 'center',
        wordBreak: 'break-all'
    },
    collectionPercent: {
        width: '5%',
        marginRight:'5px'
    },
    collectionOwners: {
        width: '15%',
        textAlign: 'center'
    },
    collectionDelete: {
        width: '30%'
    },
    ratio: {
        height: '25px',
        borderRadius: '5px',
        width: '40px',
        marginTop: '10px',
        marginLeft: '3px'
    },
    collectionLine: {
        display: 'flex',
        alignItems: 'center'
    },
    collectionsLoader: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '100px'
    
    },
    collectionLink : {
        textDecoration: 'none', 
        color: 'inherit'
    },
    paginationContainerHide: {
        display:'none'
    },
    optionAccountHolder: {
        width:'100%'
    },
    selectCollections: {
        display:'flex',
        alignItems:'center',
        gap:'15px'

    }
}));



const NftAnalyze = () => {
    const styles = useNftCatalogStyles()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const [rowCounts, setRowCounts] = useState(0);
    const [rowsPerPageOptions] = useState([1, 3, 4]);
    // const [searchFilter, setFilterSearch] = useState('');
    const [displayUploadNftsModal, setDisplayUploadNftsModal] = useState(false);
    const [accountHolder, setAccountHolder] = useState(null);
    const [accountHolders, setAccountHolders] = useState([]);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [collectionsForSelect, setCollectionsForSelect] = useState([]);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [collections, setCollections] = useState([]);
    const [collectionsStatistic, setCollectionsStatistic] = useState([]);
    const [totalOwners, setTotalOwners] = useState([]);
    const [loadingCollections,setLoadingCollections] = useState(false);
    const [offset, setOffset] = useState(0);
    const [loadingOwners,setLoadingOwners] = useState(false);

    const maxSelections = 3;

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/my`)
            .then(data => setAccountHolders(data));
        getCollectionsForSelect(null);
        getCollections(null);
        setCollectionsStatistic([]);
    }, [])

    function changeAccountHolder(accHolder) {
        setAccountHolder(accHolder);
        if (accHolder) {
            getCollections(accHolder);
            getCollectionsForSelect(accHolder);
        } else {
            getCollections(null);
            setCollectionsStatistic([]);
        }
        setCollectionsStatistic([]);
        setSelectedCollections([]);
    }

    useEffect(() => {
        setOffset(page * rowsPerPage)
    }, [page,rowsPerPage]);

    useEffect(() => {
        setPage(0)
    }, [rowsPerPage]);

    useEffect(() => {
        calculateOwners();
    }, [collectionsStatistic]);

    useEffect(() =>{
        getCollections(accountHolder)
    },[offset,rowsPerPage])

    function calculateOwners(){
        let totalOwners = 0;
        collectionsStatistic.forEach(coll => {
            if (!Array.isArray(coll)) {
                totalOwners = totalOwners + coll.numberOfOwners;
            }
        })
        setTotalOwners(totalOwners);
    }

    function onClose() {
        setDisplayUploadNftsModal(false)
    }

    async function getCollections(accHolder) {
        setLoadingCollections(true);
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft-analyze/get-nft-collections?${(accHolder && accHolder.id) ? 'accountHolderId=' + accHolder.id : ''}&offset=${offset}&limit=${rowsPerPage}`)
            .then(data => {
                setCollections(data.collections);
                setRowCounts(data.total)
            })
            .catch((error) => console.error(error))
            .finally(()=>{
                setLoadingCollections(false);
            })
    }

    async function getCollectionsForSelect(accHolder) {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft-analyze/get-nft-collections-for-select${(accHolder && accHolder.id) ? '?accountHolderId=' + accHolder.id : ''}`)
            .then(data => setCollectionsForSelect(data.collections));
    }

    async function getOwnersOfCollection(collectionName){
        setLoadingOwners(true);
        // const contractAddresses = await JSON.stringify(contractAddressesJSON);
        // const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/nft-analyze/get-number-of-owners-by-contract-addresses?contractAddresses=${contractAddresses}`);
        const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/nft-analyze/get-number-of-owners-by-collection-name?collectionName=${collectionName}`);
        setLoadingOwners(false);
        return data.numberOfOwners
    }


    async function selectCollection(collections) {
        let removeCollection = false;
        setSelectedCollections(prev => {
            if (prev.length - 1 === collections.length) {
                removeCollection = true;
            }
            return collections;
        });

        const collectionsStatisticToAdd = [];
        if (!removeCollection) {
            const accHolder = {...accountHolder};
            for (const collection of collections) {
                const collectionAlreadyAdded = collectionsStatistic.filter(coll => coll.collectionName === collection.collectionName);
                if(collectionsStatistic.length === 0){
                    const numberOfOwners = await getOwnersOfCollection(collection.collectionName)
                    collectionsStatisticToAdd.push({ ...collection, numberOfOwners: numberOfOwners });
                }else if(!collectionAlreadyAdded.length ){
                    const numberOfOwners = await getOwnersOfCollection(collection.collectionName)
                    collectionsStatisticToAdd.push({ ...collection, numberOfOwners: numberOfOwners });
                }else if (collectionAlreadyAdded.length){
                    collectionsStatisticToAdd.push({ ...collection,numberOfOwners:collectionAlreadyAdded[0].numberOfOwners});
                }

            }
            setAccountHolder(prev =>{
                if(prev?.name === accHolder.name){
                    addCollectionStatistics(collectionsStatisticToAdd);
                }
                return prev;
            })
        } else if (removeCollection) {
            addCollectionStatistics(prev => {
                const allCollectionNames = collections.map(coll => coll.collectionName)

                const collectionStatisticsWithoutDeletedItem = prev.reduce((acc, item) => {

                    if (Array.isArray(item)) {
                        let existRemovedCollection = false;
                        item.forEach(childColl => {
                            if (!allCollectionNames.includes(childColl.collectionName)) {
                                existRemovedCollection = true;
                            }
                        })
                        if (!existRemovedCollection) {
                            acc.push(item);
                        }
                    } else if (allCollectionNames.includes(item.collectionName)) {
                        acc.push(item);
                    }
                    return acc;
                }, [])
                return collectionStatisticsWithoutDeletedItem
            })
        }
    }

    function addCollectionStatistics(collectionsStatistic) {
        if (collectionsStatistic.length === 1) {
            setCollectionsStatistic(collectionsStatistic);
        } else if (collectionsStatistic.length === 2) {
            setCollectionsStatistic([...collectionsStatistic, [collectionsStatistic[0], collectionsStatistic[1]]]);
        } else if (collectionsStatistic.length === 3) {
            setCollectionsStatistic([...collectionsStatistic, [collectionsStatistic[0], collectionsStatistic[1]], [collectionsStatistic[0], collectionsStatistic[2]], [collectionsStatistic[2], collectionsStatistic[1]], [collectionsStatistic[0], collectionsStatistic[1], collectionsStatistic[2]]]);
        } else if (collectionsStatistic.length === 0) {
            setCollectionsStatistic([]);
        }
    }

    function removeCollectionFromSelected({ item, indexOfItem }) {
        if (!Array.isArray(item)) {
            setCollectionsStatistic(prev => {
                const collections = [];
                prev.forEach((coll, i) => {
                    if (indexOfItem === i) {
                        coll.hide = true;
                        collections.push(coll);
                    } else {
                        collections.push(coll);
                    }
                })
                return collections;
            })
        } else {
            setCollectionsStatistic(prev => {
                const collections = [];
                prev.forEach((coll, i) => {
                    if (indexOfItem != i) {
                        collections.push(coll);
                    }
                })
                return collections;
            })

        }
    }

    function unSelectByClick(accHolder){
        if(accHolder?.id === accountHolder?.id){
            setAccountHolder(null);
        }
    }

    return (
        <div className={styles.container} >
            <BrandModal
                open={displayUploadNftsModal}
                onClose={onClose}
            >

            </BrandModal>
            <Typography
                variant="h6"
                className={styles.sectionHeader}
            >
                Blockchain Analyze
            </Typography>
            <div className={styles.searchBarContainer}>
                <div className={styles.searchAndCheckboxContainer}>
                    <div className={styles.autocompleteContainer}>
                        {/* <SearchIcon style={{ color: '#707070' }} /> */}
                        <Autocomplete
                            value={accountHolder}
                            onChange={(event, value) => changeAccountHolder(value)}
                            className={styles.checkboxContainer}
                            id="checkboxes-tags-demo"
                            options={accountHolders}
                            getOptionLabel={(option) => option.name}
                            renderTags={() => null}
                            disableCloseOnSelect
                            renderOption={(option, { selected }) =>
                                <div className={styles.optionAccountHolder} onClick={() => unSelectByClick(option)}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        color={"primary"}
                                        checked={selected}
                                    />
                                    {option.name}
                                </div>
                            }
                            style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" placeholder="Account Holder" ></TextField>
                            )}
                        />
                    </div>

                </div>
            </div>
            <div className={styles.collectionsContainer}>
                <div className={styles.collections}>
                                
                    {loadingCollections ? 
                    <div className={styles.collectionsLoader}><BrandLoader color='white' width={50} height={50} /></div> 
                    : collections.map((collection, i) => {
                            return (<NavLink to={`/nft-analyze/${encodeURIComponent(collection.collectionName)}`} className={styles.collectionLink}><CollectionItem key={collection.collectionName} details={collection} accountHolderName={accountHolder ? accountHolder.name : ''} /></NavLink>)
                        })
                    }
                </div>
                <div className={styles.collectionStatistics}>
                    <div className={styles.accHolderName}>{accountHolder && accountHolder.name ? accountHolder.name : ''}</div>
                    <div>{collections.map(c => {
                        <p>{c.collectionName}</p>
                    })}</div>
                    <div className={clsx(styles.collectionsSearch, styles.searchAndCheckboxContainer)}>
                        <div className={styles.selectCollections}>
                            {/* <SearchIcon style={{ color: '#707070' }} /> */}
                            <Autocomplete
                                onChange={(event, value) => selectCollection(value)}
                                className={styles.checkboxContainer}
                                multiple
                                limitTags={3}
                                id="checkboxes-tags-demo"
                                options={collectionsForSelect}
                                getOptionDisabled={(option) => (loadingOwners || (selectedCollections.includes(option) ? false : false || selectedCollections.length === maxSelections) )}
                                value={selectedCollections}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.collectionName}
                                renderTags={() => null}
                                renderOption={(option, { selected }) =>
                                    <div style={{ display: 'flex', maxWidth: '260px' }} >
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            color={"primary"}
                                            checked={selected}
                                        />
                                        <p style={{ wordWrap: 'break-word', maxWidth: '170px' }}>
                                            {option.collectionName }</p>
                                    </div>
                                }
                                style={{ width: 260 }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" placeholder="Search for Collection" ></TextField>
                                )}
                            />
                            {loadingOwners ? <BrandLoader color='white' width={25} height={25} />:'' }
                        </div>

                    </div>
                    {
                        collectionsStatistic.length ?
                            <div className={styles.collectionsInfoTable}>
                                <div className={styles.tableHeadersCollections}>
                                    <div className={styles.collectionCol}>Collections</div>
                                    <div className={styles.collectionPercent}>%</div>
                                    <div className={styles.collectionOwners}>Owners</div>
                                    <div className={styles.collectionDelete}></div>
                                </div>
                                {
                                    collectionsStatistic.map((item, index) => {
                                        let color = 'white';

                                        if (Array.isArray(item) && item.length > 1) {
                                            item.id = item[0].id + item[1].id;
                                            let colors = '';

                                            let numberOfOwners = 0;
                                            item.forEach(coll => {
                                                numberOfOwners = numberOfOwners + coll.numberOfOwners;
                                            })

                                            return (
                                                <div className={styles.collectionLine} key={item[0].collectionName + index}>
                                                    <div className={styles.collectionsInfo} key={item.collectionName}>
                                                        <div className={styles.collectionCol} >
                                                            {
                                                                item.map((coll, i) => {
                                                                    if (coll.collectionName == collectionsStatistic[0].collectionName) {
                                                                        color = '#B50391';
                                                                        colors = colors ? colors + ',#B50391' : '#B50391';
                                                                    } else if (coll.collectionName == collectionsStatistic[1].collectionName) {
                                                                        color = '#EDE107';
                                                                        colors = colors ? colors + ',#EDE107' : '#EDE107';
                                                                    } else if (coll.collectionName == collectionsStatistic[2].collectionName) {
                                                                        color = '#62E162';
                                                                        colors = colors ? colors + ',#62E162' : '#62E162';
                                                                    }
                                                                    return <p key={coll.collectionName} style={{ color: color }}>{coll.collectionName}{item.length - 1 != i ? ',' : ''}</p>
                                                                })
                                                            }
                                                        </div>
                                                        <div className={styles.collectionPercent}>{
                                                            numberOfOwners && numberOfOwners ? Math.round(numberOfOwners / totalOwners * 100) : 0
                                                        }</div>
                                                        <div className={styles.collectionOwners}>
                                                            {
                                                                numberOfOwners
                                                            }
                                                        </div>
                                                        <div className={styles.collectionDelete}>
                                                            {/* <BrandButton
                                                                startIcon={<DeleteOutlineIcon />}
                                                                className={styles.deleteButton}
                                                                onClick={() => removeCollectionFromSelected({ item, indexOfItem: index })}
                                                            >
                                                                Delete
                                                            </BrandButton> */}
                                                        </div>

                                                    </div>
                                                    <div>
                                                    </div>
                                                    <div className={styles.ratio} style={{ background: `linear-gradient(to right, ${colors})`, width: `${Math.round(numberOfOwners / totalOwners * 100) * 2}px` }}>
                                                    </div>
                                                </div>)
                                        } else if (!item.hide) {

                                            if (item.collectionName === selectedCollections[0]?.collectionName) {
                                                color = '#B50391';
                                            } else if (item.collectionName === selectedCollections[1]?.collectionName) {
                                                color = '#EDE107';
                                            } else if (item.collectionName === selectedCollections[2]?.collectionName) {
                                                color = '#62E162';
                                            }
                                            return (
                                                <div className={styles.collectionLine} key={item.collectionName}>
                                                    <div className={styles.collectionsInfo} key={item.collectionName}>
                                                        <div className={styles.collectionCol} style={{ color: color }}>{item.collectionName ? item.collectionName : item.contractAddresses[0]}</div>
                                                        <div className={styles.collectionPercent}>{
                                                            Math.round(item.numberOfOwners / totalOwners * 100) === 0 && item.numberOfOwners > 0 ? '>1' : (item.numberOfOwners && totalOwners ? Math.round(item.numberOfOwners / totalOwners * 100) : 0)
                                                        }</div>
                                                        <div className={styles.collectionOwners}>{item.numberOfOwners}</div>
                                                        <div className={styles.collectionDelete}>
                                                            {/* <BrandButton
                                                                startIcon={<DeleteOutlineIcon />}
                                                                className={styles.deleteButton}
                                                                onClick={() => removeCollectionFromSelected({ item, indexOfItem: index })}
                                                            >
                                                                Delete
                                                            </BrandButton> */}
                                                        </div>

                                                    </div>
                                                    <div className={styles.ratio} style={{ backgroundColor: color, width: `${Math.round(item.numberOfOwners / totalOwners * 100) === 0 && item.numberOfOwners > 0 ? 1 : Math.round(item.numberOfOwners / totalOwners * 100) * 2}px` }}>

                                                    </div>
                                                </div>)
                                        }


                                    })
                                }
                                <div className={styles.tableBottomCollections}>
                                    <div className={styles.collectionTotalOwnersTitle}>Total</div>
                                    <div className={styles.collectionTotalOwners}>{totalOwners}</div>
                                </div>

                            </div> : ''

                    }

                </div>
            </div>
            <div className={collections.length === 0 ? styles.paginationContainerHide: ''}>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={rowCounts}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                        setOffset(0);
                    }}
                    ActionsComponent={TablePaginationActions}
                />
            </div>


        </div >
    );
}
export default NftAnalyze;






