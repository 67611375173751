import React, { useEffect, useState } from 'react';
import { Checkbox, Input, ListItemText, MenuItem, Select, Slide, makeStyles } from '@material-ui/core';
import { BrandInput } from '../CoreComponents/BrandInput';
import { textIsEmpty, textIsEmail } from '../utils/ValidationUtils';
import { TempBrandButton } from '../CoreComponents/BrandButton';
import { postData } from '../utils/FetchUtils';
import { useHistory } from 'react-router-dom';


const dataValidators = {
    validateEmail: (value) => {
        const errors = []
        if (!textIsEmail(value)) {
            errors.push('Invalid mail.')
        }
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
    validateText: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    }
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'center',
        font: 'normal normal 18px/24px ITCAvantGardeGothicStd-Normal',
        width: '410px',
        borderRadius: 13,
        background: theme.palette.background.darkNuanceVersion3,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            background: theme.palette.background.dark,
        }
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    moto: {
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    formInfo: {
        width: '294px',
        margin: ' 0px 0px 10px 0px',
        textAlign: 'left',
        fontSize: '15px',
    },
    input: {
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        width: '350px',
        height: '48px',
        fontSize: '18px',
        '& .MuiInputBase-input': {
            textAlign: 'center'
        },
        borderRadius: '28px',
        "& label": {
            fontSize: '18px',
        },
        '& input::placeholder': {
            fontSize: '16px',
            color: theme.palette.text.darkNuance,
            letterSpacing: '0.15px',
        }
    },
    submitButton: {
        width: '350px',
        height: '48px',
        marginBottom: '40px',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    dropdownMenu: {
        width: '350px',
        height: '48px',
        border: "1px solid grey",
        borderRadius: 28,
        marginTop: 10,
        '& .MuiSelect-root': {
            width: '100%',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                width: '301px'
            }
        },
        '& .MuiInputBase-root': {
            width: '294px',
            height: '48px',
            borderRadius: '28px',
        },
        '& .MuiInput-underline:after': {
            position: 'relative',
            zIndex: -1
        },
        '& .MuiInput-underline:before': {
            position: 'relative',
            zIndex: -1
        },
        '& .MuiSelect-icon': {
            color: 'white'
        }
    },
    interestSuccessful: {
        marginTop: '20px'
    },
    inputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
    },
    errorMessage: {
        width: '294px',
        marginLeft: '1px',
        textAlign: 'left',
        fontSize: '15px',
        color: theme.palette.primary.alert
    }
}))
export default function Contact() {
    const history = useHistory();
    const styles = useStyles();
    const visitorName = useState('');
    const namePassed = useState(true);
    const emailPassed = useState(true);
    const email = useState('');
    const [error, setError] = useState('')
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const dropDownValues = useState(['Artist', 'Songwriter/Producer', 'Label', 'Management', 'Legal', 'Publisher', 'Distributor']);
    const [personInterests, setPersonInterests] = useState(['Artist']);

    const handleChange = (event) => {
        setPersonInterests(event.target.value);
    };

    useEffect(() => {

        setError('');
        if (visitorName[0].length === 255) {
            setError('Name cannot be longer than 255 characters');
        } else if (email[0].length === 255) {
            setError('Email cannot be longer than 255 characters');
        } else if (personInterests.length === 0) {
            setError('Add at least one interest!');
        }
    }, [email[0], visitorName[0], personInterests])

    const submission = (e) => {
        e.preventDefault();

        const user = {
            visitorName: visitorName[0],
            email: email[0],
            interest: personInterests
        }

        postData(process.env.REACT_APP_SERVER_HOST + '/api/user/save-splashpage-visitor', user)
            .then(res => {
                history.push('/thank-you-message');
            }, error => {
                setError(error.message);
                console.log(error.message);
            })

    }

    useEffect(() => {
        if ((!emailPassed[0] || email[0] === '') || (!namePassed[0] || visitorName[0] === '') || error.length > 0 || personInterests.length === 0) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [emailPassed[0], namePassed[0], visitorName[0], email[0], error, personInterests]);

    return (
        <Slide
            timeout={500}
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
        >
            <div className={styles.container}>
                <div className={styles.logoContainer}>
                    <p className={styles.moto}>Reach Out to Record</p>
                    <p className={styles.formInfo}>Please fill out a little information below & we’ll be in touch shortly.</p>
                    <div className={styles.inputsContainer}>
                        <BrandInput
                            label='Name'
                            required
                            $value={visitorName}
                            validator={dataValidators.validateText}
                            validatorPassed={namePassed}
                            placeholder='Legal or Artist'
                            classes={{ input: styles.input }}
                            maxLength={255}
                        />
                        <BrandInput
                            label='Email'
                            required
                            $value={email}
                            validator={dataValidators.validateEmail}
                            validatorPassed={emailPassed}
                            placeholder='Enter email here'
                            classes={{ input: styles.input }}
                            maxLength={255}
                        />
                        <div>
                            <label className={styles.multiSelectLabel}>What services are you interested in?</label>
                            <div className={styles.dropdownMenu}>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={personInterests}
                                    onChange={handleChange}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(", ")}
                                    placeholder='asd'
                                    displayEmpty={true}
                                >
                                    {dropDownValues[0].map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox color={'primary'} checked={personInterests.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>

                            </div>
                        </div>
                        <TempBrandButton
                            disabled={submitDisabled}
                            onClick={submission}
                            className={styles.submitButton}
                        >
                            Submit
                        </TempBrandButton>
                        {
                            error.includes('Visitor email added!') ? <div className={styles.interestSuccessful}>Visitor email is already added!</div> : ''
                        }
                        {
                            error ?
                                <div className={styles.errorMessage}>{error}</div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </Slide>
    );
}