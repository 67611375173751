import { TableCell, TableHead, TableRow, TableSortLabel, makeStyles } from "@material-ui/core";
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: 'black',
        position: 'sticky',
        top: 0,
    },
    cell: {
        border: 'none',
        textAlign: 'center'
    }
}));

const TableHeadPart = ({ rows, sortingType, handleToggleSortingType }) => {
    const styles = useStyles();

    return (
        <TableHead className={styles.wrapper}>
            <TableRow>
                { rows.map(({ value }, index) => (
                    <TableCell
                        key={index + value}
                        className={styles.cell}
                    >
                        { index < rows.length - 1 ? (
                            value
                        ) : (
                            <TableSortLabel 
                                active 
                                direction={sortingType} 
                                IconComponent={sortingType === 'ASC' ? ArrowDropUp : ArrowDropDown}
                                onClick={handleToggleSortingType}
                            >
                                {value}
                            </TableSortLabel>
                        ) }
                    </TableCell>
                )) }
            </TableRow>
        </TableHead>
    )
};

export default TableHeadPart;