
export function formatPeriodQYear (reportingPeriod) {
    const year = reportingPeriod.toString().slice(0, -1);
    const period = reportingPeriod.toString().slice(-1);

    const quarter = period === '1' ? 'Q1'
        : period === '2' ? 'Q2'
            : period === '3' ? 'Q3'
                : 'Q4';

    return `${quarter} ${year}`;
};