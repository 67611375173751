import React, { useState, useEffect, useRef } from 'react';
import { getData, postData } from '../utils/FetchUtils'
import BrandTable from '../CoreComponents/BrandTable'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Button, CircularProgress, TablePagination, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { SecondaryBrandButton } from '../CoreComponents/BrandButton';
import { BrandModal } from '../CoreComponents/BrandModal'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ConnectSong } from '../Songs/Songs';
import TablePaginationActions from '../CoreComponents/TablePaginationActions';
import { useStoreContext } from '../../store/Store';
import SongDetailsTabs from './SongsDialog/SongDetailsTabs';
import { useAsyncAction } from '../../hooks/useAsyncAction';
import { UnverifySong } from '../Songs/Songs';
import { BrandAlert } from '../CoreComponents/BrandAlert';
import { formatNumber } from '../utils/NumberUtils';
import PercentageInfo from '../RevenueSplits/PercentageInfo';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useAbortableFetch, { forceAbortFetch } from '../../hooks/useAbortableFetch';
import { SONG_PORTFOLIO_DEFAULT_ROWS_PER_PAGE, SONG_PORTFOLIO_ROWS_PER_PAGE_OPTIONS, handleRowsPerPageChange } from '../utils/PaginationUtils';

const useSongStyles = makeStyles((theme) => ({
    songsPortfiolioContainer: {
        '& .MuiTableCell-root': {
            padding: '9px 16px'
        },
        '& .MuiTableCell-head': {
            padding: '16px'
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    icons: {
        marginRight: '6px',
    },
    itemsCountSpinnerWrapper: {
        position: 'relative'
    },
    itemsCountSpinner: {
        position: 'absolute',
        right: '300px',
        top: '10px'
    },
    percentageText: {
        fontSize: '14px',
        fontWeight: 'normal'
    },
    link: {
        textDecoration: 'none',
        color: 'white',
        fontSize: '14px',
        textTransform: 'none'
    },
    fiberRecordIcon: {
        color: theme.palette.primary.warning, 
        fontSize: 10
    },
    titleWrapper: {
        display: 'flex'
    },
    songMergeInfoMainContainer: {
        cursor: 'default'
    },
    mergeIcon: {
        color: theme.palette.primary.main,
        display: 'flex',
        marginLeft: '4px'
    },
    songMergeInfo: {
        maxHeight: '250px',
        position: 'absolute', 
        zIndex: '1', 
        backgroundColor: theme.palette.background.default,
        padding: '10px',
    },
    songMergeInfoLabel: {
        paddingRight: '10px'
    },
    songMergeInfoValue: {
        paddingBottom: '4px',
        paddingRight: '10px',
        color: theme.palette.primary.main,
    }
}));
export function tableRowsPerClientViewHeight(setRowsFn, setOptionsFn, optionsState, headerHeight) {
    const tableViewHeight = window.innerHeight - headerHeight;
    const rowsPerViewHeight = Math.floor((tableViewHeight / 51) - 2);

    if (Number.isFinite(rowsPerViewHeight)) {
        setRowsFn(rowsPerViewHeight);
        if (!optionsState.includes(rowsPerViewHeight)) {
            setOptionsFn([rowsPerViewHeight, ...optionsState].sort((a, b) => a > b ? 1 : -1));
        }
    };
}

export default function SongPortfolio({ 
    accountHolder, 
    startQuarter, 
    endQuarter, 
    search, 
    territory, 
    playSource, 
    album, 
    reportingCompany, 
    groupings,
    checkedUnasignedRevenueSplit,
    genre,
    selectedFilter
}) {
    const styles = useSongStyles();

    const [state, setState] = useStoreContext();
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState(SONG_PORTFOLIO_ROWS_PER_PAGE_OPTIONS);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(SONG_PORTFOLIO_DEFAULT_ROWS_PER_PAGE);
    const [sortBy, setSortBy] = useState('id');
    const [sortType, setSortType] = useState('ASC');
    const [displaySongDataModal, setDisplaySongDataModal] = useState(false);
    const [selectedSongRow, setSelectedSongRow] = useState(null);
    const mountedRef = useRef(false);

    const isAdmin = state.user && state.user.userType === 1;
    const adminOnlyRows = ['assignedRevenue'];
    const revenueSplitColumns = ['assignedRevenue'];

    const [allHeadCells] = useState([
        {
            id: 'displayName',
            label: 'Song Title',
            minWidth: '250px',
            CellRender: ({ cellData, rowData }) => {
                const [isShown, setIsShown] = useState(false);
                const ref = useRef(null);
                const mergedSongs = rowData.mergedSongs && rowData.mergedSongs.length > 0 ? rowData.mergedSongs[0] : null;
                return(
                <>
                    {cellData ? 
                        <div className={styles.titleWrapper}>
                            {cellData}
                            {mergedSongs &&
                            <div
                                className={styles.songMergeInfoMainContainer}
                                onMouseEnter={() => setIsShown(true)}
                                onMouseLeave={() => setIsShown(false)}
                                ref={ref}
                            >
                                <div className={styles.mergeIcon}>
                                    {'+'}<MergeTypeIcon fontSize='small' />
                                </div>
                                {isShown &&
                                <div className={styles.songMergeInfo}>
                                    <div className={styles.songMergeInfoLabel}>Artist:</div>
                                    <div className={styles.songMergeInfoValue}>{rowData.artistName || 'Unknown'}</div>
                                    <div className={styles.songMergeInfoLabel}>Song merged into:</div>
                                    <div className={styles.songMergeInfoValue}>{mergedSongs}</div>
                                    <div className={styles.songMergeInfoLabel}>View whole hierarchy:</div>
                                    <div><Link className={styles.songMergeInfoValue} onClick={(e) => e.stopPropagation()} to='/merge-history'>Merge history</Link></div>
                                </div>
                                }
                            </div>
                            }
                        </div>
                    :
                    <FiberManualRecordIcon className={styles.fiberRecordIcon} />
                    }
                </>
                )
            }
        },
        {
            id: 'artistName',
            label: 'Artist',
            minWidth: '120px',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }} >
                        {cellData ?
                            `${cellData}`
                            :
                            'Unknown'
                        }
                    </div>
                );
            }
        },
        {
            id: 'accountHolderName',
            label: 'Account Holder(s)',
            notSortable: true,
            CellRender: ({ rowData }) => {

                const parsedAccountHolders = rowData.accountHolderName;
                const [anchorEl, setAnchorEl] = useState(null);

                const handlePopoverOpen = (event) => {
                    setAnchorEl(event.currentTarget);
                };

                const handlePopoverClose = () => {
                    setAnchorEl(null);
                };

                const open = Boolean(anchorEl);

                if (!parsedAccountHolders || parsedAccountHolders.length === 0) {
                    return "N/A";
                } else if (parsedAccountHolders.length === 1) {
                    return parsedAccountHolders[0];
                }

                return (
                    <div>
                        <div
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            {`${parsedAccountHolders[0]}; +${parsedAccountHolders.length - 1}`}
                        </div>
                        <Popover
                            id="mouse-over-popover"
                            className={styles.popover}
                            classes={{
                                paper: styles.paper,
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            {
                                parsedAccountHolders.map(x => {
                                    return (
                                        <Typography key={`${x}${Math.random()}`} color='primary'>{x}</Typography>
                                    )
                                })
                            }
                        </Popover>
                    </div>
                );
            },
        },
        {
            id: 'releaseDate',
            label: 'Release Date',
            CellRender: ({ rowData }) => {
                const releaseDate = new Date(rowData.releaseDate)
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {releaseDate.getTime() ?
                            `${releaseDate.getFullYear()}-${releaseDate.getMonth() < 9 ? '0' : ''}${releaseDate.getMonth() + 1}-${releaseDate.getDate() < 10 ? '0' : ''}${releaseDate.getDate()}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            },
        },
        {
            id: 'revenue',
            label: 'Revenue',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData === null ?
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                            :
                            `$${formatNumber(cellData)}`
                        }
                    </div>
                );
            }
        },
        // {
        //     id: 'masterValuation',
        //     label: 'Master Valuation',
        //     CellRender: ({ cellData }) => {
        //         return (
        //             <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
        //                 {cellData ?
        //                     `$${formatNumber(cellData)}`
        //                     :
        //                     <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
        //                 }
        //             </div>
        //         );
        //     }
        // },
        // {
        //     id: 'publishingValuation',
        //     label: 'Publisher Valuation',
        //     CellRender: ({ cellData }) => {
        //         return (
        //             <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
        //                 {cellData ?
        //                     `$${formatNumber(cellData)}`
        //                     :
        //                     <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
        //                 }
        //             </div>
        //         );
        //     }
        // },
        {
            id: 'totalMasterEarnings',
            label: 'Total Master Earnings',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData ?
                            `$${formatNumber(cellData)}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            }
        },
        {
            id: 'totalPublishingEarnings',
            label: 'Total Publisher Earnings',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData ?
                            `$${formatNumber(cellData)}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            }
        },
        {
            id: 'assignedRevenue',
            label: 'Assigned Revenue',
            notSortable: true,
            CellRender: ({ cellData }) => {
                return (
                    <PercentageInfo 
                        hideCompletion 
                        percentage={cellData} 
                        className={styles.percentageText} 
                    />
                )
            }
        },
        {
            id: 'territory',
            label: 'Territory',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData ?
                            `${cellData}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            },
        },
        {
            id: 'playSource',
            label: 'Play Source',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData ?
                            `${cellData}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            },
        },
        {
            id: 'album',
            label: 'Album',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData ?
                            `${cellData}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            },
        },
        {
            id: 'genre',
            label: 'Genre',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData ?
                            `${cellData}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            },
        },
        {
            id: 'reportingCompany',
            label: 'Reporting Company',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData ?
                            `${cellData}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            },
        },
        {
            id: 'spotifySongId',
            label: 'Verification',
            CellRender: ({ rowData }) => {
                const { spotifySongId, id } = rowData;
                const [verAlert, setVerAlert] = useState('');

                function onVerifiedCellClick() {
                    setVerAlert('song already verified')

                    setTimeout(() => {
                        setVerAlert('');
                    }, "2000")
                }

                return (
                    <div style={{ position: 'relative' }}>
                    <SecondaryBrandButton
                        variant='text'
                        key={id}
                        startIcon={spotifySongId ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                        onClick={(event) => {
                            event.stopPropagation();

                            if (spotifySongId) {
                                onVerifiedCellClick();

                                //Disable reverify song until problem with updating data is solved

                                // handleShowUnverifySongModal();
                                // setSelectedSongToUnverify(rowData);
                            } else {
                                setDisplayConnectSong(true);
                                setSelectedSongToConnect(rowData);
                            }
                        }}
                    >   
                        {spotifySongId ? 'Verified' : 'Verify'}
                    </SecondaryBrandButton>
                        <div style={{ position: 'absolute', whiteSpace: 'nowrap', left: '-20px' }}>
                            {verAlert !== '' && <BrandAlert>Song already verified!</BrandAlert>}
                        </div>
                    </div>
                );
            }
        }
    ].filter((headCell) => isAdmin || adminOnlyRows.indexOf(headCell.id) === -1)
     .filter((headCell) => process.env.REACT_APP_SHOW_REVENUE_SPLIT === 'true' || revenueSplitColumns.indexOf(headCell.id) === -1));

    const [headCells, setHeadCells] = useState(allHeadCells.filter(x => x.id !== 'territory' && x.id !== 'playSource' && x.id !== 'album' && x.id !== 'reportingCompany'));
    const [displayConnectSong, setDisplayConnectSong] = useState(false);
    const [selectedSongToConnect, setSelectedSongToConnect] = useState({});
    const [selectedSongModalRefresh, setSelectedSongModalRefresh] = useState({});

    const [selectedSongToUnverify, setSelectedSongToUnverify] = useState({});
    const [showUnverifySongModal, setShowUnverifySongModal] = useState(false);

    const handleShowUnverifySongModal = () => setShowUnverifySongModal(true);
    const handleHideUnverifySongModal = () => setShowUnverifySongModal(false);

    function onRowClick(event, row) {
        const maxChars = 100;
        row = { ...row, writers: row.writers && row.writers.length > maxChars ? row.writers.substring(0, maxChars) + '...' : row.writers }
        setSelectedSongRow(row);
        setDisplaySongDataModal(true);
    }

    useEffect(() => {
        tableRowsPerClientViewHeight(setRowsPerPage, setRowsPerPageOptions, rowsPerPageOptions, 260);
    }, [])

    useEffect(() => {
        return () => {
            setState(state => (state.portfolioItems.items = [], state.portfolioItems.countTotal = 0, { ...state }));
        }
    }, []);

    useEffect(() => {
        setOffset(page * rowsPerPage);
    }, [page])

    const [loading, setLoading] = useState(false);
    const abortableFetch = useAbortableFetch();

    const trigger = async () => {
        setLoading(true);

        const body = {
            filter: search,
            startDate: startQuarter,
            endDate: endQuarter,
            sort: sortBy,
            type: sortType,
            accountHolder: accountHolder[0],
            groupings,
            checkedUnasignedRevenueSplit,
            selectedFilter
        };

        try {
            const fetchedData = await abortableFetch('POST', '/api/elastic/portfolio', {
                body,
                query: {
                    offset,
                    limit: rowsPerPage
                }
            });

            // TODO: Rewrite it cleaner

            setState(state => (state.portfolioItems.items = fetchedData ? fetchedData.songsWithRevenues : [], { ...state }));
            setState(state => (state.portfolioItems.countTotal = fetchedData ? fetchedData.songsCount : 0, { ...state }));
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            setHeadCells(allHeadCells.filter(x => (
                x.id !== 'territory' &&
                x.id !== 'playSource' &&
                x.id !== 'album' &&
                x.id !== 'genre' &&
                x.id !== 'reportingCompany'
            )
                || (x.id === 'territory' && territory[0])
                || (x.id === 'playSource' && playSource[0])
                || (x.id === 'album' && album[0])
                || (x.id === 'genre' && genre[0]) 
                || (x.id === 'reportingCompany' && reportingCompany[0])));

            if (rowsPerPage === 0) return;

            if (loading) {
                forceAbortFetch();
            }
            trigger();
    }, [startQuarter, endQuarter, offset, rowsPerPage, sortType, sortBy, accountHolder[0], search, groupings, checkedUnasignedRevenueSplit, selectedFilter])

    useEffect(() => {
        setPage(0);
    }, [startQuarter, endQuarter, accountHolder[0], search])

    const onClose = async () => {
        setDisplaySongDataModal(false);
        setState(state => ({ ...state, displayName: '' }));
    };

    return (
        <div className={styles.songsPortfiolioContainer}>
            <BrandModal
                open={showUnverifySongModal}
                onClose={handleHideUnverifySongModal}
            >
                <UnverifySong
                    rowData={selectedSongToUnverify}
                    onClose={handleHideUnverifySongModal}
                    onFetch={trigger}
                />
            </BrandModal>
            <BrandModal
                open={displaySongDataModal}
                hasPadding={false}
                onClose={() => onClose()}
            >
                <SongDetailsTabs
                    details={selectedSongRow}
                    setDetails={setSelectedSongRow}
                    onClose={onClose}
                    setDisplayConnectSong={setDisplayConnectSong}
                    setSelectedSongToConnect={setSelectedSongToConnect}
                    selectedSongModalRefresh={selectedSongModalRefresh}
                    startQuarter={startQuarter}
                    endQuarter={endQuarter}
                    accountHolder={accountHolder[0]}
                    onChange={trigger}
                />
            </BrandModal>
            <BrandModal
                open={displayConnectSong}
                onClose={() => setDisplayConnectSong(false)}
            >
                <ConnectSong
                    song={selectedSongToConnect}
                    onClose={() => {
                        setDisplayConnectSong(false);
                        setSelectedSongModalRefresh({});
                        trigger();
                    }}
                />
            </BrandModal>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <BrandTable
                        rows={state.portfolioItems.items}
                        headCells={headCells}
                        checkboxless={true}
                        page={page}
                        hidePagination={true}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                        rowIsClickable={true}
                        onRowClick={onRowClick}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={state.portfolioItems.countTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event, offset, setRowsPerPage, setPage, setOffset)}
                        ActionsComponent={TablePaginationActions}
                    />
                </>
            )}
        </div>
    );
}



