import { Snackbar, Typography } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as Papa from 'papaparse';
import { BrandButton } from "../CoreComponents/BrandButton";
import { getData, postData } from "../utils/FetchUtils";
import { BrandDatePicker } from "../CoreComponents/BrandDatePicker";
import { BrandInput } from "../CoreComponents/BrandInput";

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: theme.spacing(3),

    },
    pageTitle: {
        marginBottom: theme.spacing(5),
        font: 'normal normal bold 25px Roboto',
    },
    autocompleteContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    checkboxContainer: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: '1px  #3C3C3C',
            }
        },
        '& .MuiInputBase-root': {
            height: theme.spacing(5),
            border: '1px solid #3C3C3C',
            borderRadius: theme.spacing(3),
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3C3C3C',
            border: 'none'
        },
        '& .MuiFormLabel-root': {
            top: '-6px'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'white'
        },
        '& .MuiInputBase-input': {
            position: 'relative',
            bottom: theme.spacing(1),
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: 'white'
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: 'white',
            "& span": {
                "& svg": {
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')" // your svg icon path here
                    }
                }
            }
        },
    },
    defaultSelect: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#C3C3C3',
        gap: theme.spacing(2),
        '& .MuiInputBase-root': {
            border: '1px solid #3C3C3C'
        },
        height: theme.spacing(6),
    },
    defaultSelectLabel: {
        font: 'normal normal normal 14px/16px Roboto',
        color: '#FFFFFFDE',
        letterSpacing: theme.spacing(0.125),
        width: theme.spacing(14),
    },
    datesContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        width: theme.spacing(46),
    },
    filterContainer: {
        display: 'flex',
        gap: theme.spacing(6),
        borderBottom: '1px solid #3C3C3C',
        paddingBottom: theme.spacing(5),
    },
    dateSelect: {
        '& .MuiInputBase-root': {
            background: 'none'
        },
    },
    selectedAccountHolders: {

    },
    accountSelectLabel: {
        font: 'normal normal normal 14px/16px Roboto',
        width: theme.spacing(21)
    },
    accountHolderContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '50px',
        marginTop: '20px'
    },
    accountHolderName: {
        width: '200px'
    },
    inputWalletAddress: {
        '& .MuiInputBase-input': {
            width: theme.spacing(34)
        }
    },
    previewButton: {
        color: 'black',
        fontSize: '12px',
        height: theme.spacing(5)
    },
    clearIndicator: {
        backgroundColor: "white",
        "& span": {
            "& svg": {
                "& path": {
                    d: "path('M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z')" // your svg icon path here
                }
            }
        }
    },
    popupIndicator: {
        backgroundColor: "white"
    }
}));

const NftReports = () => {
    const styles = useStyles();
    const [accountHolders, setAccountHolders] = useState([]);
    const [limitDate, setLimitDate] = useState(new Date());
    const [offsetDate, setOffsetDate] = useState(new Date());
    const [maxDate] = useState(new Date());
    const [selectedValues, setSelectedValues] = useState([]);
    const [walletIds, setWalletIds] = useState({});
    const [messageOfAlert,setMessageOfAlert] = useState('');
    const [showAlert,setShowAlert] = useState(false)

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/my`)
            .then(data => setAccountHolders(data));
    }, [])
    useEffect(() => {
        if(showAlert){
            const timer = setTimeout(()=> setShowAlert(false),3000);
            return () => clearTimeout(timer);
        }
    },[showAlert])

    const inHand = (e, id) => {

        setWalletIds({ ...walletIds, [id]: e.target.value })
    }

    function changeLimitDate(date){
        if(date.getTime() > offsetDate.getTime()){
            setLimitDate(date);
        }else if(date.getTime() <= offsetDate.getTime()){
            setShowAlert(true);
            setMessageOfAlert('The date TO must be less than the date FROM')
        }

    }

    const DownloadCSV = async (token) => {

        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-report?token=${token}`)
            .then(data => {
                let dataToDownload;
                if(data.transactionsData.length === 0){
                    setMessageOfAlert('There is no information for the selected time period')
                    setShowAlert(true);
                    return;
                }

                if (data.transactionsData[0]?.transactionChain == 'ETHEREUM') {

                    dataToDownload = data.transactionsData.map(trans => {
                        return {
                            "Hash": trans.hash,
                            "NFT Title": trans.nftTitle,
                            "NFT Collection": trans['NFT Collection'],
                            "Timestamp": trans.Timestamp,
                            "Transaction chain": trans['Transaction Chain'],
                            "Seller of NFT": trans['Seller of NFT'],
                            "Buyer of NFT": trans['Buyer of NFT'],
                            "Marketplace": trans.Marketplace,
                            "Amount of NFT Sale": trans['Amount of NFT Sale'],
                            "Marketplace Fee": trans['Marketplace Fee'] !== null ? trans['Marketplace Fee'] : 0,
                            "Creator Royalty": trans['Creator Royalty'] !== null ? trans['Creator Royalty'] : 0,
                            "Sale type": trans['Sale Type'],
                            "Token ID": trans.transactionTokenID,
                            "Account holder": trans['Account Holder'],
                            "Ownership %": trans['Ownership %'],
                            "Ownership from royalty": trans['Ownership from royalty'],
                            "Currency": trans.Currency,
                            "Transaction Type": trans.transactionType
                        }
                    })
                } else {
                    dataToDownload = data.transactionsData.map(trans => {
                        return {
                            "Id": trans.id,
                            "Title": trans.title,
                            "Date": trans.date,
                            "Sale Type": trans.salesType,
                            "Royalty Amount": trans.amount,
                            "Transaction Chain": trans.transactionChain
                        }
                    })
                }
                setShowAlert(false);
                const asJsonData = JSON.stringify(dataToDownload);
                const asCsvData = Papa.unparse(asJsonData);
                const csvFile = new File([asCsvData], `${data.accountHolderName}.csv`, {
                    type: "text/plain",
                });
                const dateStamp = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
                const url = window.URL.createObjectURL(csvFile);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${data.accountHolderName}_${dateStamp}.csv`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
    }

    return (
        <div className={styles.container}>
            <Typography
                component='div'
                variant='h5'
                className={styles.pageTitle}
            >
                Reports
            </Typography>
            <Typography
                component='div'
                variant='h6'
                className={styles.subtitle}
            >
                Report Filters
            </Typography>

            <div className={styles.filterContainer}>
                <div className={styles.autocompleteContainer}>
                    <div className={styles.accountSelectLabel}>Account Holder</div>
                    <Autocomplete
                        onChange={(event, value) => setSelectedValues(value)}
                        className={styles.checkboxContainer}
                        multiple
                        id="checkboxes-tags-demo"
                        options={accountHolders}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderTags={() => null}
                        renderOption={(option, { selected }) =>
                            <>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    color={"primary"}
                                    checked={selected}
                                />
                                {option.name}
                            </>
                        }
                        style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" placeholder="Search for Account Holder" />
                        )}
                    />
                </div>
                <div className={styles.defaultSelect}>
                    <div className={styles.defaultSelectLabel}>Date Range</div>
                    <div className={styles.datesContainer}>
                        <div className={styles.dateSelect}>
                            <BrandDatePicker
                                label=""
                                selectedDate={offsetDate}
                                setSelectedDate={setOffsetDate}
                                maxDate={maxDate}
                            />
                        </div>
                        <div className={styles.dateSelect}>
                            <BrandDatePicker
                                label=""
                                selectedDate={limitDate}
                                setSelectedDate={changeLimitDate}
                                maxDate={maxDate}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.selectedAccountHolders}>
                {
                    selectedValues.map(el => (
                        <div key={el.id} className={styles.accountHolderContainer}>
                            <div className={styles.accountHolderName} >{el.name}</div>
                            <BrandInput
                                onChange={(e) => inHand(e, el.id)}
                                placeholder="Account Holder's Wallet Address"
                                classes={{ input: styles.inputWalletAddress }}
                                defaultValue={el.walletAddress}
                            />
                            <BrandButton
                                disabled={offsetDate == null ? true : false}
                                className={styles.previewButton}
                                onClick={async () => {
                                    const requestObject = {
                                        accountHolderId: el.id,
                                        offsetDate: offsetDate,
                                        limitDate: limitDate,
                                        walletId: el.walletAddress
                                    };

                                    if (Object.keys(walletIds).length) {
                                        requestObject.walletId = walletIds[el.id];
                                    }

                                    await postData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-nft-report-data-token`, requestObject)
                                        .then(data => {
                                            // window.location.href = `/nft-report?token=${data.token}`
                                            window.open(`/nft-report?token=${data.token}`, '_blank');
                                            // window.open(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-report?token=${data.token}`, '_blank');
                                        });
                                    }}
                            >
                                {/* <Link to={`/nft-report/${token}`} style={{ textDecoration: 'none', color: 'black' }}> */}
                                    Preview Report
                                {/* </Link> */}
                            </BrandButton>

                            <BrandButton
                                disabled={offsetDate == null ? true : false}
                                className={styles.previewButton}
                                onClick={async () => {
                                    const requestObject = {
                                        accountHolderId: el.id,
                                        offsetDate: offsetDate,
                                        limitDate: limitDate,
                                        walletId: el.walletAddress
                                    };

                                    if (Object.keys(walletIds).length) {
                                        requestObject.walletId = walletIds[el.id];
                                    }

                                    await postData(process.env.REACT_APP_SERVER_HOST + `/api/nft/get-nft-report-data-token`, requestObject)
                                        .then(data => {
                                            DownloadCSV(data.token)
                                        });
                                }}
                            >
                                DOWNLOAD CSV
                            </BrandButton>
                        </div>
                    ))
                }
            </div>
            <Snackbar open={showAlert}>
                <Alert severity="error">
                    {messageOfAlert}
                </Alert>
            </Snackbar>
        </div >
    );
};
export default NftReports;