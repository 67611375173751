import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useState } from 'react';
import { getData } from '../../utils/FetchUtils';
import { useEffect } from 'react';
import clsx from 'clsx';
import { BrandLinearChart, BrandVerticalBarsChart } from '../../CoreComponents/BrandChart';
import CryptoCurrenciesTabs from './CryptoCurrenciesTabs';
import NftActivity from './NftActivity';
import Overview from './Overview';
import Performance from './Performance';
import { roundToSecondDecimal } from '../NftPortfolio';
import CapitalGainsAndLossesDateRanges from './CapitalGainsAndLossesDateRanges';
import GainsAndLossesTransactions from './GainsAndLossesTransasctions';

const useNftStyles = makeStyles((theme) => ({
    bar: {
        width: '100%',
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiPaper-elevation4': {
            boxShadow: 'none !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main
        },
        '& .MuiBox-root': {
            padding: 0
        },
        "& .MuiButtonBase-root": {
            color: 'white',
            opacity: 1,
            font: 'normal normal bold 16px/20px Roboto',
            letterSpacing: '1.6px'
        },
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
        "& .MuiTableContainer-root": {
            height: '420px'
        },
        "& .MuiTableHead-root": {
            position: 'sticky',
            top: 0
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiTabs-flexContainer': {
            marginTop: '8px',
            justifyContent: 'space-between'
        },
        '& .MuiAppBar-root': {
            dispaly: 'flex',
            alignItems: 'center'
        },
        '& .MuiTabs-root': {
            width: '85%'
        }
    },
    tabHeight: {
        height: '680px'
    },
    chart: {
        width: '100%',
        overflow: 'auto'
    },
    tabContainer: {
        margin: theme.spacing(2),
        marginTop: 0,
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiChip-root': {
            marginTop: theme.spacing(1),
        }
    },
    tabPanel: {
        padding: theme.spacing(2)
    },

}));

function setTabProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function NftDetailsTabs({ details, additionalDetails, selectedCryptocurrency, cryptocurrencies, setSelectedCryptocurrency }) {
    const styles = useNftStyles();
    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [currencyTabValue, setCurrencyTabValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (value === 1) {
            getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-performance?nftId=${details.id}${selectedCryptocurrency ? `&cryptocurrency=${selectedCryptocurrency}` : ''}`)
                .then(d => {
                    d.map(x => {
                        x.month = x.month.substr(0, 3)
                    })
                    setData(d)
                })
        }
    }, [value, selectedCryptocurrency])

    return (
        <div className={styles.bar}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Overview" {...setTabProps(0)} />
                    <Tab label="Performance" {...setTabProps(1)} />
                    <Tab label="NFT Activity" {...setTabProps(2)} />
                    <Tab label="Capital gains & losses" {...setTabProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel
                value={value}
                index={0}
                className={clsx(styles.tabPanel, styles.tabHeight)}
            >
                <Overview details={details} />
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
                className={clsx(styles.tabPanel, styles.tabHeight)}
            >
                <CryptoCurrenciesTabs
                    setSelectedCryptocurrency={setSelectedCryptocurrency}
                    cryptocurrencies={cryptocurrencies}
                    calendarShow={true} showTypography={true}
                    currencyTabValue={currencyTabValue}
                    setCurrencyTabValue={setCurrencyTabValue}
                />
                <Performance
                    details={details}
                    additionalDetails={additionalDetails}
                />
                <div className={styles.tabContainer}>
                    <Typography variant='h6' component='div'>Revenue By Year</Typography>
                    <div className={styles.chart}>
                        <BrandVerticalBarsChart
                            customFormat={(value) => `${selectedCryptocurrency ? selectedCryptocurrency === 'ETHEREUM' ? 'ETH' : selectedCryptocurrency : 'ETH'} ${roundToSecondDecimal(value)}`}
                            by='month'
                            data={data}
                            tooltipLabel='Earnings'
                            color='#16AE90'
                            width={`${data.length ?
                                data.length < 11 ?
                                    data.length < 9 ?
                                        data.length < 7 ?
                                            data.length < 5 ?
                                                data.length < 3 ?
                                                    data.length === 1 ?
                                                        data.length * 180
                                                        :
                                                        data.length * 135
                                                    :
                                                    data.length * 125
                                                :
                                                data.length * 115
                                            :
                                            data.length * 105
                                        :
                                        data.length * 95
                                    :
                                    data.length * 80
                                :
                                120}px`}
                            height='300px'
                        />
                    </div>
                </div>
            </TabPanel>
            <TabPanel
                value={value}
                index={2}
                className={clsx(styles.tabPanel, styles.tabHeight)}
            >
                <CryptoCurrenciesTabs
                    setPage={setPage}
                    setSelectedCryptocurrency={setSelectedCryptocurrency}
                    cryptocurrencies={cryptocurrencies}
                    calendarShow={true}
                    showTypography={true}
                    currencyTabValue={currencyTabValue}
                    setCurrencyTabValue={setCurrencyTabValue}
                />
                <NftActivity
                    page={page}
                    setPage={setPage}
                    nftId={details.id}
                    nftData={details}
                    selectedCryptocurrency={selectedCryptocurrency}
                />
            </TabPanel>
            <TabPanel
                value={value}
                index={3}
                className={clsx(styles.tabPanel, styles.tabHeight)}
            >
                <CryptoCurrenciesTabs
                    setPage={setPage}
                    setSelectedCryptocurrency={setSelectedCryptocurrency}
                    cryptocurrencies={cryptocurrencies}
                    calendarShow={false}
                    currencyTabValue={currencyTabValue}
                    setCurrencyTabValue={setCurrencyTabValue}
                />
                <CapitalGainsAndLossesDateRanges />
                <div style={{ display: 'flex', gap: "80px" }}>
                    <BrandLinearChart
                        width={700}
                        height={400}
                    />
                    <GainsAndLossesTransactions
                        nftId={details.id}
                        selectedCryptocurrency={selectedCryptocurrency}
                    />
                </div>
            </TabPanel>
        </div>
    );
}

export default NftDetailsTabs;