import React, { useEffect, useState } from 'react';
import './Validation.scss';
import { makeStyles } from '@material-ui/core/styles';
import { BrandCanvasTable } from '../CoreComponents/BrandCanvasTable.js';
import { BrandButton } from '../CoreComponents/BrandButton';
import { BrandModal } from '../CoreComponents/BrandModal';
import { cellTextIsEmpty } from '../utils/ValidationUtils';
import { useStoreContext } from '../../store/Store';

const useStyles = makeStyles((theme) => ({
    mistakes: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '18px',
        marginBottom: '2em'
    },
    invalid: {
        color: '#f58270',
        fontWeight: 'bold'
    },
    pkDuplicate: {
        color: theme.palette.primary.warning,
        fontWeight: 'bold'
    },
    mandatory: {
        color: theme.palette.primary.attention,
        fontWeight: 'bold'
    },
    tableHeaderText: {
        color: theme.palette.primary.lightGray,
        margin: 0
    },
    title: {
        fontSize: '30px',
    },
    colHeader: {
        background: `${theme.palette.primary.lightGray} 0% 0% no-repeat padding-box`,
        opacity: 1,
        height: '44px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.primary.lightYellow}`,
    },
    mappingSelect: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px',
        color: 'white',
        fontWeight: 'bold',
        width: '250px',
        minWidth: '250px',
        maxWidth: '250px',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.black,
        border: `1px solid ${theme.palette.primary.lightYellow}`,
        '&:not(last-child)': {
            borderRight: 'none',
        }
    },
    mappingTop: {
        display: 'flex',
        marginTop: '1em'
    },
    mappingTable: {
        width: '100%',
        overflow: 'auto',
        overflowX: 'auto',
        '& .x-spreadsheet-bottombar': {
            display: 'none'
        }
    },
    table: {
        overflow: 'hidden',
        width: '100%',
        '& .x-spreadsheet': {
            marginTop: '-49px',
            marginLeft: '-57px',
        }
    },
    wrongDataModal: {

    },
    rowsContainer: {
        maxHeight: '600px',
        overflow: 'auto'
    },
    wrongDataModalRow: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    wrongDataButton: {
        backgroundColor: theme.palette.primary.alert,
        color: theme.palette.text.black,
        fontWeight: 'bold',
        width: '150px',
        height: '42px',
        '&:hover': {
            background: theme.palette.primary.alertHover,
        }
    }
}));

function checkError(wrongFunc) {
    let text = '';
    switch (wrongFunc) {
        case 'date':
            text = 'wrong Date format (ex. YYYY-MM-DD, 2020-12-25)';
            break;
        case 'upc':
            text = 'wrong UPC format';
            break;
        case 'isrc':
            text = 'wrong ISRC format (ex. USKO10701678)';
            break;
        case 'number':
            text = 'wrong numeric format (only numbers are allowed)';
            break;
        case 'yearQuarter':
            text = 'wrong YearQuarter format (ex. 20231, 20232, 20233, 20234)';
            break;
        case 'textIsPercent':
            text = 'wrong number range (must be between 0 and 100)';
            break;
        default:
            break;
    };

    return text
};

function validateCell(cellData, columnMapping) {
    // 1 = red style / mandatory cells are empty or wrong data type
    // 2 = orange style / wrong data type or format
    // 3 = yellow style / empty but not mandatory
    // 4 = default style
    const percentValidatorName = ['textIsPercent'];
    const requiredValidatorName = ['required'];

    if (columnMapping.fieldName === 'masterOwnership' || columnMapping.fieldName === 'publishingOwnership') {
        if (Number(cellData) > 100 || Number(cellData) < 0) {
            return [1, { [columnMapping.name]: percentValidatorName }]
        }
    }

    if (columnMapping.required) {
        if (cellTextIsEmpty(cellData)) {
            return [1, { [columnMapping.name]: requiredValidatorName }];
        }

        for (const validator of columnMapping.validations) {
            const isValid = validator(cellData);
            if (!isValid) {
                return [1, { [columnMapping.name]: columnMapping.validatorName }];
            }
        }
        return [4];
    }

    if (cellTextIsEmpty(cellData)) {
        return [3];
    }

    for (const validator of columnMapping.validations) {
        const isValid = validator(cellData);
        if (!isValid) {
            return [2, { [columnMapping.name]: columnMapping.validatorName }];
        }
    }
    return [4];
}

export function Validation({
    validationRows,
    setValidationRows,
    mandatoryCellsAreEmptyOrWrongDataType,
    setMandatoryCellsAreEmptyOrWrongDataType,
    dataMappings,
    mappingTemplate,
    retryUpload
}) {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [cellsAreWrongDataTypeOrFormat, setCellsAreWrongDataTypeOrFormat] = useState(0);
    const [cellsAreEmptyButNotMandatory, setCellsAreEmptyButNotMandatory] = useState(0);
    const [tableStyles] = useState([
        {},
        {
            bgcolor: '#f58270',
            color: '#CFCFCF',
        },
        {
            bgcolor: '#f1802d',
            color: '#CFCFCF',
        },
        {
            bgcolor: '#e4d906',
            color: 'black',
        },
        {
            bgcolor: '#222222',
            color: '#CFCFCF',
        }
    ]);
    const [tableOptions] = useState({
        mode: 'edit',
        showToolbar: true,
        showGrid: true,
        view: {
            height: () => Math.min(validationRows.length * 50 + 150, 800),
            width: () => mappingTemplate.length * 250 + 70
        },
        row: {
            height: 50
        },
        col: {
            len: mappingTemplate.length,
            width: 250
        },
        style: {
            bgcolor: 'rgb(30,33,36)',
            color: '#CFCFCF',
            align: 'right',
            textwrap: true,
            font: {
                name: 'Roboto',
                size: 11,
                bold: false,
                italic: false,
            }, border: {
                top: ['thin', '#1E2124'],
                bottom: ['thin', '#1E2124'],
                right: ['medium', '#929395'],
                left: ['medium', '#929395'],
            }
        }
    });
    const [displayRows, setDisplayRows] = useState([]);
    const [wrongColumnsModal, setWrongColumnsModal] = useState(false);
    const [wrongData, setWrongData] = useState([]);

    useEffect(() => {
        validateRows(validationRows);

        const sortedRows = [...validationRows];

        sortedRows.sort((a, b) => {
            const rowDataA = Object.values(a.cells);
            const rowDataB = Object.values(b.cells);
            return rowDataB.findIndex(x => x.style === 1) - rowDataA.findIndex(x => x.style === 1);
        })

        setDisplayRows(sortedRows);

    }, []);

    function validateRows(rows) {
        let temporaryMandatoryCellsAreEmptyOrWrongDataType = 0;
        let temporaryCellsAreWrongDataTypeOrFormat = 0;
        let temporaryCellsAreEmptyButNotMandatory = 0;
        const tempWrongData = [];

        for (let row = 0; row < rows.length; row++) {

            const rowData = Object.values(rows[row].cells);
            for (let col = 0; col < rowData.length; col++) {
                const cell = rowData[col];
                const [cellStyle, wrongColAndFunc] = validateCell(cell.text, dataMappings[mappingTemplate[col]])
                const [wrongCol, wrongFunc] = wrongColAndFunc ? Object.entries(wrongColAndFunc)[0] : []

                if (wrongColAndFunc) {
                    tempWrongData.push({ wrongCol, wrongFunc })
                }

                cell.text = cell.text?.trim();
                cell.style = cellStyle;
                cell.validatorName = wrongFunc || '';
                cell.wrongCol = wrongCol || '';

                switch (cell.style) {
                    case 1:
                        temporaryMandatoryCellsAreEmptyOrWrongDataType++;
                        break;
                    case 2:
                        temporaryCellsAreWrongDataTypeOrFormat++;
                        break;
                    case 3:
                        temporaryCellsAreEmptyButNotMandatory++;
                        break;
                    default:
                        break;
                }
            }
        }

        const reducedWrongData = tempWrongData.reduce((uniqueArr, currentObj) => {
            const exists = uniqueArr.some(obj => obj.wrongCol === currentObj.wrongCol && obj.wrongFunc === currentObj.wrongFunc);

            if (!exists) {
                uniqueArr.push(currentObj);
            }

            return uniqueArr;
        }, []);

        setMandatoryCellsAreEmptyOrWrongDataType(temporaryMandatoryCellsAreEmptyOrWrongDataType);
        setCellsAreWrongDataTypeOrFormat(temporaryCellsAreWrongDataTypeOrFormat);
        setCellsAreEmptyButNotMandatory(temporaryCellsAreEmptyButNotMandatory);
        setValidationRows(rows);
        setWrongData(reducedWrongData);
    }

    return (
        <React.Fragment>
            <BrandModal
                className={styles.wrongDataModal}
                open={wrongColumnsModal}
                onClose={() => setWrongColumnsModal(false)}
            >
                <div className={styles.rowsContainer}>
                    {
                        wrongData.length ?
                            wrongData.map((el, i) => {
                                return (
                                    el.wrongFunc[0] === 'required' ?
                                        <div key={`${el.wrongCol}${i}`} className={styles.wrongDataModalRow}>{el.wrongCol} column is required </div>
                                        :
                                        <div key={`${el.wrongCol}${i}`} className={styles.wrongDataModalRow}>{el.wrongCol} column has a {checkError(el.wrongFunc[0])}</div>
                                )
                            })
                            :
                            <div>No wrong data found</div>
                    }
                </div>
            </BrandModal>
            <div className={styles.title}>3. Validation - {state.uploadReportFileName}</div>
            <p className={styles.tableHeaderText}>Problematic entries are listed in the table below. Please edit them and click the &lsquo;Submit&rsquo; button.</p>
            <div className={styles.mistakes}>
                <div>
                    <p className={styles.invalid}>{mandatoryCellsAreEmptyOrWrongDataType} mandatory cells are empty or wrong data type</p>
                    <p className={styles.pkDuplicate}>{cellsAreWrongDataTypeOrFormat} cells are wrong data type or format</p>
                    <p className={styles.mandatory}>{cellsAreEmptyButNotMandatory} cells are empty but not mandatory</p>
                </div>
                <BrandButton
                    onClick={() => setWrongColumnsModal(true)}
                    className={styles.wrongDataButton}
                >
                    WRONG DATA
                </BrandButton>
            </div>
            <div className={styles.mappingTable}>
                <div className={styles.mappingTop}>
                    {mappingTemplate.map((column, index) => {
                        return (<div className={styles.mappingSelect} key={index}>
                            <div className={styles.colHeader} id={column}>
                                <span>{dataMappings[column].required ? column + '*' : column}</span>
                            </div>
                        </div>);
                    })}
                </div>
                <BrandCanvasTable
                    id='redValidatedTableContainer'
                    options={tableOptions}
                    rows={displayRows}
                    onChange={(table, cdata) => {
                        if (!state.findSongsInDb) {
                            if (!retryUpload) {
                                setState(state => (state.findSongsInDb = true, { ...state }))
                            }
                        }
                        const len = cdata.rows.len;
                        delete cdata.rows.len;
                        validateRows(Object.values(cdata.rows));
                        cdata.rows.len = len;
                    }}
                    styles={tableStyles}
                    className={styles.table}
                    style={{ width: mappingTemplate.length * 250 }}
                />
            </div>
        </React.Fragment>
    );
}
