import { Box, Button, Divider, Popover, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useState } from "react";
import Calendar from "./Calendar";
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '2.5px 20px',
        borderRadius: theme.spacing(2.5),
        border: '1px solid gray',
        width: '255px',
        textTransform: 'capitalize'
    },
    buttonIcon: {
        marginLeft: 'auto',
        marginRight: '-10px'
    },
    popoverContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '5px'
    },
    startEndWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        paddingLeft: '18%',
        gap: '285px',
        height: '30px'
    },
    divider: {
        backgroundColor: 'gray'
    },
    optionsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
        margin: '20px 10px 0px 10px',
        alignItems: 'flex-start',
    },
    option: {
        textTransform: 'capitalize',
    },
    selectedBackground: {
        backgroundColor: theme.palette.primary.main,

    }
}));

const CalendarsButton = ({ startDate, setStartDate, endDate, setEndDate, selectedRange, setSelectedRange }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const styles = useStyles();

    return (
        <div>
            <Button className={styles.button} onClick={handleClick}>
                {selectedRange}
                <ExpandMore fontSize='large' className={styles.buttonIcon} />
            </Button>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <div>
                    <Box className={styles.startEndWrapper}>
                        <Box>Start</Box>
                        <Box>End</Box>
                    </Box>
                    <Box className={styles.popoverContentWrapper}>
                        <Calendar
                            date={startDate}
                            setDate={setStartDate}
                            maxDate={endDate}
                            setSelectedRange={setSelectedRange}
                        />
                        <Divider orientation='vertical' flexItem className={styles.divider} />
                        <Calendar
                            date={endDate}
                            setDate={setEndDate}
                            minDate={startDate}
                            setSelectedRange={setSelectedRange}
                        />
                        <Divider orientation='vertical' flexItem className={styles.divider} />
                        <Box className={styles.optionsWrapper}>
                            <Button className={clsx(styles.option, selectedRange == 'All Time' ? styles.selectedBackground : null)} onClick={(e) => setSelectedRange(e.target.innerText)}>All Time</Button>
                            <Button className={clsx(styles.option, selectedRange == 'Last Year' ? styles.selectedBackground : null)} onClick={(e) => setSelectedRange(e.target.innerText)}>Last Year</Button>
                            <Button className={clsx(styles.option, selectedRange == 'Last Quarter' ? styles.selectedBackground : null)} onClick={(e) => setSelectedRange(e.target.innerText)}>Last Quarter</Button>
                            <Button className={clsx(styles.option, selectedRange == 'Custom' ? styles.selectedBackground : null)} onClick={(e)=> setSelectedRange(e.target.innerText)}>Custom</Button>
                        </Box>
                    </Box>
                </div>
            </Popover>
        </div>
    )
};

export default CalendarsButton;