import { Slide, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'justify',
        overflow: 'scroll',
        height: '550px',
        width: '777px',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        fontSize: '14px',
        lineHeight: 1.6,
        '& p': {
            margin: '20px 0 20px 0px'
        },
        overflow: 'scroll',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a:hover': {
            color: theme.palette.primary.main
        },
        '& h3': {
            textAlign: 'center'
        },
        '& header': {
            textAlign: 'justify',
            fontSize: '32px',
            lineHeight: '141%',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0px 20px 0px 20px',
            overflowX: 'hidden',
        },
    },
    personalInformationContainer: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
    },
    subInfo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

        '& p': {
            display: 'flex',
            width: '100%',
            border: '1px solid white',
        },
    },
    tableContainer: {
        width: '100%',
        '& th': {
            width: '50%',
            padding: 20
        },
        '& td': {
            verticalAlign: 'baseline',
            padding: 20
        }
    }
}));

export default function PrivacyPolicy() {
    const styles = useStyles();

    return (
        <Slide
            timeout={500}
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
        >
            <div className={styles.container}>
                <header className={styles.header} >Privacy Policy</header>
                <br />
                <i>Last Updated:</i> 03/23/2023
                <p>Your  privacy  is  very  important  to  us  and  we  take  your  privacy  very  seriously.  This  Privacy Policy describes how Record Financial Inc. (“Company”, “we”, “us”, or “our”) collects and uses the  personal  information  you  provide  to  us  on  our  website  and  all  other  websites,  mobile  sites, applications, platforms and tools where this Privacy Policy appears or is linked, and through the use  of  our  services,  (collectively  the  “Site”).  It  also  describes  the  choices  available  to  you regarding  our  use  of  your  personal  information  and  how  you  can  access  and  update  this information.</p>
                <p>We collect, use and are responsible for certain personal information about you. When we do so we are subject to various laws in the United States, the United Kingdom General Data Protection Regulation,  and  the  General  Data  Protection  Regulation  which  applies  across  the  European Union,  and  we  are  responsible  as  “controller”  of  that  personal  information  for  the  purposes  of those laws.</p>
                <p>By creating an account, providing information to us (by any means, whether in correspondence, via  our  Site,  or  otherwise),  or  continuing  to  use  our  services,  you  acknowledge  that  you  have read, understood, and consent to be bound by this Privacy Policy. </p>
                <p>IF  YOU  DO  NOT  AGREE  WITH  THIS  PRIVACY  POLICY  OR  OUR  PRACTICES,  YOU MAY  NOT  USE  OUR  SITE.  THIS  PRIVACY  POLICY  MAY  CHANGE  FROM  TIME  TO TIME    AND    YOUR    CONTINUED    USE    OF    OUR    SITE    CONSTITUTES    YOUR ACCEPTANCE   OF   THOSE   CHANGES.   WE   ENCOURAGE   YOU   TO   REVIEW   THIS PRIVACY POLICY PERIODICALLY.</p>
                <ol>
                    <li><b>Personal  Information  We  Collect  About  You</b>.  We  may  collect  and  use  the  following personal  information  that  identifies,  relates  to,  describes,  is  reasonably  capable  of  being associated  with,  or  could  reasonably  be  linked,  directly  or  indirectly,  with  a  particular consumer or household (“personal information”):
                        <table border="1" className={styles.tableContainer}>
                            <thead>
                                <tr>
                                    <th><b>Categories of Personal Information</b></th>
                                    <th><b>Specific Types of Personal Information Collected</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Identifiers (e.g., a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers).</td>
                                    <td>Real name, internet protocol address, email address, account name</td>
                                </tr>
                                <tr>
                                    <td>Information that identifies, relates to, describes, or is capable of being associated with, a particular individual, including, but not limited to, his or her name, signature, social security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information.</td>
                                    <td>Real name, email address, account name, and bank account or credit or debit card number through our third party payment providers (however, we do not have access to this information)</td>
                                </tr>
                                <tr>
                                    <td>Characteristics of protected classifications under California or federal law (Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information)).</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Commercial information (e.g., records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies).</td>
                                    <td>Records of personal property.</td>
                                </tr>
                                <tr>
                                    <td>Biometric information (e.g. a retina or iris scan, fingerprint, voiceprint, or scan of hand or face geometry).</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Internet or other electronic network activity information (e.g., browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement).</td>
                                    <td>Information regarding a consumer’s interaction with our Site</td>
                                </tr>
                                <tr>
                                    <td>Geolocation data (e.g. physical location or movements).</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Sensory data (e.g. audio, electronic, visual, thermal, olfactory, or similar information).</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Professional or employment-related information.</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Education information, defined as information that is not publicly available personally identifiable information as defined in the Family Educational Rights and Privacy Act (FERPA) (i.e. education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary </td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Sensitive personal information (e.g. (1) Personal information that reveals: (A) A person’s social security, driver’s license, state identification card, or passport number; (B) A person’s account log-in, financial account, debit card, or credit card number in combination with any required security or access code, password, or credentials allowing access to an account; (C) A person’s precise geolocation; (D) A person’s racial or ethnic origin, religious or philosophical beliefs, or union membership; (E) The contents of a person’s mail, email, and text messages unless the business is the intended recipient of the communication; (F) A person’s genetic data; (2) the processing of biometric information for the purpose of uniquely identifying a consumer; (3) personal information collected and analyzed concerning a consumer’s health, or a personal information collected and analyzed concerning a consumer’s sex life or sexual </td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Inferences drawn from any of the information identified above to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, </td>
                                    <td>None</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>This personal information is required to provide services to you. If you do not provide personal information we ask for, it may delay or prevent us from providing services to you.</p>
                    </li>
                    <li>
                        <b>Cookies and Other Tracking Technologies.</b>
                        <p><i><a href="/cookies" target="_blank" style={{ textDecoration: 'underline' }}>Cookies</a></i></p>
                        <p>Cookies are small text files of information stored by the Internet browser on your computer’s hard drive. We may use these cookies to collect browsing data to keep track of your preferences and profile information and to collect general usage and volume statistical information. Our cookies do not collect personal or confidential information and are not spyware. </p>
                    </li>
                    <p>There are a number of different types of cookies, however, our Site uses:</p>
                    <ul>
                        <li>Essential – These cookies are necessary to the core functionality of our Site and some of its features, such as access to secure areas.</li>
                        <li>Performance and Functionality – These cookies are used to enhance the performance and functionality of our Site. For example, we may use these cookies so that we recognize you on our Site and remember your previously selected preferences. These could include what language you prefer and your geographic location. These cookies are nonessential to the use of our Site, however, without these cookies, certain functionality may become unavailable. A mix of first-party and third-party cookies are used.</li>
                        <li>Advertising – These cookies are used to make advertising messages more relevant to you. They prevent the same ad from continuously reappearing, ensure that ads are properly displayed for advertisers, and in some cases select advertisements that are based on your interests. Our Company sometimes shares online data collected through these cookies with our advertising partners. This means that when you visit another Site, you may be shown advertising based on your browsing patterns on our Site.</li>
                        <li>Analytics and Customization –Our Company uses these cookies and technologies to analyze how the Site is accessed, used, or performing in order to improve your user experience and to maintain, operate and continually improve the Site. For example, we use Google Analytics on the Site to collect: page url/page title and user browser/system information, which includes browser type, referrer, language, java/flash support, IP address, and ad-serving data. For information on how Google Analytics collects and processes data, visit www.google.com/policies/privacy/partners/. To opt-out of Google Analytics, visit Google’s “How you can control the information collected by Google on these sites and apps” article available <a style={{ color: 'blue' }} target="_blank" href="https://policies.google.com/technologies/partner-sites?hl=en-US">here</a>.</li>
                        <li>Social Media – These are cookies used to connect our Site to a third-party social media platform. For example, these cookies enable you to share our Site’s content through third-party social networks and other websites. They remember your details after you sign in to a social media account from a website. These cookies may also be used for advertising purposes. </li>
                        <li>Unclassified – These are cookies that have not yet been categorized. We are in the process of classifying these cookies with the help of their providers.</li>
                    </ul>
                    <p style={{ textDecoration: 'underline' }}><i>Web Beacons</i></p>
                    <p>Our Site contains electronic images known as web beacons (sometimes called single-pixel gifs) and are used along with cookies to compile aggregated statistics to analyze how our Site is used and may be used in some of our emails to let us know which emails and links have been opened by recipients. This allows us to gauge the effectiveness of our customer communications and marketing campaigns. We use a third party to gather information about how you and others use our Site. For example, we will know how many users access a specific page and which links they clicked on. We use this aggregated information to understand and optimize how our Site is used.</p>
                    <p style={{ textDecoration: 'underline' }}><i>Targeted Advertising</i></p>
                    <p>Targeted Advertising (also known as online behavioral advertising) uses information collected on an individual’s web browsing behavior such as the pages they have visited or the searches they have made. Third parties collect this information by placing or accessing cookies in your browser when you visit this Site, or other, websites. If you would like to learn more about the third party advertisers that may be aware of the fact that you visit the Site, and to understand your choices about having such advertisers’ cookies turned off, please visit <a href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a>, <a href="http://www.youronlinechoices.com/" target="_blank">http://www.youronlinechoices.com/</a>, or <a href="http://www.networkadvertising.org/" target="_blank">http://www.networkadvertising.org/</a>.</p>
                    <p style={{ textDecoration: 'underline' }}><i>How to Manage Cookies</i></p>
                    <p>You have the right to decide whether to accept or reject certain cookies. You can set your browser not to accept cookies, and the above websites tells you how to remove cookies from your browser. However, in a few cases, some of our Site features may not function as a result. Essential cookies cannot be rejected, as they are strictly necessary to provide you with our Site.</p>
                    <li>
                        <p><b>Do Not Track</b></p>
                        <p>We do not track users across the web and therefore do not respond to web browser “do not track” signals. </p>
                    </li>
                    <li>
                        <p><b>Promotional Communications</b>. We may use your personal information to send you updates (by email, text message, telephone or post) about our services and Site.</p>
                        <p>We have a legitimate interest in processing your personal information for promotional purposes (see below “How and why we use your personal information”). This means we do not usually need your consent to send you promotional communications. However, where consent is needed, we will ask for this consent separately and clearly.</p>
                        <p>We will always treat your personal information with the utmost respect and never sell or share it with other organizations for marketing purposes.</p>
                        <p>You have the right to opt out of receiving promotional communications at any time by:</p>
                        <ul>
                            <li>Contacting us by using one of the methods listed in the “How to Contact Us” Section below; or</li>
                            <li>Using the “unsubscribe” link in emails or “STOP” number in texts.</li>
                        </ul>
                        <p>We may ask you to confirm or update your marketing preferences if you instruct us to provide further products or services in the future, or if there are changes in the law, regulation, or the structure of our business.</p>
                    </li>
                    <li>
                        <p><b>How Your Personal Information is Collected</b>. We collect most of this personal information directly from you—in person, by telephone, text or email or via our Site. However, we may also collect information:</p>
                        <ul>
                            <li>From publicly accessible sources (e.g., royalty rate databases, Broadcast Music Inc.);</li>
                            <li>From a third party with your consent (e.g., your email provider);</li>
                            <li>From cookies on our Site; and</li>
                            <li>Via our IT systems, including automated monitoring of our Site and other technical systems, such as our computer networks and connections, access control systems, communications systems, email and instant messaging systems.</li>
                        </ul>
                    </li>
                    <li>
                        <p><b>How and Why We Use Your Personal Information</b>. Under data protection law, we can only use your personal information if we have a proper reason for doing so, e.g.,:</p>
                        <ul>
                            <li>To comply with our legal and regulatory obligations;</li>
                            <li>For the performance of our contract with you or to take steps at your request before entering into a contract;</li>
                            <li>For our legitimate interests or those of a third party; or</li>
                            <li>Where you have given consent.</li>
                        </ul>
                        <p>A legitimate interest is when we have a business or commercial reason to use your information, so long as this is not overridden by your own rights and interests.</p>
                        <p>The table below explains what we use (process) your personal information for and our reasons for doing so:</p>
                        <table border="1" className={styles.tableContainer}>
                            <thead>
                                <tr>
                                    <th><b>What we use your personal information</b></th>
                                    <th><b>Our reasons</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>To provide our Site and services to you</td>
                                    <td>For the performance of our contract with you or to take steps at your request before entering into a contract</td>
                                </tr>
                                <tr>
                                    <td>To prevent and detect fraud against you or Record Financial Inc.</td>
                                    <td>For our legitimate interests or those of a third party, i.e. to minimize fraud that could be damaging for us and for you</td>
                                </tr>
                                <tr>
                                    <td>Ensuring business policies are adhered to, e.g. policies covering security and internet use</td>
                                    <td>For our legitimate interests or those of a third party, i.e. to make sure we are following our own internal procedures so we can deliver the best service to you</td>
                                </tr>
                                <tr>
                                    <td>Operational reasons, such as improving efficiency, training and quality control</td>
                                    <td>For our legitimate interests or those of a third party, i.e. to be as efficient as we can so we can deliver the best service for you at the best price</td>
                                </tr>
                                <tr>
                                    <td>Ensuring the confidentiality of commercially sensitive information</td>
                                    <td>For our legitimate interests or those of a third party, i.e. to protect trade secrets and other commercially valuable information
                                        <br />
                                        <br />
                                        To comply with our legal and regulatory obligations</td>
                                </tr>
                                <tr>
                                    <td>Statistical analysis to help us manage our business, e.g. in relation to our performance, customer base, product range or other efficiency measures</td>
                                    <td>For our legitimate interests or those of a third party, i.e. to be as efficient as we can so we can deliver the best service for you at the best price</td>
                                </tr>
                                <tr>
                                    <td>Preventing unauthorized access and modifications to systems</td>
                                    <td>For our legitimate interests or those of a third party, i.e. to prevent and detect criminal activity that could be damaging for us and for you
                                        <br />
                                        <br />
                                        To comply with our legal and regulatory obligations</td>
                                </tr>
                                <tr>
                                    <td>Updating and enhancing customer records</td>
                                    <td>For the performance of our contract with you or to take steps at your request before entering into a contract
                                        <br />
                                        <br />
                                        To comply with our legal and regulatory obligations
                                        <br />
                                        <br />
                                        For our legitimate interests or those of a third party, e.g. making sure that we can keep in touch with our customers about existing orders and new products</td>
                                </tr>
                                <tr>
                                    <td>Marketing our services to:
                                        <br />
                                        <br />
                                        - existing and former customers;
                                        <br />
                                        <br />
                                        - third parties who have previously expressed an interest in our services;
                                        <br />
                                        <br />
                                        - third parties with whom we have had no previous dealings.</td>
                                    <td>For our legitimate interests or those of a third party, i.e. to promote our business to existing and former customers</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <p>The above table does not apply to special or sensitive categories of personal information, which we will only process with your explicit consent.</p>
                        <p>We may also anonymize, aggregate or de-identify personal information so the end-product does not identify you or any other individual. For example, we may use this information to generate norms by industry, geography, level, etc., enable us to understand where our services are being utilized, conduct ongoing validation studies, compile reports, and improve the services. Such aggregated, anonymized or de-identified information is not considered personal information for purposes of this Privacy Policy and we may use it for any purpose.</p>
                    </li>
                    <li>
                        <p><b>Who We Share Your Personal Information With</b>. We routinely share personal information with:</p>
                        <ul>
                            <li>Service providers we use to help deliver our services to you, such as software-as-a-service platforms, database management companies, website hosts, and third-party payment providers;</li>
                            <li>Other third parties we use to help us run our business, such as marketing agencies;</li>
                            <li>Third parties approved by you, including sites you choose to link your account to (e.g. Broadcast Music Inc. and email providers);</li>
                        </ul>
                        <p>We may disclose and exchange information with law enforcement agencies and regulatory bodies to comply with our legal and regulatory obligations.</p>
                        <p>We may also need to share some personal information with other parties, such as potential buyers of some or all of our business or during a re-structuring. We will typically anonymize information, but this may not always be possible. The recipient of the information will be bound by confidentiality obligations.</p>
                        <p>We will not share your personal information with any other third party without your consent.</p>
                    </li>
                    <li>
                        <p><b>How Long Your Personal Information Will Be Kept</b>. We will keep your personal information while you have an account with us or while we are providing services to you. Thereafter, we will keep your personal information for as long as is necessary:</p>
                        <ul>
                            <li>To respond to any questions, complaints or claims made by you or on your behalf;</li>
                            <li>To show that we treated you fairly; or</li>
                            <li>To keep records required or permitted by law (including for dispute resolution).</li>
                        </ul>
                        <p>We will not retain your personal information for longer than necessary for the purposes set out in this Privacy Policy. Different retention periods apply for different types of personal information but no information will be kept longer than 1 year after our services with you have terminated. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of the personal information, the purposes for which we process your personal information, and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
                        <p>When we no longer require your personal information, we will either delete or deidentify it or, if this is not possible, we will securely store it in accordance with this policy and cease use of the personal information until deletion is possible. If we deidentify your personal information (so that it is no longer associated with you), we may retain this information for longer periods. </p>
                    </li>
                    <li>
                        <p><b>Children and our Site</b>.</p>
                        <p>Our Site is not directed to children, and you may not use our Site if you are under the age of 18. If you are under 18, do not use our services, access the Site, or provide any information about yourself including, without limitation, your name, address, email address or any screen name or user name you may use. If we learn that we have collected or received personal information from a child under 13 without verification of parental consent, in compliance with the Children’s Online Privacy Protection Act, we will purge such information from our database and cancel the corresponding accounts. If you believe we may have any information from or about a child under 13, please see our “How to Contact Us” Section below. Please visit the FTC's website at www.ftc.gov for tips on protecting children's privacy online.</p>
                    </li>
                    <li>
                        <p><b>Keeping Your Personal Information Secure</b>. We use reasonable and appropriate physical, technical, and administrative safeguards to protect your information from unauthorized use, access, loss, misuse, alteration, or destruction. We endeavor to protect the personal information we receive, gather and store, by such means as password protection, firewalls and other means. We limit access to your personal information to those who have a genuine business need to access it. Those processing your information will do so only in an authorized manner and are subject to a duty of confidentiality. We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.</p>
                        <p>However, while we strive to protect your privacy, due to many reasons (including inherent security flaws in the internet), transmissions over the internet are never entirely secure and <b>we cannot guarantee the security of information you disclose to us</b>. As such, you agree any such disclosure of information is at your own risk.</p>
                    </li>
                    <li>
                        <p><b>Where Your Personal Information is Held</b>. Information may be held at our offices and those of our third party agencies, service providers, representatives and agents as described above (see above: “Who We Share Your Personal Information with”).</p>
                        <p>Some of these third parties may be based outside the country where we originally collected your personal information. For more information, including on how we safeguard your personal information when this occurs, see below: “International Transfers of Personal Information”.</p>
                    </li>
                    <li>
                        <p><b>International Transfers of Personal Information</b>. To bring you our Site, we operate globally. Therefore, it is sometimes necessary for us to share your personal information outside the country where we originally collected your personal information, e.g.:</p>
                        <ul>
                            <li>With our offices in the United States;</li>
                            <li>With your and our service providers located throughout the world;</li>
                            <li>If you are based outside the EEA; or</li>
                            <li>Where there is an international dimension to the services we are providing to you.</li>
                        </ul>
                        <p>These transfers may be subject to special rules under local data protection law.</p>
                        <p>These outside countries may not have the same data protection laws as the country where we originally collected the personal information. In particular, you are advised that the United States of America uses a sectoral model of privacy protection that relies on a mix of legislation, governmental regulation, and self-regulation. We will, however, ensure the transfer complies with data protection law and all personal information will be secure. If you are located outside the United States, we will only transfer your personal information if:</p>
                        <ul>
                            <li>you provide your explicit consent;</li>
                            <li>the country to which the personal information will be transferred has been granted an appropriate adequacy decision by your local supervisory authority; or</li>
                            <li>we have put in place appropriate safeguards in respect of the transfer, for example we have entered into the appropriate standard contractual clauses and required additional safeguards with the recipient, or the recipient is a party to binding corporate rules approved by a supervisory authority.</li>
                        </ul>
                        <p>Our Company is headquartered in the United States and utilizes service providers both in the United States and throughout the world. As such, our Company and our service providers may transfer your personal information to, or access it in, jurisdictions that may not provide equivalent levels of data protection as your home jurisdiction.</p>
                        <p>Where the laws of your country allow you to do so, by using the Site or our services, you consent and authorize our Company to transfer, store, and use all such information in the United States of America and any other country where we operate which may not offer an equivalent level of protection to that required in the country where you reside and to the processing of that information by us on its servers located in the United States of America as described in this Privacy Policy. If you do not want your personal information transferred to the United States of America and any other country where we operate, please do not submit any information to us or use our services.</p>
                        <p>If you would like further information, please contact us (see “How To Contact Us” below).</p>
                    </li>
                    <li>
                        <p><b>Your Rights and Choices.</b></p>
                        <p>If you are located in the European Economic Area or if you are a resident of California, Colorado, Connecticut, Iowa, Virginia, or Utah please go to our Privacy Notice Addendum to learn about additional rights you may have under applicable data protection laws.</p>
                    </li>
                    <li>
                        <p><b>Third Party Websites.</b></p>
                        <p>If, in your interactions with the Site, you are linked or directed to, or click on, a third party website, we cannot control what information you may provide to that party or on that website, and we are not responsible for how that party may use or disclose any information you may provide to them. This is not as an endorsement by us of any third party website, content that may be offered on such third party website, or of any products or services provided by such third party. We do not control, nor are we responsible for, such third party website, product or service offerings. As such, we urge that you exercise caution before providing them with your personal information and to review the third party’s privacy policy for information on its data processing practice. </p>
                        <p>You should contact the site administrator for such third party website if you have any complaints, claims, concerns or questions regarding such third party website or its privacy practices.</p>
                    </li>
                    <li>
                        <p><b>Changes to This Privacy Notice</b>. This Privacy Policy was published on the date “Last Updated” above.</p>
                        <p>We may change this Privacy Policy from time to time. Changes to this Privacy Policy will be made by updating this page. Please visit this Privacy Policy regularly to read the current version.</p>
                    </li>
                    <li>
                        <p><b>How to Contact Us</b>. Please contact us if you have any questions about this Privacy Policy or the information we hold about you.</p>
                        <p><a href="/contact">[Contact Form]</a></p>
                    </li>
                </ol>
                <h3>Privacy Notice Addendum</h3>
                <p>This Privacy Notice Addendum (“Notice”) supplements the information contained in Record Financial Inc.’s Privacy Policy and applies solely to all visitors, users, and others who are located in the European Economic Union or who reside in the State of California, Colorado, Connecticut, Iowa, Virginia, or Utah ("consumers" or "you"). We adopt this Notice to comply with the General Data Protection Regulation (EU) 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (GDPR), California Consumer Privacy Act of 2018 (CCPA), the California Privacy Rights Act of 2020 (CPRA), the Colorado Privacy Act of 2021, the Connecticut Data Privacy Act of 2022, the Iowa Consumer Data Protection Act of 2023, the Utah Consumer Privacy Act of 2022, the Virginia Consumer Data Protection Act of 2023, and certain other privacy and data protection laws, as applicable (collectively, the " Statutes"). Any terms defined in the Statutes will have the same meaning when used in this Notice.</p>
                <p>As further set forth in our Privacy Policy, may collect certain information that is (i) linked or reasonably linkable to an identified or identifiable individual or natural person, or (ii) that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a consumer or household, or (iii) as further defined in the Statutes as “Personal Data” or “Personal Information.”</p>
                <ol>
                    <li>
                        <p><b>Your Rights Under the GDPR.</b></p>
                        <p>In some regions (like the European Economic Area), you have certain rights under applicable data protection laws.</p>
                        <table border="1" className={styles.tableContainer}>
                            <tbody>
                                <tr>
                                    <td>Right to Access</td>
                                    <td>The right to be provided with a copy of your personal information (the right of access)</td>
                                </tr>
                                <tr>
                                    <td>Right to Rectification</td>
                                    <td>The right to require us to correct any mistakes in your personal information</td>
                                </tr>
                                <tr>
                                    <td>Right to be Forgotten</td>
                                    <td>The right to require us to delete your personal information—in certain situations</td>
                                </tr>
                                <tr>
                                    <td>Right to Restriction of Processing</td>
                                    <td>The right to require us to restrict processing of your personal information—in certain circumstances, e.g. if you contest the accuracy of the data</td>
                                </tr>
                                <tr>
                                    <td>Right to Data Portability</td>
                                    <td>The right to receive the personal information you provided to us, in a structured, commonly used and machine-readable format and/or transmit that data to a third party—in certain situations</td>
                                </tr>
                                <tr>
                                    <td>Right to Object</td>
                                    <td>The right to object:
                                        <br />
                                        <br />
                                        - at any time to your personal information being processed for direct marketing (including profiling);
                                        <br />
                                        <br />
                                        - in certain other situations to our continued processing of your personal information, e.g. processing carried out for the purpose of our </td>
                                </tr>
                                <tr>
                                    <td>Right Not to be Subject to Automated Individual Decision-Making</td>
                                    <td>The right not to be subject to a decision based solely on automated processing (including profiling) that produces legal effects concerning you or similarly significantly affects you</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>For further information on each of those rights, including the circumstances in which they apply, see the guidance from the UK Information Commissioner's Office (ICO) on individual rights under the General Data Protection Regulation.</p>
                    </li>
                    <li>
                        <p><b>Your Rights Under the CCPA and CPRA</b>. You may have the right under the California Consumer Privacy Act of 2018 (CCPA), the California Privacy Rights Act of 2020 (CPRA), and certain other privacy and data protection laws, as applicable, to exercise free of charge:</p>
                        <table border="1" className={styles.tableContainer}>
                            <tbody>
                                <tr>
                                    <td>Disclosure of Personal Information We Collect About You</td>
                                    <td>You have the right to know:
                                        <ul>
                                            <li>The categories of personal information we have collected about you;</li>
                                            <li>The categories of sources from which the personal information is collected;</li>
                                            <li>Our business or commercial purpose for collecting or selling personal information;</li>
                                            <li>The categories of third parties with whom we share personal information, if any; and</li>
                                            <li>The specific pieces of personal information we have collected about you.</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Personal Information Sold, Shared, Disclosed, or Used for a Business Purpose</td>
                                    <td>In connection with any personal information we may sell or disclose to a third party for a business purpose, you have the right to know:
                                        <ul>
                                            <li>The categories of personal information about you that we sold and the categories of third parties to whom the personal information was sold; and</li>
                                            <li>The categories of personal information that we disclosed about you for a business purpose.</li>
                                        </ul>
                                        <p>We do not sell or share your personal</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Right to Limit Use and Disclosure of Sensitive Personal Information.</td>
                                    <td>You have the right to opt-out of the use and disclosure of your sensitive personal information for anything other than supplying requested goods or services.
                                        <br />
                                        <br />
                                        We do not collect sensitive personal information.</td>
                                </tr>
                                <tr>
                                    <td>Right to Correction</td>
                                    <td>You have the right to request correction of inaccurate personal information maintained by us about you. Upon receipt of a verifiable request from you, we will use commercially reasonable efforts to correct the inaccurate personal information.</td>
                                </tr>
                                <tr>
                                    <td>Right to Deletion</td>
                                    <td>Subject to certain exceptions set out by statute, on receipt of a verifiable request from you, we will:
                                        <ul>
                                            <li>Delete your personal information from our records; and</li>
                                            <li>Direct any service providers to delete your personal information from their records.</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Protection Against Discrimination</td>
                                    <td>
                                        You have the right to not be discriminated against by us because you exercised any of your rights under the CCPA. This means we cannot, among other things:
                                        <ul>
                                            <li>Deny goods or services to you;</li>
                                            <li>Charge different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties;</li>
                                            <li>Provide a different level or quality of goods or services to you; or</li>
                                            <li>Suggest that you will receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                                        </ul>
                                        <p>Please note that we may charge a different price or rate or provide a different level or quality of services to you, if that difference is reasonably related to the value provided to our business by your personal information.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>
                        <p><b>Personal Information We Sold or Disclosed for a Business Purpose.</b></p>
                        <p>In the preceding 12 months, we have not sold to any third parties any category of personal information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household.</p>
                        <p>In the preceding 12 months, we have disclosed for a business purpose to one or more third parties the following categories of personal information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household:</p>
                        <ul>
                            <li>Identifiers (e.g., a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers);</li>
                            <li>Information that identifies, relates to, describes, or is capable of being associated with, a particular individual, including, but not limited to, his or her name, signature, social security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information;</li>
                            <li>Commercial information (e.g., records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies);</li>
                            <li>Internet or other electronic network activity information (e.g., browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement);</li>
                        </ul>
                    </li>
                    <li>
                        <p><b>Your Rights Under the Colorado Privacy Act </b></p>
                        <p>The Colorado Privacy Act provides consumers who are Colorado residents with specific rights regarding their Personal Data. This section describes your rights under the Colorado Privacy Act and explains how to exercise those rights.</p>
                        <table border="1" className={styles.tableContainer}>
                            <tbody>
                                <tr>
                                    <td>Right to Opt-Out</td>
                                    <td>You have the the right to opt out of the processing of Personal Data concerning you for the purposes of:
                                        <ul>
                                            <li> Targeted Advertising;</li>
                                            <li>The Sale of Personal Data; or</li>
                                            <li>Profiling in furtherance of decisions that produce legal or similarly significant effects concerning the consumer.</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Right of Access</td>
                                    <td>You have the right to confirm whether we are processing Personal Data concerning you and to access your Personal Data.</td>
                                </tr>
                                <tr>
                                    <td>Right to Correction</td>
                                    <td>You have the right to correct inaccuracies in your Personal Data, taking into account the nature of the Personal Data and the purposes of the processing of your Personal Data.</td>
                                </tr>
                                <tr>
                                    <td>Right to Deletion</td>
                                    <td>You have the right to delete Personal Data concerning you.</td>
                                </tr>
                                <tr>
                                    <td>Right to Data Portability</td>
                                    <td>When exercising the right to access Personal Data, you have the right to obtain the Personal Data in a portable, and to the extent technically feasible, readily usable format that allows you to transmit the data to another entity without hindrance.
                                        <br />
                                        <br />
                                        You may exercise this right no more than two times per calendar year</td>
                                </tr>
                                <tr>
                                    <td>Right to Appeal</td>
                                    <td>We hope that we can resolve any query or concern you raise about our use of your Personal Data. However, if we do not take action on your request to exercise any of your rights, we will inform you without undue delay after the receipt of the request for not taking action. You may appeal any decision we have made about your request by following the instructions in the communication you receive from us notifying you of our decision.
                                        <br />
                                        <br />
                                        The Colorado Privacy Act also gives you right to contact the Colorado Attorney General if you have concerns about the results of your appeal.</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>
                        <p><b>Your Rights Under the Connecticut Data Privacy Act of 2022</b></p>
                        <p>The Connecticut Data Privacy Act of 2022 provides consumers who are Connecticut residents with specific rights regarding their Personal Information. This section describes your rights under the Connecticut Data Privacy Act of 2022 and explains how to exercise those rights.</p>
                        <table border="1" className={styles.tableContainer}>
                            <tbody>
                                <tr>
                                    <td>Right to Access</td>
                                    <td>You have the right to confirm whether or not we are processing your Personal Data and access such Personal Data.</td>
                                </tr>
                                <tr>
                                    <td>Right to Correction</td>
                                    <td>You have the right to correct inaccuracies in your Personal Data, taking into account the nature of the Personal Data and the purposes of the processing of your Personal Data.</td>
                                </tr>
                                <tr>
                                    <td>Right to Deletion</td>
                                    <td>You have the right to request that we delete Personal Data provided by, or obtained about, you.</td>
                                </tr>
                                <tr>
                                    <td>Right to Data Portability</td>
                                    <td>You have the right to obtain a copy of your Personal Data processed by us, in a portable and, to the extent technically feasible, readily usable format that allows you to transmit the data to another controller without hindrance, where the processing is carried out by automated means.</td>
                                </tr>
                                <tr>
                                    <td>Right to Opt-Out</td>
                                    <td>You have the right to opt out of the processing of the Personal Data for purposes of:
                                        <ul>
                                            <li>Targeted advertising;</li>
                                            <li>The sale of personal data; or </li>
                                            <li>Profiling in furtherance of solely automated decisions that produce legal or similarly significant effects</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Right to Appeal</td>
                                    <td>We hope that we can resolve any query or concern you raise about our use of your Personal Data. However, if we do not take action on your request to exercise any of your rights, we will inform you without undue delay after the receipt of the request for not taking action. You may appeal any decision we have made about your request by following the instructions in the communication you receive from us notifying you of our decision.</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>
                        <p><b>Your Rights Under the Iowa Consumer Data Protection Act of 2023</b></p>
                        <p>The Iowa Consumer Data Protection Act of 2023 provides consumers who are Iowa residents with specific rights regarding their Personal Information. This section describes your rights under the Iowa Consumer Data Protection Act of 2023 and explains how to exercise those rights.</p>
                        <table border="1" className={styles.tableContainer}>
                            <tbody>
                                <tr>
                                    <td>Right to Access</td>
                                    <td>You have the right to confirm whether we are processing your Personal Data and to access such Personal Data.
                                        <br />
                                        <br />
                                        You may exercise this right no more than twice annually.</td>
                                </tr>
                                <tr>
                                    <td>Right to Delete</td>
                                    <td>You have the right to request we delete Personal Data provided by you.</td>
                                </tr>
                                <tr>
                                    <td>Right to Data Portability</td>
                                    <td>You have the right obtain a copy of your Personal Data that you previously provided to us in a portable and, to the extent technically practicable, readily usable format that allows you to transmit the data to another controller without hindrance, where the processing is carried out by automated</td>
                                </tr>
                                <tr>
                                    <td>Right to Opt-Out</td>
                                    <td>You have the right to opt out of the sale of Personal Data.
                                        <br />
                                        <br />
                                        We do not sell your Personal Data.</td>
                                </tr>
                                <tr>
                                    <td>Right to Appeal</td>
                                    <td>We hope that we can resolve any query or concern you raise about our use of your Personal Data. However, if we do not take action on your request to exercise any of your rights, we will inform you without undue delay after the receipt of the request for not taking action. You may appeal any decision we have made about your request by following the instructions in the communication you receive from us notifying you of our decision.
                                        <br />
                                        <br />
                                        The Iowa Consumer Data Protection Act of 2023 also gives you right to contact the Iowa Attorney General if you have concerns about the results of your appeal.</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>
                        <p><b>Your Rights Under the Utah Consumer Privacy Act of 2022</b></p>
                        <p>The Utah Consumer Privacy Act of 2022 provides consumers who are Utah residents with specific rights regarding their Personal Information. This section describes your rights under the Utah Consumer Privacy Act of 2022 and explains how to exercise those rights.</p>
                        <table border="1" className={styles.tableContainer}>
                            <tbody>
                                <tr>
                                    <td>Right to Access</td>
                                    <td>You have the right to confirm whether we are processing your Personal Data and access your Personal Data.</td>
                                </tr>
                                <tr>
                                    <td>Right to Delete</td>
                                    <td>You have the right to delete your Personal Data that you provided to us.</td>
                                </tr>
                                <tr>
                                    <td>Right to Data Portability</td>
                                    <td>You have the right to obtain a copy of your Personal Data that you previously provided to us, in a format that:
                                        <ul>
                                            <li>To the extent technically feasible, is portable;</li>
                                            <li>To the extent practicable, is readily usable; and</li>
                                            <li>Allows you to transmit the data to another controller without impediment, where the processing is carried out by automated means.</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Right to Opt-Out</td>
                                    <td>You have the right to opt-out of the processing of your Personal data for purposes of:
                                        <ul>
                                            <li>Targeted advertising; or</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>
                        <p><b>Your Rights Under the Virginia Consumer Data Protection Act of 2023</b></p>
                        <p>The Virginia Consumer Data Protection Act of 2023 provides consumers who are Virginia residents with specific rights regarding their Personal Information. This section describes your rights and explains how to exercise those rights under the Virginia Consumer Data Protection Act of 2023.</p>
                        <table border="1" className={styles.tableContainer}>
                            <tbody>
                                <tr>
                                    <td>Right to Access</td>
                                    <td>You have the right to confirm whether or not we are processing your Personal Data and to access such Personal Data.
                                        <br />
                                        <br />
                                        You may exercise this right no more than twice annually.</td>
                                </tr>
                                <tr>
                                    <td>Right to Correction</td>
                                    <td>You have the right to correct inaccuracies in your Personal Data, taking into account the nature of the Personal Data and the purposes of the processing of your Personal Data.</td>
                                </tr>
                                <tr>
                                    <td>Right to Delete</td>
                                    <td>You have the right to request we delete Personal Data provided by or obtained about you.</td>
                                </tr>
                                <tr>
                                    <td>Right to Data Portability</td>
                                    <td>You have the right to obtain a copy of your Personal Data that you previously provided to us in a portable and, to the extent technically feasible, readily usable format that allows you to transmit the data to another controller without hindrance, where the processing is carried out by automated </td>
                                </tr>
                                <tr>
                                    <td>Right to Opt-Out</td>
                                    <td>
                                        <ul>You have the right to opt out of the processing of the Personal Data for purposes of:
                                            <li>Targeted advertising;</li>
                                            <li>The sale of personal data; or </li>
                                            <li>Profiling in furtherance of decisions that produce legal or similarly significant effects concerning the </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Right to Appeal</td>
                                    <td>We hope that we can resolve any query or concern you raise about our use of your Personal Data. However, if we do not take action on your request to exercise any of your rights, we will inform you without undue delay after the receipt of the request for not taking action. You may appeal any decision we have made about your request by following the instructions in the communication you receive from us notifying you of our decision.
                                        <br />
                                        <br />
                                        The Virginia Consumer Data Protection Act of 2023 also gives you right to contact the Virginia Attorney General if you have concerns about the results of your appeal.</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>
                        <p><b>How to Exercise Your Rights</b>. If you would like to exercise any of your rights as described in this Privacy Policy, please:</p>
                        <ul>
                            <li>Call us, toll-free, at [toll-free phone number]; or</li>
                            <li>Contact us by email or mail (see “How To Contact Us” below).</li>
                        </ul>
                        <p>Please note that you may only make a CCPA-related data access or data portability disclosure request twice within a 12-month period.</p>
                        <p>If you choose to contact us directly by email/phone/in writing, you will need to provide us with:</p>
                        <ul>
                            <li>Enough information to identify you (e.g., your full name, address and customer or matter reference number);</li>
                            <li>Proof of your identity and address (e.g., a copy of your driving license or passport and a recent utility or credit card bill); and</li>
                            <li>A description of what right you want to exercise and the information to which your request relates.</li>
                        </ul>
                        <p>You may use a representative, called an "authorized agent," to submit a request to us. An authorized agent means a natural person, or a business entity registered with the California Secretary of State, that you have authorized to act on your behalf. In order to protect your privacy, Company requires you to confirm that you have provided the authorized agent permission to submit the request and you must provide the authorized agent with signed permission. "Signed" means that the written attestation, declaration, or permission has either been physically signed or provided electronically pursuant to the Uniform Electronic Transactions Act. An authorized agent that has power of attorney pursuant to California Probate Code section 4121 to 4130 must submit proof of statutory power of attorney, but consumer verification is not required. Company may deny a request from an authorized agent that does not submit proof that they have been authorized to act on your behalf. Requests submitted by an authorized agent will still require verification of the person who is the subject of the request in accordance with the process described above. </p>
                        <p>We are not obligated to make a data access or data portability disclosure if we cannot verify that the person making the request is the person about whom we collected information, or is someone authorized to act on such person’s behalf.</p>
                        <p>Any personal information we collect from you to verify your identity in connection with you request will be used solely for the purposes of verification.</p>
                    </li>
                    <li>
                        <p><b>How to File a GDPR Complaint</b>. We hope that we can resolve any query or concern you raise about our use of your information.</p>
                        <p>The General Data Protection Regulation also gives you right to lodge a complaint with a supervisory authority, in the European Union (or European Economic Area) state where you work, normally live, or where any alleged infringement of data protection laws occurred. The current list of supervisory authorities who may be contacted are provided here: <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en" target="_blank">https://edpb.europa.eu/about-edpb/about-edpb/members_en</a> .</p>
                    </li>
                </ol>
            </div>
        </Slide>
    )
}
