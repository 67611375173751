import recordLogo from '../../assets/recordLogo.svg'
import React, { useEffect, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { BrandInput } from '../CoreComponents/BrandInput';
import { textIsEmpty, textIsEmail } from '../utils/ValidationUtils';
import {  TempBrandButton } from '../CoreComponents/BrandButton';
import { postData } from '../utils/FetchUtils';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/BrandSelect';

const dataValidators = {
    validateEmail: (value) => {
        const errors = []
        if (!textIsEmail(value)) {
            errors.push('Invalid mail.')
        }
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    }
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: 'black',
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logo: {
        maxWidth: '100%',
        height: '70px'
    },
    moto: {
        textAlign: 'center',
        fontSize: '28px',
        letterSpacing: '2px',
    },
    submitInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    submitButton: {
        [theme.breakpoints.down('xs')]: {
            width: '300px'
        }
    },
    input: {
        width: '300px',
        '& .MuiInputBase-input': {
            textAlign: 'center'
        }
    },
    dropdownMenu: {
        '& .MuiSelect-root': {
            width: '100px',
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                width: '240px'
            }
        }
    },
    dropdownDefaultValue: {
        color: "#616161",
        font: 'normal normal normal 16px Roboto'
    },
    textField: {
        width: '570px',
        height: '150px',
        border: '1px solid #616161',
        borderRadius: '15px',
        marginTop: '20px',
        '& .MuiInputBase-root': {
            '& textarea': {
                padding: '20px'
            },
        },
        [theme.breakpoints.down('xs')]: {
            width: '300px',
            height: '200px'
        }
    },
    interestSuccessful: {
        marginTop: '20px'
    }
}));

export default function SplashPage() {
    const styles = useStyles();
    const emailPassed = useState(true);
    const email = useState('');
    const [error, setError] = useState('')
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const dropDownValues = useState(['Artist', 'Manager', 'Record Label', 'Other']);
    const selectedOption = useState(0);
    const [textFieldValue, setTextFieldValue] = useState('')
    const [textSuccessfulSent, setTextSuccessfulSent] = useState(false)

    const textFieldHandler = (e) => {
        setTextFieldValue(e.target.value)
    }

    const inputProps = {
        maxLength: 250,
    };

    const submission = (e) => {
        e.preventDefault();
        const interest = selectedOption[0] === 'Other' ? textFieldValue : selectedOption[0]
        const user = {
            email: email[0],
            interest: interest,
        }

        postData(process.env.REACT_APP_SERVER_HOST + '/api/user/save-splashpage-visitor', user)
            .then(res => {
                setTextSuccessfulSent(true)
            }, error => {
                setError(error.message);
                setTextSuccessfulSent(false)
                console.log(error.message);
            })

        setTextFieldValue('')
        email[1]('')
        selectedOption[1](0)
        setSubmitDisabled(true)
    }

    useEffect(() => {
        if (!emailPassed[0] || email[0] === '') {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [emailPassed[0]])

    useEffect(() => {
        setError('');
    }, [email[0]])

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <img src={recordLogo} alt='take-record-logo' className={styles.logo}></img>
                <p className={styles.moto}>we cover your assets</p>
                <div className={styles.submitInfoContainer}>
                    <BrandInput
                        required
                        $value={email}
                        validator={dataValidators.validateEmail}
                        validatorPassed={emailPassed}
                        placeholder='your@email.com'
                        classes={{ input: styles.input }}
                    />
                    <div className={styles.dropdownMenu}>
                        <BrandSelect $value={selectedOption}>
                            <BrandMenuItem className={styles.defaultOption} value={0}>
                                <em className={styles.dropdownDefaultValue}>I am ...</em></BrandMenuItem>
                            {dropDownValues[0].map(x => (
                                <BrandMenuItem key={x} value={x}>
                                    {x}
                                </BrandMenuItem>
                            ))}
                        </BrandSelect>
                    </div>
                    <TempBrandButton
                        className={styles.submitButton}
                        disabled={submitDisabled}
                        onClick={submission}
                    >
                        Submit
                    </TempBrandButton>
                </div>
                {
                    textSuccessfulSent ? <div className={styles.interestSuccessful}>Thank you for your interest! Your contact was saved for future needs.</div> : ''
                }
                {
                    error.includes('Visitor email added!') ? <div className={styles.interestSuccessful}>Visitor email is already added!</div> : ''
                }
                {
                    selectedOption[0] === 'Other' ?
                        <div>
                            <TextField
                                multiline
                                autoFocus
                                placeholder='Type here ...'
                                className={styles.textField}
                                onChange={textFieldHandler}
                                value={textFieldValue}
                                InputProps={{ disableUnderline: true }}
                                inputProps={inputProps} />
                        </div>
                        :
                        null
                }
            </div>
        </div>
    );
}