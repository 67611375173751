import { Button, makeStyles, TableCell, TableRow, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { BrandModal } from "../CoreComponents/BrandModal";
import MissingRevenueIssueModal from "./MissingRevenueIssueModal";
import RegistrationForm from "./RegistrationForm";
import { useStoreContext } from "../../store/Store";
import { reportNames } from "../utils/ReportNamesUtils";
import CloseModal from "./CloseModal";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiTableHead-root': {
            height: '50px',
            backgroundColor: theme.palette.primary.border
        }
    },
    root: {
        textDecoration: 'none',
        '& .MuiTableCell-root': {
            border: 'none'
        },
    },
    tableCell: {
        padding: '10px 0px 0px 10px',
        border: 'none',
        font: 'normal normal light 16px/32px Roboto',
    },
    cellTitle: {
        padding: '0px 0px 0px 10px',
        border: 'none',
        font: 'normal normal bold 16px/32px Roboto',
    },
    cellData: {
        font: 'normal normal 12px/32px Roboto',
    },
    tableCellData: {
        marginBottom: '24px'
    },
    songTitleCell: {
        width: '25%'
    },
    buttonView: {
        width: '160px',
        marginBottom: '10px',
        font: 'normal normal  14px/32px Roboto !important',
        textTransform: 'uppercase !important',
        borderRadius: '20px',
        '& .MuiButton-label': {
            borderRadius: '20px',
        },
    },
    resolveButton: {
        backgroundColor: theme.palette.primary.alert,
        '& .MuiButton-label': {
            borderRadius: '20px',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.alert,
            border: `1px solid ${theme.palette.primary.alert}`
        },
        '& .MuiButton-label:hover': {
            backgroundColor: `${theme.palette.primary.alert}`
        },
    },
    adminResolveButton: {
        backgroundColor: theme.palette.primary.main,
        '& .MuiButton-label': {
            color: theme.palette.background.default,
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`
        },
        '& .MuiButton-label:hover': {
            backgroundColor: `${theme.palette.primary.main}`
        },
    },
    closeButton: {
        backgroundColor: theme.palette.background.default,
        '& .MuiButton-label': {
            color: `${theme.palette.text.primary}`,
            backgroundColor: theme.palette.background.default,
            border: '1px solid white'
        },
        '& .MuiButton-label:hover': {
            backgroundColor: theme.palette.primary.border
        },
    },
    showMoreButton: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        gap: '8px'
    },
    missingRevenueDotContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
    },
    revenueDot: {
        color: theme.palette.primary.warning,
        fontSize: '1rem'
    },
    songStatusRegisterdDot: {
        color: theme.palette.primary.main,
        fontSize: '1rem'
    },
    btnContainer: {
        display: 'flex',
        gap: '20px'
    }

}));

const missingCasesStatuses = {
    CLOSE: 'close',
    PENDING: 'pending',
    ADDED: 'added',
    RESOLVE: 'resolved',
    REPORT: 'report'
}

function Row(props) {
    const styles = useStyles();
    const { initialRowData } = props;
    const { collapseCellTitles } = props;
    const { data, fetchData } = props;

    const [state, setState] = useStoreContext();
    const [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [clickedButton, setClickedButton] = useState('')
    const [modalChange, setModalChange] = useState(null);
    const [selectedRow, setSelectedRow] = useState({});
    const [parsedMissingPeriods, setParsedMissingPeiods] = useState([]);
    const [rowData, setRowData] = useState(initialRowData);
    const [issueReportedState, setIssueReportedState] = useState(null);

    const reportAndResolveHandler = (collapsibleTableSelectedRowData, parsedPeriods, buttonValue) => {
        setSelectedRow(collapsibleTableSelectedRowData);
        setClickedButton(buttonValue);
        setShowModal(true);
        collapsibleTableSelectedRowData.missingPeriods !== 'null' ? setModalChange(true) : setModalChange(false);
        setParsedMissingPeiods(parsedPeriods);
    }

    const closeIssueHandler = (collapsibleTableSelectedRowData) => {
        setSelectedRow(collapsibleTableSelectedRowData);
        setClickedButton(missingCasesStatuses.CLOSE);
        setShowModal(true);
    }
    useEffect(() => {
        const newRowData = rowData.map(row => {
            if (issueReportedState === row.id && clickedButton === missingCasesStatuses.REPORT) {
                row.status = missingCasesStatuses.PENDING
                return row
            } else if (issueReportedState === row.id && clickedButton === missingCasesStatuses.RESOLVE) {
                row.status = missingCasesStatuses.RESOLVE
                return row
            } else {
                return row
            }
        })
        setRowData(newRowData)
    }, [issueReportedState])

    return (
        <React.Fragment>
            <BrandModal
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                {
                    clickedButton === missingCasesStatuses.CLOSE ?
                        <CloseModal
                            selectedRowData={selectedRow}
                            setShowModal={setShowModal}
                            rowData={rowData}
                            setRowData={setRowData}
                        />
                        :
                        modalChange ?
                            <MissingRevenueIssueModal
                                selectedRowData={selectedRow}
                                originalData={rowData}
                                fetchData={fetchData}
                                rowData={selectedRow}
                                setRowData={setRowData}
                                setShowModal={setShowModal}
                                parsedPeriods={parsedMissingPeriods}
                                setIssueReportedState={setIssueReportedState}
                            />
                            :
                            <RegistrationForm
                                originalData={rowData}
                                selectedRowData={selectedRow}
                                fetchData={fetchData}
                                rowData={selectedRow}
                                setRowData={setRowData}
                                setShowModal={setShowModal}
                                setIssueReportedState={setIssueReportedState}
                            />
                }
            </BrandModal>
            {
                rowData.length > 0 ?
                    <>
                        <TableRow className={styles.root}>
                            <TableCell className={clsx(styles.tableCell, styles.songTitleCell)}>{rowData[0].songTitle}</TableCell>
                            <TableCell className={styles.tableCell}>{rowData[0].artist}</TableCell>
                            <TableCell className={styles.tableCell}>
                                <div className={styles.missingRevenueDotContainer}>
                                    <FiberManualRecordIcon className={styles.revenueDot} />
                                    <div>{'Missing'}</div>
                                </div>
                            </TableCell>
                            <TableCell className={styles.tableCell}>
                                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                    {
                                        open ?
                                            <div className={styles.showMoreButton}>
                                                <KeyboardArrowUpIcon />
                                                <p>Hide more details</p>
                                            </div>
                                            :
                                            <div className={styles.showMoreButton}>
                                                <KeyboardArrowDownIcon />
                                                <p>Show more details</p>
                                            </div>
                                    }
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.tableCell} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box>
                                        <Table size="small" aria-label="purchases">
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        collapseCellTitles.map(x => <TableCell key={Math.random()} className={styles.cellTitle}>{x}</TableCell>)
                                                    }
                                                    <TableCell key={Math.random()} className={styles.cellTitle}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowData.map((x) => {
                                                    let missingPeriodsQuarter;
                                                    if (x.missingPeriods !== 'null') {
                                                        missingPeriodsQuarter = JSON.parse(x.missingPeriods).periods.map(x => {
                                                            x = x.toString()
                                                            const quarter = x.substring(x.length - 1)
                                                            const year = x.substring(0, x.length - 1)

                                                            return `Q${quarter}-${year}`
                                                        })
                                                    }
                                                    const reportName = reportNames.filter(repName => repName.id === Number(x.reportType))[0]
                                                    return (
                                                        < TableRow key={Math.random()} >
                                                            <TableCell
                                                                className={styles.tableCell}
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {reportName.name}
                                                            </TableCell>
                                                            <TableCell className={styles.tableCell}>
                                                                {
                                                                    x.missingPeriods !== 'null' ?
                                                                        <div className={clsx(styles.cellData, styles.missingRevenueDotContainer)}>
                                                                            <FiberManualRecordIcon className={styles.songStatusRegisterdDot} />
                                                                            <div>Registered</div>
                                                                        </div>
                                                                        :
                                                                        <div className={clsx(styles.cellData, styles.missingRevenueDotContainer)}>
                                                                            <FiberManualRecordIcon className={styles.revenueDot} />
                                                                            <div>Not Registered</div>
                                                                        </div>
                                                                }
                                                            </TableCell>
                                                            <TableCell className={styles.tableCell}>
                                                                {
                                                                    x.missingPeriods !== 'null' ?
                                                                        missingPeriodsQuarter.length > 0 ?
                                                                            <div className={styles.cellData}>{missingPeriodsQuarter.join(',')}</div>
                                                                            :
                                                                            <div className={styles.cellData}>N/A</div>
                                                                        :
                                                                        <div className={styles.cellData}>N/A</div>
                                                                }
                                                            </TableCell>
                                                            {
                                                                state.user.userType === 1 ?
                                                                    <>
                                                                        <TableCell className={styles.tableCell}>
                                                                            {
                                                                                x.userComment === null || x.userComment === "" ?
                                                                                    'N/A'
                                                                                    :
                                                                                    x.userComment
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell className={styles.tableCell}>
                                                                            {
                                                                                x.reporterId !== null ?
                                                                                    x.email
                                                                                    :
                                                                                    'N/A'
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell className={styles.tableCell}>{x.submitDate.split('T')[0]}</TableCell>
                                                                    </>
                                                                    :
                                                                    <TableCell className={styles.tableCell} >
                                                                        {
                                                                            x.missingPeriods !== 'null' ?
                                                                                x.status === missingCasesStatuses.ADDED ?
                                                                                    'Missing Revenue Period found! Click on Report Issue to find your missing revenue.'
                                                                                    :
                                                                                    'Pending Resolution. We are working on finding your missing revenue.'
                                                                                :
                                                                                x.status === missingCasesStatuses.ADDED ?
                                                                                    'Song not registered. Click on Report Issue to register your song and find missing revenue.'
                                                                                    :
                                                                                    'Pending Resolution. We are working on finding your missing revenue.'
                                                                        }
                                                                    </TableCell>
                                                            }

                                                            <TableCell className={styles.tableCell} >
                                                                <div className={styles.btnContainer}>
                                                                    {
                                                                        state.user.userType === 1 ?
                                                                            <>
                                                                                <Button onClick={() => { reportAndResolveHandler(x, missingPeriodsQuarter, missingCasesStatuses.RESOLVE) }} className={clsx(styles.buttonView, styles.adminResolveButton)}>Resolve</Button>
                                                                                <Tooltip title='Close if not relevant'>
                                                                                    <Button onClick={() => { closeIssueHandler(x) }} className={clsx(styles.buttonView, styles.closeButton)}>Close</Button>
                                                                                </Tooltip>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    x.status === missingCasesStatuses.ADDED ?
                                                                                        <Button onClick={() => { reportAndResolveHandler(x, missingPeriodsQuarter, missingCasesStatuses.REPORT) }} className={clsx(styles.buttonView, styles.resolveButton)}>Report Issue</Button>
                                                                                        :
                                                                                        <Button disabled className={clsx(styles.buttonView, styles.closeButton)}>Issue Reported</Button>
                                                                                }
                                                                                <Tooltip title='Close issue if not relevant'>
                                                                                    <Button onClick={() => { closeIssueHandler(x) }} className={clsx(styles.buttonView, styles.closeButton)}>Close Issue</Button>
                                                                                </Tooltip>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </>
                    :
                    null
            }
        </React.Fragment >
    );
};

export default function CollapsibleTable({ rows, cellTitles, collapseCellTitles, collapseData, data, fetchData }) {
    const [state, setState] = useStoreContext();
    const styles = useStyles();
    return (
        <TableContainer className={styles.container}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {cellTitles.map(x => (
                            <TableCell key={Math.random()} className={styles.cellTitle}>{x}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows.length === 0 ?
                            null
                            :
                            rows.map((row) => {
                                return (
                                    <Row
                                        data={rows}
                                        key={Math.random()}
                                        collapseCellTitles={collapseCellTitles}
                                        collapseData={collapseData}
                                        initialRowData={row}
                                        rowDataKeys={Object.keys(row).filter(x => x !== 'data')}
                                        fetchData={fetchData}
                                    />
                                )
                            })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};