import { 
    Box, 
    Typography, 
    makeStyles 
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        maxWidth: '450px'
    },
    columnInfo: {
        fontSize: '14px'
    }
}));

const CustomAccordionContent = ({
    label,
    children
}) => {
    const styles = useStyles();

    return (
        <Box className={styles.contentWrapper}>
            <Typography>
                { label ?? '' }
            </Typography>
            <Typography className={styles.columnInfo}>
                { children }
            </Typography>
        </Box>
    )
}

export default CustomAccordionContent;