import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import Calendar from "../../CoreComponents/Calendar";


const useNftStyles = makeStyles((theme) => ({
    currencyTabContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTabs-flexContainer.MuiTabs-centered': {
            justifyContent: 'flex-start'
        },
    },
    currencyContainer: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(2),
        alignSelf: 'normal',
        margin: '32px 16px 16px 16px'
    },
    tabsAndCalendarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
}));



function CryptoCurrenciesTabs({ setSelectedCryptocurrency, cryptocurrencies, setPage, calendarShow, showTypography, currencyTabValue, setCurrencyTabValue }) {
    const styles = useNftStyles();

    const handleCurrencyTabsChange = (e, newValue) => {
        setCurrencyTabValue(newValue);
        setSelectedCryptocurrency(e.target.innerText)
    };

    return (
        <>
            {
                cryptocurrencies.length ?
                    <div className={styles.currencyContainer}>
                        <div>Crypto Currency</div>
                        <div className={styles.tabsAndCalendarContainer}>
                            <Tabs
                                selectionFollowsFocus={true}
                                value={currencyTabValue}
                                onChange={handleCurrencyTabsChange}
                                indicatorColor="primary"
                                textColor="primary"
                                className={styles.currencyTabContainer}
                                centered
                            >
                                {cryptocurrencies.map(x => (
                                    <Tab onClick={() => { setPage?.(0) }} key={x} label={x} />
                                ))}
                            </Tabs>
                            {
                                calendarShow ?
                                    <Calendar showTypography={showTypography} />
                                    :
                                    null
                            }
                        </div>
                    </div>
                    :
                    null
            }
        </>
    );
}

export default CryptoCurrenciesTabs;