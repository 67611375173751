import {
    Box,
    Typography,
    makeStyles
} from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import CustomModal from "./CustomModal";
import { useEffect, useMemo, useState } from "react";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import AddRevenueSplitForm from "./AddRevenueSplitForm";
import EditListRevenueSplits from "./EditListRevenueSplits";
import { BrandButton, BrandInformationalButton } from "../CoreComponents/BrandButton";

const SAVE_WARNING_MESSAGE = 'Revenue split not applied (total revenue split percentage exceeds 100%) for these files: ';
const EDIT_WARNING_MESSAGE = 'Total revenue split percentage exceeds 100%';
const ZERO_REVENUE_SPLIT_WARNING_MESSAGE = 'If the total revenue split % is equal to 0% total revenue will be equal to $0.';

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        padding: '35px 80px',
        width: '60em',
        height: '35em'
    },
    header: {
        fontSize: '22px',
        fontWeight: 'bold'
    },
    fileDetails: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    addNewButton: {
        color: '#62E162',
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '20px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    customInputDataWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
    },
    columnHeader: {
        fontWeight: 'bold',
        '&:first-child': {
            width: '60%'
        }
    },
    actionsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '20px',
        alignItems: 'center',
        position: 'absolute',
        right: '40px',
        bottom: '40px'
    },
    headerSectionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
    },
    warningMessage: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        color: theme.palette.primary.attention
    }
}));

const generateFileName = (selectedItems) => {
    if (selectedItems.length === 0) return '';

    return `${selectedItems[0].fileName} ${selectedItems.length > 1 ? ` + ${selectedItems.length - 1}` : ''}`
}

const ManageRevenueSplitModal = ({
    open,
    song,
    onClose,
    selectedItems,
    fetchRevenueSplits
}) => {
    const [warningMessage, setWarningMessage] = useState('');
    const [revenueSplits, setRevenueSplits] = useState([]);
    const [selectedAccountHolder, setSelectedAccountHolder] = useState({});
    const [mainAccountHolderId, setMainAccountHolderId] = useState(null);
    const [inputPercentageData, setInputPercentageData] = useState('');
    const [showAddRevenueSplit, setShowAddRevenueSplit] = useState(false);
    const [disabledApplyEdit, setDisabledApplyEdit] = useState(false);

    const managedFileNames = useMemo(() => generateFileName(selectedItems), []);

    const styles = useStyles();
    const abortableFetch = useAbortableFetch();

    useEffect(() => {
        handleGetRevenueSplits();
    }, []);

    useEffect(() => {
        const totalSplitPercentage = revenueSplits.reduce((prev, curr) => Number(curr.splitPercentage) + prev, Number(inputPercentageData) ?? 0);
        if (revenueSplits.length > 0) {
            if (totalSplitPercentage === 0) {
                setWarningMessage(ZERO_REVENUE_SPLIT_WARNING_MESSAGE);
                return;
            } else if (totalSplitPercentage > 100) {
                setWarningMessage(EDIT_WARNING_MESSAGE);
                return;
            }
        }
        setWarningMessage('');
    }, [revenueSplits, inputPercentageData]);

    const handleShowAddRevenueSplit = () => setShowAddRevenueSplit(true);

    const handleHideAddRevenueSplit = () => {
        setShowAddRevenueSplit(false);
        setSelectedAccountHolder({});
        setInputPercentageData('');
    }

    const handleSaveRevenueSplit = async () => {
        const reportAndSongIdPairs = selectedItems.flatMap(({ id, songIds }) => (songIds.map(songId => ({ reportId: id, songId: songId }))));

        const revenueSplitPercentage = revenueSplits.reduce((prev, curr) => Number(curr.splitPercentage) + prev, Number(inputPercentageData));
        if (revenueSplitPercentage > 100 || revenueSplitPercentage < 0) {
            return;
        }

        try {
            const { blacklistedFiles } = await abortableFetch('POST', '/api/revenue-split', {
                body: {
                    reportAndSongIdPairs,
                    songId: song.id,
                    accountHolderId: selectedAccountHolder.id,
                    revenueSplitPercentage: inputPercentageData
                }
            });

            if (blacklistedFiles.length > 0) {
                const warningMessage = SAVE_WARNING_MESSAGE + `${blacklistedFiles.map((x) => x.name).join(', ')}`;

                setWarningMessage(warningMessage);
            } else {
                setWarningMessage('');
                handleHideAddRevenueSplit();
            }

            await fetchRevenueSplits();
            await handleGetRevenueSplits();
        } catch (error) {
            console.log(error);
        }
    }

    const handleGetRevenueSplits = async () => {
        const reportAndSongIdPairs = selectedItems.flatMap(({ id, songIds }) => (songIds.map(songId => ({ reportId: id, songId: songId }))));

        try {
            const { revenueSplits, mainAccountHolderId } = await abortableFetch('POST', '/api/revenue-split/get-revenue-split', {
                body: {
                    reportAndSongIdPairs,
                    songId: song.id
                }
            });

            setRevenueSplits(revenueSplits);
            setMainAccountHolderId(mainAccountHolderId);
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpdatePercentage = async (isClicked, setIsClicked, setBtnClicked) => {
        const reportAndSongIdPairs = selectedItems.flatMap(({ id, songIds }) => (songIds.map(songId => ({ reportId: id, songId: songId }))));
        const revenueSplitPercentage = revenueSplits.reduce(
            (prev, curr) => Number(curr.splitPercentage) + prev
            , 0);

        if (revenueSplitPercentage > 100 || revenueSplitPercentage < 0 || disabledApplyEdit) {
            return;
        }

        try {
            await abortableFetch('PUT', '/api/revenue-split', {
                body: {
                    songId: song.id,
                    reportAndSongIdPairs,
                    revenueSplits
                }
            });

            setIsClicked(!isClicked);
            setBtnClicked({});    
            await fetchRevenueSplits();
            await handleGetRevenueSplits();
        } catch (error) {
            console.log(error);
        }
    }

    const handleRefreshRevenueSplits = async () => {
        await fetchRevenueSplits();
        await handleGetRevenueSplits();
    }

    return (
        <CustomModal open={open} onClose={onClose}>
            <Box className={styles.contentWrapper}>
                <Box className={styles.headerSectionWrapper}>
                    <Typography className={styles.header}>
                        Manage Revenue Split for {song.title}
                    </Typography>
                    <Typography variant="body2" className={styles.fileDetails}>
                        {managedFileNames}
                    </Typography>
                    {warningMessage && (
                        <Typography variant="body2" className={styles.warningMessage}>
                            <ReportProblemOutlinedIcon />
                            {warningMessage}
                        </Typography>
                    )}
                    <Box className={styles.customInputDataWrapper}>
                        <Typography className={styles.columnHeader}>
                            Account Holders
                        </Typography>
                        <Typography className={styles.columnHeader}>
                            Revenue
                        </Typography>
                    </Box>
                </Box>
                {revenueSplits.length > 0 && (
                    <EditListRevenueSplits
                        revenueSplits={revenueSplits}
                        songId={song.id}
                        mainAccountHolderId={mainAccountHolderId}
                        setRevenueSplits={setRevenueSplits}
                        refreshRevenueSplits={handleRefreshRevenueSplits}
                        setDisabledApplyEdit={setDisabledApplyEdit}
                        setWarningMessage={setWarningMessage}
                        selectedItems={selectedItems}
                        editSplits={handleUpdatePercentage}
                        handleGetRevenueSplits={handleGetRevenueSplits}
                    />
                )}
                {showAddRevenueSplit ? (
                    <AddRevenueSplitForm
                        mainAccountHolderId={mainAccountHolderId}
                        selectedAccountHolder={selectedAccountHolder}
                        setSelectedAccountHolder={setSelectedAccountHolder}
                        inputPercentageData={inputPercentageData}
                        setInputPercentageData={setInputPercentageData}
                        onSubmit={handleSaveRevenueSplit}
                        onCancel={handleHideAddRevenueSplit}
                    />
                ) : (
                    <Typography
                        className={styles.addNewButton}
                        onClick={handleShowAddRevenueSplit}
                    >
                        + Add new
                    </Typography>
                )}
                {/* <Box className={styles.actionsWrapper}>
                    <BrandInformationalButton
                        onClick={onClose}
                    >
                        Cancel
                    </BrandInformationalButton>
                    <BrandButton
                        onClick={handleEditRevenueSplits}
                        disabled={disabledApplyEdit}
                    >
                        Save
                    </BrandButton>
                </Box> */}
            </Box>
        </CustomModal>
    )
}

export default ManageRevenueSplitModal;