import { Popover, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { formatNumber } from "../../utils/NumberUtils";
import { modalSong } from "../../../constants/portfolioSongsConstants"

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
        color: theme.palette.primary.main
    },

}))

export default function PopoverDetails({ details }) {
    const styles = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const parsedDetails = formatNumber(details);

    const handlePopoverOpen = (event) => {
        if (parsedDetails.length > 9) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = anchorEl;

    return (
        <>
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {
                    parsedDetails.length > modalSong.MAX_LENGTH_REVENUE ?
                        `$${formatNumber(details).substring(0, 10)}`
                        :
                        `$${parsedDetails}`
                }
            </div>
            <Popover
                id="mouse-over-popover"
                className={styles.popover}
                classes={{
                    paper: styles.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {
                `$${formatNumber(details)}`
                }
            </Popover>
        </>

    )
}