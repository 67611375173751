import { Slide, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        font: 'normal normal normal 24px/32px ITCAvantGardeGothicStd-Normal',
        letterSpacing: '1.3px',
        width: '400px'
    },
}))
export default function Cookies() {
    const styles = useStyles();

    return (
        <Slide
            timeout={500}
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
        >
            <div className={styles.container}>
                Test Cookies Page
            </div>
        </Slide>
    )
}