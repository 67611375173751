import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    btnContainer: {
        display: 'flex',
        gap: '10px',
        color: theme.palette.primary.lightGray,
        font: 'normal normal normal 14px/21px ITCAvantGardeGothicStd-Normal',
        alignItems: 'center',
        marginLeft: '20px'
    },
    editAndDeleteBtn: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        cursor: 'pointer',
    },
    icons: {
        fontSize: '1rem'
    },
    coloredTextAndIconGreen: {
        color: theme.palette.primary.main
    },
    coloredTextAndIconRed: {
        color: theme.palette.primary.alert
    },
    disable: {
        pointerEvents: 'none',
        opacity: 0.5
    },

}))

export default function ActionButtons({
    isActionClicked,
    setIsActionClicked,
    actionButtonTypeClicked,
    tableData,
    clickHandler,
    onDelete,
    onEdit,
    editAlert,
    setActionButtonTypeClicked,
    showDelete,
    cancelEdit,
    showBulkEditButtons,
    handleShowBulkEditButtons
}) {
    const styles = useStyles();


    if (showBulkEditButtons) {
        return (
            <div className={styles.btnContainer}>
                <div className={styles.editAndDeleteBtn}>
                    <div
                        onClick={() => onEdit(showBulkEditButtons, handleShowBulkEditButtons, setActionButtonTypeClicked)}
                        className={clsx(styles.editAndDeleteBtn, styles.coloredTextAndIconGreen, editAlert?.length ? styles.disable : '')}
                    >
                        <div className={styles.icons}>
                            <DoneIcon fontSize='small' />
                        </div>
                        <div>
                            Save
                        </div>
                    </div>
                </div>
                <div className={styles.editAndDeleteBtn}>
                    <div
                        className={clsx(styles.editAndDeleteBtn, styles.coloredTextAndIconRed)}
                        onClick={() => cancelEdit()}
                    >
                        <div className={styles.icons}>
                            <CloseIcon fontSize='small' />
                        </div>
                        <div>
                            Cancel
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    return (
        <div className={styles.btnContainer}>
            <div
                className={styles.editAndDeleteBtn}
            >
                {
                    isActionClicked && actionButtonTypeClicked.btnValue === 'delete' && actionButtonTypeClicked.id === tableData.id ?
                        <div
                            className={clsx(styles.editAndDeleteBtn, styles.coloredTextAndIconGreen)}
                            onClick={() => onDelete()}
                        >
                            <div className={styles.icons}>
                                <DoneIcon fontSize='small' />
                            </div>
                            <div>
                                Delete
                            </div>
                        </div>
                        :
                        isActionClicked && actionButtonTypeClicked.btnValue === 'edit' && actionButtonTypeClicked.id === tableData.id ?
                            <div
                                onClick={() => onEdit(isActionClicked, setIsActionClicked, setActionButtonTypeClicked)}
                                className={clsx(styles.editAndDeleteBtn, styles.coloredTextAndIconGreen, editAlert?.length ? styles.disable : '')}
                            >
                                <div className={styles.icons}>
                                    <DoneIcon fontSize='small' />
                                </div>
                                <div>
                                    Save
                                </div>
                            </div>
                            :
                            <div
                                className={styles.editAndDeleteBtn}
                                onClick={() => clickHandler('edit', tableData.id)}
                            >
                                <div className={styles.icons}>
                                    <EditOutlinedIcon fontSize='small' />
                                </div>
                                <div>
                                    Edit
                                </div>
                            </div>
                }
            </div>
            <div
                className={styles.editAndDeleteBtn}
            >
                {
                    isActionClicked && actionButtonTypeClicked.id === tableData.id ?
                        <div
                            className={clsx(styles.editAndDeleteBtn, styles.coloredTextAndIconRed)}
                            onClick={() => cancelEdit()}

                        >
                            <div className={styles.icons}>
                                <CloseIcon fontSize='small' />
                            </div>
                            <div>
                                Cancel
                            </div>
                        </div>
                        :
                        (
                            showDelete &&
                            (<div
                                className={styles.editAndDeleteBtn}
                                onClick={() => clickHandler('delete', tableData.id)}
                            >
                                <div className={styles.icons} >
                                    <DeleteOutlineOutlinedIcon fontSize='small' />
                                </div>
                                <div>
                                    Delete
                                </div>
                            </div>)
                        )
                }
            </div>
        </div>
    )
}