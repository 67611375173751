import React from "react";
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const useStyles = makeStyles({
    root: {
        height: 30,
        marginBottom: -6,
        background: '#FFFFFFE6',
        borderRadius: '2px',
        '& .MuiLinearProgress-bar': {
            background: 'linear-gradient(270deg,#62E162, #03B56D);'
        }
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
    },
    label: {
        fontSize: '18px',
        letterSpacing: '0.9px',
        //fontFamily: 'Roboto Light,Sans-serif',
    },
    warning: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 20,
        color: '#F96C00',
        height: 50,
        '&:hover': {
            border: 'none'
        }
    }
});

export function BrandLoaderDots({ className = '' }) {
    return (
        <BrandLoader
            type='ThreeDots'
            height={14}
            width={70}
            className={className}
        />
    );
}

export function BrandLoaderSpinner(params) {
    return (
        <BrandLoader />
    );
}

export function BrandLoader({ type = 'TailSpin', color = '#D7CDE5', height = 80, width = 80, className,style }) {
    return (
        <Loader
            type={type}
            color={color}
            height={height}
            width={width}
            className={className}
            style={style}
        />
    );
}

function LinearProgressWithLabel(props) {
    const classes = useStyles();
    return (
        process.env.REACT_APP_ENABLE_REVIEW_PAGE_TAB === 'true' ?
            <div className={classes.container}>
                <div className={classes.label}>
                    {/* <span>{`Data Completion: ${Math.round(
                    props.value,
                )}%`}</span> */}
                    <div className={classes.warning}>
                        {/* <FiberManualRecordIcon style={{ color: '#F96C00', marginBottom: -5 }} /> */}
                        <ReportProblemOutlinedIcon style={{ color: '#F96C00', fontSize: '4rem' }} />
                        <div>
                            See Identified Missing Revenue on the Review Page.
                        </div>
                    </div>
                </div>
                {/* <Box width="300px" marginLeft={-3}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box> */}

            </div> :
            <div></div>
    );
}

export function BrandProgressBar({ progress }) {
    return (
        <LinearProgressWithLabel value={progress} />
    );
}