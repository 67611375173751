/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useState, useEffect } from 'react';
import { useStoreContext } from '../../store/Store.js';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { BrandLink } from '../CoreComponents/BrandLink'
import recordLogo from '../../assets/recordLogo.svg';
import DropZone from '../upload/DropZone.js';
import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { BrandButton } from '../CoreComponents/BrandButton.js';
import * as Papa from 'papaparse';
import { BrandLoader, BrandLoaderDots } from '../CoreComponents/BrandLoader.js';
import { BrandInput } from '../CoreComponents/BrandInput.js';
import { textIsEmpty } from '../utils/ValidationUtils.js';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/BrandSelect.js';
import { postData } from '../utils/FetchUtils';
import { BrandAlert } from '../CoreComponents/BrandAlert.js';
require('dotenv').config();

const useStyles = makeStyles((theme) => ({
    progressBarContainer: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    stepper: {
        width: '100%',
        height: '174px !important',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        margin: 'auto',
        background: '#161616 0% 0% no-repeat padding-box',
        borderBottom: '1px solid black',
        '& .MuiStepper-horizontal': {
            width: '80%',
            marginLeft: '10em'
        },
        '& .MuiStepper-root': {
            background: '#161616 0% 0% no-repeat padding-box'
        }
    },
    goBack: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    goBackIcon: {
        margin: 'auto',
        display: 'flex',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    logo: {
        width: '116px',
        height: '32px',
        marginLeft: '1.5em',
        margin: 'auto',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    contentContainer: {
        overflow: 'auto',
        height: '100%',
        width: '100%',
        padding: '2rem',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start'
    },
    actions: {
        width: '100%',
        paddingTop: '1em',
        paddingBottom: '1em',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 'auto',
        background: '#343434 0% 0% no-repeat padding-box',
        alignItems: 'center',
    },
    cancel: {
        marginLeft: '150px'
    },
    title: {
        marginTop: '50px',
        marginLeft: '50px',
        fontSize: '30px',
    },
    uploadContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    uploadedFiles: {
        marginLeft: '-15px',
    },
    upload: {
        width: 'auto',
        paddingRight: '60px'
    },
    uploadedFilesTitle: {
        marginLeft: '15px',
        textOverflow: 'ellipsis',
        font: 'normal normal bold 10px / 12px Proxima Nova',
        letterSpacing: '1.5px',
        color: '#FFFFFF61',
        textTransform: 'uppercase',
        opacity: 1,
    },
    continue: {
        display: 'flex',
        marginRight: '150px',
        width: '120px',
        '& button': {
            marginRight: '10px',
            marginLeft: '10px'
        }
    },
    uploadedFilesHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        justifyContent: 'space-between'
    },
    form: {
        padding: theme.spacing(3),
        width: theme.spacing(77),
    },
    nftUploadButton: {
        display: 'flex',
        justifyContent: 'end',
        '& .MuiButton-root': {
            width: 148,
            height: 40
        }
    },
    nftTitle: {
        marginTop: '50px',
        marginLeft: theme.spacing(3),
        fontSize: '30px',
    },
    uploading: {
        display: 'flex',
        gap: theme.spacing(1)
    },
    nftUploadButtonAndAlertContainer: {
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'column',
        alignItems: 'end',
        width: 162,
        height: 67
    }
}));

function parseDate(date) {
    let today = new Date().getTime();
    const tempToday = new Date();
    /*
    a second ago
    {X} seconds ago
    a minute ago
    {X} minutes ago
    an hour ago
    {X} hours ago
    a day ago
    {X} days ago
    a month ago
    {X} months ago
    a year ago
    {X} years ago
    */
    if (date.includes('a second')) {
        today -= 1000;
    } else if (date.includes('seconds')) {
        const subtractor = Number(date.split(' seconds')[0])
        today -= subtractor * 1000;
    } else if (date.includes('a minute')) {
        today -= 60000;
    } else if (date.includes('minutes')) {
        const subtractor = Number(date.split(' minutes')[0])
        today -= subtractor * 60000;
    } else if (date.includes('an hour')) {
        today -= 3600000;
    } else if (date.includes('hours')) {
        const subtractor = Number(date.split(' hours')[0])
        today -= subtractor * 3600000;
    } else if (date.includes('a day')) {
        tempToday.setDate(tempToday.getDate() - 1);
        today = tempToday.getTime();
    } else if (date.includes('days')) {
        const subtractor = Number(date.split(' days')[0])
        tempToday.setDate(tempToday.getDate() - subtractor);
        today = tempToday.getTime();
    } else if (date.includes('a month')) {
        tempToday.setMonth(tempToday.getMonth() + 1 - 1);
        today = tempToday.getTime();
    } else if (date.includes('months')) {
        const subtractor = Number(date.split(' months')[0])
        tempToday.setMonth(tempToday.getMonth() + 1 - subtractor);
        today = tempToday.getTime();
    } else if (date.includes('a year')) {
        tempToday.setFullYear(tempToday.getFullYear() - 1)
        today = tempToday.getTime();
    } else if (date.includes('years')) {
        const subtractor = Number(date.split(' years')[0])
        tempToday.setFullYear(tempToday.getFullYear() - subtractor)
        today = tempToday.getTime();
    } else {
        return date;
    }

    const parsedTempToday = new Date(today);

    return `${parsedTempToday.getFullYear()}-${parsedTempToday.getMonth() + 1}-${parsedTempToday.getDate()}`
}

const dataValidators = {
    validateNftUrl: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
}

export const UploadNft = () => {
    const styles = useStyles();
    const state = useStoreContext();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [parsing, setParsing] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const nftUrl = useState('');
    const [uploadNftDisabled, setUploadNftDisabled] = useState(true);
    const [fileTypes] = useState([{ label: 'TXT to parse', ext: 'txt' }]);
    const selectedFileType = useState('txt');
    const [isUploading, setIsUploading] = useState(false);
    const [finishedUploadingAlert, setFinishedUploading] = useState('');
    const [uploadedSuccessfully, setUploadedSuccessfully] = useState(false);

    useEffect(() => {
        state[1](state => (state.hideNavigation = true, state.disableRootPadding = true, { ...state }));
        return () => { state[1](state => (state.hideNavigation = false, state.disableRootPadding = false, { ...state })) }
    }, [])

    useEffect(() => {
        if (uploadedFiles.length <= 0 || parsing) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [parsing, uploadedFiles])

    useEffect(() => {
        setUploadNftDisabled(dataValidators.validateNftUrl(nftUrl[0]).length !== 0)
    }, [nftUrl[0]])

    useEffect(() => {
        setUploadedFiles([]);
    }, [selectedFileType[0]])

    const parseTxt = async () => {
        setParsing(true);
        for (let i = 0; i < uploadedFiles.length; i++) {
            const file = uploadedFiles[i]
            const textData = (await file.text()).replaceAll('\r', '')
            let textDataArr = textData.split('\n');
            const entries = [];
            let counter = 0;
            let entry = {};

            for (let i = 0; i < textDataArr.length; i++) {
                if (textDataArr[i] === 'shopping_cart' && i > 0) {
                    counter = 0;
                    entries.push(entry)
                    entry = {}
                }
                const entryData = textDataArr[i];
                switch (counter) {
                    case 3:
                        entry.unitPrice = entryData;
                        break;
                    case 4:
                        entry.quantity = entryData;
                        break;
                    case 5:
                        entry.from = entryData;
                        break;
                    case 6:
                        entry.to = entryData;
                        break;
                    case 7:
                        entry.date = parseDate(entryData);
                        break;
                    default:
                        break;
                }
                counter++;
            }
            if (textDataArr.length > 8) {
                const lastRow = textDataArr.slice(-8);
                entries.push({
                    unitPrice: lastRow[3] || '',
                    quantity: lastRow[4] || '',
                    from: lastRow[5] || '',
                    to: lastRow[6] || '',
                    date: lastRow[7] ? parseDate(lastRow[7]) : ''
                })
            }

            //Two Issues
            //1. Never pushing the last 8 rows from 'textDataArr' in the array 'entries' for some reason
            //2. Date is not parsed well

            const asJsonData = JSON.stringify(entries);
            const asCsvData = Papa.unparse(asJsonData);
            const name = file.name.slice(0, -4);
            const csvFile = new File([asCsvData], `${name}.csv`, {
                type: "text/plain",
            });
            const dateStamp = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
            const url = window.URL.createObjectURL(csvFile);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${name}_${dateStamp}.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
        setParsing(false);
    }

    const handleDrop = (files) => {
        const filesToAdd = [];
        if (Array.isArray(files)) {
            for (const file of files) {
                if (!uploadedFiles.find(x => file.name === x.name)) {
                    filesToAdd.push(file);
                }
            }
            setUploadedFiles([...uploadedFiles, ...filesToAdd].slice(0, 10))
        } else if (!uploadedFiles.find(x => files.name === x.name)) {
            setUploadedFiles([...uploadedFiles, files].slice(0, 10))
        }
    };

    useEffect(() => {
        if (finishedUploadingAlert) {
            setTimeout(() => {
                setFinishedUploading('');
            }, "4000")
        }
    }, [finishedUploadingAlert])

    function onSubmitUploadNftFromUrl(e) {
        setIsUploading(true);
        setUploadNftDisabled(true);
        postData(process.env.REACT_APP_SERVER_HOST + '/api/nft/opensea-link', { openSeaTokensLink: nftUrl[0] })
            .then(() => {
                nftUrl[1]('');
                setIsUploading(false);
                setUploadNftDisabled(false);
                setUploadedSuccessfully(true);
                setFinishedUploading('Uploaded Successfully');
            }, err => {
                setIsUploading(false);
                setUploadNftDisabled(false);
                setUploadedSuccessfully(false);
                setFinishedUploading('Failed to upload')
            });
        e.preventDefault();
    }

    function onSubmitBtnClick() {
        switch (selectedFileType[0]) {
            case 'txt':
                parseTxt();
                break;
            default:
        }
    }

    return (
        <div className={styles.progressBarContainer}>
            <div className={styles.stepper}>
                <div className={styles.goBack}>
                    <BrandLink to='/manage-users' className={styles.goBackIcon}>
                        <ArrowBack fontSize='large' style={{ color: '#FFFFFF' }} />
                        <img src={recordLogo} alt='' className={styles.logo}></img>
                    </BrandLink>
                </div>
            </div>

            <div className={styles.nftTitle}>Upload NFT</div>
            <form className={styles.form} onSubmit={onSubmitUploadNftFromUrl}>
                <BrandInput
                    label='Open Sea NFT URL'
                    placeholder='https://opensea.io/assets/matic/{ Contract Address }/{ Token ID }'
                    $value={nftUrl}
                    required
                    validator={dataValidators.validateNftUrl}
                />
                <div className={styles.nftUploadButton}>
                    <div className={styles.nftUploadButtonAndAlertContainer}>
                        <BrandButton
                            type='submit'
                            disabled={uploadNftDisabled}
                        >
                            {isUploading ?
                                <div className={styles.uploading}>
                                    <BrandLoader color='white' width={20} height={20} />
                                    <div>Uploading</div>
                                </div>
                                :
                                'Upload'
                            }
                        </BrandButton>
                        {finishedUploadingAlert ? <BrandAlert success={uploadedSuccessfully}>{finishedUploadingAlert}</BrandAlert> : null}
                    </div>
                </div>
            </form>
            <div className={styles.form}>
                <BrandSelect $value={selectedFileType} label='File Type'>
                    {fileTypes.map(x => (
                        <BrandMenuItem key={x.ext} value={x.ext}>
                            {x.label}
                        </BrandMenuItem>
                    ))}
                </BrandSelect>
            </div>
            <div className={styles.title}>Upload {selectedFileType[0].toUpperCase()} For Parsing</div>
            <div className={styles.contentContainer}>
                <div className={styles.uploadContainer}>
                    <div className={styles.upload}>
                        <DropZone
                            handleDrop={handleDrop}
                            acceptedFormats={{'text/plain': [`.${selectedFileType[0]}`]}}>
                        </DropZone>
                    </div>
                </div>
                <div className={styles.uploadedFiles}>
                    <div className={styles.uploadedFilesHeader}>
                        <div className={styles.uploadedFilesTitle}>Uploaded File(s). Max (10)</div>
                        {uploadedFiles.length ?
                            <BrandButton onClick={() => setUploadedFiles([])}>Clear All</BrandButton>
                            :
                            null
                        }
                    </div>
                    <List>
                        {uploadedFiles.map(file => {
                            return (
                                <ListItem>
                                    <ListItemIcon>
                                        <DescriptionOutlinedIcon style={{ color: '#FFFFFF8A' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete">
                                            <DeleteOutlineOutlinedIcon style={{ color: '#FFFFFF8A' }}
                                                onClick={() => {
                                                    setUploadedFiles(uploadedFiles.filter(x => x !== file));
                                                }} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })}
                    </List>
                </div>
            </div>
            <div className={styles.actions}>
                <BrandLink to='/manage-users' className={styles.cancel}>Cancel</BrandLink>
                <BrandButton
                    disabled={disabled}
                    onClick={onSubmitBtnClick}
                    className={styles.continue}
                >
                    {parsing ?
                        <BrandLoaderDots />
                        : selectedFileType[0] === 'txt' ?
                            'Parse'
                            :
                            'Upload'
                    }
                </BrandButton>
            </div>
        </div>
    );
}