import { AppBar, Box, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { useState } from "react";
import clsx from 'clsx';
import Calendar from "../../CoreComponents/Calendar";

const useNftStyles = makeStyles((theme) => ({
    dateRangeTabsContainer: {
        marginTop: '48px'
    },
    bar: {
        width: '70% !important',
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiPaper-elevation4': {
            boxShadow: 'none !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main
        },
        '& .MuiBox-root': {
            padding: 0
        },
        "& .MuiButtonBase-root": {
            color: 'white',
            opacity: 1,
            font: 'normal normal normal 14px/20px Roboto !important',
            letterSpacing: '1.6px',
            '& .MuiTab-wrapper': {
                width: '100px',
                textTransform: 'none'
            },
        },
        '& .MuiTab-root': {
            minWidth: '0px !important',
            width: '100px'
        },
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
        "& .MuiTableContainer-root": {
            height: '420px'
        },
        "& .MuiTableHead-root": {
            position: 'sticky',
            top: 0
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiTabs-flexContainer': {
            marginTop: '8px',
            justifyContent: 'space-between'
        },
        '& .MuiAppBar-root': {
            dispaly: 'flex',
            alignItems: 'start !important',
            marginLeft: '16px'
        },
        '& .MuiTabs-root': {
            width: '85%'
        }
    },
    tabHeight: {
        height: '680px'
    },
    chart: {
        width: '100%',
        overflow: 'auto'
    },
    tabContainer: {
        margin: theme.spacing(2),
        marginTop: 0,
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiChip-root': {
            marginTop: theme.spacing(1),
        }
    },
    tabPanel: {
        padding: theme.spacing(2)
    },
    title: {
        margin: "16px"
    }

}));

function setTabProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function CapitalGainsAndLossesDateRanges() {
    const styles = useNftStyles();
    const [value, setValue] = useState(0);
    const [test, setTest] = useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={styles.dateRangeTabsContainer}>
            <Typography
                component='div'
                variant='subtitle1'
                className={styles.title}
            // onClick={arrowHandler}
            >
                Capital Gains & Losses
            </Typography>
            <div className={styles.bar}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="1 day" {...setTabProps(0)} />
                        <Tab label="1 week" {...setTabProps(1)} />
                        <Tab label="1 month" {...setTabProps(2)} />
                        <Tab label="6 months" {...setTabProps(3)} />
                        <Tab label="1 year" {...setTabProps(4)} />
                        <Tab label="All time" {...setTabProps(5)} />
                        <Tab onClick={(e) => {
                            test == null ?
                                setTest(e.currentTarget)
                                :
                                setTest(null)
                        }} label="Custom" {...setTabProps(6)} />
                    </Tabs>
                </AppBar>
                <Calendar showTypography={false} test={test} setTest={setTest} />
            </div>
        </div>
    );
}

export default CapitalGainsAndLossesDateRanges;