import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getData } from '../utils/FetchUtils'
import { Box, TextField, Typography } from "@material-ui/core";
import { useInfiniteScrolling } from "../../hooks/useInfiniteScrolling";
import { useDebounce } from '../../hooks/useDebounce';
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
    autocomplete: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        width: '100%',
        marginBottom: '8px'
    },
    optionsContainer: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: `1px  ${theme.palette.primary.border}`,
            }
        },
        '& .MuiInputBase-root': {
            height: theme.spacing(5),
            border: '1px solid grey',
            borderRadius: theme.spacing(3),
            height: '40px'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.border,
            border: 'none'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.primary.contrastText
        },
        '& .MuiInputBase-input': {
            position: 'relative',
            bottom: theme.spacing(1),
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: theme.palette.primary.contrastText
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: theme.palette.primary.contrastText,
            marginRight: '0px',
            "& span": {
                "& svg": {
                    fontSize: '2.1875rem',
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')"
                    }
                }
            }
        },
        '& .MuiAutocomplete-endAdornment': {
            right: '0px !important',
            top: '0px'
        }
    }
}));


export const UploadAutoComplete = ({
    placeholder,
    selectedAutoCompleteOption, 
    onSelectedAutoCompleteOption, 
    autoCompleteOptions,
    autoCompleteOptionsString,
    heading
}) => {
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [open, setOpen] = useState(false);
    const LIMIT = 200;
    const offsetRef = useRef(0);
    const elementRef = useRef();
    const searchDebounce = useDebounce(searchQuery, 700);

    const [setIsFetching] = useInfiniteScrolling(elementRef, async () => await handleGetOptions());

    const handleGetOptions = async () => {
        if(autoCompleteOptions?.length > 0){
            return;
        }
        try {
            setIsLoading(true);
            const { items } = await getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/?offset=${offsetRef.current}&limit=${LIMIT}&filter=${searchDebounce}`);

            if (items.length === 0) {
                setIsFetching(false);
                return;
            }

            setOptions((prev) => prev.concat(items.filter(item => item.id !== 0)));

            offsetRef.current += LIMIT;
            setIsFetching(false);
            setIsLoading(false);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        if(Object.keys(selectedAutoCompleteOption).length > 0){
            setSelectedValue(selectedAutoCompleteOption);
        } else if (Number(selectedAutoCompleteOption) && autoCompleteOptions.length > 0) {
            const foundOption = autoCompleteOptions.find((option) => option.id === selectedAutoCompleteOption);
            setSelectedValue(foundOption);
        }
    }, []);

    useEffect(() => {
        if(autoCompleteOptions?.length > 0){
            return;
        }
        offsetRef.current = 0;
        setOptions([]);
        handleGetOptions();
    }, [searchDebounce]);

    const defaultProps = {
        options: options,
        getOptionLabel: (option) => option?.name ? option.name : '',
        getOptionSelected: (option, value) => value !== '' ? option.name === value.name : value === ''
    };

    const handleOnchange = (event, newValue) => {
        if(!newValue) {
            onSelectedAutoCompleteOption('');
            setSelectedValue('');
            setSearchQuery('');
            handleGetOptions();
            return;
        }
        if(autoCompleteOptions?.length && newValue){
            onSelectedAutoCompleteOption(newValue.id);
        } else {
            onSelectedAutoCompleteOption(newValue);
        }
        setSelectedValue(newValue);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    }

    useEffect(() => {
        if(autoCompleteOptions?.length){
            setOptions(autoCompleteOptions);
        }
    }, [autoCompleteOptionsString]);

    return (
        <Box className={styles.autocomplete}>
            <Typography>{heading}</Typography>
                <Autocomplete
                    ref={elementRef}
                    className={styles.optionsContainer}
                    value={selectedValue}
                    onChange={(event, newValue) => handleOnchange(event, newValue)}
                    renderTags={() => null}
                    getOptionLabel={(option) => option.name}
                    loading={isLoading}
                    onOpen={() => {
                        setOpen(true);
                      }}
                    onClose={() => {
                        setOpen(false);
                      }}
                    open={open}
                    renderInput={(params) => 
                        <TextField 
                            {...params}
                            variant="outlined"
                            placeholder={placeholder}
                            onChange={(e) => handleSearch(e)}
                        />
                    }
                    {...defaultProps}
                />
        </Box>
    );
}