import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Doughnut, Bar as ChartJsBar } from 'react-chartjs-2';

const useStyles = makeStyles((theme) => ({
    verticalBarChart: {
        borderRadius: theme.spacing(0.625),
        paddingLeft: theme.spacing(3.125),
        paddingBottom: theme.spacing(2.5),
        marginTop: '40px',
        position: 'relative',
    },
    overTextContainer: {

        position: 'absolute',
        marginTop: theme.spacing(-48.5),
    },
    overText: {
        marginBottom: theme.spacing(13.4),
        letterSpacing: '1.4px',
        fontSize: '14px'
    },
}));

const formatMoney = (element) => {
    return Number(element).toLocaleString('en', {
        maximumFractionDigits: 12
    });
}

export function NftHorizontalChart({ data, tooltipLabel, color, width, height, currencyState, ...props }) {
    const styles = useStyles();
    const [chartLabels, setChartLabels] = useState([]);
    const [chartValues, setChartValues] = useState([]);

    useEffect(() => {
        const labels = [];
        const values = [];

        for (let i = 0; i < data.length; i++) {
            labels.push(data[i].name);
            currencyState ? values.push(data[i].usdValue) : values.push(data[i].ethValue)
        }

        // if (data.length !== 4) {
        //     labels.push('');
        // }
        setChartLabels(labels);
        setChartValues(values);
    }, [data])

    const chartData = {
        labels: chartValues,
        datasets: [
            {
                label: tooltipLabel,
                data: chartValues,
                backgroundColor: color,
                borderColor: color,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        // barPercentage: 2,
        categoryPercentage: 0.65,
        scales: {
            y: {
                ticks: {
                    callback: function (value, index, values) {
                        if (currencyState) {
                            try {
                                if (chartData.labels.length) {
                                    return `$${formatMoney(chartData.labels[index])}`;
                                }
                                return '$0  ';
                            } catch (err) {
                                return '$0  ';
                            }
                        } else {
                            try {
                                if (chartData.labels.length) {
                                    return `ETH ${formatMoney(chartData.labels[index])}`;
                                }
                                return 'ETH 0  ';
                            } catch (err) {
                                return 'ETH 0  ';
                            }
                        }
                    },
                    color: 'white',
                    font: {
                        size: 16,
                        weight: 'bold'
                    },
                    crossAlign: 'far'
                },
                grid: {
                    drawTicks: false,
                    borderColor: '#1B1B1B',
                    display: false,
                },

            },
            x: {
                grid: {
                    drawTicks: false,
                    borderColor: '#1B1B1B',
                    display: false,
                },
                ticks: {
                    callback: function (value, index, values) {
                        return ``;
                    },
                },
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        return '';
                    },
                    title: function (context) {
                        let label = context[0].dataset.label || '';
                        if (label) {
                            label += ': ';
                        } else {
                            label = 'Earnings: ';
                        }
                        if (context[0].raw) {
                            currencyState ?
                                label += `$${formatMoney(context[0].raw)}`
                                :
                                label += `ETH ${formatMoney(context[0].raw)}`
                        }
                        return label;
                    }
                },
                titleFont: {
                    size: 16
                },
                titleMarginBottom: 0
            }
        },
    };

    return (
        <div>
            <div className={styles.verticalBarChart} style={{ width: width, height: height }}>
                <ChartJsBar className={styles.chartComponent} data={chartData} options={options} />
                {chartLabels.length ?
                    <div className={styles.overTextContainer}>
                        {chartLabels.map(label => (<p key={label} className={styles.overText}>{label}</p>))}
                    </div>
                    : null}
            </div>
        </div>
    )
}