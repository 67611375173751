import { makeStyles } from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useEffect, useState } from "react";
import SharesTable from "./SharesTable";
import { BrandLoader } from "../../../CoreComponents/BrandLoader";

const useStyles = makeStyles((theme) => ({
    sharesContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '550px',
        justifyContent: 'space-between',
    },
    header: {
        font: 'normal normal bold 20px/24px Roboto',
        minWidth: '175px'
    },
    icons: {
        cursor: 'pointer'
    },
    message: {
        textAlign: 'left',
        font: 'normal normal normal 14px/19px Roboto',
        letterSpacing: '1.4px',
        color: theme.palette.primary.attention,
        marginTop: '15px'
    },
    loader: {
        display: 'flex',
        justifyContent: "center"
    },
    alert: {
        color: theme.palette.primary.alert
    },
    successMsg: {
        color: theme.palette.primary.main
    }

}));

export default function Shares({
    title,
    tableHeaders,
    sharesData,
    alert,
    loading,
    originalData,
    setOriginalData,
    fetchData,
    isAdmin,
    expandCondition,
    isInManageShares
}) {
    const styles = useStyles();
    const [expandShares, setExpandShares] = useState(false);
    const [editAlert, setEditAlert] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const loaderOptions = {
        loaderHeight: 40,
        loaderWidth: 40
    };

    const expandHandler = (e) => {
        setExpandShares(!expandShares);
    };

    useEffect(() => {
        if (expandCondition === 'master' && tableHeaders.length <= 3) {
            expandHandler()
        } else if (expandCondition === 'composition' && tableHeaders > 3) {
            expandHandler()
        }
    }, [expandCondition])

    useEffect(() => {
        setTimeout(() => {
            setEditAlert('');
            setSuccessMsg('');
        }, 5000);
    }, [alert, successMsg]);

    return (
        <div className={styles.sharesContainer}>
            <div className={styles.headerContainer} >
                <div className={styles.header}>{title}</div>
                {
                    isLoading ?
                        <div className={styles.loader}>
                            <BrandLoader
                                height={loaderOptions.loaderHeight}
                                width={loaderOptions.loaderWidth}
                            />
                        </div>
                        :
                        ''
                }
                {
                    editAlert ?
                        <div className={styles.alert}>{editAlert}</div>
                        :
                        ''
                }
                {
                    successMsg ?
                        <div className={styles.successMsg}>{successMsg}</div>
                        :
                        ''
                }
                {
                    expandShares ?
                        <RemoveCircleOutlineIcon
                            className={styles.icons}
                            onClick={expandHandler}
                            fontSize="large"
                            color="secondary"
                        />
                        :
                        <AddCircleOutlineIcon
                            className={styles.icons}
                            onClick={expandHandler}
                            fontSize="large"
                            color="primary"
                        />
                }
            </div>
            {
                expandShares ?
                    loading ?
                        <div className={styles.loader}>
                            <BrandLoader
                                height={loaderOptions.loaderHeight}
                                width={loaderOptions.loaderWidth}
                            />
                        </div>
                        :
                        alert ?
                            <div className={styles.alert}>{alert}</div>
                            :
                            sharesData.length === 0 ?
                                <div className={styles.message}>There is no applicable data to be displayed. {isAdmin ? 'Click Upload CSV to add.' : "Please contact the admin."}</div>
                                :
                                <SharesTable
                                    title={title}
                                    tableHeaders={tableHeaders}
                                    sharesData={sharesData}
                                    originalData={originalData}
                                    setOriginalData={setOriginalData}
                                    fetchData={fetchData}
                                    setEditAlert={setEditAlert}
                                    setSuccessMsg={setSuccessMsg}
                                    editAlert={editAlert}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    isAdmin={isAdmin}
                                    isInManageShares={isInManageShares}
                                />
                    :
                    null
            }
        </div>
    );
}