import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useQuery } from '../utils/RouteUtils'
import { BrandButton, SecondaryBrandButton, TempBrandButton } from '../CoreComponents/BrandButton'
import { BrandPasswordInput, BrandInput } from '../CoreComponents/BrandInput'
import { BrandLoaderDots } from '../CoreComponents/BrandLoader'
import { Typography } from '@material-ui/core';
import { BrandLink } from '../CoreComponents/BrandLink'
import { BrandCheckbox } from '../CoreComponents/BrandCheckbox';
import { Link } from "@material-ui/core";
import { postData } from '../utils/FetchUtils'
import { BrandAlert } from '../CoreComponents/BrandAlert'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BrandModal } from '../CoreComponents/BrandModal'
import TermsAndConditions from './TermsAndConditions'
import { textIsEmpty, textIsPassword } from '../utils/ValidationUtils';
import clsx from 'clsx';
require('dotenv').config();

const dataValidators = {
    isRequired: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
    validatePassword: (value) => {
        const errors = []
        const res = textIsPassword(value);
        if (res !== true) {
            errors.push(res)
        }
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    }
}

const useStyles = makeStyles((theme) => ({
    submitBtn: {
        width: '100%',
    },
    input: {
        marginTop: 25,
    },
    title: {
        padding: 15
    },
    link: {
        marginTop: 30
    },
    termsAndConditions: {
        marginBottom: 30,
        "& .MuiLink-root": {
            '&:hover': {
                cursor: 'pointer'
            },
            marginLeft: -12
        },
    },
    error: {
        '& :nth-child(2)': {
            borderColor: '#F42A82 !important'
        }
    }
}));

const GetTermsAndConditionsDialog = ({ onClose }) => {
    return (
        <div className="termsAndCondtionsDialog">
            <Typography variant='h5' component='div'>
                Terms and Conditions
            </Typography>
            <TermsAndConditions />
            <SecondaryBrandButton onClick={onClose}>Close</SecondaryBrandButton>
        </div>
    );
}

export default function LoginPage(props) {
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { token } = useQuery()
    const history = useHistory();
    const firstName = useState('');
    const lastName = useState('');
    const password = useState('');
    const repeatPassword = useState('');
    const termsRead = useState(false);
    const firstNamePassed = useState(true);
    const lastNamePassed = useState(true);
    const passwordPassed = useState(true);
    const repeatPasswordPassed = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [alert, setAlert] = useState('');
    const [firstNameAlert, setFirstNameAlert] = useState(false);
    const [lastNameAlert, setLastNameAlert] = useState(false);

    useEffect(() => {
        if (!token) {
            history.push('/login')
        }
    }, [])

    useEffect(() => {
        if (!firstNamePassed[0] || !lastNamePassed[0] || !passwordPassed[0] || !repeatPasswordPassed[0]) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [firstNamePassed[0], lastNamePassed[0], passwordPassed[0], repeatPasswordPassed[0]])


    useEffect(() => {
        setAlert('');
    }, [firstName[0], lastName[0], password[0], repeatPassword[0], termsRead[0]])

    useEffect(() => {
        const pattern = /[^A-Za-z0-9 \p{Cyrillic}]/gm
        const firstNameCheck = firstName[0].match(pattern)
        const lastNameCheck = lastName[0].match(pattern)

        setFirstNameAlert(firstNameCheck)
        setLastNameAlert(lastNameCheck)

        if (firstNameCheck || lastNameCheck) {
            setAlert('Special characters are now allowed!')
            firstNamePassed[1](false)
            lastNamePassed[1](false)
        } else {
            setAlert('');
            firstNamePassed[1](true)
            lastNamePassed[1](true)
        }

    }, [firstName[0], lastName[0]]);

    function handleAcceptInvite(e) {
        e.preventDefault();
        try {
            setLoading(true)
            if (password[0] !== repeatPassword[0]) {
                throw Error('Please check if your passwords match.')
            }

            if (!termsRead[0]) {
                throw Error('Please accept the terms and conditions.')
            }

            postData(process.env.REACT_APP_SERVER_HOST + '/api/user/accept-invite', {
                token: token,
                firstName: firstName[0],
                lastName: lastName[0],
                password: password[0]
            })
                .then(data => {
                    if (data.error) {
                        setAlert(data.error)
                        setLoading(false)
                        return;
                    }
                    setLoading(false)
                    history.push('/login')
                }, error => {
                    setAlert(error.message)
                    setLoading(false)
                })
        } catch (err) {
            setAlert(err.message)
            setLoading(false)
        }
    }

    return (
        <div>
            <BrandModal
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                <GetTermsAndConditionsDialog onClose={() => setShowModal(false)} />
            </BrandModal>
            <Typography className={styles.title} variant='h5' component='div' gutterBottom>
                Registration
            </Typography>
            <form onSubmit={handleAcceptInvite}>
                <div className={clsx(styles.input, firstNameAlert ? styles.error : null)}>
                    <BrandInput $value={firstName} required validator={dataValidators.isRequired} validatorPassed={firstNamePassed} label='First Name' />
                </div>
                <div className={clsx(styles.input, lastNameAlert ? styles.error : null)}>
                    <BrandInput $value={lastName} required validator={dataValidators.isRequired} validatorPassed={lastNamePassed} label='Last Name' />
                </div>
                <div className={styles.input}>
                    <BrandPasswordInput $value={password} required validator={dataValidators.validatePassword} validatorPassed={passwordPassed} label='Password' />
                </div>
                <div className={styles.input}>
                    <BrandPasswordInput $value={repeatPassword} required validator={dataValidators.isRequired} validatorPassed={repeatPasswordPassed} label='Confirm Password' />
                </div>
                <div className={styles.termsAndConditions}>
                    <BrandCheckbox $value={termsRead} label='I accept the' />
                    <Link onClick={() => setShowModal(true)} underline='always'>
                        Terms and conditions
                    </Link>
                </div>
                {alert ? <BrandAlert>{alert}</BrandAlert> : null}
                <TempBrandButton capitalize={true} className={styles.submitBtn} disabled={submitDisabled} type='submit'>
                    {loading ? <BrandLoaderDots /> : 'Register'}
                </TempBrandButton>
                <div className={styles.link}>
                    <BrandLink to='/login'>Go to login page</BrandLink>
                </div>
            </form>
        </div>
    );
}