import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, List, ListItem, ListItemText, TablePagination, Typography } from "@material-ui/core";
import { BrandButton } from "../CoreComponents/BrandButton";
import { BrandInput } from "../CoreComponents/BrandInput";
import { useEffect, useState } from 'react';
import { deleteData, getData, postData, putData } from '../utils/FetchUtils';
import { ManageNftAutoComplete } from './ManageNftAutoComplete';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import { BrandAlert } from '../CoreComponents/BrandAlert';
import { BrandLoader } from '../CoreComponents/BrandLoader';
import { validateSingleInput } from '../utils/BlockchainUtils';

const useStyles = makeStyles(theme => ({
    manageOwnershipContainer: {
        width: '1265px',
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: 'white'
                }
            }
        }
    },
    manageOwnershipSearch: {
        marginLeft: '15px',
        width: '46%'
    },
    manageOwnershipSalesInput: {
        width: '33%',
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiInputBase-input': {
            textAlign: 'center'
        },
        paddingRight: theme.spacing(2)
    },
    ownershipAlert: {
        color: '#F42A82',
    },
    accHolderActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    list: {
        width: '100%',
        height: theme.spacing(50),
        overflow: 'auto'
    },
    alertMessage: {
        color: '#F42A82',
        textAlign: 'end',
        paddingRight: 50,
        paddingTop: 10,
    },
    bulkEditButton: {
        display: 'flex',
        width: '100px',
        gap: theme.spacing(0.5),
        alignItems: 'center',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        '& .MuiButtonBase-root': {
            padding: '6px',
        },
        paddingRight: theme.spacing(1)
    },
    ownershipTitles: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    ownerships: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(3),
    },
    manageOwnershipInputs: {
        width: theme.spacing(14)
    },
    ownership: {
        width: theme.spacing(20),
        '& .MuiInputBase-input': {
            width: theme.spacing(10),
            textAlign: 'center'
        }
    },
    ownershipTitlesMarginBottom: {
        marginBottom: '0px',
    },
    ownershipTitlesRemoveHoverEffect: {
        '& .MuiListItem-root': {
            '&:hover': {
                backgroundColor: 'transparent !important',
            }
        }
    },
    title: {
        marginLeft: '15px'
    },
    uploading: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
    saveMessage: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10
    },
    cancelButton: {
        color: '#FFFFFFDE',
        borderColor: '#FFFFFFDE',
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        font: 'normal normal normal 14px Roboto',
        textTransform: 'none'
    },
    saveButton: {
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        color: '#222222',
        font: 'normal normal normal 14px Roboto',
        textTransform: 'none'
    },
    saveButtonDisabled: {
        backgroundColor: '#08661d',
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        color: '#222222',
        font: 'normal normal normal 14px Roboto',
        textTransform: 'none',
        "&:hover": { backgroundColor: "#08661d" }
    }
}))

export const NftManageOwnership = ({ nft, onClose, setRefreshCatalog }) => {
    const styles = useStyles()
    const [nftAccountHolders, setNftAccountHolders] = useState([])
    const [invalidInputs, setInvalidInputs] = useState({});
    const [refresh, setRefresh] = useState({});
    const [disabledSave, setDisabledSave] = useState(false);
    const [primaryValues, setPrimaryValues] = useState(0);
    const [secondaryValues, setSecondaryValues] = useState(0);
    const [changedOwnerships, setChangedOwnerships] = useState([]);
    const [originalAccountHoldersInfo, setOriginalAccountHoldersInfo] = useState([]);
    const [showInputPrimary, setShowInputPrimary] = useState(false);
    const [showInputSecondary, setShowInputSecondary] = useState(false);
    const [assignedOwnerships, setAssignedOwnerships] = useState({ primaryOwnership: 0, secondaryOwnership: 0 });
    const [finishedSavingAlert, setFinishedSaving] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);

    const [disabledButtonClick, setDisabledButtonClick] = useState(false);

    const [removedAccountHolderFlag, setRemovedAccountHolderFlag] = useState(false);
    const [removedAcc, setRemovedAcc] = useState({});

    const indexNameForPrimary = 'p';
    const indexNameForSecondary = 's';

    const props = {
        nftAccountHolders,
        setInvalidInputs,
        primaryValues,
        setPrimaryValues,
        secondaryValues,
        setSecondaryValues,
        setDisabledSave
    };

    useEffect(() => {
        if (finishedSavingAlert) {
            setTimeout(() => {
                setFinishedSaving('');
            }, "4000")
        }
    }, [finishedSavingAlert])

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-catalog-account-holder-ownerships?nftId=${nft.id}`)
            .then(data => {
                setOriginalAccountHoldersInfo(JSON.parse(JSON.stringify(data.items)));
                if (changedOwnerships.length) {
                    for (const changedOwnership of changedOwnerships) {
                        const changedAccountHolderIndex = data.items.findIndex(x => x.id === changedOwnership.id);
                        if (changedAccountHolderIndex >= 0) {
                            data.items[changedAccountHolderIndex] = changedOwnership;
                        }
                    }
                }
                setNftAccountHolders(data.items);
            })
    }, [refresh]);


    useEffect(() => {
        if (nft && nft.assignedOwnerships) {
            setAssignedOwnerships(JSON.parse(nft.assignedOwnerships))
        }
    }, []);

    // The following 2 useEffects need to be refactored
    useEffect(() => {
        if (removedAccountHolderFlag) {
            const accountHoldersAfterRomeve = nftAccountHolders.filter((holder) => holder.id !== removedAcc.id);
            setPrimaryValues(accountHoldersAfterRomeve.reduce((prev, curr) => prev + (curr.primaryOwnership != null ? Number(curr.primaryOwnership) : 0), 0));
            setSecondaryValues(accountHoldersAfterRomeve.reduce((prev, curr) => prev + (curr.secondaryOwnership != null ? Number(curr.secondaryOwnership) : 0), 0));

            setAssignedOwnerships({ primaryOwnership: primaryValues, secondaryOwnership: secondaryValues });
        }
        // setRemovedAccountHolder(false);
    }, [removedAccountHolderFlag])

    useEffect(() => {
        if (nftAccountHolders.length > 0) {
            if (removedAccountHolderFlag) {
                setNftAccountHolders(nftAccountHolders.filter((holder) => holder.id !== removedAcc.id));
                setChangedOwnerships(changedOwnerships.filter((holder) => holder.id !== removedAcc.id));
                setPrimaryValues(nftAccountHolders.reduce((prev, curr) => prev + (curr.primaryOwnership != null ? Number(curr.primaryOwnership) : 0), 0));
                setSecondaryValues(nftAccountHolders.reduce((prev, curr) => prev + (curr.secondaryOwnership != null ? Number(curr.secondaryOwnership) : 0), 0));

                setAssignedOwnerships({ primaryOwnership: primaryValues, secondaryOwnership: secondaryValues });
                setRemovedAccountHolderFlag(false);

                setRefresh({});
                setRefreshCatalog({});
            } else {
                setPrimaryValues(nftAccountHolders.reduce((prev, curr) => prev + (curr.primaryOwnership != null ? Number(curr.primaryOwnership) : 0), 0));
                setSecondaryValues(nftAccountHolders.reduce((prev, curr) => prev + (curr.secondaryOwnership != null ? Number(curr.secondaryOwnership) : 0), 0));
            }
        }
    }, [nftAccountHolders]);

    const onChangeBulkInput = (e, isPrimary) => {
        let target = e.target.value;
        let tempNftsBulk;


        if (isPrimary) {
            nftAccountHolders.map((el, index) => {
                tempNftsBulk = validateSingleInput(props, indexNameForPrimary, index, target, isPrimary,true);
            })
        } else {
            nftAccountHolders.map((el, index) => {
                tempNftsBulk = validateSingleInput(props, indexNameForSecondary, index, target, isPrimary,true);
            })
        }

        setNftAccountHolders([...tempNftsBulk]);
        setChangedOwnerships([...tempNftsBulk]);
        setDisabledButtonClick(false);
        setDisabledSave(false);
    }



    const onChangeInput = (e, accHolderInfo, index, isPrimary) => {
        let manageOwnershipInputValue = e.target.value;
        let tempNfts;

        if (isPrimary) {
            tempNfts = validateSingleInput(props, indexNameForPrimary, index, manageOwnershipInputValue, isPrimary);
        } else {
            tempNfts = validateSingleInput(props, indexNameForSecondary, index, manageOwnershipInputValue, isPrimary);
        }

        const foundIndex = originalAccountHoldersInfo.findIndex(el => el.id === accHolderInfo.id)

        if (originalAccountHoldersInfo[foundIndex].primaryOwnership != accHolderInfo.primaryOwnership || originalAccountHoldersInfo[foundIndex].secondaryOwnership != accHolderInfo.secondaryOwnership) {

            const foundIndex = changedOwnerships.findIndex(el => el.id == accHolderInfo.id)

            if (foundIndex < 0) {
                setChangedOwnerships([...changedOwnerships, accHolderInfo])
            } else {
                changedOwnerships[foundIndex] = accHolderInfo
                setChangedOwnerships([...changedOwnerships])
            }
        } else {
            setChangedOwnerships(changedOwnerships.filter(el => el.id != accHolderInfo.id))
        }

        setNftAccountHolders([...tempNfts]);
        setDisabledButtonClick(false);
    }

    useEffect(() => {
        let invalid = Object.keys(invalidInputs);

        if (changedOwnerships.length === 0 || invalid.length > 0) {
            setDisabledSave(true)
        } else {
            if (primaryValues > 100 || secondaryValues > 100) {
                setDisabledSave(true);
            } else {
                setDisabledSave(false);
            }
        }

    }, [primaryValues, secondaryValues, originalAccountHoldersInfo, nftAccountHolders, changedOwnerships]);

    const onClickSave = () => {
        if (!disabledSave && changedOwnerships.length > 0) {
            setIsSaving(true)
            const reqObj = {
                nftId: nft.id,
                ownerships: changedOwnerships
            };

            putData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-catalog-nft-ownerships`, reqObj)
                .then(data => {
                    setChangedOwnerships([]);
                    setRefresh({});
                    setRefreshCatalog({});
                    setIsSaving(false)
                    setSavedSuccessfully(true);
                    setFinishedSaving('Saved successfully');
                }, error => {
                    console.log(error);
                    setChangedOwnerships([]);
                    setRefresh({});
                    setRefreshCatalog({});
                })

            setAssignedOwnerships({ primaryOwnership: primaryValues, secondaryOwnership: secondaryValues });
            setDisabledButtonClick(false);
            setShowInputPrimary(false);
            setShowInputSecondary(false);
        } else {
            setDisabledButtonClick(true);
        }
    }

    const bulkInputHandler = (e, isPrimary) => {
        const target = e.target.value
        let tempNfts;

        if (e.code === 'Enter') {
            if (isPrimary) {
                nftAccountHolders.map((el, index) => {
                    tempNfts = validateSingleInput(props, indexNameForPrimary, index, target, isPrimary);
                })
                setShowInputPrimary(false)
            } else {

                nftAccountHolders.map((el, index) => {
                    tempNfts = validateSingleInput(props, indexNameForSecondary, index, target, isPrimary);
                })
                setShowInputSecondary(false);
            }
  
            setNftAccountHolders([...tempNfts]);
            setChangedOwnerships([...tempNfts]);
        }
    }

    return (
        <div className={styles.manageOwnershipContainer}>
            <Typography variant="h6" className={styles.title}>
                Manage Ownership
            </Typography>
            <div className={styles.ownershipTitlesRemoveHoverEffect}>
                <ListItem className={clsx(styles.ownershipTitles, styles.ownershipTitlesMarginBottom)}>
                    <ListItemText primary={`Total Assigned Ownership for ${nft.title}`} />
                    <div className={styles.ownerships}>
                        <div className={styles.ownership}>
                            Primary: {assignedOwnerships.primaryOwnership}%
                        </div>
                        <div className={styles.ownership}>
                            Secondary: {assignedOwnerships.secondaryOwnership}%
                        </div>
                    </div>
                </ListItem>
            </div>
            <div className={styles.manageOwnershipSearch}>
                <ManageNftAutoComplete
                    nftId={nft.id}
                    onRemoveSelectedAccountHolder={(accountHolder) => {
                        deleteData(process.env.REACT_APP_SERVER_HOST + `/api/nft/remove-account-holder-from-nft`, accountHolder)
                            .then(data => {
                                setRefresh({});
                            }, error => {
                                console.log(error);
                            })
                        setRemovedAccountHolderFlag(true);
                        setRemovedAcc(accountHolder);
                    }}
                    onAddSelectedAccountHolder={(accountHolder) => {
                        postData(process.env.REACT_APP_SERVER_HOST + `/api/nft/add-account-holder-to-nft`, accountHolder)
                            .then(data => {
                                setRefresh({});
                            }, error => {
                                console.log(error);
                            })
                    }}
                />
            </div>
            <div className={styles.alertMessage}>
                <div>
                    {
                        primaryValues > 100 ?
                            'Sum of Primary Ownerships must be less or equal to 100'
                            : ''
                    }
                </div>
                <div>
                    {
                        secondaryValues > 100 ?
                            'Sum of Secondary Ownerships must be less or equal to 100'
                            : ''
                    }
                </div>
            </div>
            <List className={styles.list}>
                <div className={styles.ownershipTitlesRemoveHoverEffect}>
                    <ListItem className={styles.ownershipTitles}>
                        <ListItemText primary={'Account Holder(s)'} />
                        <div className={styles.ownerships}>
                            <div className={styles.ownership}>
                                Primary Ownership
                            </div>
                            <div className={styles.ownership}>
                                Secondary Ownership
                            </div>
                        </div>
                    </ListItem>
                </div>
                <div className={styles.ownershipTitlesRemoveHoverEffect}>
                    <ListItem className={styles.ownershipTitles}>
                        <ListItemText primary={''} />
                        <div className={styles.ownerships}>
                            <div className={styles.ownership}>
                                {showInputPrimary ?
                                    <BrandInput
                                        onKeyUp={(e) => bulkInputHandler(e, true)}
                                        onChange={(e) => e.target.value == '' ? setDisabledSave(true) : onChangeBulkInput(e, true)}
                                    />
                                    :
                                    <div
                                        className={styles.bulkEditButton}
                                        onClick={(e) => setShowInputPrimary(true)}
                                    >
                                        < EditIcon />
                                        Bulk Edit
                                    </div>
                                }
                            </div>
                            <div className={styles.ownership}>
                                {showInputSecondary ?
                                    <BrandInput
                                        onKeyUp={bulkInputHandler}
                                        onChange={(e) => e.target.value == '' ? setDisabledSave(true) : onChangeBulkInput(e, false)}
                                    />
                                    :
                                    <div
                                        className={styles.bulkEditButton}
                                        onClick={(e) => setShowInputSecondary(true)}
                                    >
                                        < EditIcon />
                                        Bulk Edit
                                    </div>
                                }
                            </div>
                        </div>
                    </ListItem>
                </div>
                {
                    nftAccountHolders.map((accHolderInfo, index) => {
                        return (
                            <ListItem key={accHolderInfo.id} className={styles.ownershipTitles}>
                                <ListItemText primary={accHolderInfo.name} />
                                <div className={styles.ownerships}>
                                    <div className={styles.ownership}>
                                        <BrandInput

                                            InputProps={{
                                                endAdornment: (
                                                    accHolderInfo.primaryOwnership > 0 ?
                                                        <InputAdornment position="start">%</InputAdornment>
                                                        :
                                                        null
                                                )
                                            }}
                                            value={accHolderInfo.primaryOwnership}
                                            classes={{ input: styles.manageOwnershipInputs }}
                                            onChange={(e) => onChangeInput(e, accHolderInfo, index, true)}
                                        />
                                        <div className={styles.ownershipAlert}>
                                            {invalidInputs[`p${index}`] ? 'Ownership must be a number' : ''}
                                        </div>
                                    </div>
                                    <div className={styles.ownership}>
                                        <BrandInput
                                            InputProps={{
                                                endAdornment: (
                                                    accHolderInfo.secondaryOwnership > 0 ?
                                                        <InputAdornment position="start">%</InputAdornment>
                                                        :
                                                        null
                                                )
                                            }}
                                            value={accHolderInfo.secondaryOwnership}
                                            classes={{ input: styles.manageOwnershipInputs }}
                                            onChange={(e) => onChangeInput(e, accHolderInfo, index, false)}
                                        />
                                        <div className={styles.ownershipAlert}>
                                            {invalidInputs[`s${index}`] ? 'Ownership must be a number' : ''}
                                        </div>
                                    </div>
                                </div>
                            </ListItem>
                        )
                    })
                }
            </List >
            {disabledButtonClick &&
                <p style={{ color: '#F42A82', marginLeft: '80%' }}>Change Ownerships to save!</p>
            }
            <div className={styles.accHolderActions}>
                <BrandButton
                    variant='outlined'
                    onClick={() => onClose()}
                    className={styles.cancelButton}
                >
                    Cancel
                </BrandButton>
                <BrandButton
                    // disabled={disabledSave}
                    onClick={onClickSave}
                    className={disabledSave ? styles.saveButtonDisabled : styles.saveButton}
                >
                    {isSaving ?
                        <div className={styles.uploading}>
                            <BrandLoader color='white' width={20} height={20} />
                            <div>Saving</div>
                        </div>
                        :
                        'Save'
                    }
                </BrandButton>
            </div>
            <div className={styles.saveMessage} >
                {finishedSavingAlert ? <BrandAlert success={savedSuccessfully}>{finishedSavingAlert}</BrandAlert> : null}
            </div>
        </div >
    )

}