import { Slide, makeStyles } from "@material-ui/core"
import recordGIF from '../../assets/recordHomePage.gif'
import { useRef } from "react";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '539px',
        height: '565px',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            width: '240px',
            height: '365px',
        }
    },
    imgGif: {
        width: '539px',
        height: '565px',
        [theme.breakpoints.down('sm')]: {
            width: '320px',
            height: '345px',
        }
    }
}))
export default function HomePage() {
    const styles = useStyles();
    const imgRef = useRef();

    const handleLoad = () => {
        // This will make the GIF loop infinitely
        setTimeout(() => {
            if (imgRef.current) {
                imgRef.current.setAttribute('src', imgRef.current.src);
            }
        }, 5000)

    };

    return (
        <Slide
            timeout={500}
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
        >
            <div className={styles.container}>
                <img
                    className={styles.imgGif}
                    ref={imgRef}
                    src={recordGIF}
                    alt="record-gif-animation"
                    onLoad={handleLoad}
                />
            </div>
        </Slide>
    )
}