const { Popover, Typography } = require("@material-ui/core")

const ListHover = ({styles,open,handlePopoverOpen,handlePopoverClose,anchorEl,items}) => {

    return (
        <div className={styles.songDataRowData}>
            <div>
                <div
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    {!items || items.length === 0 ?
                        "N/A"
                        :
                        items.length === 1 ?
                        items[0]
                            :
                            `${items[0]}; +${items.length - 1}`
                    }
                </div>
                <Popover
                    id="mouse-over-popover"
                    className={styles.popover}
                    classes={{
                        paper: styles.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    {
                        Array.isArray(items) ? items.map(x => {
                            return (
                                <Typography key={x} color='primary'>{x}</Typography>
                            )
                        }) : ''
                    }
                </Popover>
            </div>
    </div>
    )
}

export default ListHover;