import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useQuery } from '../utils/RouteUtils'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useStoreContext } from '../../store/Store';
import AuthPage from '../CoreComponents/AuthPage';
import zipFile from '../../assets/zipFile.svg'
import { TempBrandButton } from '../CoreComponents/BrandButton.js'
import { BrandAlert } from '../CoreComponents/BrandAlert';
import { BrandLoaderDots } from '../CoreComponents/BrandLoader';
import GetAppIcon from '@material-ui/icons/GetApp';
import { download, getData } from '../utils/FetchUtils';

import { Avatar, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

require('dotenv').config();

const useStyles = makeStyles((theme) => ({
    svg: {
        width: '100%',
        height: 'auto',
        marginBottom: theme.spacing(3)
    },
    downloadButton: {
        color: 'white',
        textTransform: 'capitalize',
        font: 'normal normal bolder 14px/18px Roboto',
        letterSpacing: '1.4px',
        height: 50,
        width: 180,
        marginTop: theme.spacing(2),
        '& .MuiSvgIcon-root': {
            fontSize: '35px'
        }
    },
    downloadButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },

}));

// zip icon downloaded from here: https://www.svgrepo.com/svg/63536/zip-file
const ShareDiligenceContext = ({ token }) => {
    const styles = useStyles();
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDownloaded, setIsDownloaded] = useState(false);
    const [alert, setAlert] = useState('');
    const [song, setSong] = useState({});

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/report/song-diligence-data?token=${token}`)
            .then(data => {
                setSong(data.data)
            }, err => {
                setAlert(err.message);
            })
    }, [])

    function downloadFiles() {
        setIsDownloading(true);
        download(process.env.REACT_APP_SERVER_HOST + '/api/report/song-diligence-report-file-stream', { token: token }, 'zip')
            .then(data => {
                setIsDownloaded(true);
                setIsDownloading(false);
            }, err => {
                setAlert(err.message);
                setIsDownloaded(false);
                setIsDownloading(false);
            })
    }

    return (
        < div className={styles.container}>
            <img src={zipFile} alt='zip_file' className={styles.svg} />
            <ListItem >
                <ListItemIcon>
                    <Avatar
                        alt={`Avatar of ${song.name ? song.name + ' - ' : ''}${song.title}`}
                        src={song?.coverArt || null}
                    />
                </ListItemIcon>
                <ListItemText primary={`${song.name ? song.name + ' - ' : ''}${song.title}`} />
            </ListItem>
            {alert ? <BrandAlert success={isDownloaded}>{alert}</BrandAlert> : null}
            <div className={styles.downloadButtonContainer}>
                <TempBrandButton onClick={downloadFiles} disabled={isDownloading} className={styles.downloadButton} startIcon={isDownloading ? null : <GetAppIcon />} >
                    {isDownloading ? <BrandLoaderDots /> : 'Download Zip'}
                </TempBrandButton>
            </div>
        </div>
    );
}


export default function ShareDiligence(props) {
    const { token } = useQuery()
    const history = useHistory();
    const [state, setState] = useStoreContext();

    useEffect(() => {
        if (!token) {
            history.push('/')
        }
        setState(state => (state.hideNavigation = true, state.disableRootPadding = true, { ...state }));
        return () => { setState(state => (state.hideNavigation = false, state.disableRootPadding = false, { ...state })) }
    }, [])

    return (
        !state.user ?
            <ShareDiligenceContext token={token} />
            :
            <AuthPage>
                <ShareDiligenceContext token={token} />
            </AuthPage>
    );
}