import RecursiveSongView from "./RecursiveSongView";

const MergedSongsTree = ({ 
    songsData, 
    expandedItems, 
    setSelectedSong,
    setExpandedItems,
    setShowUnmergeModal,
    setShowMoveModal
}) => {
    const toggleExpand = (itemId) => {
        setExpandedItems((prevExpandedItems) => (
            prevExpandedItems.includes(itemId)
                ? prevExpandedItems.filter((id) => id !== itemId)
                : [...prevExpandedItems, itemId]
        ));
    }

    return (
        <RecursiveSongView 
            object={songsData} 
            toggleExpand={toggleExpand}
            expandedItems={expandedItems}
            setSelectedSong={setSelectedSong}
            setShowUnmergeModal={setShowUnmergeModal}
            setShowMoveModal={setShowMoveModal}
        />
    )
}

export default MergedSongsTree;