import { 
    Box, 
    TextField, 
    makeStyles,
    InputAdornment
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { useEffect, useRef } from "react";

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '290px',
        rowGap: '10px',
        padding: '2.5px 20px',
        borderRadius: theme.spacing(2.5),
        border: '1px solid gray',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: theme.spacing(5)
    },
    contentWrapper: {
        position: 'relative'
    }
}));

const CustomAutocomplete = ({ 
    children, 
    showOptions,
    searchQuery, 
    setSearchQuery,
    setShowOptions
}) => {
    const componentRef = useRef(null);

    const styles = useStyles();

    const handleOutsideClick = (e) => {
        if (componentRef.current && !componentRef.current.contains(e.target)) {
            setShowOptions(false);
        }
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            handleOutsideClick(e);
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <Box 
            ref={componentRef} 
            className={styles.contentWrapper} 
        >
            <TextField
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder='Search for song'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchOutlined />
                        </InputAdornment>
                    ),
                    disableUnderline: true
                }}
                className={styles.buttonWrapper}
                onFocus={() => setShowOptions(true)}
            />
            { showOptions && children }
        </Box>
    )
}

export default CustomAutocomplete;