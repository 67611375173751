import { isUndefined, omitBy, trimEnd, trimStart } from "lodash";
import { useEffect } from "react";

const baseUrl = process.env.REACT_APP_SERVER_HOST;
let controller = new AbortController();

export const forceAbortFetch = () => {
    controller.abort();
    controller = new AbortController();
}

const useAbortableFetch = () => {
    useEffect(() => {
        return () => {
            controller.abort();
            controller = new AbortController();
        };
    }, []);

    const fetchWithAbort = (method, path, options = {}) => {
        const queryWithoutUndefined = omitBy(options.query, isUndefined);

        const queryParams = new URLSearchParams(queryWithoutUndefined).toString();
        const url = `${trimEnd(baseUrl, '/')}/${trimStart(path, '/')}?${queryParams}`;

        const response = fetch(url, {
            method,
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            signal: controller.signal,
            ...(options.body ? { body: JSON.stringify(options.body) } : {}),
            headers: {
                ...(options.body ? { 'Content-Type': 'application/json' } : {})
            }
        });

        return response;
    };

    return fetchWithAbort;
};

export default useAbortableFetch;