import { Box, MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import { BrandInput } from "../CoreComponents/BrandInput";
import { ExpandMore } from "@material-ui/icons";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    expandMore: {
        right: theme.spacing(0.5),
        position: 'absolute',
        cursor: 'pointer'
    },
    selectedOption: {
        color: theme.palette.background.lime,
        backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '10px',
        alignItems: 'center'
    }
}));

const CustomFilterDropdown = ({
    label,
    options,
    selectedOption, 
    setSelectedOption
}) => {
    const [open, setOpen] = useState(false);

    const styles = useStyles();

    const handleOpenDropdown = () => setOpen(true);
    const handleCloseDropdown = () => setOpen(false);
    const handleToggleDropdown = () => setOpen(!open);

    const handleSelectOptionOnChange = (e) => {
        setSelectedOption(e.target.value);
    }

    return (
        <Box className={styles.wrapper}>
            { label && <Typography>{label}</Typography>}
            <Select
                open={open}
                value={selectedOption}
                onChange={handleSelectOptionOnChange}
                input={<BrandInput />}
                IconComponent={() => (
                    <ExpandMore 
                        fontSize="large" 
                        className={styles.expandMore}
                        onClick={handleToggleDropdown} 
                    />
                )}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null
                }}
                onOpen={handleOpenDropdown}
                onClose={handleCloseDropdown}
            >
                { options && options.length > 0 ? options.map((option, index) => (
                    <MenuItem 
                        value={option.value} 
                        selected={selectedOption.value === option.value} 
                        classes={{ selected: styles.selectedOption }}
                        key={index}
                    >
                        {option.label}
                    </MenuItem>
                )) : (
                    <Typography>
                        No options
                    </Typography>
                ) }
            </Select>
        </Box>
    )
}

export default CustomFilterDropdown;