import { makeStyles } from "@material-ui/core"
import { Button, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from "react";
import { Link } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        zIndex: 1,
        width: '100%',
        padding: '14px 0px 15px 5px',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            alignItems: 'end'
        },
    },
    drawerContainer: {
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a:hover': {
            color: theme.palette.primary.main
        }
    },
    loginBtn: {
        display: 'flex',
        backgroundColor: theme.palette.primary.contrastText,
        width: '85px',
        height: '36px',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '4px',
        marginRight: '20px',
        borderRadius: '18px',
        color: `${theme.palette.background.dark} !important`,
        fontSize: '16px'
    },
    hamburgerMenu: {
        '& .MuiSvgIcon-root': {
            width: '30px',
            height: '30px',
        }
    }
}))
export default function HomePageMobileMenu() {
    const styles = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className={styles.container}>
            <div className={styles.hamburgerMenu}>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MenuIcon />
                </Button>
                <Menu
                    id="basic-menu"
                    className={styles.drawerContainer}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                    }}
                    >
                        <Link to='/contact'>
                            Contact
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                    }}
                    >
                        <Link to='/learn-more'>
                            Learn More
                        </Link>
                    </MenuItem>
                </Menu>
            </div>
            <div>
                <Link className={styles.loginBtn} to='/login'>
                    Login
                </Link>
            </div>
        </div>
    )
}