import { fetchConstants } from "../../constants/httpConstants";

function makeFetchData(method, data, isFormData) {
    // Default options are marked with *
    const options = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'same-origin', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
    if (isFormData) {
        options.body = data; // body data type must match "Content-Type" header
    } else {
        options.body = data ? JSON.stringify(data) : null; // body data type must match "Content-Type" header
        options.headers = { 'Content-Type': 'application/json' }
    }

    return options;
}

function fetchData(url, request) {
    return fetch(url, request);
}

export function getData(url = '') {
    const [baseUrl, queryString] = url.split(fetchConstants.URL_SEPARATOR);

    if (queryString) {
        const params = new URLSearchParams(queryString);

        const encodedParams = Array.from(params.keys())
            .map(key => `${key}=${encodeURIComponent(params.get(key))}`)
            .join(fetchConstants.PARAM_SEPARATOR);

        url = `${baseUrl}?${encodedParams}`
    } 

    return fetchData(url, makeFetchData(fetchConstants.HTTP_GET_METHOD));
}

export function postFormData(url = '', formData) {
    return fetchData(url, makeFetchData('POST', formData, true));
}

export function postData(url = '', data = {}) {
    return fetchData(url, makeFetchData('POST', data));
}

export function postWithOriginalFetch(url = '', data = {}) {
    return window.originalFetch(url, makeFetchData('POST', data));
}

export function patchData(url = '', data = {}) {
    return fetchData(url, makeFetchData('PATCH', data));
}

export function putData(url = '', data = {}) {
    return fetchData(url, makeFetchData('PUT', data));
}

export function deleteData(url = '', data = {}) {
    return fetchData(url, makeFetchData('DELETE', data));
}


export async function download(url = '', data = {}, fileType) {
    return new Promise((resolve, reject) => {
        postWithOriginalFetch(url, data)
            .then(async response => {
                if (response.status !== 200) {
                    const resJson = await response.json();
                    throw new Error(resJson.msg);
                }
                return response.blob()
            })
            .then(blob => {
                const dateStamp = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `reports_${dateStamp}.${fileType}`;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again   
                resolve({ success: true });
            }, err => {
                reject({ success: false, message: err.message })
            });
    })
}


// postData('https://example.com/answer', { answer: 42 })
//     .then(data => {
//         console.log(data); // JSON data parsed by `data.json()` call
//     });

export function randomInt(minOrMaxValue, maxValue) {
    if (!maxValue) {
        return Math.floor(Math.random() * minOrMaxValue);
    }

    return Math.floor(minOrMaxValue + Math.random() * (maxValue - minOrMaxValue));
}

export function debounce(onInput, func, wait = 300) {
    let timeout;
    function debounced(...args) {
        const later = () => {
            func.apply(this, args);
        };

        clearTimeout(timeout);
        onInput.apply(this, args);
        timeout = setTimeout(later, wait);
    }

    debounced.clear = () => {
        clearTimeout(timeout);
    };

    return debounced;
}
