import React, { useState, useEffect } from "react";
import { ExpandMore } from "@material-ui/icons";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { endOfMonth, endOfYear } from 'date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Box, InputAdornment, Typography, Divider, Popover, Button, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { parseDate } from '../utils/DateUtils'
import clsx from 'clsx';
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { useStoreContext } from '../../store/Store';

const useStyles = makeStyles((theme) => ({
    datePicker: {
        width: '100%',
        '& .MuiFormControl-root': {
            width: '100%',
            marginTop: 0,
            marginBottom: 0,
        },
        "& .Mui-focused": {
            color: "white"
        },
        "& .MuiInputBase-root": {
            '&::before': {
                content: 'none'
            },
            '&::after': {
                content: 'none'
            },
            width: '100%',
            height: theme.spacing(5),
            borderRadius: theme.spacing(2.5),
            boxSizing: 'border-box',
            padding: '8px 16px',
            background: '#000000',
            color: 'white',
            '& .MuiSvgIcon-root': {
                color: 'white'
            },
        }
    },
    sus: {
        '& .MuiButtonBase-root': {
            backgroundColor: 'transparent'
        },
        '& .MuiPickersStaticWrapper-staticWrapperRoot': {
            backgroundColor: 'transparent'
        }
    }
}))

export function BrandDatePicker({ label = 'Choose Date', selectedDate, setSelectedDate, maxDate, minDate }) {
    const styles = useStyles();
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        maxDate && minDate ?
            <div className={styles.datePicker}>
                {label ?
                    <Typography
                        component='div'
                        variant='subtitle2'
                    >
                        {label}
                    </Typography>
                    :
                    null
                }
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ExpandMoreIcon />
                                </InputAdornment>
                            )
                        }}
                        minDate={parseDate(minDate)}
                        maxDate={parseDate(maxDate)}
                        disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        value={selectedDate}
                        onChange={handleDateChange}
                        minDateMessage={`Date should not be before ${parseDate(minDate)}`}
                        maxDateMessage={`Date should not be after ${parseDate(maxDate)}`}
                    />
                </MuiPickersUtilsProvider>
            </div >
            :
            maxDate ?
                <div className={styles.datePicker}>
                    {label ?
                        <Typography
                            component='div'
                            variant='subtitle2'
                        >
                            {label}
                        </Typography>
                        :
                        null
                    }
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ExpandMoreIcon />
                                    </InputAdornment>
                                )
                            }}
                            maxDate={parseDate(maxDate)}
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            value={selectedDate}
                            onChange={handleDateChange}
                            maxDateMessage={`Date should not be after ${parseDate(maxDate)}`}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                :
                minDate ?
                    <div className={styles.datePicker}>
                        {label ?
                            <Typography
                                component='div'
                                variant='subtitle2'
                            >
                                {label}
                            </Typography>
                            :
                            null
                        }
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <ExpandMoreIcon />
                                        </InputAdornment>
                                    )
                                }}
                                minDate={parseDate(minDate)}
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="yyyy-MM-dd"
                                margin="normal"
                                value={selectedDate}
                                onChange={handleDateChange}
                                minDateMessage={`Date should not be before ${parseDate(minDate)}`}
                            />
                        </MuiPickersUtilsProvider>
                    </div >
                    :
                    <>
                        <div className={styles.datePicker}>
                            {label ?
                                <Typography
                                    component='div'
                                    variant='subtitle2'
                                >
                                    {label}
                                </Typography>
                                :
                                null
                            }
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ExpandMoreIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </div >
                    </>
    );
}

const useBrandDateRangeStyles = makeStyles((theme) => ({
    button: {
        padding: '2.5px 20px',
        borderRadius: theme.spacing(2.5),
        border: '1px solid gray',
        width: '255px',
        textTransform: 'capitalize'
    },
    buttonIcon: {
        marginLeft: 'auto',
        marginRight: '-10px'
    },
    popoverContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        '& .rdp-root': {
            padding: '10px 20px',
            '--rdp-accent-color': theme.palette.primary.main,
            '--rdp-range_start-color': theme.palette.text.black,
            '--rdp-range_end-color': theme.palette.text.black,
            '& .rdp-selected .rdp-day_button': {
                background: 'var(--rdp-accent-color)',
                color: theme.palette.text.black
            }
        }
    },
    divider: {
        backgroundColor: 'gray'
    },
    optionsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
        margin: '20px 10px 0px 10px',
        alignItems: 'flex-start'
    },
    option: {
        textTransform: 'capitalize',
    },
    selectedBackground: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.black,
        '&:hover': {
            color: theme.palette.text.primary
        }
    }
}));

const JANUARY = 0; 
const DECEMBER = 11; 
const START_OF_MONTH_DAY = 1; 
const END_OF_MONTH_DAY = 31; 
const DEFAULT_BUTTON_LABEL = 'Select Date';

const DateRangeOptions = {
    AllTime: 'All Time',
    LastQuarter: 'Last Quarter',
    LastYear: 'Last Year',
    Custom: 'Custom'
};

export function BrandDateRange({ startDate, setStartDate, endDate, setEndDate }) {
    const styles = useBrandDateRangeStyles();

    const [store] = useStoreContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dateRangeOption, setDateRangeOption] = useState(DateRangeOptions.Custom);
    const [buttonLabel, setButtonLabel] = useState(DEFAULT_BUTTON_LABEL);

    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        switch (dateRangeOption) {
            case DateRangeOptions.LastQuarter:
                setStartDate(new Date(year, month - 3, 1));
                setEndDate(endOfMonth(new Date(year, month - 1, 1)));
                break;
            case DateRangeOptions.LastYear:
                setStartDate(new Date(year - 1, JANUARY, START_OF_MONTH_DAY));
                setEndDate(new Date(year - 1, DECEMBER, END_OF_MONTH_DAY));
                break;
            case DateRangeOptions.AllTime:
                setStartDate(store.defaultDateRangeStartDate);
                setEndDate(today);
                break;
            default:
                break;
        }
    }, [dateRangeOption])

    useEffect(() => {
        if (startDate && endDate) {
            setButtonLabel(`${parseDate(startDate)} - ${parseDate(endDate)}`);
        } else {
            setButtonLabel(DEFAULT_BUTTON_LABEL);
        }
    }, [startDate, endDate]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleSelectDateRangeOption = (e) => {
        setDateRangeOption(e.target.innerText);
    }

    return (
        <div>
            <Button className={styles.button} onClick={handleClick}>
                {buttonLabel}
                <ExpandMore fontSize='large' className={styles.buttonIcon} />
            </Button>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Box className={styles.popoverContentWrapper}>
                    <DayPicker
                        month={startDate}
                        mode="single"
                        captionLayout="dropdown"
                        selected={startDate}
                        onSelect={setStartDate}
                        disabled={{ after: endDate }}
                    />
                    <Divider orientation='vertical' flexItem className={styles.divider} />
                    <DayPicker
                        month={endDate}
                        mode="single"
                        captionLayout="dropdown"
                        selected={endDate}
                        onSelect={setEndDate}
                        disabled={{ before: startDate }}
                    />
                    <Divider orientation='vertical' flexItem className={styles.divider} />
                    <Box className={styles.optionsWrapper}>
                        <Button className={clsx(styles.option, dateRangeOption === DateRangeOptions.AllTime ? styles.selectedBackground : null)} onClick={handleSelectDateRangeOption}>{DateRangeOptions.AllTime}</Button>
                        <Button className={clsx(styles.option, dateRangeOption === DateRangeOptions.LastYear ? styles.selectedBackground : null)} onClick={handleSelectDateRangeOption}>{DateRangeOptions.LastYear}</Button>
                        <Button className={clsx(styles.option, dateRangeOption === DateRangeOptions.LastQuarter ? styles.selectedBackground : null)} onClick={handleSelectDateRangeOption}>{DateRangeOptions.LastQuarter}</Button>
                        <Button className={clsx(styles.option, dateRangeOption === DateRangeOptions.Custom ? styles.selectedBackground : null)} onClick={handleSelectDateRangeOption}>{DateRangeOptions.Custom}</Button>
                    </Box>
                </Box>
            </Popover>
        </div>
    )
}