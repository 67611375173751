import React, { useState, useEffect, } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Switch, Tooltip, Typography } from '@material-ui/core';
import { BrandCheckbox } from '../CoreComponents/BrandCheckbox';
import { TempBrandButton, TempBrandDeleteButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { getData, postData } from '../utils/FetchUtils';
import { useStoreContext } from '../../store/Store';
import { BrandAlert } from '../CoreComponents/BrandAlert';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ConfirmationDialog from '../CoreComponents/ConfirmationDialog';
import { AlternativeNamesActions } from '../../constants/alternativeNamesConstants';
import { ButtonSize } from '../../constants/buttonConstants';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '1300px',
        height: '670px',
        padding: '0px 36px 0px 36px'
    },
    mainHeader: {
        letterSpacing: '1.26px',
        font: 'normal normal bold 24px/21px Roboto',
    },
    artistAndSongNames: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        marginTop: '20px',
        marginBottom: '32px',
        font: 'normal normal normal 20px/21px Roboto',
        letterSpacing: '1px',
    },
    songHeaderText: {
        font: 'normal normal normal 20px/21px Roboto',
        letterSpacing: '1px',
    },
    alternativeNamesHeader: {
        font: 'normal normal normal 16px/21px Roboto',
        letterSpacing: '1px',
    },
    alternativeNamesSwitch: {
        display: 'flex',
        justifyContent: ' center',
        alignItems: 'center',
        font: 'normal normal normal 12px/16px Roboto',
        letterSpacing: '1px',
    },
    togglesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginBottom: '55px',
    },
    altNamesSection: {
        display: 'flex',
        marginTop: '55px',
        gap: '80px'
    },
    selectAllAndMerge: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '32px',
    },
    selectAllCheckbox: {
        display: 'flex',
        alignItems: 'center',
    },
    mergeNamesContainer: {
        width: '50%'
    },
    dataContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    singleName: {
        display: 'flex',
        alignItems: 'center',
        '& .Mui-disabled': {
            color: 'grey'
        }
    },
    footerButtonsContainer: {
        display: 'flex',
        justifyContent: 'end',
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(3),
        '& .MuiButton-outlinedPrimary:hover': {
            border: `1px solid ${theme.palette.primary.contrastText}`
        }
    },
    verifiedIconApproved: {
        color: theme.palette.primary.blue,
        marginLeft: '-16px',
        marginRight: '24px'
    },
    verifiedIconPendingApproval: {
        color: theme.palette.primary.attention,
        marginLeft: '-16px',
        marginRight: '24px'
    },
    alternativeName: {
        marginLeft: '-16px',
    },
    selecAllCheckbox: {
        '& .Mui-disabled': {
            color: 'grey'
        }
    },
    cancelAndSaveBtnContainer: {
        display: 'flex',
        gap: '20px'
    },
    mergeAndUnmergeMessage: {
        height: '20px'
    },
    infoText: {
        marginTop: '16px',
        textAlign: 'left',
        font: 'normal normal normal 14px / 21px Roboto',
        letterSpacing: '1px'
    }
}));

function selectAll(allCheckboxReference, stateOfSelectAll) {

    for (const checkbox of allCheckboxReference) {
        const checkboxEle = checkbox.getElementsByTagName('input')[0];

        if (!stateOfSelectAll) {
            if (!checkboxEle.checked) {
                checkboxEle.click();
            };
        } else {
            if (checkboxEle.checked) {
                checkboxEle.click();
            };
        };
    };
};

export const AlternativeNames = ({ onClose, alternativeNamesData, selectedSongData, selectedSongId, setAlternativeNamesData, setAltNamesButtonDisable, reportCompanyName }) => {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [csvAltNamesToggleState, setCsvAltNameToggleState] = useState(false);
    const [takeRecordAltNamesToggleState, setTakeRecordAltNameToggleState] = useState(false);
    const [disableSelect, setDisableSelect] = useState(true);
    const [disableCsvSelect, setDisableCsvSelect] = useState(false);
    const selectAllNamesCsv = useState(false);
    const selectAllNamesSystem = useState(false);
    const [selectedCsvAltNames, setSelectedCsvAltNames] = useState([]);
    const [selectedSystemAltNames, setSelectedSystemAltNames] = useState([]);
    const [csvNames, setCsvNames] = useState([]);
    const [takeRecordNames, setTakeRecordNames] = useState([...alternativeNamesData]);
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    const [finishedSavingAlert, setFinishedSaving] = useState('');
    const [clickedButton, setClickedButton] = useState('');
    const [disableMergeButton, setDisableMergeButton] = useState(true);
    const [disableUnMergeButton, setDisableUnMergeButton] = useState(true);
    const [showUnmergeModal, setShowUnmergeModal] = useState(false);
    const [showMergeModal, setShowMergeModal] = useState(false);

    const csvNewAltNamesArray = csvNames.filter(x => x.status === 'new')

    useEffect(() => {
        if (selectedCsvAltNames.length === 0) {
            setDisableMergeButton(true);
        } else {
            setDisableMergeButton(false);
        }
    }, [selectedCsvAltNames]);

    useEffect(() => {
        if (selectedSystemAltNames.length === 0) {
            setDisableUnMergeButton(true);
        } else {
            setDisableUnMergeButton(false);
        }
    }, [selectedSystemAltNames]);

    useEffect(() => {
        if (finishedSavingAlert) {
            setTimeout(() => {
                setSavedSuccessfully(false)
                setFinishedSaving('');
            }, "4000")
        }
    }, [finishedSavingAlert])

    useEffect(() => {
        const foundSongFromDbAltNames = csvNames.filter(x => x.status === 'founded').length
        if (foundSongFromDbAltNames > 0) {
            setDisableCsvSelect(true);
        } else {
            setDisableCsvSelect(false);
        }
    }, [csvNames]);

    useEffect(() => {
        const filteredNames = Object.values(state.songsMap).filter(x => x.foundSongId === selectedSongId && x.merged != true).map(x => {
            const song = { title: x.title, songId: x.foundSongId, externalId: x.externalId, artist: x.artist, status: 'new', merged: false }
            return song;
        })
        const filteredTakeRecordNames = Object.values(state.songsMap).filter(x => x.foundSongId === selectedSongId && x.merged === true).map(x => {
            const song = { title: x.title, songId: x.foundSongId, externalId: x.externalId, artist: x.artist, status: 'new', merged: true }
            return song;
        })

        let arr = JSON.parse(JSON.stringify(alternativeNamesData));

        filteredNames.forEach(x => {
            arr.forEach(z => {
                if (x.title === z.title) {
                    x.status = z.status === 'added' ? 'new' : 'founded'
                }
            })

        })

        setTakeRecordNames(prev => [...prev, ...filteredTakeRecordNames])
        setCsvNames([...csvNames, ...filteredNames]);
    }, []);

    const csvAltNameSwitchHandler = (e) => {
        setCsvAltNameToggleState(e.target.checked);
        e.target.checked ? setDisableCsvSelect(false) : setDisableCsvSelect(true);
    };

    const takeRecordAltNameSwitchHandler = (e) => {
        setTakeRecordAltNameToggleState(e.target.checked);
        e.target.checked ? setDisableSelect(false) : setDisableSelect(true);
    };

    function onClickSelectAll(checkboxClass) {

        const allCheckboxes = document.getElementsByClassName(checkboxClass);

        if (checkboxClass === 'csvAlternativeNamesCheckbox') {

            selectAll(allCheckboxes, selectAllNamesCsv[0]);

            let allSelectedSongs = [];

            if (selectAllNamesCsv[0]) {
                setSelectedCsvAltNames([]);
            } else {
                csvNames.forEach(x => {
                    const altnamesTemp = x;

                    if (altnamesTemp.status === 'new') {
                        allSelectedSongs.push(altnamesTemp)
                    }
                    setSelectedCsvAltNames(allSelectedSongs);
                })
            }

        } else {
            selectAll(allCheckboxes, selectAllNamesSystem[0]);

            let allSelectedSongs = [];

            if (selectAllNamesSystem[0]) {
                setSelectedSystemAltNames([]);
            } else {
                takeRecordNames.filter(x => x.status === 'new').forEach(x => {
                    const altnamesTemp = x;
                    allSelectedSongs.push(altnamesTemp);
                    setSelectedSystemAltNames(allSelectedSongs);
                })
            }
        };

    };


    const csvSongCheckboxHandler = (e, songData) => {

        if (e.target.checked) {
            if (!selectedCsvAltNames.find(x => x.songId === songData.foundSongId)) {
                setSelectedCsvAltNames([...selectedCsvAltNames, songData]);
            }
        } else {
            setSelectedCsvAltNames(prev => prev.filter(x => x.songId != songData.foundSongId));
        }
    }

    const systemSongCheckboxHandler = (e, songData) => {
        if (e.target.checked) {
            if (!selectedSystemAltNames.find(x => x.title === songData.title)) {
                setSelectedSystemAltNames([...selectedSystemAltNames, songData]);
            }
        } else {
            setSelectedSystemAltNames(prev => prev.filter(x => x.title != songData.title));
        }
    }

    const mergeSongsHandler = () => {
        const checkboxesRef = document.getElementsByClassName('csvAlternativeNamesCheckbox');
        setTakeRecordNames([...takeRecordNames, ...selectedCsvAltNames]);

        selectedCsvAltNames.forEach(x => {
            const index = csvNames.findIndex(song => song.title === x.title);
            csvNames.splice(index, 1);
        })

        setCsvNames(csvNames);
        setSelectedCsvAltNames([]);
        selectAllNamesCsv[1](false);
        selectAll(checkboxesRef, !selectAllNamesCsv[0]);

        setSavedSuccessfully(true)

        if (selectedCsvAltNames.length > 0) {
            setFinishedSaving('Merged successfully');
            setClickedButton('merge');
        }

    }

    const unMergeSongsHandler = () => {
        const checkboxesRef = document.getElementsByClassName('systemAlternativeNamesCheckbox');
        setCsvNames([...csvNames, ...selectedSystemAltNames]);

        selectedSystemAltNames.forEach(x => {
            const index = takeRecordNames.findIndex(song => x.title === song.title && song.status === 'new');
            takeRecordNames.splice(index, 1);
        })

        setTakeRecordNames(takeRecordNames);
        setSelectedSystemAltNames([]);
        selectAllNamesSystem[1](false);
        selectAll(checkboxesRef, !selectAllNamesSystem[0]);

        setSavedSuccessfully(true);

        if (selectedSystemAltNames.length > 0) {
            setFinishedSaving('Un-Merged successfully');
            setClickedButton('unmerge');
        }

    }
    const saveHandler = () => {

        const songs = takeRecordNames.filter(x => x.status === 'new').map(n => {
            return {
                songId: n.songId,
                title: n.title,
                artist: n.artist,
                externalId: n.externalId
            }
        });
        const alternativeNamesToUnMerge = csvNames.filter(x => x.merged === true).map(n => {
            return {
                songId: n.songId,
                title: n.title,
                artist: n.artist,
                externalId: n.externalId
            }
        });

        if (songs.length > 0) {
            postData(process.env.REACT_APP_SERVER_HOST + `/api/song/merge-alternative-names`, {
                songId: selectedSongId,
                alternativeNames: songs
            })
                .then(res => {
                    let songsMap = state.songsMap;

                    songs.forEach(s => {
                        songsMap[`${s.title}-${s.artist}`].merged = true;
                    });
                    setState(state => (state.songsMap = songsMap, { ...state }));

                    getData(process.env.REACT_APP_SERVER_HOST + `/api/song/get-alternative-names?songId=${selectedSongId}`)
                        .then(data => {
                            const filtered = data.filter(x => x.source === reportCompanyName)
                            setAlternativeNamesData(filtered);

                            setAltNamesButtonDisable(false);
                        });
                })
                .catch(err => console.log(err));
        }

        if (alternativeNamesToUnMerge.length > 0) {
            postData(process.env.REACT_APP_SERVER_HOST + `/api/song/unmerge-alternative-names`, {
                songId: selectedSongId,
                alternativeNames: alternativeNamesToUnMerge
            })
                .then(res => {
                    let songsMap = state.songsMap;

                    alternativeNamesToUnMerge.forEach(s => {

                        songsMap[`${s.title}-${s.artist}`].merged = false;
                    });
                    setState(state => (state.songsMap = songsMap, { ...state }));

                    getData(process.env.REACT_APP_SERVER_HOST + `/api/song/get-alternative-names?songId=${selectedSongId}`)
                        .then(data => {
                            const filtered = data.filter(x => x.source === reportCompanyName);
                            setAlternativeNamesData(filtered);

                            setAltNamesButtonDisable(false);
                        });
                })
                .catch(err => console.log(err));
        }


        onClose();
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.headerSection}>
                    <div className={styles.mainHeader}>Manage Alternative Names</div>
                    <div className={styles.infoText}>If the CSV and Record display name match the selected alternative names will be linked to the matching Record name in the system. If the CSV name does not have a Record match, it will be added as a new song in the system.</div>
                </div>
                <div className={styles.altNamesSection}>
                    <div className={styles.mergeNamesContainer}>
                        <div className={styles.togglesContainer}>
                            <div className={styles.artistAndSongNames}>
                                <span className={styles.songHeaderText}>CSV name</span>
                                <span>  - {selectedSongData.artist} - {selectedSongData.title}</span>
                            </div>
                            <div className={styles.alternativeNamesHeader}>Alternative names found in CSV</div>
                            <div className={styles.alternativeNamesSwitch}>
                                <Switch
                                    checked={csvAltNamesToggleState}
                                    onChange={csvAltNameSwitchHandler}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <div>Show New Only</div>
                            </div>
                        </div>
                        <div className={styles.mergeAndUnmergeMessage} >
                            {
                                finishedSavingAlert ?
                                    clickedButton === 'merge' ?
                                        <BrandAlert success={savedSuccessfully}>{finishedSavingAlert}</BrandAlert>
                                        :
                                        null
                                    :
                                    null
                            }
                        </div>
                        <div className={styles.selectAllAndMerge}>
                            <div className={styles.selectAllCheckbox}>
                                <BrandCheckbox
                                    onClick={() => disableCsvSelect ? '' : onClickSelectAll('csvAlternativeNamesCheckbox')}
                                    $value={selectAllNamesCsv}
                                    disabled={disableCsvSelect}
                                    className={styles.selecAllCheckbox}
                                />
                                <Typography
                                    style={{ color: disableCsvSelect ? 'grey' : 'inherit' }}
                                    variant='subtitle1'
                                    component='div'>
                                    Select All
                                </Typography>
                            </div>
                            <TempBrandButton
                                disabled={disableMergeButton}
                                onClick={() => setShowMergeModal(true)}
                            >
                                Merge Names
                            </TempBrandButton>

                            <ConfirmationDialog
                                type={AlternativeNamesActions.MergeAlternativeName}
                                onClose={() => setShowMergeModal(false)}
                                onAction={mergeSongsHandler}
                                showState={showMergeModal}
                                setShowState={setShowMergeModal}
                            />

                        </div>
                        <div className={styles.dataContainer}>
                            {
                                csvAltNamesToggleState ?
                                    csvNewAltNamesArray.map(x => {
                                        return (
                                            <div className={styles.singleName}>

                                                <BrandCheckbox
                                                    className='csvAlternativeNamesCheckbox'
                                                    onClick={(e) => csvSongCheckboxHandler(e, x)}
                                                />
                                                <div className={styles.alternativeName}>{x.title}</div>
                                            </div>
                                        )
                                    })
                                    :
                                    csvNames.map(x => {
                                        return (
                                            <div className={styles.singleName}>
                                                {
                                                    x.status === 'founded' ?
                                                        <>
                                                            <BrandCheckbox
                                                                disabled={true}
                                                                className='csvAlternativeNamesCheckbox'
                                                                onClick={(e) => csvSongCheckboxHandler(e, x)}
                                                            />
                                                            <Tooltip title='Approved' placement="bottom">
                                                                <VerifiedUserIcon
                                                                    className={styles.verifiedIconApproved}
                                                                    fontSize='small'
                                                                />
                                                            </Tooltip>
                                                            <div className={styles.alternativeName}>{x.title}</div>
                                                        </>
                                                        :
                                                        <>
                                                            <BrandCheckbox
                                                                className='csvAlternativeNamesCheckbox'
                                                                onClick={(e) => csvSongCheckboxHandler(e, x)}
                                                            />
                                                            <div className={styles.alternativeName}>{x.title}</div>
                                                        </>
                                                }
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                    <div className={styles.mergeNamesContainer}>
                        <div className={styles.togglesContainer}>
                            <div className={styles.artistAndSongNames}>
                                <Tooltip title={`Spotify name - ${selectedSongData.artist} - ${selectedSongData.title}`} placement="bottom">
                                    <InfoOutlinedIcon />
                                </Tooltip>
                                <div>
                                    Record Display name - {selectedSongData.artist} - {alternativeNamesData[0].displayName}
                                </div>
                            </div>
                            <div className={styles.alternativeNamesHeader}>Alternative names found in Record</div>
                            <div className={styles.alternativeNamesSwitch}>
                                <Switch
                                    checked={takeRecordAltNamesToggleState}
                                    onChange={takeRecordAltNameSwitchHandler}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <div>Show Pending Approval Only</div>
                            </div>
                        </div>
                        <div className={styles.mergeAndUnmergeMessage} >
                            {
                                finishedSavingAlert ?
                                    clickedButton === 'unmerge' ?
                                        <BrandAlert warning={savedSuccessfully}>{finishedSavingAlert}</BrandAlert>
                                        :
                                        null
                                    :
                                    null
                            }
                        </div>
                        <div className={styles.selectAllAndMerge}>
                            <div className={styles.selectAllCheckbox}>
                                <BrandCheckbox
                                    disabled={disableSelect}
                                    onClick={() => disableSelect ? '' : onClickSelectAll('systemAlternativeNamesCheckbox')}
                                    $value={selectAllNamesSystem}
                                    className={styles.selecAllCheckbox}
                                />
                                {
                                    disableSelect ?
                                        <Tooltip title='Select all is available only for pending approval names.' placement="bottom">
                                            <Typography
                                                style={{ color: disableSelect ? 'grey' : 'inherit' }}
                                                variant='subtitle1'
                                                component='div'
                                            >
                                                Select All
                                            </Typography>
                                        </Tooltip>
                                        :
                                        <Typography
                                            style={{ color: disableSelect ? 'grey' : 'inherit' }}
                                            variant='subtitle1'
                                            component='div'
                                        >
                                            Select All
                                        </Typography>
                                }
                            </div>
                            <Tooltip title='Cannot un-merge names previously approved in the system.' placement="bottom">
                                <div>
                                    <TempBrandDeleteButton
                                        disabled={disableUnMergeButton}
                                        onClick={() => setShowUnmergeModal(true)}
                                    >
                                        Un-merge Names
                                    </TempBrandDeleteButton>
                                </div>
                            </Tooltip>
                            <ConfirmationDialog
                                type={AlternativeNamesActions.UnmergeAlternativeName}
                                onClose={() => setShowUnmergeModal(false)}
                                onAction={unMergeSongsHandler}
                                showState={showUnmergeModal}
                                setShowState={setShowUnmergeModal}
                            />
                        </div>
                        <div className={styles.dataContainer}>
                            {
                                alternativeNamesData.length ?
                                    takeRecordAltNamesToggleState ?
                                        takeRecordNames.filter(x => x.status === 'new').map(x => {
                                            return (
                                                <div className={styles.singleName}>
                                                    <BrandCheckbox
                                                        className='systemAlternativeNamesCheckbox'
                                                        onClick={(e) => systemSongCheckboxHandler(e, x)}
                                                    />
                                                    <Tooltip title='Pending Approval' placement="bottom">
                                                        <VerifiedUserIcon
                                                            className={styles.verifiedIconPendingApproval}
                                                            fontSize='small'
                                                        />
                                                    </Tooltip>
                                                    <div className={styles.alternativeName}>{x.title}</div>
                                                </div>
                                            )
                                        })
                                        :
                                        takeRecordNames.map(x => {
                                            return (
                                                <div className={styles.singleName}>
                                                    {
                                                        x.status === 'new' ?
                                                            <>
                                                                <BrandCheckbox
                                                                    className='systemAlternativeNamesCheckbox'
                                                                    onClick={(e) => systemSongCheckboxHandler(e, x)}
                                                                />
                                                                <Tooltip title='Pending Approval' placement="bottom">
                                                                    <VerifiedUserIcon
                                                                        className={styles.verifiedIconPendingApproval}
                                                                        fontSize='small'
                                                                    />
                                                                </Tooltip>
                                                                <div className={styles.alternativeName}>{x.title}</div>
                                                            </>
                                                            : x.status === 'merged' ?
                                                                <>
                                                                    <BrandCheckbox
                                                                        disabled={true}
                                                                        className='systemAlternativeNamesCheckbox'
                                                                        onClick={(e) => systemSongCheckboxHandler(e, x)}
                                                                    />
                                                                    <Tooltip title='Approved' placement="bottom">
                                                                        <VerifiedUserIcon
                                                                            className={styles.verifiedIconApproved}
                                                                            fontSize='small'
                                                                        />
                                                                    </Tooltip>
                                                                    <div className={styles.alternativeName}>{x.title}</div>
                                                                </>
                                                                :
                                                                <>
                                                                    <BrandCheckbox
                                                                        disabled={true}
                                                                        className='systemAlternativeNamesCheckbox'
                                                                        onClick={(e) => systemSongCheckboxHandler(e, x)}
                                                                    />
                                                                    {/* <VerifiedUserIcon
                                                                className={styles.verifiedIconApproved}
                                                                fontSize='small'
                                                            /> */}
                                                                    <div className={styles.alternativeName}>{x.title}</div>
                                                                </>
                                                    }
                                                </div>
                                            )
                                        })
                                    :
                                    <div>
                                        Тhere are currently no such records
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footerButtonsContainer}>
                <div className={styles.cancelAndSaveBtnContainer}>
                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        variant='outlined'
                        onClick={onClose}
                    >
                        Cancel
                    </TempSecondaryBrandButton>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        onClick={saveHandler}
                    >
                        Save
                    </TempBrandButton>
                </div>
            </div>
        </>
    );
};
