import {  memo, useRef } from 'react';
import { useRenderPDF } from './useRenderPDF';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { BrandLoader } from '../../../CoreComponents/BrandLoader';

export const RenderedPDFTopOwners = memo(({styles, showToolbar = true,collectionName,topOwners,saveFilePDF }) => {
    const paramsRef = useRef({ collectionName, styles });
    const countOfDownload = useRef(0);
    
    if (paramsRef.current.collectionName !== collectionName || paramsRef.current.styles !== styles || paramsRef.current.topOwners !== topOwners) {
        paramsRef.current = { collectionName, styles,topOwners };
    }

    const { url, loading, error } = useRenderPDF(paramsRef.current);

  const src = url ? `${url}#toolbar=${showToolbar ? 1 : 0}` : null;
  if (loading)
    return (      
      <BrandLoader color='white' width={18} height={18} style={{display: 'flex', alignItems: 'center',marginLeft:'3px'}} /> 
    );

  if (error) {
    console.log({ error });
    return <ErrorOutlineIcon style={{color:'red'}} />
  }
  if(src && countOfDownload.current === 0){
    countOfDownload.current = countOfDownload.current + 1;
    saveFilePDF(src);
  }

  return (
    <></>
  );
});
