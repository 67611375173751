import { Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AlbumIcon from '@material-ui/icons/Album';
import { useEffect, useState } from 'react';


const useNftStyles = makeStyles((theme) => ({
    nftDataContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: theme.spacing(1.25)
    },
    verifyAndCoverArt: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    img: {
        width: theme.spacing(30),
        height: theme.spacing(30),
        borderRadius: theme.spacing(1)

    },
    dataCols: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '25px'
    },
    dataCol: {
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(38),
        justifyContent: 'space-between',
    },
    dataRow: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },
    dataRowData: {
        marginBottom: theme.spacing(1),
        font: 'normal normal bold 16px/20px Roboto',
        letterSpacing: '1.6px',
    },
    dataRowLabel: {
        color: '#A5A5A5',
        font: 'normal normal normal 12px/15px Roboto',
        letterSpacing: '1.2px',
    },
   
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

function Overview({ details }) {
    const styles = useNftStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [parsedAccountHolders, setParsedAccountHolders] = useState([]);

    useEffect(() => {
        setParsedAccountHolders(JSON.parse(details.accountHolders))
    }, [])

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <div className={styles.nftDataContainer}>
            <div className={styles.verifyAndCoverArt}>
                {details.coverArt ?
                    <img src={details.coverArt} alt={''} className={styles.img} />
                    :
                    <AlbumIcon className={styles.img} />
                }
            </div>
            <div className={styles.dataCols}>
                <div className={styles.dataCol}>
                    <div className={styles.dataRow}>
                        <div className={styles.dataRowData}>{details.title}</div>
                        <div className={styles.dataRowLabel}>Title</div>
                    </div>
                    <div className={styles.dataRow}>
                        <div className={styles.dataRowData}>{details.collection}</div>
                        <div className={styles.dataRowLabel}>Collection</div>
                    </div>
                    <div className={styles.dataRow}>
                        <div className={styles.dataRowData}>{details.creator}</div>
                        <div className={styles.dataRowLabel}>Creator</div>
                    </div>
                    <div className={styles.dataRow}>
                        <div className={styles.dataRowData}>
                            <div>
                                <div
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    {!parsedAccountHolders || parsedAccountHolders.length === 0 ?
                                        "N/A"
                                        :
                                        parsedAccountHolders.length === 1 ?
                                            parsedAccountHolders[0].name
                                            :
                                            `${parsedAccountHolders[0].name}; +${parsedAccountHolders.length - 1}`
                                    }
                                </div>
                                <Popover
                                    id="mouse-over-popover"
                                    className={styles.popover}
                                    classes={{
                                        paper: styles.paper,
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    {
                                        parsedAccountHolders.map(x => {
                                            return (
                                                <Typography key={x.id} color='primary'>{x.name}</Typography>
                                            )
                                        })
                                    }
                                </Popover>
                            </div>
                        </div>
                        <div className={styles.dataRowLabel}>Account Holder(s)</div>
                    </div>
                </div>
                <div className={styles.dataCol}>
                    <div className={styles.dataRow}>
                        <div className={styles.dataRowData}>{details.releaseDate ? details.releaseDate.split("T")[0] : 'N/A'}</div>
                        <div className={styles.dataRowLabel}>Released</div>
                    </div>
                    <div className={styles.dataRow}>
                        <div className={styles.dataRowData}>{details.tokenId.length > 27 ? details.tokenId.slice(0, 27) + '...' : details.tokenId}</div>
                        <div className={styles.dataRowLabel}>Token ID</div>
                    </div>
                    <div className={styles.dataRow}>
                        <div className={styles.dataRowData}>{details.contractAddress.slice(0, 11) + '...' + details.contractAddress.slice(-16)}</div>
                        <div className={styles.dataRowLabel}>Contract Address</div>
                    </div>
                    <div className={styles.dataRow}>
                        <div className={styles.dataRowData}>{details.blockchain}</div>
                        <div className={styles.dataRowLabel}>Blockchain</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Overview;