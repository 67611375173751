import React, { useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, ClickAwayListener, Grow, List, ListItem, ListItemText, Paper, Popper, TablePagination, Tooltip, makeStyles } from '@material-ui/core';
import useAbortableFetch from '../../../hooks/useAbortableFetch';
import BrandTable from '../../CoreComponents/BrandTable';
import TablePaginationActions from '../../CoreComponents/TablePaginationActions';
import { SONG_PORTFOLIO_DEFAULT_ROWS_PER_PAGE, SONG_PORTFOLIO_ROWS_PER_PAGE_OPTIONS, handleRowsPerPageChange } from '../../utils/PaginationUtils';
import { formatNumber } from '../../utils/NumberUtils';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { transformPeriodToQuarterYear } from '../../utils/DateUtils';
import { reportTypeIds } from '../../../constants/reportConstants';
import { toNumber } from 'lodash';
import { deleteData, download, putData } from '../../utils/FetchUtils';
import { useStoreContext } from '../../../store/Store';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { userAccessByType } from '../../utils/AccessUtils';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { QuartersListModal } from '../../CoreComponents/QuartersListModal';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteReportModal from './DeleteReportModal';

const sharedTableRowStyles = (theme) => ({
    backgroundColor: theme.palette.background.darkNuanceVersion4,
    border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
    height: '55px',
    paddingTop: '16px',
    paddingLeft: '16px',
});

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiTableCell-root': {
            padding: '0px'
        },
        '& .MuiTableCell-head': {
            padding: '16px'
        },
        '& .MuiTableRow-root': {
            font: 'normal normal medium 14px/17px Roboto',
        },
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: 'white'
                }
            }
        },
    },
    rowItemFirst: {
        borderBottomLeftRadius: '6px',
        borderTopLeftRadius: '6px',
        borderRight: 'none',
        ...sharedTableRowStyles(theme),
    },
    rowItemMiddle: {
        borderRight: 'none',
        borderLeft: 'none',
        ...sharedTableRowStyles(theme),
    },
    rowItemLast: {
        borderBottomRightRadius: '6px',
        borderTopRightRadius: '6px',
        borderLeft: 'none',
        ...sharedTableRowStyles(theme),
    },
    status: {
        font: 'normal normal medium 17px/28px Roboto',
        letterSpacing: '0px',
        color: theme.palette.text.greenNuanceVersion3,
    },
    reportingPeriod: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: '18px',
        marginLeft: '10px',
    },
    reportingPopper: {
        zIndex: 1,
        border: `1px solid ${theme.palette.primary.border}`,
        borderRadius: '10px',
        marginTop: '10px',
    },
    menuItem: {
        padding: '8px',
        font: 'normal normal medium 14px/21px Roboto',
        letterSpacing: '0px',
        textAlign: 'left',
        width: '150px',
        '& > :first-child': {
            marginRight: theme.spacing(1),
        },
    },
    deleteText: {
        color: theme.palette.primary.delete,
    },
    redIcon: {
        color: theme.palette.primary.delete,
    }
}));

const CsvDataTable = (
    {
        accountHolder,
        startQuarter,
        endQuarter,
        selectedFilter,
        reportNameSearch,
        selectedReportingCompanies
    }
) => {
    const abortableFetch = useAbortableFetch();
    const [state] = useStoreContext();
    const styles = useStyles();

    const [items, setItems] = useState([]);
    const [itemsCount, setItemsCount] = useState(0);

    const [isDownloading, setIsDownloading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [reportData, setReportData] = useState({
        id: null,
        reportName: null,
    })

    const handleDownload = async (reportFileId) => {
        if (isDownloading)
            return;
        setIsDownloading(true);
        try {
            await download(process.env.REACT_APP_SERVER_HOST + '/api/report/report-files-stream', { reportIds: [reportFileId] }, 'zip');
        }
        catch (error) {
            console.error(error);
        }
        setIsDownloading(false);
    }

    const handleDelete = async () => {
        if (!reportData.id) 
            throw new Error("Invalid report ID.");
        
        await deleteData(process.env.REACT_APP_SERVER_HOST + '/api/report/',
            {
                id: reportData.id,
            }
        );
        setItems(prevItems => prevItems.filter(item => item.reportFileId !== reportData.id));
    }

    const isAdmin = state.user && state.user.userType === userAccessByType.USER_TYPE_ADMIN_ACCESS;
    const adminOnlyRows = ['actions', 'reportingCompany', 'status'];

    const rowPaddingTop = '16px';
    const rowPaddingBottom = '12px';

    const [headCells] = useState([
        {
            id: 'accountHolderName',
            label: 'Account Holder',
            width: '13%',
            paddingTop: rowPaddingTop,
            paddingBottom: rowPaddingBottom,
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemFirst}>
                        {rowData.accountHolderName}
                    </div>
                )
            }
        },
        {
            id: 'reportName',
            label: 'Report Name',
            minWidth: '160px',
            width: '18%',
            paddingTop: rowPaddingTop,
            paddingBottom: rowPaddingBottom,
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemMiddle}>
                        {rowData.fileName}
                    </div>
                );
            }
        },
        {
            id: 'reportType',
            label: 'Report Type',
            minWidth: '120px',
            width: '12%',
            paddingTop: rowPaddingTop,
            paddingBottom: rowPaddingBottom,
            CellRender: ({ rowData }) => {
                const getReportingType = (value) => {
                    const type = toNumber(value);
                    switch (type) {
                        case reportTypeIds.PRO_REVENUE: return "Pro Revenue";
                        case reportTypeIds.MASTER_PERFORMANCE_REVENUE: return "Master Performance Revenue";
                        case reportTypeIds.PUBLISHER_ADMIN_REVENUE: return "Publisher Admin Revenue";
                        case reportTypeIds.LABEL_DISTRIBUTOR_REVENUE: return "Label Distributor Revenue";
                    }
                }

                return (
                    <div className={styles.rowItemMiddle}>
                        {getReportingType(rowData.reportType)}
                    </div>
                );
            },
        },
        {
            id: 'reportingCompany',
            label: 'Reporting Company',
            minWidth: '120px',
            width: '12%',
            paddingTop: rowPaddingTop,
            paddingBottom: rowPaddingBottom,
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemMiddle}>
                        {rowData.reportingCompanyName}
                    </div>
                );
            },
        },
        {
            id: 'reportingPeriod',
            label: 'Reporting Period',
            minWidth: '80px',
            width: '10%',
            paddingTop: rowPaddingTop,
            paddingBottom: rowPaddingBottom,
            CellRender: ({ rowData }) => {
                const [openEditModal, setOpenEditModal] = useState(false);
                const [anchorEl, setAnchorEl] = useState(null);
                const [selectedQuarter, setSelectedQuarter] = useState(rowData.reportingPeriods);

                const handlePopoverOpen = (event) => {
                    setOpenEditModal(true);
                    setAnchorEl(event.currentTarget);
                };

                const handlePopoverClose = () => {
                    setOpenEditModal(false);
                    setAnchorEl(null);
                };

                useEffect(() => {
                    const handleEditReportingPeriod = async () => {
                        try {
                            await putData(process.env.REACT_APP_SERVER_HOST + '/api/report/',
                                {
                                    id: rowData.reportFileId,
                                    newQ: selectedQuarter,
                                    accountHolderId: accountHolder,
                                    reportingCompanyId: rowData.reportingCompanyId,
                                }
                            );
                        }
                        catch (err) {
                            alert(err);
                            setSelectedQuarter(rowData.reportingPeriods);
                        }
                    }

                    if (openEditModal) {
                        handlePopoverClose();
                        handleEditReportingPeriod();
                    }
                }, [selectedQuarter])

                return (
                    <div className={styles.rowItemMiddle}>
                        <div className={styles.reportingPeriod}>
                            {transformPeriodToQuarterYear(selectedQuarter)}
                            {
                                isAdmin &&
                                <>
                                    <Tooltip title="Edit" placement="bottom">
                                        <EditOutlinedIcon
                                            className={styles.icon}
                                            onClick={handlePopoverOpen}
                                        />
                                    </Tooltip>
                                    <QuartersListModal
                                        openEdit={openEditModal}
                                        value={selectedQuarter}
                                        onChange={setSelectedQuarter}
                                        onClose={handlePopoverClose}
                                        anchorEl={anchorEl}
                                    />
                                </>
                            }

                        </div>
                    </div>
                );
            },
        },
        {
            id: 'totalRevenue',
            label: 'Total Revenue',
            minWidth: '80px',
            paddingTop: rowPaddingTop,
            paddingBottom: rowPaddingBottom,
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItemMiddle}>
                        {rowData === null ?
                            <FiberManualRecordIcon style={{ fontSize: 10 }} />
                            :
                            `$${formatNumber(rowData.value)}`
                        }
                    </div>

                );
            }
        },
        {
            id: 'actions',
            label: '',
            notSortable: true,
            paddingTop: rowPaddingTop,
            paddingBottom: rowPaddingBottom,
            CellRender: ({ rowData }) => {
                const [open, setOpen] = useState(false);
                const anchorRef = useRef(null);

                const handleMenuToggle = () => {
                    setOpen((prevOpen) => !prevOpen);
                };

                const handleMenuClose = (event) => {
                    if (anchorRef.current && anchorRef.current.contains(event.target))
                        return;
                    setOpen(false);
                };

                const handleListKeyDown = (event) => {
                    if (event.key === 'Tab') {
                        event.preventDefault();
                        setOpen(false);
                    } else if (event.key === 'Escape') {
                        setOpen(false);
                    }
                }

                return (
                    <div className={styles.rowItemLast}>

                        <PendingOutlinedIcon
                            onClick={handleMenuToggle}
                            ref={anchorRef}
                        />
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            className={styles.reportingPopper}
                            placement="bottom-start"
                            transition
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleMenuClose}>
                                            <List
                                                autoFocusItem={open}
                                                id="composition-menu"
                                                onKeyDown={handleListKeyDown}>
                                                <ListItem
                                                    onClick={(event) => { handleDownload(rowData.reportFileId); handleMenuClose(event); }}
                                                    className={styles.menuItem}>
                                                    <FileDownloadIcon />
                                                    <ListItemText>Download</ListItemText>
                                                </ListItem>
                                                <ListItem
                                                    onClick={(event) => {
                                                        setReportData({id: rowData.reportFileId, reportName: rowData.fileName})
                                                        setShowDeleteModal(true);
                                                        handleMenuClose(event);
                                                    }}
                                                    className={styles.menuItem}>
                                                    <DeleteIcon className={styles.redIcon}/>
                                                    <ListItemText className={styles.deleteText}>Delete</ListItemText>
                                                </ListItem>
                                            </List>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                );
            }
        }
    ].filter((headCell) => isAdmin || adminOnlyRows.indexOf(headCell.id) === -1));

    const [rowsPerPage, setRowsPerPage] = useState(SONG_PORTFOLIO_DEFAULT_ROWS_PER_PAGE); // SONG_PORTFOLIO_DEFAULT_ROWS_PER_PAGE
    const [rowsPerPageOptions] = useState(SONG_PORTFOLIO_ROWS_PER_PAGE_OPTIONS); // SONG_PORTFOLIO_ROWS_PER_PAGE_OPTIONS
    const [sortBy, setSortBy] = useState('id');
    const [sortType, setSortType] = useState('ASC');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const body = {
                filter: reportNameSearch,
                startDate: startQuarter,
                endDate: endQuarter,
                sort: sortBy,
                type: sortType,
                reportTypeFilter: selectedFilter,
                accountHolder: accountHolder,
                selectedReportingCompanies,
            };
            try {
                const fetchedData = await abortableFetch('POST',
                    '/api/elastic/csv-statements', {
                    body,
                    query: {
                        offset,
                        limit: rowsPerPage
                    }
                });

                setItems(fetchedData ? fetchedData.statementsData : []);
                setItemsCount(fetchedData ? fetchedData.statementsDataEntries : 0);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [startQuarter, endQuarter, offset, rowsPerPage, sortType, sortBy, selectedFilter, accountHolder, reportNameSearch, selectedReportingCompanies])

    useEffect(() => {
        setPage(0);
    }, [startQuarter, endQuarter, accountHolder, reportNameSearch])

    useEffect(() => {
        setOffset(page * rowsPerPage);
    }, [page])

    return (
        <div className={styles.container}>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <DeleteReportModal 
                        open={showDeleteModal} 
                        onClose={() => {setShowDeleteModal(false)}} 
                        onDelete={handleDelete} 
                        reportData={reportData}
                    />
                    <BrandTable
                        rows={items}
                        headCells={headCells}
                        checkboxless={true}
                        page={page}
                        hidePagination={true}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                        rowIsClickable={false}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={itemsCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event, offset, setRowsPerPage, setPage, setOffset)}
                        ActionsComponent={TablePaginationActions}
                    />
                </>
            )}
        </div>
    )
}

export default CsvDataTable