import { Box, Popover, Typography, makeStyles } from "@material-ui/core";
import { useState } from "react";
import ExpandMoreButton from "./ExpandMoreButton";
import { TempBrandButton, TempSecondaryBrandButton } from "../CoreComponents/BrandButton";
import { ButtonSize } from "../../constants/buttonConstants";

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '10px'
    },
    popoverPaper: {
        borderRadius: '0px',
        marginTop: '10px',
        backgroundColor: theme.palette.background.default,
        width: '290px',
        height: '320px'
    },
    popoverContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px'
    },
    optionsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px 0px 5px 20px',
        rowGap: '5px'
    },
    actionsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '10px',
        justifyContent: 'center',
        marginBottom: '10px'
    },
    option: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    selectedOption: {
        color: theme.palette.primary.main,
    }
}));

const SortOptions = ({ 
    options,
    selectedSortOption,
    setSelectedSortOption,
    fetchRevenueSplits
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const styles = useStyles();

    const handleShowPopover = (e) => setAnchorEl(e.currentTarget);
    const handleHidePopover = () => setAnchorEl(null);

    const handleResetSelectedSortOption = () => setSelectedSortOption(null);
    const handleSelectSortOption = (option) => setSelectedSortOption(option);

    const handleApplySort = () => fetchRevenueSplits?.();

    return (
        <Box className={styles.contentWrapper}>
            <Typography>Sort by</Typography>
            <ExpandMoreButton 
                textContent={selectedSortOption ? selectedSortOption.label : 'Select'}
                onClick={handleShowPopover}
            />
            <Popover 
                open={!!anchorEl} 
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                onClose={handleHidePopover}
                classes={{ paper: styles.popoverPaper }}
            >
                <Box className={styles.popoverContentWrapper}>
                    <Box className={styles.optionsWrapper}>
                        { options.map((option) => (
                            <Typography
                                key={option.label}
                                onClick={() => handleSelectSortOption(option)}
                                className={`${styles.option} ${
                                    selectedSortOption && selectedSortOption.label === option.label 
                                        ? styles.selectedOption 
                                        : ''
                                    }`
                                }
                            >
                                { option.label }
                            </Typography>
                        )) }
                    </Box>
                    <Box className={styles.actionsWrapper}>
                        <TempSecondaryBrandButton
                            disabled={!selectedSortOption}
                            onClick={handleResetSelectedSortOption}
                            size={ButtonSize.SMALL}
                        >
                            Reset
                        </TempSecondaryBrandButton>
                        <TempBrandButton 
                            onClick={handleApplySort}
                            size={ButtonSize.SMALL}
                        >
                            Apply
                        </TempBrandButton>
                    </Box>
                </Box>
            </Popover>
        </Box>
    )
}

export default SortOptions;