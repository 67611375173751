import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { BrandAlert } from "../CoreComponents/BrandAlert";
import { BrandButton, TempBrandButton, TempSecondaryBrandButton } from "../CoreComponents/BrandButton";
import { postData } from "../utils/FetchUtils";
import { reportNames } from "../utils/ReportNamesUtils";
import { ButtonSize } from "../../constants/buttonConstants";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 600
    },
    actions: {
        display: 'flex',
        justifyContent: "flex-end",
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
        "& button": {
            width: '93px',

        },
        marginTop: '48px',
        '& .MuiButton-outlinedPrimary:hover': {
            border: '1px solid #FFFFFFDE'
        }
    },
    description: {
        marginBottom: 10
    },
    caseInfo: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: 'Roboto'
    }
}));

function CloseModal({ selectedRowData, setShowModal, rowData, setRowData }) {
    const styles = useStyles();
    const [alert, setAlert] = useState('')

    const reportName = reportNames.filter(repName => repName.id === Number(selectedRowData.reportType))[0].name;
    const songStatus = selectedRowData.missingPeriods === 'null' ? 'Not Registered' : 'Registered';

    const confirmHandler = () => {
        setShowModal(false);
        postData(process.env.REACT_APP_SERVER_HOST + '/api/missing-data/close-issue', {
            missingDataCaseId: selectedRowData.id,
        })
            .then(res => {
                const filteredData = rowData.filter(row => row.id !== selectedRowData.id);
                setRowData(filteredData);
            })
            .catch(err => setAlert(err.message))
    };

    return (
        <div className={styles.container}>
            <h3>Close Case</h3>
            <div className={styles.description}>
                You are about to close the missing data case - <span className={styles.caseInfo}>{selectedRowData.songTitle} - {reportName} - {songStatus}</span>
            </div>
            <div>Are you sure you want to continue?</div>
            <BrandAlert>{alert}</BrandAlert>
            <div className={styles.actions}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    variant='outlined'
                    onClick={() => setShowModal(false)}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton
                    size={ButtonSize.SMALL}
                    onClick={confirmHandler}
                >
                    Confirm
                </TempBrandButton>
            </div>
        </div>
    );
}

export default CloseModal;