import { Fragment, useRef, useState } from "react";
import SearchButton from "../SearchButton";
import { Box, Popover, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
        color: theme.palette.primary.main,
        pointerEvents: 'auto',
    }
}))

const PopoverAutocomplete = ({
    selectedOptions,
    setSelectedOptions,
    searchQuery,
    setSearchQuery,
    options,
    setOptions,
    offset,
    callback,
    textContent,
    placeholder,
    allSelectedText,
    disabled,
    showSearchIcon,
    isLoading,
    handleResetAutoCompletes,
    disableAccountHolderAutoComplete
}) => {
    const styles = useStyles();
    const [openPopover, setOpenPopover] = useState(false);
    const anchorEl = useRef(null);

    const selectedOptionsToString = selectedOptions.map(x => x.name).join('; ');

    const handlePopoverClose = () => {
        setOpenPopover(false);
    };

    return (
        <Fragment>
            <div ref={anchorEl}>
                <Box aria-owns={!!anchorEl.current ? 'mouse-over-popover' : undefined} aria-haspopup='true'>
                    <SearchButton
                        showSearchIcon={showSearchIcon}
                        disabled={disabled}
                        textContent={selectedOptions.length > 0 ? selectedOptionsToString : textContent}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        options={options}
                        setOptions={setOptions}
                        offset={offset}
                        callback={callback}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        placeholder={placeholder}
                        allSelectedText={allSelectedText}
                        closeOutsidePopover={handlePopoverClose}
                        openOutsidePopover={() => setOpenPopover(true)}
                        isLoading={isLoading}
                        handleResetAutoCompletes={handleResetAutoCompletes}
                        disableAccountHolderAutoComplete={disableAccountHolderAutoComplete}
                    />
                </Box>
                <Popover
                    id="mouse-over-popover"
                    className={styles.popover}
                    classes={{
                        paper: styles.paper,
                    }}
                    open={openPopover}
                    anchorEl={anchorEl.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    {
                        selectedOptions.length > 0 ?
                            selectedOptions.map(x => {
                                return (
                                    <div key={x.id} color='primary'>{x.name}</div>
                                )
                            })
                            :
                            null
                    }
                </Popover>
            </div>
            
        </Fragment>
    )
};

export default PopoverAutocomplete;