import { 
    Box,
    Checkbox, 
    FormControl, 
    FormControlLabel, 
    Typography, 
    makeStyles 
} from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
    checkboxesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px'
    },
    formControlLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '35px'
    },
    disabled: {
        '&.Mui-disabled': {
            color: theme.palette.primary.lightGray
        }
    },
    bottomUnmergeWarningsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '15px',
        marginTop: '25px'
    },
    warningIcon: {
        color: 'yellow'
    },
    unmergeWarningMessage: {
        color: 'yellow',
        fontSize: '13px',
        marginRight: '20px'
    }
}));

const ActionChooser = ({ 
    values,
    optionChecked,
    setOptionChecked,
    warningsMessages
}) => {
    const styles = useStyles();

    return (
        <Fragment>
            <FormControl className={styles.checkboxesWrapper}>
                { values && values.map((value, index) => (
                    <FormControlLabel
                        className={styles.formControlLabel}
                        control={<Checkbox color='primary' />} 
                        label={<Typography variant='body2'>{value}</Typography>}
                        disabled={optionChecked && optionChecked !== (index + 1)}
                        onChange={() => setOptionChecked(optionChecked ? null : (index + 1))}
                        classes={{ label: styles.disabled }}
                    />
                )) }
            </FormControl>
            { optionChecked && (
                <Box className={styles.bottomUnmergeWarningsWrapper}>
                    <Warning fontSize="medium" className={styles.warningIcon} />
                    { warningsMessages && warningsMessages.map((value, index) => (
                        <Fragment>
                            { optionChecked === (index + 1) && (
                                <Typography 
                                    variant='body1' 
                                    className={styles.unmergeWarningMessage}
                                >
                                    { value }
                                </Typography>
                            ) }
                        </Fragment>
                    )) }
                </Box>
            ) }
        </Fragment>
    )
}

export default ActionChooser;