import { Box, CircularProgress, IconButton, TablePagination, makeStyles } from "@material-ui/core";
import { deleteData, getData } from "../utils/FetchUtils";
import { useEffect, useState } from "react";
import BrandTable from "../CoreComponents/BrandTable";
import TablePaginationActions from "../CoreComponents/TablePaginationActions";
import { reportNames } from "../utils/ReportNamesUtils";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { REPORTING_COMPANIES_DEFAULT_ROWS_PER_PAGE, REPORTING_COMPANIES_ROWS_PER_PAGE_OPTIONS, handleRowsPerPageChange } from "../utils/PaginationUtils";

const useStyles = makeStyles((theme) => ({
    editButton: {
        textAlign: 'right'
    },
    alert: {
        color: theme.palette.primary.alert,
        marginBottom: '10px'
    },
    successMsg: {
        color: theme.palette.primary.main,
        marginBottom: '10px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    icons: {
        color: 'white',
        fontSize: '0.875rem'
    }
}));

const ReportingCompaniesTable = ({
    setDisplayDeleteReportingCompanyModal,
    setSelectedRowData,
    setDisplayEditCompanyModal,
    isActionClicked,
    setIsActionClicked,
    filterByCompany,
    filterByType
}) => {
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState('');
    const [reportingCompanies, setReportingCompanies] = useState([]);
    const [sortBy, setSortBy] = useState('id');
    const [sortType, setSortType] = useState('ASC');
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState(REPORTING_COMPANIES_ROWS_PER_PAGE_OPTIONS);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [rowCounts, setRowCounts] = useState(REPORTING_COMPANIES_DEFAULT_ROWS_PER_PAGE);
    const [rowsPerPage, setRowsPerPage] = useState(6);

    const [headCells] = useState([
        {
            id: 'name',
            label: 'Company Name',
        },
        {
            id: 'reportingType',
            label: 'Report Type',
            CellRender: ({ cellData }) => {
                const reportingCompanyName = reportNames.filter(company => company.id === cellData)[0]?.name;

                return (
                    <div>
                        {reportingCompanyName}
                    </div>
                )
            }
        },
        {
            id: 'edit',
            label: '',
            hideHeadLabel: true,
            CellRender: ({ rowData }) => {
                return (
                    <div
                        className={styles.editButton}
                        onClick={() => {
                            setDisplayEditCompanyModal(true)
                            setSelectedRowData(rowData)
                        }
                        }
                    >
                        <IconButton
                            className={styles.icons}
                        >
                            <EditOutlinedIcon fontSize='small' />Edit
                        </IconButton>
                    </div>
                )
            }
        },
        {
            id: 'delete',
            label: '',
            hideHeadLabel: true,
            CellRender: ({ rowData }) => {
                return (
                    <div
                        className={styles.deleteButton}
                        onClick={() => {
                            setSelectedRowData(rowData);
                            setDisplayDeleteReportingCompanyModal(true);
                        }
                        }
                    >
                        <IconButton
                            className={styles.icons}
                        >
                            <DeleteOutlineOutlinedIcon fontSize='small' />
                            Delete
                        </IconButton>
                    </div>
                )
            }
        }
    ]);

    const getCompanies = () => {
        const filterByTypeIds = filterByType.map(company => reportNames.find(el => el.name === company).id.toString());
        const filterByCompanyNames = filterByCompany.map(company => company.id)
        setLoading(true);
        
        getData(process.env.REACT_APP_SERVER_HOST + `/api/admin/reporting-companies?offset=${offset}&limit=${rowsPerPage}&filterByType=${filterByTypeIds}&filterByCompany=${filterByCompanyNames}`)
            .then(reportingCompaniesData => {
                setReportingCompanies(reportingCompaniesData.companies);
                setRowCounts(reportingCompaniesData.totalCompanies);
                setLoading(false);
                setIsActionClicked(false);
            })
            .catch(err => {
                setLoading(false);
                setAlert(err.message);
                setIsActionClicked(false);
            })
    }

    useEffect(() => {
        getCompanies();
    }, [page, rowsPerPage, offset, isActionClicked])

    useEffect(() => {
        setOffset(page * rowsPerPage);
    }, [page, rowsPerPage])

    useEffect(() => {
        setTimeout(() => {
            setAlert('');
        }, 5000);
    }, [alert]);

    useEffect(() => {
        getCompanies();
        setPage(0);
    }, [filterByCompany, filterByType])

    return (
        loading ?
            <Box className={styles.loadingContainer} >
                <CircularProgress />
            </Box >
            :
            alert ?
                <div>{alert}</div>
                :
                <>
                    <BrandTable
                        rows={reportingCompanies}
                        headCells={headCells}
                        checkboxless={true}
                        page={page}
                        hidePagination={true}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rowCounts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event, offset, setRowsPerPage, setPage, setOffset)}
                        ActionsComponent={TablePaginationActions}
                    />
                </>
    )

}

export default ReportingCompaniesTable;