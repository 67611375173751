import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SecondaryBrandButton, BrandButton, DeleteBrandButton, BrandDeleteButton, TempSecondaryBrandButton, TempBrandDeleteButton, TempBrandButton } from './BrandButton';
import clsx from 'clsx';
import { BrandLoader, BrandLoaderDots } from './BrandLoader';
import { Button, CircularProgress } from '@material-ui/core';
import { ButtonSize } from '../../constants/buttonConstants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    hasPadding: {
        padding: '1.5rem'
    },
    hasCustomScrollbar: {
        padding: '1.5rem',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': { width: '10px', },
        '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
        '&::-webkit-scrollbar-thumb': { background: '#888' },
        '&::-webkit-scrollbar-thumb:hover': { background: '#555' }
    },
    actions: {
        display: 'flex',
        justifyContent: "flex-end",
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
        "& button": {
            width: '93px'
        },
        "& .MuiButton-containedSecondary": {
            color: theme.palette.text.primary,
            fontWeight: 'lighter'
        }
    },
    overflowXHidden: {
        overflowX: 'hidden'
    },
    messageModalButton: {
        color: theme.palette.text.dark
    },
    messageModalMessageContainer: {
        padding: '3.125rem'
    },
}));


export function BrandModal({
    open,
    onClose,
    notClosable,
    hasPadding = true,
    children,
    overflowXHidden,
}) {
    const styles = useStyles();
    return (
        <Dialog
            open={open}
            onClose={notClosable ? null : onClose}
            TransitionComponent={Transition}
            maxWidth={false}
        >
            <div className={clsx(hasPadding ? styles.hasPadding : null, overflowXHidden ? styles.overflowXHidden : null)}>
                {children}
            </div>
        </Dialog>
    );
}

export function BrandDeleteModal({
    open,
    onClose,
    hasPadding = true,
    children,
    onDelete,
    hasCustomScrollbar,
    loading,
    disabled = false,
    maxWidth = false
}) {
    const styles = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            maxWidth={maxWidth}
        >
            <div className={hasCustomScrollbar ? styles.hasCustomScrollbar : hasPadding ? styles.hasPadding : null}>
                {children}
                <div className={styles.actions}>
                    <TempSecondaryBrandButton size={ButtonSize.SMALL} variant='outlined' onClick={onClose}>Cancel</TempSecondaryBrandButton>
                    <TempBrandDeleteButton
                        size={ButtonSize.SMALL}
                        onClick={onDelete}
                        disabled={disabled}
                    >
                        {loading ? (
                            <BrandLoaderDots />
                        ) : 'Delete'}
                    </TempBrandDeleteButton>
                </div>
            </div>
        </Dialog>
    );
}

export function BrandMessageModal({
    open,
    onClose,
    children
}) {
    const styles = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            maxWidth={'sm'}
        >
            <div className={styles.hasPadding}>
                <div className={styles.messageModalMessageContainer}>
                    {children}
                </div>
                <div className={styles.actions}>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        variant='contained'
                        onClick={onClose}
                        className={styles.messageModalButton}
                    >OK</TempBrandButton>
                </div>
            </div>
        </Dialog>
    );
}