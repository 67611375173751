import React, { useState, useEffect, useMemo } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce, getData } from '../utils/FetchUtils'
import { ListItem, ListItemText } from '@material-ui/core';
import { BrandInput } from '../CoreComponents/BrandInput';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
const useStyles = makeStyles(theme => ({
    autocomplete: {
        "& .MuiAutocomplete-clearIndicatorDirty": {
            display: 'none'
        },
        "& .MuiAutocomplete-popupIndicator": {
            display: 'none'
        },
    }
}));


export const AccountHolderAutoComplete = ({ label, onSelectedAccountHolder }) => {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setOpen(inputValue.length > 0);
        (async () => {
            if (!inputValue || !isLoading) return;
            const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/?offset=0&limit=${20}&filter=${inputValue}`);
            setIsLoading(false);
            setOptions(data.items);
        })();
    }, [inputValue, isLoading]);

    const debounceMemo = useMemo(
        () => debounce(
            (event, newInputValue) => {
                setOptions([]);
            }, (event, newInputValue) => {
                setIsLoading(newInputValue.length > 0);
                setInputValue(newInputValue);
            }, 500),
        [],
    );

    return (
        <Autocomplete
            className={styles.autocomplete}
            open={open}
            onClose={() => {
                setOpen(false);
                setOptions([]);
            }}

            value={value}
            onChange={(e, newValue) => {
                setValue('');
                setInputValue('');

                if(newValue) {
                    const accountHolder = { name: newValue.name, id: newValue.id };
                    onSelectedAccountHolder(accountHolder);
                }

                setOpen(false);
                setIsLoading(false);
            }}
            onInputChange={debounceMemo}
            blurOnSelect={true}
            clearOnBlur={true}
            clearOnEscape={true}
            getOptionLabel={(option) => option?.name || ''}
            getOptionSelected={() => false}
            renderOption={(accHolder, option) => {
                return (<ListItem key={accHolder.id}>
                    <ListItemText primary={accHolder.name} />
                </ListItem>)
            }}
            options={options}
            loading={isLoading}
            renderInput={(params) => (
                <BrandInput
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}