import { Box, CircularProgress, TablePagination, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useState } from 'react';
import BrandTable from '../CoreComponents/BrandTable';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ethSvg from '../../assets/ethSvg.svg'
import { getData } from '../utils/FetchUtils';
import { useEffect } from 'react';
import { BrandModal } from '../CoreComponents/BrandModal';
import TablePaginationActions from '../CoreComponents/TablePaginationActions';
import SelectedNftData from './NftDialog/SelectedNftData';
import { useAsyncAction } from '../../hooks/useAsyncAction';

const useNftStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

export const roundToSecondDecimal = (number) => {
    return Math.round((Number(number) + Number.EPSILON) * 100) / 100
}

export const EthIcon = ({ header = false }) => {
    return (
        <img src={ethSvg} alt='' style={{
            width: header ? '10px' : '15px',
            height: '100%',
            marginBottom: header ? '2px' : '-6px',
            marginLeft: header ? '5px' : '0px'
        }} />
    );
}

const NftPortfolio = ({ accountHolder, startQuarter, endQuarter, search }) => {
    const styles = useNftStyles();
    const [headCells] = useState([
        {
            id: 'collection',
            label: 'Collection',
        },
        {
            id: 'title',
            label: 'Title',
            minWidth: '250px'
        },
        {
            id: 'accountHolders',
            label: 'Account Holder(s)',
            CellRender: ({ rowData }) => {
                const parsedAccountHolders = JSON.parse(rowData.accountHolders);

                const [anchorEl, setAnchorEl] = useState(null);

                const handlePopoverOpen = (event) => {
                    setAnchorEl(event.currentTarget);
                };

                const handlePopoverClose = () => {
                    setAnchorEl(null);
                };

                const open = Boolean(anchorEl);

                if (!parsedAccountHolders || parsedAccountHolders.length === 0) {
                    return "N/A";
                } else if (parsedAccountHolders.length === 1) {
                    return parsedAccountHolders[0].name;
                }

                return (
                    <div>
                        <div
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            {`${parsedAccountHolders[0].name}; +${parsedAccountHolders.length - 1}`}
                        </div>
                        <Popover
                            id="mouse-over-popover"
                            className={styles.popover}
                            classes={{
                                paper: styles.paper,
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            {
                                parsedAccountHolders.map(x => {
                                    return (
                                        <Typography key={x.id} color='primary'>{x.name}</Typography>
                                    )
                                })
                            }
                        </Popover>
                    </div>
                );
            }
        },
        {
            id: 'releaseDate',
            label: 'Release Date',
            CellRender: ({ rowData }) => {
                const releaseDate = new Date(rowData.releaseDate)
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {releaseDate.getTime() ?
                            `${releaseDate.getFullYear()}-${releaseDate.getMonth() < 9 ? '0' : ''}${releaseDate.getMonth() + 1}-${releaseDate.getDate() < 10 ? '0' : ''}${releaseDate.getDate()}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            },
        },
        {
            id: 'revenue',
            label: 'Revenue',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData === null ?
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                            :
                            <> {roundToSecondDecimal(cellData)} < EthIcon header={true} /> </>
                        }
                    </div >
                );
            }
        },
        {
            id: 'totalVolume',
            label: 'Total Volume',
            CellRender: ({ rowData }) => {
                const totalVolume = Number(rowData.primaryTransactions || 0) + Number(rowData.secondaryTransactions || 0);
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {isNaN(totalVolume) ?
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                            :
                            <> {totalVolume} < EthIcon header={true} /> </>
                        }
                    </div>
                );
            }
        },
        {
            id: 'lastSale',
            label: 'Last Sale',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData === null ?
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                            :
                            <> {cellData} < EthIcon header={true} /> </>
                        }
                    </div>
                );
            }
        },
        // {
        //     id: 'currentPrice',
        //     label: 'Current Price',
        //     CellRender: ({ cellData }) => {
        //         return (
        //             <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }} >
        //                 {cellData === null ?
        //                     <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
        //                     :
        //                     <> {cellData} < EthIcon header={true} /> </>
        //                 }
        //             </div>
        //         );
        //     }
        // },
    ]);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [sortBy, setSortBy] = useState('id');
    const [sortType, setSortType] = useState('ASC');

    const [rowsPerPageOptions] = useState([25, 50, 100]);
    const [rowCounts, setRowCounts] = useState(0);
    const [offset, setOffset] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [selectedNftRow, setSelectedNfRow] = useState(undefined);
    const [displayNftDataModal, setDisplayNftDataModal] = useState(false);

    useEffect(() => {
        setOffset(page * rowsPerPage)
    }, [page])

    useEffect(() => {
        setPage(0);
    }, [startQuarter, endQuarter, accountHolder[0], search, rowsPerPage])

    useEffect(() => {
        trigger();
    }, [startQuarter, endQuarter, offset, rowsPerPage, sortType, sortBy, accountHolder[0], search])

    function onRowClick(event, row) {
        setSelectedNfRow(row);
        setDisplayNftDataModal(true);
    }

    const { trigger, loading } = useAsyncAction(async () => {
        const fetchedData = await getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/portfolio?filter=${search}&startDate=${startQuarter}&endDate=${endQuarter}&offset=${offset}&limit=${rowsPerPage}&sort=${sortBy}&type=${sortType}&accountHolder=${accountHolder[0]}`)

        setRowCounts(fetchedData.total || 0)
        setRows(fetchedData.items || []);
    });

    return (
        <>
            <>
                <BrandModal
                    open={displayNftDataModal}
                    hasPadding={false}
                    onClose={() => setDisplayNftDataModal(false)}
                    overflowXHidden={true}
                >
                    <SelectedNftData
                        details={selectedNftRow}
                        setDetails={setSelectedNfRow}
                        onClose={setDisplayNftDataModal}
                        accountHolder={accountHolder[0]}
                    />
                </BrandModal>
            </>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <BrandTable
                        headCells={headCells}
                        rows={rows}
                        page={page}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                        onRowClick={onRowClick}
                        rowIsClickable={true}
                        hidePagination={true}
                        checkboxless={true}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        count={rowCounts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        component="div"
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                        ActionsComponent={TablePaginationActions}
                    />
                </>
            )}
        </>
    );
}

export default NftPortfolio;