import { TableCell, TableRow } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useState } from 'react';
import { useEffect } from 'react';
import clsx from 'clsx';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useNftStyles = makeStyles((theme) => ({
    tableRowIcon: {
        fontSize: '18px !important'
    },
    tableRow: {
        cursor: "pointer",
        display: 'flex',
        justifyContent: 'start',
        gap: 3,
    },
    tableCell: {
        paddingTop: 0,
        borderBottom: '1px solid transparent'
    },
    firstTableCell: {
        paddingLeft: theme.spacing(6),
    },
    tableCellData: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.2)
    },
    hash: {
        width: '143px'
    },
}));

const NFTActivityList = (props) => {
    const styles = useNftStyles();
    const [transfers, setTransfers] = useState([]);
    const [additionalData, setAdditionalData] = useState({});
    useEffect(() => {
        setAdditionalData(props.additionalData || {});
        setTransfers(props?.row?.transfers || []);
    }, [])

    return (
        <TableRow
            hover={true}
            role={null}
            aria-checked={null}
            tabIndex={null}
            key={'toggled-table-row'}
            selected={null}
        >
            {additionalData && additionalData.blockchain && additionalData.blockchain === 'MATIC' ?
                <>
                    <TableCell classes={{ root: styles.tableCell }} align="left">
                        <div className={clsx(styles.firstTableCell, styles.tableCellData)}>
                            <div>From:</div>
                            {transfers.map(transfer => {
                                return (
                                    <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.from)}>
                                        <div className={styles.hash}>{transfer.from.slice(0, 11) + '...' + transfer.from.slice(-5)}</div>
                                        <FileCopyIcon className={styles.tableRowIcon} />
                                    </div>
                                );
                            })}
                        </div>
                    </TableCell>
                    <TableCell classes={{ root: styles.tableCell }} align="left">
                        <div className={styles.tableCellData}>
                            <div>To:</div>
                            {transfers.map(transfer => {
                                return (
                                    <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.to)}>
                                        <div className={styles.hash}>{transfer.to.slice(0, 11) + '...' + transfer.to.slice(-5)}</div>
                                        <FileCopyIcon className={styles.tableRowIcon} />
                                    </div>
                                );
                            })}
                        </div>
                    </TableCell>
                    <TableCell classes={{ root: styles.tableCell }} align="left">
                        <div className={styles.tableCellData}>
                            <div>Value:</div>
                            {transfers.map(transfer => {
                                return (
                                    <div>{transfer.ethValue}</div>
                                );
                            })}
                        </div>
                    </TableCell>
                </>
                :
                additionalData && additionalData.blockchain && additionalData.blockchain === 'ETHEREUM' ?
                    <>
                        <TableCell classes={{ root: styles.tableCell }} align="left">
                            <div className={styles.tableCellData}>
                                {transfers.map((transfer, index) => {

                                    if (index === 0 && transfer.orderFulfilled) {

                                        return (
                                            <>
                                                <div>From:</div>
                                                <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.from)}>
                                                    <div className={styles.hash}>{transfer.from.slice(0, 11) + '...' + transfer.from.slice(-5)}</div>
                                                    <FileCopyIcon className={styles.tableRowIcon} />
                                                </div>
                                            </>
                                        );
                                    } else if (index === 0) {
                                        if (transfer && transfer.from) {
                                            return (
                                                <>
                                                    <div>From:</div>
                                                    <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.from)}>
                                                        <div className={styles.hash}>{transfer.from.slice(0, 11) + '...' + transfer.from.slice(-5)}</div>
                                                        <FileCopyIcon className={styles.tableRowIcon} />
                                                    </div>
                                                </>
                                            );
                                        }

                                    }

                                    if (transfers[0].orderFulfilled) {
                                        return null;
                                    }



                                    if (transfer && transfer.from) {
                                        return (
                                            <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.from)}>
                                                <div className={styles.hash}>{transfer.from.slice(0, 11) + '...' + transfer.from.slice(-5)}</div>
                                                <FileCopyIcon className={styles.tableRowIcon} />
                                            </div>
                                        );
                                    }

                                })}
                            </div>
                        </TableCell>
                        <TableCell classes={{ root: styles.tableCell }} align="left">
                            <div className={styles.tableCellData}>
                                {transfers.map((transfer, index) => {
                                    if (index > 0 && transfer.addressTaker) {
                                        return null;
                                    }

                                    if (index === 0 && transfer.orderFulfilled) {
                                        return (
                                            <>
                                                <div>To:</div>
                                                <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.to)}>
                                                    <div className={styles.hash}>{transfer.to.slice(0, 11) + '...' + transfer.to.slice(-5)}</div>
                                                    <FileCopyIcon className={styles.tableRowIcon} />
                                                </div>
                                            </>
                                        );
                                    } else if (index === 0) {

                                        if (transfer && transfer.to) {
                                            return (
                                                <>
                                                    <div>To:</div>
                                                    <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.to)}>
                                                        <div className={styles.hash}>{transfer.to.slice(0, 11) + '...' + transfer.to.slice(-5)}</div>
                                                        <FileCopyIcon className={styles.tableRowIcon} />
                                                    </div>
                                                </>
                                            );
                                        }

                                        if (transfer && transfer.addressTaker) {
                                            return (
                                                <>
                                                    <div>Address Taker:</div>
                                                    <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.addressTaker)}>
                                                        <div className={styles.hash}>{transfer.addressTaker.slice(0, 11) + '...' + transfer.addressTaker.slice(-5)}</div>
                                                        <FileCopyIcon className={styles.tableRowIcon} />
                                                    </div>
                                                </>
                                            );
                                        }
                                    }

                                    if (transfers[0].orderFulfilled) {
                                        return null;
                                    }

                                    if (transfer && transfer.to) {
                                        return (
                                            <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.to)}>
                                                <div className={styles.hash}>{transfer.to.slice(0, 11) + '...' + transfer.to.slice(-5)}</div>
                                                <FileCopyIcon className={styles.tableRowIcon} />
                                            </div>
                                        );
                                    }

                                    return (
                                        <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.addressTaker)}>
                                            <div className={styles.hash}>{transfer.addressTaker.slice(0, 11) + '...' + transfer.addressTaker.slice(-5)}</div>
                                            <FileCopyIcon className={styles.tableRowIcon} />
                                        </div>
                                    );
                                })}
                            </div>
                        </TableCell>
                        <TableCell classes={{ root: styles.tableCell }} align="left">
                            <div className={styles.tableCellData}>
                                {transfers.map((transfer, index) => {
                                    if (index > 0 && transfer.sellHash) {
                                        return null;
                                    }

                                    if (index === 0 && transfer.orderFulfilled) {
                                        return (
                                            <>
                                                <div>Creator:</div>
                                                <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.creatorTaxCollector)}>
                                                    <div className={styles.hash}>{transfer.creatorTaxCollector.slice(0, 11) + '...' + transfer.creatorTaxCollector.slice(-5)}</div>
                                                    <FileCopyIcon className={styles.tableRowIcon} />
                                                </div>
                                            </>
                                        );
                                    } else if (index === 0) {
                                        if (transfer && transfer.transactionChain) {
                                            return (
                                                <>
                                                    <div>Currency:</div>
                                                    <div className={styles.tableRow}>
                                                        <div className={styles.hash}>{transfer.transactionChain}</div>
                                                    </div>
                                                </>
                                            );
                                        }

                                        if (transfer && transfer.sellHash) {
                                            return (
                                                <>
                                                    <div>Sell Hash:</div>
                                                    <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.sellHash)}>
                                                        <div className={styles.hash}>{transfer.sellHash.slice(0, 11) + '...' + transfer.sellHash.slice(-5)}</div>
                                                        <FileCopyIcon className={styles.tableRowIcon} />
                                                    </div>
                                                </>
                                            );
                                        }
                                    }

                                    if (transfers[0].orderFulfilled) {
                                        return null;
                                    }

                                    if (transfer && transfer.transactionChain) {
                                        return (
                                            <div className={styles.tableRow}>
                                                <div className={styles.hash}>{transfer.transactionChain}</div>
                                            </div>
                                        );
                                    }

                                    return (
                                        <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.sellHash)}>
                                            <div className={styles.hash}>{transfer.sellHash.slice(0, 11) + '...' + transfer.sellHash.slice(-5)}</div>
                                            <FileCopyIcon className={styles.tableRowIcon} />
                                        </div>
                                    );
                                })}
                            </div>
                        </TableCell>
                        <TableCell classes={{ root: styles.tableCell }} align="left">
                            <div className={styles.tableCellData}>
                                {transfers.map((transfer, index) => {
                                    if (index > 0 && transfer.buyHash) {
                                        return null;
                                    }

                                    if (index === 0 && transfer.orderFulfilled) {
                                        return (
                                            <>
                                                <div>Value to Creator:</div>
                                                <div className={styles.tableRow}>
                                                    <div className={styles.hash}>{transfer.ethValueToCreator}</div>
                                                </div>
                                            </>
                                        );
                                    } else if (index === 0) {
                                        if (transfer && transfer.ethValue) {
                                            return (
                                                <>
                                                    <div>Eth Value:</div>
                                                    <div className={styles.tableRow}>
                                                        <div className={styles.hash}>{transfer.ethValue}</div>
                                                    </div>
                                                </>
                                            );
                                        }

                                        if (transfer && transfer.buyHash) {
                                            return (
                                                <>
                                                    <div>Buy Hash:</div>
                                                    <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.buyHash)}>
                                                        <div className={styles.hash}>{transfer.buyHash.slice(0, 11) + '...' + transfer.buyHash.slice(-5)}</div>
                                                        <FileCopyIcon className={styles.tableRowIcon} />
                                                    </div>
                                                </>
                                            );
                                        }
                                    }

                                    if (transfers[0].orderFulfilled) {
                                        return null;
                                    }

                                    if (transfer && transfer.ethValue) {
                                        return (
                                            <div className={styles.tableRow}>
                                                <div className={styles.hash}>{transfer.ethValue}</div>
                                            </div>
                                        );
                                    }

                                    return (
                                        <div className={styles.tableRow} onClick={() => navigator.clipboard.writeText(transfer.buyHash)}>
                                            <div className={styles.hash}>{transfer.buyHash.slice(0, 11) + '...' + transfer.buyHash.slice(-5)}</div>
                                            <FileCopyIcon className={styles.tableRowIcon} />
                                        </div>
                                    );
                                })}
                            </div>
                        </TableCell>
                        <TableCell classes={{ root: styles.tableCell }} align="left">
                            <div className={styles.tableCellData}>

                                {transfers.map((transfer, index) => {
                                    if (index > 0 && transfer.price) {
                                        return null;
                                    }

                                    if (index === 0 && transfer.orderFulfilled) {
                                        return (
                                            <>
                                                <div>Currency:</div>
                                                <div>{transfer.currency}</div>
                                            </>
                                        );
                                    } else if (index === 0) {
                                        return (
                                            <>
                                                <div>Value:</div>
                                                <div>{
                                                    transfer && transfer.value ?
                                                        transfer.value
                                                        : 'N/A'
                                                }</div>
                                            </>
                                        );
                                    }

                                    if (transfers[0].orderFulfilled) {
                                        return null;
                                    }

                                    return (
                                        <div>{
                                            transfer && transfer.value ?
                                                transfer.value
                                                : 'N/A'
                                        }
                                        </div>
                                    );
                                })}
                            </div>
                        </TableCell>
                    </>
                    :
                    null
            }
        </TableRow>
    );
}

export default NFTActivityList;