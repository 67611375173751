import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, IconButton, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useStoreContext } from '../../store/Store';
import { MatchingLevels } from '../../constants/song-matching-levels-constants';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    foundSong: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
        borderRadius: theme.spacing(2.5),
        height: theme.spacing(5),
        width: '288px',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        justifyContent: 'space-between'
    },
    fullMatchBackground: {
        backgroundColor: theme.palette.primary.main,
    },
    partialBackground: {
        backgroundColor: theme.palette.background.partialMatch,
    },
    suggestionsBackground: {
        backgroundColor: theme.palette.background.suggestedSongsMatch,
    },
    manualMatchBackground: {
        backgroundColor: theme.palette.background.manualMatch,
    },
    foundSongAvatar: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
    },
    foundSongLeftSide: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    foundSongLabel: {
        color: 'black',
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    foundSongIconButton: {
        '& .MuiIconButton-root': {
            color: 'black',
            fontWeight: 'bold',
            padding: '5px'
        }
    },
    customTooltip: {
        [`& .MuiTooltip-tooltip`]: {
          backgroundColor: theme.palette.primary.black,
          color: theme.palette.primary.main,
          borderRadius: 12,
          font: 'normal normal normal 16px/26px Roboto',
          textAlign: 'left',
          fontSize: '16px',
          lineHeight: '20px',
          padding: '10px 15px', 
        },
      },
}));

export default function FoundSong({
    songMap,
    songMapKey,
    setExternalISRCs,
    setExternalUPCs
}) {
    const styles = useStyles();

    const [state, setState] = useStoreContext();
    const getBackgorundByMatchingLevel = () => {
        if (songMap.manuallyMatched)
            return styles.manualMatchBackground;
        else if (songMap.matchingLevel === MatchingLevels.PARTIAL_MATCH)
            return styles.partialBackground;
        else if (songMap.matchingLevel === MatchingLevels.PARTIAL_MATCH_SUGGESTIONS)
            return styles.suggestionsBackground;
        else
            return styles.fullMatchBackground;
    }
    const label = `${songMap && songMap.foundArtistId ? `${songMap.foundArtist} - ` : ''}${songMap.foundTitle || songMap.title}`;

    const deleteExternalData = (setExternalData, value) => {
        if (!value) return;

        setExternalData((prev) => ({
            ...prev,
            [songMap.foundSongId]: (prev[songMap.foundSongId] || []).filter((x) => x !== value)
        }));
    }

    const deleteFoundSongData = () => {
        const tempSongsMap = songMap.matchingLevel === MatchingLevels.FULL_MATCH ? state.songsMap : state.partialSongsMap;
        tempSongsMap[songMapKey] = {
            ...tempSongsMap[songMapKey],
            ...{
                foundSongId: '',
                foundArtistId: '',
                foundTitle: '',
                foundArtist: '',
                foundIsrc: '',
                foundAlbum: '',
                foundCoverArt: '',
                foundAccountHolders: '',
                found: false,
                manuallyMatched: false
            }
        };

        if (songMap.matchingLevel === MatchingLevels.FULL_MATCH) {
            setState(state => (state.songsMap = { ...tempSongsMap }, { ...state }));
        } else {
            setState(state => (state.partialSongsMap = { ...tempSongsMap }, { ...state }));
        }

        deleteExternalData(setExternalISRCs, songMap.isrc);
        deleteExternalData(setExternalUPCs, songMap.upc);
    }

    return (
        <Tooltip title={songMap.manuallyMatched ? `This song has been manually matched` : `${songMap.matchingRule}`} placement="bottom"
        classes={{ popper: styles.customTooltip }}
             >
            <div className={clsx(styles.foundSong, getBackgorundByMatchingLevel())} >
                <div className={styles.foundSongLeftSide}>
                    <Avatar
                        className={styles.foundSongAvatar}
                        alt={`Avatar of ${songMap.foundTitle || songMap.title}`}
                        src={songMap && songMap.foundCoverArt ? songMap.foundCoverArt : null}
                    />
                </div>
                <Tooltip title={label} placement="top">
                    <div className={styles.foundSongLabel}>
                        {label}
                    </div>
                </Tooltip>
                <div className={styles.foundSongIconButton}>
                    <IconButton onClick={deleteFoundSongData}><ClearIcon /></IconButton>
                </div>
            </div>
        </Tooltip>
    );
}