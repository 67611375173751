import { useEffect, useState } from "react";
import {  TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BrandAutocomplete } from "../CoreComponents/BrandAutoComplete";
import useAbortableFetch from '../../hooks/useAbortableFetch.js';
import { BrandDateRange } from "../CoreComponents/BrandDatePicker.js";

const useStyles = makeStyles((theme) => ({
    pageMainHeader: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginBottom: '30px'
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        alignItems: 'flex-start',
        '& .MuiButton-root': {
            textTransform: 'capitalize'
        }
    },
    mainWrapper: {
        marginTop: theme.spacing(3)
    },
    filterWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(3),
        marginTop: '30px'
    },
    autocompleteWrapper: {
        width: '275px',
    },
    filterLabel: {
        color: theme.palette.text.lightYellow,
    },
    notesField: {
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.text.lightYellow
            },
            '&:hover fieldset': {
                borderColor: theme.palette.text.lightYellow
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.text.lightYellow
            },
        },
    },
    requiredChar: {
        color: theme.palette.primary.alert
    }
}));

export default function FiltersSetupStep({
    selectedFitlers,
    setSelectedFitlers
}) {
    const styles = useStyles();
    const abortableFetch = useAbortableFetch();

    const [payorAccountHolders, setPayorAccountHolders] = useState([]);
    const [payeeAccountHolders, setPayeeAccountHolders] = useState([]);

    useEffect(() => {
        abortableFetch('GET', '/api/account-holder/my')
            .then(data => {
                setPayorAccountHolders(data);
            });
    }, []);

    useEffect(() => {
        if (selectedFitlers.payorId)
            abortableFetch('GET', '/api/account-holder/payees', {
                query: { payorId: selectedFitlers.payorId}
            }).then(data => {
                setPayeeAccountHolders(data);
            });
        else {
            setSelectedFitlers(prevState => ({ ...prevState, payeeId: 0 }));
            setPayeeAccountHolders([]);
        }
    }, [selectedFitlers.payorId]);

    return (
        <>
            <div className={styles.filterWrapper}>
                <div className={styles.filterLabel}>Payor (Label) <span className={styles.requiredChar}>*</span></div>
                <div className={styles.autocompleteWrapper}>
                    <BrandAutocomplete
                        placeholder={'Select Account Holder'}
                        selectedAutoCompleteOption={selectedFitlers.payorId}
                        onSelectedAutoCompleteOption={(newValue) => setSelectedFitlers(prevState => ({ ...prevState, payorId: newValue, payeeId: 0 }))}
                        autoCompleteOptions={payorAccountHolders}
                    />
                </div>
                <div className={styles.filterLabel}>Payee (Artist) <span className={styles.requiredChar}>*</span></div>
                <div className={styles.autocompleteWrapper}>
                    <BrandAutocomplete
                        placeholder={'Select Account Holder'}
                        selectedAutoCompleteOption={selectedFitlers.payeeId}
                        onSelectedAutoCompleteOption={(newValue) => setSelectedFitlers(prevState => ({ ...prevState, payeeId: newValue }))}
                        autoCompleteOptions={payeeAccountHolders}
                    />
                </div>
                <div className={styles.filterLabel}>Date Range <span className={styles.requiredChar}>*</span></div>
                <BrandDateRange 
                    startDate={selectedFitlers.startDate}
                    setStartDate={(newValue) => setSelectedFitlers(prevState => ({ ...prevState, startDate: newValue }))}
                    endDate={selectedFitlers.endDate}
                    setEndDate={(newValue) => setSelectedFitlers(prevState => ({ ...prevState, endDate: newValue }))}
                />
            </div>
            <div className={styles.filterWrapper}>
                <div className={styles.notesField}>
                    <div>Subtitle (Optional)</div>
                    <TextField
                        value={selectedFitlers.subtitle}
                        onChange={(e) => setSelectedFitlers(prevState => ({ ...prevState, subtitle: e.target.value }))}
                        id="outlined-multiline-static"
                        multiline
                        minRows={5}
                        variant="outlined"
                        fullWidth={true}
                        maxRows={15}
                    />
                </div>
            </div>
        </>
    );
}