import { makeStyles, TablePagination } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useStoreContext } from "../../store/Store";
import BrandTable from "../CoreComponents/BrandTable";

const useStyles = makeStyles((theme) => ({

}));

function ResolvedCasesTable({ data }) {

    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [rowsPerPageOptions] = useState([5, 10, 15]);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [rowCounts, setRowCounts] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [sortBy, setSortBy] = useState('timestamp');
    const [sortType, setSortType] = useState('ASC');

    const [headCells, setHeadCells] = useState([
        {
            id: 'title',
            label: 'Song Title',
            CellRender: ({ cellData }) => {
                return (
                    <div>{cellData}</div>
                );
            },
        },
        {
            id: 'name',
            label: 'Artist',
            CellRender: ({ cellData }) => {
                return (
                    <div>{cellData}</div>
                )
            }
        },
        {
            id: 'masterPerformanceRevenue',
            label: 'Master Performance',
            notSortable: true,
            CellRender: ({ cellData }) => {
                return (
                    <div>{cellData}</div>

                );
            }
        },
        {
            id: 'LabelDistributorRevenue',
            label: 'Label/Distributor',
            notSortable: true,
            CellRender: ({ cellData }) => {
                return (
                    <div>{cellData}</div>
                );
            }
        },
        {
            id: 'PublisherAdminRevenue',
            label: 'Publisher/Admin',
            notSortable: true,
            CellRender: ({ cellData }) => {
                return (
                    <div>{cellData}</div>
                );
            }
        },
        {
            id: 'proRevenue',
            label: 'Publishing PRO Reports',
            notSortable: true,
            CellRender: ({ cellData }) => {
                return (
                    <div>{cellData}</div>
                );
            }
        },
        {
            id: 'resolutionDate',
            label: 'Date Resolved',
            notSortable: true,
            CellRender: ({ cellData }) => {
                return (
                    <div>{cellData}</div>
                );
            }
        },
    ]);

    useEffect(() => {
        setOffset(page * rowsPerPage)
    }, [page]);

    useEffect(() => {
        setPage(0);
    }, [rowsPerPage]);

    useEffect(() => {
        if (state.user.userType === 1) {
            setRows(data);
            setHeadCells([
                {
                    id: 'title',
                    label: 'Song Title',
                    CellRender: ({ cellData }) => {
                        return (
                            <div>{cellData}</div>
                        );
                    },
                },
                {
                    id: 'name',
                    label: 'Artist',
                    CellRender: ({ cellData }) => {
                        return (
                            <div>{cellData}</div>
                        )
                    }
                },
                {
                    id: 'masterPerformanceRevenue',
                    label: 'Master Performance',
                    notSortable: true,
                    CellRender: ({ cellData }) => {
                        return (
                            <div>{cellData}</div>

                        );
                    }
                },
                {
                    id: 'LabelDistributorRevenue',
                    label: 'Label/Distributor',
                    notSortable: true,
                    CellRender: ({ cellData }) => {
                        return (
                            <div>{cellData}</div>
                        );
                    }
                },
                {
                    id: 'PublisherAdminRevenue',
                    label: 'Publisher/Admin',
                    notSortable: true,
                    CellRender: ({ cellData }) => {
                        return (
                            <div>{cellData}</div>
                        );
                    }
                },
                {
                    id: 'proRevenue',
                    label: 'Publishing PRO Reports',
                    notSortable: true,
                    CellRender: ({ cellData }) => {
                        return (
                            <div>{cellData}</div>
                        );
                    }
                },
                {
                    id: 'submittedBy',
                    label: 'Submitted By',
                    notSortable: true,
                    CellRender: ({ cellData }) => {
                        return (
                            <div>{cellData}</div>
                        );
                    }
                },
                {
                    id: 'dateSubmitted',
                    label: 'Date Submitted',
                    notSortable: true,
                    CellRender: ({ cellData }) => {
                        return (
                            <div>{cellData}</div>
                        );
                    }
                },
                {
                    id: 'resolutionDate',
                    label: 'Date Resolved',
                    notSortable: true,
                    CellRender: ({ cellData }) => {
                        return (
                            <div>{cellData}</div>
                        );
                    }
                },
            ])
        } else {
            setRows(data);
        }
    }, []);

    return (
        <>
            <BrandTable
                headCells={headCells}
                rows={rows}
                page={page}
                setSortBy={setSortBy}
                setSortType={setSortType}
                hidePagination={true}
                checkboxless={true}
                className={styles.nftActivityTable}
            />
            <TablePagination
                className={styles.tablePagination}
                rowsPerPageOptions={rowsPerPageOptions}
                count={rowCounts}
                rowsPerPage={rowsPerPage}
                page={page}
                component="div"
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
            />
        </>
    );
};

export default ResolvedCasesTable;