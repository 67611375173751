import { makeStyles, TablePagination, IconButton, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { BrandDeleteModal, BrandModal } from '../CoreComponents/BrandModal'
import React, { memo, useEffect, useState } from "react";
import { useStoreContext } from "../../store/Store";
import BrandTable from "../CoreComponents/BrandTable";
import { getData } from "../utils/FetchUtils";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { AlternativeNames } from "./AlternativeNames";
import { BrandButton, TempBrandButton } from "../CoreComponents/BrandButton";
import { ButtonSize } from "../../constants/buttonConstants";

const useStyles = makeStyles((theme) => ({
    summary: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '25px',
    },
    songEquivalentMaps: {
        marginLeft: '2%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    selected: {
        background: '#252525',
        borderRadius: '5px',
        fontWeight: 'bold'
    },
    title: {
        fontSize: '30px',
    },
    vertical: {
        height: '65vh',
        display: 'inline-block',
        border: '1px solid #393939',
        marginLeft: '8px',
        marginRight: '16px'
    },
    equivalentSongsMappingColumns: {
        minWidth: '150px',
        "& .MuiList-root": {
            maxHeight: '55vh',
            overflow: 'auto',
            "& .Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.background.default,
            },
            "& .MuiListItem-button:hover": {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: theme.palette.background.default,
            },
        },
    },
    potentialSongsHeaderSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '42px',
        marginBottom: '10px'
    },
    tableContainer: {
        '& .MuiTableContainer-root': {
            maxHeight: '55vh'
        }
    }
}));
const getCheckValueTitle = (input) => {
    let startIndex = input.indexOf(' ') + 1;
    let endIndex = 0;

    const selectors = ['FEAT', 'W', 'WITH'];
    const singleSelectors = ['(', '-', '['];

    for (let i = 0; i < input.length; i++) {
        const symbol = input[i];

        if (singleSelectors.includes(symbol)) {
            if (startIndex === 0)
                return input.slice(0).trim();

            return input.slice(0, startIndex - 1).trim();
        }

        if (symbol === ' ') {
            const word = input.slice(startIndex, i);

            if (selectors.includes(word)) {
                return input.slice(0, startIndex).trim();
            }

            startIndex = i + 1;
            endIndex = i;
        }
    }

    const word = input.slice(startIndex);

    return selectors.includes(word) ? input.slice(0, endIndex).trim() : input.trim();
}

const GetEquivalentSongs = ({
    songId,
    selectedSong,
    equivalentSongs,
    setEquivalentSongs,
    csvToServer,
    reportType,
    setSongId,
    setData,
    setSelectedSong,
    alternativeNamesData,
    setAlternativeNamesData,
    reportCompanyName
}) => {
    const [state, setState] = useStoreContext();
    const styles = useStyles();
    const [songOptions, setSongOptions] = useState(equivalentSongs[Object.keys(equivalentSongs)[0]].rows);
    const [rows, setRows] = useState(Object.values(state.songsMap)[0]);
    const [displayAltNames, setDisplayAltNamesModal] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPageOptions] = useState([25, 50, 100]);
    const [rowCounts, setRowCounts] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selectedSongData, setSelectedSongData] = useState(Object.values(state.songsMap).filter(x => x.foundSongId)[0]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [altNamesButtonDisable, setAltNamesButtonDisable] = useState(true);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [uniqueSongs, setUniqueSongs] = useState({})
    const [headCells, setHeadCells] = useState([
        {
            id: 'title',
            label: 'Song Title',
            notSortable: true,
        },
        {
            id: 'artist',
            label: 'Artist',
            notSortable: true,
        },
        {
            id: 'consumptionPeriod',
            label: 'Consumption Period',
            notSortable: true,
        },
        {
            id: 'reportingPeriod',
            label: 'Reporting Period',
            notSortable: true,
        },
        {
            id: 'revenue',
            label: 'Revenue',
            notSortable: true,
        },
        {
            id: reportType === 1 || reportType === 3 ? 'publishingOwnership' : 'masterOwnership',
            label: reportType === 1 || reportType === 3 ? 'Publishing Ownership %' : 'Master Ownership %',
            notSortable: true,
        },
        {
            id: 'isrc',
            label: 'ISRC',
            notSortable: true,
        },
        {
            id: 'upc',
            label: 'UPC',
            notSortable: true,
        },
        {
            id: 'releaseDate',
            label: 'Release Date',
            notSortable: true,
        },
        {
            id: 'writers',
            label: 'Writer(s)',
            notSortable: true,
        },
        {
            id: 'producers',
            label: 'Producer(s)',
            notSortable: true,
        },
        {
            id: 'territory',
            label: 'Territory',
            notSortable: true,
        },
        {
            id: 'album',
            label: 'Album',
            notSortable: true,
        },
        {
            id: 'playSource',
            label: 'Play Source',
            notSortable: true,
        }
    ]);

    useEffect(() => {
        setPage(0);
    }, [selectedSong]);

    const onClose = () => {
        setDisplayAltNamesModal(false);
    };

    useEffect(() => {
        if (selectedSong) {
            setSongOptions(equivalentSongs[selectedSong[selectedSong.length - 1]] ? equivalentSongs[selectedSong[selectedSong.length - 1]].rows : []);
        }
    }, [selectedSong]);

    useEffect(() => {
        const songsList = [...Object.values(state.songsMap), ...Object.values(state.partialSongsMap)].filter(x => x.foundSongId)
        if (songsList.length > 0) {
            const initialSongId = songsList[0].foundSongId;

            getData(process.env.REACT_APP_SERVER_HOST + `/api/song/get-alternative-names?songId=${initialSongId}`)
                .then(data => {
                    const filtered = data.filter(x => x.source === reportCompanyName)
                    setAlternativeNamesData(filtered);
                    setAltNamesButtonDisable(false);
                });
        }
    }, [])

    const handleAlternativeNames = (e) => {
        setDisplayAltNamesModal(true);

    };

    const handleClickSelectedSong = (e, index, x) => {

        setRows(uniqueSongs[x])
        setAltNamesButtonDisable(true);
        setSelectedIndex(index);
        setSelectedSong(Object.keys(equivalentSongs).filter(song => {
            if (equivalentSongs[song].externalId !== 'No Data') {
                return equivalentSongs[song].externalId === uniqueSongs[x][0].externalId;
            } else {
                return equivalentSongs[song].title === uniqueSongs[x][0].title;
            }
        }));
        setSelectedSongData(uniqueSongs[x])
        setSongId(uniqueSongs[x][0].foundSongId);

        getData(process.env.REACT_APP_SERVER_HOST + `/api/song/get-alternative-names?songId=${uniqueSongs[x][0].foundSongId}`)
            .then(data => {
                const filtered = data.filter(x => x.source === reportCompanyName)
                setAlternativeNamesData(filtered);
                setAltNamesButtonDisable(false);
            });
    };

    useEffect(() => {
        if (alternativeNamesData.length === 0) {
            setAltNamesButtonDisable(true);
        }
    }, [alternativeNamesData]);

    useEffect(() => {
        const songsMapsArray = [...Object.entries(state.songsMap), ...Object.entries(state.partialSongsMap)];
        const newObj = {}
        for (let i = 0; i < songsMapsArray.length; i++) {
            const element = songsMapsArray[i];
            const values = element[1];
            const checkedName = values.foundTitle !== '' ? getCheckValueTitle(values.foundTitle) : getCheckValueTitle(values.title)

            if (newObj[checkedName]) {
                newObj[checkedName].push(values)
            } else {
                newObj[checkedName] = [];
                newObj[checkedName].push(values)
            }
        }
        setUniqueSongs(newObj)
    }, [])

    return (
        <React.Fragment>
            <div className={styles.summary}>
                <BrandDeleteModal
                    open={showDeleteDialog}
                    onClose={() => setShowDeleteDialog(false)}
                >
                    <Typography
                        variant="h5"
                        style={{ paddingBottom: 20 }}
                    >
                        Please confirm that you want to proceed with the deletion of this song
                    </Typography>
                </BrandDeleteModal>
                <div className={styles.equivalentSongsMappingColumns}>
                    <h3>Songs</h3>

                    <List>
                        {
                            Object.keys(uniqueSongs).length > 0 ?
                                Object.keys(uniqueSongs).map((x, indx) =>
                                    <ListItem
                                        button
                                        key={x + '-' + indx}
                                        selected={selectedIndex === indx}
                                        onClick={(e) => handleClickSelectedSong(e, indx, x)}
                                    >
                                        <ListItemText primary={x} />
                                    </ListItem>
                                )
                                :
                                <ListItemText primary={"No Data"} />
                        }
                    </List>
                </div>
                <hr className={styles.vertical} />
                <div className={styles.songEquivalentMaps}>
                    <div className={styles.potentialSongsHeaderSection}>
                        <h3>Potential song equivalents</h3>
                        <BrandModal
                            open={displayAltNames}
                            onClose={onClose}
                        >
                            <AlternativeNames
                                onClose={onClose}
                                alternativeNamesData={alternativeNamesData}
                                setAlternativeNamesData={setAlternativeNamesData}
                                setAltNamesButtonDisable={setAltNamesButtonDisable}
                                selectedSongData={selectedSongData}
                                selectedSongId={songId}
                                reportCompanyName={reportCompanyName}
                            />
                        </BrandModal>
                        <TempBrandButton
                            size={ButtonSize.SMALL}
                            disabled={altNamesButtonDisable}
                            startIcon={<EditIcon />}
                            onClick={(e) => handleAlternativeNames(e, selectedSongData.foundSongId)}
                        >
                            ALT NAMES
                        </TempBrandButton>
                    </div>
                    {rows && rows.length ?
                        <div className={styles.tableContainer}>
                            <BrandTable
                                rows={rows}
                                headCells={headCells}
                                checkboxless={true}
                                page={page}
                                setPage={setPage}
                                hidePagination={true}
                            />
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                component="div"
                                count={rowCounts}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(event, newPage) => setPage(newPage)}
                                onRowsPerPageChange={(event) => {
                                    setRowsPerPage(parseInt(event.target.value, 10));
                                    setPage(0);
                                }}
                            />
                        </div>
                        :
                        <div> {'No potential equivalents were found for this song!'}</div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
};

export default memo(GetEquivalentSongs)