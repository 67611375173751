import { makeStyles } from "@material-ui/core";
import Shares from "./Shares";
import { useEffect, useState } from "react";
import { getData } from "../../../utils/FetchUtils";
import { TempBrandButton } from "../../../CoreComponents/BrandButton";
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { shareTypeEnum } from "../../../utils/MusicShareEnums";
import { ButtonSize } from "../../../../constants/buttonConstants";

const useStyles = makeStyles((theme) => ({
    songsRegistrationContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '15px',
        marginLeft: '92px',
        marginRight: '20px'
    },
    navigationBtn: {
        width: '200px',
        height: '40px',
        color: `${theme.palette.background.default} !important`,
    },
    manageSharesStyles: {
        margin: '20px 0px 0px 0px',
    }
}));


export default function SongsRegistration({
    details,
    isAdmin,
    isInManageShares,
    setIsEmptyData,
    isApplyClicked,
    refresh,
    setRefresh
}) {
    const styles = useStyles();
    const masterHeaders = ['AccountHolder', 'Participant Type', 'Shares'];
    const compositionHeaders = ['Account Holder', 'Participant Type', 'Report Type', 'Reporting Company', 'Shares'];
    const [sharesData, setSharesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState('');

    const [masterData, setMasterData] = useState([]);
    const [compositionData, setCompositionData] = useState([]);

    const fetchData = () => {
        setLoading(true);

        getData(process.env.REACT_APP_SERVER_HOST + `/api/music-shares/get-music-shares-data?songId=${details.id}`)
            .then(res => {
                setSharesData(res.data);
                setLoading(false);
                setRefresh?.(false);
                res.data.length === 0 ? setIsEmptyData?.(true) : setIsEmptyData?.(false)
            })
            .catch(err => {
                console.log(err.message);
                setAlert(err.message);
                setRefresh?.(false);
                setLoading(false);
            })
    };

    useEffect(() => {
        fetchData();

    }, [isApplyClicked, details, refresh]);
    useEffect(() => {

        setMasterData(sharesData.filter(el => el.shareType === shareTypeEnum.MASTER_SHARE_TYPE));
        setCompositionData(sharesData.filter(el => el.shareType === shareTypeEnum.COMPOSITION_SHARE_TYPE));

    }, [sharesData]);

    const expandCondition =
        compositionData.length > 0 && masterData.length === 0 ?
            'composition'
            :
            compositionData.length === 0 && masterData.length > 0 ?
                'master'
                :
                compositionData.length > 0 && masterData.length > 0 ?
                    'master'
                    :
                    compositionData.length === 0 && masterData.length === 0 ?
                        'master'
                        :
                        ''
    return (
        <div className={clsx(styles.songsRegistrationContainer, isInManageShares ? styles.manageSharesStyles : '')}>
            {isAdmin ?
                !!isInManageShares ?
                    ''
                    :
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        capitalize={true}
                        className={styles.navigationBtn}
                        component={NavLink} to="/manage-shares"
                    >
                        Upload CSV
                    </TempBrandButton>
                :
                ''
            }
            <Shares
                expandCondition={expandCondition}
                title='Master Shares'
                tableHeaders={masterHeaders}
                sharesData={masterData}
                alert={alert}
                loading={loading}
                originalData={sharesData}
                setOriginalData={setSharesData}
                fetchData={fetchData}
                isAdmin={isAdmin}
                isInManageShares={isInManageShares}
            />
            <Shares
                expandCondition={expandCondition}
                title='Compostion Shares'
                tableHeaders={compositionHeaders}
                sharesData={compositionData}
                alert={alert}
                loading={loading}
                originalData={sharesData}
                setOriginalData={setSharesData}
                fetchData={fetchData}
                isAdmin={isAdmin}
                isInManageShares={isInManageShares}
            />
        </div>
    );
}