import { makeStyles } from "@material-ui/core";
import { BrandVerticalBarsChart } from "../CoreComponents/BrandChart";
import { NavLink } from 'react-router-dom';
import { TempBrandButton } from "../CoreComponents/BrandButton";
import { formatNumber } from "../utils/NumberUtils";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter, shortenLabel } from "../utils/StringUtils";
import { ButtonSize } from "../../constants/buttonConstants";

const useStyles = makeStyles((theme) => ({
    fourChartsHeadings: {
        display: 'flex',
        marginBottom: '10px',
        font: 'normal normal normal 18px/37px Roboto'
    },
    fourChartsHeadingsWihoutButton: {
        marginRight: '15px'
    },
    fourChartsSubheadings: {
        marginBottom: '15px',
        font: 'normal normal normal 14px/33px Roboto',
        color: theme.palette.text.lightGray
    },
    miniChartsComponent: {
        marginTop: theme.spacing(10),
    },
    innerChartContainer: {
        marginRight: '60px'
    }

}));
export default function EarningsChart ({ 
    loadingChart,
    chartData,
    sumRevenue,
    chartHeader,
    totalRevenue,
    dataType
}) {
    const styles = useStyles();
    const [readyChartData, setReadyChartData] = useState([]);
    const [useLongLabels, setUseLongLabels] = useState(false);

    const MAX_LENGTH_FOR_LONG_LABEL = 18;
    const MAX_LENGTH_FOR_NORMAL_LABEL = 9;
    const NUMBER_OF_DATA_LENGHT_TO_SHORTEN_LABELS = 2;
    
    const modifyChartData = (chartData) => {
        if (chartData.length === 0) return [];
    
        const modifyLongStrings = (item, label, originalLabel) => {
            const string = item[label];
    
            if (string.length <= MAX_LENGTH_FOR_NORMAL_LABEL) {
                item[originalLabel] = string;
                item[label] = string;
            } else if (string.length > MAX_LENGTH_FOR_NORMAL_LABEL) {
                item[originalLabel] = shortenLabel(string, MAX_LENGTH_FOR_LONG_LABEL);
                item[label] = shortenLabel(string, MAX_LENGTH_FOR_NORMAL_LABEL);
            }
        };

        chartData.forEach((item) => {
            const label = dataType ? dataType : 'year';
            const originalLabel = 'original label';
    
            if (item[label] !== null) {
                if (chartData.length > NUMBER_OF_DATA_LENGHT_TO_SHORTEN_LABELS) {
                    modifyLongStrings(item, label, originalLabel);
                    setUseLongLabels(true);
                }
            } else {
                item[label] = chartData.length > NUMBER_OF_DATA_LENGHT_TO_SHORTEN_LABELS ? 'Uncat...' : 'Uncategorized';
            }
        });
    
        return chartData;
    };

    useEffect(() => {
        const modifiedChartData = modifyChartData(chartData)
        setReadyChartData(modifiedChartData)
    }, [chartData]);

    const percentCalculation = ((sumRevenue.revenue / totalRevenue) * 100).toFixed(2);
    const percentRevenue = !isNaN(percentCalculation) ? percentCalculation : 'N/A';

    return (
        <div>
        <div className={styles.fourChartsHeadings}>
            <div className={styles.fourChartsHeadingsWihoutButton}>{chartHeader}</div>
            <NavLink to="/earnings"><TempBrandButton size={ButtonSize.SMALL}>View All</TempBrandButton></NavLink>
        </div>
        <div className={styles.fourChartsSubheadings}>{chartData.length > 0 && `${sumRevenue.startQ} - ${sumRevenue.endQ} ${percentRevenue}% of $${formatNumber(totalRevenue)} Revenue`}</div>
        <div className={styles.innerChartContainer}>
            {readyChartData && readyChartData.length && 
                <BrandVerticalBarsChart
                    isLoading={loadingChart}
                    data={readyChartData}
                    tooltipLabel='Earnings'
                    width='460px'
                    color='#3DA1D7'
                    height='360px'
                    borderColor='#393939'
                    gridColor='#393939'
                    colorOne='#fbe300'
                    colorTwo='#d5008e'
                    barPercentage='0.7'
                    longLabels={useLongLabels}
                    dataType={dataType}
                />
            }
        </div>
    </div>
    )
}