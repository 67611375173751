import React from 'react';
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { deleteData, getData } from "../utils/FetchUtils";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import { TempBrandButton } from "../CoreComponents/BrandButton";
import SongsRegistration from "../Portfolio/SongsDialog/SongsRegistration/SongsRegistration";
import { useStoreContext } from "../../store/Store";
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import { BrandDeleteModal, BrandModal } from "../CoreComponents/BrandModal";
import UploadShares from "../Portfolio/SongsDialog/SongsRegistration/UploadShares";
import { userAccessByType } from "../utils/AccessUtils";
import { Link } from "react-router-dom";
import { BrandTooltip } from '../CoreComponents/BrandTooltip';
import { ExactWordTooltipMessage } from '../../constants/messageConstants';


const useStyles = makeStyles((theme) => ({
    manageSharesContainer: {
        '& .MuiDialog-root ': {
            width: '600px'
        },
        font: 'normal normal normal 14px/21px Roboto'

    },
    searchContainer: {
        display: 'flex',
        alignItems: 'baseline',
        gap: '35px'
    },
    autocompletes: {
        display: 'flex',
        gap: '60px'
    },
    header: {
        font: 'normal normal bold 25px Roboto'
    },
    accountHoldersAutoComplete: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: `1px  ${theme.palette.primary.border}`,
            }
        },
        '& .MuiInputBase-root': {
            height: theme.spacing(5),
            border: '1px solid grey',
            borderRadius: theme.spacing(3),
            height: '40px',
            width: '220px'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.border,
            border: 'none'
        },
        '& .MuiFormLabel-root': {
            top: '-6px'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'white'
        },
        '& .MuiInputBase-input': {
            position: 'relative',
            bottom: theme.spacing(1),
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: 'white'
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: 'white',
            "& span": {
                "& svg": {
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')" // your svg icon path here
                    }
                }
            }
        },

    },
    accountHolderSongsAutoComplete: {
        '& .Mui-disabled': {
            color: 'grey'
        }
    },
    songsActionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        alignItems: 'baseline'
    },
    epxortAndNotesContainer: {
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        height: '10px'
    },
    iconText: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    },
    backButton: {
        justifyContent: 'end',
        cursor: 'pointer',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'end',
        gap: '20px',
        marginTop: '10px'
    },
    text: {
        display: 'flex',
        color: theme.palette.primary.attention,
        marginTop: '48px',
        alignItems: 'center',
        gap: '10px'
    },
    applyBtn: {
        '& .Mui-disabled': {
            backgroundColor: theme.palette.primary.lightGray
        },
        '& .MuiButton-root': {
            height: '40px',
            textTransform: 'capitalize',
            width: '155px'
        },
        '& .MuiButton-label': {
            color: `${theme.palette.background.default} !important`
        },
    },
    deleteBtn: {

        '& .MuiButton-root': {
            height: '40px',
            textTransform: 'capitalize',
            width: '155px',
            backgroundColor: theme.palette.primary.alert
        },
        '& .Mui-disabled': {
            backgroundColor: theme.palette.primary.lightGray
        },
        '& .MuiButton-label': {
            color: `${theme.palette.background.default} !important`,
        },
    },
    uploadBtn: {
        '& .Mui-disabled': {
            backgroundColor: theme.palette.primary.lightGray
        },
        '& .MuiButton-root': {
            height: '40px',
            textTransform: 'capitalize',
            width: '155px'
        },
        '& .MuiButton-label': {
            color: `${theme.palette.background.default} !important`
        },
    },
    uploadContainer: {
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a:hover': {
            textDecoration: 'none',
            color: 'inherit',
        }
    },
    autocompleteContainers: {
        display: 'flex',
        alignItems: 'center',
        width: '345px',
        gap: '10px'
    },
    label: {
        width: '105px'
    },
    applyLabel: {
        display: 'flex',
        width: '95px'
    },
    alert: {
        color: theme.palette.primary.alert
    }
}));

export default function ManageShares({ }) {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [accHolders, setAccHolders] = useState([]);
    const [accHolderSongs, setAccHolderSongs] = useState([]);
    const [accountHolderValue, setAccountHolderValue] = useState({});
    const [selectedSong, setSelectedSong] = useState({});
    const [isEmptyData, setIsEmptyData] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [isApplyClicked, setIsApplyClicked] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [alert, setAlert] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [value, setValue] = useState(null); //for selected song option
    const [search, setSearch] = useState(''); // for song input field filter

    const isAdmin = state.user.userType !== userAccessByType.USER_TYPE_USER_ACCESS;

    const fetchAccountHolders = () => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/get-account-holders`)
            .then(data => {
                setAlert('')
                setAccHolders(data);
            })
            .catch(err => {
                console.error(err.message);
                setAlert(err.message);
            });
    };

    const fetchAccountHolderSongs = () => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/song/get-account-holder-songs?accountHolderId=${accountHolderValue.id}`)
            .then(data => {
                setAlert('')
                setAccHolderSongs(data);
            })
            .catch(err => {
                console.error(err.message);
                setAlert(err.message);
            });
    };

    useEffect(() => {
        fetchAccountHolders();
    }, [])

    useEffect(() => {
        if (!!accountHolderValue) fetchAccountHolderSongs()
    }, [accountHolderValue])

    const handleDeleteAllModal = () => {
        setShowDeleteModal(true)
    }

    const handleUploadModal = () => {
        setShowUploadModal(true)
    }

    const handleDeleteAllShares = () => {
        deleteData(process.env.REACT_APP_SERVER_HOST + '/api/music-shares/delete-all-music-shares-data', { id: selectedSong.id })
            .then(data => {
                setAlert('')
                setRefresh(true);
                setShowDeleteModal(false);
            })
            .catch(err => {
                console.log(err)
                setAlert(err.message)
                setRefresh(false);
            });
    };

    const handleApplyBtn = () => {
        setIsApplyClicked(true)
    }

    useEffect(() => {
        if (!!accHolderSongs.length && !!Object.entries(selectedSong).length) {
            setDisabled(false)
        }

    }, [selectedSong, accHolderSongs])

    const filterOptions = (options, { inputValue }) => {
        const normalizedInputValue = inputValue.replace(/(^{)|(}$)/g, '').toLowerCase();
        const filter = normalizedInputValue.toLowerCase();

        return options.filter(option => {
            if (inputValue.startsWith('{') && inputValue.endsWith('}')) {
                return option.title.toLowerCase() === filter;
            } else {
                return option.title.toLowerCase().includes(filter);
            }
        });
    };

    return (
        <div className={styles.manageSharesContainer}>
            <BrandModal
                open={showUploadModal}
                onClose={() => setShowUploadModal(false)}
            >
                <UploadShares
                    details={selectedSong}
                    onClose={() => setShowUploadModal(false)}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            </BrandModal>
            <BrandDeleteModal
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onDelete={() => handleDeleteAllShares()}
                maxWidth={'sm'}
            >
                <Typography
                    variant="h5"
                    style={{ paddingBottom: 20 }}
                >
                    Delete Shares
                </Typography>
                <div>
                    Are you sure you want to delete all Account Holder information for Master and Composition shares?
                </div>
                <div className={styles.text}>
                    <WarningIcon />
                    <div>
                        This action cannot be undone.
                    </div>
                </div>
            </BrandDeleteModal>

            <div>
                <Typography
                    variant="h6"
                    className={styles.header}
                >
                    Manage Shares
                </Typography>
                <div className={styles.songsActionsContainer}>
                    <div className={styles.searchContainer}>
                        <div className={styles.autocompletes}>
                            <div className={styles.autocompleteContainers}>
                                <div className={styles.label}>
                                    Account Holders
                                </div>
                                <Autocomplete
                                    className={styles.accountHoldersAutoComplete}
                                    disableClearable={true}
                                    onChange={(event, newValue) => {
                                        setAccountHolderValue(newValue);
                                        setSelectedSong({});
                                        setAccHolderSongs([]);
                                        setIsEmptyData(true);
                                        setIsApplyClicked(false);
                                        setValue(null);
                                    }}
                                    options={accHolders}
                                    getOptionLabel={(option) => option.name || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Account holder"
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.autocompleteContainers}>
                                <div className={styles.applyLabel}>
                                    Apply to Song
                                    <div className={styles.required}>*</div>
                                </div>
                                <Autocomplete
                                    className={clsx(styles.accountHolderSongsAutoComplete, styles.accountHoldersAutoComplete)}
                                    disableClearable={true}
                                    value={value}
                                    onChange={(event, newValue) => {
                                        setSelectedSong(newValue)
                                        setIsApplyClicked(false)
                                        setIsEmptyData(true)
                                        setValue(newValue)
                                        setSearch(newValue.title);
                                    }}
                                    inputValue={search}
                                    onInputChange={(event, filter) => {
                                        setSearch(filter);
                                    }}
                                    options={accHolderSongs}
                                    filterOptions={filterOptions}
                                    disabled={!(!!accountHolderValue)}
                                    getOptionSelected={(option, value) => option.title == value.title}
                                    getOptionLabel={(option) => option.title || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Song"
                                        />
                                    )}
                                />
                                <BrandTooltip title={ExactWordTooltipMessage}/>
                            </div>
                        </div>
                        <div className={styles.applyBtn}>
                            <TempBrandButton
                                onClick={handleApplyBtn}
                                disabled={disabled}
                            >
                                Apply
                            </TempBrandButton>
                        </div>
                    </div>
                    <div className={styles.uploadContainer}>
                        {/* <div className={styles.epxortAndNotesContainer}>
                            <div className={styles.iconText}>
                                <VerticalAlignBottomIcon fontSize='small' />
                                <p>Export CSV for current song</p>
                            </div>
                            <div className={styles.iconText}>
                                <TextsmsOutlinedIcon fontSize="small" />
                                <p>Notes for current song</p>
                            </div>
                                </div>*/}
                        <Link to='/portfolio'>
                            <div className={clsx(styles.iconText, styles.backButton)}>
                                <NavigateBeforeOutlinedIcon />
                                <div>
                                    BACK
                                </div>
                            </div>
                        </Link>
                        <div className={styles.buttonContainer}>
                            <div className={styles.deleteBtn}>
                                <TempBrandButton
                                    onClick={handleDeleteAllModal}
                                    disabled={isEmptyData}
                                >
                                    Delete All
                                </TempBrandButton>
                            </div>
                            <div className={styles.uploadBtn}>
                                <TempBrandButton
                                    onClick={handleUploadModal}
                                    disabled={disabled}
                                >
                                    Upload CSV
                                </TempBrandButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.alert}>
                {alert}
            </div>
            <div>
                {
                    isApplyClicked ?
                        <SongsRegistration
                            isAdmin={isAdmin}
                            details={selectedSong}
                            isInManageShares={true}
                            setIsEmptyData={setIsEmptyData}
                            isApplyClicked={isApplyClicked}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                        :
                        ""
                }
            </div>
        </div>
    )
}