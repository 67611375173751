import { useEffect } from 'react';
import { useAsync } from 'react-use';
import { proxy, wrap } from 'comlink';

export const pdfWorker = wrap(new Worker(new URL('./worker/pdf.worker.js', import.meta.url)));
pdfWorker.onProgress(proxy((info) => console.log(info)));

export const useRenderPDF = (props) => {
  const {
    value: url,
    loading,
    error,
  } = useAsync(async () => {
    return pdfWorker.renderPDFInWorker(props);
  }, [props]);
  useEffect(() => (url ? () => URL.revokeObjectURL(url) : undefined), [url]);
  return { url, loading, error };
};
