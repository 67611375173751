import React, { useState, useEffect, useMemo } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce, getData } from '../utils/FetchUtils'
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { BrandInput } from '../CoreComponents/BrandInput';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({

    root: {
        width: theme.spacing(62.5),
        "& .MuiAutocomplete-clearIndicatorDirty": {
            display: 'none'
        },
        "& .MuiAutocomplete-popupIndicator": {
            display: 'none'
        }
    }
}));


export const GetSpotifySongAutocomplete = ({ label, onSelectedSong, setAlert }) => {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (setAlert) setAlert('');
    }, [inputValue])

    useEffect(() => {
        if (value && inputValue === value.entryName) {
            setIsLoading(false);
            return;
        }

        setOpen(inputValue.length > 0);
        (async () => {
            if (!inputValue || !isLoading) {
                return;
            }
            const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/spotify/search-song?name=${inputValue}`);
            setIsLoading(false);
            setOptions(data.tracks.items.map(x => ({ ...x, entryName: `${x.artists.length > 0 ? x.artists[0].name + ' - ' : null}${x.name}` })));
        })();
    }, [inputValue, isLoading]);

    const debounceMemo = useMemo(
        () => debounce(
            (event, newInputValue) => {
                setOptions([]);
            }, (event, newInputValue) => {
                setIsLoading(newInputValue.length > 0);
                setInputValue(newInputValue);
            }, 500),
        [],
    );

    return (
        <Autocomplete
            classes={{ root: styles.root }}
            open={open}
            onClose={() => {
                setOpen(false);
                setOptions([]);
            }}
            value={value}
            onChange={(e, newValue) => {
                setValue(newValue);
                setInputValue('');

                onSelectedSong(newValue);

                setOpen(false);
                setIsLoading(false);
            }}
            filterOptions={(options, state) => options}
            onInputChange={debounceMemo}
            blurOnSelect={true}
            clearOnBlur={true}
            clearOnEscape={true}
            getOptionLabel={(option) => option?.entryName || ''}
            getOptionSelected={() => false}
            renderOption={(song, option) => {
                return (
                    <ListItem key={song.id}>
                        <ListItemAvatar>
                            <Avatar
                                alt={`Avatar of ${song.name}`}
                                src={song.album.images.length > 0 ? song.album.images[0].url : null}
                            />
                        </ListItemAvatar>
                        <ListItemText primary={song.entryName} />
                    </ListItem>
                )
            }}
            options={options}
            loading={isLoading}
            renderInput={(params) => (
                <BrandInput
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ?
                                    <CircularProgress color="inherit" size={20} />
                                    :
                                    <IconButton
                                        onClick={(e) => {
                                            setValue('');
                                            setInputValue('');
                                            onSelectedSong({});
                                        }}
                                    >
                                        <ClearIcon style={{ color: 'white' }} />
                                    </IconButton>
                                }
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}