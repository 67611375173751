import React, { useState, useEffect, useRef } from 'react';
import { getData, download, postData } from '../../utils/FetchUtils'
import makeStyles from '@material-ui/core/styles/makeStyles';
import {  TempSecondaryBrandButton } from '../../CoreComponents/BrandButton';
import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';
import { BrandLoaderDots } from '../../CoreComponents/BrandLoader';
import { BrandAlert } from '../../CoreComponents/BrandAlert';

const useSongStyles = makeStyles((theme) => ({
    downloadButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        '& .MuiButton-outlinedPrimary:hover': {
            border: '2px solid white'
        }
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        background: '#202020',
        borderTop: '1px solid black'
    },
    footerContent: {
        display: 'flex',
        alignItems: 'center'
    },
    footerVerticalHr: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        height: theme.spacing(1.625),
        display: 'inline-block',
        border: '1px solid #393939'
    },
    downloadButton: {
        textTransform: 'capitalize',
        font: ' normal normal bold 12px/15px Roboto',
        letterSpacing: '1.2px',
        height: 50,
        width: 230,
        border: '1px solid white',
    },
    shareButton: {
        color: 'black',
        textTransform: 'capitalize',
        font: 'normal normal bolder 14px/18px Roboto',
        letterSpacing: '1.4px',
        marginLeft: theme.spacing(3),
        height: 50,
        color: 'black',
        width: 230,
        '& .MuiSvgIcon-root': {
            fontSize: '35px'
        },
        '& .MuiButton-label': {
            color: theme.palette.background.default
        }
    },
    icons: {
        marginRight: '6px',
    },
    additionalIsrcsUpcsPopover: {
        maxHeight: '250px', 
        overflowY: 'auto', 
        position: 'fixed', 
        zIndex: '1', 
        backgroundColor: theme.palette.background.default,
        marginTop: ({ marginHeight }) => `-${marginHeight}px`,
        marginLeft: '50px',
        padding: '10px',
        color: theme.palette.primary.main,
    },
    additionalIsrcsUpcsNumber: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(0.5)
    },
    isrcsUpcsContainer: {
        display: 'flex',
        cursor: 'default'
    },
    isrcUpcLabel: {
        marginRight: theme.spacing(2)
    }
}));

function Footer({ 
    details, 
    setDetails, 
    selectedSongModalRefresh, 
    startQuarter, 
    endQuarter, 
    accountHolder
}) {
    const [marginHeight, setMarginHeight] = useState(0);
    const styles = useSongStyles({ marginHeight });
    const [modalLoaded, setModalLoaded] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isFetchingDiligence, setIsFetchingDiligence] = useState(false);
    const [fetchedDiligence, setFetchedDiligence] = useState(false);
    const [reportsAvailable, setReportsAvailable] = useState(true);
    const [alert, setAlert] = useState('');
    const [isrcs, setIsrcs] = useState([]);
    const [upcs, setUpcs] = useState([]);
    const [areIsrcsShown, setAreIsrcsShown] = useState(false);
    const [areUpcsShown, setAreUpcsShown] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        if((areIsrcsShown && isrcs.length > 0) || (areUpcsShown && upcs.length > 0)){
            setMarginHeight(ref.current.clientHeight)
        }
    }, [areIsrcsShown, areUpcsShown])

    const getMergedSongsIsrcsUpcs = async () => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/song/get-merged-songs-isrcs-upcs?songId=${details.id}`)
            .then(data => {
                if(data.isrcs) {
                    setIsrcs(data.isrcs.filter((item) => item !== details.isrc));
                }
                if(data.upcs){
                    setUpcs(data.upcs.filter((item) => item !== details.upc));
                }
            });
    }

    useEffect(() => {
        const getReportCount = async () => {
            const getReportCountBody = {
                songId: details.id, 
                accountHolderId: accountHolder
            };
    
            const { reportsCount } = await postData(
                process.env.REACT_APP_SERVER_HOST + '/api/report/song-report-count', 
                getReportCountBody
            );

            if (reportsCount === 0) {
                setAlert('No reports were found');
                setReportsAvailable(false);
            }
        }
        
        getReportCount();
        getMergedSongsIsrcsUpcs();
    }, []);

    function downloadFiles() {
        setIsDownloading(true);
        download(process.env.REACT_APP_SERVER_HOST + '/api/report/song-report-file-stream', { songId: details.id, accountHolderId: accountHolder }, 'zip')
            .then(data => {
                setFetchedDiligence(true);
                setIsDownloading(false);
            }, err => {
                setAlert(err.message);
                setFetchedDiligence(false);
                setIsDownloading(false);
            })
    }

    function shareDiligence() {
        setIsFetchingDiligence(true);
        postData(process.env.REACT_APP_SERVER_HOST + '/api/report/share-diligence', { songId: details.id, accountHolderId: accountHolder })
            .then(data => {
                navigator.clipboard.writeText(process.env.REACT_APP_SERVER_HOST + `/share-diligence?token=${data.token}`);
                setFetchedDiligence(true);
                setIsFetchingDiligence(false);
                setAlert('Copied link to clipboard');
            }, err => {
                setFetchedDiligence(false);
                setIsFetchingDiligence(false);
                setAlert(err.message);
            })
    }
    useEffect(() => {
        setAlert('');
        setFetchedDiligence(false);
        if (modalLoaded) {
            getData(process.env.REACT_APP_SERVER_HOST + `/api/report/portfolio?filter=${''}&startDate=${startQuarter}&endDate=${endQuarter}&offset=${0}&limit=${1}&accountHolder=${accountHolder}&songId=${details.id}`)
                .then(data => {
                    const maxChars = 100;
                    let row = data.items[0]
                    row = { ...row, writers: row.writers && row.writers.length > maxChars ? row.writers.substring(0, maxChars) + '...' : row.writers }
                    setDetails(row)
                })
        }

        setModalLoaded(true);
    }, [selectedSongModalRefresh]);

    return (
        <div className={styles.footer}>
            <div className={styles.footerContent}>
                <div 
                    className={styles.isrcsUpcsContainer}
                    onMouseEnter={() => setAreIsrcsShown(true)}
                    onMouseLeave={() => setAreIsrcsShown(false)}
                >
                    {(areIsrcsShown && isrcs.length > 0) &&
                        <div className={styles.additionalIsrcsUpcsPopover} ref={ref}>
                            {isrcs.length > 0 ? isrcs.map((item) => <div key={item}>{item}</div>) : ''}
                        </div>
                    }
                    <div className={styles.isrcUpcLabel}>
                        ISRC
                    </div>
                    {details.isrc || 'N/A'}
                    {isrcs.length > 0 ? 
                        <div className={styles.additionalIsrcsUpcsNumber}>
                            {'+' + isrcs.length}
                        </div> 
                    : ''}
                </div>
                <hr className={styles.footerVerticalHr} />
                <div 
                    className={styles.isrcsUpcsContainer}
                    onMouseEnter={() => setAreUpcsShown(true)}
                    onMouseLeave={() => setAreUpcsShown(false)}
                >
                    {(areUpcsShown && upcs.length > 0) &&
                        <div className={styles.additionalIsrcsUpcsPopover} ref={ref}>
                            {upcs.length > 0 ? upcs.map((item) => <div key={item}>{item}</div>) : ''}
                        </div>
                    }
                    <div className={styles.isrcUpcLabel}>
                        UPC
                    </div>
                    {details.upc || 'N/A'}
                    {upcs.length > 0 ? 
                        <div className={styles.additionalIsrcsUpcsNumber}>
                            {'+' + upcs.length}
                        </div> 
                    : ''}
                </div>
            </div>
            <div className={styles.footerContent}>
                <div className={styles.downloadButtonContainer}>
                    { alert ? (
                        <BrandAlert success={fetchedDiligence}>{alert}</BrandAlert> 
                    ) : null }
                    <TempSecondaryBrandButton 
                        variant="outlined" 
                        onClick={downloadFiles} 
                        disabled={isDownloading || !reportsAvailable} 
                        className={styles.downloadButton} 
                        startIcon={isDownloading ? null : <GetAppIcon />} 
                    >
                        {isDownloading ? <BrandLoaderDots /> : 'Download Reports'}
                    </TempSecondaryBrandButton>
                </div>
                 {/* Hided until client decide which logic to implement */}
                {/* <BrandButton 
                    onClick={shareDiligence} 
                    disabled={isFetchingDiligence} 
                    className={styles.shareButton} 
                    startIcon={isFetchingDiligence ? null : <ShareIcon />} 
                >
                    {isFetchingDiligence ? <BrandLoaderDots /> : 'Share Diligence'}
                </BrandButton> */}
            </div>
        </div>
    );
}

export default Footer;