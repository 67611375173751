import { 
    Typography, 
    makeStyles 
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    percentage: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    red: { color: '#F42A82' },
    green: { color: '#62E162' },
    yellow: { color: '#EDE107' }
}));

const PercentageInfo = ({ 
    percentage,
    hideCompletion,
    className
}) => {
    const styles = useStyles();

    const getColorClass = () => {
        if (percentage === 100) return styles.green;
        if (percentage >= 50) return styles.yellow;
        return styles.red;
    }

    const combinedClassNames = `${className ?? ''} ${styles.percentage} ${getColorClass()}`;
    
    return (
        <Typography className={combinedClassNames}>
            { (percentage ?? 0).toFixed(2) }% { !hideCompletion && 'complete' }
        </Typography>
    )
}

export default PercentageInfo;