import { Box, makeStyles } from "@material-ui/core";
import PercentageInput from "./PercentageInput";
import { TempBrandButton, TempSecondaryBrandButton } from "../CoreComponents/BrandButton";
import { useState, useEffect } from "react";
import { BrandAutocomplete } from "../CoreComponents/BrandAutoComplete";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import { ButtonSize } from "../../constants/buttonConstants";

const useStyles = makeStyles(() => ({
    customInputDataWrapper: {
        width: 'calc(60% + 80px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    actionsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '20px',
        alignItems: 'center'
    },
    addNewWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '30px',
        alignItems: 'flex-start',
        margin: '20px 0px',
    },
    selectAccountHolderWrapper: {
        width: '290px',
    }
}));

const AddRevenueSplitForm = ({
    selectedAccountHolder,
    setSelectedAccountHolder,
    inputPercentageData,
    setInputPercentageData,
    onSubmit,
    onCancel,
    mainAccountHolderId
}) => {
    const styles = useStyles();
    const abortableFetch = useAbortableFetch();

    const [percentageValidatorPassed, setPercentageValidatorPassed] = useState(false);
    const [accountHolderOptions, setAccountHolderOptions] = useState([]);

    useEffect(() => {
        fetchAccountHolders();
    }, []);

    const fetchAccountHolders = async () => {
        try {
            const accountHolders = await abortableFetch('GET', '/api/account-holder/my');
            if (accountHolders)
                setAccountHolderOptions(accountHolders.filter(ah => ah.id !== mainAccountHolderId));
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Box className={styles.addNewWrapper}>
            <Box className={styles.customInputDataWrapper}>
                <Box className={styles.selectAccountHolderWrapper}>
                    <BrandAutocomplete
                        placeholder={'Select Account Holder'}
                        selectedAutoCompleteOption={selectedAccountHolder}
                        onSelectedAutoCompleteOption={setSelectedAccountHolder}
                        autoCompleteOptions={accountHolderOptions}
                    />
                </Box>
                <PercentageInput
                    inputData={inputPercentageData}
                    setInputData={setInputPercentageData}
                    validatorPassed={[
                        percentageValidatorPassed, 
                        setPercentageValidatorPassed
                    ]}
                />
            </Box>
            <Box className={styles.actionsWrapper}>
                <TempSecondaryBrandButton
                    onClick={onCancel}
                    size={ButtonSize.SMALL}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton 
                    onClick={onSubmit}
                    size={ButtonSize.SMALL}
                    disabled={!(selectedAccountHolder && percentageValidatorPassed)}
                >
                    Save
                </TempBrandButton>
            </Box>
        </Box>
    )
}

export default AddRevenueSplitForm;