import { Doughnut } from "react-chartjs-2";

const { useEffect, useState } = require("react");
const { formatMoney } = require("../../utils/NumberUtils");

const BrandDoughnutChartTypeTwo = ({ isLoading, data, hasLegend, width, height,styles, ...props }) =>{
    const [chartLabels, setChartLabels] = useState([]);
    const [chartValues, setChartValues] = useState([]);
    const [colors, setColors] = useState([]);

    useEffect(() => {
        const labels = [];
        const values = [];
        const colors = [];
        for (let i = 0; i < data.length; i++) {
            labels.push(data[i].name);
            values.push(data[i].value);
            colors.push(data[i].color);
        }
        setColors(colors);
        setChartLabels(labels);
        setChartValues(values);
    }, [data])

    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                data: chartValues,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        radius: '80%',
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        return '';
                    },
                    title: function (context) {
                        let label = context[0].label;
                        if (label) {
                            label += ': ';
                        } else {
                            label = 'Earnings for track: ';
                        }
                        if (context[0].raw) {
                            label += `${formatMoney(context[0].raw)}`;
                        }
                        return label;
                    }
                },
                titleFont: {
                    size: 16
                },
                titleMarginBottom: 0
            }
        },
    };

    return (
        <div className={styles.pieChart} style={{ width: width, height: height }}>
            {isLoading ? (
                <div>Loading...</div>
                // <CircularProgress className={styles.spinner} />
            ) : (
                <>
                    <Doughnut data={chartData} options={options} className={styles.doughnut} />
                    {hasLegend ?
                        <div className={styles.bullets}>
                            {data.map((entry, i) => {
                                return (
                                    <div key={entry.name + i} className={styles.bulletItem}>
                                        <div className={styles.bullet} style={{ background: entry.color }} />
                                        <div>{entry.name === 'Others' ? 'Others - ' : entry.name}<br />${formatMoney(entry.value)}</div>
                                    </div>
                                )
                            })}
                        </div>
                        : null}
                </>
            )}
        </div>
    );
}

export default BrandDoughnutChartTypeTwo;