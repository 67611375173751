export function excelDateToJSDate(serial, slashed) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);
    if (slashed) {
        return '' + date_info.getFullYear() + '-' + (date_info.getMonth() + 1) + '-' + date_info.getDate();
    }
    return '' + date_info.getDate() + (date_info.getMonth() + 1) + date_info.getFullYear();
}

export function parseDate(date, delimiter = '-') {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    if (!year || !month || !day) {
        return ''
    }
    return `${year}${delimiter}${month < 10 ? `0${month}` : month}${delimiter}${day < 10 ? `0${day}` : day}`
}

export function getFirstDayOfQuarter(quarter, year) {
    if (!quarter || quarter < 1 || quarter > 4) return;

    const month = (quarter - 1) * 3 + 1;

    const firstDayOfQuarter = new Date(year, month - 1, 1);

    return firstDayOfQuarter;
}

export const transformPeriodToQuarterYear = (period) => {
    const quarter = 'Q' + (period % 10);
    const year = parseInt(period / 10);
    
    return quarter + ' ' + year;
}

export const isCurrentQuarter = (year, quarter) => {
    const currentDate = new Date();
    if (year < currentDate.getFullYear())
        return true;
    else if (year > currentDate.getFullYear())
        return false;
    const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3);
    return (quarter <= currentQuarter);
};

export const generateYears = (minYear, maxYear) => {
    minYear = Number(minYear.toString().substr(0, 4));
    maxYear = Number(maxYear?.toString().substr(0, 4));

    const currentYear = maxYear || (new Date()).getFullYear();
    const years = [];
    for (let i = currentYear; i >= minYear; --i) {
        years.push(i);
    }
    return years;
}

export const transformDateToQuarterPeriodInt = (date) => {
    return Number(date.getFullYear() + `${Math.ceil((date.getMonth() + 1) / 3)}`);
}

export const parseDateToISODateFormat = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}