/* eslint-disable react-hooks/exhaustive-deps */
import { AppBar, Avatar, ListItemAvatar, makeStyles, Tab, TablePagination, Tabs, Typography } from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import { useEffect, useMemo, useState } from "react";
import { BrandButton } from "../CoreComponents/BrandButton";
import { BrandInput, BrandSearchInput } from "../CoreComponents/BrandInput";
import { BrandModal } from "../CoreComponents/BrandModal";
import BrandTable from "../CoreComponents/BrandTable";
import { debounce, getData, postData } from "../utils/FetchUtils";
import { NftManageOwnership } from "./ManageNftOwnership";
import clsx from 'clsx';
import { textIsEmpty } from "../utils/ValidationUtils";
import AlbumIcon from '@material-ui/icons/Album';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { BrandLoader } from "../CoreComponents/BrandLoader";
import { BrandAlert } from "../CoreComponents/BrandAlert";
import { BrandCheckbox } from "../CoreComponents/BrandCheckbox";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { EthIcon, roundToSecondDecimal } from "../Portfolio/NftPortfolio";
import TablePaginationActions from "../CoreComponents/TablePaginationActions";

const useNftCatalogStyles = makeStyles(theme => ({
    container: {
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: 'white'
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: '7px 16px'
        },
        '& .MuiTableCell-head': {
            padding: '16px'
        }
    },
    sectionHeader: {
        fontWeight: 'bold',
        fontSize: 25
    },
    input: {
        width: theme.spacing(55.625),
    },
    uploadNftButton: {
        color: '#222222',
        width: 200,
    },
    searchBarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 30px 20px 0px'
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    nftTitle: {
        marginLeft: '50px',
        fontSize: 25,
        fontWeight: 'bold'
    },
    tabPanel: {
        paddingTop: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    bar: {
        width: '1350px',
        height: '779px',
        '& .MuiTabs-flexContainer': {
            width: '45%',
            justifyContent: 'space-evenly'
        },
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: '#1B1B1B',
        },
        '& .MuiPaper-elevation4': {
            boxShadow: 'none !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#62E162'
        },
        '& .MuiBox-root': {
            padding: 0
        },
        "& .MuiButtonBase-root": {
            opacity: 1,
            font: 'normal normal bold 16px/20px Roboto',
            letterSpacing: '1.6px'
        },
        "& .Mui-selected": {
            color: '#62E162',
        },
    },
    nftContainer: {
        marginLeft: 20,
    },
    singleNftContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    singleNftInputSection: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    singleNftInputContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    singleNftInputHeader: {
        marginLeft: 15,
        marginBottom: 5
    },
    showNftButtonContainer: {

    },
    showButton: {
        height: '42px',
        color: '#222222',
        marginLeft: 15,
        textAlign: 'center'
    },
    nftUrlInput: {
        height: '42px',
        width: theme.spacing(70),
    },
    headerDetailsSection: {
        marginLeft: 20,
        font: 'normal normal bold 16px/20px Roboto',
        letterSpacing: '1.6px',
    },
    nftDataContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        padding: theme.spacing(2),
    },
    verifyAndCoverArt: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    img: {
        width: theme.spacing(23),
        height: theme.spacing(23)
    },
    dataCols: {
        width: '100%',
        height: '184px',
        display: 'flex',
        justifyContent: 'space-around',
    },
    dataCol: {
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(38),
        justifyContent: 'space-between',
    },
    dataRow: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },
    dataRowData: {
        marginBottom: theme.spacing(1),
        font: 'normal normal bold 16px/20px Roboto',
        letterSpacing: '1.6px',
    },
    dataRowLabel: {
        color: '#A5A5A5',
        font: 'normal normal normal 12px/15px Roboto',
        letterSpacing: '1.2px',
    },
    accHolderActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
    },
    uploadModalButton: {
        color: '#222222'
    },
    tableSection: {
        marginTop: theme.spacing(2),
        '& .MuiTableContainer-root': {
            height: theme.spacing(66)
        }
    },
    tableHeaders: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    uploading: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center'
    },
    uploadAlert: {
        textAlign: 'right',
        marginTop: theme.spacing(1)
    },
    showNftAlert:{
        textAlign: 'start',
        marginLeft: '5px'
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControlLabel-root': {
            margin: 0,
            padding: 0
        },
    },
    searchAndCheckboxContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2)
    },
    circleIconContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            fontSize: '4rem',
            color: '#62E162',
            marginBottom: theme.spacing(2)
        },
        '& p': {
            margin: 0,
            fontSize: 11
        },
        '& h4': {
            margin: 0,
        },
        marginTop: theme.spacing(5)
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

const dataValidators = {
    validateNftUrl: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
}

const NftCatalog = () => {
    const styles = useNftCatalogStyles()
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [sortBy, setSortBy] = useState('id');
    const [sortType, setSortType] = useState('ASC');
    const [rowsPerPageOptions] = useState([25, 50, 100]);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [rowCounts, setRowCounts] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [displayManageOwnershipModal, setDisplayNftOwnershipModal] = useState(false);
    const [searchFilter, setFilterSearch] = useState('');
    const [displayUploadNftsModal, setDisplayUploadNftsModal] = useState(false);
    const [refreshCatalog, setRefreshCatalog] = useState({});
    const selectOwnerships = useState(false);


    const [headCells] = useState([
        {
            id: 'collection',
            label: 'Collection',
        },
        {
            id: 'title',
            label: 'Title',
        },
        {
            id: 'accountHolders',
            label: 'Account Holder(s)',
            CellRender: ({ rowData }) => {
                const [anchorEl, setAnchorEl] = useState(null);

                const handlePopoverOpen = (event) => {
                    setAnchorEl(event.currentTarget);
                };

                const handlePopoverClose = () => {
                    setAnchorEl(null);
                };

                const open = Boolean(anchorEl);

                const parsedAccountHolders = JSON.parse(rowData.accountHolders);

                if (!parsedAccountHolders || parsedAccountHolders.length === 0) {
                    return "N/A";
                } else if (parsedAccountHolders.length === 1) {
                    return parsedAccountHolders[0].name;
                }

                return (
                    <div>
                        <div
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            {`${parsedAccountHolders[0].name}; +${parsedAccountHolders.length - 1}`}
                        </div>
                        <Popover
                            id="mouse-over-popover"
                            className={styles.popover}
                            classes={{
                                paper: styles.paper,
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            {
                                parsedAccountHolders.map(x => {
                                    return (
                                        <Typography key={x.id} color='primary'>{x.name}</Typography>
                                    )
                                })
                            }
                        </Popover>
                    </div>
                );
            }
        },
        {
            id: 'releaseDate',
            label: 'Release Date',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData === null ?
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                            :
                            <> {cellData} <EthIcon header={true} /> </>
                        }
                    </div >
                );
            }
        },
        {
            id: 'revenue',
            label: 'Revenue',
            CellRender: ({ cellData }) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {cellData === null ?
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                            :
                            <> {roundToSecondDecimal(cellData)} <EthIcon header={true} /> </>
                        }
                    </div >
                );
            }
        },
        {
            id: 'assignedOwnerships',
            label: 'Assigned Ownerships',
            notSortable: true,
            CellRender: ({ cellData }) => {
                const parsedCellData = JSON.parse(cellData);
                return (
                    <>
                        <div style={{ color: parsedCellData.primaryOwnership == 100 ? 'inherit' : '#F42A82' }}>Primary - {parsedCellData.primaryOwnership}%</div>
                        <div style={{ color: parsedCellData.secondaryOwnership == 100 ? 'inherit' : '#F42A82' }}>Secondary - {parsedCellData.secondaryOwnership}%</div>
                    </>
                )
            }
        },
        {
            id: '',
            label: '',
            hideHeadLabel: true,
            CellRender: ({ rowData }) => {
                return (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setSelectedRowData(rowData);
                            setDisplayNftOwnershipModal(true);
                        }}
                    >Manage Ownership
                    </div>
                )
            }
        }
    ]);

    const fetchData = async () => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-catalog?filter=${searchFilter}&offset=${offset}&limit=${rowsPerPage}&sort=${sortBy}&type=${sortType}&showOnlyOwnershipsUnder100=${selectOwnerships[0]}`)
            .then(data => {
                setRows(data.items)
                setRowCounts(data.total);
            }, err => {
                console.log(err);
            })
    }

    useEffect(() => {
        setOffset(page * 6)
    }, [page]);

    useEffect(() => {
        setPage(0)
    }, [searchFilter, rowsPerPage]);

    useEffect(() => {
        fetchData();
    }, [offset, rowsPerPage, sortType, sortBy, searchFilter, refreshCatalog]);

    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setSearch(event.target.value);
            }, (event) => {
                if (event.target.value.includes('#')) {
                    setFilterSearch(event.target.value.replaceAll('#', '%23'))
                } else {
                    setFilterSearch(event.target.value);
                }
            }, 500),
        [],
    );

    function onClose() {
        setDisplayUploadNftsModal(false)
    }

    useEffect(() => {
        fetchData()
    }, [selectOwnerships[0]]);



    return (
        <div className={styles.container} >
            <BrandModal
                open={displayManageOwnershipModal}
                onClose={() => setDisplayNftOwnershipModal(false)}
            >
                <NftManageOwnership
                    nft={selectedRowData}
                    onSubmit={() => {
                        setDisplayNftOwnershipModal(false);
                    }}
                    onClose={() => setDisplayNftOwnershipModal(false)}
                    setRefreshCatalog={setRefreshCatalog}
                />
            </BrandModal>
            <BrandModal
                open={displayUploadNftsModal}
                onClose={onClose}
            >
                <UploadNftsModal
                    onClose={onClose}
                />
            </BrandModal>
            <Typography
                variant="h6"
                className={styles.sectionHeader}
            >
                NFT Catalog
            </Typography>
            <div className={styles.searchBarContainer}>
                <div className={styles.searchAndCheckboxContainer}>
                    <BrandSearchInput placeholder='Search for NFT' value={search} onChange={debounceMemo} classes={{ input: styles.input }} />
                    <div className={styles.checkboxLabel}>
                        <BrandCheckbox style={{ color: '#A5A5A5' }} $value={selectOwnerships} />
                        <Typography variant='subtitle2' component='div'> Show only &lt;100% Assigned Ownership</Typography>
                    </div>
                </div>
                <BrandButton className={styles.uploadNftButton} onClick={() => setDisplayUploadNftsModal(true)} >Upload NFT</BrandButton>
            </div>
            {selectOwnerships[0] ?
                rows.length ?
                    <>
                        <BrandTable
                            rows={rows}
                            headCells={headCells}
                            checkboxless={true}
                            page={page}
                            hidePagination={true}
                            setSortBy={setSortBy}
                            setSortType={setSortType}
                        />
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={rowCounts}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            onRowsPerPageChange={(event) => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                            ActionsComponent={TablePaginationActions}
                        />
                    </>
                    :
                    <>
                        <BrandTable
                            rows={rows}
                            headCells={headCells}
                            checkboxless={true}
                            page={page}
                            hidePagination={true}
                            setSortBy={setSortBy}
                            setSortType={setSortType}
                        />
                        <div className={styles.circleIconContainer}>
                            <CheckCircleIcon />
                            <h4>All Set!</h4>
                            <p>All NFT in the catalog are with 100% Assigned Ownership</p>
                        </div>
                    </>
                :
                <>
                    <BrandTable
                        rows={rows}
                        headCells={headCells}
                        checkboxless={true}
                        page={page}
                        hidePagination={true}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rowCounts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                        ActionsComponent={TablePaginationActions}
                    />
                </>
            }
        </div>
    );
}
export default NftCatalog;

const SingleNFTUpload = ({ details, setDetails }) => {
    const styles = useNftCatalogStyles();
    const nftUrl = useState('');
    const [uploadNftDisabled, setUploadNftDisabled] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [showError,setShowError] = useState('');

    const showUploadModalHandler = () => {
        if(!nftUrl[0]){
            setShowError("OpenSea link cannot be empty");
            return;
        }
        setIsShow(true);
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/opensea-link-v2?openSeaLink=${nftUrl[0]}`)
            .then(data => {
                setDetails(data.tokenInfo);
                setShowDetails(true);
                setIsShow(false);
                setShowError('');
            }).catch(err => {
                setShowError(err.message);
                setShowDetails(false);
                setIsShow(false);
            })
    }

    useEffect(() => {
        setShowError('');
        setUploadNftDisabled(dataValidators.validateNftUrl(nftUrl[0]).length !== 0)
    }, [nftUrl[0]])

    return (
        <div>
            <div className={styles.singleNftInputSection}>
                <div className={styles.singleNftInputContainer}>
                    <div className={styles.singleNftInputHeader}>Open Sea NFT URL</div>
                    <BrandInput
                        classes={{ input: styles.nftUrlInput }}
                        placeholder='https://opensea.io/assets/{ chain }/{ Contract Address }/{ Token ID }'
                        $value={nftUrl}
                        required
                        validator={dataValidators.validateNftUrl}
                    />
                </div>
                <div className={styles.showNftButtonContainer}>
                    <BrandButton
                        className={styles.showButton}
                        onClick={showUploadModalHandler}
                    >
                        {isShow ?
                            <div className={styles.uploading}>
                                <BrandLoader color='white' width={20} height={20} />
                                <div>SHOW NFT</div>
                            </div>
                            :
                            'SHOW NFT'
                        }
                    </BrandButton>
                </div>
            </div>

            {showError ? <BrandAlert className={clsx(styles.uploadAlert,styles.showNftAlert)} warning={showError}>{showError}</BrandAlert> : ''}
            {showDetails ?
                <div className={styles.nftDataContainer}>
                    <div className={styles.verifyAndCoverArt}>
                        <div className={styles.headerDetailsSection} >NFT Preview</div>
                        {details?.mediaUrl ?
                            <img src={details.mediaUrl} alt={''} className={styles.img} />
                            :
                            <AlbumIcon className={styles.img} />
                        }
                    </div>
                    <div className={styles.dataCols}>
                        <div className={styles.dataCol}>
                            <div className={styles.dataRow}>
                                <div className={styles.dataRowData}>{details.title}</div>
                                <div className={styles.dataRowLabel}>Title</div>
                            </div>
                            <div className={styles.dataRow}>
                                <div className={styles.dataRowData}>{details.creatorName || 'N/A'}</div>
                                <div className={styles.dataRowLabel}>Creator</div>
                            </div>
                            <div className={styles.dataRow}>
                                <div className={styles.dataRowData}>{details.mintTimestamp ? details.mintTimestamp.split("T")[0] : 'N/A'}</div>
                                <div className={styles.dataRowLabel}>Released</div>
                            </div>
                        </div>
                        <div className={styles.dataCol}>
                            <div className={styles.dataRow}>
                                <div className={styles.dataRowData}>{details.tokenId.length > 27 ? details.tokenId.slice(0, 27) + '...' : details.tokenId}</div>
                                <div className={styles.dataRowLabel}>Token ID</div>
                            </div>
                            <div className={styles.dataRow}>
                                <div className={styles.dataRowData}>{details.contractAddress.slice(0, 11) + '...' + details.contractAddress.slice(-16)}</div>
                                <div className={styles.dataRowLabel}>Contract Address</div>
                            </div>
                            <div className={styles.dataRow}>
                                <div className={styles.dataRowData}>{details.chain}</div>
                                <div className={styles.dataRowLabel}>Blockchain</div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={styles.nftDataContainer}></div>
            }
        </div>
    );
}

const NftCollectionUpload = ({ selectedRows, setSelectedRows, rows, setRows }) => {
    const styles = useNftCatalogStyles();
    const nftUrl = useState('');
    const [uploadNftDisabled, setUploadNftDisabled] = useState(false);
    const [showTableNfts, setShowTableNfts] = useState(false);
    const [headCells] = useState([
        {
            id: 'title',
            label: 'Title',
            CellRender: ({ rowData }) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemAvatar>
                            {rowData.mediaUrl ?
                                <Avatar
                                    alt={`Avatar of ${rowData.mediaUrl}`}
                                    src={rowData.mediaUrl}
                                />
                                :
                                <AlbumIcon fontSize='large' />
                            }
                        </ListItemAvatar>
                        {rowData.title}
                    </div>
                );
            },
        },
        {
            id: 'creatorName',
            label: 'Creator',
        },
        {
            id: 'mintTimestamp',
            label: 'Release Date',
            CellRender: ({ rowData }) => {
                const releaseDate = new Date(rowData.releaseDate)
                return (
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} >
                        {releaseDate.getTime() ?
                            `${releaseDate.getFullYear()}-${releaseDate.getMonth() < 9 ? '0' : ''}${releaseDate.getMonth() + 1}-${releaseDate.getDate() < 10 ? '0' : ''}${releaseDate.getDate()}`
                            :
                            <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
                        }
                    </div>
                );
            },
        },
        {
            id: 'tokenId',
            label: 'Token ID',
            CellRender: ({ rowData }) => {
                const tokenId = rowData?.tokenId?.length > 22 ? rowData.tokenId.slice(0, 22) + '...' : rowData.tokenId
                return (
                    <div >
                        {tokenId}
                    </div>
                );
            },
        },
        {
            id: 'contractAddress',
            label: 'Contract Address',
            CellRender: ({ rowData }) => {
                const contractAddress = rowData.contractAddress.slice(0, 8) + '...' + rowData.contractAddress.slice(-8)
                return (
                    <div >
                        {contractAddress}
                    </div>
                );
            },
        },
        {
            id: 'chain',
            label: 'Block Chain',
        },
    ]);
    const [page, setPage] = useState(0);
    const [rowsPerPageOptions] = useState([6, 12, 18]);
    const [offset, setOffset] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [initialLod, setInitialLod] = useState(true);
    const [isShow, setIsShow] = useState(false);
    const [showError,setShowError] = useState('');

    const showUploadModalHandler = () => {
        fetchData();
    }

    useEffect(() => {
        setUploadNftDisabled(dataValidators.validateNftUrl(nftUrl[0]).length !== 0)
        setShowError('');
    }, [nftUrl[0]])

    useEffect(() => {
        setOffset(page * rowsPerPage)
    }, [page])

    useEffect(() => {
        setPage(0);
    }, [rowsPerPage])

    useEffect(() => {
        if (initialLod) {
            setInitialLod(false);
            return;
        }
    }, [offset, rowsPerPage])

    async function fetchData() {
        if(!nftUrl[0]){
            setShowError("OpenSea link cannot be empty");
            return;
        }
        const parsedNftUrl = nftUrl[0].split('https://opensea.io/collection/')[1];
        setIsShow(true);

        // postData(process.env.REACT_APP_HOST + `/api/nft/opensea-collection-tokens`, { collectionName: nftUrl[0] })
        postData(process.env.REACT_APP_SERVER_HOST + `/api/nft/opensea-collection-tokens`, { collectionName: parsedNftUrl })
            .then(data => {
                setRows(data || []);
                setShowTableNfts(true);
                setIsShow(false);
                setShowError('');
            }, err => {
                setShowError(err.message);
                setShowTableNfts(false);
                setIsShow(false);
            })
    }

    return (
        <div className={styles.nftContainer}>
            <div className={styles.singleNftInputSection}>
                <div className={styles.singleNftInputContainer}>
                    <div className={styles.singleNftInputHeader}>Open Sea NFT Collection URL</div>
                    <BrandInput
                        classes={{ input: styles.nftUrlInput }}
                        placeholder='https://opensea.io/collection/{collectionName}'
                        $value={nftUrl}
                        required
                        validator={dataValidators.validateNftUrl}
                    />
                </div>
                <div className={styles.showNftButtonContainer}>
                    <BrandButton
                        className={styles.showButton}
                        onClick={showUploadModalHandler}
                    >
                        {isShow ?
                            <div className={styles.uploading}>
                                <BrandLoader color='white' width={20} height={20} />
                                <div>SHOW COLLECTION</div>
                            </div>
                            :
                            'SHOW COLLECTION'
                        }
                    </BrandButton>
                </div>
            </div>
            {showError ? <BrandAlert className={clsx(styles.uploadAlert,styles.showNftAlert)} warning={showError}>{showError}</BrandAlert> : ''}
            {showTableNfts ?
                <div className={styles.tableSection}>
                    <BrandTable
                        headCells={headCells}
                        rows={rows}
                        page={page}
                        setPage={setPage}
                        setSelectedRows={setSelectedRows}
                        rowsPerPageOptions={rowsPerPageOptions}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </div>
                :
                <div className={styles.nftDataContainer}></div>
            }
        </div>
    );
}

function setTabProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

function UploadNftsModal({ onClose }) {
    const styles = useNftCatalogStyles();
    const [value, setValue] = useState(0);
    const [singleNftDetails, setSingleNftDetails] = useState({});
    const [nftCollection, setNftCollection] = useState([]);
    const [selectedNfts, setSelectedNfts] = useState({});
    const [isUploading, setIsUploading] = useState(false);
    const [finishedUploadingAlert, setFinishedUploading] = useState('');
    const [uploadedSuccessfully, setUploadedSuccessfully] = useState(false);
    const [uploadNftDisabled, setUploadNftDisabled] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function singleNftUpload() {
        setIsUploading(true);
        setUploadNftDisabled(true);

        postData(process.env.REACT_APP_SERVER_HOST + `/api/nft/opensea-link`, { nftInfo: singleNftDetails })
            .then(data => {
                setIsUploading(false)
                setUploadedSuccessfully(true);
                setFinishedUploading('Uploaded Successfully');
                setUploadNftDisabled(false);
            }, err => {
                console.log(err);
                setIsUploading(false);
                setUploadedSuccessfully(false);
                setFinishedUploading('Failed to upload')
                setUploadNftDisabled(false);
            })
    }

    function nftCollectionUpload() {
        setIsUploading(true);
        setUploadNftDisabled(true);
        const selectedNftsToUpload = Object.values(selectedNfts);


        postData(process.env.REACT_APP_SERVER_HOST + `/api/nft/opensea-collection-tokens-upload`, { tokens: selectedNftsToUpload })
            .then(data => {
                setIsUploading(false);
                setUploadedSuccessfully(true);
                setFinishedUploading('Uploaded Successfully');
                setUploadNftDisabled(false);
            }, err => {
                console.log(err);
                setIsUploading(false);
                setUploadedSuccessfully(false);
                setFinishedUploading('Failed to upload')
                setUploadNftDisabled(false);
            })
    }

    useEffect(() => {
        if (finishedUploadingAlert) {
            setTimeout(() => {
                setFinishedUploading('');
            }, "4000")
        }
    }, [finishedUploadingAlert])

    return (
        <>
            <div className={styles.bar}>
                <Typography variant='h5' className={clsx(styles.title, styles.nftTitle)} component='div'>Upload NFT</Typography>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Single NFT Upload" {...setTabProps(0)} />
                        <Tab label="NFT Collection upload" {...setTabProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0} className={styles.tabPanel}>
                    <SingleNFTUpload
                        details={singleNftDetails}
                        setDetails={setSingleNftDetails}
                    />
                </TabPanel>
                <TabPanel value={value} index={1} className={styles.tabPanel}>
                    <NftCollectionUpload
                        rows={nftCollection}
                        setRows={setNftCollection}
                        selectedRows={selectedNfts}
                        setSelectedRows={setSelectedNfts}
                    />
                </TabPanel>
            </div>
            <div className={styles.accHolderActions}>
                <BrandButton variant='outlined' onClick={onClose} >
                    Cancel
                </BrandButton>
                <BrandButton
                    disabled={uploadNftDisabled}
                    onClick={value === 0 ? singleNftUpload : value === 1 ? nftCollectionUpload : null}
                    className={styles.uploadModalButton}
                >
                    {isUploading ?
                        <div className={styles.uploading}>
                            <BrandLoader color='white' width={20} height={20} />
                            <div>Uploading</div>
                        </div>
                        :
                        'Upload'
                    }
                </BrandButton>
            </div>
            {finishedUploadingAlert ? <BrandAlert className={styles.uploadAlert} success={uploadedSuccessfully}>{finishedUploadingAlert}</BrandAlert> : null}
        </>
    );
}

