import { makeStyles } from "@material-ui/core"
import footerLogo from '../../assets/Record_White_Black_Logos.svg'
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    footerNav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        width: '95%',
        zIndex: 1,
        width: '100%',
        padding: '0px 20px 0px 20px',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a:hover': {
            color: theme.palette.primary.main
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
            gap: '20px',
            padding: '10px 0px 15px 0px',
        }
    },
    policiesAndProcedures: {
        display: 'flex',
        flexDirection: 'row',
        gap: '30px',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            gap: '30px',
            alignItems: 'start',
        }
    },
    socialMedias: {
        display: 'flex',
        flexDirection: 'row',
        gap: '30px',
    },
    footerLogo: {
        marginTop: '6px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))
export default function Footer() {
    const styles = useStyles();
    return (
        <div className={styles.footerNav}>
            <div className={styles.policiesAndProcedures}>
                <div className={styles.footerLogo}>
                    <Link to="/home-page"><img src={footerLogo} width={65} alt="recordLogo" /></Link>

                </div>
                <div className={styles.termsOfUse}> <Link to="/therms-of-use">terms of use</Link></div>
                <div className={styles.privicyPolicy}><Link to="/privacy-policy">privacy policy</Link></div>
                {/* <div className={styles.accessbility}><Link to="accessibility">Accessibility</Link></div>
                <div className={styles.cookies}><Link to="/cookies">Cookies</Link></div> */}
            </div>
            <div className={styles.socialMedias}>
                <div className={styles.facebook}><a href='https://www.facebook.com/RecordFinancialInc' target="_blank">fb</a></div>
                <div className={styles.instagram}><a href='https://www.instagram.com/takerecord' target="_blank">ig</a></div>
                <div className={styles.twitter}><a href='https://twitter.com/recordfinancial' target="_blank">tw</a></div>
                {/* <div className={styles.discord}><a href='https://discord.com/' target="_blank">dc</a></div>
                <div className={styles.tiktok}><a href='https://www.tiktok.com/' target="_blank">tt</a></div>
                <div className={styles.youtube}><a href='https://www.youtube.com/' target="_blank">yt</a></div> */}
            </div>
        </div>
    )
}