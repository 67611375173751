import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Typography, makeStyles } from '@material-ui/core'
import { BrandDateRange } from "../../CoreComponents/BrandDatePicker";
import AccountHolderAutocomplete from '../../Earnings/AccountHolderAutocomplete';
import RoyaltyStatementsDataTable from './RoyaltyStatementsDataTable';
import { TempBrandButton } from '../../CoreComponents/BrandButton';
import { BrandMenuItem, BrandSelect } from "../../CoreComponents/BrandSelect";
import royaltyStatementStatus from '../../../constants/royalty-statement-status';
import { userAccessByType } from '../../utils/AccessUtils';
import { useStoreContext } from '../../../store/Store';

const useStyles = makeStyles((theme) => ({
    selectGroup: {
        width: '100%',
    },
    defaultSelect: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        color: theme.palette.text.lightGray,
        gap: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    datePickerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px'
    },
    filtersWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        alignItems: 'center',
        color: theme.palette.text.lightGray,
        marginBottom: theme.spacing(4),
        '& .MuiButton-root': {
            textTransform: 'none'
        },
        '& .MuiInputBase-root': {
            background: 'none',
            border: `1px solid ${theme.palette.primary.darkGrayNuance}`,
            minWidth: '215px',
        }
    },
    dateAlert: {
        color: theme.palette.primary.alert
    },
    beforeSelecting: {
        marginRight: '520px'
    },
    generateButton: {
        marginLeft: 'auto'
    },
    issuedStatus: {
        color: theme.palette.primary.main,
        '& .MuiSelect-select': {
            color: theme.palette.primary.main
        }
    },
    unissuedStatus: {
        color: theme.palette.primary.attention,
        '& .MuiSelect-select': {
            color: theme.palette.primary.attention
        }
    },
    archivedStatus: {
        color: theme.palette.text.lightGray,
        '& .MuiSelect-select': {
            color: theme.palette.text.lightGray
        }
    },
    invalidatedPaidStatus: {
        color: theme.palette.primary.delete,
        '& .MuiSelect-select': {
            color: theme.palette.primary.delete
        }
    },
}))

const RoyaltyStatements = () => {
    const styles = useStyles();
    const history = useHistory();
    const [state] = useStoreContext();

    const [statusesProps] = useState({
        [royaltyStatementStatus.Pending]: { name: 'Unissued', style: styles.unissuedStatus },
        [royaltyStatementStatus.Published]: { name: 'Issued', style: styles.issuedStatus },
        [royaltyStatementStatus.Archived]: { name: 'Archived', style: styles.archivedStatus },
        [royaltyStatementStatus.Invalidated]: { name: 'Invalidated', style: styles.invalidatedPaidStatus }
    });

    const [selectedArtistAccountHolders, setSelectedArtistAccountHolders] = useState([]);
    const [selectedLabelAccountHolders, setSelectedLabelAccountHolders] = useState([]);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [selectedStatus, setSelectedStatus] = useState(0);

    const handleGenerateRoyaltyStatement = () => {
        history.push('/generate-royalty-statement');
    }

    return (
        <div className={styles.selectGroup}>
            <div className={styles.filtersWrapper}>
                <Typography>Artist's Account holders(s)</Typography>
                <AccountHolderAutocomplete
                    selectedOptions={selectedArtistAccountHolders}
                    setSelectedOptions={setSelectedArtistAccountHolders}
                />
                <Typography>Label's Account holders(s)</Typography>
                <AccountHolderAutocomplete
                    selectedOptions={selectedLabelAccountHolders}
                    setSelectedOptions={setSelectedLabelAccountHolders}
                />
                {state.user.userType === userAccessByType.USER_TYPE_USER_ACCESS &&
                    <>
                        <Typography>Date Range</Typography>
                        <BrandDateRange
                            startDate={startDate}
                            setStartDate={(newValue) => setStartDate(newValue)}
                            endDate={endDate}
                            setEndDate={(newValue) => setEndDate(newValue)}
                        />
                    </>
                }
            </div>

            {state.user.userType === userAccessByType.USER_TYPE_ADMIN_ACCESS &&
                <div className={styles.filtersWrapper}>
                    <div className={styles.datePickerContainer}>
                        <Typography>Date Range</Typography>
                        <BrandDateRange
                            startDate={startDate}
                            setStartDate={(newValue) => setStartDate(newValue)}
                            endDate={endDate}
                            setEndDate={(newValue) => setEndDate(newValue)}
                        />
                        <Typography>Filter by Status</Typography>
                        <div className={statusesProps[selectedStatus]?.style}>
                            <BrandSelect
                                value={selectedStatus}
                                onChange={(value) => setSelectedStatus(value)}
                            >
                                <BrandMenuItem value={0} key={0}>All</BrandMenuItem>
                                {Object.keys(statusesProps).map(id => (
                                    <BrandMenuItem key={id} value={id} className={statusesProps[id].style}>
                                        {statusesProps[id].name}
                                    </BrandMenuItem>
                                ))}
                            </BrandSelect>
                        </div>
                    </div>
                    <TempBrandButton className={styles.generateButton} onClick={handleGenerateRoyaltyStatement}>
                        Generate Royalty Statement
                    </TempBrandButton>
                </div>
            }

            <RoyaltyStatementsDataTable
                selectedArtistAccountHolders={selectedArtistAccountHolders}
                selectedLabelAccountHolders={selectedLabelAccountHolders}
                startDate={startDate}
                endDate={endDate}
                selectedStatus={selectedStatus}
                statusesProps={statusesProps}
            />
        </div>
    )
}

export default RoyaltyStatements;