import { useState, useEffect } from 'react';
import { Route } from 'react-router';
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { useStoreContext, initialStoreState } from '../../store/Store';
import { postData } from '../utils/FetchUtils';
import { Redirect } from 'react-router';

const ErrorBoundaryRoute = ({ component: Component,path, ...rest }) => {
    const [state, setState] = useStoreContext();

    const [userIsCorrect, setUserIsCorrent] = useState(true);
    
    const checkCurrentUser = async () => {
        const data = await postData(process.env.REACT_APP_SERVER_HOST + '/api/user/checkUser', { user: state.user });
    
        setUserIsCorrent(data.userIsCorrect);
    }

    useEffect(() => {
        if (rest.checkUser) checkCurrentUser();
    }, [path]);

    if (!userIsCorrect) {
        setState(initialStoreState);
        return <Redirect from="*" to="/login" />;
    }

    return (
        <Route
            exact={rest.exact}
            path={path}
            render={(props) => (
                <ErrorBoundary>
                    <Component />
                </ErrorBoundary>
            )}
        />
  )};

export default ErrorBoundaryRoute;