import { Box, Typography, makeStyles } from "@material-ui/core";
import { BrandModal } from "../CoreComponents/BrandModal";
import { useState } from "react";
import { TempBrandButton, TempSecondaryBrandButton } from "../CoreComponents/BrandButton";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import { BrandLink } from "../CoreComponents/BrandLink";
import { Warning } from "@material-ui/icons";
import { ButtonSize } from "../../constants/buttonConstants";

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        width: '625px'
    },
    modalHeader: {
        fontWeight: 'bold',
        marginBottom: '5px'
    },
    unmergeWarningMessage: {
        color: 'yellow',
        fontSize: '13px',
        marginRight: '20px'
    },
    warningMessagesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px'
    },
    checkboxesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px'
    },
    formControlLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '35px'
    },
    bottomUnmergeWarningsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '15px',
        marginTop: '25px'
    },
    warningIcon: {
        color: 'yellow'
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        columnGap: '20px',
        marginTop: '50px',
        '& button': {
            width: '153px',
        }
    },
    singleButtonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    successMessageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '150px',
        width: '625px',
        flexDirection: 'column'
    },
    successMessage: {
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    disabled: {
        '&.Mui-disabled': {
            color: theme.palette.primary.lightGray
        }
    }
}));

const UnmergeSongModal = ({ 
    song,
    open,
    onClose,
    refreshData
}) => {
    const [successfulMergeMessage, setSuccessfulMergeMessage] = useState(false);

    const abortableFetch = useAbortableFetch();

    const styles = useStyles();

    const handleUnmergeSong = async () => {
        try {
            await abortableFetch('PUT', '/api/merge-history/unmerge-song', {
                query: { id: song.id }
            });

            setSuccessfulMergeMessage(true);
            refreshData();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <BrandModal open={open} onClose={onClose}>
            { successfulMergeMessage ? (
                <Box className={styles.mainWrapper}>
                    <Box className={styles.successMessageWrapper}>
                        <Typography className={styles.successMessage}>
                            Successfully unmerged "{song.displayName}". 
                            <br/> 
                            It is available in the <BrandLink to='/songs'>Songs page</BrandLink>.
                        </Typography>
                    </Box>
                    <Box className={styles.singleButtonWrapper}>
                        <TempBrandButton
                            onClick={onClose}
                        >
                            OK
                        </TempBrandButton>
                    </Box>
                </Box>
            ) : (
                <Box className={styles.mainWrapper}>
                    <Typography variant='h6' className={styles.modalHeader}>
                        Unmerge "{song.displayName}"
                    </Typography>
                    <Box className={styles.warningMessagesWrapper}>
                        <Typography variant='body2'>
                            Are you sure you want to unmerge "{song.displayName}" by {song.artist} ?
                        </Typography>
                        <Box className={styles.bottomUnmergeWarningsWrapper}>
                            <Warning fontSize="medium" className={styles.warningIcon} />
                            <Typography variant='body2' className={styles.unmergeWarningMessage}>
                                Unmerging "{song.displayName}" will result in creating it as an individual song.
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={styles.buttonsWrapper}>
                        <TempSecondaryBrandButton
                            size={ButtonSize.SMALL}
                            variant='outlined'
                            onClick={onClose}
                        >
                            Cancel
                        </TempSecondaryBrandButton>
                        <TempBrandButton
                            size={ButtonSize.SMALL}
                            onClick={handleUnmergeSong}
                        >
                            Unmerge    
                        </TempBrandButton>
                    </Box>
                </Box>
            ) }
        </BrandModal>
    )
}

export default UnmergeSongModal;