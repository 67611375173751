import React, { useEffect, useState } from 'react';
import { IconButton, MenuList, Popover } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/core/styles';
import { BrandCheckbox } from '../CoreComponents/BrandCheckbox';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        border: `1px solid ${theme.palette.primary.lightGray}`,
        color: theme.palette.primary.lightGray,
        height: 40,
        width: 40,
        marginLeft: theme.spacing(2)
    },
    popOver: {
        "& .MuiPaper-root": {
            paddingLeft: 15,
            paddingRight: 15
        }
    },
}));

const SongsListFilter = ({
    songsFilter
}) => {
    const styles = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton className={styles.iconButton} onClick={handleOpen} disableTouchRipple>
                <TuneIcon />
            </IconButton>
            <Popover
                className={styles.popOver}
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuList>
                    <BrandCheckbox $value={songsFilter.unmatchedOption} label='Show unmatched' />
                    <br />
                    <BrandCheckbox $value={songsFilter.matchedWithSuggestionsOption} label='Show 50% Matching' />
                    <br />
                    <BrandCheckbox $value={songsFilter.partialMatchOption} label='Show >50% Matching' />
                    <br />
                    <BrandCheckbox $value={songsFilter.manuallyMatchedOption} label='Show manually matched' />
                </MenuList>
            </Popover>
        </>
    );

}

export default SongsListFilter