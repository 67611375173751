import { useRef, useState } from "react";
import { getData } from "../../utils/FetchUtils";
import PopoverAutocomplete from "./PopoverAutocomplete";

const SongsAutocomplete = ({
    selectedOptions,
    setSelectedOptions,
    selectedAccountHolders
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [textContent,setTextContent] = useState('Select songs');
    const [placeholder,setPlaceholder] = useState('Search songs');
    const [allSelectedText,setAllSelectedText] = useState('All songs selected');
    const [isLoading,setIsLoading] = useState(true);

    const offset = useRef(0);

    const callback = async () => {
        const searchIDsString = selectedAccountHolders.map((x) => x.id);
        const query = `/api/earnings/songs?offset=${offset.current}&limit=10&filter=${searchQuery}&accountHolderIDs=${searchIDsString}`;
        const { items } = await getData(process.env.REACT_APP_SERVER_HOST + query);
        
        setIsLoading(false);
        setOptions((options) => options.concat(...items));
        offset.current += 10;
    };

    return (
        <PopoverAutocomplete
            showSearchIcon={true}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            options={options}
            setOptions={setOptions}
            offset={offset}
            callback={callback}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            textContent={textContent}
            placeholder={placeholder}
            allSelectedText={allSelectedText}
            isLoading={isLoading}
        />
    )
};

export default SongsAutocomplete;