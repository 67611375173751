import {
    Box,
    Checkbox,
    makeStyles
} from "@material-ui/core";
import CustomAccordion from "./CustomAccordion";

const useStyles = makeStyles(() => ({
    optionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '10px',
        width: '100%',
        alignItems: 'flex-start'
    },
    checkbox: {
        marginTop: '15px'
    },
    optionsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        width: '100%',
        height: '550px',
        overflowY: 'scroll'
    }
}));

const MultipleOptions = ({
    data,
    selectedItems,
    setSelectedItems,
    setSelectAllChecked,
    handleShowManageModal,
    accountHolder
}) => {
    const styles = useStyles();

    const handleSelectItem = (event, revenueSplit) => {
        const { checked } = event.target;

        if (checked) {
            setSelectedItems((prev) => prev.concat(revenueSplit));
        } else {
            setSelectedItems((prev) => prev.filter((item) => item.id !== revenueSplit.id));
        }

        setSelectAllChecked(false);
    }

    const handleEdit = (revenueSplit) => {

        setSelectedItems([revenueSplit]);
        setSelectAllChecked(false);

        handleShowManageModal();
    };

    return (
        <Box className={styles.optionsWrapper}>
            {data && data.map((revenueSplit) => {
                const checked = selectedItems.map((r) => r.id).includes(revenueSplit.id);

                return (
                    <Box className={styles.optionWrapper} key={revenueSplit.id}>
                        <Checkbox
                            color='primary'
                            className={styles.checkbox}
                            checked={checked}
                            onChange={(event) => handleSelectItem(event, revenueSplit)}
                        />
                        <CustomAccordion data={revenueSplit} accountHolderName={accountHolder.name} handleEdit={() => handleEdit(revenueSplit)} />
                    </Box>
                );
            })}
        </Box>
    )
}

export default MultipleOptions;