import React from 'react';

export const ExactWordTooltipMessage = (
    <div>
        To search only for a specific letter or word use {'{}'}.
        <br /><br />
        Example: {'{X}'} will return only X and not words containing 'X'.
    </div>
);

export const DisabledMergeToolTooltipMessage = (
    <div>
        Merge tool cannot be used for merged songs.
    </div>
)

export const DisabledQuarterMessage = (
    <div>
        You cannot select a quarter that has not occurred yet.
    </div>
)