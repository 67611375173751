import { Snackbar, Typography } from "@material-ui/core"
import BrandTable from "../../CoreComponents/BrandTable"
import { BrandButton } from "../../CoreComponents/BrandButton"
import { useCallback, useEffect, useRef, useState } from "react";
import { getData } from "../../utils/FetchUtils";
import { useInfiniteScrolling } from "../../../hooks/useInfiniteScrolling";
import { useDownloadCSV } from "../../../hooks/useDownloadCSV";
import Alert from "@material-ui/lab/Alert";
import { PDFDownloadLink,Document,Page } from "@react-pdf/renderer";
import TopOwnersPDF from "./renderPDF/TopOwnersPDF";
import { saveAs } from 'file-saver';
import { useRenderPDF } from "./renderPDF/useRenderPDF";
import { RenderedPDFTopOwners, RenderedPDFViewer } from "./renderPDF/RenderedPDFTopOwners.js";
import {stylesPDF} from '../../../assets/css/stylesPDF.js'


const TopOwnersTable =({styles,collectionName}) =>{
    const [topOwners,setTopOwners] = useState([]);
    const [allTopOwners,setAllTopOwners] = useState([]);
    const downloadCSV = useDownloadCSV();
    const [messageOfAlert,setMessageOfAlert] = useState('');
    const [showAlert,setShowAlert] = useState(false);
    const [disabledDownloadPDF,setDisabledDownloadPDF] = useState(false);
    const [headCells] = useState([
        {
            id: 'id',
            label: '',
            notSortable: true
        },
        {
            id: 'currentOwner',
            label: 'Wallet',
            notSortable: true
        },
        {
            id: 'ownedNftsCollection',
            label: 'Owned nfts (From this collection)',
            notSortable: true
        },
        // {
        //     id: 'owned nfts artist',
        //     label: 'Owned nfts (From this artist)',
        //     notSortable: true
        // },
    ]);
    const [LIMIT] = useState(10);

    const elementRef = useRef(null);
    const offsetRef = useRef(0);

    const [setIsFetching, setIsDisabled] = useInfiniteScrolling(elementRef, async () => await handleFetchData());

    async function handleFetchData() {
        try {
            const encodedCollectionName = encodeURIComponent(collectionName);
            const { topOwners } = await getData(process.env.REACT_APP_SERVER_HOST + `/api/nft-analyze/get-top-owners?collectionName=${encodedCollectionName}&offset=${offsetRef.current}&limit=${LIMIT}`);
            
            if (topOwners.length === 0) {
                setIsDisabled(true);
                return;
            }
            setTopOwners((prev) => [...prev,...topOwners]);

            offsetRef.current += LIMIT;
            setIsFetching(false);
            setIsDisabled(false);

        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(()=>{
        handleFetchData()
    },[]);

    useEffect(() => {
        if(showAlert){
            const timer = setTimeout(()=> setShowAlert(false),3000);
            return () => clearTimeout(timer);
        }
    },[showAlert]);

    async function getAllTopOwners(){
        const maxOwners = 10000;
        const encodedCollectionName = encodeURIComponent(collectionName);
        const result = await getData(process.env.REACT_APP_SERVER_HOST + `/api/nft-analyze/get-top-owners?collectionName=${encodedCollectionName}&offset=0&limit=${maxOwners}`);
        return result 
    }

    async function downloadTopOwnersCSV(){
            getAllTopOwners().then(data => {
                try{
                    let dataToDownload;
                    if(data?.topOwners?.length === 0){
                        setMessageOfAlert('There is no information about owners');
                        setShowAlert(true);
                        return;
                    }

                        dataToDownload = data.topOwners.map(owner => {
                            return {
                                "Wallet": owner.currentOwner,
                                "Owned NFTs": owner.ownedNftsCollection,
                            }
                        })

                        downloadCSV(dataToDownload,`Owners_${collectionName}`);

                }catch(err){
                    console.error(err);
                    setMessageOfAlert('Error when downloading document');
                    setShowAlert(true);
                }
                
            })
    }

    async function fetchAllOwnersPDF(){
        setDisabledDownloadPDF(true);
        const res = await getAllTopOwners();
        setAllTopOwners(res.topOwners);
        }

       const saveFilePDF = useCallback((blob)=>{
            if(blob){
                saveAs(blob, `Owners_${collectionName}.pdf`);
                setDisabledDownloadPDF(false);
            }
        },[]);

    return (
        <div className={styles.collectionContainer}>
        <Typography style={{ marginBottom: '25px' }}>
            Top Owners
        </Typography>
        <div > 
            <BrandTable
                rows={topOwners}
                headCells={headCells}
                checkboxless={true}
                hidePagination={true}
                tableSize={'small'}
                stickyHeader={true}
                maxHeight={'300px'}
                containerRef={elementRef}
                stylesHeader={{padding:'15px'}}
            />
        </div>
        {topOwners.length ?
            <div className={styles.brandButtons}>
            <BrandButton onClick={downloadTopOwnersCSV} disabled={topOwners.length === 0 }>
                Download CSV 
            </BrandButton>

            <BrandButton onClick={fetchAllOwnersPDF} className={styles.buttonPDF} disabled={disabledDownloadPDF || topOwners.length === 0} >
                Download PDF
                { disabledDownloadPDF ? 
                <RenderedPDFTopOwners
                    collectionName={collectionName} 
                    styles={stylesPDF}
                    topOwners={allTopOwners}
                    saveFilePDF={saveFilePDF}
                        /> : ''
                }
            </BrandButton>
            </div>
        : '' }
        
        <Snackbar open={showAlert}>
            <Alert severity="error">
                {messageOfAlert}
            </Alert>
        </Snackbar>
    </div>
    )
}

export default TopOwnersTable;