import { Box, Typography, makeStyles } from "@material-ui/core";
import { Fragment, useEffect, useRef, useState } from "react";
import { BrandSearchInput } from "../CoreComponents/BrandInput";
import MergedSongsTree from "./MergedSongsTree";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import { useDebounce } from "../../hooks/useDebounce";
import { useInfiniteScrolling } from "../../hooks/useInfiniteScrolling";
import UnmergeSongModal from "./UnmergeSongModal";
import MoveSongModal from "./MoveSongModal";
import { BrandTooltip } from "../CoreComponents/BrandTooltip";
import { ExactWordTooltipMessage } from "../../constants/messageConstants";

const useStyles = makeStyles((theme) => ({
    pageMainHeader: {
        font: 'normal normal bold 25px Roboto'
    },
    searchFieldWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: '16px',
        marginBottom: '25px'
    },
    searchField: {
        width: theme.spacing(55.625)
    },
    mergedSongsTreeWrapper: {
        marginLeft: '-40px', 
        maxHeight: '500px', 
        height: '500px', 
        overflow: 'scroll',
        overflowX: 'hidden'
    },
    errorMessage: {
        color: theme.palette.primary.alert,
        textAlign: 'center',
        marginTop: '10px'
    }
}));

function buildMergedTree(pairs, inputPair) {
    const song = { 
        ...inputPair,
        lastModifiedDate: inputPair.lastModifiedDate.split('T')[0]
    };

    const parentInfo = pairs.find((pair) => pair.id === inputPair.mergedIntoSongId);

    if (parentInfo) {
        song.parentId = parentInfo.id;
        song.parentTitle = parentInfo.displayName;
        song.parentArtist = parentInfo.artist;
    }

    const mergedSongs = pairs
        .filter((pair) => pair.mergedIntoSongId === inputPair.id)
        .map((pair) => buildMergedTree(pairs, pair));

    if (mergedSongs.length > 0) {
        song.mergedSongs = mergedSongs;
    }

    return song;
}

const MergeHistory = () => {
    const LIMIT = 10;
    const DEBOUNCE_TIMEOUT = 500;

    const [songsData, setSongsData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [expandedItems, setExpandedItems] = useState([]);
    const [selectedSong, setSelectedSong] = useState(null);

    const [showUnmergeModal, setShowUnmergeModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const offsetRef = useRef(0);
    const elementRef = useRef(null);

    const searchQuery = useDebounce(searchInput, DEBOUNCE_TIMEOUT);
    const abortableFetch = useAbortableFetch();

    const [setIsFetching, setIsDisabled] = useInfiniteScrolling(elementRef, async () => await fetchMergedSongsData());

    const fetchMergedSongsData = async () => {
        try {
            const { songs } = await abortableFetch('GET', '/api/merge-history', {
                query: { searchQuery, offset: offsetRef.current, limit: LIMIT }
            });

            if (songs.length === 0 && offsetRef.current === 0) {
                setShowErrorMessage(true);
                return;
            }

            if (songs.length === 0) {
                setIsDisabled(true);
                return;
            }

            const songsTree = songs
                .filter((pair) => !songs.some((p) => p.id === pair.mergedIntoSongId))
                .map((pair) => buildMergedTree(songs, pair));

            setSongsData((prev) => prev.concat(songsTree));

            offsetRef.current += LIMIT;
            setIsFetching(false);
            setIsDisabled(false);
            setShowErrorMessage(false);
        } catch (error) {
            console.log(error);
        }
    }

    const refreshSongsData = async () => {
        offsetRef.current = 0;
        setSongsData([]);
        setIsDisabled(false);
        await fetchMergedSongsData();
    }

    useEffect(() => {
        offsetRef.current = 0;
        setSongsData([]);
        setIsDisabled(false);
        fetchMergedSongsData();
    }, [searchQuery]);

    const styles = useStyles();

    return (
        <Box component='div'>
            { showUnmergeModal && (
                <UnmergeSongModal
                    song={selectedSong}
                    open={showUnmergeModal}
                    onClose={() => setShowUnmergeModal(false)}
                    refreshData={refreshSongsData}
                />
            ) }
            { showMoveModal && (
                <MoveSongModal 
                    song={selectedSong} 
                    open={showMoveModal} 
                    onClose={() => setShowMoveModal(false)}
                    refreshData={refreshSongsData} 
                />
            ) } 
            <Typography className={styles.pageMainHeader}>
                Merge History
            </Typography>
            <Box className={styles.searchFieldWrapper}>
                <BrandSearchInput
                    placeholder='Search for song'
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    classes={{ input: styles.searchField }}
                />
                <BrandTooltip title={ExactWordTooltipMessage} />
            </Box>
            <Box ref={elementRef} className={styles.mergedSongsTreeWrapper}>
                { !showErrorMessage ? (
                    <MergedSongsTree
                        songsData={songsData} 
                        expandedItems={expandedItems} 
                        setSelectedSong={setSelectedSong}
                        setExpandedItems={setExpandedItems}
                        setShowUnmergeModal={setShowUnmergeModal}
                        setShowMoveModal={setShowMoveModal}
                    />
                ) : (
                    <Typography className={styles.errorMessage}>
                        No results found.
                    </Typography>
                ) }
            </Box>
        </Box>
    );
}

export default MergeHistory;