import { makeStyles } from "@material-ui/core";
import { BrandButton, BrandDeleteButton, SecondaryBrandButton, TempBrandButton, TempBrandDeleteButton, TempSecondaryBrandButton } from "./BrandButton";
import { BrandModal } from "./BrandModal";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { AlternativeNamesActions } from "../../constants/alternativeNamesConstants";
import { ButtonSize } from "../../constants/buttonConstants";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 600
    },
    actions: {
        display: 'flex',
        justifyContent: "flex-end",
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
        '& button': {
            width: '143px',
        },
        marginTop: '20px',
    },
    warningText: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        color: theme.palette.primary.attention,
    },
    warningIcon: {
        marginRight: '0.4vw',
    }
}));

const DialogConfig = {
    [AlternativeNamesActions.UnmergeAlternativeName.id]: {
        primaryButton: false,
        deleteButton: true,
        continueConfirmationText: true,
        showWarning: false,
    },
    [AlternativeNamesActions.MergeAlternativeName.id]: {
        primaryButton: true,
        deleteButton: false,
        continueConfirmationText: true,
        showWarning: false,
    },
    [AlternativeNamesActions.KeepSeparateAlternativeName.id]: {
        primaryButton: true,
        deleteButton: false,
        continueConfirmationText: true,
        showWarning: true,
    }
}

function ConfirmationDialog({
    type,
    onClose,
    onAction,
    showState,
    setShowState
}) {
    const config = DialogConfig[type.id] || {};
    const styles = useStyles();

    const actionHandler = () => {
        onAction();
        onClose();
    }

    return (
        <BrandModal
            open={showState}
            onClose={() => setShowState(false)}
        >
            <div className={styles.container}>
                <h3>{type.title}</h3>
                <div className={config.showWarning ? styles.warningText : ''}>
                    {
                        config.showWarning &&
                        <div className={styles.warningIcon}>
                            <WarningAmberIcon />
                        </div>
                    }
                    <div>
                        {type.description}
                    </div>
                </div>
                {
                    config.continueConfirmationText &&
                    <p>
                        Are you sure you want to continue?
                    </p>
                }
                <div className={styles.actions}>
                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        onClick={onClose}
                    >
                        {type.secondaryButtonText}
                    </TempSecondaryBrandButton>
                    {config.primaryButton ?
                        <TempBrandButton
                            size={ButtonSize.SMALL}
                            onClick={actionHandler}
                        >
                            {type.primaryButtonText}
                        </TempBrandButton>
                        :
                        <TempBrandDeleteButton
                            size={ButtonSize.SMALL}
                            onClick={actionHandler}
                        >
                            {type.primaryButtonText}
                        </TempBrandDeleteButton>
                    }
                </div>
            </div>
        </BrandModal>
    );
}

export default ConfirmationDialog;