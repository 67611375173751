const LOCALE_STRING_LANGUAGE = 'en-US';
const NUMBER_FORMAT_MIN_FRACTION_DIGITS = 2;
const NUMBER_FORMAT_MAX_FRACTION_DIGITS = 2;
const MONEY_FORMAT_MIN_FRACTION_DIGITS = 2;
const MONEY_FORMAT_MAX_FRACTION_DIGITS = 2;

function formatNumber(element) {
    return Number(element).toLocaleString(LOCALE_STRING_LANGUAGE, {
        maximumFractionDigits: NUMBER_FORMAT_MAX_FRACTION_DIGITS,
        minimumFractionDigits: NUMBER_FORMAT_MIN_FRACTION_DIGITS
    });
}
function formatNumberByMinMaxFractionDigits(element, min, max) {
    return Number(element).toLocaleString(LOCALE_STRING_LANGUAGE, {
        maximumFractionDigits: max,
        minimumFractionDigits: min
    });
}

function formatMoney(element) {
    return Number(element).toLocaleString(LOCALE_STRING_LANGUAGE, {
        minimumFractionDigits: MONEY_FORMAT_MIN_FRACTION_DIGITS,
        maximumFractionDigits: MONEY_FORMAT_MAX_FRACTION_DIGITS
    });
}

module.exports = {
    formatNumber,
    formatNumberByMinMaxFractionDigits,
    formatMoney
}