import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { postData } from '../utils/FetchUtils';
import { useStoreContext } from '../../store/Store';
import SongsList from './SongsList';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '30px',
    },
    accountHolderSubTitle: {
        fontSize: '14px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    }
}));

const getUniqueSongs = (validationRows, reportTemplate) => {
    const uniqueSongs = {};

    for (let i = 0; i < validationRows.length; i++) {
        const title = validationRows[i].cells['0'].text;
        const artist = validationRows[i].cells['1'].text === 'No Data' ? '' : validationRows[i].cells['1'].text;
        const isrc = validationRows[i].cells['5'].text === 'No Data' ? '' : validationRows[i].cells['5'].text;
        const upc = validationRows[i].cells['6'].text === 'No Data' ? '' : validationRows[i].cells['6'].text;
        const album = validationRows[i].cells['11'].text === 'No Data' ? '' : validationRows[i].cells['11'].text;
        const externalId = validationRows[i].cells['13'].text === 'No Data' ? '' : validationRows[i].cells['13'].text;

        const key = `${title}-${artist}-${upc}-${isrc}-${externalId}`;
        if (uniqueSongs[key]) {
            if (!uniqueSongs[key].album && album) {
                uniqueSongs[key].album = album;
            }
            if (!uniqueSongs[key].isrc && isrc) {
                uniqueSongs[key].isrc = isrc;
            }
            if (!uniqueSongs[key].upc && upc) {
                uniqueSongs[key].upc = upc;
            }
        } else {
            uniqueSongs[key] = {
                title: title,
                upc: upc,
                isrc: isrc,
                artist: artist,
                album: album,
                externalId: externalId,
                foundSongId: '',
                foundArtistId: '',
                foundTitle: '',
                foundArtist: '',
                foundIsrc: '',
                foundAlbum: '',
                foundCoverArt: ''
            };
        }
    }

    return uniqueSongs;
}

export default function Songs({
    validationRows,
    setLoadingSongsStep,
    mappingTemplate,
    songsMapKeys,
    setSongsMapKeys,
    setPartialSongsMapKeys,
    setAlternativeNamesWithSongData,
    reportingCompany,
    accountHolder,
    setExternalISRCs,
    setExternalUPCs
}) {
    const [state, setState] = useStoreContext();
    const styles = useStyles();
    const [alternativeNames, setAlternativeNames] = useState([]);

    useEffect(() => {
        if (state.findSongsInDb) {
            setLoadingSongsStep(true);
            const uniqueSongs = getUniqueSongs(validationRows, mappingTemplate);

            postData(process.env.REACT_APP_SERVER_HOST + "/api/song/find-matching-songs", { uniqueSongs: uniqueSongs, reportingCompany })
                .then(data => {
                    setState(state => (state.findSongsInDb = false, { ...state }));
                    setState(state => (state.songsMap = data.matchingResultByLevels.fullMatch, { ...state }));
                    setState(state => (state.partialSongsMap = data.matchingResultByLevels.partialMatch, { ...state }));
                    setLoadingSongsStep(false);
                    setSongsMapKeys(Object.keys(data.matchingResultByLevels.fullMatch));
                    setPartialSongsMapKeys(Object.keys(data.matchingResultByLevels.partialMatch));

                    // A store for the ISRCs of the songs matched to songs in the DB 

                    const songExternalISRCs = {};
                    const songExternalUPCs = {};

                    const allSongs = [...Object.values(data.matchingResultByLevels.fullMatch), ...Object.values(data.matchingResultByLevels.partialMatch)];
                    for (const song of allSongs) {
                        const {
                            upc,
                            isrc,
                            found,
                            foundSongId
                        } = song;

                        if (!found) continue;

                        if (!songExternalISRCs[foundSongId]) songExternalISRCs[foundSongId] = [];
                        if (!songExternalUPCs[foundSongId]) songExternalUPCs[foundSongId] = [];

                        if (isrc) songExternalISRCs[foundSongId].push(isrc);
                        if (upc) songExternalUPCs[foundSongId].push(upc);
                    }

                    setExternalISRCs(songExternalISRCs);
                    setExternalUPCs(songExternalUPCs);
                }, error => {
                    setState(state => (state.findSongsInDb = true, { ...state }));
                    setLoadingSongsStep(false);
                });
        }
    }, []);

    const setExternalData = (setExternalFunction, value, foundSongId) => {
        if (!value) return;

        setExternalFunction((prev) => ({
            ...prev,
            [foundSongId]: [...(prev[foundSongId] || []), value]
        }));
    }

    const onSelectedSong = (song, songMapKey) => {
        const actualSong = state.songsMap[songMapKey];

        setExternalData(setExternalISRCs, actualSong.isrc, song.foundSongId);
        setExternalData(setExternalUPCs, actualSong.upc, song.foundSongId);

        const tempSongsMap = state.songsMap;
        tempSongsMap[songMapKey] = {
            ...tempSongsMap[songMapKey],
            ...song
        }

        setState(state => (state.songsMap = { ...tempSongsMap }, { ...state }));
    };

    useEffect(() => {
        let alternativeNamesArray = [];
        for (const track in state.songsMap) {
            if (state.songsMap[track].externalId && state.songsMap[track].foundSongId) {
                const title = state.songsMap[track].title
                const songId = state.songsMap[track].foundSongId;
                const externalId = state.songsMap[track].externalId?.trim();
                const reportingCompanyId = state.selectedReportingCompany;
                alternativeNamesArray.push({ title: title, songId: songId, externalId: externalId, reportingCompanyId });
            }
        }
        setAlternativeNames(alternativeNamesArray)
    }, [state.songsMap]);

    useEffect(() => {
        if (alternativeNames.length > 0) {

            let altNamesWithSongData = alternativeNames.map(obj => {
                for (const row of validationRows) {
                    if (row.cells[0].text === obj.title) {
                        obj = { songData: row.cells, ...obj };
                        break
                    }
                }
                return obj
            })
            const keysNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
            altNamesWithSongData.map(song => {
                song.data = {}
                keysNumbers.forEach(num => {
                    if (song.songData[num].text === 'No Data') {
                        song.songData[num].text = null;
                    }
                    switch (num) {
                        case 0:
                            song.data.title = song.songData[num].text
                            break;
                        case 1:
                            song.data.artist = song.songData[num].text
                            break;
                        case 2:
                            song.data.consumptionPeriod = song.songData[num].text
                            break;
                        case 3:
                            song.data.revenue = song.songData[num].text
                            break;
                        case 4:
                            song.data.publishingOwnership = song.songData[num].text
                            break;
                        case 5:
                            song.data.isrc = song.songData[num].text
                            break;
                        case 6:
                            song.data.upc = song.songData[num].text
                            break;
                        case 7:
                            song.data.releaseDate = song.songData[num].text
                            break;
                        case 8:
                            song.data.writers = song.songData[num].text
                            break;
                        case 9:
                            song.data.producers = song.songData[num].text
                            break;
                        case 10:
                            song.data.territory = song.songData[num].text
                            break;
                        case 11:
                            song.data.album = song.songData[num].text
                            break;
                        case 12:
                            song.data.playSource = song.songData[num].text
                            break;
                        case 13:
                            song.data.externalId = song.songData[num].text;
                    }
                })
                delete song.songData
                return song
            });
            setAlternativeNamesWithSongData(altNamesWithSongData);



        }
    }, [alternativeNames]);

    return (
        <div>
            <div className={styles.title}>4. Songs - {state.uploadReportFileName}</div>
            <div className={styles.accountHolderSubTitle}>Current Account Holder: {accountHolder.name}</div>
            <SongsList
                songsMapKeys={songsMapKeys}
                onSelectedSong={onSelectedSong}
                setExternalISRCs={setExternalISRCs}
                setExternalUPCs={setExternalUPCs}
                accountHolder={accountHolder}
            />
        </div>
    );
}