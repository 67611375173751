import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/BrandSelect';
import { QuarterPicker } from '../CoreComponents/QuarterPicker';
import { getData } from '../utils/FetchUtils';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { BrandButton, TempBrandButton } from '../CoreComponents/BrandButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import BrandTable from '../CoreComponents/BrandTable'
import { ButtonSize } from '../../constants/buttonConstants';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: 'white'
                }
            }
        }
    },
    title: {
        fontSize: theme.spacing(5.625),
        color: '#62E162',
        marginBottom: theme.spacing(2),
    },
    firstComponent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
    },
    missingDataSelectGroup: {
        width: '100%'
    },
    artistSelect: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        color: '#C3C3C3',
        marginBottom: theme.spacing(2),
        '& .MuiInputBase-root': {
            minWidth: theme.spacing(26.875),
            background: '#000000',
            border: '#000000'
        }
    },
    selectDateRange: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dateSelect: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        color: '#C3C3C3',
        marginBottom: theme.spacing(2),
        '& .MuiInputBase-root': {
            background: '#000000',
            border: '#000000',
            '& .MuiButtonBase-root': {
                color: 'white'
            }
        }
    },
    warningIcon: {
        height: theme.spacing(5.75),
        width: theme.spacing(5.75),
        color: '#F96C00'
    },
    warningContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(3),
        background: "#000000",
        borderRadius: '6px',
        width: theme.spacing(58),
        height: theme.spacing(20)
    },
    warningMessage: {
        color: '#F96C00',
        marginBottom: theme.spacing(1.25)
    },
    lastDataIngestion: {
        display: 'flex',
        gap: theme.spacing(3),
        color: "#A5A5A5"
    },
    secondComponent: {
        display: 'flex',
        gap: theme.spacing(2.5),
        marginBottom: theme.spacing(3),
        "& .MuiButtonBase-root": {
            color: '#A5A5A5',
            background: '#323232',
            border: '#323232'
        },
        "& .MuiButton-contained": {
            boxShadow: 'none'
        }
    },
    btnActive: {
        "& .MuiButtonBase-root": {
            color: 'white',
            background: '#000000',
            border: '1px solid #03B56D'
        }
    },
    distributorActive: {
        "& .MuiButtonBase-root": {
            color: 'white',
            background: '#000000',
            border: '1px solid #F96C00'
        }
    },
    missingDistributorMessage: {
        width: theme.spacing(98.125),
        height: theme.spacing(25.625),
        border: '1px solid #707070',
        borderRadius: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(3),
        color:'#CCCCCC'
    },
    missingDistributorContainer: {
        background: '#323232',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.spacing(65.7)
    }
}));

const headCells = [
    {
        id: 'title',
        label: 'Song Title',
    },
    {
        id: 'artistName',
        label: 'Artist',
    },
    {
        id: 'labelReports',
        label: 'Label Reports',
    },
    {
        id: 'missingPeriods',
        label: 'Missing Periods',
    },
    {
        id: 'releaseDate',
        label: 'Release Date',
    }
];

export default function MissingData() {
    const styles = useStyles();
    const selectedArtist = useState(0);
    const $startQuarter = useState(19901);
    const $endQuarter = useState(20214);
    const [accHolders, setAccHolders] = useState([]);

    const [page, setPage] = useState(0);

    const [labelReportsActive, setLabelReportsActive] = useState(true);
    const [distributorActive, setDistributorActive] = useState(false);
    const [publisherReportsActive, setPublisherReportsActive] = useState(false);
    const [publishingProReports, setPublishingProReports] = useState(false);

    //Data that we don't have yet
    const [lastDataIngestion] = useState('06/21/2021');
    const [rows, setRows] = useState([
        {
            title: 'Catch These Paws',
            artistName: 'Alley Katz',
            labelReports: 'Full',
            missingPeriods: 'complete data',
            releaseDate: '2016-12-12',
            id: 1
        },
        {
            title: 'Spend 9 Lives With You',
            artistName: 'DJ Itza TravisG',
            labelReports: 'Partial',
            missingPeriods: 'Q1 2020 - Q2 2020; Q1 2012 - Q4 2012',
            releaseDate: 'unknown',
            id: 2
        },
        {
            title: 'Stay Pawsitive',
            artistName: 'Alley Katz',
            labelReports: 'Missing',
            missingPeriods: 'Q1 1996 - Q3 2021',
            releaseDate: '2016-12-12',
            id: 3
        },
        {
            title: 'Sandpaper Tongue Kiss',
            artistName: 'Alley Katz',
            labelReports: 'Full',
            missingPeriods: 'complete data',
            releaseDate: '2016-12-12',
            id: 4
        },
        {
            title: 'Did You Hiss?',
            artistName: 'Alley Katz',
            labelReports: 'Full',
            missingPeriods: '-',
            releaseDate: 'unknown',
            id: 5
        },
        {
            title: 'Hell On Halloween',
            artistName: 'Alley Katz',
            labelReports: 'Missing',
            missingPeriods: 'Q1 1996 - Q3 2021',
            releaseDate: '2016-12-12',
            id: 6
        },
        {
            title: 'Flawed & Declawed',
            artistName: 'Alley Katz',
            labelReports: 'Partial',
            missingPeriods: 'Q3 2021',
            releaseDate: '2016-12-12',
            id: 7
        },
        {
            title: 'Sylvester',
            artistName: 'Alley Katz',
            labelReports: 'Missing',
            missingPeriods: 'Q1 1996 - Q3 2021',
            releaseDate: 'unknown',
            id: 8
        },
        {
            title: 'Tuna Dinner Remix',
            artistName: 'Alley Katz',
            labelReports: 'Partial',
            missingPeriods: 'Q1 2020 - Q2 2020',
            releaseDate: 'unknown',
            id: 9
        },
        {
            title: 'To Kill A Mockingbird',
            artistName: 'Alley Katz',
            labelReports: 'Missing',
            missingPeriods: 'Q1 1996 - Q3 2021',
            releaseDate: 'unknown',
            id: 10
        },
        {
            title: 'Catch These Paws',
            artistName: 'Alley Katz',
            labelReports: 'Partial',
            missingPeriods: 'Q3 2021',
            releaseDate: 'unknown',
            id: 11
        },
    ]);
    //Data that we don't have yet

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/my`)
            .then(data => setAccHolders(data));
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                Missing Data
            </div>
            <div className={styles.firstComponent}>
                <div className={styles.missingDataSelectGroup}>
                    <div className={styles.artistSelect}>
                        <div>Account Holder:&nbsp;&nbsp;</div>
                        <BrandSelect $value={selectedArtist}>
                            <BrandMenuItem value={0}>All</BrandMenuItem>
                            {accHolders.map(x => (
                                <BrandMenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </BrandMenuItem>
                            ))}
                        </BrandSelect>
                    </div>
                    <div className={styles.selectDateRange}>
                        <div className={styles.dateSelect}>
                            <div >Date&nbsp;</div>
                            <div>Range:&nbsp;&nbsp;</div>
                            <QuarterPicker $value={$startQuarter} />
                            <div className="text">&nbsp;&nbsp;to&nbsp;&nbsp;</div>
                            <QuarterPicker $value={$endQuarter} />
                        </div>
                    </div>
                </div>
                <div className={styles.warningContainer}>
                    <ReportProblemOutlinedIcon className={styles.warningIcon} />
                    <div >
                        <div className={styles.warningMessage} >Warning: Insufficient report data</div>
                        <div className={styles.warningMessage}>Please provide more reports.</div>
                        <div className={styles.lastDataIngestion}>
                            <div>Last data ingestion</div>
                            <div>{lastDataIngestion}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.secondComponent}>
                <div className={labelReportsActive ? styles.btnActive : null}>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        variant='outlined'
                        onClick={() => {
                            setLabelReportsActive(true);
                            setDistributorActive(false);
                            setPublisherReportsActive(false);
                            setPublishingProReports(false);
                        }}
                    >
                        Label Reports
                    </TempBrandButton>
                </div>
                <div className={distributorActive ? styles.distributorActive : null}>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        startIcon={<FiberManualRecordIcon style={{ color: '#F96C00' }} />}
                        onClick={() => {
                            setDistributorActive(true);
                            setLabelReportsActive(false);
                            setPublisherReportsActive(false);
                            setPublishingProReports(false);
                        }}
                    >
                        Distributor
                    </TempBrandButton>
                </div>
                <div className={publisherReportsActive ? styles.btnActive : null}>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        variant='outlined'
                        onClick={() => {
                            setPublisherReportsActive(true);
                            setLabelReportsActive(false);
                            setDistributorActive(false);
                            setPublishingProReports(false);
                        }}
                    >
                        Publisher Reports
                    </TempBrandButton>
                </div>
                <div className={publishingProReports ? styles.btnActive : null}>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        variant='outlined'
                        onClick={() => {
                            setPublishingProReports(true);
                            setLabelReportsActive(false);
                            setDistributorActive(false);
                            setPublisherReportsActive(false);
                        }}
                    >
                        Publishing PRO Reports
                    </TempBrandButton>
                </div>
            </div>
            {labelReportsActive ?
                <BrandTable
                    rows={rows}
                    headCells={headCells}
                    checkboxless={true}
                    page={page}
                    setPage={setPage} // delete when we have pagination
                />
                :
                null}
            {distributorActive ?
                <div className={styles.missingDistributorContainer}>
                    <div className={styles.missingDistributorMessage}>
                        <div>
                            There is no distributor data found.
                        </div>
                        <div>
                            Please provide reports for ingestion and check this view again.
                        </div>
                    </div>
                </div>
                :
                null}

        </div >
    );
}



