import { 
    InputAdornment, 
    makeStyles 
} from "@material-ui/core";
import { BrandInput } from "../CoreComponents/BrandInput";

const PERCENTAGE_REGEX = /^100(\.0{1,2})?$|^\d{1,2}(\.\d{1,2})?$/;

const useStyles = makeStyles(() => ({
    input: {
        width: '80px'
    },
    inputAdornment: {
        marginRight: '0px'
    }
}));

const PercentageInput = ({
    inputData,
    setInputData,
    validatorPassed,
    ...other
}) => {
    const styles = useStyles();

    const percentageValidator = (value) => PERCENTAGE_REGEX.test(value) 
        ? [] 
        : ['Value should be percentage.'];

    return (
        <BrandInput 
            classes={{ input: styles.input }} 
            $value={[inputData, setInputData]}
            validator={percentageValidator}
            validatorPassed={validatorPassed}
            InputProps={{
                endAdornment: (
                    <InputAdornment 
                        position="start" 
                        className={styles.inputAdornment}
                    >
                        %
                    </InputAdornment>
                )
            }}
            {...other}
        />
    )
}

export default PercentageInput;