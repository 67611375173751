const frozenObject = Object.freeze({
    AlternativeNamesActions: {
        MergeAlternativeName: {
            id: 0,
            title: 'Merge Alternative Name',
            description: 
                `Merging an Alternative Name will result in adding this song to the Record database,
                linking the alternative name to the Record song name and it will
                add the related transactions to the overall revenue.`,
                primaryButtonText: 'Merge',
                secondaryButtonText: 'Cancel',
        },
        UnmergeAlternativeName: {
            id: 1,
            title: 'Unmerge Alternative Name',
            description: 
                `Unmerging an Alternative Name will result in adding the song as a new song in the Record database
                and it will move all the related transactions from the overall revenue for the
                Record song name.`,
            primaryButtonText: 'Unmerge',
            secondaryButtonText: 'Cancel',
        },
        KeepSeparateAlternativeName: {
            id: 2,
            title: 'Keep Separate Alternative Name', 
            description: 
            `The alternative name won't be linked to this selected song anymore.`,
            primaryButtonText: 'Keep Separate',
            secondaryButtonText: 'Cancel',
        }
    }
})

module.exports = frozenObject;