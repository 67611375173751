import { useEffect, useRef, useState } from "react";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useDebounce } from '../../hooks/useDebounce';
import { TempBrandButton } from "../CoreComponents/BrandButton";
import { BrandCheckbox } from "../CoreComponents/BrandCheckbox";
import useAbortableFetch from '../../hooks/useAbortableFetch.js';
import { BrandSearchInput } from '../CoreComponents/BrandInput';
import BrandTable from '../CoreComponents/BrandTable.js';
import { transformPeriodToQuarterYear, transformDateToQuarterPeriodInt } from '../utils/DateUtils.js';
import clsx from 'clsx';
import { QuarterPicker } from '../CoreComponents/QuarterPicker.js';
import { IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles((theme) => ({
    filtersWrapper: {
        display: 'flex',
        columnGap: '40px',
        alignItems: 'center',
        marginBottom: '20px'
    },
    searchInput: {
        width: 450
    },
    tableContainer: {
        '& .MuiTableCell-head': {
            padding: '16px'
        },
        '& .MuiBox-root': {
            marginBottom: '0px !important'
        }
    },
    subtitle: {
        marginBottom: '20px'
    },
    fileNameCell: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    },
    quarterPickerContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '15px'
    },
    filterLabel: {
        color: theme.palette.text.lightYellow,
    },
    showOnlyApplicableFilter: {
        '& .MuiFormControlLabel-root': {
            marginRight: '0px'
        }
    },
    tooltipText: {
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.primary.lightYellow}`,
        borderRadius: '16px',
        textAlign: 'left',
        padding: '12px'
    },
    tooltipAlertText: {
        color: theme.palette.primary.delete
    }
}));

const USED_CSV_REPORT_TOOLTIP_TEXT = <p>Used in previous Royalty reports.</p>;
const APPLICABLE_FILES_FILTER_TOOLTIP_TEXT_ = <p>The applicable files are the ones that are currently NOT included in any previously generated royalty reports.</p>;

export default function CsvStatementsStep({
    selectedFitlers,
    setSelectedFitlers,
    selectedCSVStatements,
    setSelectedCSVStatements
}) {
    const styles = useStyles();
    const abortableFetch = useAbortableFetch();

    const [startQuarter, setStartQuarter] = useState(transformDateToQuarterPeriodInt(selectedFitlers.startDate));
    const [endQuarter, setEndQuarter] = useState(transformDateToQuarterPeriodInt(selectedFitlers.endDate));
    const [minDateValue, setMinDateValue] = useState(startQuarter);
    const [maxDateValue, setMaxDateValue] = useState(endQuarter);

    useEffect(() => {
        setMinDateValue(startQuarter);
    }, [startQuarter])

    useEffect(() => {
        setMaxDateValue(endQuarter);
    }, [endQuarter])

    const [searchField, setSearchField] = useState('');
    const searchFieldQuery = useDebounce(searchField, 500);
    const [items, setItems] = useState([]);
    const [sortBy, setSortBy] = useState('reportingPeriod');
    const [sortType, setSortType] = useState('ASC');
    const showOnlyApplicable = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const body = {
                payeeId: selectedFitlers.payeeId,
                payorId: selectedFitlers.payorId,
                startDate: startQuarter,
                endDate: endQuarter,
                filter: searchFieldQuery,
                sort: sortBy,
                type: sortType,
                showOnlyApplicable: showOnlyApplicable[0]
            };
            try {
                const fetchedData = await abortableFetch('POST',
                    '/api/royalty-statement/get-payee-csv-statements', {
                    body,
                });

                setItems(fetchedData ? fetchedData.statementsData : []);
                const preSelectedStatements = fetchedData.statementsData.reduce((filtered, currStatement) => {
                    if (!currStatement.disabled)
                        filtered.push(currStatement.id);
                    return filtered;
                }, []);
                setSelectedCSVStatements(preSelectedStatements);

            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [searchFieldQuery, sortBy, sortType, showOnlyApplicable[0], startQuarter, endQuarter])

    const [headCells] = useState([
        {
            id: 'accountHolderName',
            label: 'Account Holder',
            width: '20%',
            minWidth: '20%',
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItem}>
                        {rowData.accountHolderName}
                    </div>
                )
            }
        },
        {
            id: 'reportingCompanyName',
            label: 'Reporting Company',
            width: '20%',
            minWidth: '20%',
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItem}>
                        {rowData.reportingCompanyName}
                    </div>
                );
            },
        },
        {
            id: 'fileName',
            label: 'CSV File name',
            width: '30%',
            minWidth: '30%',
            CellRender: ({ rowData }) => {
                return (
                    <div className={clsx(styles.rowItem, styles.fileNameCell)}>
                        {rowData.fileName}
                        {Boolean(rowData.alreadyUsed) &&
                            <Tooltip title={USED_CSV_REPORT_TOOLTIP_TEXT} arrow placement="bottom" classes={{ tooltip: styles.tooltipText }} >
                                <MonetizationOnIcon color='primary' fontSize='small' />
                            </Tooltip>
                        }
                    </div>
                );
            }
        },
        {
            id: 'reportingPeriod',
            label: 'Reporting Period',
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.rowItem}>
                        {transformPeriodToQuarterYear(rowData.reportingPeriod)}
                    </div>
                );
            },
        }
    ]);

    return (
        <>
            <Typography className={styles.subtitle} variant='subtitle1'>View CSVs for statement.</Typography>
            <div className={styles.filtersWrapper}>
                <BrandSearchInput
                    placeholder={`Search for statement`}
                    value={searchField}
                    onChange={(event) => setSearchField(event.target.value)}
                    classes={{ input: styles.searchInput }}
                />
                <div className={styles.showOnlyApplicableFilter}>
                    <BrandCheckbox
                        $value={showOnlyApplicable}
                        label='Show only applicable files'
                    />
                    <Tooltip title={APPLICABLE_FILES_FILTER_TOOLTIP_TEXT_} arrow placement="bottom" classes={{ tooltip: styles.tooltipText }} >
                        <IconButton>
                            <InfoOutlinedIcon fontSize={'small'} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={styles.quarterPickerContainer}>
                    <div className={styles.filterLabel}>Quarter Range</div>
                    <QuarterPicker value={startQuarter} maxVal={maxDateValue} onChange={setStartQuarter} />
                    <div className={styles.filterLabel}>to</div>
                    <QuarterPicker value={endQuarter} minVal={minDateValue} onChange={setEndQuarter} />
                </div>
            </div>
            <div className={styles.tableContainer}>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <BrandTable
                        rows={items}
                        headCells={headCells}
                        hidePagination={true}
                        rowIsClickable={false}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                        selectedIds={selectedCSVStatements}
                        setSelectedIds={setSelectedCSVStatements}
                        stickyHeader={true}
                        maxHeight={'60vh'}
                    />
                )}
            </div>
        </>
    );

}