import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import { BrandVerticalBarsChart, BrandHorizontalBarsChart } from "../../CoreComponents/BrandChart";
import BrandTable from '../../CoreComponents/BrandTable';
import { BrandButton } from '../../CoreComponents/BrandButton';
import { BrandCheckbox } from '../../CoreComponents/BrandCheckbox';
import { debounce, getData } from "../../utils/FetchUtils";
import CollectionItem from "../CollectionItem";
import TopOwnersTable from "./TopOwnersTable";
import BrandDoughnutChartTypeTwo from "./BrandDoughnutChartTypeTwo";
import { BrandLoader } from "../../CoreComponents/BrandLoader";
import { blockchainAnalyze } from "../../../constants/blockchainConstants";


const useCollectionStatStyles = makeStyles(theme => ({
    mainTypography: {
        color: '#b0b0b0'
    },
    mainContainer: {
        [theme.breakpoints.down('xs')]: {
            display: 'inline-block',
            alignItems: 'center',
            width: '100%',
        }
    },
    sectionHeader: {
        fontWeight: 'bold',
        fontSize: 25
    },
    collectionContainer: {
        width: '50%',
        // border: '2px solid yellow',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '200px'
        }
    },
    collectionMarketplaces: {
        display: 'flex',
        marginBottom: '25px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
            flexDirection: 'column'
        }
    },
    collectionDiagram: {
        marginLeft: '2.5%',
        display: 'flex',
        width: '45%',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '200px'
        }
    },
    collectionOwnersAndDistribution: {
        display: 'flex',
        overflow: 'auto'
    },
    topOwners: {
        width: '55%'
    },
    collectionDistribution: {
        marginLeft: '2.5%',
        width: '45%',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '200px'
        }
    },
    collectionOwnersAndPriceCharts: {
        display: 'flex',
        overflow: 'auto'
    },
    brandButtons: {
        marginLeft: '54%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '20px',
        marginTop: '10px'
    },
    marketpalcesListHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '10px'
    },
    marketpalcesList: {
        width: '70%'
    },
    horisontalLine: {
        border: '1px solid #A5A5A5'
    },
    sortPlatformTitle: {
        fontSize: '18px',
        fontWeight:'bold'
    },
    checkboxPlatform:{
        display:'flex',
        gap:'5px',
        alignItems:'center',
        justifyContent:'center',
        marginRight:'14px'

    },

    //-----------------------Doughnut styles-------

    doughnutContainer: {
        width: '40%',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    doughnut: {
    },
    pieChart: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& canvas': {
            zIndex: 1
        }
    },
    checkboxContainer: {
        alignItems: 'center',
        // justifyContent: 'center',
        display: 'flex',
        // border: '1px solid red'
    },

    //------------------LinearGradient styles--------

    ratio: {
        height: '25px',
        marginTop: '10px',
        borderRadius: '25px'
    },
    buttonPDF: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    collectionsLoader: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '100px'
    }
}))

const ownerDistributionData = {
    totalOwners: 1111
}

const ownersOvertimeBarChartData = [
    {
        year: 'April',
        value: 30
    },
    {
        year: 'May',
        value: 33
    },
    {
        year: 'June',
        value: 3
    },
    {
        year: 'July',
        value: 14
    },
    {
        year: 'August',
        value: 50
    },
    {
        year: 'September',
        value: 90
    },
    {
        year: 'October',
        value: 10
    },
    {
        year: 'November',
        value: 1
    }
]

const CollectionStats = () => {
    const styles = useCollectionStatStyles();
    const { collectionName } = useParams();
    const collectionNameDecoded = decodeURIComponent(collectionName);
    const [collectionDetails, setCollectionDetails] = useState()
    const [isLoading, setIsloading] = useState(true);
    const [row, setRows] = useState([]);
    const [selected, setSelected] = useState(false);
    const [selectedMarketplaces, setSelectedMarketplaces] = useState([]);
    const [countOfSalesByPlatform,setCountOfSalesByPlatform] = useState([]);
    const [salesByPlatformLoading,setSalesByPlatformLoading] = useState(false);

    useEffect(() => {
        getTotalRevenue();
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft-analyze/get-nft-collection?collectionName=${collectionNameDecoded}`)
            .then(data => setCollectionDetails(data.collectionDetails));

        setIsloading(false);

        const allNftCheckboxes = document.getElementsByClassName('nftCheckbox');
        for (const nftCheckbox of allNftCheckboxes) {
            const nftCheckboxEle = nftCheckbox.getElementsByTagName('input')[0];
            if (!nftCheckboxEle.checked) {
                nftCheckboxEle.click();
            }
        }
    }, [])

    function selectHandler(name, sales, color) {
        if (!selectedMarketplaces.find((item) => item.name == name)) {
            setSelectedMarketplaces((prev) => prev.concat({ name: name, value: sales, color: color }))
        } else {
            setSelectedMarketplaces((prev) => prev.filter((item) => item.name !== name))
        }
    }
    function setCountOfSales(countsOfSales){
        const countsOfSalesWithColors = countsOfSales.map((platform,i) =>{
            return {...platform,color:blockchainAnalyze.COLORS_OF_PLATFORMS[i]};
        });

        setCountOfSalesByPlatform(countsOfSalesWithColors);
        setSelectedMarketplaces(countsOfSalesWithColors.map(market =>{
             return {name:market.marketplace, value: market.value,color:market.color}
        }
        ));

    }

    async function getTotalRevenue(){
        setSalesByPlatformLoading(true);
        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft-analyze/get-marketplace-sales?collectionName=${collectionName}`)
        .then(data => setCountOfSales(data.countsOfSalesByPlatform))
        .finally(() => setSalesByPlatformLoading(false));
    }

    let colors = '#8123bf, #42a0d6'

    return (
        <div>
            <Typography
                variant="h6"
                className={styles.sectionHeader}
            >
                Blockchain Analyze
            </Typography>
            <div className={styles.mainContainer}>
                <div className={styles.collectionMarketplaces} style={{}}>
                    <div className={styles.collectionContainer}>
                        {collectionDetails ?
                            <CollectionItem details={collectionDetails} accountHolderName={''} />
                            : <div className={styles.collectionsLoader}><BrandLoader color='white' width={40} height={40} /></div>
                        }
                    </div>
                    {!salesByPlatformLoading ? 
                    <div className={styles.collectionDiagram} style={{}}>
                        <div style={{ width: '58%' }}>
                            <Typography className={styles.sortPlatformTitle}>Sort by platform</Typography>
                            <div className={styles.checkboxContainer}>
                                {countOfSalesByPlatform.map((item) => (
                                    <div key={item.marketplace} className={styles.checkboxPlatform}>
                                        <div style={{ width: '25px' }}>
                                            <BrandCheckbox checked={selectedMarketplaces.some(obj => obj['name'] === item.marketplace)} onClick={() => selectHandler(item.marketplace, item.value, item.color)} className='nftCheckbox' />
                                        </div>
                                        <div style={{ marginRight: '3%' }}>
                                            <Typography>{item.marketplace}</Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.marketpalcesList}>
                                <div className={styles.marketpalcesListHeading}>
                                    <Typography>Name</Typography>
                                    <Typography>Sales</Typography>
                                </div>
                                <div className={styles.horisontalLine} />
                                {selectedMarketplaces?.map((item) => (
                                    <div key={item.name} style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        marginTop: '10px'
                                    }}>
                                        <Typography>{item.name}</Typography>
                                        <Typography>{item.value}</Typography>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.doughnutContainer}>
                            <BrandDoughnutChartTypeTwo
                                width={'100%'}
                                height={'100%'}
                                hasLegend={false}
                                isLoading={isLoading}
                                data={selectedMarketplaces}
                                styles={styles}
                            />
                            <div className={styles.totalInAllMarketplaces}>
                                <Typography>Total</Typography>
                                <Typography>{selectedMarketplaces.reduce((acc,item) => {
                                    return acc + item.value
                                },0)}</Typography>
                            </div>
                        </div>
                    </div> :
                    <div className={styles.collectionsLoader}><BrandLoader color='white' width={40} height={40} /></div>
                    }
                </div>
                <div className={styles.collectionMarketplaces}>
                    <TopOwnersTable styles={styles} collectionName={collectionNameDecoded} />

                    {/* <div className={styles.collectionDistribution}>
                        <div>
                            <Typography>Owner distribution</Typography>
                        </div>
                        <div>
                            <div style={{ marginTop: '25px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
                                    <Typography className={styles.mainTypography}>80%</Typography>
                                    <Typography>1 item</Typography>
                                </div>
                                <div style={{ width: '90%', backgroundColor: '#313133', borderRadius: '25px' }}>
                                    <div className={styles.ratio} style={{ background: `linear-gradient(to right, ${colors})`, width: `${Math.round((850 / ownerDistributionData.totalOwners) * 100)}%` }}></div>
                                </div>
                            </div>
                            <div style={{ marginTop: '25px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
                                    <Typography>80%</Typography>
                                    <Typography>1 item</Typography>
                                </div>
                                <div style={{ width: '90%', backgroundColor: '#313133', borderRadius: '25px' }}>
                                    <div className={styles.ratio} style={{ background: `linear-gradient(to right, ${colors})`, width: `${Math.round((350 / ownerDistributionData.totalOwners) * 100)}%` }}></div>
                                </div>
                            </div>
                            <div style={{ marginTop: '25px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
                                    <Typography>80%</Typography>
                                    <Typography>1 item</Typography>
                                </div>
                                <div style={{ width: '90%', backgroundColor: '#313133', borderRadius: '25px' }}>
                                    <div className={styles.ratio} style={{ background: `linear-gradient(to right, ${colors})`, width: `${Math.round((50 / ownerDistributionData.totalOwners) * 100)}%` }}></div>
                                </div>
                            </div>
                            <div style={{ marginTop: '25px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
                                    <Typography>80%</Typography>
                                    <Typography>1 item</Typography>
                                </div>
                                <div style={{ width: '90%', backgroundColor: '#313133', borderRadius: '25px' }}>
                                    <div className={styles.ratio} style={{ background: `linear-gradient(to right, ${colors})`, width: `${Math.round((50 / ownerDistributionData.totalOwners) * 100)}%` }}></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div className={styles.collectionOwnersAndPriceCharts} style={{ marginBottom: '25px' }}>
                    <div className={styles.topOwners}>
                        Owners overtime
                        <BrandVerticalBarsChart 
                            isLoading={isLoading}
                            by=''
                            data={ownersOvertimeBarChartData}
                            tooltipLabel='Volume ETH'
                            color='none'
                            width='100%'
                            height='250px'
                            // customFormat=''
                            // currency='eth'
                        />
                    </div>
                    <div className={styles.collectionDistribution}>
                        Volume & Price
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default CollectionStats;