import { Box, Typography, makeStyles } from "@material-ui/core";
import monster from '../../../public/error-monster.png';

const useStyles = makeStyles(() => ({
    imageWrapper: {
        width: '200px',
        height: '400px'
    },
    monsterImage: {
        width: '100%',
        height: '100%'
    },
    errorMessage: {
        fontSize: '25px'
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: '20px',
        marginTop: '5%'
    }
}));

const PageNotFound = () => {
    const styles = useStyles();
    
    return (
        <Box className={styles.contentWrapper}>
            <Box className={styles.imageWrapper}>
                <Box 
                    className={styles.monsterImage}
                    component='img'
                    alt='Error Monster Image'
                    src={monster}
                />
            </Box>
            <Typography className={styles.errorMessage}>
                An error occurred. Come back later.
            </Typography>
        </Box>
    )
};

export default PageNotFound;