import React, { useEffect, useState } from 'react';
import { getData, deleteData } from '../utils/FetchUtils'
import BrandTable from '../CoreComponents/BrandTable'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TablePagination, Typography } from '@material-ui/core';
import { BrandModal, BrandDeleteModal } from '../CoreComponents/BrandModal'
import { makeStyles } from '@material-ui/core';
import { BrandInput } from '../CoreComponents/BrandInput';
import { BrandButton, TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton';
import { ButtonSize } from '../../constants/buttonConstants';

const useStyles = makeStyles(theme => ({
    text: {
        marginBottom: theme.spacing(8.5)
    },
    songsDataContainer: {
        '& .MuiTableCell-root': {
            padding: '16px'
        },
        '& .MuiTableCell-head': {
            padding: '16px'
        }
    },
    container: {
        "& label": {
            marginBottom: theme.spacing(3.5),
        }
    },
    actions: {
        display: 'flex',
        justifyContent: "flex-end",
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
        "& button": {
            width: '93px'
        }
    },
    inputGroup: {
        display: 'flex',
        "& label:first-child": {
            marginRight: theme.spacing(2),
        },
    }
}));

const EditModal = ({ rowData, onClose, onSubmit }) => {
    const styles = useStyles();
    const consumptionPeriod = useState(rowData.consumptionPeriod);
    const reportType = useState(rowData.reportType);
    const reportingPeriod = useState(rowData.reportingPeriod);
    const publishingOwnership = useState(rowData.publishingOwnership);
    const masterOwnership = useState(rowData.masterOwnership);
    const territory = useState(rowData.territory);
    const playSource = useState(rowData.playSource);
    const consumptionType = useState(rowData.consumptionType);
    const revenue = useState(rowData.revenue);
    const reportingCompanyId = useState(rowData.reportingCompanyId);

    async function submitEditing(e) {
        e.preventDefault();
        onClose();
        onSubmit();
    }

    return (
        <div className={styles.container}>
            <Typography
                variant="h5"
                style={{ paddingBottom: 20 }}
            >
                Edit Song
            </Typography>
            <form onSubmit={submitEditing}>
                <div className={styles.inputGroup}>
                    <BrandInput $value={consumptionPeriod} label='Consumption Period' />
                    <BrandInput $value={reportType} label='Report Type' />
                </div>
                <div className={styles.inputGroup}>
                    <BrandInput $value={reportingPeriod} label='Reporting Period' />
                    <BrandInput $value={publishingOwnership} label='Publishing Ownership' />
                </div>
                <div className={styles.inputGroup}>
                    <BrandInput $value={masterOwnership} label='Master Ownership' />
                    <BrandInput $value={territory} label='Territory' />
                </div>
                <div className={styles.inputGroup}>
                    <BrandInput $value={playSource} label='Play Source' />
                    <BrandInput $value={consumptionType} label='Consumption Type' />
                </div>
                <div className={styles.inputGroup}>
                    <BrandInput $value={reportingCompanyId} label='Reporting Company Id' />
                    <BrandInput $value={revenue} label='Revenue' />
                </div>
                <div className={styles.actions}>
                    <TempSecondaryBrandButton size={ButtonSize.SMALL} variant='outlined' onClick={onClose}>Cancel</TempSecondaryBrandButton>
                    <TempBrandButton size={ButtonSize.SMALL} type='submit'>Save</TempBrandButton>
                </div>
            </form>
        </div >
    );
}

const SongsData = ({ selectedSongRow }) => {
    const styles = useStyles();
    // const [headCells] = useState([
    //     {
    //         id: 'consumptionPeriod',
    //         label: 'Consumption Period',
    //         notSortable: true,
    //         CellRender: ({ cellData }) => {
    //             return (
    //                 <>
    //                     {cellData ?
    //                         `${cellData}`
    //                         :
    //                         <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
    //                     }
    //                 </>
    //             );
    //         }
    //     },
    //     {
    //         id: 'reportType',
    //         label: 'Report Type',
    //         notSortable: true,
    //         CellRender: ({ cellData }) => {
    //             return (
    //                 <>
    //                     {cellData ?
    //                         `${cellData}`
    //                         :
    //                         <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
    //                     }
    //                 </>
    //             );
    //         }
    //     },
    //     {
    //         id: 'reportingPeriod',
    //         label: 'Reporting Period',
    //         notSortable: true,
    //         CellRender: ({ cellData }) => {
    //             return (
    //                 <>
    //                     {cellData ?
    //                         `${cellData}`
    //                         :
    //                         <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
    //                     }
    //                 </>
    //             );
    //         }
    //     },
    //     {
    //         id: 'publishingOwnership',
    //         label: 'Publishing Ownership',
    //         notSortable: true,
    //         CellRender: ({ cellData }) => {
    //             return (
    //                 <>
    //                     {cellData ?
    //                         `${cellData}`
    //                         :
    //                         <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
    //                     }
    //                 </>
    //             );
    //         }
    //     },
    //     {
    //         id: 'masterOwnership',
    //         label: 'Master Ownership',
    //         notSortable: true,
    //         CellRender: ({ cellData }) => {
    //             return (
    //                 <>
    //                     {cellData ?
    //                         `${cellData}`
    //                         :
    //                         <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
    //                     }
    //                 </>
    //             );
    //         }
    //     },
    //     {
    //         id: 'territory',
    //         label: 'Territory',
    //         notSortable: true,
    //         CellRender: ({ cellData }) => {
    //             return (
    //                 <>
    //                     {cellData ?
    //                         `${cellData}`
    //                         :
    //                         <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
    //                     }
    //                 </>
    //             );
    //         }
    //     },
    //     {
    //         id: 'playSource',
    //         label: 'Play Source',
    //         notSortable: true,
    //         CellRender: ({ cellData }) => {
    //             return (
    //                 <>
    //                     {cellData ?
    //                         `${cellData}`
    //                         :
    //                         <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
    //                     }
    //                 </>
    //             );
    //         }
    //     },
    //     {
    //         id: 'consumptionType',
    //         label: 'Consumption Type',
    //         notSortable: true,
    //         CellRender: ({ cellData }) => {
    //             return (
    //                 <>
    //                     {cellData ?
    //                         `${cellData}`
    //                         :
    //                         <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
    //                     }
    //                 </>
    //             );
    //         }
    //     },
    //     {
    //         id: 'reportingCompanyId',
    //         notSortable: true,
    //         label: 'Reporting Company ID',
    //         CellRender: ({ cellData }) => {
    //             return (
    //                 <>
    //                     {cellData ?
    //                         `${cellData}`
    //                         :
    //                         <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
    //                     }
    //                 </>
    //             );
    //         }
    //     },
    //     {
    //         id: 'revenue',
    //         label: 'Revenue',
    //         notSortable: true,
    //         CellRender: ({ cellData }) => {
    //             return (
    //                 <>
    //                     {cellData ?
    //                         `${cellData}`
    //                         :
    //                         <FiberManualRecordIcon style={{ color: '#F96C00', fontSize: 10 }} />
    //                     }
    //                 </>
    //             );
    //         }
    //     },
    //     // {
    //     //     id: 'delete',
    //     //     label: '',
    //     //     hideHeadLabel: true,
    //     // notSortable: true,
    //     //     CellRender: ({ rowData }) => {
    //     //         return <IconButton style={{ color: 'white', fontSize: '0.875rem' }} onClick={() => onDeleteRowClick(rowData)}><DeleteOutlineOutlinedIcon fontSize='small' />Delete</IconButton>
    //     //     }
    //     // },
    //     // {
    //     //     id: 'edit',
    //     //     label: '',
    //     //     hideHeadLabel: true,
    //     // notSortable: true,
    //     //     CellRender: ({ rowData }) => {
    //     //         return <IconButton style={{ color: 'white', fontSize: '0.875rem' }} onClick={() => onEditRowClick(rowData)}><EditOutlinedIcon fontSize='small' />Edit</IconButton>
    //     //     }
    //     // }
    // ]);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [selectedSongDataRowSongID, setSelectedSongDataRowSongID] = useState(undefined);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState({});
    const [displayDeleteSongsDataModal, setDisplayDeleteSongsDataModal] = useState(false);
    const [selectedRowToEditData, setSelectedRowToEditData] = useState({});
    const [showEditSongModal, setShowEditSongModal] = useState(false);

    const [rowCounts, setRowCounts] = useState(0);
    const [rowsPerPageOptions] = useState([6, 12, 18]);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [offset, setOffset] = useState(0);

    function onEditRowClick(rowData) {
        setShowEditSongModal(true);
        setSelectedRowToEditData(rowData)
    }

    async function fetchSongsData() {
        if (selectedSongRow.id) {
            const fetchedData = await getData(process.env.REACT_APP_SERVER_HOST + `/api/song-data/?songId=${selectedSongRow.id}&offset=${offset}&limit=${rowsPerPage}`)
            setRowCounts(fetchedData.total)
            setRows(fetchedData.items)
        }
    }

    useEffect(() => {
        setOffset(page * 6)
    }, [page])



    useEffect(() => {
        if (selectedSongRow) {
            fetchSongsData();
        }
    }, [selectedSongRow, offset, rowsPerPage])

    function onRowClick(event, row) {
        setSelectedSongDataRowSongID(row);
    }

    function onDeleteRowClick(rowData) {
        setSelectedRowToDelete(rowData);
        setDisplayDeleteSongsDataModal(true);
    }

    const onDeleteSongsData = async () => {
        await deleteData(process.env.REACT_APP_SERVER_HOST + '/api/song-data/', selectedRowToDelete);
        setDisplayDeleteSongsDataModal(false)
        fetchSongsData()
    };

    return (
        <div>
            <BrandModal
                open={showEditSongModal}
                onClose={() => setShowEditSongModal(false)}
            >
                <EditModal
                    rowData={selectedRowToEditData}
                    onClose={() => setShowEditSongModal(false)}
                    onSubmit={() => fetchSongsData()}
                />
            </BrandModal>
            <BrandDeleteModal
                open={displayDeleteSongsDataModal}
                onClose={() => setDisplayDeleteSongsDataModal(false)}
                onDelete={() => onDeleteSongsData()}
            >
                <Typography
                    variant="h5"
                    style={{ paddingBottom: 20 }}
                >
                    Delete Song
                </Typography>
                <div>
                    Are you sure you want to delete the data of "{selectedSongRow.title}"?
                </div>
                <div className={styles.text}>
                    Once deleted you will not be able to restore the data.
                </div>
            </BrandDeleteModal>
            {/* {rows.length > 0 ?
                <div className={styles.songsDataContainer}>
                    <BrandTable
                        rows={rows}
                        headCells={headCells}
                        checkboxless={true}
                        page={page}
                        hidePagination={true}
                        rowIsClickable={true}
                        onRowClick={onRowClick}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rowCounts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                    />
                </div>
                : null} */}
        </div>
    );
}

export default SongsData;