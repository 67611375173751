import React, { useEffect, useRef, useState } from 'react';
import Spreadsheet from 'x-data-spreadsheet';

export const BrandCanvasTable = ({ options, styles, cols, rows, onChange, manualRefresh, manualRerender, ...props }) => {
    const [table, setTable] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        if (!table) {
            return;
        }

        table.options = options;
        (async () => {
            table.reRender();
            table.validate();
        })();
        // table.reRender();
        // table.validate();
    }, [table, options]);

    useEffect(() => {
        if (!ref || !ref.current || !options) {
            return;
        }

        if (!table) {
            const newTable = new Spreadsheet(ref.current, options);
            setTable(newTable);

            newTable
                .loadData({
                    styles: styles,
                    cols: cols ? { len: cols.length, ...cols } : { len: 0 },
                    rows: { len: rows.length, ...rows }
                })
                .change(cdata => onChange && onChange(table, cdata));
        } else if (manualRerender && manualRerender(table)) {
            // nothing to do...
            (async () => {
                table.reRender();
                table.validate();
            })();
            // table.reRender();
            // table.validate();
        } else {
            table
                .loadData({
                    styles: styles,
                    cols: cols ? { len: cols.length, ...cols } : { len: 0 },
                    rows: { len: rows.length, ...rows }
                })
                .change(cdata => onChange && onChange(table, cdata));
        }

        window.brandCanvasTable = table;
    }, [ref, styles, options, cols, rows, manualRefresh]);

    return (
        <div ref={ref} {...props}></div>
    )
}
