import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import { Fragment } from "react";
import { BrandButton, SecondaryBrandButton, TempBrandButton, TempBrandDeleteButton } from "../CoreComponents/BrandButton";
import { ButtonSize } from "../../constants/buttonConstants";
import AdditionalAccountHolders from "../Songs/AdditionalAccountHolders";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        background: theme.palette.background.darkNuanceVersion4,
        display: 'flex',
        flexDirection: 'row',
        padding: '17.5px 0px',
        marginBottom: '10px',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    infoContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '2px',
    },
    songTitleItem: {
        width: '15vw',
        wordWrap: 'break-word',
    },
    artistNameItem: {
        width: '8vw',
    },
    accountHolderItem:{
        width: '12vw',
    },
    mainContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '25px',
    },
    itemWraper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '40px'
    },
    emptySpace: {
        height: '56px',
        width: '56px'
    },
    icon: {
        color: 'white',
        fontSize: '32px'
    },
    mergedSongsCount: {
        color: theme.palette.text.greenNuanceVersion2
    },
    actionsWrapper: {
        marginRight: '25px',
        display: 'flex',
        flexDirection: 'row',
        columnGap: '50px',
        alignItems: 'center'
    },
    leftSideContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    buttonsWrapper: {
        width: '250px',
        display: 'flex',
        justifyContent: 'flex-end',
        columnGap: '25px',
        '& button': {
            width: '123px',
        }
    },
    test: {
        fontWeight: 500,
        fontSize: '18px',  
        lineHeight: '22px',  
        fontFamily: 'Roboto, sans-serif',
    },
    fontSizeA: {
        fontWeight: 500,
        fontSize: '16px',  
        lineHeight: '19px',  
        fontFamily: 'Roboto, sans-serif',
        '& .MuiTypography-root': {
            font: 'normal normal medium 16px/19px Roboto',
        },
    },
    fontSizeB: {
        font: 'normal normal normal 14px/21px Roboto',
    },
}));

const RecursiveSongView = ({ 
    object, 
    isOrphanNode = true, 
    toggleExpand, 
    expandedItems,
    setSelectedSong,
    setShowUnmergeModal,
    setShowMoveModal
}) => {
    const styles = useStyles();

    const handleUnmergeButtonClick = (song) => {
        setSelectedSong(song);
        setShowUnmergeModal(true);
    }

    const handleMoveButtonClick = (song) => {
        setSelectedSong(song);
        setShowMoveModal(true);
    }

    return (
        <Fragment>
            { object.map(({
                id,
                displayName,
                artist,
                mergedSongs,
                lastModifiedDate,
                parentId,
                parentTitle,
                parentArtist,
                accountHolders
            }) => (
                <Box className={styles.itemWraper} key={id}>
                    <Box className={styles.mainContainer}>
                        <Box className={styles.leftSideContentWrapper}>
                            { mergedSongs ? (
                                <IconButton onClick={() => toggleExpand(id)}>
                                    { expandedItems.includes(id) 
                                        ? <ExpandMore className={styles.icon} /> 
                                        : <ChevronRight className={styles.icon} /> 
                                    }
                                </IconButton>
                            ) : (
                                <Box className={styles.emptySpace} />
                            ) }
                            <Box className={styles.mainContentWrapper}>
                                <Box className={`${styles.infoContentWrapper} ${styles.songTitleItem}`}>
                                    <Typography className={styles.fontSizeB}>Song title</Typography>
                                    <Typography className={styles.test}>{displayName}</Typography>
                                </Box>
                                <Box className={`${styles.infoContentWrapper} ${styles.artistNameItem}`}>
                                    <Typography className={styles.fontSizeB}>Artist</Typography>
                                    <Typography className={styles.fontSizeA}>{artist}</Typography>
                                </Box>
                                <Box className={`${styles.infoContentWrapper} ${styles.accountHolderItem}`}>
                                    <Typography className={styles.fontSizeB}>Account Holder</Typography>
                                    <AdditionalAccountHolders 
                                        accountHolders={JSON.parse(accountHolders)} 
                                        showAccountHoldersText={false} 
                                        textStyle={styles.fontSizeA}
                                    />
                                </Box>
                                <Box className={styles.infoContentWrapper}>
                                    <Typography className={styles.fontSizeB}>Last modified date</Typography>
                                    <Typography className={styles.fontSizeA}>{lastModifiedDate}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={styles.actionsWrapper}>
                            { mergedSongs &&
                                <Typography className={styles.mergedSongsCount}>
                                    { mergedSongs.length === 1 
                                        ? `${mergedSongs.length} merged song` 
                                        : `${mergedSongs.length} merged songs` 
                                    }
                                </Typography> 
                            }
                            <Box className={styles.buttonsWrapper}>
                                <TempBrandButton 
                                    size={ButtonSize.SMALL}
                                    onClick={() => handleMoveButtonClick({ 
                                        id, 
                                        displayName, 
                                        artist, 
                                        parentId, 
                                        parentTitle, 
                                        parentArtist 
                                    })}
                                >
                                    Move
                                </TempBrandButton>
                                { !isOrphanNode && (
                                    <TempBrandDeleteButton 
                                        size={ButtonSize.SMALL}
                                        onClick={() => handleUnmergeButtonClick({ 
                                            id, 
                                            displayName, 
                                            artist, 
                                            parentId, 
                                            parentTitle, 
                                            parentArtist 
                                        })}
                                    >
                                        Unmerge    
                                    </TempBrandDeleteButton>
                                ) }
                            </Box>
                        </Box>
                    </Box>  

                    { expandedItems.includes(id) && mergedSongs && 
                        <RecursiveSongView 
                            object={mergedSongs} 
                            isOrphanNode={false} 
                            toggleExpand={toggleExpand} 
                            expandedItems={expandedItems} 
                            setSelectedSong={setSelectedSong}
                            setShowUnmergeModal={setShowUnmergeModal}
                            setShowMoveModal={setShowMoveModal}
                        /> 
                    }
                </Box>
            )) }
        </Fragment>
    )
}

export default RecursiveSongView;