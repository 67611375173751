import { Box, CircularProgress, Input, ListItemText, MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import { BrandInput } from "../CoreComponents/BrandInput";
import { useEffect, useState } from "react";
import { BrandButton, SecondaryBrandButton, TempBrandButton, TempSecondaryBrandButton } from "../CoreComponents/BrandButton";
import { reportNames } from "../utils/ReportNamesUtils";
import { postData, putData } from "../utils/FetchUtils";
import { textIsEmpty } from "../utils/ValidationUtils";
import { ButtonSize } from "../../constants/buttonConstants";



const useStyles = makeStyles((theme) => ({
    container: {
        width: '1000px',
    },
    mainHeader: {
        font: 'normal normal bold 25px Roboto'
    },
    addCompanyContainer: {
        display: 'flex',
        gap: '30px',
        marginTop: '40px',
        marginBottom: '40px'
    },
    input: {
        width: '100%',
    },
    inputRoot: {
        width: '100%'
    },
    dropdownMenu: {
        width: '100%',
        height: '40px',
        border: "1px solid grey",
        borderRadius: 24,
        '& .MuiSelect-root': {
            display: 'flex',
            marginLeft: '10px',
            width: '100%',
        },
        '& .MuiInputBase-root': {
            width: '98%',
            height: '40px',
            borderRadius: '28px',
        },
        '& .MuiInput-underline:after': {
            position: 'relative',
            zIndex: -1
        },
        '& .MuiInput-underline:before': {
            position: 'relative',
            zIndex: -1
        },
        '& .MuiSelect-icon': {
            color: 'white',
            "& path": {
                d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')" // your svg icon path here
            }
        }
    },
    selectContainer: {
        width: '100%'
    },
    selectLabel: {
        marginBottom: '8px'
    },
    cancelAndSaveBtnContainer: {
        textTransform: 'capitalize',
        display: 'flex',
        justifyContent: "flex-end",
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
        "& button": {
            width: '93px'
        },
        '& .MuiButton-outlinedPrimary:hover': {
            border: '1px solid #FFFFFFDE'
        }
    },
    alert: {
        color: theme.palette.primary.alert,
        marginTop: '20px'
    },
    successMsg: {
        color: theme.palette.primary.main,
        marginTop: '20px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
}))

const dataValidators = {
    isRequired: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    }
};

const CompanyActions = ({
    onClose,
    header,
    placeholder,
    defaultCompany,
    defaultCompanyName,
    kindOfModal,
    actionButtonText,
    rowId,
    setIsActionClicked,
    setAddCompanyModal,
    setEditCompanyModal
}) => {
    const ADD_MODAL_TEXT = 'add';

    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const newCompanyName = useState(defaultCompanyName);
    const dropDownValues = useState(reportNames);
    const [selectedCompany, setSelectedCompany] = useState(defaultCompany);

    const isDisabledEditModal = selectedCompany === defaultCompany && newCompanyName[0] === defaultCompanyName;
    const isDisabledAddModal = selectedCompany === defaultCompany || newCompanyName[0] === defaultCompanyName;
    const isDisabled = kindOfModal === ADD_MODAL_TEXT ? isDisabledAddModal : isDisabledEditModal;


    const handleChange = (event) => {
        setSelectedCompany(event.target.value);
    };

    const actionHandler = () => {
        const reportingType = reportNames.filter(company => company.name === selectedCompany)[0]?.id;

        const companyData = {
            companyName: newCompanyName[0],
            reportingType: reportingType,
            id: rowId
        }
        setLoading(true);

        kindOfModal === ADD_MODAL_TEXT ?
            postData(process.env.REACT_APP_SERVER_HOST + `/api/reporting-companies/add-reporting-company`, companyData)
                .then(res => {
                    setLoading(false);
                    setIsActionClicked(true);
                    setSuccessMsg('Successfully added!');
                    setAddCompanyModal(false);
                })
                .catch(err => {
                    console.log(err.message)
                    setAlert(err.message);
                    setLoading(false);
                })
            :
            putData(process.env.REACT_APP_SERVER_HOST + `/api/reporting-companies/update-reporting-company`, companyData)
                .then(res => {
                    setLoading(false);
                    setIsActionClicked(true);
                    setSuccessMsg('Changes applied successfully!');
                    setEditCompanyModal(false);
                })
                .catch(err => {
                    console.log(err.message)
                    setAlert(err.message);
                    setLoading(false);
                })
    }

    useEffect(() => {
        setTimeout(() => {
            setAlert('');
            setSuccessMsg('');
        }, 5000);
    }, [alert, successMsg]);

    return (
        <div className={styles.container}>
            <Typography
                variant="h6"
                className={styles.mainHeader}
            >
                {header}
            </Typography>

            {
                loading ?
                    <Box className={styles.loadingContainer} >
                        <CircularProgress />
                    </Box >
                    :
                    <>
                        {
                            alert ?
                                <div className={styles.alert}>{alert}</div>
                                :
                                ''
                        }
                        {
                            successMsg ?
                                <div className={styles.successMsg}>{successMsg}</div>
                                :
                                ''
                        }
                    </>

            }
            <div className={styles.addCompanyContainer}>
                <BrandInput
                    label='Name'
                    placeholder={placeholder}
                    $value={newCompanyName}
                    classes={{ input: styles.input, root: styles.inputRoot }}
                    validator={dataValidators.isRequired}
                />
                <div className={styles.selectContainer}>
                    <div className={styles.selectLabel}>Type</div>
                    <div className={styles.dropdownMenu}>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            value={selectedCompany}
                            onChange={handleChange}
                            input={<Input />}
                            displayEmpty={true}
                        >
                            {
                                kindOfModal ?
                                    <MenuItem disabled={true} value={defaultCompany}>
                                        <ListItemText primary={defaultCompany} />
                                    </MenuItem>
                                    :
                                    ''
                            }
                            {dropDownValues[0].map((company) => (
                                <MenuItem key={company.name} value={company.name}>
                                    <ListItemText primary={company.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
            <div className={styles.cancelAndSaveBtnContainer}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    variant='outlined'
                    onClick={onClose}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton
                    size={ButtonSize.SMALL}
                    onClick={actionHandler}
                    disabled={isDisabled}
                >
                    {actionButtonText}
                </TempBrandButton>
            </div>
        </div>
    )

}

export default CompanyActions;