export const reportNames = [
    { name: 'PRO Revenue', id: 1 },
    { name: 'Master Performance Revenue', id: 2 },
    { name: 'Publisher / Admin Revenue', id: 3 },
    { name: 'Label / Distributor Revenue', id: 4 },
    // { name: 'Master Performance Revenue', id: 5 }
].sort((a, b) => a.name > b.name ? 1 : -1)

export const REPORT_TYPE_ID_BY_NAME = Object.freeze({
    'PRO Revenue': 1,
    'Master Performance Revenue': 2,
    'Publisher / Admin Revenue': 3,
    'Label / Distributor Revenue': 4,
});
