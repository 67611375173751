import { Box, makeStyles } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
    datePicker: {
        width: '310px',
        '& .MuiButtonBase-root, & .MuiPickersStaticWrapper-staticWrapperRoot': {
            backgroundColor: 'transparent',
            color: 'white'
        },
        '& .MuiPickersDay-dayDisabled': {
            color: 'grey'
        }
    }
}));

const Calendar = ({ date, setDate, minDate, maxDate, setSelectedRange }) => {
    const styles = useStyles();

    const handleDateChange = (value) => {
        setDate(value);
        setSelectedRange?.('Custom')
    }

    return (
        <Box className={styles.datePicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    value={date}
                    onChange={handleDateChange}
                    variant='static'
                    disableToolbar
                    format='yyyy-MM-dd'
                    margin='normal'
                />
            </MuiPickersUtilsProvider>
        </Box>
    )
}

export default Calendar;