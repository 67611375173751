import React, { useRef, useState } from 'react'
import clsx from 'clsx';
import { Box, ClickAwayListener, Grow, List, ListItem, ListItemText, Paper, Popper, IconButton, makeStyles } from '@material-ui/core';
import { TempBrandButton } from '../../CoreComponents/BrandButton';
import { useStoreContext } from '../../../store/Store';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { userAccessByType } from '../../utils/AccessUtils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import royaltyStatementStatus from '../../../constants/royalty-statement-status';

const useStyles = makeStyles((theme) => ({
    actionsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '15px',
        height: '100%'
    },
    popper: {
        zIndex: 1,
        border: `1px solid ${theme.palette.primary.border}`,
        borderRadius: '10px',
        marginTop: '10px',
    },
    menuItem: {
        padding: '8px',
        font: 'normal normal medium 14px/21px Roboto',
        letterSpacing: '0px',
        textAlign: 'left',
        width: '150px',
        '& > :first-child': {
            marginRight: theme.spacing(1),
        },
    },
    iconButton: {
        color: theme.palette.text.primary,
        padding: 0
    },
    publishBtn: {
        height: '30px'
    },
    redIcon: {
        color: theme.palette.primary.delete
    }
}));

const DELETEABLE_STATUSES = [royaltyStatementStatus.Pending, royaltyStatementStatus.Archived];

const ActionsRowMenu = ({
    rowData,
    handleDownload,
    onDelete,
    onInvalidate,
    onPublish
}) => {
    const styles = useStyles();

    const [state] = useStoreContext();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleMenuToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleMenuClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return;
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    if (state.user.userType === userAccessByType.USER_TYPE_USER_ACCESS) {
        return (
            <IconButton
                className={styles.iconButton}
                onClick={() => handleDownload(rowData?.id)}
            >
                <FileDownloadIcon />
                Download
            </IconButton>
        );
    }

    return (
        <Box className={styles.actionsWrapper}>
            {rowData?.status === royaltyStatementStatus.Pending &&
                <TempBrandButton
                    className={styles.publishBtn}
                    onClick={onPublish}
                >
                    Publish
                </TempBrandButton>
            }
            <PendingOutlinedIcon
                onClick={handleMenuToggle}
                ref={anchorRef}
            />
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                className={styles.popper}
                placement="bottom-start"
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleMenuClose}>
                                <List
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <ListItem
                                        className={styles.menuItem}
                                        onClick={(event) => { handleDownload(rowData?.id); handleMenuClose(event); }}
                                    >
                                        <FileDownloadIcon />
                                        <ListItemText>Download</ListItemText>
                                    </ListItem>
                                    {DELETEABLE_STATUSES.includes(rowData?.status) &&
                                        <ListItem
                                            className={clsx(styles.menuItem, styles.redIcon)}
                                            onClick={(event) => { onDelete(); handleMenuClose(event); }}
                                        >
                                            <DeleteOutlineIcon />
                                            <ListItemText>Delete</ListItemText>
                                        </ListItem>
                                    }
                                    {rowData?.status === royaltyStatementStatus.Published &&
                                        <ListItem
                                            className={clsx(styles.menuItem, styles.redIcon)}
                                            onClick={(event) => { onInvalidate(); handleMenuClose(event); }}
                                        >
                                            <ClearIcon />
                                            <ListItemText>Invalidate</ListItemText>
                                        </ListItem>
                                    }
                                    {/* <ListItem
                                        className={styles.menuItem}
                                        onClick={(event) => {
                                            handleMenuClose(event);
                                        }}
                                    >
                                        <ListItemText className={styles.deleteText}>Notes</ListItemText>
                                    </ListItem> */}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}

export default ActionsRowMenu;