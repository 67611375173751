import { makeStyles } from "@material-ui/core";
import { useStoreContext } from "../../store/Store";
import CollapsibleTable from "./ReviewPageCollapsibleTable";

const useStyles = makeStyles((theme) => ({

}));

function MissingData({ data, fetchData }) {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    return (
        state.user.userType === 1 ?
            <CollapsibleTable
                rows={data}
                cellTitles={['Song Title', 'Artist', 'Revenue','']}
                collapseCellTitles={['Community', 'Song Status', 'Missing Revenue Period', 'Comment from User', 'Submitted By', 'Date Submitted']}
                collapseData={data}
                fetchData={fetchData}
            />
            :
            <CollapsibleTable
                rows={data}
                cellTitles={['Song Title', 'Artist', 'Revenue', '']}
                collapseCellTitles={['Community', 'Song Status', 'Missing Revenue Period', 'System Status Update']}
                collapseData={data}
            />
    );
};

export default MissingData;