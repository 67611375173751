import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStoreContext } from '../../store/Store';
import SongsList from './SongsList';
import { BrandModal } from '../CoreComponents/BrandModal';
import { TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton';
import { Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { MatchingLevels } from '../../constants/song-matching-levels-constants';
import SongsListFilter from './SongsListFilter';
import { ButtonSize } from '../../constants/buttonConstants';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '30px',
    },
    accountHolderSubTitle: {
        fontSize: '14px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    actions: {
        display: 'flex',
        justifyContent: "flex-end",
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
        '& button:': {
            width: '100px',
        }
    },
    modalTitle: {
        marginBottom: theme.spacing(2)
    },
    modalWarningText: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(5),
        color: theme.palette.primary.alert
    },
    filterWrapper: {
        position: 'absolute',
        right: '2.5%'
    },
}));

export default function SongsPartialMatching({
    partialSongsMapKeys,
    setExternalISRCs,
    setExternalUPCs,
    accountHolder,
    showMatchingWarning,
    setShowMatchingWarning,
    handleNext
}) {
    const [state, setState] = useStoreContext();
    const songsFilter = {
        unmatchedOption: useState(true),
        partialMatchOption: useState(true),
        matchedWithSuggestionsOption: useState(true),
        manuallyMatchedOption: useState(true)
    };

    const styles = useStyles();

    const setExternalData = (setExternalFunction, value, foundSongId) => {
        if (!value) return;

        setExternalFunction((prev) => ({
            ...prev,
            [foundSongId]: [...(prev[foundSongId] || []), value]
        }));
    }

    const onSelectedSong = (song, songMapKey) => {
        const actualSong = state.partialSongsMap[songMapKey];

        setExternalData(setExternalISRCs, actualSong.isrc, song.foundSongId);
        setExternalData(setExternalUPCs, actualSong.upc, song.foundSongId);

        const tempSongsMap = state.partialSongsMap;
        tempSongsMap[songMapKey] = {
            ...tempSongsMap[songMapKey],
            ...song
        }

        setState(state => (state.partialSongsMap = { ...tempSongsMap }, { ...state }));
    };

    const getWarningMessage = () => {
        const songValues = Object.values(state.partialSongsMap);
        const unmatchedSongs = songValues.some(s => !s.foundSongId);
        const partiallyMatchedSongs = songValues.some(s => s.matchingLevel === MatchingLevels.PARTIAL_MATCH);
        if (unmatchedSongs && partiallyMatchedSongs)
            return 'You have unmatched songs and ones with partial matching.';
        else if (unmatchedSongs)
            return 'You have unmatched songs.';
        else
            return `You have songs with partial matching.`;
    }

    return (
        <div>
            <BrandModal
                open={showMatchingWarning}
                onClose={() => setShowMatchingWarning(false)}
            >
                <div>
                    <Typography
                        variant="h5"
                        className={styles.modalTitle}
                    >
                        Warning
                    </Typography>

                    <p>{getWarningMessage()}</p>
                    <p>Are you sure you want to proceed?</p>

                    <div className={styles.modalWarningText}><ReportProblemOutlinedIcon /> Your songs might be incorrectly matched.</div>

                    <div className={styles.actions}>
                        <TempSecondaryBrandButton
                            size={ButtonSize.SMALL}
                            onClick={() => setShowMatchingWarning(false)}
                        >
                            Cancel
                        </TempSecondaryBrandButton>
                        <TempBrandButton
                            size={ButtonSize.SMALL}
                            onClick={() => {
                                handleNext();
                                setShowMatchingWarning(false);
                            }}
                        >
                            Continue
                        </TempBrandButton>
                    </div>
                </div>
            </BrandModal>

            <div className={styles.title}>5. Songs - Partial Matching</div>
            <div className={styles.accountHolderSubTitle}>Current Account Holder: {accountHolder.name}</div>
            <div className={styles.filterWrapper}>
                <SongsListFilter songsFilter={songsFilter}/>
            </div>
            <SongsList
                songsMapKeys={partialSongsMapKeys}
                onSelectedSong={onSelectedSong}
                setExternalISRCs={setExternalISRCs}
                setExternalUPCs={setExternalUPCs}
                accountHolder={accountHolder}
                songsFilter={songsFilter}
            />
        </div>
    );
}