import React, { useState, useEffect } from 'react';
import { useQuery } from "../../utils/RouteUtils";
import { useHistory } from 'react-router-dom'
import { useStoreContext } from "../../../store/Store";
import LogoPDF from './LogoPDF.js';
import {
    Document,
    Page,
    Text,
    View,
    Font,
    StyleSheet,
    PDFViewer,
    usePDF,
    BlobProvider
} from "@react-pdf/renderer";
import { getData } from '../../utils/FetchUtils';
import { BrandLoader } from '../../CoreComponents/BrandLoader';
import { makeStyles } from '@material-ui/core';
import ReportPDF from './ReportPDF';
import { RenderedPDFViewer } from './RenderedPDFViewer';
import {stylesPDF} from './../../../assets/css/stylesPDF.js'

const useNftReportStyles = makeStyles(theme => ({
    viewer: {
        width: window.innerWidth,
        height: window.innerHeight,
        overflow:'hidden'
    },
    collectionsLoader: {
        display: 'flex',
        width: '100%',
        height:'100%',
        justifyContent: 'center',
        alignItems: 'center',
        position:'absolute',
        background:'#525659'
    }
}))

const NftReport = () => {
    const reportStyles = useNftReportStyles()
    const [pdfData, setPdfData] = useState(null);

    const { token } = useQuery()
    const history = useHistory();
    const [state, setState] = useStoreContext();


    useEffect(() => {
        if (!token) {
            history.push('/');
        }

        getData(process.env.REACT_APP_SERVER_HOST + `/api/nft/nft-report?token=${token}`)
            .then(data => {
                setPdfData(data)
            });

        setState(state => (state.hideNavigation = true, state.disableRootPadding = true, state.hidePageProps = true, { ...state }));
        return () => { setState(state => (state.hideNavigation = false, state.disableRootPadding = false, state.hidePageProps = false, { ...state })) }
    }, [])

    return (
        <div>
            {!pdfData ? 
            <div className={reportStyles.collectionsLoader}><BrandLoader color='white' width={50} height={50} /></div> 
            : 
            <div className={reportStyles.viewer}>
                {/* <PDFViewer style={styles.viewer}>
                    <ReportPDF pdfData={pdfData} styles={styles}/>
                </PDFViewer> */}
                <RenderedPDFViewer
                    pdfData={pdfData} styles={stylesPDF} reportStyles={reportStyles}
                />

            </div>}
        </div>
    );
}


export default NftReport;
