import React, { useState, useEffect, useMemo } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce, getData } from '../utils/FetchUtils'
import { ListItemText } from '@material-ui/core';
import { BrandInput } from '../CoreComponents/BrandInput';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useStoreContext } from '../../store/Store';
import { useDebounce } from '../../hooks/useDebounce';

const useStyles = makeStyles(theme => ({
    autocomplete: {
        "& .MuiAutocomplete-clearIndicatorDirty": {
            display: 'none'
        },
        "& .MuiAutocomplete-popupIndicator": {
            display: 'none'
        },
    }
}));

export const SongsAutoComplete = () => {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [accountHolders, setAccountHolders] = useState([]);

    const songName = useDebounce(inputValue, 500);

    const accountHolderIds = state.selectedAccountholderPortfolio === 0 ? accountHolders : [state.selectedAccountholderPortfolio];

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/my`)
        .then(data => {
            const accountHoldersIds = data.map((holder) => holder.id);
            setAccountHolders(accountHoldersIds)
        });
    }, []);
    
    useEffect(() => {
        setOpen(inputValue.length > 0);
        (async () => {
            if (!inputValue || !isLoading) return;
            const encodedSongSearch = encodeURIComponent(songName || '');
            const accountHoldersStringifyArray = encodeURIComponent(JSON.stringify(accountHolderIds));
            const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/song/search-song-filter?song=${encodedSongSearch}&accHolderId=${accountHoldersStringifyArray}`);
            setIsLoading(false);
            setOptions(data);
        })();
    }, [songName]);

    return (
        <Autocomplete
            className={styles.autocomplete}
            open={open}
            onClose={() => {
                setOpen(false);
                setOptions([]);
            }}
            value={value}
            onChange={(e, newValue) => {
                setValue('');
                setState(state => (state.selectedSongPortfolioHeadFilter = newValue ? newValue?.displayName : '', { ...state }));
                setInputValue('');
                setOpen(false);
            }}
            onInputChange={(e, newInputValue) => {
                setInputValue(newInputValue)
                newInputValue ? setIsLoading(true) : setIsLoading(false)
            }}
            blurOnSelect={true}
            clearOnBlur={true}
            clearOnEscape={true}
            getOptionLabel={(option) => option?.displayName || ''}
            getOptionSelected={() => false}
            renderOption={(songs, option) => {
                return (
                    <div key={songs?.displayName}>
                        <ListItemText primary={songs?.displayName} />
                    </div>
                )
            }}
            options={options}
            loading={isLoading}
            renderInput={(params) => (
                <BrandInput
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}