import { Box, Button, Popover, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useState } from "react";
import HiddenSongsAutocomplete from "./HiddenSongsAutocomplete";

const useStyles = makeStyles((theme) => ({
        togglePopoverButton: {
            padding: '2.5px 20px',
            borderRadius: theme.spacing(2.5),
            border: '1px solid gray',
            width: '270px',
            textTransform: 'capitalize',
            maxWidth: '270px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&.Mui-disabled': {
                color: 'grey'
            }
        },
        expandMoreIcon: {
            marginLeft: 'auto',
            marginRight: '-10px'
        },
    textInputField: {
        fontSize: '14px'
    }
}));

const SongsAutocomplete = ({ 
    songId,
    selectedOption, 
    setSelectedOption 
}) => {
    const MAX_OPTION_LENGTH = 25;

    const [anchorEl, setAnchorEl] = useState(null);
    
    const styles = useStyles();

    const handleOpenPopover = (e) => setAnchorEl(e.currentTarget); 
    const handleClosePopover = () => setAnchorEl(null);

    const handleButtonClick = (song) => {
        setSelectedOption(song);
        setAnchorEl(null);
    }
    
    return (
        <Box>
            <Button 
                className={styles.togglePopoverButton} 
                onClick={handleOpenPopover}
            >
                { selectedOption && selectedOption.artist && selectedOption.displayName 
                    ? (selectedOption.artist + ' - ' + selectedOption.displayName).slice(0, MAX_OPTION_LENGTH) 
                    : '' 
                }
                <ExpandMore fontSize='large' className={styles.expandMoreIcon} />
            </Button>
            <Popover 
                open={!!anchorEl} 
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{ 
                    vertical: 'bottom', 
                    horizontal: 'left' 
                }}
            >
                <HiddenSongsAutocomplete 
                    songId={songId} 
                    handleButtonClick={handleButtonClick} 
                />
            </Popover>
        </Box>
    )
}

export default SongsAutocomplete;