import React, { useEffect, useState } from 'react';
import BrandTable from '../CoreComponents/BrandTable'
import { getData, postData, deleteData } from '../utils/FetchUtils'
import { BrandButton, SecondaryBrandButton, TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton'
import { BrandInput, BrandSearchInput } from '../CoreComponents/BrandInput'
import { PersonAdd } from '@material-ui/icons';
import { IconButton, Typography, makeStyles, TablePagination, Box, Chip, CircularProgress } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { BrandModal, BrandDeleteModal } from '../CoreComponents/BrandModal'
import { BrandAlert } from '../CoreComponents/BrandAlert'
import { EditUser } from './EditUser'
import { AccountHolderAutoComplete } from '../ManageAccountHolders/AccountHolderAutoComplete';
import { textIsEmpty, textIsEmail } from '../utils/ValidationUtils';
import { useDebounce } from '../../hooks/useDebounce';
import { tableRowsPerClientViewHeight } from '../Portfolio/SongPortfolio';
import { useStoreContext } from '../../store/Store';
import { useAsyncAction } from '../../hooks/useAsyncAction';
import TablePaginationActions from '../CoreComponents/TablePaginationActions';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { MANAGE_USERS_DEFAULT_ROWS_PER_PAGE, MANAGE_USERS_ROWS_PER_PAGE_OPTIONS, handleRowsPerPageChange } from '../utils/PaginationUtils';
import { ButtonSize } from '../../constants/buttonConstants';
import CancelIcon from '@mui/icons-material/Cancel';

const useManageUsersStyles = makeStyles(theme => ({
    input: {
        width: theme.spacing(55.625),
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        textAlign: 'center',
        margin: 'auto auto 16px auto',
    },
    leftSide: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        '&>*': {
            marginTop: theme.spacing(2),
        },
        "& .MuiButtonBase-root": {
            color: 'black'
        },
    },
    text: {
        marginBottom: theme.spacing(8.5)
    },
    container: {
        "& .MuiTablePagination-root": {
            '& .MuiTablePagination-selectRoot': {
                "& .MuiSvgIcon-root": {
                    color: 'white'
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: '3px 16px'
        },
        '& .MuiTableCell-head': {
            padding: '16px'
        }
    },
    inviteUserActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        '& .MuiButton-outlinedPrimary:hover': {
            border: `1px solid ${theme.palette.text.lightYellow}`
        },
        "& button": {
            width: '95px'
        },
    },
    inviteUserContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        minWidth: '20vw'
    },
    usersMainTitle: {
        font: 'normal normal bold 25px Roboto'
    },
    cancelButton: {
        color: theme.palette.text.lightYellow,
        borderColor: theme.palette.text.lightYellow,
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        font: 'normal normal normal 14px Roboto',
        textTransform: 'none'
    },
    inviteButton: {
        width: theme.spacing(11.25),
        height: theme.spacing(4),
        color: theme.palette.text.dark,
        font: 'normal normal normal 14px Roboto',
        textTransform: 'none'
    },
    deleteButton: {
        display: 'flex',
        justifyContent: 'end'
    },
    popover: {
        // pointerEvents: 'unset',
    },
    paper: {
        padding: theme.spacing(1),
    },
    accountHolderAutocompleteWrapper: {
        '& .MuiChip-colorPrimary': {
            color: theme.palette.text.black,
        },
    }
}));


const dataValidators = {
    validateEmail: (value) => {
        const errors = []
        if (!textIsEmail(value)) {
            errors.push('Invalid mail.')
        }
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    }
}


const InviteUser = ({ 
    onSuccessfulSubmit, 
    onClose,
}) => {
    const styles = useManageUsersStyles();

    const email = useState('');
    const emailPassed = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [error, setError] = useState('')
    const [accountHolders, setAccountHolders] = useState([]);

    const submission = (e) => {
        e.preventDefault();
        const accountHoldersToServer = [];

        for (let i = 0; i < accountHolders.length; i++) {
            accountHoldersToServer.push({ id: accountHolders[i].id })
        }

        const user = {
            email: email[0],
            accountHolders: accountHoldersToServer
        }

        postData(process.env.REACT_APP_SERVER_HOST + '/api/user/send-invite', user)
            .then(newToken => {
                onSuccessfulSubmit();
            }, error => {
                setError(error.message);
            })
    }

    useEffect(() => {
        if (!emailPassed[0]) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [emailPassed[0]])

    useEffect(() => {
        setError('');
    }, [email[0]])

    return (
        <div className={styles.inviteUserContainer}>
            <form className={styles.inviteUserContainer} onSubmit={submission}>
                <Typography variant="h6" component="div"  >
                    Invite User
                </Typography>
                {error ?
                    <BrandAlert>
                        {error}
                    </BrandAlert>
                    : null}
                <BrandInput $value={email} validator={dataValidators.validateEmail} validatorPassed={emailPassed} required label='Email' />
                <div className={styles.accountHolderAutocompleteWrapper}>
                    <AccountHolderAutoComplete
                        label="Linked Account Holders (optional)"
                        onSelectedAccountHolder={accountHolder => {
                            for (let i = 0; i < accountHolders.length; i++) {
                                if (accountHolders[i].id === accountHolder.id) {
                                    return;
                                }
                            }
                            setAccountHolders([...accountHolders, accountHolder])
                        }}
                    />
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5, marginBottom: 40 }}>
                        {accountHolders.map((value, i) => {
                            return (
                                <Chip 
                                    color='primary'
                                    key={value.id + value.name + i}
                                    label={value.name}
                                    clickable
                                    deleteIcon={
                                        <CancelIcon
                                            sx={{color: 'black'}}
                                            onMouseDown={(e) => e.stopPropagation()}
                                        />
                                    }
                                    onDelete={() => {
                                        setAccountHolders(accountHolders.filter(chip => chip !== value));
                                    }}
                                />
                            )
                        })}
                    </Box>
                </div>
                <div className={styles.inviteUserActions}>
                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        variant='outlined'
                        onClick={() => onClose()}
                    >
                        Cancel
                    </TempSecondaryBrandButton>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        disabled={submitDisabled}
                        type='submit'
                    >
                        Invite
                    </TempBrandButton>
                </div>
            </form>
        </div>
    );
}

const ManageUsers = () => {
    const styles = useManageUsersStyles();
    const [displayInviteUserModal, setDisplayInviteUserModal] = useState(false);

    const [search, setSearch] = useState('');
    const searchQuery = useDebounce(search, 500);

    const [rows, setRows] = useState([]);
    const [sortBy, setSortBy] = useState('id');
    const [sortType, setSortType] = useState('ASC');
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState(MANAGE_USERS_ROWS_PER_PAGE_OPTIONS);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [rowCounts, setRowCounts] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(MANAGE_USERS_DEFAULT_ROWS_PER_PAGE);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [selectedRowDataForDelete, setSelectedRowDataForDelete] = useState({});
    const [displayDeleteUserModal, setDisplayDeleteUserModal] = useState(false);
    const [displayEditUserModal, setDisplayEditUserModal] = useState(false);
    const [popoverState, setPopoverState] = useState(false)
    const [state, setState] = useStoreContext();
    const [sendInviteStatus, setSendInviteStatus] = useState(false);
    const [sendInviteStatusMessage, setSendInviteStatusMessage] = useState('');

    const [headCells] = useState([
        {
            id: 'firstName',
            label: 'First Name',
        },
        {
            id: 'lastName',
            label: 'Last Name',
        },
        {
            id: 'accountHolders',
            label: 'Account Holder(s)',
            notSortable: true,
            CellRender: ({ rowData }) => {
                const parsedAccountHolders = rowData.accountHolders;

                const [anchorEl, setAnchorEl] = useState(null);

                const handlePopoverOpen = (event) => {
                    if (popoverState) {
                        setAnchorEl(null);
                        setPopoverState(false)
                    } else {
                        setAnchorEl(event.currentTarget);
                        setPopoverState(true)
                    }

                };

                const handlePopoverClose = () => {
                    setAnchorEl(null);
                };

                const open = Boolean(anchorEl);

                if (!parsedAccountHolders || parsedAccountHolders.length === 0) {
                    return "N/A";
                } else if (parsedAccountHolders.length === 1) {
                    return parsedAccountHolders[0].name;
                }

                return (
                    <div>
                        <div
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onClick={handlePopoverOpen}
                        >
                            {`${parsedAccountHolders[0].name}; +${parsedAccountHolders.length - 1}`}
                        </div>
                        <Popover
                            id="mouse-over-popover"
                            className={styles.popover}
                            classes={{
                                paper: styles.paper,
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            {
                                parsedAccountHolders.map(x => {
                                    return (
                                        <Typography key={x.id} color='primary'>{x.name}</Typography>
                                    )
                                })
                            }
                        </Popover>
                    </div>
                );
            }
        },
        {
            id: 'email',
            label: 'Email',
        },
        {
            id: 'userType',
            label: 'Role',
            CellRender: ({ rowData }) => {
                return rowData.userType === 1 ? 'Admin' : 'User'
            }
        },
        {
            id: 'agreedToTerms',
            label: 'Status',
            CellRender: ({ rowData }) => {
                return rowData.agreedToTerms === 1 ? "Active" : "Pending"
            }
        },
        {
            id: 'delete',
            label: '',
            hideHeadLabel: true,
            CellRender: ({ rowData }) => {
                return (
                    <div className={styles.deleteButton}>
                        <IconButton
                            style={{ color: 'white', fontSize: '0.875rem' }}
                            onClick={() => {
                                setSelectedRowDataForDelete(rowData);
                                setDisplayDeleteUserModal(true);
                            }}>
                            <DeleteOutlineOutlinedIcon fontSize='small' />
                            Delete
                        </IconButton>
                    </div>
                )
            }
        },
        {
            id: 'edit',
            label: '',
            hideHeadLabel: true,
            CellRender: ({ rowData }) => {
                return <IconButton
                    style={{ color: 'white', fontSize: '0.875rem' }}
                    onClick={() => {
                        setSelectedRowData(rowData);
                        setDisplayEditUserModal(true);
                    }}>
                    <EditOutlinedIcon fontSize='small' />Edit
                </IconButton>
            }
        }
    ]);

    useEffect(() => {
        tableRowsPerClientViewHeight(setRowsPerPage, setRowsPerPageOptions, rowsPerPageOptions, 200)
    }, []);

    const { trigger, loading } = useAsyncAction(async () => {
        const fetchedData = await getData(process.env.REACT_APP_SERVER_HOST + `/api/user/?filter=${searchQuery}&offset=${offset}&limit=${rowsPerPage}&sort=${sortBy}&type=${sortType}`)
        setRowCounts(fetchedData.length);
        setRows(fetchedData.items);
    });

    const onDeleteUser = async () => {
        await deleteData(process.env.REACT_APP_SERVER_HOST + '/api/user/', selectedRowDataForDelete);
        setDisplayDeleteUserModal(false);
        trigger();
    };

    useEffect(() => {
        setOffset(page * rowsPerPage);
    }, [page]);

    useEffect(() => {
        setPage(0)
    }, [searchQuery]);

    useEffect(() => {
        if (rowsPerPage !== 0) {
            trigger();
        }
    }, [offset, rowsPerPage, sortType, sortBy, searchQuery]);

    useEffect(() => {
        if(sendInviteStatus){
            const timer = setTimeout(()=> {
                setSendInviteStatus(false);
                setSendInviteStatusMessage('');
            }, 2500);
            return () => clearTimeout(timer);
        }
    },[sendInviteStatus]);

    const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSendInviteStatus(false);
        setSendInviteStatusMessage('');
    };

    return (
        <div className={styles.container}>
            <Snackbar 
                open={sendInviteStatus}
            >
                <Alert 
                    onClose={handleCloseAlert}
                    severity={'success'}
                >
                    {sendInviteStatusMessage}
                </Alert>
            </Snackbar>
            <BrandModal
                open={displayInviteUserModal}
                onClose={() => setDisplayInviteUserModal(false)}
            >
                <InviteUser
                    onSuccessfulSubmit={() => {
                        setDisplayInviteUserModal(false);
                        setSendInviteStatusMessage('User invited successfully!');
                        setSendInviteStatus(true);
                        trigger();
                    }}
                    onClose={() => setDisplayInviteUserModal(false)} 
                />
            </BrandModal>
            <BrandModal
                open={displayEditUserModal}
                onClose={() => setDisplayEditUserModal(false)}
            >
                <EditUser
                    rowData={selectedRowData}
                    editAsAdmin={true}
                    onSubmit={() => {
                        setDisplayEditUserModal(false);
                        trigger();
                    }}
                    onClose={() => setDisplayEditUserModal(false)}
                />
            </BrandModal>
            <BrandDeleteModal
                open={displayDeleteUserModal}
                onClose={() => setDisplayDeleteUserModal(false)}
                onDelete={() => onDeleteUser()}
            >
                <Typography
                    variant="h5"
                    style={{ paddingBottom: 20 }}
                >
                    Delete User
                </Typography>
                <div className={styles.text}>
                    Are you sure you want to delete "{selectedRowDataForDelete.email}"?
                </div>
            </BrandDeleteModal>
            <Typography
                variant="h6"
                className={styles.usersMainTitle}
            >
                Users
            </Typography>
            <div className={styles.toolbar}>
                <div className={styles.leftSide}>
                    <BrandSearchInput
                        placeholder='Search for user'
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        classes={{ input: styles.input }}
                    />
                    <TempBrandButton
                        capitalize={true}
                        startIcon={<PersonAdd />}
                        onClick={() => setDisplayInviteUserModal(true)}
                    >
                        Invite User
                    </TempBrandButton>
                </div>
            </div>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <BrandTable
                        rows={rows}
                        headCells={headCells}
                        checkboxless={true}
                        page={page}
                        hidePagination={true}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rowCounts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event, offset, setRowsPerPage, setPage, setOffset)}
                        ActionsComponent={TablePaginationActions}
                    />
                </>
            )}
        </div>
    );
}

export default ManageUsers;