import { Typography, makeStyles } from "@material-ui/core"
import { Link } from "react-router-dom";
import recordLogo from '../../assets/recordLogo.svg'

const useStyles = makeStyles(theme => ({
    headerNav: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 1,
        width: '100%',
        padding: '20px 20px 0px 20px',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a:hover': {
            color: theme.palette.primary.main
        }
    },
    aboutContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '50px'
    },
    loginBtn: {
        display: 'flex',
        backgroundColor: theme.palette.primary.contrastText,
        width: '85px',
        height: '36px',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '4px',
        marginRight: '10px',
        borderRadius: '18px',
        color: `${theme.palette.background.dark} !important`,
        fontSize: '16px',
        '&:hover': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    logo: {
        maxWidth: '80px',
        height: '36px',
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: '40%',
    },

}))
export default function Header() {
    const styles = useStyles();
    return (
        <div className={styles.headerNav}>
            <div className={styles.aboutContainer}>
                <div className={styles.logoContainer}>
                    <Link to='/home-page'>
                        <img src={recordLogo} alt='take-record-logo' className={styles.logo}></img>
                    </Link>
                </div>
                <div className={styles.learnMoreBtn}><Link to="/learn-more">Learn More</Link></div>
                <div className={styles.contactBtn}><Link to="/contact">Contact</Link></div>
            </div>
            <Link to="/login" className={styles.loginBtn}>
                <Typography> Login </Typography>
            </Link>

        </div>
    )
}