import { AppBar, Box, makeStyles, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { BrandMenuItem, BrandSelect } from "../CoreComponents/BrandSelect";
import { getData } from "../utils/FetchUtils";
import clsx from 'clsx';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import MissingData from "./MissingData";
import ResolvedCasesTable from "./ResolvedCasesTable";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useStoreContext } from "../../store/Store";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        font: 'normal normal bold 25px Roboto',
        color: theme.palette.text.primary,
        marginBottom: '16px',
        letterSpacing: '2.4px',
    },
    bar: {
        width: '100%',
        '& .MuiTabs-root': {
            marginBottom: '24px',
        },
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiPaper-elevation4': {
            boxShadow: 'none !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main
        },
        '& .MuiBox-root': {
            padding: 0
        },
        "& .MuiButtonBase-root": {
            padding: 0,
            color: 'white',
            opacity: 1,
            font: 'normal normal normal 20px/25px Roboto',
            letterSpacing: '1.6px',
            textTransform: 'none'
        },
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: 'start',
            gap: '50px'
        },
    },
    select: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '& .MuiInputBase-root': {
            minWidth: '315px',
            borderColor: theme.palette.primary.border
        },
        "& .MuiSelect-root": {
            letterSpacing: '0px',
            font: 'normal normal medium 18px/26px Roboto',
            fontSize: '18px'
        }
    },
    artistSelectLabel: {
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '1px',
        color: theme.palette.text.primary,
    },
    selectContainer: {
        display: 'flex',
        gap: '50px',
        marginBottom: '24px'
    },
    warning: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: 20,
        color: theme.palette.primary.warning,
        height: 50,
        width: '80%',
        '&:hover': {
            border: 'none'
        },
        marginBottom: '32px'
    },
    infoText: {
        marginBottom: '32px'
    },
    dotsContainer: {
        display: 'flex',
    },
    tabDots: {
        color: theme.palette.primary.warning,
        fontSize: '1rem'
    },
    autoCompleteElement: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: '1px  #3C3C3C',
            }
        },
        '& .MuiInputBase-root': {
            height: theme.spacing(5),
            border: '1px solid #3C3C3C',
            borderRadius: theme.spacing(3),
            '& .MuiAutocomplete-endAdornment': {
                top: 'calc(50% - 18px)'
            }
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3C3C3C',
            border: 'none'
        },
        '& .MuiFormLabel-root': {
            top: '-6px'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'white'
        },
        '& .MuiInputBase-input': {
            position: 'relative',
            bottom: theme.spacing(1),
            width: 180
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: 'white'
        },
        '& .MuiAutocomplete-popupIndicator': {
            '& .MuiSvgIcon-root': {
                fontSize: '2.1875rem'
            },
            color: 'white',
            "& span": {
                "& svg": {
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')" // your svg icon path here
                    }
                }
            }
        },
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} >
                    <Typography component='div'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function setTabProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function ReviewPage() {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [accountHolders, setAccountHolders] = useState([]);
    const selectedAccountHolder = useState(0);
    const [artists, setArtists] = useState([]);
    const selectedArtist = useState(-1);
    const [value, setValue] = useState(0);
    const [reviewPageData, setReviewPageData] = useState([]);
    const [resolvedCasesData, setResolvedCasesData] = useState([]);
    const [groupedData, setGroupedData] = useState([]);
    const defaulAutocompleteValue = { name: 'All', id: -1 };
    const [autocomleteValue, setAutocompleteValue] = useState(defaulAutocompleteValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/my`)
            .then(data => {
                setAccountHolders(data);
                setAutocompleteValue(defaulAutocompleteValue);
            })
    }, [selectedAccountHolder[0]]);

    const fetchReviewPageData = () => {
        let accountHolderIds;

        const songStatus = 'added';

        if (selectedAccountHolder[0] === 0) {
            accountHolderIds = accountHolders.map(x => x.id);
        } else {
            accountHolderIds = [selectedAccountHolder[0]];
        }

        if (accountHolderIds.length > 0) {
            getData(process.env.REACT_APP_SERVER_HOST + `/api/review-page/get-missing-data?accountHolderIds=${accountHolderIds}&artistId=${selectedArtist[0]}&status=${songStatus}&userId=${state.user.id}`)
                .then(data => setReviewPageData(data.allMissingData));

            getData(process.env.REACT_APP_SERVER_HOST + `/api/review-page/get-resolved-data?accountHolderIds=${accountHolderIds}&artistId=${selectedArtist[0]}&status=${songStatus}&userId=${state.user.id}`)
                .then(data => setResolvedCasesData(data.allResolvedData));

        }
    };


    useEffect(() => {
        fetchReviewPageData();
        // let accountHolderIds;

        // const songStatus = 'added';

        // if (selectedAccountHolder[0] === 0) {
        //     accountHolderIds = accountHolders.map(x => x.id);
        // } else {
        //     accountHolderIds = [selectedAccountHolder[0]];
        // }

        // if (accountHolderIds.length > 0) {
        //     getData(process.env.REACT_APP_SERVER_HOST + `/api/review-page/get-missing-data?accountHolderIds=${accountHolderIds}&artistId=${selectedArtist[0]}&status=${songStatus}&userId=${state.user.id}`)
        //         .then(data => setReviewPageData(data.allMissingData));

        //     getData(process.env.REACT_APP_SERVER_HOST + `/api/review-page/get-resolved-data?accountHolderIds=${accountHolderIds}&artistId=${selectedArtist[0]}&status=${songStatus}&userId=${state.user.id}`)
        //         .then(data => setResolvedCasesData(data.allResolvedData));

        // }
    }, [selectedAccountHolder[0], accountHolders, selectedArtist[0]]);

    useEffect(() => {
        selectedArtist[1](-1)
        getData(process.env.REACT_APP_SERVER_HOST + `/api/review-page/get-artists-group-by-account-holders`)
            .then(data => {
                if (selectedAccountHolder[0].length > 1) {
                    setArtists(Object.values(data.artistsGroupByAccountHolders));
                } else {
                    if (data.artistsGroupByAccountHolders[selectedAccountHolder[0]] !== undefined) {
                        const artists = data.artistsGroupByAccountHolders[selectedAccountHolder[0]];
                        setArtists([defaulAutocompleteValue, ...artists])
                    } else {
                        setArtists([defaulAutocompleteValue, ...data.allArtists]);
                    }
                }
            })
    }, [selectedAccountHolder[0]]);

    let groupBy = (array, key) => {
        return array.reduce((result, obj) => {
            (result[obj[key]] = result[obj[key]] || []).push(obj);
            return result;
        }, []);
    };

    useEffect(() => {

        const groups = groupBy(reviewPageData, 'songId')
        if (groups !== undefined) {
            setGroupedData(groups);
        }
    }, [reviewPageData])

    const autocompleteDefaultProps = {
        getOptionSelected: (option, value) => value !== autocomleteValue ? option.name === value.name : value === autocomleteValue
    };

    return (
        <div className={styles.container}>
            <Typography
                component='div'
                className={styles.pageTitle}
            >
                Review Page
            </Typography>
            <div className={styles.bar}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange}>
                        <Tab
                            label={
                                <div className={styles.dotsContainer}>
                                    <div>Missing Revenue Data</div>
                                    <FiberManualRecordIcon className={styles.tabDots} />
                                </div>
                            }
                            {...setTabProps(0)}
                        />
                        <Tab
                            label={
                                <div className={styles.dotsContainer}>
                                    <div>Resolved Cases</div>
                                    <FiberManualRecordIcon className={styles.tabDots} />
                                </div>
                            }
                            {...setTabProps(1)}
                        />
                    </Tabs>
                </AppBar>
                {value === 0 ?
                    state.user.userType !== 1 ?
                        <p className={styles.infoText}>Missing Revenue Data is shown only for verified Spotify songs</p>
                        :
                        null
                    :
                    null
                }
                <div className={styles.selectContainer}>
                    <div className={styles.select}>
                        <div className={styles.artistSelectLabel}>Account Holder: </div>
                        <div>
                            <BrandSelect $value={selectedAccountHolder}>
                                <BrandMenuItem value={0}>All</BrandMenuItem>
                                {accountHolders.map(x => (
                                    <BrandMenuItem key={x.id} value={x.id}>
                                        {x.name}
                                    </BrandMenuItem>
                                ))}
                            </BrandSelect>
                        </div>
                    </div>
                    <div className={styles.select}>
                        <div className={styles.artistSelectLabel}>Artist: </div>
                        <div>
                            <Autocomplete
                                onChange={(event, value) => {
                                    if (value !== null) {
                                        selectedArtist[1](value.id)
                                        setAutocompleteValue(value)
                                    }
                                }}
                                value={autocomleteValue}
                                disableClearable={true}
                                className={styles.autoCompleteElement}
                                options={artists}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 270 }}
                                renderInput={(params) => <TextField InputProps={{
                                    ...params.InputProps,
                                    inputProps: {
                                        ...params.inputProps,
                                        maxLength: 60
                                    }
                                }}
                                    label=""
                                    variant="outlined"
                                />}
                                {...autocompleteDefaultProps}
                            />
                        </div>
                    </div>
                </div>
                {
                    value === 0 ?
                        state.user.userType !== 1 ?
                            <div className={styles.warning}>
                                <ReportProblemOutlinedIcon style={{ color: '#F96C00', fontSize: '4rem' }} />
                                <div>
                                    Our System has indicated you have potential missing royalty revenue. Please click Report Issue to grant permission to Record to rectify the error and locate any potential revenues.
                                </div>
                            </div>
                            :
                            null
                        :
                        null
                }
                <TabPanel value={value} index={0} className={clsx(styles.tabPanel, styles.tabHeight)}>
                    <MissingData data={groupedData} fetchData={fetchReviewPageData} />
                </TabPanel>
                <TabPanel value={value} index={1} className={clsx(styles.tabPanel, styles.tabHeight)}>
                    <ResolvedCasesTable data={resolvedCasesData} />
                </TabPanel>
            </div>
        </div>
    );
};

export default ReviewPage;