import { Paper, Table, TableContainer, TablePagination, Typography, makeStyles } from "@material-ui/core";
import TableHeadPart from "./SongTableHead";
import TableBodyPart from "./SongTableBody";
import { useEffect } from "react";
import { handleRowsPerPageChange } from "../utils/PaginationUtils";

const useStyles = makeStyles(() => ({
    containerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: '40px',
        width: '100%',
    },
    table: {
        width: '100%'
    },
    pagination: {
        border: 'none'
    }
}));

const headerCells = [
    { value: 'Songs' },
    { value: 'Country' },
    // { value: 'Royalty types' },
    { value: 'Reporting Companies' },
    { value: 'Play Sources' },
    { value: 'Period' },
    { value: 'Revenue' },
];

const SongsTable = ({ 
    page, 
    setPage,
    offset,
    setOffset,
    bodyCells,
    rowsCount,
    setSortingType,
    sortingType,
    rowsPerPage,
    setRowsPerPage,
    selectedRange,
    rowsPerPageOptions
}) => {

    const styles = useStyles();

    const handleToggleSortingType = () => {
        setSortingType((prev) => prev === 'asc' ? 'desc' : 'asc');
    }

    const handleOnPageChange = (_, nextPage) => {
        setPage(nextPage);
    }

    useEffect(() => {
        setOffset(page * rowsPerPage);
    }, [page]);

    return (
        <Paper className={styles.containerWrapper}>
            { bodyCells.length ? (
                <TableContainer>
                    <Table className={styles.table}>
                        <TableHeadPart
                            rows={headerCells}
                            sortingType={sortingType}
                            handleToggleSortingType={handleToggleSortingType}
                        />
                        <TableBodyPart rows={bodyCells} selectedRange={selectedRange} />
                    </Table>
                    <TablePagination
                        component={'div'}
                        className={styles.pagination}
                        rowsPerPageOptions={rowsPerPageOptions}
                        rowsPerPage={rowsPerPage}
                        count={rowsCount}
                        page={page}
                        onPageChange={handleOnPageChange}
                        onRowsPerPageChange={(event) => handleRowsPerPageChange(event, offset, setRowsPerPage, setPage, setOffset)}
                    />
                </TableContainer>
            ) : (
                <Typography style={{ margin: '0 auto' }}>
                    There are no songs matching the selected filters
                </Typography>
            ) }
        </Paper>
    )
};

export default SongsTable;