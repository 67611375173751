import React, { useState, useEffect, useMemo } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce, getData } from '../utils/FetchUtils'
import { IconButton, ListItem, ListItemText } from '@material-ui/core';
import { BrandInput } from '../CoreComponents/BrandInput';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
    autocomplete: {
        "& .MuiAutocomplete-clearIndicatorDirty": {
            display: 'none'
        },
        "& .MuiAutocomplete-popupIndicator": {
            display: 'none'
        },
    }
}));

export function ArtistAutoComplete({ label, onSelectedArtist, selectedArtist, ...props }) {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(selectedArtist || '');
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (value && inputValue === value.name) {
            setIsLoading(false);
            return;
        }

        setOpen(inputValue.length > 0);
        (async () => {
            if (!inputValue || !isLoading) {
                return;
            }
                const data = await getData(process.env.REACT_APP_SERVER_HOST + `/api/artist/?offset=0&limit=${20}&filter=${inputValue}`)
                setOptions(data.items);
                setIsLoading(false);
        })();
    }, [inputValue]);

    const debounceMemo = useMemo(
        () => debounce(
            (event, newInputValue) => {
                setOptions([]);
            }, (event, newInputValue) => {
                setIsLoading(newInputValue.length > 0);
                setInputValue(newInputValue);
            }, 500),
        [],
    );

    return (
        <Autocomplete
            className={styles.autocomplete}
            open={open}
            onClose={() => {
                setOpen(false);
                setOptions([]);
            }}
            value={value}
            onChange={(e, newValue) => {
                if (!newValue) {
                    setValue('');
                    setInputValue('');
                    setOpen(false);
                    setIsLoading(false);
                    return;
                }

                setValue(newValue);
                setInputValue('');

                const artist = { name: newValue.name, id: newValue.id };
                onSelectedArtist(artist);

                setOpen(false);
                setIsLoading(false);
            }}
            onInputChange={debounceMemo}
            blurOnSelect={true}
            clearOnBlur={true}
            clearOnEscape={true}
            getOptionLabel={(option) => option?.name || ''}
            getOptionSelected={() => false}
            renderOption={(artist, option) => {
                return (<ListItem key={artist.id}>
                    <ListItemText primary={artist.name} />
                </ListItem>)
            }}
            options={options}
            filterOptions={(options) => options}
            loading={isLoading}
            renderInput={(params) => (
                <BrandInput
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ?
                                    <CircularProgress color="inherit" size={20} />
                                    :
                                    <IconButton
                                        onClick={(e) => {
                                            setValue('');
                                            setInputValue('');
                                            onSelectedArtist({});
                                        }}
                                    >
                                        <ClearIcon style={{ color: 'white' }} />
                                    </IconButton>
                                }
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    {...props}
                />
            )}
        />
    );
}