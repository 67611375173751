import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStoreContext } from '../../store/Store';
import GetEquivalentSongs from './GetEquivalentSongs';
import { BrandModal } from '../CoreComponents/BrandModal';
import { TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton';
import { formatNumber } from '../utils/NumberUtils';
import { ButtonSize } from '../../constants/buttonConstants';

function formatSongName(songName) {
    let index;
    const featuringSelectors = ['featuring', 'feat.', 'feat', 'ft.', 'ft']
    for (let selector of featuringSelectors) {
        if ((index = songName.indexOf(selector)) >= 0) {
            break;
        }
    }
    if (index > 0) {
        songName = songName.slice(0, index);
    }
    return songName.replace(/featuring|feat.|feat|ft.|ft|Video|video|Official|official|'|\s|\[|\]|\(|\)/g, '').toLowerCase();
}

function formatArtist(artist) {
    if (artist) {
        return artist.replace(/\s/g, '').toLowerCase();
    } else {
        return "";
    }

}

function getEquivalentSongs(data) {
    // const equivalentIsrcs = {};
    // for (let row = 0; row < data.length; row++) {
    //     let isrc = data[row].isrc && data[row].isrc.length ? data[row].isrc : '"No Data"';
    //     if (isrc !== "No Data") {
    //         isrc = isrc + "s"
    //         if (equivalentIsrcs[isrc]) {
    //             equivalentIsrcs[isrc].rows.push(row);
    //         } else {
    //             equivalentIsrcs[isrc] = { rows: [row], title: data[row].title, externalId: data[row].isrc };
    //         }
    //     }
    // }

    // for (const key in equivalentIsrcs) {
    //     if (equivalentIsrcs[key].rows.length === 1) {
    //         delete equivalentIsrcs[key];
    //     }
    // }

    const equivalentTuples = {};
    for (let row = 0; row < data.length; row++) {
        const rowIsrc = data[row].isrc + "s";
        // if (!equivalentIsrcs[rowIsrc]) {
            const title = data[row].title;
            const artist = data[row].artist;
            const formattedTitle = formatSongName(title);
            const formattedArtist = formatArtist(artist);

            const playSource = data[row].playSource;
            const territory = data[row].territory;

            const tuple = `${formattedTitle}|${formattedArtist}|${playSource}|${territory}`;
            if (equivalentTuples[tuple]) {
                equivalentTuples[tuple].rows.push(row);
            } else {
                equivalentTuples[tuple] = { rows: [row], title: title, isrc: rowIsrc, externalId: data[row].isrc }
            }
        // }
    }
    return equivalentTuples;
}

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '2em',
    },
    tableHeaderText: {
        color: theme.palette.primary.lightGray,
        margin: 0,
    },
    title: {
        fontSize: '30px',
    },
    modalContainer: {
        width: '400px',
        font: 'normal normal normal 21px/25px Roboto',
        lineHeight: 1.5
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px',
        marginTop: '30px',
    }
}));

export default function Summary({
    reportType,
    reportingCompany,
    setData,
    validationRows,
    setCsvToServer,
    csvToServer,
    dataMappings,
    mappingTemplate,
    accountHolderId,
    reportingPeriod,
    equivalentSongs,
    setEquivalentSongs,
    alternativeNamesData,
    setAlternativeNamesData,
    reportingCompanies,
    createReportModal,
    setCreateReportModal,
    setActiveStep,
    handleSubmit,
    notes
}) {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [selectedSong, setSelectedSong] = useState('');
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [songId, setSongId] = useState(null);
    const reportCompanyName = reportingCompanies[reportType].filter(x => x.id === reportingCompany)[0].name;

    const handleClickCancel = () => {
        setCreateReportModal(false)
        setActiveStep(1)
    }

    useEffect(() => {
        const csvToServer = { reportType: reportType, reportingCompany: reportingCompany, accountHolderId: accountHolderId, reportingPeriod: reportingPeriod, notes: notes, data: [] };
        let totalRevenue = 0;
        for (let row = 0; row < validationRows.length; row++) {
            const rowData = Object.values(validationRows[row].cells);
            const rowDataToServer = {};
            for (let col = 0; col < rowData.length; col++) {
                const cell = rowData[col];
                const columnName = mappingTemplate[col];
                const fieldName = dataMappings[columnName].fieldName;
                rowDataToServer[fieldName] = cell.text?.trim();
            }
            csvToServer.data.push(rowDataToServer);
            totalRevenue += parseFloat(rowDataToServer.revenue) || 0;
        }
        setTotalRevenue(totalRevenue);
        setEquivalentSongs(getEquivalentSongs(csvToServer.data));
        setCsvToServer(csvToServer);
    }, []);

    return (
        <div className={styles.allSetUpContainer}>
            <div>
                <div className={styles.container}>
                    <BrandModal
                        open={createReportModal}
                        onClose={() => setCreateReportModal(false)}
                    >
                        <div className={styles.modalContainer}>
                            {
                                isNaN(totalRevenue) ?
                                    <>
                                        <div>The total revenue of this file is not a number (NaN), please review the document in order to continue.</div>
                                        <div className={styles.btnContainer}>
                                            <TempBrandButton
                                                size={ButtonSize.SMALL}
                                                // className={styles.cancelButton}
                                                onClick={() => setCreateReportModal(false)}
                                            >
                                                OK
                                            </TempBrandButton>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div>The total revenue amount of document <br></br> <b>${state.uploadReportFileName}</b> is <b>${formatNumber(totalRevenue)}</b>, <br></br> please verify that the amount is correct.</div>
                                        <div className={styles.btnContainer}>
                                            <TempSecondaryBrandButton
                                                onClick={handleClickCancel}
                                            >
                                                Cancel
                                            </TempSecondaryBrandButton>
                                            <TempBrandButton
                                                onClick={handleSubmit}
                                            >
                                                Verify
                                            </TempBrandButton>
                                        </div>
                                    </>
                            }
                        </div>
                    </BrandModal>
                    <div className={styles.title}>5. Summary - {state.uploadReportFileName}</div>
                    <p className="text">Your file was successfully validated!</p>
                    <p className="text">Total Revenue: {formatNumber(totalRevenue)}$</p>
                    {Object.keys(equivalentSongs).length ?
                        <GetEquivalentSongs
                            songId={songId}
                            selectedSong={selectedSong}
                            equivalentSongs={equivalentSongs}
                            setEquivalentSongs={setEquivalentSongs}
                            csvToServer={csvToServer}
                            reportType={reportType}
                            setSongId={setSongId}
                            setSelectedSong={setSelectedSong}
                            alternativeNamesData={alternativeNamesData}
                            setAlternativeNamesData={setAlternativeNamesData}
                            setData={setData}
                            reportCompanyName={reportCompanyName}
                        />
                        :
                        <header className={styles.tableHeader}>
                            <p className={styles.tableHeaderText}>No potential equivalent songs were found.You can proceed by clicking &lsquo; submit&rsquo;.</p>
                        </header>
                    }
                </div>
            </div>
        </div>
    );
}
