import { Slide, makeStyles } from "@material-ui/core"
import { useEffect } from "react";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        font: 'normal normal normal 24px/32px ITCAvantGardeGothicStd-Normal',
        letterSpacing: '1.3px',
        width: '400px',
        fontFamily: 'ITCAvantGardeGothicStd-Normal',
        fontSize: '21px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
}))
export default function ThankYouMessage() {
    const styles = useStyles();
    const history = useHistory();

    useEffect(() => {
        const message = setTimeout(() => {
            history.push('/contact');
        }, 5000)

        return () => {
            clearTimeout(message)
        };
    }, []);

    return (
        <Slide
            timeout={500}
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
        >
            <div className={styles.container}>
                <p>Thank you for contacting Record! We’ll be in touch shortly.</p>
            </div>
        </Slide>
    )
}