import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useAbortableFetch from '../../hooks/useAbortableFetch.js';
import { useHistory } from 'react-router-dom';
import { BrandLink } from '../CoreComponents/BrandLink'
import { ArrowBack } from '@material-ui/icons';
import { Typography, Backdrop } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos.js';
import recordLogo from '../../assets/recordLogo.svg';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton'
import FiltersSetupStep from './FiltersSetupStep.js';
import CsvStatementsStep from './CsvStatementsStep.js';
import { useStoreContext } from '../../store/Store';
import { useSnackbarAlert } from '../../hooks/useSnackbarAlert.js';
import { postWithOriginalFetch } from '../utils/FetchUtils';
import { parseDateToISODateFormat } from '../utils/DateUtils';
import { ButtonSize } from '../../constants/buttonConstants';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { BrandModal } from '../CoreComponents/BrandModal';
import { BrandLoader } from '../CoreComponents/BrandLoader';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '116px',
        height: '32px',
        marginLeft: '1.5em',
        margin: 'auto',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    goBackIcon: {
        margin: 'auto',
        display: 'flex',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    goBack: {
        height: '68px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: theme.spacing(2)
    },
    continue: {
        display: 'flex',
        marginRight: '50px',
        '& button': {
            marginRight: '10px',
            marginLeft: '10px',
            width: '148px',
        }
    },
    stepper: {
        height: '68px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        background: `${theme.palette.background.dark} 0% 0% no-repeat padding-box`,
        borderBottom: '1px solid black',
        '& .MuiStepper-horizontal': {
            width: '20%',
            marginLeft: '10em',
        },
        '& .MuiStepper-root': {
            background: `${theme.palette.background.dark} 0% 0% no-repeat padding-box`,
        },
        '& .MuiStepIcon-root': {
            border: '1px solid white',
            borderRadius: '50%',
            '&.MuiStepIcon-completed': {
                color: theme.palette.primary.main,
                border: 'none',
            },
            '&.MuiStepIcon-active': {
                color: theme.palette.primary.main,
                border: 'none',
                '& .MuiStepIcon-text': {
                    fill: theme.palette.text.black
                }
            }
        }
    },
    actionsFooter: {
        width: '100%',
        paddingTop: '1em',
        paddingBottom: '1em',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 'auto',
        background: `${theme.palette.background.grayNuance} 0% 0% no-repeat padding-box`,
        alignItems: 'center',
    },
    contentContainer: {
        overflow: 'hidden',
        height: '100%',
        padding: '2rem',
    },
    stepsContainer: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    cancel: {
        marginLeft: '80px',
        width: '148px',
        backgroundColor: theme.palette.background.grayNuance,
        '&:hover': {
            backgroundColor: theme.palette.background.grayNuance,
        }
    },
    previous: {
        backgroundColor: theme.palette.background.grayNuance,
        '&:hover': {
            backgroundColor: theme.palette.background.grayNuance,
        }
    },
    pageMainHeader: {
        fontSize: '25px',
        fontWeight: 'bold',
    },
    modalActions: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: "flex-end",
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
        '& button:last-child': {
            width: '200px',
        }
    },
    modalTextWrapper: {
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        maxWidth: '70%'
    },
    modalWarningText: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.primary.attention
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 20,
        fontWeight: 'bold'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 1
    }
}));

const STEPS = {
    FILTERS: 0,
    CSV_STATEMENTS: 1
};

const STEP_LABELS = ['Setup', 'CSV View'];

const PreviewWarningModal = ({ handlePreviewPDF, setShowPreviewWarning }) => {
    const styles = useStyles();

    return (
        <BrandModal
            open={true}
            onClose={() => setShowPreviewWarning(false)}
        >
            <div style={{ maxWidth: '600px' }}>
                <div className={styles.modalTextWrapper}>
                    <Typography
                        variant="h5"
                        className={styles.modalTitle}
                    >
                        Warning
                    </Typography>

                    <div className={styles.modalWarningText}><ReportProblemOutlinedIcon /> When you select Preview your document will be generated but it will not be saved in the system.</div>
                    <p>Do you want to preview your report?</p>
                </div>

                <div className={styles.modalActions}>
                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        onClick={() => setShowPreviewWarning(false)}
                    >
                        Cancel
                    </TempSecondaryBrandButton>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        onClick={() => {
                            setShowPreviewWarning(false);
                            handlePreviewPDF();
                        }}
                    >
                        Preview Report
                    </TempBrandButton>
                </div>
            </div>
        </BrandModal>
    );
};

const SuccessModal = ({ downloadSavedPDF, handleCancel }) => {
    const styles = useStyles();

    return (
        <BrandModal
            open={true}
            onClose={() => handleCancel()}
        >
            <div style={{ maxWidth: '600px' }}>
                <div className={styles.modalTextWrapper}>
                    <Typography
                        variant="h5"
                        className={styles.modalTitle}
                    >
                        Success!
                    </Typography>
                    <p>Your report has been generated and saved in the system. You can now download your report and close this window. Closing this message will lead you to the Portfolio page.</p>
                </div>

                <div className={styles.modalActions}>
                    <TempSecondaryBrandButton
                        size={ButtonSize.SMALL}
                        onClick={() => handleCancel()}
                    >
                        Close
                    </TempSecondaryBrandButton>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        onClick={() => {
                            downloadSavedPDF();
                            handleCancel();
                        }}
                    >
                        Download Statement
                    </TempBrandButton>
                </div>
            </div>
        </BrandModal>
    );
};

export default function GenerateRoyaltyStatement() {
    const styles = useStyles();
    const abortableFetch = useAbortableFetch();
    const history = useHistory();

    const [currentStep, setCurrentStep] = useState(STEPS.FILTERS);
    const [store, setStore] = useStoreContext();
    const { showErrorAlert } = useSnackbarAlert();
    const [generating, setGenerating] = useState(false);
    const [showPreviewWarning, setShowPreviewWarning] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [savedFileId, setSavedFileId] = useState();

    const [selectedFitlers, setSelectedFilters] = useState({
        payorId: 0,
        payeeId: 0,
        startDate: undefined,
        endDate: undefined,
        subtitle: ''
    });
    const [selectedCSVStatements, setSelectedCSVStatements] = useState([]);

    useEffect(() => {
        setStore(state => (state.hideNavigation = true, state.disableRootPadding = true, { ...state }));
        return () => { setStore(state => (state.hideNavigation = false, state.disableRootPadding = false, { ...state })) };
    }, []);

    const handlePreviewPDF = async () => {
        if (!showPreviewWarning) {
            setShowPreviewWarning(true);
            return;
        }
        try {
            setGenerating(true);
            const response = await generatePDF();
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        } catch (error) {
            showErrorAlert('There was a problem with generating the PDF file.');
            console.error(error);
        } finally {
            setGenerating(false);
        }
    };

    const handleSavePDF = async () => {
        setGenerating(true);
        try {
            const { royaltyStatementId } = await abortableFetch('POST', '/api/royalty-statement/generate-pdf', {
                body: {
                    ...selectedFitlers,
                    startDate: parseDateToISODateFormat(selectedFitlers.startDate),
                    endDate: parseDateToISODateFormat(selectedFitlers.endDate),
                    selectedCsvStatements: selectedCSVStatements,
                    saveFile: true
                }
            });

            setSavedFileId(royaltyStatementId);
            setShowSuccessModal(true);
        } catch (error) {
            showErrorAlert('There was a problem with generating the PDF file.');
            console.error(error);
        } finally {
            setGenerating(false);
        }
    };

    const generatePDF = (saveFile) => {
        return postWithOriginalFetch(process.env.REACT_APP_SERVER_HOST + '/api/royalty-statement/generate-pdf', {
            ...selectedFitlers,
            startDate: parseDateToISODateFormat(selectedFitlers.startDate),
            endDate: parseDateToISODateFormat(selectedFitlers.endDate),
            selectedCsvStatements: selectedCSVStatements,
            saveFile
        });
    };

    const downloadSavedPDF = () => {
        if (savedFileId) {
            const url = process.env.REACT_APP_SERVER_HOST + `/api/royalty-statement/get-pdf?id=${savedFileId}`;
            const a = document.createElement('a');
            a.href = url;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleCancel = () => {
        // automatically opens the Royalty Statements Tab (index 1) on the Reporting page
        history.push('/reporting', { tabValue: 1});
    };

    const stepContent = (step) => {
        switch (step) {
            case STEPS.FILTERS:
                return (
                    <FiltersSetupStep
                        selectedFitlers={selectedFitlers}
                        setSelectedFitlers={setSelectedFilters}
                    />
                );
            case STEPS.CSV_STATEMENTS:
                return (
                    <CsvStatementsStep
                        selectedFitlers={selectedFitlers}
                        setSelectedFitlers={setSelectedFilters}
                        selectedCSVStatements={selectedCSVStatements}
                        setSelectedCSVStatements={setSelectedCSVStatements}
                    />
                );
            default:
                return (<h1>Error!</h1>);
        }
    };

    return (
        <div className={styles.stepsContainer}>

            {generating &&
                <Backdrop className={styles.backdrop} open={true}>
                    <div className={styles.loader}>
                        <BrandLoader color='white' width={150} height={150} />
                        <p>Generating Document...</p>
                    </div>
                </Backdrop>
            }
            {showPreviewWarning && <PreviewWarningModal handlePreviewPDF={handlePreviewPDF} setShowPreviewWarning={setShowPreviewWarning} />}
            {showSuccessModal && <SuccessModal downloadSavedPDF={downloadSavedPDF} handleCancel={handleCancel} />}

            <div className={styles.stepper}>
                <div className={styles.goBack}>
                    <BrandLink to='/portfolio' className={styles.goBackIcon}>
                        <ArrowBack fontSize='large' style={{ color: 'white' }} />
                        <img src={recordLogo} alt='Take Record Logo' className={styles.logo}></img>
                    </BrandLink>
                </div>
                <Stepper activeStep={currentStep}>
                    {STEP_LABELS.map((step) =>
                        <Step key={step}>
                            <StepLabel>{step}</StepLabel>
                        </Step>
                    )}
                </Stepper>
            </div>

            <div className={styles.contentContainer}>
                <Typography className={styles.pageMainHeader}>Generate Royalty Statement</Typography>
                {stepContent(currentStep)}
            </div>

            <div className={styles.actionsFooter}>
                <TempSecondaryBrandButton
                    className={styles.cancel}
                    onClick={() => handleCancel()}
                >
                    Cancel
                </TempSecondaryBrandButton>
                {currentStep === STEPS.FILTERS ?
                    <div className={styles.continue}>
                        <TempBrandButton
                            disabled={!(selectedFitlers.payeeId && selectedFitlers.payorId && selectedFitlers.startDate && selectedFitlers.endDate)}
                            onClick={() => handleNext()}
                            endIcon={<ArrowForwardIosIcon />}
                        >
                            Next
                        </TempBrandButton>
                    </div>
                    :
                    currentStep === STEPS.CSV_STATEMENTS ?
                        <div className={styles.continue}>
                            <TempSecondaryBrandButton
                                className={styles.previous}
                                onClick={() => handlePrevious()}
                                startIcon={<ArrowBackIosIcon />}
                            >
                                Previous
                            </TempSecondaryBrandButton>
                            <TempBrandButton
                                disabled={!selectedCSVStatements?.length}
                                onClick={handlePreviewPDF}
                            >
                                Preview
                            </TempBrandButton>
                            <TempBrandButton
                                disabled={!selectedCSVStatements?.length}
                                onClick={handleSavePDF}
                                startIcon={<SaveIcon />}
                            >
                                Save
                            </TempBrandButton>
                        </div>
                        : null
                }
            </div>
        </div>
    );
};