import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PopoverDetails from './PopoverDetails';

const useSongStyles = makeStyles((theme) => ({
    songDataCols: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
    },
    songDataColsFirstRow: {
        paddingTop: theme.spacing(3.75),
        background: '#252525',
    },
    songDataColsSecondRow: {
        paddingTop: theme.spacing(3.75),
    },
    barVerticalHr: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: theme.spacing(7.6),
        marginTop: -theme.spacing(1),
        display: 'inline-block',
        border: '1px solid #393939'
    },
    calculation: {
        fontSize: theme.spacing(3.5)
    },
    songDataDetailsTabRowLabel: {
        font: 'normal normal normal 14px/18px Roboto',
        color: '#D9D9D9',
        letterSpacing: '1.4px',
        textAlign: 'right'
    },
    songDataDetailsTabRowData: {
        font: 'normal normal bold 14px/18px Roboto',
        letterSpacing: '1.4px',
        textAlign: 'right'
    },
    songDataDetailsTabRowDataMoneyLabel: {
        font: 'normal normal normal 12px/15px Roboto',
        letterSpacing: '1.2px',
        color: '#A5A5A5',
        textAlign: 'right'
    },
    songDataDetailsTabRowDataMoneyData: {
        font: 'normal normal bold 24px/31px Roboto',
        letterSpacing: '2.4px',
        textAlign: 'right'
    },
    songDataDetailsCol: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        marginBottom: theme.spacing(2.5)
    },
    songDataRevenueTabRowDataMoneyData: {
        color: theme.palette.primary.main,
        font: 'normal normal bold 24px/31px Roboto',
    },
    songDataTotalRevenueTabRowDataText: {
        color: 'white',
        font: 'normal normal 14px/18px Roboto',
        textAlign: 'left'
    },
    songDataRevenueCol: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        marginBottom: theme.spacing(2.5),
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '15px',
        padding: '20px 45px 20px 45px',
        marginTop: '-18px'
    }
}));

function Details({ details }) {
    const styles = useSongStyles();

    return (
        <>
         {/* Hided until client decide which logic to implement */}
            {/* <div className={clsx(styles.songDataCols, styles.songDataColsFirstRow)}>
                <div className={styles.songDataDetailsCol}>
                    <div className={styles.songDataDetailsTabRowLabel}>Total Streams</div>
                    <div className={styles.songDataDetailsTabRowData}>{details.totalStreams ? formatNumber(details.totalStreams) : 'N/A'}</div>
                </div>
                <div className={styles.songDataDetailsCol}>
                    <div className={styles.songDataDetailsTabRowLabel}>Total Downloads</div>
                    <div className={styles.songDataDetailsTabRowData}>{details.totalDownloads ? formatNumber(details.totalDownloads) : 'N/A'}</div>
                </div>
                <div className={styles.songDataDetailsCol}>
                    <div className={styles.songDataDetailsTabRowLabel}>Physical Units</div>
                    <div className={styles.songDataDetailsTabRowData}>{details.physicalUnits ? formatNumber(details.physicalUnits) : 'N/A'}</div>
                </div>
                <div className={styles.songDataDetailsCol}>
                    <div className={styles.songDataDetailsTabRowLabel}>Total Units</div>
                    <div className={styles.songDataDetailsTabRowData}>{details.totalUnits ? formatNumber(details.totalUnits) : 'N/A'}</div>
                </div>
                <div className={styles.songDataDetailsCol}>
                    <div className={styles.songDataDetailsTabRowLabel}>Sync Revenue</div>
                    <div className={styles.songDataDetailsTabRowData}>{details.syncRevenue ? formatNumber(details.syncRevenue) : 'N/A'}</div>
                </div>
                <div className={styles.songDataDetailsCol}>
                    <div className={styles.songDataDetailsTabRowLabel}>Streaming Revenue</div>
                    <div className={styles.songDataDetailsTabRowData}>{details.streamingRevenue ? formatNumber(details.streamingRevenue) : 'N/A'}</div>
                </div>
                <div className={styles.songDataDetailsCol}>
                    <div className={styles.songDataDetailsTabRowLabel}>Merchandise Revenue</div>
                    <div className={styles.songDataDetailsTabRowData}>{details.merchandiseRevenue ? formatNumber(details.merchandiseRevenue) : 'N/A'}</div>
                </div>
            </div> */}
            <div className={clsx(styles.songDataCols, styles.songDataColsSecondRow)}>
                <div className={styles.songDataDetailsCol}>
                    <div className={`${styles.songDataDetailsTabRowDataMoneyData} ${styles.calculation}`}>{details?.labelDistributorRevenue ? <PopoverDetails details={details?.labelDistributorRevenue} /> : 'N/A'}</div>
                    <div className={styles.songDataDetailsTabRowDataMoneyLabel}>Label/Distributor $</div>
                </div>

                <hr className={styles.barVerticalHr} />

                <div className={styles.songDataDetailsCol}>
                    <div className={`${styles.songDataDetailsTabRowDataMoneyData} ${styles.calculation}`}>{details?.masterProRevenue ? <PopoverDetails details={details?.masterProRevenue} /> : 'N/A'}</div>
                    <div className={styles.songDataDetailsTabRowDataMoneyLabel}>Master Performance $</div>
                </div>

                <hr className={styles.barVerticalHr} />

                <div className={styles.songDataDetailsCol}>
                    <div className={`${styles.songDataDetailsTabRowDataMoneyData} ${styles.calculation}`}>{details?.compositionProRevenue ? <PopoverDetails details={details?.compositionProRevenue} /> : 'N/A'}</div>
                    < div className={styles.songDataDetailsTabRowDataMoneyLabel}>Compositon PRO $</div>
                </div>

                <hr className={styles.barVerticalHr} />

                <div className={styles.songDataDetailsCol}>
                    <div className={`${styles.songDataDetailsTabRowDataMoneyData} ${styles.calculation}`}>{details?.publisherAdministratorRevenue ? <PopoverDetails details={details?.publisherAdministratorRevenue} /> : 'N/A'}</div>
                    <div className={styles.songDataDetailsTabRowDataMoneyLabel}>Publishing/Admin $</div>
                </div> 
                {/* <div className={styles.songDataDetailsCol}>
                    <div className={`${styles.songDataDetailsTabRowDataMoneyData} ${styles.calculation}`}>{details.masterValuation || details.publishingValuation ? <PopoverDetails details={Number(details.masterValuation || 0) + Number(details.publishingValuation || 0)} /> : 'N/A'}</div>
                    <div className={styles.songDataDetailsTabRowDataMoneyLabel}>Total Song Valuation</div>
                </div> */}
                <div className={styles.songDataRevenueCol}>
                    <div className={`${styles.songDataRevenueTabRowDataMoneyData} ${styles.calculation}`}>{details?.revenue ? <PopoverDetails details={details?.revenue} /> : 'N/A'}</div>
                    <div className={styles.songDataTotalRevenueTabRowDataText}>Total Revenue</div>
                </div>
            </div>
        </>
    );
}

export default Details;