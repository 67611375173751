import React, { Fragment, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Doughnut, Bar as ChartJsBar, Line } from 'react-chartjs-2';
import { CircularProgress } from '@material-ui/core';
import { formatMoney } from '../utils/NumberUtils';


function moneyShortSyntax(value) {
    if (value < 1000) {
        return value
    } else if (value < 1000000) {
        return (value / 1000) + " K";
    } else if (value < 1000000000) {
        return (value / 1000000) + ' M';
    }
    return (value / 1000000000) + " B";
}

const useStyles = makeStyles((theme) => ({
    bullet: {
        paddingLeft: theme.spacing(1.25),
        paddingRight: theme.spacing(1.25),
        paddingBottom: theme.spacing(3.375),
        paddingTop: theme.spacing(3.375),
        marginBottom: theme.spacing(3.125),
        marginRight: theme.spacing(1.875),
        borderRadius: theme.spacing(0.625),
    },
    bulletItem: {
        display: 'flex',
        flexDirection: 'row',
        width: theme.spacing(25)
    },
    doughnut: {
        marginLeft: theme.spacing(-20)
    },
    bullets: {
        marginLeft: theme.spacing(-17),
        letterSpacing: '0.32px',
        //fontFamily: 'Roboto Light,Sans-serif',
        fontSize: '16px',

    },
    pieChart: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: theme.spacing(0.625),
        paddingRight: theme.spacing(6.25),
        paddingLeft: theme.spacing(2.5),
        paddingTop: theme.spacing(0.625),
        paddingBottom: theme.spacing(0.625),
        '& canvas': {
            zIndex: 1
        }
    },
    title: {
        fontSize: theme.spacing(5.625)
    },
    barChart: {
        position: 'relative',
        paddingLeft: theme.spacing(3),
    },
    verticalBarChart: {
        borderRadius: theme.spacing(0.625),
        paddingLeft: theme.spacing(3.125),
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        position: 'relative',
    },
    overTextContainer: {
        marginTop: theme.spacing(-46),
        marginLeft: theme.spacing(15.5)
    },
    overText: {
        marginBottom: theme.spacing(8),
        letterSpacing: '1.4px',
        //fontFamily: 'Roboto Light,Sans-serif',
        fontSize: '14px'
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'transale(-50%, -50%)'
    }
}));

let width, height, gradient;
function getGradient(ctx, chartArea, colorOne, colorTwo) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        colorOne ? gradient.addColorStop(0, colorOne) : gradient.addColorStop(0, '#3EA1D9');
        colorTwo ? gradient.addColorStop(0.7, colorTwo) : gradient.addColorStop(1, '#03B56D');
    }

    return gradient;
}



export function BrandDoughnutChart({ isLoading, data, hasLegend, width, height, ...props }) {
    const styles = useStyles();
    const [chartLabels, setChartLabels] = useState([]);
    const [chartValues, setChartValues] = useState([]);
    const [colors, setColors] = useState([]);

    useEffect(() => {
        const labels = [];
        const values = [];
        const colors = [];
        for (let i = 0; i < data.length; i++) {
            labels.push(data[i].name);
            values.push(data[i].value);
            colors.push(data[i].color);
        }
        setColors(colors);
        setChartLabels(labels);
        setChartValues(values);
    }, [data])

    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                data: chartValues,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        radius: '50%',
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        return '';
                    },
                    title: function (context) {
                        let label = context[0].label;
                        if (label) {
                            label += ': ';
                        } else {
                            label = 'Earnings for track: ';
                        }
                        if (context[0].raw) {
                            label += `$${formatMoney(context[0].raw)}`;
                        }
                        return label;
                    }
                },
                titleFont: {
                    size: 16
                },
                titleMarginBottom: 0
            }
        },
    };

    return (
        <div className={styles.pieChart} style={{ width: width, height: height }}>
            {isLoading ? (
                <CircularProgress className={styles.spinner} />
            ) : (
                <Fragment>
                    <Doughnut data={chartData} options={options} className={styles.doughnut} />
                    {hasLegend ?
                        <div className={styles.bullets}>
                            {data.map((entry, i) => {
                                return (
                                    <div key={entry.name + i} className={styles.bulletItem}>
                                        <div className={styles.bullet} style={{ background: entry.color }} />
                                        <div>{entry.name === 'Others' ? 'Others - ' : entry.name}<br />${formatMoney(entry.value)}</div>
                                    </div>
                                )
                            })}
                        </div>
                        : null}
                </Fragment>
            )}
        </div>
    );
}

export function BrandVerticalBarsChart({ isLoading, by = 'year', data, tooltipLabel, color, width, height, customFormat, currency, ...props }) {
    const styles = useStyles();
    const [chartLabels, setChartLabels] = useState([]);
    const [chartValues, setChartValues] = useState([]);

    const [originalChartLabels, setOriginalChartLabels] = useState([]);

    const colorOne = props.colorOne;
    const colorTwo = props.colorTwo;
    const gridBorderColor = props.borderColor ? props.borderColor : '#5A5A5A';
    const gridMainColor = props.gridColor ? props.gridColor : '#1B1B1B';
    const barPercentage = props.barPercentage ? props.barPercentage : 0.9;
    const longLabels = props.longLabels;
    const minBarLength = 5;

    useEffect(() => {
        const labels = [];
        const values = [];
        const originalLabels = [];
        const dataType = props.dataType;

        for (let i = 0; i < data.length; i++) {
            const dataSourceType = data[i].year ? data[i].year : data[i][dataType];
            const { ethValue, value } = data[i];

            labels.push(dataSourceType);
            values.push(currency === 'eth' ? ethValue : value);

            if (longLabels) {
                originalLabels.push(data[i]['original label']);
            }
        }

        setChartLabels(labels);
        setChartValues(values);
        setOriginalChartLabels(originalLabels);
    }, [data]);

    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                originalLabels: originalChartLabels,

                label: tooltipLabel,
                data: chartValues,
                backgroundColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return;
                    }
                    return getGradient(ctx, chartArea, colorOne, colorTwo);
                },
                borderColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return;
                    }
                    return getGradient(ctx, chartArea);
                },
                borderWidth: 1,
                barPercentage: barPercentage
            },
        ],
    };

    const options = {
        minBarLength: minBarLength,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        return '';
                    },
                    title: function (context) {
                        if (customFormat) {
                            return customFormat(context[0].raw)
                        }

                        let label = context[0].dataset.label || '';
                        if (label) {
                            label += ': ';
                        } else {
                            label = 'Earnings: ';
                        }
                        if (context[0].raw) {
                            currency === 'eth' ?
                                label += `ETH ${formatMoney(context[0].raw)}`
                                :
                                currency === 'not currency' ? label += `${formatMoney(context[0].raw)}`
                                    :
                                    longLabels ? label += `${context[0].dataset.originalLabels[context[0].dataIndex] + ' $ '}${formatMoney(context[0].raw)}`
                                        :
                                        label += `$${formatMoney(context[0].raw)}`;
                        }
                        return label;
                    }
                },
                titleFont: {
                    size: 16
                },
                titleMarginBottom: 0
            }
        },
        scales: {
            y: {
                ticks: {
                    callback: function (value, index, values) {
                        try {
                            return customFormat ? customFormat(value) : `${currency === 'eth' ? 'ETH ' : currency == 'not currency' ? '' : '$'}${moneyShortSyntax(value)}`;
                        } catch (err) {
                            return '';
                        }
                    },
                    color: 'white',
                    font: {
                        size: 16
                    },
                },
                grid: {
                    borderColor: gridBorderColor,
                    color: gridMainColor
                },
            },
            x: {
                grid: {
                    drawTicks: false,
                    borderColor: color,
                    display: false,
                },
                ticks: {
                    color: 'white',
                    font: {
                        size: 16
                    }
                },
            }

        },
    };

    return (
        <div className={styles.barChart} style={{ width: width, height: height }}>
            {isLoading ? (
                <CircularProgress className={styles.spinner} />
            ) : (
                <ChartJsBar data={chartData} options={options} />
            )}
        </div>
    );
}
const dummyData = [
    { month: 'Jan', value: 510 },
    { month: 'Feb', value: 1520 },
    { month: 'Mar', value: 3330 },
    { month: 'Apr', value: 2410 },
    { month: 'May', value: -6131 },
    { month: 'Jun', value: 4231 },
    { month: 'Jul', value: -550 },
    { month: 'Aug', value: -8230 },
    { month: 'Sep', value: 1567 },
    { month: 'Oct', value: 6341 },
    { month: 'Nov', value: -1230 },
    { month: 'Dec', value: 3310 }

]
export function BrandLinearChart({ width, height }) {
    const styles = useStyles();
    const [chartLabels, setChartLabels] = useState([]);
    const [chartValues, setChartValues] = useState([]);

    useEffect(() => {
        const labels = [];
        const values = [];
        for (let i = 0; i < dummyData.length; i++) {
            labels.push("");
            values.push(dummyData[i].value);
        }
        setChartLabels(labels);
        setChartValues(values);
    }, [dummyData])

    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Earnings',
                data: chartValues,
                backgroundColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return;
                    }
                    return getGradient(ctx, chartArea);
                },
                borderColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return;
                    }
                    return getGradient(ctx, chartArea);
                },
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        return '';
                    },
                    title: function (context) {
                        if (context !== undefined) {
                            let label = context[0].dataset.label || '';
                            if (label) {
                                label += ': ';
                            } else {
                                label = 'Earnings: ';
                            }
                            if (context[0].raw) {
                                label += `$${formatMoney(context[0].raw)}`;
                            }
                            return label;
                        }
                    }
                },
                titleFont: {
                    size: 16
                },
                titleMarginBottom: 0
            }
        },
        scales: {
            y: {
                ticks: {
                    callback: function (value, index, values) {
                        try {
                            if (!value)
                                return `$${value}`;
                        } catch (err) {
                            return '';
                        }
                    },
                    color: 'white',
                    font: {
                        size: 16
                    },
                },
                grid: {
                    borderColor: '#1B1B1B',
                    color: '#5A5A5A',
                },
            },
            x: {
                grid: {
                    drawTicks: false,
                    borderColor: '#1B1B1B',
                    display: false,
                },
                ticks: {
                    color: 'white',
                    font: {
                        size: 16
                    }
                },
            }

        },
    };

    return (
        <div className={styles.barChart} style={{ width: width, height: height }}>
            <Line data={chartData} options={options} />
        </div>
    );
}

export function BrandHorizontalBarsChart({ isLoading, data, tooltipLabel, color, width, height, ...props }) {
    const styles = useStyles();
    const [chartLabels, setChartLabels] = useState([]);
    const [chartValues, setChartValues] = useState([]);

    useEffect(() => {
        const labels = [];
        const values = [];
        for (let i = 0; i < data.length; i++) {
            labels.push(data[i].name);
            values.push(data[i].value);
        }
        setChartLabels(labels);
        setChartValues(values);
    }, [data])

    const chartData = {
        labels: chartValues,
        datasets: [
            {
                label: tooltipLabel,
                data: chartValues,
                backgroundColor: color,
                borderColor: color,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        // barPercentage: 2,
        categoryPercentage: 0.65,
        scales: {
            y: {
                ticks: {
                    callback: function (value, index, values) {
                        try {
                            if (chartData.labels.length) {
                                return `$${formatMoney(chartData.labels[index])}  `;
                            }
                            return '$0  ';
                        } catch (err) {
                            return '$0  ';
                        }
                    },
                    color: 'white',
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                },
                grid: {
                    drawTicks: false,
                    borderColor: '#1B1B1B',
                    display: false,
                },

            },
            x: {
                grid: {
                    drawTicks: false,
                    borderColor: '#1B1B1B',
                    display: false,
                },
                ticks: {
                    callback: function (value, index, values) {
                        return ``;
                    },
                },
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        return '';
                    },
                    title: function (context) {
                        let label = context[0].dataset.label || '';
                        if (label) {
                            label += ': ';
                        } else {
                            label = 'Earnings: ';
                        }
                        if (context[0].raw) {
                            label += `$${formatMoney(context[0].raw)}`;
                        }
                        return label;
                    }
                },
                titleFont: {
                    size: 16
                },
                titleMarginBottom: 0
            }
        },
    };

    return (
        <div>
            <div className={styles.verticalBarChart} style={{ width: width, height: height }}>
                {isLoading ? (
                    <CircularProgress className={styles.spinner} />
                ) : (
                    <Fragment>
                        <ChartJsBar data={chartData} options={options} />
                        {chartLabels.length ?
                            <div className={styles.overTextContainer}>
                                {chartLabels.map(label => (<p key={label} className={styles.overText}>{label}</p>))}
                            </div>
                            : null}
                    </Fragment>
                )}

            </div>
        </div>
    )
}
