import React, { useState, useEffect, useRef } from 'react';
import {
    makeStyles
} from "@material-ui/core";
import SearchSongAutocomplete from './SearchSongAutocomplete';

const useStyles = makeStyles((theme) => ({
    songOptions: {
        borderRadius: theme.spacing(2.5),
        height: theme.spacing(5),
        backgroundColor: theme.palette.background.suggestedSongsMatch,
        width: '288px',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        justifyContent: 'center',
        cursor: 'pointer'
    },
    optionsLabel: {
        color: 'black',
        fontWeight: 'bold',
    },

}));

const SongSuggestionsAutocomplete = ({
    songMap,
    songMapKey,
    onSelectedSong
}) => {
    const label = `+ Choose from selection (${songMap.suggestedSongs.length} options)`;

    const styles = useStyles();
    const [suggestedSongs, setSuggestedSongs] = useState(songMap.suggestedSongs);
    const [showSuggestedSongs, setShowSuggestedSongs] = useState(false);
    const listComponentRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (listComponentRef.current && !listComponentRef.current.contains(e.target)
                && (e.target.className !== styles.optionsLabel && e.target.className !== styles.songOptions)) {
                setShowSuggestedSongs(false);
            }
        }

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        showSuggestedSongs ?
            <div ref={listComponentRef}>
                <SearchSongAutocomplete
                    onSelectedSong={(song) => onSelectedSong(song, songMapKey)}
                    suggestedSongs={suggestedSongs}
                />
            </div>
            :
            <div>
                <div onClick={() => setShowSuggestedSongs(true)} className={styles.songOptions} >
                    <div className={styles.optionsLabel}>
                        {label}
                    </div>
                </div>
            </div>
    );
}

export default SongSuggestionsAutocomplete;