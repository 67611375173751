import { useStoreContext } from '../store/Store';

export const useSnackbarAlert = () => {
    const [state, setState] = useStoreContext();

    const showSuccessAlert = (text) => {
        setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                open: true,
                severity: 'success',
                text: text
            }
        }));
    }

    const showErrorAlert = (text) => {
        setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                open: true,
                severity: 'error',
                text: text
            }
        }));
    }

    return { showSuccessAlert, showErrorAlert };
}