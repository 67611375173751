import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { EthIcon, roundToSecondDecimal } from '../NftPortfolio';


const useNftStyles = makeStyles((theme) => ({
    dataCols: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
    },
    dataDetailsCol: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        marginBottom: theme.spacing(2.5)
    },
    dataColsRow: {
        paddingTop: theme.spacing(3.75),
    },
    dataDetailsTabRowDataMoneyData: {
        font: 'normal normal bold 24px/31px Roboto',
        letterSpacing: '2.4px',
        textAlign: 'center'
    },
    calculation: {
        fontSize: theme.spacing(3.5)
    },
    dataDetailsTabRowDataMoneyLabel: {
        font: 'normal normal normal 12px/15px Roboto',
        letterSpacing: '1.2px',
        color: theme.palette.primary.lightGray,
        textAlign: 'center'
    },
    barVerticalHr: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: theme.spacing(7.6),
        marginTop: -theme.spacing(1),
        display: 'inline-block',
        border: `1px solid ${theme.palette.primary.border}`
    },

}));

function Performance({ details, additionalDetails }) {
    const styles = useNftStyles();

    return (
        <div className={clsx(styles.dataCols, styles.dataColsRow)}>
            <div className={styles.dataDetailsCol}>
                <div className={clsx(styles.dataDetailsTabRowDataMoneyData, styles.calculation)}>
                    {additionalDetails && additionalDetails.revenue >= 0 ?
                        <>
                            {roundToSecondDecimal(additionalDetails.revenue)}{additionalDetails.currency === 'USDC' ? '$' : <EthIcon />}
                        </>
                        :
                        <>
                            {roundToSecondDecimal(details.revenue)}<EthIcon />
                        </>
                    }
                </div>
                <div className={styles.dataDetailsTabRowDataMoneyLabel}>Total Revenue</div>
            </div>
            <div className={styles.dataDetailsCol}>
                <div className={clsx(styles.dataDetailsTabRowDataMoneyData, styles.calculation)}>
                    {additionalDetails && additionalDetails.primarySales >= 0 ?
                        <>
                            {roundToSecondDecimal(additionalDetails.primarySales)}{additionalDetails.currency === 'USDC' ? '$' : <EthIcon />}
                        </>
                        :
                        <>
                            {roundToSecondDecimal(details.primarySales)} <EthIcon />
                        </>
                    }
                </div>
                <div className={styles.dataDetailsTabRowDataMoneyLabel}>Primary Sales</div>
            </div>
            <div className={styles.dataDetailsCol}>
                <div className={clsx(styles.dataDetailsTabRowDataMoneyData, styles.calculation)}>
                    {additionalDetails && additionalDetails.primaryTransactions >= 0 ?
                        <>
                            {additionalDetails.primaryTransactions}
                        </>
                        :
                        <>
                            {details.primaryTransactions}
                        </>
                    }
                </div>
                <div className={styles.dataDetailsTabRowDataMoneyLabel}>Primary Transactions</div>
            </div>
            <div className={styles.dataDetailsCol}>
                <div className={clsx(styles.dataDetailsTabRowDataMoneyData, styles.calculation)}>
                    {additionalDetails && additionalDetails.secondarySales >= 0 ?
                        <>
                            {roundToSecondDecimal(additionalDetails.secondarySales)}{additionalDetails.currency === 'USDC' ? '$' : <EthIcon />}
                        </>
                        :
                        <>
                            {roundToSecondDecimal(details.secondarySales)} <EthIcon />
                        </>
                    }
                </div>
                < div className={styles.dataDetailsTabRowDataMoneyLabel}>Secondary Sales</div>
            </div>
            <div className={styles.dataDetailsCol}>
                <div className={clsx(styles.dataDetailsTabRowDataMoneyData, styles.calculation)}>
                    {additionalDetails && additionalDetails.secondaryTransactions >= 0 ?
                        <>
                            {additionalDetails.secondaryTransactions}
                        </>
                        :
                        <>
                            {details.secondaryTransactions}
                        </>
                    }
                </div>
                <div className={styles.dataDetailsTabRowDataMoneyLabel}>Secondary Transactions</div>
            </div>
            <hr className={styles.barVerticalHr} />
            <div className={styles.dataDetailsCol}>
                <div className={clsx(styles.dataDetailsTabRowDataMoneyData, styles.calculation)}>
                    {additionalDetails && additionalDetails.lastSale >= 0 ?
                        <>
                            {roundToSecondDecimal(additionalDetails.lastSale)}{additionalDetails.currency === 'USDC' ? '$' : <EthIcon />}
                        </>
                        :
                        <>
                            {roundToSecondDecimal(details.lastSale)}<EthIcon />
                        </>
                    }
                </div>
                <div className={styles.dataDetailsTabRowDataMoneyLabel}>Last Sale</div>
            </div>
            <div className={styles.dataDetailsCol}>
                <div className={clsx(styles.dataDetailsTabRowDataMoneyData, styles.calculation)}>{details.floorPrice} <EthIcon /></div>
                <div className={styles.dataDetailsTabRowDataMoneyLabel}>Floor Price</div>
            </div>
        </div>
    );
}

export default Performance;