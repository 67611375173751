export const participantTypeEnum = Object.freeze({
    1: "Songwriter/Composer",
    2: "Publisher",
    3: "Artist",
    4: "Rightsholder"
});

export const shareTypeEnum = Object.freeze({
    MASTER_SHARE_TYPE: "1",
    COMPOSITION_SHARE_TYPE: '2',
});