import React, { useEffect, useState } from 'react';
import { TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton'
import { BrandInput, BrandPasswordInput } from '../CoreComponents/BrandInput'
import { BrandMenuItem, BrandSelect } from '../CoreComponents/BrandSelect'
import { Typography, makeStyles, Chip } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { useStoreContext } from '../../store/Store';
import { textIsEmpty, textIsEmail, textIsPassword } from '../utils/ValidationUtils';
import { putData } from '../utils/FetchUtils';
import { BrandAlert } from '../CoreComponents/BrandAlert';
import { AccountHolderAutoComplete } from '../ManageAccountHolders/AccountHolderAutoComplete';
import { ButtonSize } from '../../constants/buttonConstants';
// import RecordLogin from '../../assets/RecordLogin.png';

const useEditUsersStyles = makeStyles(theme => ({
    container: {
        "& label": {
            marginBottom: theme.spacing(3.5),
        },
        width: theme.spacing(51),
        height: theme.spacing(73),
        padding: theme.spacing(2)
    },
    editAsNormalUserContainer: {
        width: theme.spacing(40),
        margin: 'auto',
    },
    layout: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: 'transparent linear-gradient(61deg, #121212 0%, #212020 32%, #4A4848 100%) 0% 0% no-repeat content-box',
        '&:before': {
            content: '""',
            position: 'absolute',
            height: '92%',
            width: '100%',
            // background: `url(${RecordLogin}) 0% 0% no-repeat content-box`,
            backgroundSize: 'auto 100%',
        }
    },
    emailAndRoleContainer: {
        display: 'flex',
        marginTop: 10,
        gap: 20,
        "& .emailInput": {
            width: "100%",
            "& label": {
                width: "100%",
            }
        },
        "& .roleSelect": {
            minWidth: '140px',
            "& label": {
                width: "100%",
            }
        }
    },
    actions: {
        display: 'flex',
        justifyContent: "flex-end",
        gap: 10,
        paddingBottom: theme.spacing(2),
        '& .MuiButton-outlinedPrimary:hover': {
            border: `1px solid ${theme.palette.text.lightYellow}`
        },
        "& button": {
            width: '93px'
        },
    },
    saveMessage: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10
    },
    chip: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.hover,
        },
        padding: '2px',
        '& .MuiChip-deleteIcon': {
            color: 'black'
        },
        maxWidth: '95%'
    },
}));

const dataValidators = {
    isRequired: (value) => {
        const errors = []
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
    validateEmail: (value) => {
        const errors = []
        if (!textIsEmail(value)) {
            errors.push('Invalid mail.')
        }
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
    validatePassword: (value) => {
        const errors = []
        const res = textIsPassword(value);
        if (res !== true) {
            errors.push(res)
        }
        if (textIsEmpty(value)) {
            errors.push('Value must not be empty.')
        }
        return errors;
    },
}

const inputProps = {
    maxLength: 60,
};

export function EditUser({ rowData, onClose, onSubmit }) {
    const styles = useEditUsersStyles();
    const firstName = useState(rowData.firstName);
    const lastName = useState(rowData.lastName);
    const email = useState(rowData.email);
    const selectedRole = useState(rowData.userType);
    const [roleOptions] = useState([{ userType: 1, name: 'Admin' }, { userType: 2, name: 'User' }]);
    const [state, setState] = useStoreContext();
    const [accountHolders, setAccountHolders] = useState(rowData.accountHolders || []);
    const firstNamePassed = useState(true);
    const lastNamePassed = useState(true);
    const emailPassed = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [alert, setAlert] = useState('');
    const [finishedSavingAlert, setFinishedSaving] = useState('');
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);

    const originalAccountHolders = JSON.stringify(rowData.accountHolders)
    const changedAccountHolders = JSON.stringify(accountHolders)

    useEffect(() => {
        if (finishedSavingAlert) {
            setTimeout(() => {
                setFinishedSaving('');
            }, "4000")
        }
    }, [finishedSavingAlert])

    useEffect(() => {
        if (!firstNamePassed[0] || !lastNamePassed[0] || !emailPassed[0]) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [firstNamePassed[0], lastNamePassed[0], emailPassed[0]])

    useEffect(() => {
        const pattern = /[^A-Za-z0-9 \p{Cyrillic}]/gm
        const firstNameCheck = firstName[0].match(pattern)
        const lastNameCheck = lastName[0].match(pattern)

        setSubmitDisabled(false)

        if (firstNameCheck || lastNameCheck) {
            setAlert('Special chars are now allowed!')
            setSubmitDisabled(true)
        } else {
            setAlert('');
            setSubmitDisabled(false)
        }
        if (!firstName[0] || !lastName[0] || !emailPassed[0]) {
            setSubmitDisabled(true)
        }


        if (
            firstName[0] === rowData.firstName &&
            lastName[0] === rowData.lastName &&
            email[0] === rowData.email &&
            selectedRole[0] === rowData.userType &&
            originalAccountHolders === changedAccountHolders
        ) {
            setSubmitDisabled(true)
        }
    }, [firstName[0], lastName[0], email[0], selectedRole[0], emailPassed[0], accountHolders]);

    return (
        <div className={styles.container}>
            <Typography
                variant="h5"
                style={{ paddingBottom: 20 }}
            >
                Edit User
            </Typography>
            <BrandInput
                required
                inputProps={inputProps}
                $value={firstName}
                validator={dataValidators.isRequired}
                validatorPassed={firstNamePassed}
                label='First Name'
            />
            <BrandInput
                required
                inputProps={inputProps}
                $value={lastName}
                validator={dataValidators.isRequired}
                validatorPassed={lastNamePassed}
                label='Last Name'
            />

            {alert ? <BrandAlert>{alert}</BrandAlert> : null}

            <div className={styles.emailAndRoleContainer}>
                <div className='emailInput'>
                    <BrandInput $value={email} required validator={dataValidators.validateEmail} validatorPassed={emailPassed} label='Email' />
                </div>
                <div className='roleSelect'>
                    <BrandSelect label='Role' $value={selectedRole} disabled={rowData.id !== state.user.id}>
                        {roleOptions.map(x => (
                            <BrandMenuItem key={x.userType} value={x.userType}>
                                {x.name}
                            </BrandMenuItem>
                        ))}
                    </BrandSelect>
                </div>
            </div>
            <AccountHolderAutoComplete
                label="Account Holder"
                onSelectedAccountHolder={accountHolder => {
                    for (let i = 0; i < accountHolders.length; i++) {
                        if (accountHolders[i].id === accountHolder.id) {
                            return;
                        }
                    }
                    setAccountHolders([...accountHolders, accountHolder])
                }}
            />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5, marginBottom: 40 }}>
                {accountHolders.map((value, i) => {
                    return ( //todo
                        <Chip                         
                            className={styles.chip}
                            key={value.id + value.name + i}
                            label={value.name}
                            clickable
                            onDelete={() => {
                                setAccountHolders(accountHolders.filter(chip => chip !== value));
                            }}
                        />
                    )
                })}
            </Box>
            <div className={styles.actions}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    variant='outlined'
                    onClick={onClose}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton
                    size={ButtonSize.SMALL}
                    disabled={submitDisabled}
                    onClick={() => {
                        const accountHolderMap = [];
                        accountHolders.forEach(x => accountHolderMap.push({ accountHolderId: x.id, userId: rowData.id }));
                        putData(process.env.REACT_APP_SERVER_HOST + '/api/user/',
                            {
                                firstName: firstName[0],
                                lastName: lastName[0],
                                email: email[0],
                                userType: selectedRole[0],
                                id: rowData.id,
                                accountHolderMap: accountHolderMap
                            })
                            .then(data => {
                                if (data.error) {
                                    setAlert(data.error)
                                    return;
                                }
                                onSubmit();
                                setSavedSuccessfully(true);
                                setFinishedSaving('Saved successfully');
                                setSubmitDisabled(true)
                            }, error => {
                                setAlert(error.message)
                            })
                    }
                    }
                >
                    Save
                </TempBrandButton>
            </div>
            <div className={styles.saveMessage} >
                {finishedSavingAlert ? <BrandAlert success={savedSuccessfully}>{finishedSavingAlert}</BrandAlert> : null}
            </div>
        </div >
    );
}

export const EditAsNormalUser = () => {
    const styles = useEditUsersStyles();
    const [state, setState] = useStoreContext();
    const [rowData, setRowData] = useState(state.user);
    const [passwordAlert, setPasswordAlert] = useState('');
    const [passwordSuccess, setPasswordSuccess] = useState('');
    const firstName = useState(rowData.firstName);
    const lastName = useState(rowData.lastName);
    const password = useState('');
    const repeatPassword = useState('');
    const firstNamePassed = useState(true);
    const lastNamePassed = useState(true);
    const passwordPassed = useState(true);
    const [disableSave, setDisableSave] = useState(true);
    const [disablePasswordCotainerSave, setDisablePasswordContainerSave] = useState(false);
    const [alert, setAlert] = useState('');
    const [finishedSavingAlert, setFinishedSaving] = useState('');
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);

    useEffect(() => {
        if (finishedSavingAlert) {
            setTimeout(() => {
                setFinishedSaving('');
            }, "4000")
        }
    }, [finishedSavingAlert])

    useEffect(() => {
        setState(state => (state.disableRootPadding = true, { ...state }));
        return () => { setState(state => (state.disableRootPadding = false, { ...state })) }
    }, [])

    useEffect(() => {
        setPasswordAlert('');
        setPasswordSuccess('')
    }, [password[0], repeatPassword[0]])

    useEffect(() => {
        if (!firstNamePassed[0] || !lastNamePassed[0]) {
            setDisableSave(true);
        } else {
            setDisableSave(false);
        }
    }, [firstNamePassed[0], lastNamePassed[0]])

    useEffect(() => {
        if (!passwordPassed[0]) {
            setDisablePasswordContainerSave(true);
        } else {
            setDisablePasswordContainerSave(false);
        }
    }, [passwordPassed[0]])

    useEffect(() => {
        const pattern = /[^A-Za-z0-9 \p{Cyrillic}]/gm
        const firstNameCheck = firstName[0].match(pattern)
        const lastNameCheck = lastName[0].match(pattern)

        setDisableSave(false)

        if (firstNameCheck || lastNameCheck) {
            setAlert('Special characters are now allowed!')
            setDisableSave(true)
        } else {
            setAlert('');
            setDisableSave(false)
        }
        if (!firstName[0] || !lastName[0]) {
            setDisableSave(true)
        }

        if (firstName[0] === rowData.firstName && lastName[0] === rowData.lastName) {
            setDisableSave(true)
        }

    }, [firstName[0], lastName[0]]);


    return (
        <div className={styles.layout}>
            <div className={styles.editAsNormalUserContainer}>
                <Typography
                    variant="h5"
                    style={{ paddingBottom: 20 }}
                >
                    Profile
                </Typography>
                <BrandInput
                    required
                    inputProps={inputProps}
                    $value={firstName}
                    validator={dataValidators.isRequired}
                    validatorPassed={firstNamePassed}
                    label='First Name'
                />
                <BrandInput
                    required
                    inputProps={inputProps}
                    $value={lastName}
                    validator={dataValidators.isRequired}
                    validatorPassed={lastNamePassed}
                    label='Last Name'
                />
                {alert ? <BrandAlert>{alert}</BrandAlert> : null}
                <div className={styles.actions}>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        disabled={disableSave}
                        onClick={async () => {
                            if (firstName[0] !== rowData.firstName || lastName[0] !== rowData.lastName) {
                                putData(process.env.REACT_APP_SERVER_HOST + '/api/user/', {
                                    firstName: firstName[0],
                                    lastName: lastName[0],
                                    id: rowData.id
                                }).then(data => {
                                    setState(state => (state.user.firstName = firstName[0], state.user.lastName = lastName[0], { ...state }));
                                    setSavedSuccessfully(true);
                                    setFinishedSaving('Saved successfully');
                                    setDisableSave(true)
                                }, error => {
                                    console.log(error)
                                })
                            }
                        }
                        }
                    >
                        Save
                    </TempBrandButton>
                </div>
                <div className={styles.saveMessage} >
                    {finishedSavingAlert ? <BrandAlert success={savedSuccessfully}>{finishedSavingAlert}</BrandAlert> : null}
                </div>
                <Typography
                    variant="h5"
                    style={{ marginBottom: 20, marginTop: 70 }}
                >
                    Change Password
                </Typography>
                <BrandPasswordInput $value={password} required validator={dataValidators.validatePassword} validatorPassed={passwordPassed} label='New Password' />
                <BrandPasswordInput $value={repeatPassword} required label='Confirm Password' />
                {passwordAlert ? <BrandAlert>{passwordAlert}</BrandAlert> : null}
                {passwordSuccess ? <BrandAlert success={true}>{passwordSuccess}</BrandAlert> : null}
                <div className={styles.actions}>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        disabled={disablePasswordCotainerSave}
                        onClick={async () => {
                            if (password[0] && repeatPassword[0]) {
                                if (password[0] !== repeatPassword[0]) {
                                    setPasswordAlert("Passwords don't match")
                                    return;
                                }
                                putData(process.env.REACT_APP_SERVER_HOST + '/api/user/change-password', {
                                    password: password[0],
                                    id: rowData.id
                                }).then(data => {
                                    if (data.error) {
                                        setPasswordAlert(data.error)
                                        return;
                                    }
                                    setPasswordSuccess("Password changed successfully")
                                }, error => {
                                    setPasswordAlert(error.msg)
                                })
                            } else {
                                setPasswordAlert("All fields are mandatory")
                            }
                        }
                        }
                    >
                        Save
                    </TempBrandButton>
                </div>
            </div>
        </div>
    );
}
