import { Box, CircularProgress, Typography, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { BrandButton, TempBrandButton } from "../CoreComponents/BrandButton";
import { BrandDeleteModal, BrandModal } from "../CoreComponents/BrandModal";
import ReportingCompaniesTable from "./ReportingCompaniesTable";
import SelectReportingCompanyAndType from "./SelectReportingCompanyAndType";
import CompanyActions from "./CompanyActions";
import { reportNames } from "../utils/ReportNamesUtils";
import { deleteData, getData } from "../utils/FetchUtils";

const useStyles = makeStyles((theme) => ({
    mainHeader: {
        font: 'normal normal bold 25px Roboto'
    },
    autocompleteContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '16px',
        marginBottom: '25px'
    },
    addCompanyButton: {
        height: '40px',
        color: theme.palette.background.default,
        font: 'normal normal normal 14px Roboto',
    },
    text: {
        marginBottom: theme.spacing(8.5)
    },
    alert: {
        color: theme.palette.primary.alert,
        marginBottom: '5px',
        wordWrap: 'break-word',
        width: '330px', 
    },
    successMsg: {
        color: theme.palette.primary.main,
        marginBottom: '5px',
        wordWrap: 'break-word',
        width: '330px', 
    },
    addCompanyButton: {
        width: '10vw',
    }
}));

const ReportingCompanies = ({ }) => {
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState('');
    const [isActionClicked, setIsActionClicked] = useState(false);
    const [successMsg, setSuccessMsg] = useState('')
    const [addCompanyModal, setAddCompanyModal] = useState(false);
    const [displayEditCompanyModal, setDisplayEditCompanyModal] = useState(false);
    const [displayDeleteReportingCompanyModal, setDisplayDeleteReportingCompanyModal] = useState(false);
    const [disableModalDeleteButton, setDisableModalDeleteButton] = useState(false);

    const [filterByType, setFilterByType] = useState([]);
    const [filterByCompany, setFilterByCompany] = useState([]);

    const [selectedRowData, setSelectedRowData] = useState({});

    const addModal = 'add';
    const addHeader = 'Add Reporting Company';
    const addDefaultCompany = 'Select Reporting Company Type';
    const addModalDefaultCompanyName = '';
    const addActionButtonText = 'Add'

    const editModal = 'edit';
    const editHeader = 'Edit Reporting Company';
    const editDefaultCompany = reportNames.filter(company => company.id === selectedRowData.reportingType)[0]?.name;
    const editModalDefaultCompanyName = selectedRowData.name;
    const editActionButtonText = 'Save';
    const rowId = selectedRowData.id;

    const addAndEditPlaceholder = 'Add Reporting Company Name';

    const handleCompanyModal = (kindOfModal) => {
        if (kindOfModal === addModal) {
            setAddCompanyModal(!addCompanyModal);
        } else {
            setDisplayEditCompanyModal(!displayEditCompanyModal);
        }
    };

    const deleteCompany = (id) => {
        setLoading(true);
        setAlert('');

        getData(process.env.REACT_APP_SERVER_HOST + `/api/reporting-companies/get-reporting-company-reports?reportingCompanyId=${id}`)
            .then(reportFiles => {
                if (reportFiles.reports.length) {
                    setLoading(false);
                    setAlert("There are reports attached to this reporting company and it can't be deleted");
                } else {
                    deleteData(process.env.REACT_APP_SERVER_HOST + '/api/reporting-companies/delete-reporting-company', { id })
                        .then(res => {
                            setDisplayDeleteReportingCompanyModal(false);
                            // setSuccessMsg('Successfully deleted!');
                            setLoading(false);
                            setIsActionClicked(true);
                        })
                        .catch(err => {
                            console.log(err.message);
                            setAlert(err.message);
                            setLoading(false);
                        })
                }
            })
            .catch(err => {
                setLoading(false);
                setAlert(err.message);
            })
    }

    useEffect(() => {
        setDisableModalDeleteButton(alert != '');
    }, [alert]);

    useEffect(() => {
        setAlert('');
        setSuccessMsg('');
    }, [displayDeleteReportingCompanyModal])

    return (
        <div>
            <Typography
                variant="h6"
                className={styles.mainHeader}
            >
                Reporting Companies
            </Typography>
            <BrandModal
                open={addCompanyModal}
                onClose={() => handleCompanyModal(addModal)}
            >
                <CompanyActions
                    kindOfModal={addModal}
                    onClose={() => handleCompanyModal(addModal)}
                    header={addHeader}
                    placeholder={addAndEditPlaceholder}
                    defaultCompany={addDefaultCompany}
                    defaultCompanyName={addModalDefaultCompanyName}
                    actionButtonText={addActionButtonText}
                    setIsActionClicked={setIsActionClicked}
                    setAddCompanyModal={setAddCompanyModal}
                />
            </BrandModal>
            <BrandModal
                open={displayEditCompanyModal}
                onClose={() => handleCompanyModal(editModal)}
            >
                <CompanyActions
                    onClose={() => handleCompanyModal(editModal)}
                    header={editHeader}
                    placeholder={addAndEditPlaceholder}
                    defaultCompany={editDefaultCompany}
                    defaultCompanyName={editModalDefaultCompanyName}
                    actionButtonText={editActionButtonText}
                    rowId={rowId}
                    setIsActionClicked={setIsActionClicked}
                    setEditCompanyModal={setDisplayEditCompanyModal}
                />
            </BrandModal>
            <BrandDeleteModal
                open={displayDeleteReportingCompanyModal}
                onClose={() => setDisplayDeleteReportingCompanyModal(false)}
                onDelete={() => deleteCompany(selectedRowData.id)}
                disabled={disableModalDeleteButton}
            >
                <Typography
                    variant="h5"
                    style={{ paddingBottom: 20 }}
                >
                    Delete Reporting Company
                </Typography>
                <div className={styles.text}>
                    Are you sure you want to delete "{selectedRowData.name}"?
                </div>
                {
                    loading ?
                        <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                            <CircularProgress />
                        </Box >
                        :
                        <>
                            {
                                alert ?
                                    <div className={styles.alert}>{alert}</div>
                                    :
                                    ''
                            }
                            {
                                successMsg ?
                                    <div className={styles.successMsg}>{successMsg}</div>
                                    :
                                    ''
                            }
                        </>
                }
            </BrandDeleteModal>
            <div className={styles.autocompleteContainer}>
                <SelectReportingCompanyAndType
                    setFilterByType={setFilterByType}
                    setFilterByCompany={setFilterByCompany}
                    isActionClicked={isActionClicked}
                />
                <TempBrandButton
                    className={styles.addCompanyButton}
                    capitalize={true}
                    onClick={() => handleCompanyModal(addModal)}
                >
                    Add Company
                </TempBrandButton>
            </div>
            <ReportingCompaniesTable
                setDisplayDeleteReportingCompanyModal={setDisplayDeleteReportingCompanyModal}
                setSelectedRowData={setSelectedRowData}
                setDisplayEditCompanyModal={setDisplayEditCompanyModal}
                isActionClicked={isActionClicked}
                setIsActionClicked={setIsActionClicked}
                filterByCompany={filterByCompany}
                filterByType={filterByType}
            />
        </div>
    )
};

export default ReportingCompanies;